"use strict";
/**
 *  SuggestedModal.tsx
 *  Recurring expenses edit modal
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_1 = require("@helpers/format");
const react_1 = require("react");
const recurring_expenses_1 = require("@actions/recurring_expenses");
const ModalProvider_1 = require("@providers/ModalProvider");
const UserProvider_1 = require("@providers/UserProvider");
const SuggestedModal = ({ 
/** Recurring expense to be edited */
data, useModal, utils, setVisibility, switchView, setDisableOnClickOutside, }) => {
    let history = (0, react_router_dom_1.useHistory)();
    const _modal = (0, react_1.useContext)(ModalProvider_1.ModalContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [expense, setExpense] = (0, react_1.useState)(data.recurring);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [suggestRule, setSuggestRule] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        useModal({
            header: (React.createElement("div", null,
                React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        switchView('DETAILS_VIEW');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " Transaction Details"),
                React.createElement("span", { className: "header-text-large" }, "Is this a recurring item?"))),
            middleButton: React.createElement(React.Fragment, null),
            leftButton: React.createElement(React.Fragment, null),
            rightButton: (React.createElement(semantic_ui_react_1.Button, { className: "no-border", onClick: () => {
                    setVisibility(false, true);
                } }, 'Close')),
        });
        return () => {
            useModal({
                header: React.createElement(React.Fragment, null),
                leftButton: React.createElement(React.Fragment, null),
                middleButton: React.createElement(React.Fragment, null),
                rightButton: React.createElement(React.Fragment, null),
            });
        };
    }, []);
    (0, react_1.useEffect)(() => {
        const fetchSuggested = () => __awaiter(void 0, void 0, void 0, function* () {
            // TODO: This should just get it from TransactionsProvider
            const results = yield (0, recurring_expenses_1.getSuggested)(_modal.data.current.recurring_id);
            if (results) {
                setExpense(results);
            }
            else {
                setIsLoading(true);
                const results = yield utils._process(recurring_expenses_1.updateRecurringExpense)(_modal.data.current.recurring_id, {
                    type: 'dismissed',
                });
                if (!results.error) {
                    utils._showToast({
                        message: `Woops, looks like this was mislabeled as a recurring item! Nothing to see here!`,
                        type: 'success',
                        autoDismissTimeout: 10000,
                    });
                    if (!!data.removeSuggested) {
                        data.removeSuggested();
                    }
                    else if (!!data.dismissRecurring) {
                        data.dismissRecurring(_modal.data.current.recurring_id, false);
                    }
                    setIsLoading(false);
                    setVisibility(false, false);
                }
                setIsLoading(false);
            }
        });
        if (data === null || data === void 0 ? void 0 : data.recurring) {
            setExpense(data.recurring);
        }
        else if (_modal.data.current.recurring_id) {
            // Comes here with no details from transactions page
            fetchSuggested();
        }
    }, []);
    return !expense ? null : (React.createElement("div", { id: "recurring-modal-detail-view", className: "g-recurring-modal" },
        React.createElement(semantic_ui_react_1.Message, { info: true },
            React.createElement("p", null, "We found the same transaction occurring regularly coming from the same account, the same merchant, and for the same amount.")),
        React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", className: "mb-05rem", color: "green", loading: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b, _c, _d, _e;
                // Set modal data
                _modal.data.current.previous = 'SUGGESTED_RECURRING';
                _modal.data.current.previous_text = 'Suggested Recurring Item Details';
                _modal.data.current.recurring = {
                    cadence: 'monthly',
                    recurring_id: expense.id,
                    category_id: expense.category_id ||
                        (expense.transactions && ((_a = expense.transactions[0]) === null || _a === void 0 ? void 0 : _a.category_id)) ||
                        ((_c = (_b = _modal.data.current) === null || _b === void 0 ? void 0 : _b.transaction) === null || _c === void 0 ? void 0 : _c.category_id) ||
                        null,
                    payee: expense.payee,
                    criteria_name: expense.payee, // Won't be changed
                    criteria_name_match: 'contain', // Won't be changed
                    amount_match: 'exactly',
                    amount_type: expense.amount > 0 ? 'expenses' : 'credits',
                    amount: Math.abs(expense.amount),
                    currency: expense.currency,
                    asset_id: expense.asset_id,
                    plaid_account_id: expense.plaid_account_id,
                    description: expense.notes,
                    billing_date: ((_d = expense.transactions[0]) === null || _d === void 0 ? void 0 : _d.formatted_date) || expense.billing_date,
                    transaction_id: (expense.transactions && ((_e = expense.transactions[0]) === null || _e === void 0 ? void 0 : _e.id)) ||
                        _modal.data.current.transaction.id,
                };
                // Switch view
                switchView('ADD_RECURRING');
            }) }, "Create recurring item"),
        React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", className: "mb-05rem", color: "orange", loading: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                // Switch view
                _modal.data.current.previous = 'SUGGESTED_RECURRING';
                _modal.data.current.previous_text = 'Suggested Recurring Item Details';
                _modal.data.current.recurring = {
                    id: expense.id,
                    payee: expense.payee,
                    category_id: expense.category_id,
                    institution_name: expense.institution_name,
                    asset_institution_name: expense.asset_institution_name,
                    plaid_account_name: expense.plaid_account_name,
                    asset_name: expense.asset_name,
                    amount: expense.amount,
                    currency: expense.currency,
                    flipSign: _user.settings['show_debits_as_negative'],
                    transactions: expense.transactions,
                };
                switchView('MERGE_RECURRING');
            }) }, "Merge with existing recurring item"),
        React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", className: "mb-1rem", color: "grey", loading: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                setIsLoading(true);
                const results = yield utils._process(recurring_expenses_1.deleteRecurringExpense)(expense.id);
                if (!results.error) {
                    if (!!data.amendTransactions) {
                        data.amendTransactions({
                            bulkUpdate: [
                                {
                                    ids: results.data.map(o => o.id),
                                    update: { recurring_id: null, recurring_type: null },
                                },
                            ],
                        });
                    }
                    setIsLoading(false);
                    setSuggestRule(true);
                    setDisableOnClickOutside(true);
                }
                setIsLoading(false);
            }) }, "This is not a recurring item"),
        React.createElement("div", { className: "transaction-details" },
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Transaction Original Name"),
                React.createElement("span", null, expense.payee)),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Originating Account"),
                React.createElement("span", null,
                    expense.institution_name || expense.asset_institution_name,
                    ' ',
                    expense.plaid_account_name ||
                        expense.asset_name ||
                        'Cash (none assigned)')),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Transaction Amount"),
                React.createElement("span", null,
                    React.createElement("p", null, (0, format_1.toPrice)(expense.flipSign ? -1 * expense.amount : expense.amount, expense.currency))))),
        React.createElement("div", { className: "transaction-details" },
            React.createElement("h3", null, "Matched transactions"),
            React.createElement("p", null, "Transactions to the same merchant for the same amount were paid on the following dates:"),
            React.createElement("p", { className: "mb-1rem" }, expense.transactions.map((transaction, index) => {
                return (React.createElement("span", { className: "display--block", key: `transaction-date-${index}` },
                    React.createElement(semantic_ui_react_1.Icon, { name: "check circle", color: "green" }),
                    React.createElement("span", { className: "monospace" }, (0, format_1.toPrice)(transaction.amount, transaction.currency)),
                    ' ',
                    "on",
                    ' ',
                    Moment(transaction.formatted_date || transaction.date).format(_user.getMonthDayYearFormat())));
            })),
            React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", className: "mb-1rem", onClick: () => {
                    _modal.data.current.edit_recurring_view = null;
                    setVisibility(false);
                    history.push({
                        pathname: '/transactions',
                        search: `?match=any&recurring=${expense.id}&time=all`,
                    });
                } }, "View matched transactions")),
        React.createElement(semantic_ui_react_1.Modal, { open: !!suggestRule, closeOnEscape: false, closeOnDimmerClick: false, onClose: () => {
                setSuggestRule(false);
                setVisibility(false, false);
            }, size: "tiny" },
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("div", { className: "content" },
                    React.createElement("p", null,
                        "Should we stop suggesting that transactions with the payee name,",
                        ' ',
                        React.createElement("b", null, expense.payee),
                        ", as a recurring item in the future?"))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        if (!!data.removeSuggested) {
                            utils._showToast({
                                message: 'Successfully removed suggested recurring item',
                                type: 'success',
                            });
                            data.removeSuggested();
                        }
                        else if (!!data.dismissRecurring) {
                            data.dismissRecurring(expense.id);
                        }
                        setVisibility(false, false);
                    }, negative: true }, "No"),
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        _modal.data.current['rule'] = {
                            criteria_payee_name: expense.payee,
                            criteria_payee_name_match: 'contain',
                            skip_recurring: true,
                        };
                        switchView('ADD_RULE');
                    } }, "Yes, take me to create a new rule")))));
};
exports.default = SuggestedModal;
