"use strict";
/**
 *  Transactions.tsx
 *  Container for the Transactions page
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
// Semantic UI
const semantic_ui_react_1 = require("semantic-ui-react");
const transactions_1 = require("@actions/transactions");
const qs = require("query-string");
// Components
const Day_1 = require("../components/Calendar/Day");
const ContainerHeader_1 = require("../components/elements/ContainerHeader");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const UserProvider_1 = require("@/providers/UserProvider");
const dates_1 = require("@/helpers/dates");
const calendar_1 = require("@/actions/calendar");
const Loader_1 = require("@/components/global/Loader");
const NewModal_1 = require("@/components/global/NewModal");
const ModalProvider_1 = require("@/providers/ModalProvider");
const PopupTransactions_1 = require("@/components/Transactions/PopupTransactions");
const Calendar = ({ history, _showToast, _removeToast, _process }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _modal = (0, react_1.useContext)(ModalProvider_1.ModalContext);
    const [rows, setRows] = (0, react_1.useState)([]);
    const [currentPeriod, setCurrentPeriod] = (0, react_1.useState)(null);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [openTransactionsTable, setOpenTransactionsTable] = (0, react_1.useState)(false);
    const [selectedDay, setSelectedDay] = (0, react_1.useState)({
        title: null,
        query: null,
        total: null,
    });
    const [showBetaMessage, setShowBetaMessage] = (0, react_1.useState)(localStorage.getItem('_lm_calendar_dismissed_beta_message') !== 'true');
    const [showModal, setShowModal] = (0, react_1.useState)(false);
    const shouldRefreshCalendar = (0, react_1.useRef)(false);
    const { year, month, day } = (0, react_router_dom_1.useParams)();
    // Display Options
    const [calendarDisplay, setCalendarDisplay] = (0, react_1.useState)(_user.settings['calendar_display'] || 'month');
    const [transactionsDisplay, setTransactionsDisplay] = (0, react_1.useState)(_user.settings['calendar_transactions_display'] || 'categorize');
    const [categoryGroupDisplay, setCategoryGroupDisplay] = (0, react_1.useState)(_user.settings['calendar_category_display'] || 'show');
    const [recurringDisplay, setRecurringDisplay] = (0, react_1.useState)(_user.settings['calendar_recurring_display'] || 'show');
    const [includeExcludeFromTotals, setIncludeExcludeFromTotals] = (0, react_1.useState)(_user.settings['calendar_include_exclude_from_totals'] || false);
    const [includePendingTransactions, setIncludePendingTransactions] = (0, react_1.useState)(_user.settings['calendar_include_pending_transactions'] || false);
    const [includeUnmatchedRecurringInTotals, setIncludeUnmatchedRecurringInTotals,] = (0, react_1.useState)(_user.settings['calendar_include_unmatched_recurring_in_totals'] || true);
    (0, react_1.useEffect)(() => {
        _user.updateSetting('calendar_transactions_display', transactionsDisplay);
    }, [transactionsDisplay]);
    (0, react_1.useEffect)(() => {
        _user.updateSetting('calendar_category_display', categoryGroupDisplay);
    }, [categoryGroupDisplay]);
    (0, react_1.useEffect)(() => {
        _user.updateSetting('calendar_recurring_display', recurringDisplay);
    }, [recurringDisplay]);
    (0, react_1.useEffect)(() => {
        _user.updateSetting('calendar_include_exclude_from_totals', includeExcludeFromTotals);
    }, [includeExcludeFromTotals]);
    (0, react_1.useEffect)(() => {
        _user.updateSetting('calendar_include_pending_transactions', includePendingTransactions);
    }, [includePendingTransactions]);
    (0, react_1.useEffect)(() => {
        _user.updateSetting('calendar_include_unmatched_recurring_in_totals', includeUnmatchedRecurringInTotals);
    }, [includeUnmatchedRecurringInTotals]);
    const generateCalendarRows = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (displayOverride = null) {
        var _a, _b, _c, _d, _e, _f;
        const _display = displayOverride || calendarDisplay;
        setIsLoading(true);
        const start_date = Moment(`${year}-${month}-${day || '01'}`);
        const end_date = Moment(`${year}-${month}-${day || '01'}`);
        if (_display == 'month') {
            start_date.startOf('month');
            end_date.endOf('month');
        }
        else if (_display == '2-week') {
            const offset = _user['settings']['week_starts_on'] == 'monday' ? 1 : 0;
            start_date.day(offset);
            end_date.day(offset).add(13, 'days');
            setTransactionsDisplay('show');
            setRecurringDisplay('show');
        }
        // Get the whole month because recurring items gets a bit wonky
        const calendar = yield (0, calendar_1.getCalendar)({
            start_date: start_date.format('YYYY-MM-DD'),
            end_date: Moment(start_date)
                .add(1, 'month')
                .format('YYYY-MM-DD'),
        });
        // month below is zero-indexed. month in params is not.
        const firstDay = start_date;
        const lastDay = end_date;
        // Calculate the offset for starting the calendar on Monday.
        // getDay() returns 0 for Sunday and 1 for Monday.
        // We need to handle Sunday as a special case (should be 6 instead of 0).
        let startOffset, numRows = 0;
        if (_display == 'month') {
            const _firstDayOfWeek = Number(firstDay.format('d'));
            const _lastDay = Number(lastDay.format('D'));
            startOffset =
                _user.settings['week_starts_on'] == 'monday'
                    ? _firstDayOfWeek === 0
                        ? 6
                        : _firstDayOfWeek - 1
                    : _firstDayOfWeek;
            numRows = Math.ceil((startOffset + _lastDay) / 7);
        }
        else if (_display == '2-week') {
            numRows = 2;
            startOffset = 0;
        }
        const _rows = [];
        let currentDate = firstDay;
        for (let i = 0; i < numRows; i++) {
            const row = [];
            for (let j = 0; j < (_display == '4-day' ? 4 : 7); j++) {
                // if ((i === 0 && j < firstDay.getDay()) || currentDate > lastDay) {
                if ((i === 0 && j < startOffset) || currentDate > lastDay) {
                    row.push(null); // Empty cell before the first day and after the last day
                }
                else {
                    const _day = currentDate.format('DD');
                    const _month = currentDate.format('MM');
                    const _year = currentDate.format('YYYY');
                    const properties = {
                        day: _day,
                    };
                    row.push({
                        day: _day,
                        month: _month,
                        year: _year,
                        matched_income: ((_a = calendar[`${_year}-${_month}-${properties.day}`]) === null || _a === void 0 ? void 0 : _a.matched_income) || { items: [] },
                        matched_expense: ((_b = calendar[`${_year}-${_month}-${properties.day}`]) === null || _b === void 0 ? void 0 : _b.matched_expense) || { items: [] },
                        unmatched_income: ((_c = calendar[`${_year}-${_month}-${properties.day}`]) === null || _c === void 0 ? void 0 : _c.unmatched_income) || { items: [] },
                        unmatched_expense: ((_d = calendar[`${_year}-${_month}-${properties.day}`]) === null || _d === void 0 ? void 0 : _d.unmatched_expense) || { items: [] },
                        tx_income: ((_e = calendar[`${_year}-${_month}-${properties.day}`]) === null || _e === void 0 ? void 0 : _e.tx_income) || { items: [] },
                        tx_expense: ((_f = calendar[`${_year}-${_month}-${properties.day}`]) === null || _f === void 0 ? void 0 : _f.tx_expense) || { items: [] },
                    });
                    currentDate = currentDate.add(1, 'day');
                }
            }
            _rows.push(row);
        }
        setRows(_rows);
        setIsLoading(false);
    });
    const onCreate = (transaction) => __awaiter(void 0, void 0, void 0, function* () {
        // Create transaction
        const results = yield _process(transactions_1.createTransaction)(Object.assign(Object.assign({}, transaction), { status: _user.settings['auto_review_transaction_on_creation']
                ? 'cleared'
                : 'uncleared' }), {
            should_convert: false,
        });
        if (results.error) {
            // Highlight the new transaction in the table
            return false;
        }
        else {
            generateCalendarRows();
        }
    });
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = 'Calendar - Lunch Money';
    }, []);
    (0, react_1.useEffect)(() => {
        let _display = calendarDisplay;
        // Set current period
        if (typeof month === 'undefined' && typeof year === 'undefined') {
            const _currentPeriod = localStorage.getItem('_lm_calendar_current_period');
            const currentPeriod = _currentPeriod !== 'null' && !!_currentPeriod
                ? _currentPeriod
                : (0, dates_1.getFormattedMonth)();
            history.replace(`/calendar/${Moment(currentPeriod).format('YYYY/MM')}${window.location.search}`);
        }
        else if (typeof month !== 'undefined' &&
            typeof year !== 'undefined' &&
            typeof day !== 'undefined') {
            _display = '2-week';
            // reset currentPeriod to the beginning of those two weeks
            // this gets reconciled when moving between periods
            setCurrentPeriod((0, dates_1.getStartOfWeek)(year, month, day, _user['settings']['week_starts_on']));
        }
        else {
            _display = 'month';
            const newCurrentPeriod = year && month
                ? (0, dates_1.getFormattedMonth)(year, month)
                : localStorage.getItem('_lm_calendar_current_period') ||
                    (0, dates_1.getFormattedMonth)();
            if (newCurrentPeriod !== currentPeriod) {
                setCurrentPeriod(newCurrentPeriod);
                localStorage.setItem('_lm_calendar_current_period', newCurrentPeriod);
                if (_user.settings['link_months_between_views']) {
                    localStorage.setItem('_lm_recurring_current_period', newCurrentPeriod);
                    localStorage.setItem('_lm_budget_current_period', newCurrentPeriod);
                    localStorage.setItem('_lm_transactions_current_period', newCurrentPeriod);
                }
            }
        }
        // Generate calendar
        generateCalendarRows(_display);
        _user.updateSetting('calendar_display', _display);
        setCalendarDisplay(_display);
    }, [month, year, day]);
    return (React.createElement(semantic_ui_react_1.Container, { className: "g-calendar" },
        React.createElement(ContainerHeader_1.default, { backLink: {
                pathname: `/calendar/${calendarDisplay == 'month'
                    ? (0, dates_1.getPreviousMonth)(currentPeriod)
                        .replace('-', '/')
                        .substring(0, 7)
                    : (0, dates_1.getPrevious2Weeks)(currentPeriod).replace(/-/g, '/')}`,
                search: window.location.search,
            }, forwardLink: {
                pathname: `/calendar/${calendarDisplay == 'month'
                    ? (0, dates_1.getNextMonth)(currentPeriod)
                        .replace('-', '/')
                        .substring(0, 7)
                    : (0, dates_1.getNext2Weeks)(currentPeriod).replace(/-/g, '/')}`,
                search: window.location.search,
            }, title: currentPeriod
                ? calendarDisplay == 'month'
                    ? `${Moment(currentPeriod, 'YYYY-MM-DD').format('MMMM YYYY')}`
                    : `${Moment(currentPeriod, 'YYYY-MM-DD').format('MMMM D, YYYY')} - ${Moment(currentPeriod, 'YYYY-MM-DD')
                        .add(13, 'days')
                        .format('MMMM D, YYYY')}`
                : '', 
            // dropdownOnChange={handleMonthJump}
            // dropdownOptions={calendarMonths}
            showBackToCurrentMonthButton: true, jumpBackToCurrentMonth: () => {
                localStorage.setItem('_lm_calendar_current_period', `${Moment().format('YYYY-MM')}-01`);
                if (_user.settings['link_months_between_views']) {
                    localStorage.setItem('_lm_recurring_current_period', `${Moment().format('YYYY-MM')}-01`);
                    localStorage.setItem('_lm_budget_current_period', `${Moment().format('YYYY-MM')}-01`);
                    localStorage.setItem('_lm_transactions_current_period', `${Moment().format('YYYY-MM')}-01`);
                }
                history.push(`/calendar/${Moment().format(calendarDisplay == 'month' ? 'YYYY/MM' : 'YYYY/MM/DD')}`);
            } }),
        showBetaMessage && (React.createElement(semantic_ui_react_1.Message, { info: true, onDismiss: () => {
                setShowBetaMessage(false);
                localStorage.setItem('_lm_calendar_dismissed_beta_message', 'true');
            } },
            React.createElement(semantic_ui_react_1.Message.Header, null, "Welcome to the new Calendar page!"),
            React.createElement(semantic_ui_react_1.Message.Content, { className: "mt-1rem" },
                React.createElement("p", null, "The purpose of the Calendar page is to give you a quick overview of your finances for the month on a day-to-day basis."),
                React.createElement("p", null, "We are looking for feedback on how you would like to use this Calendar page and what else you would like to see here. Feel free to also report any bugs or share any other feedback you have on this page."),
                React.createElement(semantic_ui_react_1.Button, { size: "mini", onClick: () => {
                        history.push({
                            search: qs.stringify({
                                show_feedback: 'general-feedback',
                            }),
                        });
                    } }, "Submit General Feedback")))),
        React.createElement("div", { className: "width-100 mb-1rem flex--space-between-flex-end" },
            React.createElement(semantic_ui_react_1.Popup, { position: 'bottom right', on: "hover", hoverable: true, wide: true, trigger: React.createElement(semantic_ui_react_1.Button, { icon: true, labelPosition: "left", color: "orange" },
                    React.createElement(semantic_ui_react_1.Icon, { name: "cogs" }),
                    "Display Options"), content: React.createElement("div", null,
                    React.createElement("p", { className: "divider-line" },
                        React.createElement("span", null, "transactions")),
                    React.createElement("div", null,
                        React.createElement(semantic_ui_react_1.Dropdown, { className: "mr-05rem inline-breakdown-dropdown no-wrap", inline: true, value: includePendingTransactions ? 'show' : 'hide', onChange: (e, { value }) => {
                                setIncludePendingTransactions(value == 'show');
                            }, options: [
                                {
                                    key: 'show',
                                    value: 'show',
                                    text: 'Include',
                                },
                                {
                                    key: 'hide',
                                    value: 'hide',
                                    text: 'Exclude',
                                },
                            ] }),
                        React.createElement("span", null, "pending transactions")),
                    React.createElement("div", { className: "mt-05rem" },
                        React.createElement(semantic_ui_react_1.Dropdown, { className: "mr-05rem inline-breakdown-dropdown no-wrap", inline: true, value: includeExcludeFromTotals ? 'show' : 'hide', onChange: (e, { value }) => {
                                setIncludeExcludeFromTotals(value == 'show');
                            }, options: [
                                {
                                    key: 'show',
                                    value: 'show',
                                    text: 'Include',
                                },
                                {
                                    key: 'hide',
                                    value: 'hide',
                                    text: 'Exclude',
                                },
                            ] }),
                        React.createElement("span", null,
                            "'Exclude from Totals' transactions",
                            ' ',
                            React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "help circle" }), inverted: true, size: "tiny" }, "Certain categories can be marked 'exclude from totals'. Hiding these transactions will remove them from view and from any totals calculations in the calendar view."))),
                    React.createElement("div", { className: "mt-05rem" },
                        React.createElement(semantic_ui_react_1.Dropdown, { className: "mr-05rem inline-breakdown-dropdown no-wrap", inline: true, value: transactionsDisplay, onChange: (e, { value }) => {
                                setTransactionsDisplay(value);
                            }, options: [
                                {
                                    key: 'hide',
                                    value: 'hide',
                                    text: 'Hide all',
                                },
                                {
                                    key: 'show',
                                    value: 'show',
                                    text: 'Show individual',
                                },
                                {
                                    key: 'categorize',
                                    value: 'categorize',
                                    text: 'Categorize',
                                },
                                {
                                    key: 'consolidate',
                                    value: 'consolidate',
                                    text: 'Consolidate',
                                },
                            ] }),
                        React.createElement("span", null,
                            "transactions",
                            ' ',
                            React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "help circle" }), inverted: true, size: "tiny" }, "Certain categories can be marked 'exclude from totals'. Hiding these transactions will remove them from view and from any totals calculations in the calendar view."))),
                    transactionsDisplay == 'categorize' && (React.createElement("div", { className: "mt-05rem" },
                        React.createElement("span", { className: "hierarchy-line-icon-5" }),
                        React.createElement(semantic_ui_react_1.Dropdown, { className: "mr-05rem inline-breakdown-dropdown no-wrap", inline: true, value: categoryGroupDisplay, onChange: (e, { value }) => {
                                setCategoryGroupDisplay(value);
                            }, options: [
                                {
                                    key: 'show',
                                    value: 'show',
                                    text: 'Show',
                                },
                                {
                                    key: 'hide',
                                    value: 'hide',
                                    text: 'Hide',
                                },
                            ] }),
                        React.createElement("span", null,
                            "category groups",
                            ' ',
                            React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "help circle" }), inverted: true, size: "tiny" }, "Certain categories can be marked 'exclude from totals'. Hiding these transactions will remove them from view and from any totals calculations in the calendar view.")))),
                    React.createElement("p", { className: "divider-line mt-1rem" },
                        React.createElement("span", null, "recurring items")),
                    React.createElement("div", null,
                        React.createElement(semantic_ui_react_1.Dropdown, { className: "mr-05rem inline-breakdown-dropdown no-wrap", inline: true, value: includeUnmatchedRecurringInTotals ? 'show' : 'hide', onChange: (e, { value }) => {
                                setIncludeUnmatchedRecurringInTotals(value == 'show');
                            }, options: [
                                {
                                    key: 'show',
                                    value: 'show',
                                    text: 'Include',
                                },
                                {
                                    key: 'hide',
                                    value: 'hide',
                                    text: 'Exclude',
                                },
                            ] }),
                        React.createElement("span", null,
                            "unmatched recurring in totals",
                            ' ',
                            React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "help circle" }), inverted: true, size: "tiny" }, "Certain categories can be marked 'exclude from totals'. Hiding these transactions will remove them from view and from any totals calculations in the calendar view."))),
                    React.createElement("div", { className: "mt-05rem" },
                        React.createElement(semantic_ui_react_1.Dropdown, { className: "mr-05rem inline-breakdown-dropdown no-wrap", inline: true, value: recurringDisplay, onChange: (e, { value }) => {
                                localStorage.setItem('_lm_calendar_recurring_display', value);
                                setRecurringDisplay(value);
                            }, options: [
                                {
                                    key: 'hide',
                                    value: 'hide',
                                    text: 'Hide all',
                                },
                                {
                                    key: 'show',
                                    value: 'show',
                                    text: 'Show individual',
                                },
                                // {
                                //   key: 'categorize',
                                //   value: 'categorize',
                                //   text: 'Categorize',
                                // },
                                {
                                    key: 'consolidate',
                                    value: 'consolidate',
                                    text: 'Consolidate',
                                },
                            ] }),
                        React.createElement("span", null,
                            "recurring items",
                            ' ',
                            React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "help circle" }), inverted: true, size: "tiny" }, "Certain categories can be marked 'exclude from totals'. Hiding these transactions will remove them from view and from any totals calculations in the calendar view.")))) }),
            React.createElement(semantic_ui_react_1.Button.Group, null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        history.push({
                            pathname: `/calendar/${Moment(currentPeriod, 'YYYY-MM-DD')
                                .add(1, 'week') // for good measure
                                .startOf('month')
                                .format('YYYY/MM')}`,
                        });
                    }, basic: calendarDisplay !== 'month', color: "orange" }, "Month"),
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        history.push({
                            pathname: `/calendar/${Moment(currentPeriod, 'YYYY-MM-DD')
                                .startOf('week')
                                .format('YYYY/MM/DD')}`,
                        });
                    }, basic: calendarDisplay !== '2-week', color: "orange" }, "2 Week"))),
        React.createElement("div", { className: "table-container mt-1rem" },
            React.createElement(semantic_ui_react_1.Table, { fixed: true, celled: true, unstackable: true, className: "p-calendar-table" },
                React.createElement(semantic_ui_react_1.Table.Header, { className: "sticky" },
                    React.createElement(semantic_ui_react_1.Table.Row, null,
                        _user.settings['week_starts_on'] == 'sunday' && (React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "center-align" }, "Sunday")),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "center-align" }, "Monday"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "center-align" }, "Tuesday"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "center-align" }, "Wednesday"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "center-align" }, "Thursday"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "center-align" }, "Friday"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "center-align" }, "Saturday"),
                        _user.settings['week_starts_on'] == 'monday' && (React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "center-align" }, "Sunday")))),
                React.createElement(semantic_ui_react_1.Table.Body, { className: rows.length === 0 ? 'g-empty-body' : '' },
                    isLoading && React.createElement(Loader_1.default, { colSpan: 7 }),
                    !isLoading &&
                        (rows === null || rows === void 0 ? void 0 : rows.map((week, weekIndex) => {
                            return (React.createElement(semantic_ui_react_1.Table.Row, { key: `week-${weekIndex}` }, week.map((day, dayIndex) => {
                                return (React.createElement(Day_1.default, { key: `day-${dayIndex}-${weekIndex}`, day: day === null || day === void 0 ? void 0 : day.day, month: day === null || day === void 0 ? void 0 : day.month, year: day === null || day === void 0 ? void 0 : day.year, data: day, includeUnmatchedRecurringInTotals: includeUnmatchedRecurringInTotals, shouldTruncate: calendarDisplay == 'month', transactionsDisplay: transactionsDisplay, categoryGroupDisplay: categoryGroupDisplay, recurringDisplay: recurringDisplay, includeExcludeFromTotals: includeExcludeFromTotals, includePendingTransactions: includePendingTransactions, isToday: Moment().format('D') == (day === null || day === void 0 ? void 0 : day.day) &&
                                        Moment().format('MM') == (day === null || day === void 0 ? void 0 : day.month) &&
                                        Moment().format('YYYY') == (day === null || day === void 0 ? void 0 : day.year), showTransactions: dayObj => {
                                        setSelectedDay(dayObj);
                                        setOpenTransactionsTable(true);
                                    }, addTransaction: date => {
                                        _modal.data.current.date = date;
                                        setShowModal(true);
                                    } }));
                            })));
                        }))))),
        React.createElement(PopupTransactions_1.default, { openTransactionsTable: openTransactionsTable, onClose: () => __awaiter(void 0, void 0, void 0, function* () {
                setOpenTransactionsTable(false);
                if (shouldRefreshCalendar.current) {
                    yield generateCalendarRows();
                    shouldRefreshCalendar.current = false;
                }
            }), headerText: `${selectedDay === null || selectedDay === void 0 ? void 0 : selectedDay.title}${(selectedDay === null || selectedDay === void 0 ? void 0 : selectedDay.total) ? `${selectedDay === null || selectedDay === void 0 ? void 0 : selectedDay.total}` : ''}`, query: selectedDay === null || selectedDay === void 0 ? void 0 : selectedDay.query, _process: _process, _showToast: _showToast, _removeToast: _removeToast }),
        showModal && (React.createElement(NewModal_1.default, { show: showModal, setShow: (show) => {
                setShowModal(show);
            }, data: {
                onCreate,
                currentPeriod: currentPeriod,
            }, utils: {
                _process: _process,
                _showToast: _showToast,
            }, view: 'ADD_TRANSACTION', closeCallback: () => __awaiter(void 0, void 0, void 0, function* () {
                setShowModal(false);
            }) }))));
};
exports.default = Calendar;
