"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const tooltips_1 = require("@helpers/tooltips");
const react_chartjs_2_1 = require("react-chartjs-2");
const format_1 = require("@helpers/format");
const charts_1 = require("@helpers/charts");
const UserProvider_1 = require("@providers/UserProvider");
const chart_js_1 = require("chart.js");
const TrendsChart = ({ identifier, labels, datasets, tooltipData, displayCountAxis = false, displayPercentageAxis = false, }) => {
    const [freeze, setFreeze] = (0, react_1.useState)(false);
    const [chartRef, setChartRef] = (0, react_1.useState)(null);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    chart_js_1.Chart.register(...chart_js_1.registerables);
    (0, react_1.useEffect)(() => {
        var _a;
        const hideTooltip = e => {
            document.getElementById(`tooltip-year-${identifier}`).style.display =
                'none';
        };
        (_a = document
            .getElementById(`chart-container-${identifier}`)) === null || _a === void 0 ? void 0 : _a.addEventListener('mouseleave', hideTooltip);
        return () => {
            var _a;
            (_a = document
                .getElementById(`chart-container-${identifier}`)) === null || _a === void 0 ? void 0 : _a.removeEventListener('mouseleave', hideTooltip);
        };
    }, []);
    (0, react_1.useEffect)(() => {
        setFreeze(false);
    }, [datasets]);
    const scaleDataAxesToUnifyZeroes = chart => {
        setFreeze(true);
        const datasets = chart.config.data.datasets;
        const options = chart.options;
        let axes = options.scales.yAxes.filter(o => o.display);
        if (axes.length > 1) {
            // Determine overall max/min values for each dataset
            datasets.forEach(function (line) {
                let axis = line.yAxisID
                    ? axes.filter(ax => ax.id === line.yAxisID)[0]
                    : axes[0];
                axis.min_value = Math.min(...line.data, axis.min_value || 0);
                axis.max_value = Math.max(...(line.data || 0), axis.max_value || 0);
            });
            // Which gives the overall range of each axis
            axes.forEach(axis => {
                axis.range = axis.max_value - axis.min_value;
                // Express the min / max values as a fraction of the overall range
                axis.min_ratio = axis.min_value / axis.range;
                axis.max_ratio = axis.max_value / axis.range;
            });
            // Find the largest of these ratios
            let largest = axes.reduce((a, b) => ({
                min_ratio: Math.min(a.min_ratio, b.min_ratio),
                max_ratio: Math.max(a.max_ratio, b.max_ratio),
            }));
            // Then scale each axis accordingly
            axes.forEach(axis => {
                axis.ticks = axis.ticks || {};
                axis.ticks.min = largest.min_ratio * axis.range;
                axis.ticks.max = largest.max_ratio * 1.1 * axis.range;
            });
        }
    };
    return (React.createElement("div", { className: "stats-year-chart-container", id: `chart-container-${identifier}` },
        React.createElement("div", { className: "stats-year-chart" },
            React.createElement(react_chartjs_2_1.Bar, { ref: reference => {
                    setChartRef(reference);
                }, data: {
                    labels,
                    datasets: datasets,
                }, options: {
                    plugins: Object.assign({ title: {
                            display: false,
                        }, legend: {
                            display: false,
                        }, tooltip: {
                            mode: 'index',
                            intersect: false,
                            enabled: false,
                            external: function (context) {
                                return (0, tooltips_1.getTrendsTooltip)(context.chart, context.tooltip, tooltipData, identifier);
                            },
                        } }, (identifier == '1'
                        ? {
                            afterUpdate: function (chart, easing) {
                                if (!freeze) {
                                    scaleDataAxesToUnifyZeroes(chart);
                                }
                            },
                        }
                        : {})),
                    maintainAspectRatio: false,
                    hover: {
                        mode: 'index',
                        intersect: false,
                    },
                    scales: {
                        x: {
                            grid: (0, charts_1.getGridLineColors)(),
                            ticks: Object.assign(Object.assign({}, (0, charts_1.getTickColors)()), { callback: function (value, index, values) {
                                    return labels[value];
                                } }),
                        },
                        price: {
                            type: 'linear',
                            position: 'left',
                            grid: (0, charts_1.getGridLineColors)(),
                            beginAtZero: true,
                            ticks: Object.assign(Object.assign({}, (0, charts_1.getTickColors)()), { maxTicksLimit: 7, sampleSize: 7, 
                                // Include a dollar sign in the ticks
                                callback: function (value, index, values) {
                                    if ((displayCountAxis || displayPercentageAxis) &&
                                        (index == 0 || index == values.length - 1)) {
                                        return '';
                                    }
                                    else {
                                        return (0, format_1.toPrice)(value, _user.primaryCurrency);
                                    }
                                } }),
                        },
                        count: {
                            display: displayCountAxis,
                            type: 'linear',
                            position: 'right',
                            border: { display: false },
                            beginAtZero: true,
                            ticks: Object.assign(Object.assign({}, (0, charts_1.getTickColors)()), { maxTicksLimit: 7, sampleSize: 7, precision: 0, callback: function (value, index, values) {
                                    if (index == 0 || index == values.length - 1) {
                                        return '';
                                    }
                                    else {
                                        return value;
                                    }
                                } }),
                        },
                        percentage: {
                            display: displayPercentageAxis,
                            type: 'linear',
                            position: 'right',
                            grid: (0, charts_1.getGridLineColors)(),
                            beginAtZero: true,
                            ticks: Object.assign(Object.assign({}, (0, charts_1.getTickColors)()), { maxTicksLimit: 7, sampleSize: 7, 
                                // Include a dollar sign in the ticks
                                callback: function (value, index, values) {
                                    if ((displayCountAxis || displayPercentageAxis) &&
                                        (index == 0 || index == values.length - 1)) {
                                        return '';
                                    }
                                    else {
                                        return (0, format_1.toPercentage)(Number(value), 2);
                                    }
                                } }),
                        },
                    },
                } }),
            React.createElement("div", { className: "chartjs-tooltip tooltip-year", id: `tooltip-year-${identifier}` }))));
};
exports.default = TrendsChart;
