"use strict";
/**
 *  AccountStatus.tsx
 *  Progress bar for a category in the budget summary card
 */
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_router_dom_1 = require("react-router-dom");
const format_1 = require("@helpers/format");
const AccountStatus = ({ account, flipSign }) => {
    return (React.createElement("div", { className: "card-content-wrapper" },
        React.createElement("div", { className: "card-content" },
            React.createElement("span", { className: `card-text ellipsis` }, account.display_name === 'Total Spent' ? (React.createElement("span", { className: "font--bold" }, "Total Spent")) : (React.createElement(react_router_dom_1.Link, { to: {
                    search: `?${account.plaid_account_id ? 'account' : 'asset'}=${account.plaid_account_id || account.asset_id || 'cash'}`,
                }, className: "link clickable" },
                account.plaid_account_id && React.createElement(semantic_ui_react_1.Icon, { name: "cloud download" }),
                ' ',
                account.display_name))),
            React.createElement("span", { className: `card-number ${flipSign && account.base_total < 0 ? 'color--green' : 'plain'} no-wrap` }, (0, format_1.toPrice)(flipSign ? -1 * account.base_total : account.base_total, account.baseCurrency)))));
};
exports.default = AccountStatus;
