"use strict";
/**
 *  AccountCard.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const UserProvider_1 = require("@providers/UserProvider");
const StaticProvider_1 = require("@providers/StaticProvider");
const format_1 = require("@/helpers/format");
const locales_1 = require("@/helpers/locales");
const Preferences = ({}) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _static = (0, react_1.useContext)(StaticProvider_1.StaticContext);
    const [supportedCurrencies, setSupportedCurrencies] = (0, react_1.useState)(_user.supportedCurrencies || []);
    const [useMulticurrency, setUseMulticurrency] = (0, react_1.useState)(_user.supportedCurrencies.length > 1);
    const [flipSign, setFlipSign] = (0, react_1.useState)(_user.settings['show_debits_as_negative']);
    return (React.createElement("div", { id: "setup-preferences" },
        React.createElement("div", { className: "header-container" },
            React.createElement("span", { className: "step-label" }, "Step 2"),
            React.createElement(semantic_ui_react_1.Header, { as: "h2" }, "Set your preferences")),
        React.createElement(semantic_ui_react_1.Header, { as: "h3" }, "Which notation looks more natural to you?"),
        React.createElement("div", { className: "display--flex width-100" },
            React.createElement("div", { className: `setup-category ${flipSign ? 'added' : ''}`, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    _user.updateSetting('show_debits_as_negative', true);
                    setFlipSign(true);
                }) },
                flipSign && React.createElement(semantic_ui_react_1.Icon, { name: "check circle" }),
                !flipSign && React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }),
                React.createElement("span", { className: "note" },
                    "Debits are shown as negative.",
                    React.createElement("br", null),
                    React.createElement("span", { className: "color--green" }, "Credits"),
                    " are shown as positive."),
                React.createElement(semantic_ui_react_1.Image, { centered: true, size: "medium", className: "mt-0rem", src: "/public/images/notation-1.png" })),
            React.createElement("div", { className: `setup-category ${flipSign ? '' : 'added'}`, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    _user.updateSetting('show_debits_as_negative', false);
                    setFlipSign(false);
                }) },
                !flipSign && React.createElement(semantic_ui_react_1.Icon, { name: "check circle" }),
                flipSign && React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }),
                React.createElement("span", { className: "note" },
                    "Debits are shown as positive.",
                    React.createElement("br", null),
                    React.createElement("span", { className: "color--green" }, "Credits"),
                    " are shown as negative."),
                React.createElement(semantic_ui_react_1.Image, { centered: true, size: "medium", className: "mt-0rem", src: "/public/images/notation-2.png" }))),
        React.createElement(semantic_ui_react_1.Header, { as: "h3" }, "Will you be tracking expenses in multiple currencies?"),
        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: useMulticurrency, onChange: (e, { checked }) => {
                setUseMulticurrency(checked);
            }, label: useMulticurrency ? 'Yes' : 'No' }),
        useMulticurrency && (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "mb-05rem mt-05rem" }, "What currencies will you be tracking in? You can always update this later!"),
            React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal", placeholder: "Select currencies", fluid: true, multiple: true, search: true, selection: true, value: supportedCurrencies, onChange: (e, { value }) => {
                    setSupportedCurrencies(value);
                    _user.updateAccountSettings({
                        supported_currencies: value.join(','),
                    });
                }, options: _static.allCurrencies }))),
        React.createElement(semantic_ui_react_1.Header, { as: "h3" }, "Which day of the week should the calendar start on?"),
        React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal settings-dropdown", placeholder: "", selection: true, fluid: true, value: _user.settings['week_starts_on'], onChange: (e, { value }) => {
                _user.updateSetting('week_starts_on', value);
            }, options: ['sunday', 'monday'].map(day => {
                return {
                    key: day,
                    value: day,
                    text: (0, format_1.capitalize)(day),
                };
            }) }),
        React.createElement(semantic_ui_react_1.Header, { as: "h3" }, "Which date format looks best for: month, day and year?"),
        React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal settings-dropdown", placeholder: "Select display format", selection: true, fluid: true, value: _user.settings['month_day_year_format'], onChange: (e, { value }) => {
                _user.updateSetting('month_day_year_format', value);
            }, options: locales_1.MONTH_DAY_YEAR_FORMATS.map(format => {
                return {
                    key: format,
                    value: format,
                    text: `${format} (ex: ${Moment('2022-01-31').format(format)} and ${Moment('2022-12-01').format(format)})`,
                };
            }) }),
        React.createElement(semantic_ui_react_1.Header, { as: "h3" }, "Which date format looks best for: day and month?"),
        React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal settings-dropdown", placeholder: "Select display format", selection: true, fluid: true, value: _user.settings['month_day_format'], onChange: (e, { value }) => {
                _user.updateSetting('month_day_format', value);
            }, options: locales_1.MONTH_DAY_FORMATS.map(format => {
                return {
                    key: format,
                    value: format,
                    text: `${format} (ex: ${Moment('2022-01-31').format(format)} and ${Moment('2022-12-01').format(format)})`,
                };
            }) })));
};
exports.default = Preferences;
