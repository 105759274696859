"use strict";
/**
 *  Transactions.tsx
 *  Container for the Transactions page
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const _ = require("lodash");
const qs = require("query-string");
const Card_1 = require("../components/elements/Card");
// Semantic UI
const semantic_ui_react_1 = require("semantic-ui-react");
const PlaidConfirmPopup_1 = require("@components/LinkedAccounts/PlaidConfirmPopup");
const Link_1 = require("@components/Plaid/Link");
const react_router_dom_1 = require("react-router-dom");
const plaid_1 = require("@actions/plaid");
const plaid_2 = require("@actions/plaid");
const NewModal_1 = require("@components/global/NewModal");
const Sticky_1 = require("@components/elements/Sticky");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const LinkedAccounts_1 = require("@components/TaskCard/LinkedAccounts");
const AccountRow_1 = require("@components/LinkedAccounts/AccountRow");
const Loader_1 = require("@components/global/Loader");
const ColumnResizer_1 = require("@components/elements/ColumnResizer");
const format_1 = require("@helpers/format");
const UserProvider_1 = require("@providers/UserProvider");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const ConnectContainer_1 = require("@components/LinkedAccounts/ConnectContainer");
const PopupTransactions_1 = require("@/components/Transactions/PopupTransactions");
const LinkedAccounts = ({ _removeToast, _showToast, _process }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const history = (0, react_router_dom_1.useHistory)();
    const [fetchId, setFetchId] = (0, react_1.useState)(null);
    const [plaidLinkOpts, setPlaidLinkOpts] = (0, react_1.useState)(null);
    const [showRelinkModal, setShowRelinkModal] = (0, react_1.useState)(null);
    const [accountsAndAssets, setAccountsAndAssets] = (0, react_1.useState)([]);
    // sorting
    const [sortColumn, setSortColumn] = (0, react_1.useState)(null);
    const [sortDirection, setSortDirection] = (0, react_1.useState)(null);
    const [totals, setTotals] = (0, react_1.useState)({});
    //
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    const [loadingId, setLoadingId] = (0, react_1.useState)(null);
    //
    const [showModal, setShowModal] = (0, react_1.useState)(false);
    const [modalView, setModalView] = (0, react_1.useState)(null);
    const [showModalData, setShowModalData] = (0, react_1.useState)(null);
    //
    const [showPlaidLinkConfirmation, setShowPlaidLinkConfirmation] = (0, react_1.useState)(false);
    const [plaidMetadata, setPlaidMetadata] = (0, react_1.useState)({
        institution: {
            name: 'Discover',
            institution_id: 'ins_33',
        },
        newlyMissingAccounts: [],
        prevMissingAccounts: [],
        newAccountsDetected: [
        // {
        //   id: 'xoJ3Ex1JyNsv3DDX36D3hXogJ5P5Xwt6K4R5a',
        //   name: 'Plaid Checking',
        //   mask: '0000',
        //   type: 'depository',
        //   subtype: 'checking',
        //   verification_status: null,
        //   class_type: null,
        // },
        // {
        //   id: 'dKrPGgvrBytwDBBkDyBDugd5DkZkgLHJnEx8j',
        //   name: 'Plaid Saving',
        //   mask: '1111',
        //   type: 'depository',
        //   subtype: 'savings',
        //   verification_status: null,
        //   class_type: null,
        // },
        // {
        //   id: 'aAlaokvlKnuJyGGKynGycNKj1343NzfZK5xj9',
        //   name: 'Plaid CD',
        //   mask: '2222',
        //   type: 'depository',
        //   subtype: 'cd',
        //   verification_status: null,
        //   class_type: null,
        // },
        // {
        //   id: '44PQM6NPDEIQMvv5MLvMfMpdB4J4MeCJRbw61',
        //   name: 'Plaid Credit Card',
        //   mask: '3333',
        //   type: 'credit',
        //   subtype: 'credit card',
        //   verification_status: null,
        //   class_type: null,
        // },
        // {
        //   id: 'Nd9QAqv964HaNddwN4dNcyBPEGdGy3fyA6qlv',
        //   name: 'Plaid Money Market',
        //   mask: '4444',
        //   type: 'depository',
        //   subtype: 'money market',
        //   verification_status: null,
        //   class_type: null,
        // },
        // {
        //   id: 'P4wMmrvw6PIwNRRbNPRNuN67RxJxNZfoy6rx1',
        //   name: 'Plaid IRA',
        //   mask: '5555',
        //   type: 'investment',
        //   subtype: 'ira',
        //   verification_status: null,
        //   class_type: null,
        // },
        // {
        //   id: 'jdaMwpBaNDHmVWW9VgWVFwaQZkXkw9c6RW8N7',
        //   name: 'Plaid 401k',
        //   mask: '6666',
        //   type: 'investment',
        //   subtype: '401k',
        //   verification_status: null,
        //   class_type: null,
        // },
        // {
        //   id: '7je18P7emGhBGJJvGxJGs7qjmGNG7WhdBJKEl',
        //   name: 'Plaid Student Loan',
        //   mask: '7777',
        //   type: 'loan',
        //   subtype: 'student',
        //   verification_status: null,
        //   class_type: null,
        // },
        // {
        //   id: 'erzjb6lz39Tq1EEG19E1fKJz7oAoKmHr39lqD',
        //   name: 'Plaid Mortgage',
        //   mask: '8888',
        //   type: 'loan',
        //   subtype: 'mortgage',
        //   verification_status: null,
        //   class_type: null,
        // },
        ],
    });
    const [plaidPublicToken, setPlaidPublicToken] = (0, react_1.useState)(null);
    //
    const [accountsUI, setAccountsUI] = (0, react_1.useState)(null);
    //
    const [connectDefaultName, setConnectDefaultName] = (0, react_1.useState)(null);
    const [showConnectContainer, setShowConnectContainer] = (0, react_1.useState)(false);
    //
    // Views
    const [groupBy, setGroupBy] = (0, react_1.useState)(localStorage.getItem('_lm_account_group_by') || 'institution');
    //
    const [openTransactionsTable, setOpenTransactionsTable] = (0, react_1.useState)(false);
    const [selectedAccount, setSelectedAccount] = (0, react_1.useState)(null);
    const _accountFilter = localStorage.getItem('_lm_account_filter_object');
    const [accountFilter, setAccountFilter] = (0, react_1.useState)(_accountFilter
        ? JSON.parse(_accountFilter)
        : {
            synced: true,
            manual: true,
        });
    const [hasFilters, setHasFilters] = (0, react_1.useState)(false);
    const _accountSubfilter = localStorage.getItem('_lm_account_subfilter_object');
    const [accountSubfilter, setAccountSubfilter] = (0, react_1.useState)(_accountSubfilter
        ? JSON.parse(_accountSubfilter)
        : {
            active: true,
            inactive: true,
            error: true,
            closed: true,
            unsupported: true,
            pending: true,
        });
    const _typeFilter = localStorage.getItem('_lm_account_type_filter_object');
    const [typeFilter, setTypeFilter] = (0, react_1.useState)(_typeFilter
        ? JSON.parse(_typeFilter)
        : {
            credit: true,
            investment: true,
            loan: true,
            'real estate': true,
            vehicle: true,
            cryptocurrency: true,
            'employee compensation': true,
            'other liability': true,
            'other asset': true,
            cash: true,
        });
    const [showFilter, setShowFilter] = (0, react_1.useState)(false);
    const [showSidebar, setShowSidebar] = (0, react_1.useState)(false);
    const _collapsed = localStorage.getItem('_lm_accounts_collapsed_obj');
    const [collapsed, setCollapsed] = (0, react_1.useState)(_collapsed ? JSON.parse(_collapsed) : {});
    const initRelink = plaid_account_id => {
        // What other plaid_accounts are under this one's connection?
        const siblings = _assets.plaidAccounts.filter(account => {
            return (account.access_token ==
                _assets.plaidAccounts.find(o => o.id == plaid_account_id).access_token);
        });
        setShowRelinkModal({
            activeSiblings: siblings.filter(o => o.status == 'active'),
            inactiveSiblings: siblings.filter(o => o.status !== 'active'),
            plaid_account_id,
            institution_name: siblings[0].institution_name,
        });
    };
    const [accountCount, setAccountCount] = (0, react_1.useState)({
        synced: 0,
        manual: 0,
        error: 0,
        active: 0,
        inactive: 0,
        pending: 0,
        closed: 0,
        unsupported: 0,
    });
    const [accountTypes, setAccountTypes] = (0, react_1.useState)([]);
    const [lastUpdatedWidth, setLastUpdatedWidth] = (0, react_1.useState)(null);
    const [displayNameWidth, setDisplayNameWidth] = (0, react_1.useState)(null);
    const [balanceWidth, setBalanceWidth] = (0, react_1.useState)(null);
    const [statusWidth, setStatusWidth] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        // removeLargestWidth
        const columns = [
            // 'type',
            'display-name',
            'balance',
            'last-updated',
            'status',
        ];
        let missing = false;
        let maxCol;
        let maxWidth = 0;
        columns.forEach(col => {
            if (localStorage.getItem(`accounts-v2-${col}`) == null) {
                missing = true;
            }
            else {
                const width = parseInt(localStorage.getItem(`accounts-v2-${col}`) || '0');
                if (width > maxWidth) {
                    maxCol = col;
                    maxWidth = width;
                }
            }
        });
        // Only do this if all widths are set
        if (!missing) {
            localStorage.removeItem(`accounts-${maxCol}`);
        }
        // Figure out which one is the largest
        // setTypeWidth(localStorage.getItem(`accounts-type`))
        setLastUpdatedWidth(localStorage.getItem(`accounts-v2-last-updated`));
        setDisplayNameWidth(localStorage.getItem(`accounts-v2-display-name`));
        setBalanceWidth(localStorage.getItem(`accounts-v2-balance`));
        setStatusWidth(localStorage.getItem(`accounts-v2-status`));
        const listener = event => {
            var _a;
            if ((_a = document
                .getElementById('filters-container')) === null || _a === void 0 ? void 0 : _a.contains(event.target)) {
                return;
            }
            setShowFilter(false);
        };
        document.addEventListener('mousedown', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
        };
    }, []);
    (0, react_1.useEffect)(() => {
        localStorage.setItem('_lm_account_filter_object', JSON.stringify(accountFilter));
        localStorage.setItem('_lm_account_subfilter_object', JSON.stringify(accountSubfilter));
        setHasFilters([
            ...Object.values(accountFilter),
            ...Object.values(accountSubfilter),
            ...Object.values(typeFilter),
        ].indexOf(false) > -1);
    }, [accountFilter, accountSubfilter, typeFilter]);
    (0, react_1.useEffect)(() => {
        if (!hasFilters) {
            setAccountCount(_assets.getCounts());
        }
    }, [hasFilters]);
    const _sort = (array, column, direction) => {
        if (!column) {
            column = 'institution_name';
        }
        const sorted = array.sort((a, b) => {
            var _a, _b;
            if (column == 'institution_name') {
                const compareA = ((_a = [a['institution_name'], a['name']].join(' ')) === null || _a === void 0 ? void 0 : _a.trim()) || '';
                const compareB = ((_b = [b['institution_name'], b['name']].join(' ')) === null || _b === void 0 ? void 0 : _b.trim()) || '';
                return compareA.toLowerCase().localeCompare(compareB.toLowerCase());
            }
            else if (column == 'display_name') {
                const compareA = [a['institution_name'], a['name']].join(' ');
                const compareB = [b['institution_name'], b['name']].join(' ');
                if (compareA == a.display_name) {
                    return 1;
                }
                else if (compareB == b.display_name) {
                    return -1;
                }
                return a.display_name
                    .toLowerCase()
                    .localeCompare(b.display_name.toLowerCase());
            }
            else if (column == 'to_base') {
                if (a.status == 'closed') {
                    return -1;
                }
                if (
                // a.status !== 'active' ||
                typeof a[column] == 'undefined' ||
                    a[column] == null)
                    return -1;
                if (
                // b.status !== 'active' ||
                typeof b[column] == 'undefined' ||
                    b[column] == null)
                    return 1;
                return a[column] - b[column];
            }
            else if (typeof a[column] === 'string') {
                if (typeof b[column] == 'undefined') {
                    return 1;
                }
                return a[column].toLowerCase().localeCompare(b[column].toLowerCase());
            }
        });
        if (direction === 'descending') {
            return sorted.reverse();
        }
        else {
            return sorted;
        }
    };
    /**
     * Handles sorting account table
     */
    const handleSort = (clickedColumn) => () => {
        if (sortColumn !== clickedColumn) {
            setSortColumn(clickedColumn);
            const sorted = _sort(accountsAndAssets, clickedColumn, 'ascending');
            setAccountsAndAssets([...sorted]);
            setSortDirection('ascending');
        }
        else {
            setAccountsAndAssets([...accountsAndAssets.reverse()]);
            setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
        }
    };
    (0, react_1.useEffect)(() => {
        if (!!fetchId && _assets.lastUpdate === fetchId) {
            refreshAccounts();
            setFetchId(null);
        }
    }, [_assets, fetchId]);
    /**
     * Refresh accounts from server and recalculate totals
     */
    const refreshAccounts = () => __awaiter(void 0, void 0, void 0, function* () {
        const currentAssets = [
            ..._assets.assets,
            ..._assets.plaidAccounts,
            ..._assets.crypto,
            ..._assets.syncedCrypto,
            ..._assets.manualCrypto,
        ];
        const totals = { total: 0 };
        const types = [];
        // Group by type and calculate totals
        currentAssets.forEach(account => {
            if (account &&
                account.hasOwnProperty('to_base') &&
                account.to_base !== null &&
                !isNaN(account.to_base) &&
                (account.status === 'active' || account.status === 'relink')) {
                if (totals.hasOwnProperty(account.type)) {
                    totals[account.type].sum += account.to_base;
                    totals[account.type].count += 1;
                }
                else {
                    totals[account.type] = { sum: account.to_base, count: 1 };
                }
                if (account.type !== 'credit') {
                    totals.total += account.to_base;
                }
                else {
                    totals.total -= account.to_base;
                }
            }
            types.push(account.type);
        });
        // When refreshing, preserve sort
        setAccountsAndAssets(_sort(currentAssets, sortColumn, sortDirection));
        setTotals(totals);
        setAccountTypes(_.uniq(types));
        setLoadingId(null);
        setIsLoading(false);
        setAccountCount(_assets.getCounts());
        // Is the modal open?
        if ((showModalData === null || showModalData === void 0 ? void 0 : showModalData.id) && (showModalData === null || showModalData === void 0 ? void 0 : showModalData.source) == 'plaid') {
            // update with latest
            setShowModalData(currentAssets.find(o => o.id == showModalData.id && o.source == 'plaid'));
        }
    });
    /**
     * After linking, add rows to reflect new accounts that were just linked
     */
    const addLoadingAccount = (accounts, institution) => {
        const loadingAccounts = accounts.map(account => {
            return {
                institution_name: institution.name,
                name: account.name,
                display_name: account.display_name || account.name,
                import_start_date: account.import_start_date,
                type: account.type,
                subtype: account.subtype,
                amount: '-',
                currency: null,
                source: 'plaid',
                status: 'processing',
            };
        });
        setAccountCount(Object.assign(Object.assign({}, accountCount), { pending: accountCount.pending + accounts.length }));
        setAccountsAndAssets([...loadingAccounts, ...accountsAndAssets]);
    };
    // Manually add account
    const addAccount = () => {
        setShowModal(true);
        setModalView('ADD_LINKED_ACCOUNT');
    };
    const init = () => __awaiter(void 0, void 0, void 0, function* () {
        yield triggerFetch();
        refreshAccounts();
    });
    const triggerFetch = () => __awaiter(void 0, void 0, void 0, function* () {
        // This will trigger a refresh
        const time = new Date().getTime();
        setFetchId(time);
        yield _assets.fetchAssets(time);
    });
    const initPlaid = (public_token_1, metadata_1, ...args_1) => __awaiter(void 0, [public_token_1, metadata_1, ...args_1], void 0, function* (public_token, metadata, is_relink = false) {
        _showToast({
            message: 'Attempting to connect to your institution, please wait...',
            type: 'info',
        });
        (0, plaid_1.initialize)({
            public_token: public_token,
            institution: metadata.institution,
            accounts: metadata.newAccountsDetected, // Only sending new accounts detected
        });
        document.body.style.overflow = 'auto'; // fail safe?
        if (is_relink) {
            _showToast({
                message: 'Relink successfully attempted, come back to this page in a minute for updates!',
                type: 'success',
                autoDismissTimeout: 9000,
            });
            setShowModal(false);
        }
        else {
            _showToast({
                message: 'Successfully linked new accounts. It may take a few minutes to set up and fetch your transactions. Check back soon for updates!',
                type: 'success',
                autoDismissTimeout: 9000,
            });
        }
        addLoadingAccount(metadata.newAccountsDetected, metadata.institution);
        setTimeout(() => {
            triggerFetch();
        }, 9000);
        setShowPlaidLinkConfirmation(false);
        setAccountSubfilter(Object.assign(Object.assign({}, accountSubfilter), { pending: true }));
    });
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = 'Accounts - Lunch Money';
        init();
    }, []);
    (0, react_1.useEffect)(() => {
        const accountRows = [];
        const tempCount = {
            synced: 0,
            manual: 0,
            error: 0,
            active: 0,
            inactive: 0,
            pending: 0,
            unsupported: 0,
            closed: 0,
            credit: 0,
            investment: 0,
            loan: 0,
            'real estate': 0,
            vehicle: 0,
            cryptocurrency: 0,
            'employee compensation': 0,
            'other liability': 0,
            'other asset': 0,
            cash: 0,
        };
        const filteredAccounts = accountsAndAssets.filter(o => {
            if (!typeFilter[o.type == 'depository' ? 'cash' : o.type]) {
                return false;
            }
            else if (!accountFilter['synced'] &&
                (o.source == 'plaid' || o.source == 'zabo')) {
                return false;
            }
            else if (!accountFilter['manual'] && o.source == 'manual') {
                return false;
            }
            else if (!accountSubfilter['active'] && o.status == 'active') {
                return false;
            }
            else if (!accountSubfilter['inactive'] && o.status == 'inactive') {
                return false;
            }
            else if (!accountSubfilter['error'] &&
                (o.status == 'relink' || o.status == 'error')) {
                return false;
            }
            else if (!accountSubfilter['unsupported'] &&
                o.status == 'not supported') {
                return false;
            }
            else if (!accountSubfilter['closed'] && o.status == 'closed') {
                return false;
            }
            else if (!accountSubfilter['pending'] &&
                (o.status == 'syncing' ||
                    o.status == 'deleting' ||
                    o.status == 'processing')) {
                return false;
            }
            else {
                // Update temp count
                if (o.source.indexOf('manual') > -1) {
                    tempCount.manual++;
                }
                else {
                    tempCount.synced++;
                }
                if (['error', 'relink'].indexOf(o.status) > -1) {
                    tempCount.error++;
                }
                else if (o.status == 'active') {
                    tempCount.active++;
                }
                else if (o.status == 'inactive') {
                    tempCount.inactive++;
                }
                else if (o.status == 'not supported') {
                    tempCount.unsupported++;
                }
                else if (o.status == 'syncing' || o.status == 'deleting') {
                    tempCount.pending++;
                }
                else if (o.status == 'closed') {
                    tempCount.closed++;
                }
                if (o.type == 'depository') {
                    tempCount['cash']++;
                }
                else {
                    tempCount[o.type]++;
                }
                return true;
            }
        });
        if (groupBy == 'none') {
            filteredAccounts.forEach((account, index) => {
                accountRows.push(React.createElement(AccountRow_1.default, { key: `account-row-${index}`, account: account, loading: account.institution_id === loadingId, groupBy: groupBy, isParent: false, openConnect: provider => {
                        setConnectDefaultName(provider);
                        setShowConnectContainer(true);
                    }, openRelink: plaid_account_id => {
                        initRelink(plaid_account_id);
                    }, openModal: (view = null) => {
                        setShowModal(true);
                        setModalView(view);
                        setShowModalData(account);
                    }, openTable: account => {
                        setSelectedAccount(account);
                        setOpenTransactionsTable(true);
                    } }));
            });
        }
        else {
            const nonPlaid = { accounts: [] };
            const plaid = { accounts: [] };
            for (let i = 0; i < filteredAccounts.length; i++) {
                if (groupBy == 'source') {
                    // Under source, combine manual crypto and manually-managed accounts together
                    if (filteredAccounts[i].source.indexOf('manual') > -1) {
                        filteredAccounts[i].source = 'manual';
                    }
                }
                if (filteredAccounts[i].source !== 'plaid' ||
                    groupBy == 'type' ||
                    groupBy == 'source' ||
                    groupBy == 'status') {
                    nonPlaid.accounts.push(filteredAccounts[i]);
                    // Push everything to one array if it's groupBy == type
                }
                else {
                    plaid.accounts.push(filteredAccounts[i]);
                }
            }
            const GROUP_BY_MAPPING = {
                institution: 'institution_name',
                type: 'type',
                source: 'source',
                status: 'status',
            };
            const getCollapsedKey = account => {
                if (groupBy == 'institution') {
                    return account.source == 'plaid'
                        ? account.institution_id
                        : account.institution_name || 'No institution';
                }
                else if (groupBy == 'connection') {
                    return account.item_id;
                }
                return account[GROUP_BY_MAPPING[groupBy]];
            };
            const groupedNonPlaid = _.groupBy(nonPlaid.accounts, GROUP_BY_MAPPING[groupBy]);
            const groupedPlaid = _.groupBy(plaid.accounts, 'access_token');
            // Build New
            const accountsWithGroup = [];
            const allAccounts = Object.assign(Object.assign({}, groupedNonPlaid), groupedPlaid);
            Object.keys(allAccounts).forEach(groupId => {
                accountsWithGroup.push({
                    type: allAccounts[groupId][0].type,
                    status: allAccounts[groupId][0].status,
                    item_id: allAccounts[groupId][0].item_id,
                    source: allAccounts[groupId][0].source,
                    date_linked: allAccounts[groupId][0].date_linked,
                    linked_by_name: allAccounts[groupId][0].linked_by_name,
                    institution_name: allAccounts[groupId][0].institution_name,
                    institution_id: allAccounts[groupId][0].institution_id,
                    accounts: allAccounts[groupId],
                });
            });
            accountsWithGroup
                .sort((a, b) => {
                return (a[GROUP_BY_MAPPING[groupBy]] || '').localeCompare(b[GROUP_BY_MAPPING[groupBy]] || '');
            })
                .map((group, index) => {
                if (groupBy == 'institution' ||
                    (groupBy !== 'institution' &&
                        groupBy !== null &&
                        groupBy !== 'null')) {
                    accountRows.push(React.createElement(AccountRow_1.default, { key: `group-account-row-${getCollapsedKey(group)}-${index}`, account: group, loading: group.institution_id === loadingId, isParent: true, groupBy: groupBy, openConnect: provider => {
                            setConnectDefaultName(provider);
                            setShowConnectContainer(true);
                        }, openRelink: plaid_account_id => {
                            initRelink(plaid_account_id);
                        }, setCollapsed: () => {
                            const newValue = Object.assign(Object.assign({}, collapsed), { [getCollapsedKey(group)]: !collapsed[getCollapsedKey(group)] });
                            localStorage.setItem('_lm_accounts_collapsed_obj', JSON.stringify(newValue));
                            setCollapsed(newValue);
                        }, isCollapsed: collapsed[getCollapsedKey(group)], openModal: (view = null) => {
                            setShowModal(true);
                            setModalView(view);
                            setShowModalData(group);
                        }, openTable: account => {
                            setSelectedAccount(account);
                            setOpenTransactionsTable(true);
                        } }));
                }
                if (!collapsed[getCollapsedKey(group)]) {
                    group.accounts.forEach((account, index2) => {
                        accountRows.push(React.createElement(AccountRow_1.default, { key: `sub-account-row-${getCollapsedKey(group)}-${index}-${index2}`, account: account, loading: account.institution_id === loadingId, isParent: false, groupBy: groupBy, openConnect: provider => {
                                setConnectDefaultName(provider);
                                setShowConnectContainer(true);
                            }, openRelink: plaid_account_id => {
                                initRelink(plaid_account_id);
                            }, openModal: (view = null) => {
                                setShowModal(true);
                                setModalView(view);
                                setShowModalData(account);
                            }, openTable: account => {
                                setSelectedAccount(account);
                                setOpenTransactionsTable(true);
                            } }));
                    });
                }
            });
        }
        setAccountCount(tempCount);
        setAccountsUI(accountRows);
    }, [
        accountFilter,
        accountSubfilter,
        typeFilter,
        loadingId,
        accountsAndAssets,
        collapsed,
        groupBy,
    ]);
    const resetFilters = () => {
        setAccountFilter({
            synced: true,
            manual: true,
        });
        setAccountSubfilter({
            active: true,
            inactive: true,
            error: true,
            closed: true,
            unsupported: true,
            pending: true,
        });
        setTypeFilter({
            credit: true,
            investment: true,
            loan: true,
            'real estate': true,
            vehicle: true,
            cryptocurrency: true,
            'employee compensation': true,
            'other liability': true,
            'other asset': true,
            cash: true,
        });
    };
    return (React.createElement(semantic_ui_react_1.Container, { className: "g-linked-accounts" },
        React.createElement(ContainerHeader_1.default, { title: "Accounts" }),
        React.createElement(ConnectContainer_1.default, { show: !!showConnectContainer, defaultName: connectDefaultName, closeModal: () => __awaiter(void 0, void 0, void 0, function* () {
                setShowConnectContainer(null);
                setConnectDefaultName(null);
                yield triggerFetch();
            }), _process: _process, _showToast: _showToast }),
        React.createElement("div", { className: "width-100 flex--space-between-flex-end" },
            React.createElement(semantic_ui_react_1.Button, { onClick: addAccount }, "Add account"),
            React.createElement("div", { className: "display--flex" },
                React.createElement("div", { className: "mr-05rem" },
                    React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-05rem view-dropdown jump-dropdown", text: `Group by: ${groupBy}` },
                        React.createElement(semantic_ui_react_1.Dropdown.Menu, null, [
                            'institution',
                            'source',
                            'status',
                            'type',
                            'connection',
                            'none',
                        ].map(group => (React.createElement(semantic_ui_react_1.Dropdown.Item, { key: group, value: group, selected: groupBy === group, content: (0, format_1.capitalize)(group), onClick: (e, { value }) => {
                                localStorage.setItem('_lm_account_group_by', group);
                                setGroupBy(group);
                            } })))))),
                ((_a = _assets === null || _assets === void 0 ? void 0 : _assets.plaidAccounts) === null || _a === void 0 ? void 0 : _a.length) ||
                    ((_b = _assets === null || _assets === void 0 ? void 0 : _assets.crypto) === null || _b === void 0 ? void 0 : _b.length) ||
                    _assets.getCounts().closed > 0 ? (React.createElement(React.Fragment, null,
                    React.createElement(semantic_ui_react_1.Button.Group, null,
                        React.createElement(semantic_ui_react_1.Popup, { wide: true, position: "bottom right", hoverable: true, on: "click", basic: true, open: showFilter, trigger: React.createElement(semantic_ui_react_1.Button, { icon: true, basic: !hasFilters, color: "orange", onClick: () => {
                                    setShowFilter(!showFilter);
                                } },
                                React.createElement("div", { className: "display--flex filter-button-content" },
                                    React.createElement(semantic_ui_react_1.Icon, { name: "filter" }),
                                    "Filters")), content: React.createElement("div", { id: "filters-container" },
                                React.createElement("p", { className: "label-style" }, "Show:"),
                                ['synced', 'manual'].map(filter => {
                                    return (React.createElement("div", { key: `filter-${filter}` },
                                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: accountFilter[filter], label: `${(0, format_1.capitalize)(filter)} accounts ${accountFilter[filter]
                                                ? `(${accountCount[filter]})`
                                                : ''}`, onChange: (e, { checked }) => {
                                                setAccountFilter(Object.assign(Object.assign({}, accountFilter), { [filter]: checked }));
                                            } })));
                                }),
                                React.createElement("p", { className: "divider-line mt-05rem" },
                                    React.createElement("span", null, "and")),
                                React.createElement("div", { className: "mb-05rem" },
                                    React.createElement("span", { onClick: () => {
                                            setAccountSubfilter({
                                                active: true,
                                                inactive: true,
                                                error: true,
                                                closed: true,
                                                unsupported: true,
                                                pending: true,
                                            });
                                        }, className: "link clickable" }, "Select all"),
                                    ' ',
                                    "|",
                                    ' ',
                                    React.createElement("span", { onClick: () => {
                                            setAccountSubfilter({
                                                active: false,
                                                inactive: false,
                                                error: false,
                                                closed: false,
                                                unsupported: false,
                                                pending: false,
                                            });
                                        }, className: "link clickable" }, "Deselect all")),
                                [
                                    'active',
                                    'inactive',
                                    'error',
                                    'closed',
                                    'unsupported',
                                    'pending',
                                ].map(subfilter => {
                                    if (_assets.getCounts()[subfilter] > 0) {
                                        return (React.createElement("div", { key: `subfilter-${subfilter}` },
                                            React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: accountSubfilter[subfilter], onChange: (e, { checked }) => {
                                                    setAccountSubfilter(Object.assign(Object.assign({}, accountSubfilter), { [subfilter]: checked }));
                                                }, label: `${(0, format_1.capitalize)(subfilter)} accounts ${accountSubfilter[subfilter]
                                                    ? `(${accountCount[subfilter]})`
                                                    : ''}` })));
                                    }
                                    else {
                                        return null;
                                    }
                                }),
                                React.createElement("p", { className: "divider-line mt-05rem" },
                                    React.createElement("span", null, "and")),
                                React.createElement("div", { className: "mb-05rem" },
                                    React.createElement("span", { onClick: () => {
                                            setTypeFilter({
                                                credit: true,
                                                investment: true,
                                                loan: true,
                                                'real estate': true,
                                                vehicle: true,
                                                cryptocurrency: true,
                                                'employee compensation': true,
                                                'other liability': true,
                                                'other asset': true,
                                                cash: true,
                                            });
                                        }, className: "link clickable" }, "Select all"),
                                    ' ',
                                    "|",
                                    ' ',
                                    React.createElement("span", { onClick: () => {
                                            setTypeFilter({
                                                credit: false,
                                                investment: false,
                                                loan: false,
                                                'real estate': false,
                                                vehicle: false,
                                                cryptocurrency: false,
                                                'employee compensation': false,
                                                'other liability': false,
                                                'other asset': false,
                                                cash: false,
                                            });
                                        }, className: "link clickable" }, "Deselect all")),
                                accountTypes.map(type => {
                                    if (type == 'total' || _assets.getCounts()[type] == 0) {
                                        return;
                                    }
                                    else if (type == 'depository') {
                                        type = 'cash';
                                    }
                                    return (React.createElement("div", { key: `type-${type}` },
                                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: typeFilter[type], onChange: (e, { checked }) => {
                                                setTypeFilter(Object.assign(Object.assign({}, typeFilter), { [type]: checked }));
                                            }, label: `${(0, format_1.capitalize)(type)} accounts ${typeFilter[type]
                                                ? `(${accountCount[type]})`
                                                : ''}` })));
                                })) })))) : (React.createElement(React.Fragment, null)),
                ((_c = _assets === null || _assets === void 0 ? void 0 : _assets.plaidAccounts) === null || _c === void 0 ? void 0 : _c.length) ||
                    ((_d = _assets === null || _assets === void 0 ? void 0 : _assets.crypto) === null || _d === void 0 ? void 0 : _d.length) ||
                    ((_e = _assets === null || _assets === void 0 ? void 0 : _assets.syncedCrypto) === null || _e === void 0 ? void 0 : _e.length) ? (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Button, { icon: true, className: "ml-05rem", onClick: () => {
                            const results = _process(plaid_2.fetchTransactions)();
                            if (!results.error) {
                                setTimeout(() => {
                                    triggerFetch();
                                }, 9000);
                                _showToast({
                                    message: 'Syncing accounts in the background. This should be fairly instant but can sometimes take up to a minute or two!',
                                    type: 'success',
                                    autoDismissTimeout: 9000,
                                });
                            }
                        } },
                        React.createElement(semantic_ui_react_1.Icon, { name: "refresh" })) }, "Refresh synced accounts")) : (React.createElement(React.Fragment, null)),
                React.createElement(semantic_ui_react_1.Button, { className: "display-on-mobile", basic: !showSidebar, color: "orange", icon: true, style: { height: '38px' }, onClick: () => {
                        setShowSidebar(!showSidebar);
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: showSidebar ? 'angle double right' : 'angle double left' })))),
        React.createElement(Link_1.default, { showOpts: plaidLinkOpts, linkCallback: (public_token, metadata) => __awaiter(void 0, void 0, void 0, function* () {
                var _l, _m;
                if (((_l = metadata.newlyMissingAccounts) === null || _l === void 0 ? void 0 : _l.length) > 0) {
                    const results = yield (0, plaid_1.detectDeactivations)({
                        public_token,
                        missing_accounts: metadata.newlyMissingAccounts,
                    });
                    if (results.data) {
                        // Means there were deactivated accounts
                        triggerFetch();
                    }
                }
                setPlaidPublicToken(public_token);
                setPlaidMetadata(metadata);
                setPlaidLinkOpts({});
                // First and foremost, are there any new accounts detected?
                if (metadata.newAccountsDetected.length > 0) {
                    setShowPlaidLinkConfirmation(true);
                }
                else if (metadata.newlyMissingAccounts.length > 0) {
                    setShowRelinkModal({
                        institution_name: (_m = metadata.institution) === null || _m === void 0 ? void 0 : _m.name,
                        missingAccounts: metadata.newlyMissingAccounts,
                        activeSiblings: metadata.activeSiblings,
                        inactiveSiblings: metadata.inactiveSiblings,
                        plaid_account_id: plaidLinkOpts.relink,
                    });
                }
                else {
                    initPlaid(public_token, metadata, true);
                }
            }) }),
        showModal && (React.createElement(NewModal_1.default, { show: showModal, setShow: show => {
                setShowModal(show);
            }, data: {
                account: showModalData,
                plaidReady: true,
            }, utils: {
                _process: _process,
                _showToast: _showToast,
                launchPlaidLink: () => {
                    setPlaidLinkOpts({ link_new: true });
                },
                relinkAccount: plaid_account_id => {
                    initRelink(plaid_account_id);
                },
                openConnect: () => {
                    setShowConnectContainer(true);
                },
                setShowHelpModalAccount: val => {
                    history.push({
                        search: qs.stringify(Object.assign(Object.assign({}, qs.parse(history.location.search)), { sync_support: true, sync_account: val.id })),
                    });
                },
                triggerFetch: triggerFetch,
            }, view: modalView, closeCallback: () => {
                triggerFetch();
            } })),
        React.createElement("div", { className: "p-content-container" },
            React.createElement("div", { className: "left" },
                React.createElement("div", { className: "table-container" },
                    React.createElement(semantic_ui_react_1.Table, { unstackable: true, sortable: true, fixed: true, selectable: true, celled: true, className: "p-accounts-table" },
                        React.createElement(semantic_ui_react_1.Table.Header, { className: "sticky" },
                            React.createElement(semantic_ui_react_1.Table.Row, null,
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-display-name", sorted: sortColumn === 'display_name' ? sortDirection : null, onClick: handleSort('display_name'), style: displayNameWidth
                                        ? {
                                            width: `${displayNameWidth}px`,
                                        }
                                        : {} },
                                    React.createElement("div", null, "Display Name")),
                                React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'accounts-v2', prevCol: 'display-name', nextCol: 'balance' }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-balance", sorted: sortColumn === 'to_base' ? sortDirection : null, onClick: handleSort('to_base'), style: balanceWidth
                                        ? {
                                            width: `${balanceWidth}px`,
                                        }
                                        : {} },
                                    "Balance",
                                    ' ',
                                    React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) },
                                        React.createElement("p", null, "Your credit card and loan balances (i.e. debt accounts) should be positive, unless there is a credit on the account."),
                                        React.createElement("p", null, "All other balances should also be positive, unless there is debt on the account."))),
                                React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'accounts-v2', prevCol: 'balance', nextCol: 'last-updated' }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-last-updated", sorted: sortColumn === 'last-updated' ? sortDirection : null, onClick: handleSort('last-updated'), style: lastUpdatedWidth
                                        ? {
                                            width: `${lastUpdatedWidth}px`,
                                        }
                                        : {} },
                                    React.createElement("div", null, "Last Updated")),
                                React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'accounts-v2', prevCol: 'last-updated', nextCol: 'status' }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-status", sorted: sortColumn === 'status' ? sortDirection : null, onClick: handleSort('status'), style: statusWidth
                                        ? {
                                            width: `${statusWidth}px`,
                                        }
                                        : {} },
                                    React.createElement("div", null, "Status")),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-settings" }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-settings" }))),
                        React.createElement(semantic_ui_react_1.Table.Body, { className: !isLoading && accountsUI.length === 0 ? 'g-empty-body' : '' },
                            hasFilters && (React.createElement(semantic_ui_react_1.Table.Row, { className: "no-hover-tr padded-row" },
                                React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: 11, className: "center-align clickable", onClick: () => {
                                        resetFilters();
                                    } },
                                    React.createElement("span", { className: "link ellipsis" },
                                        React.createElement(semantic_ui_react_1.Icon, { name: "x" }),
                                        " Clear filter and show all accounts")))),
                            !isLoading && accountsAndAssets.length === 0 && (React.createElement(semantic_ui_react_1.Table.Row, { className: "g-empty-row no-hover-tr" },
                                React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: 11, className: "center-align" },
                                    React.createElement("div", { className: "empty-mascot" }),
                                    React.createElement("p", null, "You have no accounts yet!"),
                                    React.createElement("p", null, "Accounts can either be synced (linked to your bank account) or manually-managed. Transactions that are not assigned to an account are part of a 'Cash' account."),
                                    React.createElement("p", null,
                                        "Get started by clicking",
                                        ' ',
                                        React.createElement(semantic_ui_react_1.Button, { size: "mini", className: "border-button", onClick: addAccount }, "Add Account"))))),
                            !isLoading &&
                                accountsAndAssets.length !== 0 &&
                                accountsUI.length === 0 && (React.createElement(semantic_ui_react_1.Table.Row, { className: "g-empty-row no-hover-tr" },
                                React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: 11, className: "center-align" },
                                    React.createElement("div", { className: "empty-mascot" }),
                                    React.createElement("p", null, "You have no accounts matching this filter!")))),
                            !isLoading && React.createElement(React.Fragment, null, accountsUI),
                            isLoading && React.createElement(Loader_1.default, { colSpan: 11 }))))),
            React.createElement("div", { className: `right ${showSidebar ? '' : 'hide-on-mobile'}` },
                React.createElement(Sticky_1.default, { reset: showSidebar || totals ? new Date().getTime() : null, content: React.createElement(React.Fragment, null,
                        React.createElement(LinkedAccounts_1.default
                        // accountStatus={accountCount}
                        , { 
                            // accountStatus={accountCount}
                            onClick: type => {
                                if (type == 'has-error') {
                                    setAccountFilter({
                                        synced: true,
                                        manual: false,
                                    });
                                    setAccountSubfilter({
                                        active: false,
                                        inactive: false,
                                        error: true,
                                        closed: false,
                                        unsupported: false,
                                        pending: false,
                                    });
                                    setTypeFilter({
                                        credit: true,
                                        investment: true,
                                        loan: true,
                                        'real estate': true,
                                        vehicle: true,
                                        cryptocurrency: true,
                                        'employee compensation': true,
                                        'other liability': true,
                                        'other asset': true,
                                        cash: true,
                                    });
                                }
                                else if (type == 'should-add') {
                                    addAccount();
                                }
                                else {
                                    history.push('/accounts?filter=active');
                                }
                            } }),
                        React.createElement(Card_1.default, { header: 'Accounts Overview', headerTooltip: 'Balance may not be up-to-date with what you see from your bank website and is for informational purposes only.', emptyMessage: 'You have no accounts linked yet.', rows: Object.keys(totals).map(type => {
                                if (type !== 'total') {
                                    if (totals[type]) {
                                        return {
                                            title: (React.createElement("span", { className: `circle-label label-${type}` }, type === 'depository'
                                                ? 'Cash'
                                                : (0, format_1.capitalize)(type))),
                                            secondary: `${totals[type].count} active accounts`,
                                            number: (0, format_1.toPrice)(totals[type].sum, _user.primaryCurrency),
                                        };
                                    }
                                    else {
                                        return null;
                                    }
                                }
                            }) })) }))),
        showPlaidLinkConfirmation && (React.createElement(PlaidConfirmPopup_1.default, { showPlaidLinkConfirmation: showPlaidLinkConfirmation, plaidMetadata: plaidMetadata, plaidPublicToken: plaidPublicToken, init: initPlaid, onClose: metadata => {
                var _a;
                if (metadata.newlyMissingAccounts.length > 0) {
                    setShowRelinkModal({
                        institution_name: (_a = metadata === null || metadata === void 0 ? void 0 : metadata.institution) === null || _a === void 0 ? void 0 : _a.name,
                        missingAccounts: metadata === null || metadata === void 0 ? void 0 : metadata.newlyMissingAccounts,
                        activeSiblings: metadata === null || metadata === void 0 ? void 0 : metadata.activeSiblings,
                        inactiveSiblings: metadata === null || metadata === void 0 ? void 0 : metadata.inactiveSiblings,
                        plaid_account_id: metadata === null || metadata === void 0 ? void 0 : metadata.originalAccountId,
                    });
                }
            } })),
        React.createElement(semantic_ui_react_1.Modal, { open: !!showRelinkModal, closeOnEscape: false, closeOnDimmerClick: false, size: 'tiny', onClose: () => { } },
            React.createElement(semantic_ui_react_1.Modal.Header, null,
                "Review your connection to ", showRelinkModal === null || showRelinkModal === void 0 ? void 0 :
                showRelinkModal.institution_name),
            React.createElement(semantic_ui_react_1.Modal.Content, null, ((_f = showRelinkModal === null || showRelinkModal === void 0 ? void 0 : showRelinkModal.missingAccounts) === null || _f === void 0 ? void 0 : _f.length) > 0 ? (
            // After relinking, we have newly missing new accounts.
            React.createElement(React.Fragment, null,
                React.createElement("p", null,
                    "Unfortunately, Lunch Money lost connection to the following accounts because they were not selected in the permission panel while relinking to ", showRelinkModal === null || showRelinkModal === void 0 ? void 0 :
                    showRelinkModal.institution_name,
                    ".",
                    ' ',
                    React.createElement("b", null, "This means we will no longer be syncing transactions automatically for:")),
                React.createElement("ul", null, (_g = showRelinkModal === null || showRelinkModal === void 0 ? void 0 : showRelinkModal.missingAccounts) === null || _g === void 0 ? void 0 : _g.map((account, index) => {
                    return (React.createElement("li", { key: `account-${index}` },
                        account.name,
                        " (",
                        account.mask,
                        ")"));
                })),
                React.createElement(semantic_ui_react_1.Message, { info: true },
                    React.createElement("b", null, "Didn't mean to stop syncing?"),
                    React.createElement("p", null,
                        "To avoid interruptions in syncing transactions from the above accounts, you'll need to go through the relink flow again with",
                        ' ', showRelinkModal === null || showRelinkModal === void 0 ? void 0 :
                        showRelinkModal.institution_name,
                        " and ensure the accounts (as well as other actively syncing accounts) are selected."),
                    React.createElement(semantic_ui_react_1.Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            var _o;
                            // Reget active and inactive siblings
                            const plaidAccountId = (_o = showRelinkModal === null || showRelinkModal === void 0 ? void 0 : showRelinkModal.missingAccounts[0]) === null || _o === void 0 ? void 0 : _o.id;
                            const siblings = _assets.plaidAccounts.filter(account => {
                                return (account.access_token ==
                                    _assets.plaidAccounts.find(o => o.id == plaidAccountId)
                                        .access_token);
                            });
                            setPlaidLinkOpts({
                                link_new: null,
                                plaid_account_id: plaidAccountId,
                                activeSiblings: siblings.filter(o => o.status == 'active'),
                                inactiveSiblings: siblings.filter(o => o.status !== 'active'),
                            });
                            setShowRelinkModal(null);
                        }), positive: true, size: "small", loading: isLoading, content: `Relink to ${showRelinkModal === null || showRelinkModal === void 0 ? void 0 : showRelinkModal.institution_name}` })),
                React.createElement("p", null, "If you meant to stop syncing the above accounts, no action is required and you may simply close this dialog."))) : (React.createElement(React.Fragment, null,
                React.createElement("p", null, "You may be asked to manually re-select all the accounts you'd like synced with Lunch Money."),
                ((_h = showRelinkModal === null || showRelinkModal === void 0 ? void 0 : showRelinkModal.activeSiblings) === null || _h === void 0 ? void 0 : _h.length) > 0 && (React.createElement(React.Fragment, null,
                    React.createElement("h3", null, "Actively syncing accounts"),
                    React.createElement("p", null,
                        "To avoid interruption in account syncing,",
                        ' ',
                        React.createElement("b", null, "ensure that the following actively syncing accounts are selected"),
                        ":"),
                    React.createElement("ol", null, showRelinkModal === null || showRelinkModal === void 0 ? void 0 : showRelinkModal.activeSiblings.map((account, index) => {
                        return (React.createElement("li", { key: `account-${index}` },
                            account.name,
                            " (",
                            account.mask,
                            ")"));
                    })))),
                ((_j = showRelinkModal === null || showRelinkModal === void 0 ? void 0 : showRelinkModal.inactiveSiblings) === null || _j === void 0 ? void 0 : _j.length) > 0 && (React.createElement(React.Fragment, null,
                    React.createElement("h3", null, "Deactivated or inactive accounts"),
                    React.createElement("p", null, "The following accounts are currently not syncing. To resume syncing, be sure to grant permissions while relinking."),
                    React.createElement("ol", null, showRelinkModal === null || showRelinkModal === void 0 ? void 0 : showRelinkModal.inactiveSiblings.map((account, index) => {
                        return (React.createElement("li", { key: `account-${index}` },
                            account.name,
                            " (",
                            account.mask,
                            ")"));
                    })),
                    React.createElement(semantic_ui_react_1.Message, { info: true }, "Note that relinking to a previously inactive account will create a new connection to your institution. You will be prompted to select the original account to merge with.")))))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { loading: isLoading, onClick: () => {
                        setShowRelinkModal(null);
                    }, color: "orange" }, ((_k = showRelinkModal === null || showRelinkModal === void 0 ? void 0 : showRelinkModal.missingAccounts) === null || _k === void 0 ? void 0 : _k.length) > 0 ? 'Close' : 'Cancel'),
                !(showRelinkModal === null || showRelinkModal === void 0 ? void 0 : showRelinkModal.missingAccounts) && (React.createElement(semantic_ui_react_1.Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setPlaidLinkOpts({
                            link_new: null,
                            plaid_account_id: showRelinkModal === null || showRelinkModal === void 0 ? void 0 : showRelinkModal.plaid_account_id,
                            activeSiblings: showRelinkModal === null || showRelinkModal === void 0 ? void 0 : showRelinkModal.activeSiblings,
                            inactiveSiblings: showRelinkModal === null || showRelinkModal === void 0 ? void 0 : showRelinkModal.inactiveSiblings,
                        });
                        setShowRelinkModal(null);
                    }), positive: true, loading: isLoading, labelPosition: "right", icon: "arrow right", content: `Relink to ${showRelinkModal === null || showRelinkModal === void 0 ? void 0 : showRelinkModal.institution_name}` })))),
        React.createElement(PopupTransactions_1.default, { openTransactionsTable: openTransactionsTable && !!(selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.source), onClose: () => __awaiter(void 0, void 0, void 0, function* () {
                setSelectedAccount(null);
                setOpenTransactionsTable(false);
            }), headerText: `All transactions in ${selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.display_name}`, query: {
                time: 'all',
                match: 'all',
                [(selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.source) == 'plaid'
                    ? 'account'
                    : 'asset']: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.id,
            }, _process: _process, _showToast: _showToast, _removeToast: _removeToast })));
};
exports.default = LinkedAccounts;
