"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const Sticky = ({ reset, content, forcePosition = null }) => {
    const [style, setStyle] = (0, react_1.useState)(null);
    const position = (0, react_1.useRef)(null);
    const stopMark = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        if (document.getElementById(`sticky-anchor`) &&
            document.getElementById(`sticky`)) {
            setTimeout(() => {
                var _a;
                try {
                    const elementTop = document.getElementById(`sticky-anchor`).offsetTop;
                    const height = document.getElementById(`sticky`).offsetHeight + 5 * 14;
                    const bodyHeight = document.body.offsetHeight;
                    const bodyEntireHeight = document.body.scrollHeight;
                    const stickyCounterpart = (_a = document.getElementById('sticky-counterpart')) === null || _a === void 0 ? void 0 : _a.offsetHeight;
                    // Skip this if the sticky element is the entire height of the window
                    if ((Math.abs(bodyEntireHeight - elementTop - height) > 4 * 14 &&
                        stickyCounterpart > height) ||
                        forcePosition == 'top') {
                        //(3rem margin bottom)
                        if (forcePosition == 'top' || height < bodyHeight) {
                            // The whole sticky element can fit inside the screen
                            stopMark.current = elementTop - 14; // 1rem
                            position.current = 'top';
                        }
                        else {
                            // The sticky element can't fit inside screen, so stop when the bottom hits
                            stopMark.current = elementTop + height - bodyHeight;
                            position.current = 'bottom';
                        }
                    }
                    else {
                        stopMark.current = -1;
                    }
                    handler();
                }
                catch (e) {
                    // No elements found. Ignore.
                }
            }, 750);
        }
    }, [reset, forcePosition]);
    const handler = () => {
        if (stopMark.current > 0) {
            if (window.scrollY >= stopMark.current) {
                const _style = {
                    position: 'fixed',
                };
                if (position.current == 'top') {
                    _style['top'] = '1rem';
                }
                else {
                    _style['bottom'] = '1rem';
                }
                setStyle(_style);
            }
            else {
                setStyle(null);
            }
        }
    };
    (0, react_1.useEffect)(() => {
        window.addEventListener('scroll', handler);
        window.addEventListener('resize', handler);
        return () => {
            window.removeEventListener('scroll', handler);
            window.removeEventListener('resize', handler);
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "sticky-anchor", style: {
                backgroundColor: 'red',
                width: '100%',
                height: '0px',
            } }),
        React.createElement("div", { id: `sticky`, style: style }, content)));
};
exports.default = Sticky;
