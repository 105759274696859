"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const support_1 = require("@actions/support");
const react_router_dom_1 = require("react-router-dom");
const global_1 = require("@actions/global");
const BillingProvider_1 = require("@providers/BillingProvider");
const qs = require("query-string");
const semantic_ui_react_1 = require("semantic-ui-react");
const UserProvider_1 = require("@/providers/UserProvider");
const Feedback = ({ showFeedback, onClose }) => {
    const history = (0, react_router_dom_1.useHistory)();
    const _billing = (0, react_1.useContext)(BillingProvider_1.BillingContext);
    const [showForm, setShowForm] = (0, react_1.useState)(false);
    const [submitted, setSubmitted] = (0, react_1.useState)(false);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [feedback, setFeedback] = (0, react_1.useState)('');
    const [feedback2, setFeedback2] = (0, react_1.useState)('');
    const [priority, setPriority] = (0, react_1.useState)('normal');
    const [error, setError] = (0, react_1.useState)(null);
    const [type, setType] = (0, react_1.useState)('Bug Report');
    const [files, setFiles] = (0, react_1.useState)([]);
    const [showTextbox, setShowTextbox] = (0, react_1.useState)(true);
    const [showVerifyEmail, setShowVerifyEmail] = (0, react_1.useState)(false);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    (0, react_1.useEffect)(() => {
        if (!showForm) {
            onClose();
        }
    }, [showForm]);
    (0, react_1.useEffect)(() => {
        setFiles([]);
        if (_user.verifiedEmail) {
            setShowForm(showFeedback);
        }
        else if (showFeedback == true) {
            setShowVerifyEmail(true);
        }
    }, [showFeedback]);
    (0, react_1.useEffect)(() => {
        setFiles([]);
    }, []);
    (0, react_1.useEffect)(() => {
        const parse = qs.parse(history.location.search);
        if (_user.verifiedEmail) {
            if (parse.show_feedback == 'question') {
                setType('Question');
                setShowForm(true);
            }
            else if (parse.show_feedback == 'institution-request') {
                setType('Institution request');
                setShowForm(true);
            }
            else if (parse.show_feedback == 'cryptocurrency-request') {
                setType('Cryptocurrency request');
                setShowForm(true);
            }
            else if (parse.show_feedback == 'beta-testing-feedback') {
                setType('Beta testing feedback');
                setShowForm(true);
            }
            else if (parse.show_feedback == 'general-feedback') {
                setType('General Feedback');
                setShowForm(true);
            }
        }
        else if (!!parse.show_feedback && _user.verifiedEmail == false) {
            setShowVerifyEmail(true);
        }
    }, [history.location.search]);
    const onCloseModal = () => __awaiter(void 0, void 0, void 0, function* () {
        setSubmitted(false);
        setShowForm(false);
        setShowVerifyEmail(false);
        setPriority('normal');
        setFeedback('');
        setFeedback2('');
        setType('Bug Report');
        onClose();
        const query = qs.parse(history.location.search);
        delete query.show_feedback;
        history.replace({
            search: qs.stringify(query),
        });
    });
    const submit = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const data = new FormData();
        files.forEach(file => {
            data.append('file', file);
        });
        data.append('url', window.location.href);
        data.append('feedback', `${feedback}${feedback2 ? ` (${feedback2})` : ''}`);
        data.append('priority', priority);
        data.append('type', type);
        yield (0, support_1.receiveFeedback)(data);
        setSubmitted(true);
        setIsLoading(false);
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(semantic_ui_react_1.Modal, { open: showVerifyEmail, onClose: onCloseModal, size: "tiny" },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Please verify your email address"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                "We use email to handle all support requests. In order to submit a bug or feedback, please verify your email address.",
                ' '),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        setShowVerifyEmail(false);
                        history.push('/profile');
                    } }, "Go to profile"))),
        React.createElement(semantic_ui_react_1.Modal, { open: showForm, onClose: onCloseModal, size: "tiny", dimmer: "inverted" },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Submit a bug or feedback"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                ' ',
                !submitted ? (React.createElement("div", { className: "submit-feedback-form" },
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(semantic_ui_react_1.Form.Group, { className: "mb-05rem", widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, { style: { paddingLeft: 0, paddingRight: 0 } },
                                React.createElement("label", null, "What type of feedback are you submitting?"),
                                React.createElement(semantic_ui_react_1.Dropdown, { fluid: true, selection: true, className: "category-dropdown p-category-modal feedback-dropdown", labeled: true, value: type, onChange: (e, { value }) => {
                                        if (value == 'Feature Request' ||
                                            value == 'Bank account syncing') {
                                            setShowTextbox(false);
                                        }
                                        else {
                                            setShowTextbox(true);
                                        }
                                        setFeedback2('');
                                        setType(value);
                                    }, options: [
                                        {
                                            key: 'bug',
                                            value: 'Bug Report',
                                            text: 'Bug report',
                                            icon: 'bug',
                                        },
                                        {
                                            key: 'question',
                                            value: 'Question',
                                            text: 'Question',
                                            icon: 'question',
                                        },
                                        {
                                            key: 'feature-request',
                                            value: 'Feature Request',
                                            text: 'Feature request',
                                            icon: 'bell',
                                        },
                                        // {
                                        //   key: 'syncing-issue',
                                        //   value: 'Bank account syncing',
                                        //   text: 'Bank account syncing',
                                        //   icon: 'cloud download',
                                        // },
                                        {
                                            key: 'institution-request',
                                            value: 'Institution request',
                                            text: 'Institution request for syncing',
                                            icon: 'university',
                                        },
                                        {
                                            key: 'cryptocurrency-request',
                                            value: 'Cryptocurrency request',
                                            text: 'Cryptocurrency request for syncing',
                                            icon: 'bitcoin',
                                        },
                                        {
                                            key: 'general-feedback',
                                            value: 'General Feedback',
                                            text: 'General feedback',
                                            icon: 'comments',
                                        },
                                        _user.isBeta &&
                                            window.location.href.indexOf('beta.lunchmoney') >= 0
                                            ? {
                                                key: 'beta',
                                                value: 'Beta testing feedback',
                                                text: 'Beta testing feedback',
                                                icon: 'rocket',
                                            }
                                            : null,
                                    ].filter(o => !!o) }))),
                        type === 'Feature Request' && (React.createElement(semantic_ui_react_1.Message, { info: true },
                            React.createElement("p", null,
                                "We have a",
                                ' ',
                                React.createElement("a", { href: "https://feedback.lunchmoney.app", className: "link clickable", target: "_blank" }, "Feature Request board"),
                                "! Please submit any feature requests there. You'll also be able to view, vote on or discuss other people's feature requests."),
                            React.createElement(semantic_ui_react_1.Button, { size: "mini", onClick: () => {
                                    window.open('https://feedback.lunchmoney.app');
                                } },
                                "View feature request board",
                                ' ',
                                React.createElement(semantic_ui_react_1.Icon, { className: "ml-1rem", fitted: true, name: "external alternate" })))),
                        type === 'Bug Report' && (React.createElement(semantic_ui_react_1.Message, { info: true },
                            "Is this a general bug, or are you able to submit a bug report without personal information? Consider posting on",
                            ' ',
                            React.createElement("a", { href: "https://feedback.lunchmoney.app", className: "link clickable", target: "_blank" }, "our public Bugs board"),
                            "!")),
                        type === 'Question' &&
                            (_billing.status == 'active' ||
                                _billing.subscription.user_type == 'early-adopter') && (React.createElement(semantic_ui_react_1.Message, { info: true },
                            React.createElement("p", null, "Have a general question? We've got a Discord community with lively discussions going on with other users."),
                            React.createElement("p", null,
                                React.createElement("b", null, "You will likely receive a much faster response via Discord than if you use this form!")),
                            React.createElement(semantic_ui_react_1.Button, { size: "mini", labelPosition: "right", icon: true, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    const results = yield (0, global_1.getDiscordInviteLink)();
                                    window.open(results);
                                }) },
                                "Join the Lunch Money Discord community",
                                ' ',
                                React.createElement(semantic_ui_react_1.Icon, { className: "ml-1rem", fitted: true, name: "external alternate" })))),
                        type === 'Bank account syncing' && (React.createElement(semantic_ui_react_1.Message, { info: true },
                            React.createElement("p", null,
                                "If this is about a specific account that's already linked, please use the support flow from the",
                                ' ',
                                React.createElement(react_router_dom_1.Link, { className: "link", to: "/accounts" }, "Accounts page"),
                                ".",
                                ' ',
                                React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }), hoverable: true, position: 'top center', size: "tiny", inverted: true }, "Go to the Accounts page and click on the Account to open the details pane and click the \"I need help with this account\" in red.")),
                            !showTextbox && (React.createElement(semantic_ui_react_1.Button, { size: "mini", onClick: () => {
                                    setShowTextbox(true);
                                } }, "I want to submit a general bank syncing question")))),
                        showTextbox && (React.createElement(React.Fragment, null, type !== 'Institution request' &&
                            type !== 'Cryptocurrency request' ? (React.createElement(React.Fragment, null,
                            React.createElement(semantic_ui_react_1.Form.Group, { className: "mt-05rem mb-05rem", widths: "equal" },
                                React.createElement(semantic_ui_react_1.Form.Field, { style: { paddingLeft: 0, paddingRight: 0 } },
                                    React.createElement("label", null, "How urgent is this? We will do our best to get back to you in a timely manner."),
                                    React.createElement(semantic_ui_react_1.Dropdown, { fluid: true, selection: true, className: "category-dropdown p-category-modal", labeled: true, defaultValue: 'normal', onChange: (e, { value }) => {
                                            setPriority(value);
                                        }, options: [
                                            {
                                                key: 'low',
                                                value: 'low',
                                                text: 'Low Priority',
                                            },
                                            {
                                                key: 'normal',
                                                value: 'normal',
                                                text: 'Normal Priority',
                                            },
                                            {
                                                key: 'high',
                                                value: 'high',
                                                text: 'High Priority',
                                            },
                                        ] }))),
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                                React.createElement(semantic_ui_react_1.Form.Field, { style: { paddingLeft: 0, paddingRight: 0 } },
                                    React.createElement("label", null, "Please provide context below:"),
                                    React.createElement(semantic_ui_react_1.TextArea, { rows: 5, value: feedback, onChange: (e, { value }) => {
                                            setFeedback(value.toString());
                                        }, placeholder: type == 'Bug Report'
                                            ? "Please provide details about the bug you're seeing, and include your environment (e.g. Firefox on Windows 10)"
                                            : 'What would you like to share with us?' }))),
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                React.createElement(semantic_ui_react_1.Form.Field, { style: { paddingLeft: 0, paddingRight: 0 } },
                                    React.createElement("label", null, "Upload files (optional, max 10 images, 1MB each)"),
                                    React.createElement(semantic_ui_react_1.Input, { fluid: true, multiple: true, type: "file", name: "file", onChange: e => {
                                            setError(null);
                                            const _files = [...e.target.files];
                                            if (_files.length > 10) {
                                                setError(`You tried to upload ${_files.length} files! Please upload 10 or less files.`);
                                                e.target.value = null;
                                                return;
                                            }
                                            _files.forEach(file => {
                                                if (file.size > 1000000) {
                                                    setError(`Please upload files that do not exceed 1MB!`);
                                                    e.target.value = null;
                                                    return;
                                                }
                                            });
                                            setFiles(_files);
                                        } }),
                                    error && (React.createElement("span", { className: "color--red mt-05rem" }, error)))))) : (React.createElement(React.Fragment, null,
                            type == 'Institution request' && (React.createElement(semantic_ui_react_1.Message, { info: true },
                                "We use ",
                                React.createElement("a", { href: "https://plaid.com" }, "Plaid"),
                                ", a well-known and trusted third-party banking importer, to connect to different institutions for automatic syncing. We're more than happy to pass on your request for a new institution but cannot guarantee the request will be fulfilled in a timely manner.")),
                            type == 'Cryptocurrency request' && (React.createElement(semantic_ui_react_1.Message, { info: true },
                                "We use",
                                ' ',
                                React.createElement("a", { href: "https://coingecko.com", className: "link clickable", target: "_blank" }, "CoinGecko"),
                                "'s API to fetch cryptocurrencies and their exchange rates. Please ensure the cryptocurrencies you are requesting exist on CoinGecko!")),
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                                React.createElement(semantic_ui_react_1.Form.Field, { style: { paddingLeft: 0, paddingRight: 0 } },
                                    React.createElement("label", null, type !== 'Cryptocurrency request'
                                        ? 'Name and country of institution'
                                        : 'URL of coin on Coingecko (if several, separate with a comma)'),
                                    React.createElement(semantic_ui_react_1.Input, { value: feedback || '', onChange: (e, { value }) => {
                                            setFeedback(value.toString());
                                        }, placeholder: type == 'Institution request'
                                            ? 'Bank Pekao, Poland'
                                            : 'https://www.coingecko.com/en/coins/bnb' }))),
                            type !== 'Cryptocurrency request' && (React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                                React.createElement(semantic_ui_react_1.Form.Field, { style: { paddingLeft: 0, paddingRight: 0 } },
                                    React.createElement("label", null, "Institution website"),
                                    React.createElement(semantic_ui_react_1.Input, { value: feedback2 || '', onChange: (e, { value }) => {
                                            setFeedback2(value.toString());
                                        }, placeholder: type == 'Institution request'
                                            ? 'https://'
                                            : '' }))))))))))) : (React.createElement("div", { className: "submit-feedback-text" },
                    React.createElement("p", null,
                        React.createElement(semantic_ui_react_1.Icon, { name: "paper plane outline" }),
                        " We've received your submission!"),
                    React.createElement("p", null, "Thank you for taking the time to submit your feedback, suggestions, and bug reports. It will help us continuously improve Lunch Money!")))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                !submitted && (React.createElement(semantic_ui_react_1.Button, { basic: true, color: "orange", onClick: onCloseModal }, "Cancel")),
                submitted ? (React.createElement(semantic_ui_react_1.Button, { disabled: isLoading, loading: isLoading, onClick: onCloseModal }, "Close")) : (React.createElement(semantic_ui_react_1.Button, { disabled: (feedback === null || feedback === void 0 ? void 0 : feedback.length) == 0 || isLoading, loading: isLoading, onClick: submit }, "Submit"))))));
};
exports.default = Feedback;
