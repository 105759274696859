"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unlinkDiscord = exports.getStatus = exports.exchangeCode = exports.connectDiscord = void 0;
const common_1 = require("./common");
const connectDiscord = () => {
    return (0, common_1.post)(`discord/connect`);
};
exports.connectDiscord = connectDiscord;
const exchangeCode = opts => {
    return (0, common_1.post)(`discord/exchange`, opts);
};
exports.exchangeCode = exchangeCode;
const getStatus = () => {
    return (0, common_1.get)(`discord/status`);
};
exports.getStatus = getStatus;
const unlinkDiscord = () => {
    return (0, common_1.put)(`discord/unlink`);
};
exports.unlinkDiscord = unlinkDiscord;
