"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_1 = require("react");
const rules_1 = require("@actions/rules");
const Moment = require("moment");
const EditableString_1 = require("@components/elements/EditableString");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const UserProvider_1 = require("@/providers/UserProvider");
const format_1 = require("@/helpers/format");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const RuleRow = ({ buildMatchesString, buildRulesString, rule, _process, _showToast, setEditRule, setShowModal, setShowRecurringEditConfirm, isLoadingDelete, selected, setSelected, }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    return (React.createElement(semantic_ui_react_1.Table.Row, { key: `rules-row-${rule.rule_criteria_id}` },
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align clickable hide-on-mobile-td table-cell-checkbox", onClick: () => {
                if (!isLoadingDelete) {
                    if (selected) {
                        setSelected(false);
                    }
                    else {
                        setSelected(true);
                    }
                }
            } },
            React.createElement("span", { className: `bulk-checkbox ${selected ? 'checked' : 'empty'}` })),
        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-divider" }),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align" },
            rule.criteria_suggested && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, position: 'top center', size: "mini", trigger: React.createElement("span", null, (0, format_1.capitalize)(Moment(rule.criteria_created_at).fromNow())) },
                "This rule criteria was created on",
                ' ',
                Moment(rule.criteria_created_at).format(_user.getFullTimeFormat()))),
            !rule.criteria_suggested && !!rule.criteria_last_triggered_at && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, position: 'top center', size: "mini", trigger: React.createElement("span", null, (0, format_1.capitalize)(Moment(rule.criteria_last_triggered_at).fromNow())) },
                "This rule criteria was last triggered on",
                ' ',
                Moment(rule.criteria_last_triggered_at).format(_user.getFullTimeFormat())))),
        !rule.criteria_suggested && (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align editable" },
                React.createElement(EditableString_1.default, { identifier: `priority-${rule.rule_criteria_id}`, firstValue: rule.criteria_priority, placeholder: '10', shouldSaveOnChange: false, location: 'inline', state: 'Default', autoFocus: true, shouldSaveOnBlur: true, shouldSaveOnEnter: true, shouldSelectOnFocus: true, onSave: (priority) => __awaiter(void 0, void 0, void 0, function* () {
                        if (priority !== rule.criteria_priority) {
                            const results = yield _process(rules_1.updateRulePriority)(rule.rule_criteria_id, parseInt(priority));
                            if (!results.error) {
                                _showToast({
                                    message: 'Successfully updated rule priority',
                                    type: 'success',
                                });
                            }
                        }
                    }) })))),
        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-divider" }),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell clickable", onClick: () => {
                setEditRule(rule);
                if (rule.criteria_source == 'system' && rule.recurring_id) {
                    setShowRecurringEditConfirm(true);
                }
                else {
                    setShowModal(true);
                }
            } }, buildMatchesString(rule, _assets, _categories)),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable", onClick: () => {
                setEditRule(rule);
                if (rule.criteria_source == 'system' && rule.recurring_id) {
                    setShowRecurringEditConfirm(true);
                }
                else {
                    setShowModal(true);
                }
            } },
            React.createElement("span", null,
                React.createElement(semantic_ui_react_1.Icon, { name: "long arrow alternate right" }))),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell clickable", onClick: () => {
                setEditRule(rule);
                if (rule.criteria_source == 'system' && rule.recurring_id) {
                    setShowRecurringEditConfirm(true);
                }
                else {
                    setShowModal(true);
                }
            } }, buildRulesString(rule)),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: `table-cell-arrow no-wrap no-data clickable`, onClick: () => {
                setEditRule(rule);
                if (rule.criteria_source == 'system' && rule.recurring_id) {
                    setShowRecurringEditConfirm(true);
                }
                else {
                    setShowModal(true);
                }
            } },
            rule.description && (React.createElement(semantic_ui_react_1.Popup, { position: 'top right', trigger: React.createElement(semantic_ui_react_1.Icon, { name: 'sticky note outline', className: `green-on-hover color--grey` }) }, rule.description)),
            React.createElement(semantic_ui_react_1.Icon, { name: 'angle right', className: `green-on-hover color--grey` }))));
};
exports.default = RuleRow;
