"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Autosuggest = require("react-autosuggest");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const EditableDate_1 = require("@components/elements/EditableDate");
const EditableCategory_1 = require("@components/elements/EditableCategory");
const EditableString_1 = require("@components/elements/EditableString");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const Playground = ({ _showToast }) => {
    const [autoValue, setAutoValue] = (0, react_1.useState)('');
    return (React.createElement(semantic_ui_react_1.Container, null,
        React.createElement(ContainerHeader_1.default, { title: "Playground" }),
        React.createElement(semantic_ui_react_1.Table, { selectable: true, celled: true, unstackable: true, className: "mb-3rem" },
            React.createElement(semantic_ui_react_1.Table.Header, null,
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Cell 1"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { style: { width: '100%' } }, "Cell 2"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Cell 3"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Cell 4"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Cell 5"))),
            React.createElement(semantic_ui_react_1.Table.Body, null,
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.Cell, null, "Cell 1"),
                    React.createElement(semantic_ui_react_1.Table.Cell, null, "Cell 2"),
                    React.createElement(semantic_ui_react_1.Table.Cell, null,
                        React.createElement("span", null, "Cell 3")),
                    React.createElement(semantic_ui_react_1.Table.Cell, null,
                        React.createElement("span", null, "Cell 4")),
                    React.createElement(semantic_ui_react_1.Table.Cell, null, "Cell 5")),
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.Cell, null, "Cell 1"),
                    React.createElement(semantic_ui_react_1.Table.Cell, null, "Cell 2"),
                    React.createElement(semantic_ui_react_1.Table.Cell, null,
                        React.createElement("span", null, "Cell 3")),
                    React.createElement(semantic_ui_react_1.Table.Cell, null,
                        React.createElement("span", null, "Cell 4")),
                    React.createElement(semantic_ui_react_1.Table.Cell, null, "Cell 5")))),
        React.createElement(semantic_ui_react_1.Grid, { columns: 4 },
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Button, { size: "small", onClick: () => {
                            _showToast({
                                message: 'Successfully created transaction',
                                type: 'success',
                            });
                        } }, "Add new transaction")),
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Button, { size: "mini", onClick: () => {
                            _showToast({
                                message: 'Successfully created transaction',
                                type: 'error',
                            });
                        } }, "Add new transaction")),
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Button, { size: "tiny", onClick: () => {
                            _showToast({
                                message: 'Successfully created transaction',
                                type: 'info',
                            });
                        } }, "Add new transaction")),
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Button, { basic: true, color: "orange", onClick: () => {
                            _showToast({
                                message: 'Successfully created transaction',
                                type: 'warning',
                            });
                        } }, "Add new transaction"))),
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Message, { warning: true },
                        React.createElement(semantic_ui_react_1.Message.Header, null, "This is a header"),
                        React.createElement(semantic_ui_react_1.Message.Content, null, "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga."))),
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Message, { success: true },
                        React.createElement(semantic_ui_react_1.Message.Header, null, "This is a header"),
                        React.createElement(semantic_ui_react_1.Message.Content, null, "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga."))),
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Message, { info: true },
                        React.createElement(semantic_ui_react_1.Message.Header, null, "This is a header"),
                        React.createElement(semantic_ui_react_1.Message.Content, null, "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga."))),
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Message, { error: true },
                        React.createElement(semantic_ui_react_1.Message.Header, null, "This is a header"),
                        React.createElement(semantic_ui_react_1.Message.Content, null, "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.")))),
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Card, null,
                        React.createElement(semantic_ui_react_1.Card.Content, null,
                            React.createElement(semantic_ui_react_1.Card.Header, null,
                                React.createElement(semantic_ui_react_1.Icon, { name: "exclamation circle" }),
                                " Having issues?")),
                        React.createElement(semantic_ui_react_1.Card.Content, null,
                            React.createElement("p", null, "We prioritize bug reports related to our CSV import tool."),
                            React.createElement("p", null,
                                "Help us improve our CSV import tool to support your bank. Reach out to us via",
                                ' ',
                                React.createElement("a", { className: "link clickable", href: "mailto:support@lunchmoney.app" }, "support@lunchmoney.app"),
                                ' ',
                                "or \"Submit a bug or feedback\".")))),
                React.createElement(semantic_ui_react_1.Grid.Column, null),
                React.createElement(semantic_ui_react_1.Grid.Column, null),
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: true, label: "This is a checkbox" }))),
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement("b", null, "Location")),
                React.createElement(semantic_ui_react_1.Grid.Column, null, "Modal"),
                React.createElement(semantic_ui_react_1.Grid.Column, null, "Inline"),
                React.createElement(semantic_ui_react_1.Grid.Column, null, "Query")),
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, null, "Editable Date"),
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(EditableDate_1.default, { identifier: 'linked-account', firstValue: null, location: 'modal', format: 'month_day_year', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () { }) }),
                    React.createElement(semantic_ui_react_1.Form, { className: "mt-1rem" },
                        React.createElement(EditableDate_1.default, { identifier: 'linked-account', firstValue: null, location: 'modal', state: 'Editing', format: 'month_day_year', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () { }) }))),
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(EditableDate_1.default, { identifier: 'linked-account', firstValue: null, location: 'inline', format: 'month_day_year', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () { }) }),
                    React.createElement(semantic_ui_react_1.Form, { className: "mt-1rem" },
                        React.createElement(EditableDate_1.default, { identifier: 'linked-account', firstValue: null, location: 'inline', format: 'month_day_year', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () { }) })))),
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, null, "Editable String"),
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(EditableString_1.default, { identifier: 'linked-account', firstValue: null, placeholder: "My placeholder", location: 'modal', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () { }) }),
                    React.createElement(semantic_ui_react_1.Form, { className: "mt-1rem" },
                        React.createElement(EditableString_1.default, { identifier: 'linked-account', firstValue: null, placeholder: "My placeholder", location: 'modal', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () { }) }))),
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(EditableString_1.default, { identifier: 'linked-account', firstValue: null, placeholder: "My placeholder", location: 'inline', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () { }) }),
                    React.createElement(semantic_ui_react_1.Form, { className: "mt-1rem" },
                        React.createElement(EditableString_1.default, { identifier: 'linked-account', firstValue: null, placeholder: "My placeholder", location: 'inline', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () { }) })))),
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, null, "Autosuggest"),
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement("div", { className: "g-editable-text" },
                        React.createElement(Autosuggest, { suggestions: [
                                {
                                    text: 'Apple',
                                },
                                {
                                    text: 'Banana',
                                },
                                {
                                    text: 'Cherry',
                                },
                                {
                                    text: 'Grapefruit',
                                },
                                {
                                    text: 'Lemon',
                                },
                            ], onSuggestionsFetchRequested: ({ value }) => { }, onSuggestionsClearRequested: () => { }, getSuggestionValue: suggestion => {
                                return suggestion.text;
                            }, renderSuggestion: suggestion => {
                                return React.createElement("span", null, suggestion.text);
                            }, inputProps: {
                                placeholder: 'Type a programming language',
                                value: autoValue,
                                onChange: (event, { newValue }) => {
                                    console.log('Changing to', newValue);
                                    setAutoValue(newValue);
                                },
                            }, theme: {
                                container: 'ui input p-input-modal display--flex flex--column',
                                containerOpen: 'react-autosuggest__container--open',
                                input: 'react-autosuggest__input',
                                inputOpen: 'react-autosuggest__input--open',
                                inputFocused: 'react-autosuggest__input--focused',
                                suggestionsContainer: 'react-autosuggest__suggestions-container',
                                suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
                                suggestionsList: 'react-autosuggest__suggestions-list',
                                suggestion: 'react-autosuggest__suggestion',
                                suggestionFirst: 'react-autosuggest__suggestion--first',
                                suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
                                sectionContainer: 'react-autosuggest__section-container',
                                sectionContainerFirst: 'react-autosuggest__section-container--first',
                                sectionTitle: 'react-autosuggest__section-title',
                            } })),
                    React.createElement(semantic_ui_react_1.Form, { className: "mt-1rem" },
                        React.createElement(EditableString_1.default, { identifier: 'linked-account', firstValue: null, placeholder: "My placeholder", location: 'modal', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () { }) }))),
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement("div", { className: "g-editable-text selected" },
                        React.createElement(Autosuggest, { suggestions: [
                                {
                                    text: 'Apple',
                                },
                                {
                                    text: 'Banana',
                                },
                                {
                                    text: 'Cherry',
                                },
                                {
                                    text: 'Grapefruit',
                                },
                                {
                                    text: 'Lemon',
                                },
                            ], onSuggestionsFetchRequested: ({ value }) => { }, onSuggestionsClearRequested: () => { }, getSuggestionValue: suggestion => {
                                return suggestion.text;
                            }, renderSuggestion: suggestion => {
                                return React.createElement("span", null, suggestion.text);
                            }, inputProps: {
                                placeholder: 'Type a programming language',
                                value: autoValue,
                                onChange: (event, { newValue }) => {
                                    console.log('Changing to', newValue);
                                    setAutoValue(newValue);
                                },
                            }, theme: {
                                container: 'ui input p-input-inline display--flex flex--column',
                                containerOpen: 'react-autosuggest__container--open',
                                input: 'react-autosuggest__input',
                                inputOpen: 'react-autosuggest__input--open',
                                inputFocused: 'react-autosuggest__input--focused',
                                suggestionsContainer: 'react-autosuggest__suggestions-container',
                                suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
                                suggestionsList: 'react-autosuggest__suggestions-list',
                                suggestion: 'react-autosuggest__suggestion',
                                suggestionFirst: 'react-autosuggest__suggestion--first',
                                suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
                                sectionContainer: 'react-autosuggest__section-container',
                                sectionContainerFirst: 'react-autosuggest__section-container--first',
                                sectionTitle: 'react-autosuggest__section-title',
                            } })),
                    React.createElement(semantic_ui_react_1.Form, { className: "mt-1rem" },
                        React.createElement(EditableString_1.default, { identifier: 'linked-account', firstValue: null, placeholder: "My placeholder", location: 'inline', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () { }) })))),
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, null, "Editable Category"),
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(EditableCategory_1.default, { identifier: 'playground', firstValue: null, location: 'modal', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () { }) }),
                    React.createElement(semantic_ui_react_1.Form, { className: "mt-1rem" },
                        React.createElement(EditableCategory_1.default, { identifier: 'playground', firstValue: null, location: 'modal', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () { }) }))),
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(EditableCategory_1.default, { identifier: 'playground', firstValue: null, location: 'inline', state: 'Default', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () { }) }),
                    React.createElement(semantic_ui_react_1.Form, { className: "mt-1rem" },
                        React.createElement(EditableCategory_1.default, { identifier: 'playground', firstValue: null, location: 'inline', state: 'Default', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () { }) }))),
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(EditableCategory_1.default, { identifier: 'playground', firstValue: null, location: 'inline-new', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () { }) }),
                    React.createElement(semantic_ui_react_1.Form, { className: "mt-1rem" },
                        React.createElement(EditableCategory_1.default, { identifier: 'playground', firstValue: null, location: 'inline-new', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () { }) })))))));
};
exports.default = Playground;
