"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const UserProvider_1 = require("@providers/UserProvider");
const format_1 = require("@helpers/format");
const tooltips2_1 = require("@helpers/tooltips2");
const react_1 = require("react");
const react_chartjs_2_1 = require("react-chartjs-2");
const colors_1 = require("@helpers/colors");
const charts_1 = require("@helpers/charts");
const chart_js_1 = require("chart.js");
const LineChart = ({ labels, data, dataSetNames, timeGranularity, tooltipType, normalizeIncome, labelCallback, }) => {
    const [chartRef, setChartRef] = (0, react_1.useState)(null);
    const [chartDataset, setChartDataset] = (0, react_1.useState)([]);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    chart_js_1.Chart.register(...chart_js_1.registerables);
    (0, react_1.useEffect)(() => {
        console.log({ labels, data });
        if (!labels || !data || !(data === null || data === void 0 ? void 0 : data.length))
            return;
        if (data.length > 1) {
            handleMultipleDatasets();
        }
        else if (data.length == 1) {
            handleSingleDataset();
        }
    }, [labels, data, normalizeIncome]);
    const handleMultipleDatasets = () => {
        let datasets = [];
        data.forEach((dataset, datasetIndex) => {
            if (dataset.length > 0) {
                dataset.forEach((o, index) => {
                    if (!o.hidden) {
                        const mappedData = [];
                        const mappedCounts = [];
                        labels.forEach(time => {
                            mappedData.push(o.data[time] || 0);
                            mappedCounts.push(o.counts[time] || 0);
                        });
                        datasets.push({
                            stack: dataSetNames[datasetIndex],
                            id: o.id,
                            label: `${dataSetNames[datasetIndex]}`,
                            backgroundColor: colors_1.CHART_COLORS[datasetIndex % colors_1.CHART_COLORS.length],
                            isIncome: o.is_income,
                            counts: mappedCounts,
                            data: normalizeIncome && o.is_income
                                ? mappedData.map((m) => -1 * m)
                                : mappedData,
                            lineTension: 0,
                            fill: false,
                            pointHoverBorderWidth: 2,
                            pointHoverBackgroundColor: colors_1.CHART_COLORS[datasetIndex % colors_1.CHART_COLORS.length],
                            pointHoverRadius: 5,
                            pointHoverBorderColor: document.body.classList.contains('dark')
                                ? '#111'
                                : '#fff',
                            pointRadius: 0,
                            borderWidth: 2.5,
                            pointHitRadius: 10,
                            borderColor: colors_1.CHART_COLORS[datasetIndex % colors_1.CHART_COLORS.length],
                        });
                    }
                });
            }
        });
        setChartDataset(datasets);
    };
    const handleSingleDataset = () => {
        const set = data[0]
            .map((o, index) => {
            if (o.hidden) {
                return -1;
            }
            const mappedData = [];
            const mappedCounts = [];
            labels.forEach(time => {
                mappedData.push(o.data[time] || 0);
                mappedCounts.push(o.counts[time] || 0);
            });
            return {
                id: o.id,
                label: `${o.group_name ? o.group_name + '/' : ''}${o.name}`,
                fill: false,
                pointHoverBorderWidth: 2,
                pointHoverBackgroundColor: colors_1.CHART_COLORS[index % colors_1.CHART_COLORS.length],
                pointHoverRadius: 5,
                pointHoverBorderColor: document.body.classList.contains('dark')
                    ? '#111'
                    : '#fff',
                pointRadius: 0,
                borderWidth: 2.5,
                pointHitRadius: 10,
                borderColor: colors_1.CHART_COLORS[index % colors_1.CHART_COLORS.length],
                backgroundColor: colors_1.CHART_COLORS[index % colors_1.CHART_COLORS.length],
                isIncome: o.is_income,
                counts: mappedCounts,
                data: normalizeIncome && o.is_income
                    ? mappedData.map((m) => -1 * m)
                    : mappedData,
                lineTension: 0,
            };
        })
            .filter(o => o !== -1);
        setChartDataset(set);
    };
    return (React.createElement(react_chartjs_2_1.Line, { ref: reference => {
            setChartRef(reference);
        }, data: {
            labels,
            datasets: chartDataset,
        }, options: {
            plugins: {
                title: {
                    display: false,
                },
                legend: {
                    display: false,
                },
                tooltip: {
                    mode: tooltipType,
                    intersect: false,
                    enabled: false,
                    callbacks: {
                        label: labelCallback,
                    },
                    external: function (context) {
                        return (0, tooltips2_1.getTooltip)(context, timeGranularity == 'month'
                            ? _user.getMonthYearFormat()
                            : timeGranularity == 'year'
                                ? 'YYYY'
                                : _user.getMonthDayFormat());
                    },
                },
            },
            hover: {
                mode: 'nearest',
                intersect: false,
            },
            scales: {
                x: {
                    border: {
                        display: false,
                    },
                    grid: Object.assign({}, (0, charts_1.getGridLineColors)()),
                    ticks: Object.assign(Object.assign({}, (0, charts_1.getTickColors)()), { callback: function (value, index, values) {
                            return Moment(labels[index], 'YYYY-MM-DD').format(timeGranularity == 'month'
                                ? _user.getMonthYearFormat()
                                : timeGranularity == 'year'
                                    ? 'YYYY'
                                    : _user.getMonthDayFormat());
                        } }),
                },
                y: {
                    border: {
                        display: false,
                    },
                    grid: Object.assign({}, (0, charts_1.getGridLineColors)()),
                    ticks: Object.assign(Object.assign({}, (0, charts_1.getTickColors)()), { callback: function (value, index, values) {
                            if (_user.settings['show_debits_as_negative']) {
                                // Flip axis
                                return (0, format_1.toPrice)(Number(value) * -1, _user.primaryCurrency);
                            }
                            return (0, format_1.toPrice)(value, _user.primaryCurrency);
                        } }),
                },
            },
        } }));
};
exports.default = LineChart;
