"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 *  CreateHalfTransfer.tsx
 */
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const transactions_1 = require("@actions/transactions");
const format_1 = require("@helpers/format");
const EditableAsset_1 = require("@components/elements/EditableAsset");
const EditableString_1 = require("@components/elements/EditableString");
const EditableCategory_1 = require("@components/elements/EditableCategory");
const EditableDate_1 = require("@components/elements/EditableDate");
const BalanceUpdater_1 = require("@components/elements/BalanceUpdater");
const TransactionsProvider_1 = require("@providers/TransactionsProvider");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const CreateHalfTransfer = ({ useModal, utils, data, switchView, setVisibility, }) => {
    const _transactions = (0, react_1.useContext)(TransactionsProvider_1.TransactionsContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const transaction = _transactions.modalData.current;
    const [date, setDate] = (0, react_1.useState)(transaction.formatted_date);
    const [asset, setAsset] = (0, react_1.useState)(null);
    const [category, setCategory] = (0, react_1.useState)(transaction.category_id);
    const [shouldGroup, setShouldGroup] = (0, react_1.useState)(false);
    const [payee, setPayee] = (0, react_1.useState)(`Transfer ${transaction.amount < 0 ? 'to' : 'from'} ${transaction.asset_display_name ||
        transaction.plaid_account_display_name ||
        'Cash'}`);
    const [notes, setNotes] = (0, react_1.useState)(null);
    const [balances, setBalances] = (0, react_1.useState)(null);
    // Setup
    (0, react_1.useEffect)(() => {
        useModal({
            header: (React.createElement("div", null,
                React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        switchView('DETAILS_VIEW');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " Original Transaction Details"),
                React.createElement("span", { className: "header-text-large" }, "Create transfer"))),
            middleButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: true, className: "no-border", onClick: () => {
                    setVisibility(false, true);
                } }, "Cancel")),
        });
    }, []);
    (0, react_1.useEffect)(() => {
        useModal({
            rightButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", disabled: !asset || !date || !category || !payee, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    var _a;
                    let transactionId;
                    // Create transaction
                    const transactionResults = yield utils._process(transactions_1.createTransaction)(Object.assign({ date: date, payee, notes: notes || null, amount: -1 * transaction.amount, currency: transaction.currency, category_id: category }, (asset
                        ? {
                            [asset.source == 'plaid'
                                ? 'plaid_account_id'
                                : 'asset_id']: asset.id > -1 ? asset.id : null,
                        }
                        : {})), { should_convert: true });
                    if (!transactionResults.error) {
                        transactionId = transactionResults.data.transactions[0].id;
                    }
                    // Create grouped transaction
                    if (shouldGroup) {
                        const groupResults = yield utils._process(transactions_1.createTransactionGroup)({
                            transactions: [transaction.id, transactionId],
                            details: {
                                date: date,
                                payee: `Transfer between ${transaction.asset_name ||
                                    transaction.asset_display_name ||
                                    transaction.plaid_account_display_name ||
                                    'Cash'} and ${asset.display_name}`,
                                notes: notes,
                                category_id: category,
                            },
                        });
                        data.amendTransactions &&
                            data.amendTransactions({
                                remove: [transaction.id],
                                add: [
                                    groupResults.data.parent,
                                    ...groupResults.data.children,
                                ],
                            });
                    }
                    else {
                        data.amendTransactions &&
                            data.amendTransactions({
                                add: transactionResults.data.transactions,
                            });
                    }
                    if (((_a = transactionResults.data) === null || _a === void 0 ? void 0 : _a.asset_update) &&
                        transactionResults.data.asset_update.length > 0) {
                        setBalances(transactionResults.data.asset_update);
                    }
                    else {
                        utils._showToast({
                            message: 'Successfully created transfer',
                            type: 'success',
                        });
                    }
                    setVisibility(false, true);
                }) }, "Create")),
        });
    }, [asset, date, shouldGroup, payee, notes, category]);
    return (React.createElement("div", { id: "create-transfer-view" },
        React.createElement(semantic_ui_react_1.Message, { info: true },
            "A ",
            transaction.amount < 0 ? React.createElement("b", null, "debit") : React.createElement("b", null, "credit"),
            " of",
            ' ',
            (0, format_1.toPrice)(Math.abs(transaction.amount), transaction.currency),
            " will be created to represent a transfer of funds",
            ' ',
            transaction.amount < 0 ? 'to' : 'from',
            ' ',
            transaction.asset_name ||
                transaction.asset_display_name ||
                transaction.plaid_account_display_name ||
                'Cash',
            "."),
        React.createElement("div", { id: "recurring-modal-detail-view" },
            React.createElement("div", { className: "transaction-details" },
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null,
                        "Transfer ",
                        transaction.amount < 0 ? 'from' : 'to'),
                    React.createElement(EditableAsset_1.default, { excludeId: transaction.asset_id || transaction.plaid_account_id
                            ? {
                                source: transaction.asset_id ? 'manual' : 'plaid',
                                id: transaction.asset_id || transaction.plaid_account_id,
                            }
                            : null, excludeCash: true, onSave: obj => {
                            setAsset(obj);
                        } })),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Date"),
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(EditableDate_1.default, { identifier: `transfer-date`, firstValue: transaction.formatted_date, format: 'month_day_year', state: 'Editing', location: 'modal', onSave: date => {
                                setDate(date);
                            } }))),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Category"),
                    React.createElement(EditableCategory_1.default, { showError: !category, identifier: `transfer-category`, firstValue: transaction.category_id, state: 'Editing', location: 'modal', onSave: (category_id) => __awaiter(void 0, void 0, void 0, function* () {
                            setCategory(category_id);
                        }) })),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Payee name"),
                    React.createElement(EditableString_1.default, { state: 'Editing', location: 'modal', identifier: 'jfioewxf', autosuggest: true, firstValue: payee, shouldSaveOnChange: true, onSave: payee => {
                            setPayee(payee);
                        } })),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Notes (optional)"),
                    React.createElement(EditableString_1.default, { state: 'Editing', location: 'modal', identifier: 'jfioewf', firstValue: null, shouldSaveOnChange: true, onSave: notes => {
                            setNotes(notes);
                        } })),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: shouldGroup, label: "Group transactions", onChange: (e, { checked }) => {
                            setShouldGroup(checked);
                        } })))),
        React.createElement(BalanceUpdater_1.default, { _showToast: utils._showToast, balances: balances })));
};
exports.default = CreateHalfTransfer;
