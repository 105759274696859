"use strict";
/**
 *  SplitModal.tsx
 *  Recurring expenses edit modal
 */
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const SplitElement_1 = require("@components/Rules/Modal/SplitElement");
const format_1 = require("@helpers/format");
const SplitModal = ({ useModal, utils, data, setVisibility, setDisableOnClickOutside, switchView, _modal, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const _conditions = ((_c = (_b = (_a = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.state) === null || _c === void 0 ? void 0 : _c.conditions) || {};
    const _actions = ((_f = (_e = (_d = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _d === void 0 ? void 0 : _d.current) === null || _e === void 0 ? void 0 : _e.state) === null || _f === void 0 ? void 0 : _f.actions) || {};
    const originalSplit = _actions === null || _actions === void 0 ? void 0 : _actions.split;
    const [amountLeft, setAmountLeft] = (0, react_1.useState)((_g = _conditions === null || _conditions === void 0 ? void 0 : _conditions.amount) === null || _g === void 0 ? void 0 : _g.value_1);
    const [percentageLeft, setPercentageLeft] = (0, react_1.useState)(100);
    const [hasExactAmount, setHasExactAmount] = (0, react_1.useState)(((_h = _conditions === null || _conditions === void 0 ? void 0 : _conditions.amount) === null || _h === void 0 ? void 0 : _h.match) == 'exactly' && !!((_j = _conditions === null || _conditions === void 0 ? void 0 : _conditions.amount) === null || _j === void 0 ? void 0 : _j.value_1));
    const [split, setSplit] = (0, react_1.useState)(((_k = _actions === null || _actions === void 0 ? void 0 : _actions.split) === null || _k === void 0 ? void 0 : _k.map(o => {
        return Object.assign(Object.assign({}, o), { amount: Math.abs(o.amount), amount_pct: Math.abs(o.amount_pct) });
    })) || [
        {
            payee: null,
            tags: null,
            notes: null,
            category_id: null,
            amount: null,
            amount_pct: null,
            mark_as_reviewed: false,
            should_run_rules: false,
        },
        {
            payee: null,
            tags: null,
            notes: null,
            category_id: null,
            amount: null,
            amount_pct: null,
            mark_as_reviewed: false,
            should_run_rules: false,
        },
    ]);
    const isValid = () => {
        var _a;
        if (split.length <= 1) {
            return false;
        }
        if (hasExactAmount) {
            return (amountLeft == (0, format_1.toPrice)(0, (_a = _conditions === null || _conditions === void 0 ? void 0 : _conditions.amount) === null || _a === void 0 ? void 0 : _a.currency) ||
                percentageLeft == 0);
        }
        else {
            return percentageLeft == 0;
        }
    };
    (0, react_1.useEffect)(() => {
        var _a, _b;
        setAmountLeft((0, format_1.toPrice)(Math.abs((_a = _conditions === null || _conditions === void 0 ? void 0 : _conditions.amount) === null || _a === void 0 ? void 0 : _a.value_1) -
            parseFloat(split.reduce((acc, cur) => {
                if (cur === null || cur === void 0 ? void 0 : cur.amount) {
                    return parseFloat(cur.amount) + acc;
                }
                else {
                    return acc;
                }
            }, 0) || 0), (_b = _conditions === null || _conditions === void 0 ? void 0 : _conditions.amount) === null || _b === void 0 ? void 0 : _b.currency));
        setPercentageLeft(100 -
            parseFloat(split.reduce((acc, cur) => {
                if (cur === null || cur === void 0 ? void 0 : cur.amount_pct) {
                    return parseFloat(cur.amount_pct) + acc;
                }
                else {
                    return acc;
                }
            }, 0) || 0));
    }, [split]);
    // Setup
    (0, react_1.useEffect)(() => {
        var _a, _b;
        setDisableOnClickOutside(true);
        useModal({
            header: (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "header-text-large" }, split ? 'Edit split rule' : 'Create split rule'),
                React.createElement(semantic_ui_react_1.Message, { error: !isValid(), success: isValid() },
                    React.createElement("div", { className: "split-amount-left" },
                        hasExactAmount && (React.createElement(React.Fragment, null,
                            React.createElement("span", null,
                                "Original amount:",
                                ' ',
                                (0, format_1.toPrice)(Math.abs((_a = _conditions === null || _conditions === void 0 ? void 0 : _conditions.amount) === null || _a === void 0 ? void 0 : _a.value_1), (_b = _conditions === null || _conditions === void 0 ? void 0 : _conditions.amount) === null || _b === void 0 ? void 0 : _b.currency)),
                            React.createElement("br", null),
                            React.createElement("span", null,
                                "Amount left to split: ",
                                amountLeft))),
                        !hasExactAmount && (React.createElement(React.Fragment, null,
                            React.createElement("span", null, "No exact amount provided, please use percentages."),
                            React.createElement("br", null),
                            React.createElement("span", null,
                                "Percentage left to split: ",
                                percentageLeft,
                                "%"))),
                        split.length < 2 && (React.createElement("p", null, "You must split a transaction into at least 2 parts.")))))),
            middleButton: React.createElement(React.Fragment, null),
            leftButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: true, className: "no-border", onClick: () => {
                    // Go back to original split
                    if (originalSplit) {
                        _actions.split = originalSplit;
                    }
                    else {
                        delete _actions.split;
                    }
                    switchView('ADD_RULE');
                } }, "Back")),
        });
        return () => {
            useModal({
                header: null,
                middleButton: null,
                leftButton: null,
            });
        };
    }, [split, percentageLeft, amountLeft, hasExactAmount]);
    // Setup
    (0, react_1.useEffect)(() => {
        const onClick = () => {
            const splitObj = {
                split: split.map(s => {
                    var _a;
                    if (s.amount && ((_a = _conditions === null || _conditions === void 0 ? void 0 : _conditions.amount) === null || _a === void 0 ? void 0 : _a.type) === 'credits') {
                        s.amount = s.amount * -1;
                    }
                    if (!s.amount) {
                        s.amount = null;
                    }
                    return s;
                }),
            };
            _modal.data.current = Object.assign(Object.assign({}, _modal.data.current), { state: Object.assign(Object.assign({}, _modal.data.current.state), { actions: Object.assign(Object.assign({}, _modal.data.current.actions), splitObj) }) });
            switchView('ADD_RULE');
        };
        useModal({
            rightButton: (React.createElement(semantic_ui_react_1.Button, { disabled: !isValid(), onClick: onClick }, "Save")),
        });
        return () => {
            useModal({
                rightButton: null,
            });
        };
    }, [split, percentageLeft, amountLeft, hasExactAmount]);
    return (React.createElement("div", { id: "transactions-modal-detail-view" },
        split.map((o, index) => {
            var _a, _b;
            return (React.createElement(SplitElement_1.default, { key: `split-${index}`, index: index, hasAmount: hasExactAmount, split: split, currency: (_a = _conditions === null || _conditions === void 0 ? void 0 : _conditions.amount) === null || _a === void 0 ? void 0 : _a.currency, setSplit: setSplit, originalAmount: (_b = _conditions === null || _conditions === void 0 ? void 0 : _conditions.amount) === null || _b === void 0 ? void 0 : _b.value_1 }));
        }),
        React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "mini", onClick: () => {
                setSplit([
                    ...split,
                    {
                        amount: null,
                        payee: null,
                        tags: null,
                        category_id: null,
                        notes: null,
                    },
                ]);
            } }, "Add another split")));
};
exports.default = SplitModal;
