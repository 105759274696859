"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const dates_1 = require("@helpers/dates");
const BudgetSummary_1 = require("@components/Transactions/BudgetSummary/BudgetSummary");
const AccountSummary_1 = require("@components/Transactions/AccountSummary/AccountSummary");
const TagSummary_1 = require("@components/Transactions/TagSummary/TagSummary");
const UserProvider_1 = require("@providers/UserProvider");
const Summary = ({ currentPeriod, triggerRefresh }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [summaryView, setSummaryView] = (0, react_1.useState)(localStorage.getItem('_lm_transactions_summary_view') || 'category');
    const [showAsPercentage, setShowAsPercentage] = (0, react_1.useState)(localStorage.getItem('_lm_budget_summary_show_as_percentage') === 'true');
    const [budgetColors, setBudgetColors] = (0, react_1.useState)(localStorage.getItem('_lm_budget_summary_colors') || 'multiple');
    return (React.createElement(semantic_ui_react_1.Card, { className: "p-budget-summary" },
        React.createElement(semantic_ui_react_1.Card.Content, null,
            React.createElement(semantic_ui_react_1.Card.Header, null,
                React.createElement("div", { className: "flex--space-between-flex-start" },
                    React.createElement("span", null),
                    React.createElement("span", null,
                        Moment(currentPeriod).format('MMMM'),
                        " Summary"),
                    React.createElement(semantic_ui_react_1.Popup, { wide: true, on: "click", position: "bottom right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "cog", color: "grey", className: "clickable" }), content: React.createElement("div", null,
                            React.createElement("div", { className: "mb-05rem" },
                                "View summary by",
                                React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-05rem inline-breakdown-dropdown", inline: true, defaultValue: summaryView, onChange: (e, { value }) => {
                                        setSummaryView(value);
                                        localStorage.setItem('_lm_transactions_summary_view', value);
                                    }, options: [
                                        {
                                            key: 'category',
                                            value: 'category',
                                            text: 'category',
                                        },
                                        {
                                            key: 'account',
                                            value: 'account',
                                            text: 'account',
                                        },
                                        {
                                            key: 'tag',
                                            value: 'tag',
                                            text: 'tag',
                                        },
                                    ] })),
                            React.createElement("div", { className: "mb-05rem" },
                                "Display numbers as",
                                React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-05rem inline-breakdown-dropdown", inline: true, defaultValue: showAsPercentage ? 'percentage' : 'amount', onChange: (e, { value }) => {
                                        setShowAsPercentage(value == 'percentage');
                                        localStorage.setItem('_lm_budget_summary_show_as_percentage', value == 'percentage' ? 'true' : 'false');
                                    }, options: [
                                        {
                                            key: 'percentage',
                                            value: 'percentage',
                                            text: 'percentage',
                                        },
                                        {
                                            key: 'amount',
                                            value: 'amount',
                                            text: 'amount',
                                        },
                                    ] })),
                            React.createElement("div", null,
                                "Use",
                                React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-05rem mr-05rem inline-breakdown-dropdown", inline: true, defaultValue: budgetColors, onChange: (e, { value }) => {
                                        setBudgetColors(value);
                                        localStorage.setItem('_lm_budget_summary_colors', value);
                                    }, options: [
                                        {
                                            key: 'single',
                                            value: 'single',
                                            text: 'single',
                                        },
                                        {
                                            key: 'multiple',
                                            value: 'multiple',
                                            text: 'multiple',
                                        },
                                    ] }),
                                ' ',
                                "colors for budget bars")) })))),
        summaryView === 'category' && (React.createElement(BudgetSummary_1.default, { start_date: currentPeriod, end_date: (0, dates_1.getThisMonthEnd)(currentPeriod), showTodayLine: currentPeriod ===
                Moment()
                    .startOf('month')
                    .format('YYYY-MM-DD'), triggerRefresh: triggerRefresh, displayCurrency: _user.primaryCurrency, showAsPercentage: showAsPercentage, budgetColors: budgetColors, flipSign: _user.settings['show_debits_as_negative'], allowRecurringCategories: _user.settings['allow_recurring_categories'] })),
        summaryView === 'account' && (React.createElement(AccountSummary_1.default, { start_date: currentPeriod, end_date: (0, dates_1.getThisMonthEnd)(currentPeriod), triggerRefresh: triggerRefresh, displayCurrency: _user.primaryCurrency, flipSign: _user.settings['show_debits_as_negative'] })),
        summaryView === 'tag' && (React.createElement(TagSummary_1.default, { start_date: currentPeriod, end_date: (0, dates_1.getThisMonthEnd)(currentPeriod), triggerRefresh: triggerRefresh, displayCurrency: _user.primaryCurrency, currentFilterName: null, flipSign: _user.settings['show_debits_as_negative'] }))));
};
exports.default = Summary;
