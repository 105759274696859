"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_1 = require("@helpers/format");
const ChooseType = ({ data, type, selectedType, selectType, addOther }) => {
    if (addOther) {
        data = data.concat({
            id: 9999,
            name: 'Other',
            subtype_id: 9999,
            subtype_name: 'Other',
        });
    }
    return data.map(datum => {
        return (React.createElement("div", { key: `link-recurring-${datum[type == 'type' ? 'id' : 'subtype_id']}`, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                selectType(datum);
            }), className: `link-recurring-row ${selectedType === datum[type == 'type' ? 'id' : 'subtype_id']
                ? 'selected'
                : ''}` },
            React.createElement("div", { className: "display--flex" },
                React.createElement("span", null, (0, format_1.capitalize)(datum[type == 'type' ? 'name' : 'subtype_name'])),
                datum[type == 'type' ? 'name' : 'subtype_name'] ==
                    'cryptocurrency' && (React.createElement("span", { className: "color--green font-size-smaller" }, "(NEW!)"))),
            selectedType === datum[type == 'type' ? 'id' : 'subtype_id'] ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))));
    });
};
exports.default = ChooseType;
