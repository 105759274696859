"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_1 = require("@helpers/format");
const EditableString_1 = require("@components/elements/EditableString");
const EditableCategory_1 = require("@components/elements/EditableCategory");
const EditableAsset_1 = require("@components/elements/EditableAsset");
const EditablePrice_1 = require("@components/elements/EditablePrice");
const TagsProvider_1 = require("@providers/TagsProvider");
const RecurringProvider_1 = require("@/providers/RecurringProvider");
const FilterRow = ({ index, duplicate, remove, allFilters }) => {
    const MATCH_TYPES = [
        { key: 'exact', value: 'exact', text: 'matches exactly' },
        { key: 'contain', value: 'contain', text: 'contains' },
        { key: 'start_with', value: 'start_with', text: 'starts with' },
        { key: 'is_anything', value: 'is_anything', text: 'is anything' },
    ];
    const RELATION_TYPES = [
        { key: 'is', value: 'is', text: 'is' },
        { key: 'is not', value: 'is not', text: 'is not' },
    ];
    const AMOUNT_MATCH_TYPES = [
        { key: 'is_anything', value: 'is_anything', text: 'is anything' },
        { key: 'greater_than', value: 'greater_than', text: 'greater than' },
        {
            key: 'greater_than_or_equal_to',
            value: 'greater_equal',
            text: 'greater than or equal to',
        },
        { key: 'less_than', value: 'less_than', text: 'less than' },
        {
            key: 'less_than_or_equal_to',
            value: 'less_equal',
            text: 'less than or equal to',
        },
        { key: 'exactly', value: 'exactly', text: 'exactly' },
        { key: 'between', value: 'between', text: 'between' },
    ];
    const _tags = (0, react_1.useContext)(TagsProvider_1.TagsContext);
    const _recurring = (0, react_1.useContext)(RecurringProvider_1.RecurringContext);
    const FILTERS = [
        { text: 'General Search', value: 'general_search', disabled: false },
        { text: 'By Trigger', disabled: true },
        {
            text: 'Payee (trigger)',
            content: 'Payee',
            value: 'trigger_payee',
            disabled: false,
        },
        {
            text: 'Notes (trigger)',
            content: 'Notes',
            value: 'trigger_notes',
            disabled: false,
        },
        {
            text: 'Expense (trigger)',
            content: 'Expense',
            value: 'trigger_expense',
            disabled: false,
        },
        {
            text: 'Income (trigger)',
            content: 'Income',
            value: 'trigger_income',
            disabled: false,
        },
        // { text: 'day', value: 'trigger_day', disabled: false },
        // { text: 'account', value: 'trigger_account', disabled: false },
        { text: 'by Effect', disabled: true },
        {
            text: 'Payee (effect)',
            content: 'Payee',
            value: 'effect_payee',
            disabled: false,
        },
        // {
        //   text: 'category (effect)',
        //   content: 'category',
        //   value: 'effect_category',
        //   disabled: false,
        // },
        {
            text: 'Notes (effect)',
            content: 'Notes',
            value: 'effect_notes',
            disabled: false,
        },
        // { text: 'tags', value: 'effect_tags', disabled: false },
        {
            text: 'Action (effect)',
            content: 'Action (effect)',
            value: 'effect_action',
            disabled: false,
        },
    ];
    const ACTIONS = [
        'delete transaction',
        'link recurring',
        'skip recurring link',
        'skip create rule',
        'split transaction',
        'mark reviewed',
        'send an email',
        'stop processing other rules',
        'delete after use',
    ];
    const [criteria, setCriteria] = (0, react_1.useState)(null);
    const [relation, setRelation] = (0, react_1.useState)(null);
    const [value, setValue] = (0, react_1.useState)(undefined);
    const [value2, setValue2] = (0, react_1.useState)(undefined);
    const [currency, setCurrency] = (0, react_1.useState)(null);
    const [options, setOptions] = (0, react_1.useState)([]);
    (0, react_1.useEffect)(() => {
        var _a;
        if (allFilters.current.hasOwnProperty(index)) {
            setCriteria(allFilters.current[index].criteria);
            setRelation(allFilters.current[index].relation);
            if (allFilters.current[index].criteria.indexOf('account') > -1) {
                if (!allFilters.current[index].value ||
                    ((_a = allFilters.current[index].value) === null || _a === void 0 ? void 0 : _a.toString().indexOf('plaid')) > -1) {
                    setValue(allFilters.current[index].value);
                }
                else {
                    setValue(parseInt(allFilters.current[index].value));
                }
            }
            else {
                setValue(allFilters.current[index].value);
                setValue2(allFilters.current[index].value2);
                setCurrency(allFilters.current[index].currency);
            }
        }
    }, []);
    (0, react_1.useEffect)(() => {
        if (criteria === 'effect_action') {
            setOptions(ACTIONS.map(action => {
                return {
                    key: action,
                    value: action.replace(/ /g, '_'),
                    text: (0, format_1.capitalize)(action),
                };
            }));
        }
        else if (criteria === 'tag') {
            setOptions([
                {
                    key: 'untagged',
                    value: 'untagged',
                    text: '(untagged)',
                },
                ..._tags.tags.map(tag => {
                    return {
                        key: `tag-${tag.id}`,
                        value: tag.id,
                        text: tag.name,
                    };
                }),
            ]);
        }
        else if (criteria === 'recurring') {
            setOptions([
                {
                    key: 'recurring-any',
                    value: 'any',
                    text: '(any)',
                },
                ..._recurring.dropdownOptions,
            ]);
        }
    }, [criteria]);
    return (React.createElement("div", { className: "filter-row" },
        React.createElement("div", { className: "display--flex" },
            React.createElement("div", { className: "filter-row-criteria" },
                React.createElement(semantic_ui_react_1.Dropdown
                // defaultOpen={criteria == null}
                , { 
                    // defaultOpen={criteria == null}
                    selection: true, className: "filter-row-dropdown filter-row-dropdown-criteria", value: criteria == 'asset' ? 'account' : criteria, placeholder: "Choose filter", options: FILTERS.map(filter => {
                        return {
                            disabled: filter.disabled,
                            key: filter.value || filter.text,
                            value: filter.value || filter.text,
                            text: filter.text,
                            content: filter.content || filter.text,
                        };
                    }), onChange: (e, { value }) => {
                        let _relation = value == 'effect_action' || value == 'general_search'
                            ? 'is'
                            : 'is_anything';
                        setCriteria(value);
                        setRelation(_relation);
                        setValue(value == 'effect_action'
                            ? 'delete_transaction'
                            : value == 'general_search' ||
                                value.toString().indexOf('payee') > -1 ||
                                value.toString().indexOf('notes') > -1
                                ? ''
                                : true);
                        allFilters.current = Object.assign(Object.assign({}, allFilters.current), { [index]: {
                                criteria: value,
                                relation: _relation,
                                value: value == 'effect_action'
                                    ? 'delete_transaction'
                                    : value == 'general_search'
                                        ? ''
                                        : true,
                                value2: null,
                            } });
                    } })),
            criteria && (React.createElement("div", { className: "filter-row-relation" }, criteria.indexOf('payee') > -1 ||
                criteria.indexOf('notes') > -1 ||
                ['trigger_expense', 'trigger_income', 'day'].indexOf(criteria) > -1 ? (React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-1rem mr-1rem p-match-inline width-100", inline: true, value: relation ||
                    (['trigger_expense', 'trigger_income', 'day'].indexOf(criteria) > -1
                        ? 'exactly'
                        : 'contain'), options: ['trigger_expense', 'trigger_income', 'day'].indexOf(criteria) > -1
                    ? AMOUNT_MATCH_TYPES
                    : MATCH_TYPES, onChange: (e, { value }) => {
                    setRelation(value);
                    allFilters.current = Object.assign(Object.assign({}, allFilters.current), { [index]: Object.assign(Object.assign({}, allFilters.current[index]), { relation: value }) });
                } })) : (React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-1rem mr-1rem p-match-inline filter-row-relation", inline: true, disabled: criteria == 'general_search', value: relation || 'is', options: RELATION_TYPES, onChange: (e, { value }) => {
                    setRelation(value);
                    allFilters.current = Object.assign(Object.assign({}, allFilters.current), { [index]: Object.assign(Object.assign({}, allFilters.current[index]), { relation: value }) });
                } }))))),
        React.createElement("div", { className: "display--flex flex--align-center flex-grow" },
            criteria && relation !== 'is_anything' && (React.createElement("div", { className: "filter-row-value" },
                (criteria.indexOf('payee') > -1 ||
                    criteria.indexOf('notes') > -1 ||
                    criteria === 'general_search') &&
                    relation !== 'is_anything' && (React.createElement(EditableString_1.default, { placeholder: 'Enter value', identifier: `bulk-edit-name`, firstValue: value, shouldSaveOnChange: true, location: 'modal', state: 'Editing', autosuggest: criteria.indexOf('payee') > -1, onSave: name => {
                        setValue(name);
                        allFilters.current = Object.assign(Object.assign({}, allFilters.current), { [index]: Object.assign(Object.assign({}, allFilters.current[index]), { value: name }) });
                    } })),
                criteria.indexOf('category') > -1 && (React.createElement(EditableCategory_1.default, { identifier: `filter-category-${index}`, location: 'filter-row', className: "filter-row-dropdown filter-row-dropdown-value", firstValue: value, state: 'Editing', placeholder: "Select category", allowAdditions: false, allowSelectingGroups: true, showUncategorized: false, extraValues: [
                        {
                            key: 'uncategorized',
                            value: 'uncategorized',
                            text: '(uncategorized)',
                        },
                        {
                            key: 'income',
                            value: 'income',
                            text: '(treat as income)',
                        },
                        {
                            key: 'exclude_from_totals',
                            value: 'exclude_from_totals',
                            text: '(exclude from totals)',
                        },
                        {
                            key: 'exclude_from_budget',
                            value: 'exclude_from_budget',
                            text: '(exclude from budget)',
                        },
                    ], onSave: (category_id) => __awaiter(void 0, void 0, void 0, function* () {
                        setValue(category_id);
                        allFilters.current = Object.assign(Object.assign({}, allFilters.current), { [index]: Object.assign(Object.assign({}, allFilters.current[index]), { value: category_id }) });
                    }) })),
                ['trigger_expense', 'trigger_income', 'day'].indexOf(criteria) > -1 &&
                    relation !== 'between' && (React.createElement(EditablePrice_1.default, { identifier: "criteria-amount", amount: value, currency: currency, location: 'modal', autoFocus: false, state: 'Editing', saveOnChange: true, saveOnError: true, allowNegative: false, onSave: (amount, currency) => __awaiter(void 0, void 0, void 0, function* () {
                        setValue(amount);
                        setCurrency(currency);
                        allFilters.current = Object.assign(Object.assign({}, allFilters.current), { [index]: Object.assign(Object.assign({}, allFilters.current[index]), { value: amount, value2: null, currency }) });
                    }) })),
                (criteria === 'account' || criteria === 'asset') && (React.createElement(EditableAsset_1.default, { assignableOnly: false, location: 'filter-row', className: "filter-row-dropdown filter-row-dropdown-value", defaultSelection: {
                        source: criteria == 'account'
                            ? 'plaid'
                            : value == 'cash'
                                ? null
                                : 'manual',
                        id: value == 'cash' ? -1 : parseInt(value),
                    }, onSave: value => {
                        setValue(value.id);
                        setCriteria(value.source == 'plaid' ? 'account' : 'asset');
                        allFilters.current = Object.assign(Object.assign({}, allFilters.current), { [index]: Object.assign(Object.assign({}, allFilters.current[index]), { criteria: value.source == 'plaid' ? 'account' : 'asset', value: value.id == -1 ? 'cash' : value.id }) });
                    } })),
                ['effect_action', 'tag', 'recurring'].indexOf(criteria) > -1 && (React.createElement(semantic_ui_react_1.Dropdown, { className: "filter-row-dropdown filter-row-dropdown-value", search: criteria !== 'effect_action', fluid: true, selection: true, disabled: criteria == 'file', value: value, placeholder: `Select ${criteria}`, options: options, onChange: (e, { value }) => {
                        setValue(value);
                        allFilters.current = Object.assign(Object.assign({}, allFilters.current), { [index]: Object.assign(Object.assign({}, allFilters.current[index]), { value }) });
                    } })),
                ['trigger_expense', 'trigger_income', 'day'].indexOf(criteria) > -1 &&
                    relation === 'between' && (React.createElement("div", { className: "flex--align-center" },
                    React.createElement(EditablePrice_1.default, { identifier: "criteria-amount", amount: value, currency: currency, location: 'modal', autoFocus: false, state: 'Editing', saveOnChange: true, saveOnError: true, onSave: (amount, currency) => __awaiter(void 0, void 0, void 0, function* () {
                            setValue(amount);
                            setCurrency(currency);
                            allFilters.current = Object.assign(Object.assign({}, allFilters.current), { [index]: Object.assign(Object.assign({}, allFilters.current[index]), { value: amount, currency }) });
                        }) }),
                    React.createElement("span", { className: "mr-1rem ml-05rem monospace" }, "&"),
                    React.createElement(EditablePrice_1.default, { identifier: `criteria-amount-2`, amount: value2, currency: currency, location: 'modal', state: 'Editing', saveOnChange: true, allowNegative: false, onSave: (amount, currency) => __awaiter(void 0, void 0, void 0, function* () {
                            setValue2(amount);
                            setCurrency(currency);
                            allFilters.current = Object.assign(Object.assign({}, allFilters.current), { [index]: Object.assign(Object.assign({}, allFilters.current[index]), { value2: amount, currency }) });
                        }) }))))),
            React.createElement(semantic_ui_react_1.Button, { icon: true, basic: true, disabled: criteria == 'file' || criteria == 'general search', color: "orange", size: "small", className: "ml-05rem", onClick: () => {
                    duplicate({
                        criteria,
                        relation,
                        value,
                        value2,
                        currency,
                    });
                } },
                React.createElement(semantic_ui_react_1.Icon, { name: "copy outline" })),
            React.createElement(semantic_ui_react_1.Button, { icon: true, basic: true, color: "red", size: "small", onClick: () => {
                    remove(index);
                    delete allFilters.current[index];
                } },
                React.createElement(semantic_ui_react_1.Icon, { name: "x" })))));
};
exports.default = FilterRow;
