"use strict";
/**
 *  CategoriesSetup.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const CategoriesProvider_1 = require("@providers/CategoriesProvider");
const CategoriesSetup = ({ update }) => {
    const [selectedCategories, setSelectedCategories] = (0, react_1.useState)([]);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    (0, react_1.useEffect)(() => {
        if (_categories.all.length == 0) {
            // User doesn't have any categories set up, so just set up with default for now
            const selected = [];
            _categories.defaultCategories.map(category => {
                if (category.default_selected) {
                    selected.push(category.name);
                }
            });
            setSelectedCategories(selected);
            // Send now to parent, in case user doesn't change anything
            update(selected);
        }
        else {
            const selected = [];
            _categories.all.forEach(category => {
                selected.push(category.name);
            });
            setSelectedCategories(selected);
        }
    }, []);
    return (React.createElement("div", { className: "g-categories-setup" },
        ' ',
        React.createElement("div", { className: "header-container" },
            React.createElement("span", { className: "step-label" }, "Step 1"),
            React.createElement(semantic_ui_react_1.Header, { as: "h2" }, "Customize your categories")),
        React.createElement("p", null, "Let's get to know you and shape Lunch Money to your current habits. What are you trying to track? We've selected a few for you already to start off."),
        React.createElement("div", { className: "category-content" }, _categories.defaultCategories.map((category, index) => {
            return (React.createElement("div", { key: index, className: `setup-category ${selectedCategories.indexOf(category.name) > -1 ? 'added' : ''}`, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    if (selectedCategories.indexOf(category.name) > -1) {
                        const updateObj = selectedCategories.filter(o => o !== category.name);
                        setSelectedCategories(updateObj);
                        update(updateObj);
                    }
                    else {
                        setSelectedCategories([...selectedCategories, category.name]);
                        update([...selectedCategories, category.name]);
                    }
                }) },
                React.createElement("span", { className: "category-payee" }, category.name),
                selectedCategories.indexOf(category.name) > -1 ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))));
        }))));
};
exports.default = CategoriesSetup;
