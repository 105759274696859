"use strict";
/**
 *  NewTransactionRow.tsx
 *  Row for adding a new transaction to the transaction table
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
// Components
const EditableString_1 = require("@components/elements/EditableString");
const react_1 = require("react");
const NewTagRow = ({ onSave }) => {
    const [backgroundColor, setBackgroundColor] = (0, react_1.useState)(null);
    const [textColor, setTextColor] = (0, react_1.useState)(null);
    const [name, setName] = (0, react_1.useState)(null);
    const [description, setDescription] = (0, react_1.useState)(null);
    const [newTag, setNewTag] = (0, react_1.useState)({});
    return (React.createElement(semantic_ui_react_1.Table.Row, { className: "editing" },
        React.createElement(semantic_ui_react_1.Table.Cell, null,
            React.createElement(EditableString_1.default, { autoFocus: true, location: 'inline', shouldSaveOnChange: true, identifier: "new-tx-payee", firstValue: name, state: 'Editing', onSave: name => {
                    setNewTag(Object.assign(Object.assign({}, newTag), { name }));
                }, onEnter: () => __awaiter(void 0, void 0, void 0, function* () {
                    onSave(newTag);
                }) })),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        React.createElement(semantic_ui_react_1.Table.Cell, null,
            React.createElement(EditableString_1.default, { shouldSaveOnChange: true, location: 'inline', identifier: "new-tx-payee", firstValue: description, state: 'Editing', onSave: description => {
                    setNewTag(Object.assign(Object.assign({}, newTag), { description }));
                }, onEnter: () => __awaiter(void 0, void 0, void 0, function* () {
                    onSave(newTag);
                }) })),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align" }),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align" }),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "number" },
            React.createElement(semantic_ui_react_1.Button, { className: 'green-hover', size: "tiny", color: "green", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    onSave(newTag);
                }) }, "Add"))));
};
exports.default = NewTagRow;
