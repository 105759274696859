"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const StatsCard = ({ header, isLoading = false, data = [], mapFn = (a, b) => { }, }) => {
    const [limit, setLimit] = (0, react_1.useState)(10);
    const [skip, setSkip] = (0, react_1.useState)(0);
    return (React.createElement(semantic_ui_react_1.Card, { fluid: true },
        React.createElement(semantic_ui_react_1.Card.Content, { header: header, className: "card-header" }),
        React.createElement(semantic_ui_react_1.Card.Content, null,
            React.createElement(semantic_ui_react_1.Table, { unstackable: true, selectable: true, celled: true, basic: "very" },
                React.createElement(semantic_ui_react_1.Table.Body, null,
                    isLoading && (React.createElement(semantic_ui_react_1.Table.Row, null,
                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell" },
                            React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "small" }, "Loading data..")))),
                    !isLoading &&
                        data.slice(skip, skip + limit).map((o, index) => {
                            return mapFn(o, index + skip);
                        }),
                    !isLoading && data.length == 0 && (React.createElement(semantic_ui_react_1.Table.Row, null,
                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align padded-cell" }, "No data available."))))),
            !isLoading && data.length > 10 && (React.createElement("div", { className: "pagination-container flex--space-between" },
                React.createElement("div", { className: "pagination-amount mini" }, [
                    data.length > 10 ? 10 : null,
                    data.length < 10 ? null : 20,
                    data.length <= 20 ? null : 50,
                ]
                    .filter(o => !!o)
                    .map(option => {
                    return (React.createElement("a", { key: `limit-option-${option}`, onClick: () => {
                            setLimit(option);
                            setSkip(0);
                        }, className: `choice ${limit == option ? 'active' : ''}` }, option == 10000 ? 'All' : option));
                })),
                React.createElement(semantic_ui_react_1.Pagination, { className: "align-center", size: "mini", activePage: Math.floor(skip / limit) + 1, onPageChange: (e, { activePage }) => {
                        setSkip((activePage - 1) * limit);
                    }, totalPages: Math.ceil(data.length / limit), prevItem: null, nextItem: null }))))));
};
exports.default = StatsCard;
