"use strict";
/**
 * UserProvider
 * providers data on:
 * - user settings (preferences)
 * - account settings (currencies)
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserProvider = exports.UserContext = void 0;
const React = require("react");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const auth_1 = require("@actions/auth");
const user_1 = require("@actions/user");
const account_1 = require("@actions/account");
const user_setting_1 = require("@actions/user_setting");
const BillingProvider_1 = require("@/providers/BillingProvider");
const AuthProvider_1 = require("@providers/AuthProvider");
const UserContext = (0, react_1.createContext)({
    // User details
    userId: null,
    accountId: null,
    isAdmin: null,
    name: null,
    email: null,
    joinDate: null,
    type: null,
    isDemo: false,
    isBeta: false,
    isAdminUser: false,
    verifiedEmail: false,
    hasFetchedUser: false,
    updateUser: obj => { },
    fetchAccountDetails: () => __awaiter(void 0, void 0, void 0, function* () {
        return { collaborators: null };
    }),
    fetchUserDetails: () => __awaiter(void 0, void 0, void 0, function* () { }),
    hasStripeAccount: false,
    // User preferences
    settings: {},
    updateSetting: (key, value) => { },
    hasFetchedSettings: false,
    getMonthDayFormat: () => {
        return 'MMM D';
    },
    getMonthYearFormat: () => {
        return 'MMM YYYY';
    },
    getMonthDayYearFormat: () => {
        return 'MMM D, YYYY';
    },
    getFullTimeFormat: () => {
        return 'MMM D, YYYY at h:mm';
    },
    getFormat: type => {
        return 'MMM D, YYYY';
    },
    // Account settings
    primaryCurrency: null,
    supportedCurrencies: [],
    displayName: null,
    locale: null,
    hasFetchedAccountSettings: false,
    updateAccountSettings: obj => { },
    //
    allAccounts: [],
    currentAccount: null,
    signout: type => { },
});
exports.UserContext = UserContext;
const UserProvider = props => {
    let history = (0, react_router_dom_1.useHistory)();
    let location = (0, react_router_dom_1.useLocation)();
    const _billing = (0, react_1.useContext)(BillingProvider_1.BillingContext);
    const _auth = (0, react_1.useContext)(AuthProvider_1.AuthContext);
    const userId = (0, react_1.useRef)(null);
    // User details
    const [isAdmin, setIsAdmin] = (0, react_1.useState)(null);
    const [name, setName] = (0, react_1.useState)(null);
    const [email, setEmail] = (0, react_1.useState)(null);
    const [joinDate, setJoinDate] = (0, react_1.useState)(null);
    const [hasFetchedUser, setHasFetchedUser] = (0, react_1.useState)(false);
    const [isDemo, setIsDemo] = (0, react_1.useState)(false);
    const [isBeta, setIsBeta] = (0, react_1.useState)(false);
    const [type, setType] = (0, react_1.useState)(null);
    const [isAdminUser, setIsAdminUser] = (0, react_1.useState)(false);
    const [hasStripeAccount, setHasStripeAccount] = (0, react_1.useState)(false);
    const [verifiedEmail, setVerifiedEmail] = (0, react_1.useState)(false);
    // Accounts
    const [accountId, setAccountId] = (0, react_1.useState)(null);
    const [allAccounts, setAllAccounts] = (0, react_1.useState)([]);
    const currentAccount = (0, react_1.useRef)(null);
    // User preferences
    const [settings, setSettings] = (0, react_1.useState)({});
    const [hasFetchedSettings, setHasFetchedSettings] = (0, react_1.useState)(false);
    // Account settings
    const [primaryCurrency, setPrimaryCurrency] = (0, react_1.useState)(null);
    const [supportedCurrencies, setSupportedCurrencies] = (0, react_1.useState)([]);
    const [displayName, setDisplayName] = (0, react_1.useState)(null);
    const [locale, setLocale] = (0, react_1.useState)(null);
    const [hasFetchedAccountSettings, setHasFetchedAccountSettings] = (0, react_1.useState)(false);
    /** GENERAL */
    (0, react_1.useEffect)(() => {
        console.log('[User Provider] Loaded.');
    }, []);
    (0, react_1.useEffect)(() => {
        if (_billing.isReady) {
            fetchUserSettings();
            fetchAccountSettings();
            fetchUserDetails();
        }
    }, [_billing.isReady]);
    const updateUser = (obj) => __awaiter(void 0, void 0, void 0, function* () {
        if (obj.hasOwnProperty('name')) {
            setName(obj.name);
        }
        if (obj.hasOwnProperty('email')) {
            setEmail(obj.email);
        }
    });
    /** USER DETAILS */
    const fetchUserDetails = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const results = yield (0, user_1.getUser)();
        if (!results.error) {
            setName(results.name);
            setEmail(results.email);
            setJoinDate(results.join_date);
            setHasFetchedUser(true);
            setIsDemo(results.is_demo);
            setIsBeta(results.is_beta);
            setIsAdminUser(results.is_admin_user);
            setType(results.type);
            setAccountId(results.account_id);
            userId.current = results.id;
            setHasStripeAccount(results.has_stripe_account);
            setVerifiedEmail(results.verified_email);
            console.log('[User Provider] Done fetching user details');
            // Are we on staging or beta?
            if (window.location.hostname.indexOf('beta.lunchmoney') > -1) {
                if (!results.is_beta) {
                    window.location.href = `https://my.lunchmoney.app${location.pathname}`;
                }
            }
            if (window.location.hostname.indexOf('staging.lunchmoney') > -1) {
                if (!results.is_admin_user) {
                    window.location.href = `https://my.lunchmoney.app${location.pathname}`;
                }
            }
            // Sentry
            // Sentry.configureScope(scope => {
            //   scope.setExtra('account_id', results.account_id)
            //   scope.setUser({ id: results.user_id, email: results.email })
            // })
            // Setup Canny
            if (window['Canny'] && window.location.host.indexOf('localhost') == -1) {
                window['Canny']('identify', {
                    appID: '5e8dfd734afa670fb480a3d6',
                    user: {
                        email: results.email,
                        name: results.name,
                        id: results.id,
                        created: new Date(results.join_date).toISOString(),
                        customFields: {
                            user_type: (_a = _billing === null || _billing === void 0 ? void 0 : _billing.subscription) === null || _a === void 0 ? void 0 : _a.user_type,
                            status: _billing === null || _billing === void 0 ? void 0 : _billing.status,
                            trial_end: (_billing === null || _billing === void 0 ? void 0 : _billing.trialEnd) || '',
                            primary_currency: results.primary_currency,
                        },
                    },
                });
            }
        }
        // Make sure we have the user Id
        fetchAccountDetails();
        return results;
    });
    /** ACCOUNT DETAILS */
    const fetchAccountDetails = () => __awaiter(void 0, void 0, void 0, function* () {
        // Get accounts
        const _allAccounts = yield (0, account_1.getAll)();
        setAllAccounts(_allAccounts.filter(o => o.status != 'deleting'));
        currentAccount.current = _allAccounts.find(o => o.current);
        setIsAdmin(_allAccounts
            .find(o => o.current)
            .collaborators.find(o => o.id == userId.current).access_level ==
            'admin');
        return currentAccount.current;
    });
    /** USER SETTINGS */
    const getFormat = format => {
        if (format == 'month_day') {
            return getMonthDayFormat();
        }
        else if (format == 'month_year') {
            return getMonthYearFormat();
        }
        else {
            return getMonthDayYearFormat();
        }
    };
    // with time
    const getFullTimeFormat = () => {
        return `${settings['month_day_year_format']} [at] ${settings['show_am_pm'] ? 'h:mma' : 'H:mm'}`;
    };
    const getMonthYearFormat = () => {
        return settings['month_year_format'];
    };
    const getMonthDayYearFormat = () => {
        return settings['month_day_year_format'];
    };
    const getMonthDayFormat = () => {
        if (settings['always_display_year']) {
            return settings['month_day_year_format'];
        }
        const format = [];
        if (settings['always_display_weekday']) {
            format.push('ddd, ');
        }
        format.push(settings['month_day_format']);
        return format.join('');
    };
    const fetchUserSettings = () => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield (0, user_setting_1.getUserSettings)();
        if (!results.error) {
            setSettings(results);
            // Apply dark mode from settings, fresh login
            if (results.dark_mode_setting == 'never') {
                document.body.classList.remove('dark');
                document.body.classList.remove('dark-mode');
                document.body.classList.add('no-dark');
                localStorage.setItem('_lm_dark_mode_setting', 'never');
            }
            else if (window.matchMedia('(prefers-color-scheme: dark)').matches ||
                results.dark_mode_setting == 'always') {
                document.body.classList.remove('no-dark');
                document.body.classList.add('dark');
                document.body.classList.add('dark-mode');
                if (results.dark_mode_setting == 'always') {
                    localStorage.setItem('_lm_dark_mode_setting', 'dark');
                }
            }
            else {
                // Is light mode and system settings
                document.body.classList.remove('dark');
                document.body.classList.remove('dark-mode');
                localStorage.removeItem('_lm_dark_mode_setting');
            }
            setHasFetchedSettings(true);
            console.log('[User Provider] Done fetching user settings');
        }
        return results;
    });
    const updateSetting = (key, value) => __awaiter(void 0, void 0, void 0, function* () {
        yield (0, user_setting_1.updateUserSetting)({ [key]: value });
        setSettings(Object.assign(Object.assign({}, settings), { [key]: value }));
    });
    /** ACCOUNT SETTINGS */
    const fetchAccountSettings = () => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield (0, user_1.getAccountPreferences)();
        if (!results.error) {
            setPrimaryCurrency(results.primary_currency);
            setSupportedCurrencies(results.supported_currencies
                ? [
                    ...new Set([
                        results.primary_currency,
                        ...results.supported_currencies.split(','),
                    ]),
                ]
                : [results.primary_currency]);
            setDisplayName(results.display_name);
            setLocale(results.locale || results.default_locale);
            setHasFetchedAccountSettings(true);
            localStorage.setItem('_lm_locale', results.locale || results.default_locale || 'en-US');
            console.log('[User Provider] Done fetching account settings');
        }
    });
    const updateAccountSettings = (obj) => __awaiter(void 0, void 0, void 0, function* () {
        yield (0, user_1.updateAccountPreferences)(obj);
        if (obj.hasOwnProperty('primary_currency')) {
            setPrimaryCurrency(obj.primary_currency);
        }
        if (obj.hasOwnProperty('supported_currencies')) {
            setSupportedCurrencies([
                ...new Set([
                    obj.primary_currency,
                    ...obj.supported_currencies.split(','),
                ]),
            ]);
        }
        if (obj.hasOwnProperty('display_name')) {
            setDisplayName(obj.display_name);
        }
        if (obj.hasOwnProperty('locale')) {
            setLocale(obj.locale);
            localStorage.setItem('_lm_locale', obj.locale || 'en-US');
        }
    });
    const signout = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (force = false) {
        yield (0, auth_1.logout)(force);
        _auth.setIsLoggedIn(false);
        history.push('/logout');
    });
    return (React.createElement(UserContext.Provider, { value: {
            name,
            userId: userId.current,
            accountId,
            isAdmin,
            email,
            joinDate,
            type,
            isDemo,
            isBeta,
            isAdminUser,
            hasFetchedUser,
            updateUser,
            hasStripeAccount,
            verifiedEmail,
            //
            fetchAccountDetails,
            //
            settings,
            updateSetting,
            hasFetchedSettings,
            getMonthDayFormat,
            getMonthYearFormat,
            getMonthDayYearFormat,
            getFullTimeFormat,
            getFormat,
            //
            fetchUserDetails,
            //
            primaryCurrency,
            supportedCurrencies,
            displayName,
            locale,
            hasFetchedAccountSettings,
            updateAccountSettings,
            //
            allAccounts,
            currentAccount: currentAccount.current,
            //
            signout,
        } }, props.children));
};
exports.UserProvider = UserProvider;
