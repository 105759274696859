"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const Moment = require("moment");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
const dates_1 = require("../helpers/dates");
const budgets_1 = require("../actions/budgets");
const UserProvider_1 = require("@providers/UserProvider");
const Sticky_1 = require("@components/elements/Sticky");
// import Expanded from '@components/Budget/Expanded'
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const Overview_1 = require("@components/Budget/Overview");
const Compact_1 = require("@components/Budget/Compact");
const Budget = ({ history, _showToast, _removeToast, _process }) => {
    const { year, month } = (0, react_router_dom_1.useParams)();
    const [budgetHistory, setBudgetHistory] = (0, react_1.useState)(null);
    const [overviewIsLoading, setOverviewIsLoading] = (0, react_1.useState)(true);
    const [budgetMonths, setBudgetMonths] = (0, react_1.useState)([]);
    const [budgetedTotal, setBudgetedTotal] = (0, react_1.useState)(null);
    const [spentTotal, setSpentTotal] = (0, react_1.useState)(null);
    const [incomeTotal, setIncomeTotal] = (0, react_1.useState)(null);
    const [uncategorizedTotal, setUncategorizedTotal] = (0, react_1.useState)(null);
    const [budgetStatus, setBudgetStatus] = (0, react_1.useState)({});
    const [currentPeriod, setCurrentPeriod] = (0, react_1.useState)(null);
    const [showCompact, setShowCompact] = (0, react_1.useState)(
    // localStorage.getItem('_lm_hide_compact_view') !== 'true',
    true);
    const [showSidebar, setShowSidebar] = (0, react_1.useState)(false);
    const [remainingRecurring, setRemainingRecurring] = (0, react_1.useState)(null);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    (0, react_1.useEffect)(() => {
        if (typeof month === 'undefined' && typeof year === 'undefined') {
            const _currentPeriod = localStorage.getItem('_lm_budget_current_period');
            const currentPeriod = _currentPeriod !== 'null' && !!_currentPeriod
                ? _currentPeriod
                : (0, dates_1.getFormattedMonth)();
            history.replace(`/budget/${Moment(currentPeriod).format('YYYY/MM')}${window.location.search}`);
        }
        else {
            const newCurrentPeriod = year && month
                ? (0, dates_1.getFormattedMonth)(year, month)
                : localStorage.getItem('_lm_budget_current_period') ||
                    (0, dates_1.getFormattedMonth)();
            if (newCurrentPeriod !== currentPeriod) {
                setCurrentPeriod(newCurrentPeriod);
                localStorage.setItem('_lm_budget_current_period', newCurrentPeriod);
                if (_user.settings['link_months_between_views']) {
                    localStorage.setItem('_lm_recurring_current_period', newCurrentPeriod);
                    localStorage.setItem('_lm_calendar_current_period', newCurrentPeriod);
                    localStorage.setItem('_lm_transactions_current_period', newCurrentPeriod);
                }
            }
        }
    }, [month, year]);
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = 'Budget - Lunch Money';
    }, []);
    (0, react_1.useEffect)(() => {
        if (currentPeriod) {
            fetchLatest({});
        }
    }, [currentPeriod]);
    /**
     * Fetches budget information on first load and on month change
     */
    const fetchLatest = (opts) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        // opts should be start_date, end_date, prev_date
        setOverviewIsLoading(true);
        const snapshot = yield (0, budgets_1.getBudgetsPage)(Object.assign(Object.assign({}, opts), { start_date: currentPeriod, end_date: (0, dates_1.getThisMonthEnd)(currentPeriod), prev_date: (0, dates_1.getPreviousMonth)(currentPeriod) }));
        setBudgetMonths(snapshot.budgetMonths);
        setSpentTotal(((_a = snapshot.budgetHistory.totals) === null || _a === void 0 ? void 0 : _a.expenses) +
            ((_b = snapshot.budgetHistory.totals) === null || _b === void 0 ? void 0 : _b.uncategorized_expenses) +
            ((_c = snapshot.budgetHistory.totals) === null || _c === void 0 ? void 0 : _c.actual_recurring_expenses));
        setIncomeTotal(((_d = snapshot.budgetHistory.totals) === null || _d === void 0 ? void 0 : _d.income) +
            ((_e = snapshot.budgetHistory.totals) === null || _e === void 0 ? void 0 : _e.uncategorized_income) +
            ((_f = snapshot.budgetHistory.totals) === null || _f === void 0 ? void 0 : _f.actual_recurring_income));
        setRemainingRecurring({
            income: (_g = snapshot.budgetHistory.totals) === null || _g === void 0 ? void 0 : _g.remaining_recurring_income,
            expense: (_h = snapshot.budgetHistory.totals) === null || _h === void 0 ? void 0 : _h.remaining_recurring_expenses,
        });
        setUncategorizedTotal(snapshot.uncategorizedTotal);
        setBudgetStatus(snapshot.budgetStatus);
        setOverviewIsLoading(false);
        setBudgetHistory(snapshot.budgetHistory.budgets.filter(o => !o.exclude_from_budget && !o.archived));
        setBudgetedTotal(snapshot.budgetHistory.budgeted_totals);
    });
    const handleMonthJump = (e, { value }) => {
        history.push(`/budget/${value.replace('-', '/').substring(0, 7)}`);
    };
    return (React.createElement(semantic_ui_react_1.Container, { className: "g-budget" },
        React.createElement(ContainerHeader_1.default, { backLink: {
                pathname: `/budget/${(0, dates_1.getPreviousMonth)(currentPeriod)
                    .replace('-', '/')
                    .substring(0, 7)}`,
                search: window.location.search,
            }, forwardLink: {
                pathname: `/budget/${(0, dates_1.getNextMonth)(currentPeriod)
                    .replace('-', '/')
                    .substring(0, 7)}`,
                search: window.location.search,
            }, title: currentPeriod
                ? `${Moment(currentPeriod, 'YYYY-MM-DD').format('MMMM YYYY')}`
                : '', dropdownOnChange: handleMonthJump, dropdownOptions: budgetMonths, showBackToCurrentMonthButton: currentPeriod !== `${Moment().format('YYYY-MM')}-01`, jumpBackToCurrentMonth: () => {
                localStorage.setItem('_lm_budget_current_period', `${Moment().format('YYYY-MM')}-01`);
                if (_user.settings['link_months_between_views']) {
                    localStorage.setItem('_lm_recurring_current_period', `${Moment().format('YYYY-MM')}-01`);
                    localStorage.setItem('_lm_transactions_current_period', `${Moment().format('YYYY-MM')}-01`);
                    localStorage.setItem('_lm_calendar_current_period', `${Moment().format('YYYY-MM')}-01`);
                }
                history.push(`/budget/${Moment().format('YYYY/MM')}`);
            } }),
        React.createElement("div", { className: "header-buttons" },
            React.createElement("div", { className: "flex--space-between" },
                React.createElement(semantic_ui_react_1.Button, { className: "ml-1rem display-on-mobile", basic: !showSidebar, color: "orange", icon: true, onClick: () => {
                        setShowSidebar(!showSidebar);
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: showSidebar ? 'angle double right' : 'angle double left' })))),
        React.createElement("div", { className: "p-content-container" },
            React.createElement("div", { className: `left ${showCompact && 'interactive-container'}` }, showCompact && (React.createElement(Compact_1.default, { _showToast: _showToast, _removeToast: _removeToast, _process: _process, currentPeriod: currentPeriod, budgetHistory: budgetHistory, fetchLatest: fetchLatest, updateBudgetTotal: newBudgetTotal => {
                    setBudgetedTotal(Object.assign({}, newBudgetTotal));
                }, updateTaskCard: num => {
                    setBudgetStatus(Object.assign(Object.assign({}, budgetStatus), { this_month: num }));
                } }))),
            React.createElement("div", { className: `right ${showSidebar ? '' : 'hide-on-mobile'}` },
                React.createElement(Sticky_1.default, { reset: showSidebar || !overviewIsLoading ? new Date().getTime() : null, content: React.createElement(Overview_1.default, { remainingRecurring: remainingRecurring, currentPeriod: currentPeriod, budgetedTotal: budgetedTotal, spentTotal: spentTotal, incomeTotal: incomeTotal, isLoading: overviewIsLoading, budgetStatus: budgetStatus }) })))));
};
exports.default = Budget;
