"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const Moment = require("moment");
const react_router_dom_1 = require("react-router-dom");
const qs = require("query-string");
const UserProvider_1 = require("@providers/UserProvider");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_1 = require("@helpers/format");
const Overview = ({ currentPeriod, isLoading, expectedTotal, actualTotal, remaining, }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const totalRecurring = ((expectedTotal === null || expectedTotal === void 0 ? void 0 : expectedTotal.income) || 0) + ((expectedTotal === null || expectedTotal === void 0 ? void 0 : expectedTotal.expense) || 0);
    return (React.createElement(semantic_ui_react_1.Card, null,
        React.createElement(semantic_ui_react_1.Card.Content, { header: `${Moment(currentPeriod).format('MMMM')} Overview` }),
        isLoading ? (React.createElement(semantic_ui_react_1.Card.Content, null,
            React.createElement(semantic_ui_react_1.Loader, { active: true, size: "tiny", inline: "centered" }))) : (React.createElement(React.Fragment, null,
            expectedTotal && !!expectedTotal.income && (React.createElement(semantic_ui_react_1.Card.Content, null,
                React.createElement("p", { className: "label-style mb-05rem center-align" }, "Recurring Income"),
                React.createElement("div", { className: "card-content-wrapper" },
                    React.createElement("div", { className: "card-content" },
                        React.createElement("div", null,
                            React.createElement("span", { className: "card-text font--bold" },
                                "Expected",
                                ' ',
                                React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", color: "grey" }) }, "This may be an approximation, as some recurring incomes may be fluctuating. Check a recurring income's associated rules to verify."))),
                        React.createElement("span", { className: "card-number no-wrap color--green font--bold" }, (0, format_1.toPrice)((_user.settings['show_debits_as_negative']
                            ? expectedTotal.income * -1
                            : expectedTotal.income) || 0, _user.primaryCurrency)))),
                React.createElement("div", { className: "card-content-wrapper" },
                    React.createElement("div", { className: "card-content" },
                        React.createElement("span", { className: `card-text` },
                            React.createElement(react_router_dom_1.Link, { className: "link clickable", to: {
                                    pathname: `/transactions/${Moment(currentPeriod).format('YYYY/MM')}`,
                                    search: `?${qs.stringify({
                                        recurring: 'any',
                                    })}`,
                                } }, "Received")),
                        React.createElement("span", { className: `card-number no-wrap color--green` },
                            React.createElement("span", { className: "amount" }, (0, format_1.toPrice)((_user.settings['show_debits_as_negative']
                                ? actualTotal.income * -1
                                : actualTotal.income) || 0, _user.primaryCurrency))))),
                React.createElement("div", { className: `card-content-wrapper ${remaining.income == 0 ? 'footer' : ''}` },
                    React.createElement("div", { className: "card-content" },
                        React.createElement("div", null,
                            React.createElement("span", { className: "card-text" }, "Amount remaining")),
                        React.createElement("span", { className: `card-number no-wrap ${remaining.income < 0 ? 'color--green' : ''}` }, (0, format_1.toPrice)(_user.settings['show_debits_as_negative']
                            ? remaining.income * -1
                            : remaining.income, _user.primaryCurrency))),
                    remaining.income !== 0 && (React.createElement("div", { className: "status-bar-background" },
                        React.createElement("div", { className: "status-bar", style: {
                                width: Math.min(parseFloat((0, format_1.toPercentage)((expectedTotal.income - remaining.income) /
                                    expectedTotal.income).substring(0, (0, format_1.toPercentage)((expectedTotal.income - remaining.income) /
                                    expectedTotal.income).length - 1)), 100) + '%',
                            } })))))),
            expectedTotal && !!expectedTotal.expense && (React.createElement(semantic_ui_react_1.Card.Content, null,
                React.createElement("p", { className: "label-style mb-05rem center-align" }, "Recurring Expenses"),
                React.createElement("div", { className: "card-content-wrapper" },
                    React.createElement("div", { className: "card-content font--bold" },
                        React.createElement("div", null,
                            React.createElement("span", { className: "card-text font--bold" },
                                "Expected",
                                ' ',
                                React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", color: "grey" }) }, "This may be an approximation, as some recurring expenses may be fluctuating. Check a recurring expense's associated rules to verify."))),
                        React.createElement("span", { className: "card-number no-wrap font--bold" }, (0, format_1.toPrice)((_user.settings['show_debits_as_negative']
                            ? expectedTotal.expense * -1
                            : expectedTotal.expense) || 0, _user.primaryCurrency)))),
                React.createElement("div", { className: "card-content-wrapper" },
                    React.createElement("div", { className: "card-content" },
                        React.createElement("span", { className: `card-text` },
                            React.createElement(react_router_dom_1.Link, { className: "link clickable", to: {
                                    pathname: `/transactions/${Moment(currentPeriod).format('YYYY/MM')}`,
                                    search: `?${qs.stringify({
                                        recurring: 'any',
                                    })}`,
                                } }, "Paid")),
                        React.createElement("span", { className: `card-number no-wrap ` },
                            React.createElement("span", { className: "amount" }, (0, format_1.toPrice)((_user.settings['show_debits_as_negative']
                                ? actualTotal.expense * -1
                                : actualTotal.expense) || 0, _user.primaryCurrency))))),
                React.createElement("div", { className: `card-content-wrapper ${remaining.expense == 0 ? 'footer' : ''}` },
                    React.createElement("div", { className: "card-content" },
                        React.createElement("div", null,
                            React.createElement("span", { className: "card-text" }, "Amount remaining")),
                        React.createElement("span", { className: "card-number no-wrap" }, (0, format_1.toPrice)(_user.settings['show_debits_as_negative']
                            ? remaining.expense * -1
                            : remaining.expense, _user.primaryCurrency))),
                    remaining.expense !== 0 && (React.createElement("div", { className: "status-bar-background" },
                        React.createElement("div", { className: "status-bar", style: {
                                width: Math.min(parseFloat((0, format_1.toPercentage)((expectedTotal.expense - remaining.expense) /
                                    expectedTotal.expense).substring(0, (0, format_1.toPercentage)((expectedTotal.expense - remaining.expense) /
                                    expectedTotal.expense).length - 1)), 100) + '%',
                            } })))))))),
        expectedTotal && !!expectedTotal.income && !!expectedTotal.expense && (React.createElement(semantic_ui_react_1.Card.Content, null,
            React.createElement("div", { className: `card-content-wrapper footer` },
                React.createElement("div", { className: "card-content no-wrap" },
                    React.createElement("span", { className: "card-text ellipsis font--bold" }, "Total Recurring"),
                    React.createElement("span", { className: `card-number ${totalRecurring < 0 ? 'color--green' : ''}` }, (0, format_1.toPrice)(_user.settings['show_debits_as_negative']
                        ? totalRecurring * -1
                        : totalRecurring, _user.primaryCurrency))))))));
};
exports.default = Overview;
