"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const EditableString_1 = require("@components/elements/EditableString");
const EditablePrice_1 = require("@components/elements/EditablePrice");
const EditableDate_1 = require("@components/elements/EditableDate");
const SuggestedRecurringRow = ({ onOpenModal, expense }) => {
    var _a, _b;
    return (React.createElement(semantic_ui_react_1.Table.Row, { onClick: () => {
            onOpenModal(expense, 'SUGGESTED_RECURRING');
        } },
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable expandable" },
            React.createElement(EditableString_1.default, { identifier: `recurring-${expense.id}-payee`, firstValue: expense.payee, autoFocus: true, location: 'inline', state: 'ReadOnly', shouldSaveOnBlur: true, shouldSaveOnEnter: true, shouldSelectOnFocus: true, onSave: (payee) => __awaiter(void 0, void 0, void 0, function* () { }) })),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable" },
            React.createElement(EditableString_1.default, { identifier: `recurring-${expense.id}-institution`, firstValue: [
                    expense.institution_name || expense.asset_institution_name,
                    expense.plaid_account_name || expense.asset_name,
                ].join(' '), autoFocus: true, location: 'inline', state: 'ReadOnly', shouldSaveOnBlur: true, shouldSaveOnEnter: true, shouldSelectOnFocus: true, onSave: (payee) => __awaiter(void 0, void 0, void 0, function* () { }) })),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align clickable" }, ((_a = expense.transactions) === null || _a === void 0 ? void 0 : _a.length) || '0'),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: 'clickable' }, ((_b = expense.transactions) === null || _b === void 0 ? void 0 : _b.length) > 0 && (React.createElement(EditableDate_1.default, { identifier: `recurring-${expense.id}-billing-date`, firstValue: expense.transactions[0].formatted_date, state: 'ReadOnly', location: 'inline', autoFocus: true, format: 'month_day_year', onSave: (billing_date) => __awaiter(void 0, void 0, void 0, function* () { }) }))),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: `right-align clickable` },
            React.createElement(EditablePrice_1.default, { showSignLabel: true, identifier: `recurring-${expense.id}-amount`, amount: expense.amount, state: 'ReadOnly', currency: expense.currency, location: 'inline-edit', onSave: (amount, currency) => __awaiter(void 0, void 0, void 0, function* () { }) })),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable right-align" },
            React.createElement(semantic_ui_react_1.Icon, { name: "angle right", className: 'color--grey mr-1rem' }))));
};
exports.default = SuggestedRecurringRow;
