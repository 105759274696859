"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.dismissByRecurringId = exports.clearByRecurringId = exports.deleteRecurringExpense = exports.createRecurringExpense = exports.getSuggestedRecurring = exports.updateRecurringExpense = exports.getSuggestedRecurringExpenses = exports.mergeSuggestedRecurring = exports.getSuggested = exports.detectRecurringExpenses = exports.getRecurringExpensesPage = void 0;
const common_1 = require("./common");
/**
 *
 *
 */
const getRecurringExpensesPage = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`snapshot/recurring_expenses_page`, body);
});
exports.getRecurringExpensesPage = getRecurringExpensesPage;
/**
 *
 *
 */
const detectRecurringExpenses = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`recurring_expenses/detect`, body);
});
exports.detectRecurringExpenses = detectRecurringExpenses;
/**
 *
 *
 */
const getSuggested = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`recurring_expenses/suggested/${id}`, {});
});
exports.getSuggested = getSuggested;
/**
 *
 *
 */
const mergeSuggestedRecurring = (obj) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`recurring_expenses/merge`, obj);
});
exports.mergeSuggestedRecurring = mergeSuggestedRecurring;
/**
 *
 *
 */
const getSuggestedRecurringExpenses = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`recurring_expenses/suggested`, {});
});
exports.getSuggestedRecurringExpenses = getSuggestedRecurringExpenses;
/**
 *
 *
 */
const updateRecurringExpense = (id, body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`recurring_expenses/${id}`, body);
});
exports.updateRecurringExpense = updateRecurringExpense;
/**
 *
 *
 */
const getSuggestedRecurring = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`recurring_expenses/suggested`, body);
});
exports.getSuggestedRecurring = getSuggestedRecurring;
/**
 *
 *
 */
const createRecurringExpense = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`recurring_expenses`, body);
});
exports.createRecurringExpense = createRecurringExpense;
/**
 *
 *
 */
const deleteRecurringExpense = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.del)(`recurring_expenses/${id}`, {});
});
exports.deleteRecurringExpense = deleteRecurringExpense;
/**
 *
 *
 */
const clearByRecurringId = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`recurring_expenses/approve/${id}`, {});
});
exports.clearByRecurringId = clearByRecurringId;
/**
 *
 *
 */
const dismissByRecurringId = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`recurring_expenses/dismiss/${id}`, {});
});
exports.dismissByRecurringId = dismissByRecurringId;
