"use strict";
/**
 *  ImportTransactions.tsx
 *  A row in the transaction table.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const NewModal_1 = require("@components/global/NewModal");
const react_1 = require("react");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const semantic_ui_react_1 = require("semantic-ui-react");
const qs = require("query-string");
const transactions_1 = require("@actions/transactions");
const Container_1 = require("@components/QueryTool/Container");
const SavedQueries_1 = require("@components/QueryTool/SavedQueries");
const SaveQuery_1 = require("@components/QueryTool/SaveQuery");
const format_1 = require("@helpers/format");
const QueryToolProvider_1 = require("@providers/QueryToolProvider");
const UserProvider_1 = require("@providers/UserProvider");
const PopupTransactions_1 = require("@/components/Transactions/PopupTransactions");
const QueryTool = ({ history, _process, _showToast, _removeToast }) => {
    const [_queryTool] = (0, react_1.useContext)(QueryToolProvider_1.QueryToolContext);
    // Filters
    const [filterDateStart, setFilterDateStart] = (0, react_1.useState)(null);
    const [filterDateEnd, setFilterDateEnd] = (0, react_1.useState)(null);
    const [filterDatePreset, setFilterDatePreset] = (0, react_1.useState)(null);
    const [triggerProperties, setTriggerProperties] = (0, react_1.useState)(null);
    const [showModal, setShowModal] = (0, react_1.useState)(false);
    const [dataSetOptions, setDataSetOptions] = (0, react_1.useState)([]);
    const [numDataSets, setNumDataSets] = (0, react_1.useState)(1);
    const [dataSetNames, setDataSetNames] = (0, react_1.useState)({});
    const [isLoadingTransactions, setIsLoadingTransactions] = (0, react_1.useState)(false);
    const [transactionsGroup, setTransactionsGroup] = (0, react_1.useState)([]);
    // Saved Queries
    const [showSaveQuery, setShowSaveQuery] = (0, react_1.useState)(false);
    const [showSavedQueries, setShowSavedQueries] = (0, react_1.useState)(false);
    const [currentView, setCurrentView] = (0, react_1.useState)('report');
    const [shouldFetchNew, setShouldFetchNew] = (0, react_1.useState)(0);
    //
    const [openTransactionsTable, setOpenTransactionsTable] = (0, react_1.useState)(false);
    const [selectedQuery, setSelectedQuery] = (0, react_1.useState)({});
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const CHART_VIEWS = [
        {
            text: 'Report',
            icon: 'table',
            key: 'report',
        },
        {
            text: 'Pie Chart',
            icon: 'chart pie',
            key: 'pie',
        },
        {
            text: 'Bar Graph',
            icon: 'chart bar',
            key: 'bar',
        },
        {
            text: 'Line Chart',
            icon: 'chart line',
            key: 'line',
        },
        {
            text: 'Stacked Bar',
            icon: 'stacked',
            key: 'stacked',
        },
    ];
    (0, react_1.useEffect)(() => {
        document.title = 'Analyze - Lunch Money';
        localStorage.setItem('_lm_query_tool_current_view', 'report'); // Reset it
    }, []);
    const applyQuery = () => {
        const opts = qs.parse(history.location.search);
        if (opts.opts) {
            const datasets = opts['opts'].split(',');
            const newDataSet = [];
            const names = {};
            datasets.forEach((data, index) => {
                const set = qs.parse(data);
                if (set['tags']) {
                    if (typeof set['tags'] == 'string') {
                        set['tags'] = [parseInt(set.tags)];
                    }
                    else if (typeof set['tags'] == 'object') {
                        set['tags'] = set['tags'].map(tag => {
                            return parseInt(tag);
                        });
                    }
                }
                if (set['tags_exclude']) {
                    if (typeof set['tags_exclude'] == 'string') {
                        set['tags_exclude'] = [parseInt(set.tags_exclude)];
                    }
                    else if (typeof set['tags_exclude'] == 'object') {
                        set['tags_exclude'] = set['tags_exclude'].map(tag => {
                            return parseInt(tag);
                        });
                    }
                }
                if (set['category_ids']) {
                    if (typeof set['category_ids'] == 'string') {
                        set['category_ids'] = [parseInt(set.category_ids)];
                    }
                    else if (typeof set['category_ids'] == 'object') {
                        set['category_ids'] = set['category_ids'].map(id => {
                            return parseInt(id);
                        });
                    }
                }
                if (set['date_range'] && set['date_range'] !== 'Custom') {
                    let start;
                    if (set['date_range'] == 'Month to date') {
                        start = Moment()
                            .startOf('month')
                            .format('YYYY-MM-DD');
                        setFilterDateEnd(Moment().format('YYYY-MM-DD'));
                    }
                    else if (set['date_range'] == 'Year to date') {
                        start = Moment()
                            .startOf('year')
                            .format('YYYY-MM-DD');
                        setFilterDateEnd(Moment().format('YYYY-MM-DD'));
                    }
                    else if (set['date_range'] == 'Last 7 days') {
                        start = Moment()
                            .subtract(6, 'days')
                            .format('YYYY-MM-DD');
                        setFilterDateEnd(Moment().format('YYYY-MM-DD'));
                    }
                    else if (set['date_range'] == 'Last 30 days') {
                        start = Moment()
                            .subtract(29, 'days')
                            .format('YYYY-MM-DD');
                        setFilterDateEnd(Moment().format('YYYY-MM-DD'));
                    }
                    else if (set['date_range'] == 'Last 14 days') {
                        start = Moment()
                            .subtract(13, 'days')
                            .format('YYYY-MM-DD');
                        setFilterDateEnd(Moment().format('YYYY-MM-DD'));
                    }
                    else if (set['date_range'] == 'Last month') {
                        start = Moment()
                            .startOf('month')
                            .subtract(1, 'months')
                            .format('YYYY-MM-DD');
                        setFilterDateEnd(Moment()
                            .endOf('month')
                            .subtract(1, 'months')
                            .format('YYYY-MM-DD'));
                    }
                    else if (set['date_range'] == 'Last 3 months') {
                        start = Moment()
                            .startOf('month')
                            .subtract(2, 'months')
                            .format('YYYY-MM-DD');
                        setFilterDateEnd(Moment()
                            .endOf('month')
                            .format('YYYY-MM-DD'));
                    }
                    else if (set['date_range'] == 'Last 6 months') {
                        start = Moment()
                            .startOf('month')
                            .subtract(5, 'months')
                            .format('YYYY-MM-DD');
                        setFilterDateEnd(Moment()
                            .endOf('month')
                            .format('YYYY-MM-DD'));
                    }
                    else if (set['date_range'] == 'Last 12 months') {
                        start = Moment()
                            .startOf('month')
                            .subtract(11, 'months')
                            .format('YYYY-MM-DD');
                        setFilterDateEnd(Moment()
                            .endOf('month')
                            .format('YYYY-MM-DD'));
                    }
                    else if (set['date_range'] == 'Last year') {
                        start = Moment()
                            .subtract(1, 'year')
                            .startOf('year')
                            .format('YYYY-MM-DD');
                        setFilterDateEnd(Moment()
                            .subtract(1, 'year')
                            .endOf('year')
                            .format('YYYY-MM-DD'));
                    }
                    setFilterDatePreset(set['date_range']);
                    setFilterDateStart(start);
                }
                else {
                    setFilterDateEnd(set['end_date']);
                    setFilterDateStart(set['start_date']);
                }
                if (set['current_view']) {
                    localStorage.setItem('_lm_query_tool_current_view', set['current_view']);
                    setCurrentView(set['current_view']);
                }
                names[index] =
                    set['dataset_name'] || set['name'] || `Dataset ${index + 1}`;
                delete set['name'];
                delete set['end_date'];
                delete set['start_date'];
                newDataSet.push(set);
            });
            setDataSetOptions(newDataSet);
            setNumDataSets(newDataSet.length);
            setDataSetNames(names);
            setShouldFetchNew(new Date().getTime());
            setTriggerProperties(new Date().getTime());
            return true;
        }
        return;
    };
    // This is basically for when you come in with a link that has query information
    // in it. We only want to run this if nothing has ever been loaded.
    (0, react_1.useEffect)(() => {
        var _a, _b;
        if (((_b = (_a = history.location) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.refresh) == false &&
            transactionsGroup.length > 0) {
            delete history.location.state.refresh;
            return;
        }
        if (history.location.search.length > 0) {
            applyQuery();
            setShowSavedQueries(false);
        }
        else {
            history.replace({
                to: '/analyze',
                search: qs.stringify({
                    opts: qs.stringify({ date_range: 'Last 6 months' }),
                }),
            });
        }
    }, [history.location.search]);
    (0, react_1.useEffect)(() => {
        if (shouldFetchNew > 0) {
            // setShouldFetchNew(0)
            fetchTransactions();
        }
    }, [shouldFetchNew]);
    const _getSingleOpts = () => {
        return Object.assign(Object.assign({ 
            // Default to 5 months prior
            start_date: filterDateStart ||
                Moment()
                    .subtract(5, 'months')
                    .startOf('month')
                    .format('YYYY-MM-DD'), 
            // Default to end of this month
            end_date: filterDateEnd }, (0, format_1.removeNullValues)({ date_range: filterDatePreset })), (0, format_1.removeNullValues)(Object.values(dataSetOptions)[0]));
    };
    const _getMultiOpts = () => {
        const baseOpts = Object.assign({ 
            // Default to 5 months prior
            start_date: filterDateStart ||
                Moment()
                    .subtract(5, 'months')
                    .startOf('month')
                    .format('YYYY-MM-DD'), 
            // Default to end of this month
            end_date: filterDateEnd }, (0, format_1.removeNullValues)({ date_range: filterDatePreset }));
        const opts = [];
        for (let i = 0; i < numDataSets; i++) {
            opts.push(Object.assign(Object.assign({ name: dataSetNames[i] }, baseOpts), (0, format_1.removeNullValues)(Object.values(dataSetOptions)[i])));
        }
        return opts;
    };
    const stringifyQuery = (changeUrl = true) => {
        let str = '';
        if (numDataSets == 1) {
            const opts = _getSingleOpts();
            str = qs.stringify({ opts: qs.stringify(opts) });
        }
        else {
            const opts = _getMultiOpts();
            const stringifiedOpts = opts.map(opt => {
                return qs.stringify(opt);
            });
            str = qs.stringify({ opts: stringifiedOpts }, { arrayFormat: 'comma' });
        }
        if (changeUrl) {
            history.push({
                search: str,
            });
        }
        else {
            return str;
        }
    };
    const fetchTransactions = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoadingTransactions(true);
        if (numDataSets == 1) {
            const opts = _getSingleOpts();
            const transactionsResult = yield (0, transactions_1.getTransactions)(Object.assign(Object.assign({}, opts), { minimal: true, exclude_pending: !_user.settings['include_pending_in_totals'], exclude_parents: true }));
            setTransactionsGroup([transactionsResult]);
        }
        else {
            const opts = _getMultiOpts();
            const transactionsResult = yield (0, transactions_1.getTransactionsMultiple)({
                opts,
                minimal: true,
                exclude_pending: !_user.settings['include_pending_in_totals'],
            });
            setTransactionsGroup(transactionsResult.data);
        }
        setIsLoadingTransactions(false);
        // setShouldFetchNew(0)
    });
    return (React.createElement(semantic_ui_react_1.Container, { className: "g-query-tool" },
        React.createElement(ContainerHeader_1.default, { title: "Query Tool" }),
        React.createElement("div", { className: "width-100 mt-2rem" }, !isLoadingTransactions && transactionsGroup.length == 0 ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "width-100 loader-cell monospace mt-2rem mb-1rem center-align" },
                React.createElement("div", { className: "yellow-mascot" }),
                React.createElement("h2", null, "Get insights into your spending"),
                React.createElement("p", null,
                    "Start by entering some filters,",
                    React.createElement("br", null),
                    " or click",
                    ' ',
                    React.createElement(semantic_ui_react_1.Button, { icon: "play", size: "mini", onClick: () => {
                            stringifyQuery();
                        }, content: 'Run Analysis' }),
                    ' ',
                    "to see your last 6 month's worth of transactions")),
            React.createElement(SavedQueries_1.default, { _process: _process, _showToast: _showToast, close: () => {
                    setShowSavedQueries(false);
                } }))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "header-buttons flex--space-between-flex-end width-100 mt-3rem" },
                React.createElement("div", { className: "display--flex mt-1rem" },
                    React.createElement(semantic_ui_react_1.Button, { disabled: isLoadingTransactions, color: "orange", onClick: () => {
                            setShowModal(true);
                        } },
                        "Datasets (",
                        numDataSets,
                        ")"),
                    React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Button, { disabled: isLoadingTransactions, icon: true, onClick: () => {
                                setShowSaveQuery(true);
                            } },
                            React.createElement(semantic_ui_react_1.Icon, { name: "save" })), inverted: true, hoverable: true, size: "tiny" }, "Save current configuration"),
                    React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Button, { disabled: isLoadingTransactions, icon: true, onClick: () => {
                                setShowSavedQueries(true);
                            } },
                            React.createElement(semantic_ui_react_1.Icon, { name: "folder open" })), inverted: true, hoverable: true, size: "tiny" }, "Load saved configuration"),
                    dataSetOptions.length > 1 ||
                        (Object.keys(dataSetOptions[0] || {}).length > 1 && (React.createElement(semantic_ui_react_1.Button, { color: "orange", disabled: isLoadingTransactions, basic: true, onClick: () => {
                                history.push({
                                    to: '/analyze',
                                    search: qs.stringify({
                                        opts: qs.stringify({
                                            date_range: 'Last 6 months',
                                        }),
                                    }),
                                });
                                _queryTool.allDataSets.current = {};
                            } }, "Reset")))),
                React.createElement("div", null,
                    React.createElement(semantic_ui_react_1.Button.Group, null, CHART_VIEWS.map(view => {
                        return (React.createElement(semantic_ui_react_1.Popup, { key: `chart-view-${view.key}`, position: "top center", trigger: React.createElement(semantic_ui_react_1.Button, { disabled: isLoadingTransactions, basic: currentView !== view.key, color: "orange", active: currentView == view.key, icon: true, onClick: () => {
                                    localStorage.setItem('_lm_query_tool_current_view', view.key);
                                    setCurrentView(view.key);
                                } }, view.icon == 'stacked' ? (React.createElement("span", { className: `stacked-bar-icon ${currentView == 'stacked' ? 'white' : ''}` })) : (React.createElement(semantic_ui_react_1.Icon, { name: view.icon }))), inverted: true, hoverable: true, size: "mini" }, view.text));
                    })))),
            React.createElement(Container_1.default, { view: currentView, dataSetNames: Object.values(dataSetNames), transactionsGroup: transactionsGroup, filterDateStart: filterDateStart, filterDateEnd: filterDateEnd, triggerProperties: triggerProperties, utils: {
                    _getSingleOpts,
                    _getMultiOpts,
                    _process,
                    _showToast,
                    _removeToast,
                }, openTable: query => {
                    setSelectedQuery(query);
                    setOpenTransactionsTable(true);
                }, isLoading: isLoadingTransactions })))),
        React.createElement(SaveQuery_1.default, { _process: _process, _showToast: _showToast, open: showSaveQuery, onClose: () => {
                setShowSaveQuery(false);
            } }),
        React.createElement(semantic_ui_react_1.Modal, { closeOnDimmerClick: true, onClose: () => {
                setShowSavedQueries(false);
            }, open: showSavedQueries },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Load Saved Query"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement(SavedQueries_1.default, { _process: _process, _showToast: _showToast, hideTitle: true, close: () => {
                        setShowSavedQueries(false);
                    } })),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        setShowSavedQueries(false);
                    } }, "Close"))),
        showModal && (React.createElement(NewModal_1.default, { data: {}, show: showModal, setShow: (show) => {
                setShowModal(show);
            }, utils: {
                _process,
                _showToast,
            }, view: 'EDIT_QUERY_FILTERS', closeCallback: () => { } })),
        React.createElement(PopupTransactions_1.default, { openTransactionsTable: openTransactionsTable, onClose: () => __awaiter(void 0, void 0, void 0, function* () {
                setSelectedQuery({});
                setOpenTransactionsTable(false);
            }), headerText: `Transactions matching query`, query: Object.assign({ time: 'all', match: 'all' }, selectedQuery), _process: _process, _showToast: _showToast, _removeToast: _removeToast })));
};
exports.default = QueryTool;
