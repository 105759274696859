"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const qs = require("query-string");
const Moment = require("moment");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
const PeriodPicker_1 = require("@components/QueryTool/PeriodPicker");
const TrendsChart_1 = require("@components/Trends/TrendsChart");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const format_1 = require("@helpers/format");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const EditableCategory_1 = require("@components/elements/EditableCategory");
const trends_1 = require("@actions/trends");
const transactions_1 = require("@actions/transactions");
const UserProvider_1 = require("@providers/UserProvider");
const Trends = ({}) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    const history = (0, react_router_dom_1.useHistory)();
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const [data, setData] = (0, react_1.useState)(null);
    const [monthList, setMonthList] = (0, react_1.useState)([]);
    const [budgetData, setBudgetData] = (0, react_1.useState)(null);
    const [dateLabels, setDateLabels] = (0, react_1.useState)([]);
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    const [startDate, setStartDate] = (0, react_1.useState)(null);
    const [endDate, setEndDate] = (0, react_1.useState)(null);
    const [allYears, setAllYears] = (0, react_1.useState)(['2020']);
    const [category, setCategory] = (0, react_1.useState)(((_a = _categories.assignable[0]) === null || _a === void 0 ? void 0 : _a.id) || null);
    const [merchantIndex, setMerchantIndex] = (0, react_1.useState)(0);
    const [showTxCount, setShowTxCount] = (0, react_1.useState)(_user.settings['trends_show_transaction_count'] || true);
    const [includeRecurring, setIncludeRecurring] = (0, react_1.useState)(_user.settings['trends_include_recurring'] || true);
    const [includeExcludeFromTotals, setIncludeExcludeFromTotals] = (0, react_1.useState)(_user.settings['trends_include_exclude_from_totals'] || false);
    const toQuery = (opts, includeExtras = true) => {
        const additional = {};
        if (includeExtras) {
            if (!includeExcludeFromTotals) {
                // Figure out which categories are exclude_from_totals and set
                // category_exclude
                additional['category_exclude'] = _categories.all
                    .filter(o => o.exclude_from_totals)
                    .map(category => category.id);
            }
            if (!includeRecurring) {
                additional['recurring_exclude'] = 'any';
            }
        }
        return qs.stringify(Object.assign(Object.assign({}, opts), additional));
    };
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = 'Trends - Lunch Money';
        fetchYears();
        init();
    }, []);
    const init = () => __awaiter(void 0, void 0, void 0, function* () {
        // On load, if nothing exists, then go for Month to date
        if (history.location.search.length == 0 &&
            _user.settings['trends_time_period']) {
            setTimePeriods({ date_range: _user.settings['trends_time_period'] });
        }
        else {
            // No default yet
            setTimePeriods({ date_range: 'Last 12 months' });
        }
    });
    const fetchYears = () => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield (0, transactions_1.getYears)();
        setAllYears(results);
    });
    (0, react_1.useEffect)(() => {
        if (startDate && endDate) {
            fetch();
        }
    }, [startDate, endDate, includeRecurring, includeExcludeFromTotals]);
    const fetch = () => __awaiter(void 0, void 0, void 0, function* () {
        var _u, _v;
        setIsLoading(true);
        const results = yield (0, trends_1.getTrends)({
            start_date: startDate,
            end_date: endDate,
            include_recurring: includeRecurring,
            include_exclude_from_totals: includeExcludeFromTotals,
        });
        // setDateLabels
        const months = [];
        const _monthList = [];
        const counterMonth = Moment(startDate);
        while (counterMonth <= Moment(endDate)) {
            months.push(counterMonth.format('MMM'));
            _monthList.push(counterMonth.format('YYYY-MM-DD'));
            counterMonth.add(1, 'month');
        }
        setDateLabels(months);
        setMonthList(_monthList);
        // Sort
        results.data.month_totals = (_u = results.data.month_totals) === null || _u === void 0 ? void 0 : _u.sort((a, b) => {
            return a.month.localeCompare(b.month);
        });
        // Sort by merchant name
        results.data.merchants = (_v = results.data.merchants) === null || _v === void 0 ? void 0 : _v.sort((a, b) => {
            return a.merchant_name.localeCompare(b.merchant_name);
        });
        // Within each, sort by transaction month
        results.data.merchants.forEach(merchant => {
            var _a;
            merchant.months = (_a = merchant.months) === null || _a === void 0 ? void 0 : _a.sort((a, b) => {
                return a.transaction_month.localeCompare(b.transaction_month);
            });
        });
        setData(results.data);
        setBudgetData(results.budgets);
        setIsLoading(false);
    });
    // If changes are detected from the period picker, it comes here
    (0, react_1.useEffect)(() => {
        if (history.location.search.length > 0) {
            const dateObj = qs.parse(history.location.search);
            setTimePeriods(dateObj);
        }
    }, [history.location.search]);
    const setTimePeriods = dateObj => {
        let _startDate;
        let _endDate;
        if (dateObj['date_range'] == 'Last 12 months') {
            _startDate = Moment()
                .startOf('month')
                .subtract(11, 'months')
                .format('YYYY-MM-DD');
            _endDate = Moment()
                .endOf('month')
                .format('YYYY-MM-DD');
        }
        else if (dateObj['date_range']) {
            _startDate = Moment()
                .year(dateObj['date_range'])
                .startOf('year')
                .format('YYYY-MM-DD');
            _endDate = Moment()
                .year(dateObj['date_range'])
                .endOf('year')
                .format('YYYY-MM-DD');
        }
        else if (dateObj['start_date']) {
            _startDate = dateObj['start_date'];
            if (dateObj['end_date']) {
                _endDate = dateObj['end_date'];
            }
            else {
                _endDate = Moment()
                    .endOf('month')
                    .format('YYYY-MM-DD');
            }
        }
        if (_startDate && _endDate) {
            setStartDate(_startDate);
            setEndDate(_endDate);
        }
        if (dateObj['date_range'] &&
            _user.settings['trends_time_period'] != dateObj['date_range']) {
            _user.updateSetting('trends_time_period', dateObj['date_range']);
        }
    };
    return (React.createElement(semantic_ui_react_1.Container, { id: "g-trends" },
        React.createElement(ContainerHeader_1.default, { title: 'Trends' }),
        React.createElement("div", { className: "stats-header-bar" },
            React.createElement(PeriodPicker_1.default, { showArrows: false, monthMinimum: true, monthPicker: true, disabled: isLoading, firstValue: _user.settings['trends_time_period'] || '2020', className: 'display--inline-block overview-period-picker', values: allYears, useSelectedDate: true }),
            React.createElement("div", { className: "display--flex stats-checkboxes" },
                React.createElement("div", { className: "display--flex mr-1rem" },
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: includeRecurring, onChange: (e, { checked }) => {
                            setIncludeRecurring(checked);
                            _user.updateSetting('trends_include_recurring', checked);
                        }, label: "Include recurring" }),
                    React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }) }, "Check this box to include recurring transactions in these statistics.")),
                React.createElement("div", { className: "display--flex" },
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: includeExcludeFromTotals, onChange: (e, { checked }) => {
                            setIncludeExcludeFromTotals(checked);
                            _user.updateSetting('trends_include_exclude_from_totals', checked);
                        }, label: "Include 'exclude from totals'" }),
                    React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }) }, "Check this box to include transactions which are categorized as \"exclude from totals\".")))),
        React.createElement("div", { id: "small-stats-container" },
            React.createElement(semantic_ui_react_1.Card, { fluid: true },
                React.createElement(semantic_ui_react_1.Card.Content, { header: 'Total transactions this year', className: "card-header" }),
                isLoading ? (React.createElement(semantic_ui_react_1.Card.Content, null,
                    React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "tiny", className: "mt-1rem mb-1rem" }))) : (React.createElement(semantic_ui_react_1.Card.Content, null,
                    React.createElement("p", { className: "large-type" }, data === null || data === void 0 ? void 0 : data.total_transactions),
                    React.createElement("p", { className: "sub-type" },
                        "Average ",
                        ((data === null || data === void 0 ? void 0 : data.total_transactions) / 12).toFixed(0),
                        " per month",
                        ' ',
                        React.createElement(react_router_dom_1.Link, { target: "_blank", to: {
                                pathname: '/transactions',
                                search: toQuery({
                                    end_date: endDate,
                                    match: 'all',
                                    start_date: startDate,
                                    time: 'custom',
                                }),
                            } },
                            React.createElement(semantic_ui_react_1.Icon, { name: "external" })))))),
            React.createElement(semantic_ui_react_1.Card, { fluid: true },
                React.createElement(semantic_ui_react_1.Card.Content, { header: 'Total expenses tracked', className: "card-header" }),
                isLoading ? (React.createElement(semantic_ui_react_1.Card.Content, null,
                    React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "tiny", className: "mt-1rem mb-1rem" }))) : (React.createElement(semantic_ui_react_1.Card.Content, null,
                    React.createElement("p", { className: "large-type" }, (0, format_1.toPrice)(data === null || data === void 0 ? void 0 : data.expenses_total, _user.primaryCurrency)),
                    React.createElement("p", { className: "sub-type" }, data === null || data === void 0 ? void 0 :
                        data.expenses_count,
                        " transactions",
                        ' ',
                        React.createElement(react_router_dom_1.Link, { target: "_blank", to: {
                                pathname: '/transactions',
                                search: toQuery({
                                    end_date: endDate,
                                    match: 'all',
                                    start_date: startDate,
                                    time: 'custom',
                                    is_income: false,
                                }),
                            } },
                            React.createElement(semantic_ui_react_1.Icon, { name: "external" })))))),
            React.createElement(semantic_ui_react_1.Card, { fluid: true },
                React.createElement(semantic_ui_react_1.Card.Content, { header: 'Total income tracked', className: "card-header" }),
                isLoading ? (React.createElement(semantic_ui_react_1.Card.Content, null,
                    React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "tiny", className: "mt-1rem mb-1rem" }))) : (React.createElement(semantic_ui_react_1.Card.Content, null,
                    React.createElement("p", { className: "large-type color--green" }, (0, format_1.toPrice)((data === null || data === void 0 ? void 0 : data.income_total) * -1, _user.primaryCurrency)),
                    React.createElement("p", { className: "sub-type" }, data === null || data === void 0 ? void 0 :
                        data.income_count,
                        " transactions",
                        ' ',
                        React.createElement(react_router_dom_1.Link, { target: "_blank", to: {
                                pathname: '/transactions',
                                search: toQuery({
                                    end_date: endDate,
                                    match: 'all',
                                    start_date: startDate,
                                    time: 'custom',
                                    is_income: true,
                                }),
                            } },
                            React.createElement(semantic_ui_react_1.Icon, { name: "external" })))))),
            React.createElement(semantic_ui_react_1.Card, { fluid: true },
                React.createElement(semantic_ui_react_1.Card.Content, { header: 'Savings Rate', className: "card-header" }),
                isLoading ? (React.createElement(semantic_ui_react_1.Card.Content, null,
                    React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "tiny", className: "mt-1rem mb-1rem" }))) : (React.createElement(semantic_ui_react_1.Card.Content, null,
                    React.createElement("p", { className: "large-type color--green" }, (data === null || data === void 0 ? void 0 : data.income_total)
                        ? (0, format_1.toPercentage)(((data === null || data === void 0 ? void 0 : data.income_total) + (data === null || data === void 0 ? void 0 : data.expenses_total)) /
                            (data === null || data === void 0 ? void 0 : data.income_total), 2)
                        : '-'),
                    React.createElement("p", { className: "sub-type" },
                        "Over",
                        ' ',
                        Moment(endDate).diff(startDate, 'days') > 50
                            ? `${Math.ceil(Moment(endDate).diff(startDate, 'months', true))} months`
                            : `${Moment(endDate).diff(startDate, 'days')} days`))))),
        React.createElement("div", { id: "year-stats-container" },
            React.createElement(semantic_ui_react_1.Card, { fluid: true },
                React.createElement(semantic_ui_react_1.Card.Content, { header: 'Transaction Totals and Counts', className: "card-header" }),
                (data === null || data === void 0 ? void 0 : data.month_totals) && !isLoading ? (React.createElement(semantic_ui_react_1.Card.Content, null,
                    React.createElement(TrendsChart_1.default, { identifier: '1', labels: dateLabels, datasets: [
                            {
                                type: 'line',
                                label: 'Transactions count',
                                yAxisID: 'count',
                                borderColor: '#1A607E', //E05951
                                lineTension: 0,
                                pointHoverBorderWidth: 2,
                                pointHoverBackgroundColor: '#1A607E',
                                pointHoverRadius: 5,
                                pointHoverBorderColor: '#fff',
                                pointRadius: 0,
                                fill: false,
                                data: data === null || data === void 0 ? void 0 : data.month_totals.map(o => o.count),
                            },
                            {
                                type: 'bar',
                                label: 'Income',
                                yAxisID: 'price',
                                maxBarThickness: 40,
                                backgroundColor: '#68cbba',
                                hoverBackgroundColor: '#68cbba',
                                data: data === null || data === void 0 ? void 0 : data.month_totals.map(o => o.income_total * -1),
                            },
                            {
                                type: 'bar',
                                label: 'Expenses',
                                yAxisID: 'price',
                                maxBarThickness: 40,
                                backgroundColor: '#ed7373',
                                hoverBackgroundColor: '#ed7373',
                                data: data === null || data === void 0 ? void 0 : data.month_totals.map(o => o.expense_total),
                            },
                        ], tooltipData: {
                            header: data === null || data === void 0 ? void 0 : data.month_totals.map(o => {
                                return `${Moment(o.month).format('MMMM YYYY')}`;
                            }),
                            links: data === null || data === void 0 ? void 0 : data.month_totals.map(o => {
                                return [
                                    {
                                        display: o.count > 0,
                                        url: `/transactions?${toQuery({
                                            end_date: Moment(o.month)
                                                .endOf('month')
                                                .format('YYYY-MM-DD'),
                                            match: 'all',
                                            start_date: o.month,
                                            time: 'custom',
                                            is_income: false,
                                        })}`,
                                        text: 'View expenses',
                                    },
                                    {
                                        display: o.count > 0,
                                        url: `/transactions?${toQuery({
                                            end_date: Moment(o.month)
                                                .endOf('month')
                                                .format('YYYY-MM-DD'),
                                            match: 'all',
                                            start_date: o.month,
                                            time: 'custom',
                                            is_income: true,
                                        })}`,
                                        text: 'View income',
                                    },
                                ];
                            }),
                            data: data === null || data === void 0 ? void 0 : data.month_totals.map(o => {
                                return [
                                    {
                                        color: '#ed7373',
                                        key: 'Total expenses',
                                        value: (0, format_1.toPrice)(o.expense_total, _user.primaryCurrency),
                                        rawValue: o.expense_total || null,
                                    },
                                    {
                                        color: '#68cbba',
                                        key: 'Total income',
                                        value: (0, format_1.toPrice)(o.income_total * -1, _user.primaryCurrency),
                                        rawValue: o.income_total ? o.income_total * -1 : null,
                                    },
                                    {
                                        color: '#1A607E',
                                        key: 'No. of transactions',
                                        value: o.count,
                                        rawValue: o.count || null,
                                    },
                                ];
                            }),
                        }, displayCountAxis: true }))) : (React.createElement(semantic_ui_react_1.Card.Content, null,
                    React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "tiny", className: "mt-1rem mb-1rem" })))),
            React.createElement(semantic_ui_react_1.Card, { fluid: true },
                React.createElement(semantic_ui_react_1.Card.Content, { header: 'Net Income and Savings Rate', className: "card-header" }),
                (data === null || data === void 0 ? void 0 : data.month_totals) && !isLoading ? (React.createElement(semantic_ui_react_1.Card.Content, null,
                    React.createElement(TrendsChart_1.default, { identifier: '1.2', labels: dateLabels, datasets: [
                            {
                                type: 'line',
                                label: 'Net Income',
                                yAxisID: 'price',
                                borderColor: 'purple',
                                lineTension: 0,
                                pointHoverBorderWidth: 2,
                                pointHoverBackgroundColor: '#1A607E',
                                pointHoverRadius: 5,
                                pointHoverBorderColor: '#fff',
                                pointRadius: 0,
                                fill: false,
                                data: data === null || data === void 0 ? void 0 : data.month_totals.map(o => -1 * o.income_total - o.expense_total),
                            },
                            {
                                type: 'line',
                                label: 'Savings Rate',
                                yAxisID: 'percentage',
                                borderColor: '#1A607E', //E05951
                                lineTension: 0,
                                pointHoverBorderWidth: 2,
                                pointHoverBackgroundColor: '#1A607E',
                                pointHoverRadius: 5,
                                pointHoverBorderColor: '#fff',
                                pointRadius: 0,
                                fill: false,
                                data: data === null || data === void 0 ? void 0 : data.month_totals.map(o => {
                                    return (o === null || o === void 0 ? void 0 : o.income_total)
                                        ? ((o === null || o === void 0 ? void 0 : o.income_total) + (o === null || o === void 0 ? void 0 : o.expense_total)) / (o === null || o === void 0 ? void 0 : o.income_total)
                                        : null;
                                }),
                            },
                        ], tooltipData: {
                            header: data === null || data === void 0 ? void 0 : data.month_totals.map(o => {
                                return `${Moment(o.month).format('MMMM YYYY')}`;
                            }),
                            links: data === null || data === void 0 ? void 0 : data.month_totals.map(o => {
                                return [
                                    {
                                        display: o.count > 0,
                                        url: `/transactions?${toQuery({
                                            end_date: Moment(o.month)
                                                .endOf('month')
                                                .format('YYYY-MM-DD'),
                                            match: 'all',
                                            start_date: o.month,
                                            time: 'custom',
                                            is_income: false,
                                        })}`,
                                        text: 'View expenses',
                                    },
                                    {
                                        display: o.count > 0,
                                        url: `/transactions?${toQuery({
                                            end_date: Moment(o.month)
                                                .endOf('month')
                                                .format('YYYY-MM-DD'),
                                            match: 'all',
                                            start_date: o.month,
                                            time: 'custom',
                                            is_income: true,
                                        })}`,
                                        text: 'View income',
                                    },
                                ];
                            }),
                            data: data === null || data === void 0 ? void 0 : data.month_totals.map(o => {
                                return [
                                    {
                                        color: '#1A607E',
                                        key: 'Savings Rate',
                                        value: (o === null || o === void 0 ? void 0 : o.income_total)
                                            ? (0, format_1.toPercentage)(((o === null || o === void 0 ? void 0 : o.income_total) + (o === null || o === void 0 ? void 0 : o.expense_total)) /
                                                (o === null || o === void 0 ? void 0 : o.income_total), 2)
                                            : '-',
                                        rawValue: (o === null || o === void 0 ? void 0 : o.income_total)
                                            ? ((o === null || o === void 0 ? void 0 : o.income_total) + (o === null || o === void 0 ? void 0 : o.expense_total)) /
                                                (o === null || o === void 0 ? void 0 : o.income_total)
                                            : null,
                                    },
                                    {
                                        color: 'purple',
                                        key: 'Net income',
                                        value: o.income_total || o.expense_total
                                            ? (0, format_1.toPrice)(-1 * o.income_total - o.expense_total, _user.primaryCurrency)
                                            : null,
                                        rawValue: o.income_total || o.expense_total
                                            ? -1 * o.income_total - o.expense_total
                                            : null,
                                    },
                                ];
                            }),
                        }, displayPercentageAxis: true }))) : (React.createElement(semantic_ui_react_1.Card.Content, null,
                    React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "tiny", className: "mt-1rem mb-1rem" })))),
            React.createElement(semantic_ui_react_1.Card, { fluid: true },
                React.createElement(semantic_ui_react_1.Card.Content, { header: 'Highest expenses per month', className: "card-header" }),
                (data === null || data === void 0 ? void 0 : data.expensive) && !isLoading ? (React.createElement(semantic_ui_react_1.Card.Content, null,
                    React.createElement(TrendsChart_1.default, { identifier: '2', labels: dateLabels, datasets: [
                            {
                                yAxisID: 'price',
                                maxBarThickness: 40,
                                backgroundColor: '#0982AA',
                                hoverBackgroundColor: '#0982AA',
                                label: 'first',
                                data: (_b = data === null || data === void 0 ? void 0 : data.expensive) === null || _b === void 0 ? void 0 : _b.map(o => { var _a; return (_a = o[0]) === null || _a === void 0 ? void 0 : _a.to_base; }),
                            },
                            {
                                yAxisID: 'price',
                                maxBarThickness: 40,
                                backgroundColor: '#3DA0C0',
                                hoverBackgroundColor: '#3DA0C0',
                                label: 'second',
                                data: (_c = data === null || data === void 0 ? void 0 : data.expensive) === null || _c === void 0 ? void 0 : _c.map(o => { var _a; return (_a = o[1]) === null || _a === void 0 ? void 0 : _a.to_base; }),
                            },
                            {
                                yAxisID: 'price',
                                maxBarThickness: 40,
                                backgroundColor: '#89C8DD',
                                hoverBackgroundColor: '#89C8DD',
                                label: 'third',
                                data: (_d = data === null || data === void 0 ? void 0 : data.expensive) === null || _d === void 0 ? void 0 : _d.map(o => { var _a; return (_a = o[2]) === null || _a === void 0 ? void 0 : _a.to_base; }),
                            },
                        ], tooltipData: {
                            header: (_e = data === null || data === void 0 ? void 0 : data.expensive) === null || _e === void 0 ? void 0 : _e.map(o => {
                                var _a;
                                return Moment((_a = o[0]) === null || _a === void 0 ? void 0 : _a.date).format('MMMM YYYY');
                            }),
                            links: (_f = data === null || data === void 0 ? void 0 : data.expensive) === null || _f === void 0 ? void 0 : _f.map((o, index) => {
                                if (o.length > 0) {
                                    return [
                                        {
                                            display: true,
                                            url: `/transactions/${Moment(monthList[index])
                                                .endOf('month')
                                                .format('YYYY/MM')}?direction=descending&sort=amount`,
                                            text: 'View transactions by amount',
                                        },
                                    ];
                                }
                                else {
                                    return [];
                                }
                            }),
                            data: (_g = data === null || data === void 0 ? void 0 : data.expensive) === null || _g === void 0 ? void 0 : _g.map(o => {
                                return o.map(expense => {
                                    return {
                                        key: expense === null || expense === void 0 ? void 0 : expense.payee,
                                        value: (0, format_1.toPrice)(expense === null || expense === void 0 ? void 0 : expense.amount, expense === null || expense === void 0 ? void 0 : expense.currency),
                                        rawValue: (expense === null || expense === void 0 ? void 0 : expense.amount) || null,
                                    };
                                });
                            }),
                        } }))) : (React.createElement(semantic_ui_react_1.Card.Content, null,
                    React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "tiny", className: "mt-1rem mb-1rem" })))),
            React.createElement(semantic_ui_react_1.Card, { fluid: true },
                React.createElement(semantic_ui_react_1.Card.Content, { header: 'Transaction Totals and Counts by Merchant', className: "card-header" }),
                (data === null || data === void 0 ? void 0 : data.merchants) && !isLoading ? (React.createElement(semantic_ui_react_1.Card.Content, null,
                    React.createElement("div", { className: "stats-select-header flex--align-end" },
                        React.createElement("p", { className: "font--bold label-style mr-1rem no-wrap" }, "Select merchant:"),
                        React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal", selection: true, fluid: true, value: merchantIndex, onChange: (e, { value }) => {
                                setMerchantIndex(value);
                            }, options: (data === null || data === void 0 ? void 0 : data.merchants.map((o, index) => {
                                return {
                                    key: `${o === null || o === void 0 ? void 0 : o.merchant_name}-${index}`,
                                    value: index,
                                    text: o === null || o === void 0 ? void 0 : o.merchant_name,
                                };
                            })) || [] })),
                    React.createElement(TrendsChart_1.default, { identifier: '3', labels: dateLabels, datasets: [
                            {
                                type: 'line',
                                label: 'Transactions count',
                                yAxisID: 'count',
                                borderColor: '#A9DD2F', //E05951
                                lineTension: 0,
                                pointHoverBorderWidth: 2,
                                pointHoverBackgroundColor: '#A9DD2F',
                                pointHoverRadius: 5,
                                pointHoverBorderColor: '#fff',
                                pointRadius: 0,
                                fill: false,
                                data: (_h = data === null || data === void 0 ? void 0 : data.merchants[merchantIndex]) === null || _h === void 0 ? void 0 : _h.months.map(o => o === null || o === void 0 ? void 0 : o.count),
                            },
                            {
                                type: 'bar',
                                label: 'Transactions total',
                                yAxisID: 'price',
                                maxBarThickness: 40,
                                backgroundColor: '#4fc4c0',
                                hoverBackgroundColor: '#4fc4c0',
                                data: (_j = data === null || data === void 0 ? void 0 : data.merchants[merchantIndex]) === null || _j === void 0 ? void 0 : _j.months.map(o => o === null || o === void 0 ? void 0 : o.total),
                            },
                        ], tooltipData: {
                            header: (_k = data === null || data === void 0 ? void 0 : data.merchants[merchantIndex]) === null || _k === void 0 ? void 0 : _k.months.map(o => {
                                return `${Moment(o.transaction_month).format(_user.getMonthYearFormat())}: ${o.merchant_name}`;
                            }),
                            links: (_l = data === null || data === void 0 ? void 0 : data.merchants[merchantIndex]) === null || _l === void 0 ? void 0 : _l.months.map(o => {
                                return [
                                    {
                                        display: o.count > 0,
                                        text: 'View transactions',
                                        url: `/transactions?${toQuery({
                                            start_date: o.transaction_month,
                                            end_date: Moment(o.transaction_month)
                                                .endOf('month')
                                                .format('YYYY-MM-DD'),
                                            match: 'all',
                                            time: 'custom',
                                            payee_exact: data === null || data === void 0 ? void 0 : data.merchants[merchantIndex].merchant_name,
                                        }, false)}`,
                                    },
                                    {
                                        display: o.count > 0,
                                        text: 'View in query tool',
                                        url: `/analyze?${qs.stringify({
                                            opts: qs.stringify({
                                                dataset_name: o.merchant_name,
                                                start_date: o.transaction_month,
                                                end_date: Moment(o.transaction_month)
                                                    .endOf('month')
                                                    .format('YYYY-MM-DD'),
                                                time: 'custom',
                                                payee: o.merchant_name,
                                                payee_match_type: 'exact',
                                            }),
                                        })}
                      `,
                                    },
                                ];
                            }),
                            data: (_m = data === null || data === void 0 ? void 0 : data.merchants[merchantIndex]) === null || _m === void 0 ? void 0 : _m.months.map(o => {
                                const obj = [];
                                if (o.count > 0) {
                                    obj.push({
                                        color: '#4fc4c0',
                                        key: 'Total spending',
                                        value: (0, format_1.toPrice)(o.total, _user.primaryCurrency),
                                        rawValue: o.total,
                                    });
                                }
                                return [
                                    ...obj,
                                    {
                                        color: '#A9DD2F',
                                        key: 'No. of transactions',
                                        value: o.count,
                                        rawValue: o.count,
                                    },
                                ];
                            }),
                        }, displayCountAxis: true }))) : (React.createElement(semantic_ui_react_1.Card.Content, null,
                    React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "tiny", className: "mt-1rem mb-1rem" })))),
            React.createElement(semantic_ui_react_1.Card, { fluid: true },
                React.createElement(semantic_ui_react_1.Card.Content, { header: 'Transaction Totals and Counts by Category', className: "card-header" }),
                (data === null || data === void 0 ? void 0 : data.categories) && !isLoading ? (React.createElement(semantic_ui_react_1.Card.Content, null,
                    React.createElement("div", { className: "stats-select-header flex--center" },
                        React.createElement("span", { className: "font--bold label-style mr-1rem no-wrap" }, "Select category:"),
                        React.createElement(EditableCategory_1.default, { className: "mr-1rem", identifier: `bulk-edit-category`, firstValue: category, placeholder: 'Type to search or add new', state: 'Editing', location: 'modal', allowSelectingGroups: true, onSave: (category) => __awaiter(void 0, void 0, void 0, function* () {
                                setCategory(category);
                            }) })),
                    React.createElement(TrendsChart_1.default, { identifier: '4', labels: dateLabels, datasets: [
                            {
                                type: 'line',
                                label: 'Transactions count',
                                yAxisID: 'count',
                                borderColor: '#F3CE55', //E05951
                                lineTension: 0,
                                pointHoverBorderWidth: 2,
                                pointHoverBackgroundColor: '#F3CE55',
                                pointHoverRadius: 5,
                                pointHoverBorderColor: '#fff',
                                pointRadius: 0,
                                fill: false,
                                data: showTxCount
                                    ? (_o = data === null || data === void 0 ? void 0 : data.categories[category]) === null || _o === void 0 ? void 0 : _o.map(o => o.count)
                                    : [],
                            },
                            {
                                type: 'bar',
                                label: 'Transactions budget',
                                yAxisID: 'price',
                                maxBarThickness: 40,
                                backgroundColor: '#0186A9',
                                hoverBackgroundColor: '#0186A9',
                                data: (_p = data === null || data === void 0 ? void 0 : data.categories[category]) === null || _p === void 0 ? void 0 : _p.map((o, index) => {
                                    if (budgetData &&
                                        budgetData.hasOwnProperty(category) &&
                                        budgetData[category].hasOwnProperty(monthList[index])) {
                                        return budgetData[category][monthList[index]];
                                    }
                                    else {
                                        return null;
                                    }
                                }),
                            },
                            {
                                type: 'bar',
                                label: 'Transactions price',
                                yAxisID: 'price',
                                maxBarThickness: 40,
                                backgroundColor: '#ed7373',
                                hoverBackgroundColor: '#ed7373',
                                data: (_q = data === null || data === void 0 ? void 0 : data.categories[category]) === null || _q === void 0 ? void 0 : _q.map(o => {
                                    if (_categories.get(category).is_income) {
                                        return o.total * -1;
                                    }
                                    else {
                                        return o.total;
                                    }
                                }),
                            },
                        ], tooltipData: {
                            header: (_r = data === null || data === void 0 ? void 0 : data.categories[category]) === null || _r === void 0 ? void 0 : _r.map(o => {
                                return `${Moment(o.transaction_month).format(_user.getMonthYearFormat())}: ${_categories.getName(category)}`;
                            }),
                            links: (_s = data === null || data === void 0 ? void 0 : data.categories[category]) === null || _s === void 0 ? void 0 : _s.map((o, index) => {
                                return [
                                    {
                                        display: !!budgetData[category][monthList[index]],
                                        text: 'View budget',
                                        url: `/budget/${Moment(o.transaction_month).format('YYYY/MM')}?category=${category}`,
                                    },
                                    {
                                        display: o.count > 0,
                                        text: 'View transactions',
                                        url: `/transactions?${toQuery({
                                            start_date: o.transaction_month,
                                            end_date: Moment(o.transaction_month)
                                                .endOf('month')
                                                .format('YYYY-MM-DD'),
                                            match: 'all',
                                            time: 'custom',
                                            category: o.category_id,
                                        }, false)}`,
                                    },
                                    {
                                        display: o.count > 0,
                                        text: 'View in query tool',
                                        url: `/analyze?${qs.stringify({
                                            opts: qs.stringify({
                                                dataset_name: _categories.getName(category),
                                                start_date: o.transaction_month,
                                                end_date: Moment(o.transaction_month)
                                                    .endOf('month')
                                                    .format('YYYY-MM-DD'),
                                                time: 'custom',
                                                category_ids: [category],
                                            }),
                                        })}
                      `,
                                    },
                                ];
                            }),
                            data: (_t = data === null || data === void 0 ? void 0 : data.categories[category]) === null || _t === void 0 ? void 0 : _t.map((o, index) => {
                                const obj = [];
                                if (o.count > 0) {
                                    obj.push({
                                        color: '#ed7373',
                                        key: _categories.get(category).is_income
                                            ? 'Total earning'
                                            : 'Total spending',
                                        value: (0, format_1.toPrice)(_categories.get(category).is_income
                                            ? o.total * -1
                                            : o.total, _user.primaryCurrency),
                                        rawValue: _categories.get(category).is_income
                                            ? o.total * -1
                                            : o.total,
                                    });
                                }
                                return [
                                    {
                                        color: '#0186A9',
                                        key: 'Budgeted',
                                        value: budgetData[category][monthList[index]] == null
                                            ? 'N/A'
                                            : (0, format_1.toPrice)(budgetData[category][monthList[index]], _user.primaryCurrency),
                                        rawValue: budgetData[category][monthList[index]] == null
                                            ? null
                                            : budgetData[category][monthList[index]],
                                    },
                                    ...obj,
                                    {
                                        color: '#F3CE55',
                                        key: 'No. of transactions',
                                        value: o.count,
                                        rawValue: o.count,
                                    },
                                ];
                            }),
                        }, displayCountAxis: showTxCount }))) : (React.createElement(semantic_ui_react_1.Card.Content, null,
                    React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "tiny", className: "mt-1rem mb-1rem" })))))));
};
exports.default = Trends;
