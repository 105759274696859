"use strict";
/**
 *  CreateModal.tsx
 *  Recurring expenses new modal
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_1 = require("@helpers/format");
const react_1 = require("react");
const recurring_expenses_1 = require("@actions/recurring_expenses");
const ModalProvider_1 = require("@providers/ModalProvider");
const UserProvider_1 = require("@providers/UserProvider");
const TransactionDetails_1 = require("@components/RecurringExpenses/AddSteps/TransactionDetails");
const RuleDetails_1 = require("@components/RecurringExpenses/AddSteps/RuleDetails");
const RecurringProvider_1 = require("@/providers/RecurringProvider");
const CreateModal = ({ 
/** Recurring expense to be edited */
data, useModal, utils, setVisibility, switchView, setDisableOnClickOutside, }) => {
    var _a, _b;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _modal = (0, react_1.useContext)(ModalProvider_1.ModalContext);
    const _recurring = (0, react_1.useContext)(RecurringProvider_1.RecurringContext);
    // Comes in with modal data from RecurringView (create from transaction)
    // and SuggestedModal
    const [expense, setExpense] = (0, react_1.useState)(((_b = (_a = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.recurring) || {
        payee: null,
        category_id: null,
        description: null,
        cadence: 'monthly',
        granularity: null,
        quantity: null,
        amount: null,
        amount_2: null,
        currency: data.primaryCurrency,
        billing_date: data.currentPeriod || Moment().format('YYYY-MM-DD'),
        asset_id: null,
        plaid_account_id: null,
        notes: null,
        start_date: Moment(data.currentPeriod || undefined)
            .startOf('month')
            .format('YYYY-MM-DD'),
        end_date: null,
        criteria_name: null,
        criteria_name_match: 'contain',
    });
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [step, setStep] = (0, react_1.useState)(1);
    (0, react_1.useEffect)(() => {
        var _a, _b, _c, _d;
        useModal({
            header: (React.createElement("div", null,
                ((_b = (_a = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.previous) && (React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        var _a, _b;
                        switchView((_b = (_a = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.previous);
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " ", (_d = (_c = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 :
                    _d.previous_text)),
                React.createElement("span", { className: "header-text-large" }, "Create Recurring Item"))),
            middleButton: step > 1 && (React.createElement(semantic_ui_react_1.Button, { color: 'orange', basic: true, onClick: () => {
                    setStep(step - 1);
                } }, 'Back')),
            leftButton: (React.createElement(semantic_ui_react_1.Button, { className: "no-border", color: 'orange', basic: true, onClick: () => {
                    setVisibility(false, true);
                } }, 'Cancel')),
        });
        return () => {
            useModal({
                header: React.createElement(React.Fragment, null),
                middleButton: React.createElement(React.Fragment, null),
                leftButton: React.createElement(React.Fragment, null),
            });
        };
    }, [step]);
    const submit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d;
        setIsLoading(true);
        if (expense.asset_id == 'cash') {
            expense.asset_id = null;
        }
        if (expense.amount_type == 'credits') {
            // Flip sign
            expense.amount = expense.amount * -1;
            expense.amount_2 = expense.amount_2 ? expense.amount_2 * -1 : null;
        }
        const results = yield utils._process(recurring_expenses_1.createRecurringExpense)(Object.assign(Object.assign({}, (0, format_1.removeNullValues)(expense)), { old_recurring_id: expense.recurring_id }));
        if (!results.error) {
            yield _recurring.fetchItems();
            if (data.refreshExpenses) {
                yield data.refreshExpenses();
            }
            if (data.removeSuggested) {
                // Coming from Suggested Modal
                data.removeSuggested(_modal.data.current.recurring.recurring_id);
            }
            if (data.amendTransactions &&
                expense.transaction_id &&
                ((_c = results === null || results === void 0 ? void 0 : results.data) === null || _c === void 0 ? void 0 : _c.recurring_id)) {
                data.amendTransactions({
                    update: {
                        id: expense.transaction_id,
                        category_id: expense.category_id,
                        display_name: expense.payee,
                        display_notes: expense.description,
                        recurring_payee: expense.payee,
                        recurring_cadence: expense.cadence,
                        recurring_granularity: expense.granularity,
                        recurring_quantity: expense.quantity,
                        recurring_amount: expense.amount,
                        recurring_currency: expense.currency,
                        recurring_description: expense.description,
                        status: 'cleared',
                        recurring_id: results.data.recurring_id,
                    },
                });
            }
            if (data.submitCallback) {
                data.submitCallback();
            }
            if (data.hasOwnProperty('setShowDryRunRules') &&
                ((_d = results === null || results === void 0 ? void 0 : results.data) === null || _d === void 0 ? void 0 : _d.rule_criteria_id)) {
                data.setShowDryRunRules(results.data.rule_criteria_id);
            }
            utils._showToast({
                message: 'Successfully created recurring item',
                type: 'success',
            });
            setIsLoading(false);
            setVisibility(false, true);
        }
        setIsLoading(false);
    });
    (0, react_1.useEffect)(() => {
        setDisableOnClickOutside(true);
        useModal({
            rightButton: (React.createElement(semantic_ui_react_1.Button, { color: 'orange', disabled: (step == 1 &&
                    (!expense.payee ||
                        (_user.settings['allow_recurring_categories'] &&
                            !expense.category_id) ||
                        !expense.cadence ||
                        (expense.cadence == 'custom' &&
                            (expense.granularity == null ||
                                isNaN(expense.quantity) ||
                                expense.quantity < 1 ||
                                expense.quantity == null)) ||
                        !expense.billing_date)) ||
                    (step == 2 &&
                        (expense.amount == null ||
                            (expense.amount_match == 'between' && !expense.amount_2))), loading: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    if (step == 2) {
                        submit();
                    }
                    else {
                        setStep(step + 1);
                    }
                }) }, step == null || step == 2 ? 'Create' : 'Next')),
        });
    }, [expense, step, isLoading]);
    return (React.createElement("div", { id: "recurring-modal-detail-view" },
        step == 1 && (React.createElement(TransactionDetails_1.default, { expense: expense, setExpense: setExpense })),
        step == 2 && React.createElement(RuleDetails_1.default, { expense: expense, setExpense: setExpense })));
};
exports.default = CreateModal;
