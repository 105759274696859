"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DAY_MATCH_TYPES = exports.AMOUNT_MATCH_TYPES = exports.STRING_MATCH_TYPES = void 0;
exports.STRING_MATCH_TYPES = [
    { key: 'contain', value: 'contain', text: 'contain' },
    { key: 'start_with', value: 'start_with', text: 'start with' },
    { key: 'exact', value: 'exact', text: 'match exactly' },
];
exports.AMOUNT_MATCH_TYPES = [
    { key: 'greater_than', value: 'greater than', text: 'greater than' },
    {
        key: 'greater_than_or_equal_to',
        value: 'greater than or equal to',
        text: 'greater than or equal to',
    },
    { key: 'less_than', value: 'less than', text: 'less than' },
    {
        key: 'less_than_or_equal_to',
        value: 'less than or equal to',
        text: 'less than or equal to',
    },
    { key: 'between', value: 'between', text: 'between (exclusive)' },
    { key: 'exactly', value: 'exactly', text: 'exactly' },
];
exports.DAY_MATCH_TYPES = [
    { key: 'before', value: 'before', text: 'before' },
    { key: 'on or before', value: 'on or before', text: 'on or before' },
    { key: 'after', value: 'after', text: 'after' },
    { key: 'on or after', value: 'on or after', text: 'on or after' },
    { key: 'between', value: 'between', text: 'between (inclusive)' },
    { key: 'exactly', value: 'exactly', text: 'exactly' },
];
