"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.forceDeleteTag = exports.deleteTag = exports.updateTag = exports.getTagsPage = exports.getTagColors = exports.setTagColors = exports.getTagSummary = exports.getTags = exports.createTag = void 0;
const common_1 = require("./common");
const createTag = (obj) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`tags`, obj);
});
exports.createTag = createTag;
const getTags = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`tags`, {});
});
exports.getTags = getTags;
const getTagSummary = (params) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`tags/summary`, params);
});
exports.getTagSummary = getTagSummary;
const setTagColors = (tag_id, params) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`tags/${tag_id}/colors`, params);
});
exports.setTagColors = setTagColors;
const getTagColors = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`tags/colors`, {});
});
exports.getTagColors = getTagColors;
const getTagsPage = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)('snapshot/tags_page', {});
});
exports.getTagsPage = getTagsPage;
const updateTag = (tag_id, update) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`tags/${tag_id}`, update);
});
exports.updateTag = updateTag;
const deleteTag = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.del)(`tags/${id}`, {});
});
exports.deleteTag = deleteTag;
const forceDeleteTag = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.del)(`tags/${id}/force`);
});
exports.forceDeleteTag = forceDeleteTag;
