"use strict";
/**
 *  Settings.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const locales_1 = require("@helpers/locales");
// Semantic UI
const semantic_ui_react_1 = require("semantic-ui-react");
const UserProvider_1 = require("@providers/UserProvider");
const StaticProvider_1 = require("@providers/StaticProvider");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const Collaborators_1 = require("@components/Settings/Collaborators");
const Notifications_1 = require("@components/Settings/Notifications");
const Preferences_1 = require("@components/Settings/Preferences");
const StartFresh_1 = require("@components/Settings/StartFresh");
const Settings = ({ _process, _showToast, history }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _static = (0, react_1.useContext)(StaticProvider_1.StaticContext);
    const [isSavingChanges, setIsSavingChanges] = (0, react_1.useState)(false);
    //
    const [displayName, setDisplayName] = (0, react_1.useState)(_user.displayName || '');
    const [primaryCurrency, setPrimaryCurrency] = (0, react_1.useState)(_user.primaryCurrency);
    const [supportedCurrencies, setSupportedCurrencies] = (0, react_1.useState)(_user.supportedCurrencies || []);
    const [locale, setLocale] = (0, react_1.useState)(_user.locale);
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = 'Settings - Lunch Money';
        if (history.location.hash == '#billing') {
            history.replace('/billing');
        }
    }, []);
    const toFormat = (locale, currency) => {
        const formatter = new Intl.NumberFormat(locale || 'en-US', {
            style: 'currency',
            currency: currency, // || _user.primaryCurrency,
        });
        return formatter.format(123456789);
    };
    const onSaveUserSetting = (key, value) => __awaiter(void 0, void 0, void 0, function* () {
        yield _user.updateSetting(key, value);
        _showToast({
            message: 'Successfully saved settings',
            type: 'success',
        });
        setIsSavingChanges(false);
    });
    const onSave = () => __awaiter(void 0, void 0, void 0, function* () {
        _user.updateAccountSettings({
            primary_currency: primaryCurrency,
            supported_currencies: supportedCurrencies.join(','),
            display_name: displayName,
            locale: locale,
        });
        _showToast({
            message: 'Successfully saved settings',
            type: 'success',
        });
        setIsSavingChanges(false);
    });
    return (React.createElement(semantic_ui_react_1.Container, null,
        React.createElement(ContainerHeader_1.default, { title: "Settings" }),
        !_user.isAdmin && (React.createElement(semantic_ui_react_1.Message, { error: true, className: "display--block mt-0 mb-1rem" }, "You are not the owner of this budget and may not make certain changes to the settings of this account.")),
        React.createElement("div", { className: "g-budget-settings" },
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Form, { className: "mb-1rem" },
                    React.createElement(semantic_ui_react_1.Card, null,
                        React.createElement(semantic_ui_react_1.Card.Content, { header: "Details" }),
                        React.createElement(semantic_ui_react_1.Card.Content, null,
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement("label", null, "Budget's Name"),
                                    React.createElement(semantic_ui_react_1.Form.Input, { disabled: !_user.isAdmin, value: displayName, placeholder: `Unnamed Budget`, onChange: e => {
                                            setDisplayName(e.target.value);
                                        } }))),
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement("label", null,
                                        "Primary Currency",
                                        ' ',
                                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }), hoverable: true, size: "small" },
                                            React.createElement("b", null, "Don't see your currency?"),
                                            " If a currency you'd like to track doesn't show up in our list, simply e-mail us at",
                                            ' ',
                                            React.createElement("a", { href: "mailto:support@lunchmoney.app" }, "support@lunchmoney.app"),
                                            ' ',
                                            "and we'll get it added!")),
                                    React.createElement(semantic_ui_react_1.Dropdown, { disabled: !_user.isAdmin, className: "category-dropdown p-category-modal", placeholder: "Select currency", search: true, selection: true, fluid: true, value: primaryCurrency, onChange: (e, { value }) => {
                                            setPrimaryCurrency(value.toString());
                                        }, options: _static.allCurrencies }))),
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement("label", null,
                                        "Supported Currencies",
                                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }), hoverable: true, size: "small" },
                                            React.createElement("b", null, "Don't see your currency?"),
                                            " If a currency you'd like to track doesn't show up in our list, simply e-mail us at",
                                            ' ',
                                            React.createElement("a", { href: "mailto:support@lunchmoney.app" }, "support@lunchmoney.app"),
                                            ' ',
                                            "and we'll get it added!")),
                                    React.createElement(semantic_ui_react_1.Dropdown, { disabled: !_user.isAdmin, className: "width-100 category-dropdown p-category-modal multiselect-search-dropdown", placeholder: "Select currencies", fluid: true, multiple: true, search: true, selection: true, value: supportedCurrencies, onChange: (e, { value }) => {
                                            setSupportedCurrencies(value);
                                        }, options: _static.allCurrencies }))),
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement("label", null, "Currency locale"),
                                    React.createElement(semantic_ui_react_1.Dropdown, { disabled: !_user.isAdmin, className: "category-dropdown p-category-modal", placeholder: "Select display format", selection: true, fluid: true, search: true, value: locale, onChange: (e, { value }) => {
                                            setLocale(value.toString());
                                        }, options: locales_1.LOCALES.map(locale => {
                                            return {
                                                key: locale[2],
                                                value: locale[2],
                                                text: `${locale[0]} (${locale[1]}): ${toFormat(locale[2], primaryCurrency)}`,
                                            };
                                        }) }))),
                            supportedCurrencies.length > 1 && (React.createElement(semantic_ui_react_1.Message, { info: true },
                                React.createElement(semantic_ui_react_1.Message.Header, { className: "mb-05rem" }, "Preview"),
                                supportedCurrencies.map(o => {
                                    var _a, _b;
                                    return (React.createElement("p", { key: `sample-${o}` }, (_b = (_a = _static.allCurrencies) === null || _a === void 0 ? void 0 : _a.find(c => c.value == o)) === null || _b === void 0 ? void 0 :
                                        _b.text,
                                        ": ",
                                        toFormat(locale, o)));
                                }))),
                            React.createElement(semantic_ui_react_1.Button, { disabled: !_user.isAdmin, size: "small", onClick: onSave, loading: isSavingChanges }, "Save Changes")))),
                React.createElement(Collaborators_1.default, { _process: _process, _showToast: _showToast })),
            React.createElement("div", null,
                React.createElement(Preferences_1.default, { _showToast: _showToast }),
                React.createElement(Notifications_1.default, { _showToast: _showToast }),
                React.createElement(StartFresh_1.default, null)))));
};
exports.default = Settings;
