"use strict";
/**
 *  AccountCard.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const global_1 = require("@actions/global");
const react_router_dom_1 = require("react-router-dom");
const Final = ({}) => {
    const history = (0, react_router_dom_1.useHistory)();
    const [showHelp, setShowHelp] = (0, react_1.useState)(false);
    return (React.createElement("div", { id: "final-onboarding" },
        React.createElement("div", { className: "header-container" },
            React.createElement("span", { className: "step-label" }, "Step 3"),
            React.createElement(semantic_ui_react_1.Header, { as: "h2" }, "You're all set up!")),
        React.createElement("div", null,
            React.createElement("p", null, "Great job! Now, choose an option below to get started:"),
            React.createElement("div", null,
                React.createElement("div", { className: "type-container" },
                    React.createElement("div", { className: "type-icon" },
                        React.createElement("img", { src: "/public/images/accounts.svg" })),
                    React.createElement("div", { className: "type-content" },
                        React.createElement(semantic_ui_react_1.Header, { as: "h3" }, "Import transactions from your bank"),
                        React.createElement("p", null, "Sync data directly and securely with your bank."),
                        React.createElement("p", null, "We support most banks and institutions in the following countries: Canada, Denmark, France, Germany, Ireland, the Netherlands, Poland, Portugal, Spain, Sweden and the US."),
                        React.createElement(semantic_ui_react_1.Button, { icon: "arrow right", labelPosition: "right", size: "small", content: "Link a bank account", onClick: () => {
                                history.push('/accounts');
                            }, color: "orange", className: "mb-1rem" }),
                        React.createElement("p", null,
                            React.createElement("a", { className: "link clickable", href: "https://support.lunchmoney.app/importing-transactions/automatic-imports#support-for-international-banks", target: "_blank" },
                                React.createElement(semantic_ui_react_1.Icon, { name: "external alternate" }),
                                " When will you support more banks for automatic imports?")))),
                React.createElement("div", { className: "type-container" },
                    React.createElement("div", { className: "type-icon" },
                        React.createElement("svg", { width: "36", height: "42", xmlns: "http://www.w3.org/2000/svg" },
                            React.createElement("g", { transform: "translate(1 1)", fill: "none", fillRule: "evenodd" },
                                React.createElement("path", { stroke: "#39322E", fillOpacity: ".6", fill: "#F8B500", d: "M34 30v10H1V30z" }),
                                React.createElement("path", { d: "M29.5 13.207L20.793 4.5H6a.5.5 0 00-.5.5v30a.5.5 0 00.5.5h23a.5.5 0 00.5-.5V13.207z", stroke: "#393E46", fill: "#FFF" }),
                                React.createElement("path", { d: "M20.5 4.5v9M20.5 13.5h9", stroke: "#393E46", strokeLinecap: "square" }),
                                React.createElement("text", { fontFamily: "Inconsolata-Bold, Inconsolata", fontSize: "10", fontWeight: "bold", fill: "#393E46" },
                                    React.createElement("tspan", { x: "10", y: "30" }, "CSV")),
                                React.createElement("circle", { stroke: "#44958C", fill: "#44958C", cx: "5.5", cy: "5.5", r: "5.5" }),
                                React.createElement("rect", { fill: "#FFF", x: "5", y: "2.2", width: "1", height: "7", rx: ".5" }),
                                React.createElement("rect", { fill: "#FFF", transform: "rotate(90 5.5 5.7)", x: "5", y: "2.2", width: "1", height: "7", rx: ".5" })))),
                    React.createElement("div", { className: "type-content" },
                        React.createElement(semantic_ui_react_1.Header, { as: "h3" }, "Upload bank statements"),
                        React.createElement("p", null, "Quickly import your transactions using statements provided by your bank with our File Import tool."),
                        React.createElement(semantic_ui_react_1.Button, { icon: "arrow right", labelPosition: "right", size: "small", content: "Upload a file (PDF/CSV)", onClick: () => {
                                history.push('/transactions/import');
                            }, color: "orange", className: "mb-1rem" }),
                        React.createElement("p", null,
                            React.createElement("a", { className: "link clickable", href: "https://support.lunchmoney.app/importing-transactions/import-via-csv", target: "_blank" },
                                React.createElement(semantic_ui_react_1.Icon, { name: "external alternate" }),
                                " Read more about PDF/CSV transaction imports.")))),
                React.createElement("div", { className: "type-container" },
                    React.createElement("div", { className: "type-icon" },
                        React.createElement("img", { src: "/public/images/web-icon.svg" })),
                    React.createElement("div", { className: "type-content" },
                        React.createElement(semantic_ui_react_1.Header, { as: "h3" }, "Add transactions via the web interface"),
                        React.createElement("p", null, "Enter individual transactions using the web interface."),
                        React.createElement(semantic_ui_react_1.Button, { icon: "arrow right", labelPosition: "right", onClick: () => {
                                history.push('/transactions');
                            }, size: "small", content: "Go to my transactions", color: "orange", className: "mb-1rem" }))),
                React.createElement("div", { className: "type-container" },
                    React.createElement("div", { className: "type-icon" },
                        React.createElement("img", { src: "/public/images/api-icon.svg" })),
                    React.createElement("div", { className: "type-content" },
                        React.createElement(semantic_ui_react_1.Header, { as: "h3" }, "Play with the Lunch Money developer API"),
                        React.createElement("p", null,
                            "Build your own or use a",
                            ' ',
                            React.createElement("a", { href: "https://lunchmoney.dev/#awesome-projects" }, "community-developed integration"),
                            ' ',
                            "to import transactions from various sources."),
                        React.createElement(semantic_ui_react_1.Button, { icon: "arrow right", onClick: () => {
                                history.push('/developers');
                            }, labelPosition: "right", size: "small", content: "Get an API key", color: "orange", className: "mb-1rem" }),
                        React.createElement("p", null,
                            React.createElement("a", { className: "link clickable", href: "https://lunchmoney.dev", target: "_blank" },
                                React.createElement(semantic_ui_react_1.Icon, { name: "external alternate" }),
                                " Read the API documentation"))))),
            React.createElement(semantic_ui_react_1.Header, { as: "h3" }, "Need help at any point?"),
            React.createElement("div", null, "If you need support, hit the \"Help\" button at the top right corner of the navigation or join our community Discord. We're always happy to help!"),
            React.createElement(semantic_ui_react_1.Button, { icon: true, className: "mt-1rem", style: { backgroundColor: '#7289DA' }, labelPosition: "right", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    const results = yield (0, global_1.getDiscordInviteLink)();
                    open(results);
                }) },
                "Join the Discord community ",
                React.createElement(semantic_ui_react_1.Icon, { name: "external alternate" }))),
        React.createElement(semantic_ui_react_1.Modal, { open: showHelp, onClose: () => {
                setShowHelp(false);
            }, size: "small", dimmer: "inverted" },
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("div", { style: {
                        position: 'relative',
                        paddingBottom: '61.120543293718164%',
                        height: 0,
                        backgroundColor: '#f9fafb',
                        borderTop: '1px solid #ddd',
                        borderRight: '1px solid #ddd',
                        borderLeft: '1px solid #ddd',
                    } },
                    React.createElement(semantic_ui_react_1.Loader, { size: "small", inline: "centered", active: true, style: { zIndex: 1, position: 'relative', top: '40px' } }, "Please wait, loading video.."),
                    React.createElement("iframe", { src: 'https://loom.com/embed/dc986867f70744bca9b3493b86bbcdcf', frameBorder: "0", allowFullScreen: true, 
                        // mozAllowFullScreen={true}
                        // webkitallowfullscreen={true}
                        style: {
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 10,
                        } }))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { size: "small", onClick: () => {
                        setShowHelp(false);
                    } }, "Close")))));
};
exports.default = Final;
