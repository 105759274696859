"use strict";
/**
 *  Developers.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const react_1 = require("react");
const api_token_1 = require("@actions/api_token");
const support_1 = require("@actions/support");
const semantic_ui_react_1 = require("semantic-ui-react");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const EditableString_1 = require("@/components/elements/EditableString");
const UserProvider_1 = require("@/providers/UserProvider");
const Developers = ({ _process, _showToast }) => {
    var _a, _b, _c, _d;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [token, setToken] = (0, react_1.useState)(null);
    const [currentTokens, setCurrentTokens] = (0, react_1.useState)([]);
    const [isButtonLoading, setIsButtonLoading] = (0, react_1.useState)(false);
    const [copySuccess, setCopySuccess] = (0, react_1.useState)('Copy');
    const [updateLabel, setUpdateLabel] = (0, react_1.useState)(null);
    const [labelVal, setLabelVal] = (0, react_1.useState)('');
    const [newLabelVal, setNewLabelVal] = (0, react_1.useState)('');
    const [projectNameInput, setProjectNameInput] = (0, react_1.useState)('');
    const [urlInput, setUrlInput] = (0, react_1.useState)('');
    const [descriptionTextArea, setDescriptionTextArea] = (0, react_1.useState)('');
    const [betaTextArea, setBetaTextArea] = (0, react_1.useState)('');
    const [isSaving, setIsSaving] = (0, react_1.useState)(false);
    const [apiKeyReason, setApiKeyReason] = (0, react_1.useState)(null);
    const [apiKeyReasonText, setApiKeyReasonText] = (0, react_1.useState)('');
    const tokenRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = 'Developers - Lunch Money';
        fetchTokens();
    }, []);
    const fetchTokens = () => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield _process(api_token_1.getAccessTokens)();
        if (!results.error) {
            setCurrentTokens(results);
        }
    });
    const requestToken = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsButtonLoading(true);
        const results = yield _process(api_token_1.requestAccessToken)({
            label: newLabelVal,
            apiKeyReason,
            apiKeyReasonText,
        });
        if (!results.error) {
            setToken(results.data);
            yield fetchTokens();
        }
        setIsButtonLoading(false);
    });
    const revokeToken = (id) => __awaiter(void 0, void 0, void 0, function* () {
        setIsButtonLoading(true);
        const results = yield _process(api_token_1.revokeAccessToken)(id);
        if (!results.error) {
            yield fetchTokens();
            _showToast({
                message: 'Successfully revoked token',
                type: 'success',
            });
        }
        setIsButtonLoading(false);
    });
    const updateAccessTokenLabel = (token) => __awaiter(void 0, void 0, void 0, function* () {
        setIsSaving(true);
        const results = yield _process(api_token_1.updateAccessToken)(token.id, labelVal);
        if (!results.error) {
            _showToast({
                message: 'Successfully updated label',
                type: 'success',
            });
            yield fetchTokens();
        }
        setIsSaving(false);
        setUpdateLabel(null);
        setLabelVal('');
    });
    return (React.createElement(semantic_ui_react_1.Container, null,
        React.createElement(ContainerHeader_1.default, { title: "Developers" }),
        React.createElement("div", { id: "g-developers" },
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Message, { success: true },
                    React.createElement(semantic_ui_react_1.Message.Header, null, "Submit your contribution to Lunch Money"),
                    React.createElement("p", null,
                        "Did you build something awesome with the Lunch Money API? Share it with us and we may include it in our list of",
                        ' ',
                        React.createElement("a", { href: "https://lunchmoney.dev/#awesome-projects", className: "clickable link font--bold" }, "Awesome Projects"),
                        ' ',
                        "and newsletter to all our users!"),
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(semantic_ui_react_1.Form.Input, { value: projectNameInput, placeholder: `Name of project`, onChange: (e, { value }) => {
                                        setProjectNameInput(value);
                                    } }))),
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(semantic_ui_react_1.Form.Input, { value: urlInput, placeholder: `URL to access`, onChange: (e, { value }) => {
                                        setUrlInput(value);
                                    } }))),
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(semantic_ui_react_1.Form.TextArea, { maxLength: 400, value: descriptionTextArea, placeholder: `Short description (max 400 characters)`, onChange: (e, { value }) => {
                                        setDescriptionTextArea(e.target.value);
                                    } }))),
                        React.createElement(semantic_ui_react_1.Form.Group, { className: "mb-0" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(semantic_ui_react_1.Button, { disabled: ((_a = (projectNameInput || '')) === null || _a === void 0 ? void 0 : _a.length) == 0 ||
                                        ((_b = (urlInput || '')) === null || _b === void 0 ? void 0 : _b.length) == 0 ||
                                        ((_c = (descriptionTextArea || '')) === null || _c === void 0 ? void 0 : _c.length) == 0, fluid: true, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        const results = yield _process(support_1.sendBasicFeedback)({
                                            type: 'Project Submission',
                                            message: `${projectNameInput} (${urlInput})\n\n${descriptionTextArea}`,
                                        });
                                        if (!results.error) {
                                            setProjectNameInput('');
                                            setUrlInput('');
                                            setDescriptionTextArea('');
                                            _showToast({
                                                message: 'Thanks for your contribution to our developer ecosystem! We will be in touch soon.',
                                                type: 'success',
                                            });
                                        }
                                    }) }, "Submit"))))),
                React.createElement(semantic_ui_react_1.Message, { info: true },
                    React.createElement(semantic_ui_react_1.Message.Header, null, "Coming soon: OAuth2 for third-party applications"),
                    React.createElement("p", null, "We are expanding the developer program by implementing OAuth2 to enable folks to build secure third-party apps and plugins on top of Lunch Money."),
                    React.createElement("p", null, "Interested in joining the beta program when this is available? Sign up for the waitlist below and we'll be in touch!"),
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(semantic_ui_react_1.Form.TextArea, { value: betaTextArea, placeholder: `What do you want to build? Ideas: a bridge to your bank, a mobile app, a CLI tool...`, onChange: e => {
                                        setBetaTextArea(e.target.value);
                                    } }))),
                        React.createElement(semantic_ui_react_1.Form.Group, { className: "mb-0" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(semantic_ui_react_1.Button, { disabled: ((_d = (betaTextArea || '')) === null || _d === void 0 ? void 0 : _d.length) == 0, fluid: true, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        const results = yield _process(support_1.sendBasicFeedback)({
                                            type: 'OAuth2 Waitlist',
                                            message: betaTextArea,
                                        });
                                        if (!results.error) {
                                            setBetaTextArea('');
                                            _showToast({
                                                message: 'Thanks! We will be in touch soon.',
                                                type: 'success',
                                            });
                                        }
                                    }) }, "Join Waitlist")))),
                    React.createElement("p", null,
                        ' ',
                        React.createElement("a", { className: "link", href: "https://lunchmoney.dev", target: "_blank" }, "Click here to view the API docs.")))),
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Form, { className: "mb-1rem" },
                    React.createElement(semantic_ui_react_1.Card, { fluid: true },
                        React.createElement(semantic_ui_react_1.Card.Content, { header: "New Access Token" }),
                        React.createElement(semantic_ui_react_1.Card.Content, null, token ? (React.createElement(React.Fragment, null,
                            React.createElement("p", null, "This is your access token. Please save it now as this is the only time it will be shown to you!"),
                            React.createElement("div", { className: "api-token" },
                                React.createElement(semantic_ui_react_1.Input, { ref: tokenRef, value: token, className: "width-100 mr-1rem" }),
                                document.queryCommandSupported('copy') && (React.createElement("p", { className: "copy-link clickable", onClick: () => {
                                        tokenRef.current.select();
                                        document.execCommand('copy');
                                        setCopySuccess('Copied!');
                                    } }, copySuccess))),
                            React.createElement(semantic_ui_react_1.Button, { icon: true, labelPosition: "left", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    setToken(null);
                                    setApiKeyReason(null);
                                    setApiKeyReasonText('');
                                }) },
                                React.createElement(semantic_ui_react_1.Icon, { name: "checkmark" }),
                                "Got it!"))) : (React.createElement(React.Fragment, null,
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement(semantic_ui_react_1.Form.Input, { value: newLabelVal, placeholder: `Label for access token (optional)`, onChange: e => {
                                            setNewLabelVal(e.target.value);
                                        } }))),
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal", placeholder: "What will you use this API key for? (optional)", fluid: true, selection: true, value: apiKeyReason, onChange: (e, { value }) => {
                                            setApiKeyReason(value);
                                        }, options: [
                                            {
                                                key: 'personal-project',
                                                value: "I'm developing a personal project.",
                                                text: "I'm developing a personal project.",
                                            },
                                            {
                                                key: 'third-party-app',
                                                value: 'I need it for a third-party app that requires this API.',
                                                text: 'I need it for a third-party app that requires this API.',
                                            },
                                            {
                                                key: 'experimentation',
                                                value: 'I want to experiment with the Developer API.',
                                                text: 'I want to experiment with the Developer API.',
                                            },
                                            {
                                                key: 'development',
                                                value: "I'm developing a project that I hope other Lunch Money users can use.",
                                                text: "I'm developing a project that I hope other Lunch Money users can use.",
                                            },
                                            {
                                                key: 'other',
                                                value: 'other',
                                                text: 'Other (please specify)',
                                            },
                                        ] }))),
                            apiKeyReason == 'other' && (React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement(semantic_ui_react_1.Form.Input, { value: apiKeyReasonText, placeholder: `We'd love to know what you're using this API key for!`, onChange: e => {
                                            setApiKeyReasonText(e.target.value);
                                        } })))),
                            React.createElement(semantic_ui_react_1.Form.Group, { className: "mb-0" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement(semantic_ui_react_1.Button, { loading: isButtonLoading, onClick: requestToken, icon: true, labelPosition: "right" },
                                        "Request new access token",
                                        React.createElement(semantic_ui_react_1.Icon, { name: "key" }))))))))),
                React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(semantic_ui_react_1.Card, { fluid: true },
                        React.createElement(semantic_ui_react_1.Card.Content, { header: "Active Access Tokens" }),
                        React.createElement(semantic_ui_react_1.Card.Content, null, currentTokens.length == 0 ? (React.createElement("p", null, "You have no developer access tokens yet. Click \"Request New Access Token\" to get started.")) : (currentTokens.map((token, index) => {
                            return (React.createElement("div", { className: "token", key: `token-${index}` },
                                React.createElement("div", null,
                                    React.createElement("div", { className: `display--flex ${updateLabel == token.id
                                            ? 'flex--align-center'
                                            : ''} mb-05rem ` },
                                        updateLabel == token.id ? (React.createElement(EditableString_1.default, { state: 'Editing', location: 'modal', identifier: 'account-display-name', firstValue: labelVal, shouldSaveOnChange: true, autoFocus: true, onEnter: () => __awaiter(void 0, void 0, void 0, function* () {
                                                if (updateLabel == token.id) {
                                                    updateAccessTokenLabel(token);
                                                }
                                            }), onSave: name => {
                                                setLabelVal(name);
                                            } })) : (React.createElement("b", null, token.label || '(unnamed)')),
                                        updateLabel == token.id ? (React.createElement(semantic_ui_react_1.Icon, { name: 'x', disabled: isSaving, loading: isSaving && updateLabel == token.id, color: 'red', className: "ml-1rem clickable", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                setIsSaving(false);
                                                setUpdateLabel(null);
                                                setLabelVal('');
                                            }) })) : (React.createElement(React.Fragment, null)),
                                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, position: 'top right', size: 'small', trigger: React.createElement(semantic_ui_react_1.Icon, { name: updateLabel == token.id
                                                    ? isSaving
                                                        ? 'circle notch'
                                                        : 'checkmark'
                                                    : 'pencil', disabled: isSaving, loading: isSaving && updateLabel == token.id, color: updateLabel == token.id ? 'green' : 'grey', className: "ml-1rem clickable", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                    if (!isSaving) {
                                                        if (updateLabel == token.id) {
                                                            updateAccessTokenLabel(token);
                                                        }
                                                        else {
                                                            setUpdateLabel(token.id);
                                                            setLabelVal('');
                                                        }
                                                    }
                                                }) }) }, "Set a custom label for this API key")),
                                    React.createElement("p", { className: "monospace" },
                                        "Created",
                                        ' ',
                                        Moment(token.created_at).format(_user.getFullTimeFormat()),
                                        ' ',
                                        "by ",
                                        token.name),
                                    React.createElement("p", { className: "monospace" },
                                        "Last activity:",
                                        ' ',
                                        token.last_activity
                                            ? `${Moment(token.last_activity).format(_user.getFullTimeFormat())} (${Moment(token.last_activity).fromNow()})`
                                            : 'none')),
                                React.createElement("div", { className: "" },
                                    React.createElement(semantic_ui_react_1.Button, { loading: isButtonLoading, type: "button", color: "red", size: "mini", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                            yield revokeToken(token.id);
                                        }) }, "Revoke"))));
                        })))))))));
};
exports.default = Developers;
