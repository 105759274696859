"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeWithZabo = exports.removeSynced = exports.refreshSynced = exports.updateSynced = exports.initialize = exports.getManualStatus = exports.removeManual = exports.updateManual = exports.createManual = void 0;
const common_1 = require("./common");
const createManual = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`crypto/manual`, body);
});
exports.createManual = createManual;
const updateManual = (id, body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`crypto/manual/${id}`, body);
});
exports.updateManual = updateManual;
const removeManual = (id, body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`crypto/manual/${id}/delete`, body);
});
exports.removeManual = removeManual;
const getManualStatus = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`crypto/manual/${id}/status`, {});
});
exports.getManualStatus = getManualStatus;
// Crypto synced
const initialize = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`crypto/synced`, body);
});
exports.initialize = initialize;
const updateSynced = (id, body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`crypto/synced/${id}`, body);
});
exports.updateSynced = updateSynced;
const refreshSynced = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`crypto/synced/${id}/refresh`, {});
});
exports.refreshSynced = refreshSynced;
const removeSynced = (id_1, ...args_1) => __awaiter(void 0, [id_1, ...args_1], void 0, function* (id, body = {}) {
    return yield (0, common_1.post)(`crypto/synced/${id}/remove`, body);
});
exports.removeSynced = removeSynced;
const mergeWithZabo = (crypto_account_id, zabo_account_id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`crypto/synced/${crypto_account_id}/merge_with_zabo/${zabo_account_id}`);
});
exports.mergeWithZabo = mergeWithZabo;
