"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const UserProvider_1 = require("@providers/UserProvider");
const semantic_ui_react_1 = require("semantic-ui-react");
// Functions
const recurring_expenses_1 = require("@actions/recurring_expenses");
// Components
const format_1 = require("@helpers/format");
const Moment = require("moment");
const SuggestedRecurringRow_1 = require("@components/RecurringExpenses/SuggestedRecurringRow");
const Loader_1 = require("@components/global/Loader");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const RecurringExpenses_1 = require("@components/TaskCard/RecurringExpenses");
const NewModal_1 = require("@components/global/NewModal");
const RecurringProvider_1 = require("@/providers/RecurringProvider");
const SuggestedRecurring = ({ history, _process, _showToast }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _recurring = (0, react_1.useContext)(RecurringProvider_1.RecurringContext);
    const [suggestedRecurring, setSuggestedRecurring] = (0, react_1.useState)([]);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [sortColumn, setSortColumn] = (0, react_1.useState)('payee');
    const [sortDirection, setSortDirection] = (0, react_1.useState)('ascending');
    const [showModal, setShowModal] = (0, react_1.useState)(null);
    const [modalData, setModalData] = (0, react_1.useState)(undefined);
    const [recurringUI, setRecurringUI] = (0, react_1.useState)([]);
    const [isReady, setIsReady] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = 'Suggested Recurring Items - Lunch Money';
        refreshExpenses();
    }, []);
    /**
     * Fetch all recurring expenses and totals
     */
    const refreshExpenses = (id) => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield (0, recurring_expenses_1.getSuggestedRecurringExpenses)();
        setSuggestedRecurring(results);
        setIsLoading(false);
        setIsReady(true);
    });
    /**
     * onSave: Handler when user wants to save edits in-line
     */
    const removeItem = (id) => __awaiter(void 0, void 0, void 0, function* () {
        setSuggestedRecurring(suggestedRecurring.filter(rec => {
            return rec.id !== id;
        }));
        yield _recurring.fetchItems(); // Get new list of recurring
    });
    /**
     * Handles sorting account table
     */
    const handleSort = (clickedColumn) => {
        if (sortColumn !== clickedColumn) {
            const sortKey = clickedColumn == 'amount' ? 'to_base' : clickedColumn;
            const sorted = suggestedRecurring.sort((a, b) => {
                if (sortKey == 'matched') {
                    return a.transactions.length - b.transactions.length;
                }
                else if (sortKey === 'account') {
                    return `${a.institution_name || a.asset_institution_name}{' '}
          ${a.plaid_account_name || a.asset_name}`.toLowerCase()
                        .localeCompare(`${b.institution_name ||
                        b.asset_institution_name}{' '}
            ${b.plaid_account_name || b.asset_name}`);
                }
                else if (sortKey === 'billing_date') {
                    return Moment(a.transactions[0].formatted_date, 'YYYY-MM-DD').isBefore(Moment(b.transactions[0].formatted_date, 'YYYY-MM-DD'))
                        ? 1
                        : -1;
                }
                else if (typeof a[sortKey] === 'string') {
                    return a[sortKey]
                        .trim()
                        .toLowerCase()
                        .localeCompare(b[sortKey].toLowerCase().trim());
                }
                else if (!a[sortKey]) {
                    return -1;
                }
                else if (!b[sortKey]) {
                    return 1;
                }
                else {
                    return a[sortKey] - b[sortKey];
                }
            });
            setSuggestedRecurring([...sorted]);
            setSortColumn(clickedColumn);
            setSortDirection('ascending');
        }
        else {
            setSuggestedRecurring([...suggestedRecurring.reverse()]);
            setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
        }
    };
    (0, react_1.useEffect)(() => {
        if (!isReady) {
            return;
        }
        /**
         * Build recurring expenses table rows
         */
        setRecurringUI(suggestedRecurring.map((expense, index) => {
            if (expense.transactions && expense.transactions.length > 0) {
                return (React.createElement(SuggestedRecurringRow_1.default, { key: `recurring-expense-${expense.id}-${index}`, onOpenModal: (data, view) => {
                        setModalData(data);
                        setShowModal(view);
                    }, expense: expense }));
            }
        }));
    }, [suggestedRecurring, isReady]);
    return (React.createElement(semantic_ui_react_1.Container, { className: "g-recurring" },
        React.createElement(ContainerHeader_1.default, { title: 'Suggested Recurring Items' }),
        React.createElement(semantic_ui_react_1.Message, { info: true },
            React.createElement(semantic_ui_react_1.Message.Header, null, "What are suggested recurring items?"),
            React.createElement(semantic_ui_react_1.Message.Content, { className: "mt-05rem" },
                React.createElement("p", null, "On this page, you'll see a list of transaction payees that occurred for the same amount over a period of time. This makes us think that this is a recurring item that should be tracked separately."),
                React.createElement("p", null, "Go through the list below and let us know if it is indeed a recurring item, or if it is just a transaction you make often. We'll then track it accordingly throughout Lunch Money!"))),
        !!showModal && (React.createElement(NewModal_1.default, { show: !!showModal, setShow: (show) => {
                setShowModal(show);
            }, data: {
                recurring: modalData,
                flipSign: _user.settings['show_debits_as_negative'],
                removeSuggested: () => {
                    removeItem(modalData.id);
                },
            }, utils: {
                _process: _process,
                _showToast: _showToast,
            }, view: showModal, closeCallback: () => { } })),
        React.createElement("div", { className: "flex--space-between" },
            React.createElement(semantic_ui_react_1.Button, { className: " mt-1rem", onClick: () => {
                    history.push('/recurring');
                } }, "Back to Recurring Items")),
        React.createElement("div", { className: "p-content-container" },
            React.createElement("div", { className: "left" },
                React.createElement(semantic_ui_react_1.Table, { unstackable: true, fixed: true, sortable: true, selectable: true, celled: true, className: "p-recurring-table" },
                    React.createElement(semantic_ui_react_1.Table.Header, null,
                        React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-merchant-suggested", sorted: sortColumn === 'payee' ? sortDirection : null, onClick: () => {
                                    handleSort('payee');
                                } }, "Merchant"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-account-suggested", sorted: sortColumn === 'account' ? sortDirection : null, onClick: () => {
                                    handleSort('account');
                                } }, "Originating Account"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-tx-suggested center-align", sorted: sortColumn === 'matched' ? sortDirection : null, onClick: () => {
                                    handleSort('matched');
                                } },
                                "Matched",
                                '  ',
                                React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "This is how many transactions we matched to this suggested recurring item")),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-billing-day-suggested", sorted: sortColumn === 'billing_date' ? sortDirection : null, onClick: () => {
                                    handleSort('billing_date');
                                } },
                                "Billing Day",
                                '  ',
                                React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "This is the billing date of the last transaction we matched to this suggested recurring item.")),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "right-align table-cell-amount", sorted: sortColumn === 'amount' ? sortDirection : null, onClick: () => {
                                    handleSort('amount');
                                } }, "Amount"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-status-suggested center-align", sorted: sortColumn === 'status' ? sortDirection : null, onClick: () => {
                                    handleSort('status');
                                } }))),
                    React.createElement(semantic_ui_react_1.Table.Body, { className: !isLoading && isReady && suggestedRecurring.length === 0
                            ? 'g-empty-body'
                            : '' },
                        !isLoading && isReady && suggestedRecurring.length === 0 && (React.createElement(semantic_ui_react_1.Table.Row, { className: "g-empty-row no-hover-tr" },
                            React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: "6", className: "center-align" },
                                React.createElement("div", { className: "empty-mascot" }),
                                React.createElement("p", null, "You have no suggested recurring items!"),
                                React.createElement("p", null, "Suggested recurring items may be detected upon imported transactions via bank sync or CSV import."),
                                React.createElement(semantic_ui_react_1.Button, { className: "mt-1rem", size: "mini", onClick: () => {
                                        history.push('/recurring');
                                    } }, "Back to active recurring items")))),
                        !isLoading && isReady && recurringUI,
                        isLoading && React.createElement(Loader_1.default, { colSpan: "6" })))),
            React.createElement("div", { className: "right" },
                React.createElement(RecurringExpenses_1.default, { suggestedCount: suggestedRecurring.length }),
                React.createElement(semantic_ui_react_1.Card, null,
                    React.createElement(semantic_ui_react_1.Card.Content, { header: 'Suggested Recurring Overview' }),
                    React.createElement(semantic_ui_react_1.Card.Content, null,
                        React.createElement("div", { className: "card-content-wrapper" },
                            React.createElement("div", { className: "card-content" },
                                React.createElement("div", null,
                                    React.createElement("span", { className: "card-text" }, "Total value")),
                                React.createElement("span", { className: `card-number no-wrap` }, (0, format_1.toPrice)(suggestedRecurring.reduce((acc, cur) => {
                                    return acc + cur.to_base;
                                }, 0) *
                                    (_user.settings['show_debits_as_negative'] ? -1 : 1), _user.primaryCurrency))))))))));
};
exports.default = SuggestedRecurring;
