"use strict";
/**
 *  GiftCards.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_stripe_js_1 = require("@stripe/react-stripe-js");
const react_1 = require("react");
const billing_1 = require("@actions/billing");
const Moment = require("moment");
const gift_cards_1 = require("@actions/gift_cards");
const format_1 = require("@helpers/format");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
const stripe_js_1 = require("@stripe/stripe-js");
const UserProvider_1 = require("@providers/UserProvider");
const BillingForm_1 = require("@components/GiftCards/BillingForm");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
// Live key used by staging and production
const STRIPE_PUBLIC_KEY = window.location.href.indexOf('lunchmoney.app') >= 0
    ? 'pk_live_J0Qdm8rcgqIoGtF1FdM9IC5p00v3eq75zT'
    : 'pk_test_Rfdq1z6O7JXexWSxeaEzi11y00Op3fLOvr';
const GiftCards = ({ _showToast }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const tokenRef = (0, react_1.useRef)(null);
    // Purchasing
    const [grandTotal, setGrandTotal] = (0, react_1.useState)(0);
    const [countStr, setCountStr] = (0, react_1.useState)('1');
    const [timePeriod, setTimePeriod] = (0, react_1.useState)('6 month subscription');
    const [currency, setCurrency] = (0, react_1.useState)(['usd', 'cad', 'eur'].indexOf(_user.primaryCurrency) > -1
        ? _user.primaryCurrency
        : 'usd');
    const [error, setError] = (0, react_1.useState)(false);
    // Purchased
    const [purchased, setPurchased] = (0, react_1.useState)(null);
    const [copySuccess, setCopySuccess] = (0, react_1.useState)('Copy');
    const [sendGiftCard, setSendGiftCard] = (0, react_1.useState)(null);
    const [message, setMessage] = (0, react_1.useState)('');
    const [name, setName] = (0, react_1.useState)('');
    const [email, setEmail] = (0, react_1.useState)('');
    const [tab, setTab] = (0, react_1.useState)(0);
    const [showCode, setShowCode] = (0, react_1.useState)(false);
    const [errorMessage, setErrorMessage] = (0, react_1.useState)(null);
    const stripePromise = (0, stripe_js_1.loadStripe)(STRIPE_PUBLIC_KEY);
    (0, react_1.useEffect)(() => {
        // localStorage.setItem('_lm_gift_cards_seen', 'true')
        document.title = 'Gift Cards - Lunch Money';
        retrieve();
    }, []);
    const retrieve = () => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield (0, gift_cards_1.getGiftCards)();
        setPurchased(results);
    });
    const submitPayment = (paymentMethod) => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield (0, billing_1.charge)({
            amount: grandTotal,
            currency: currency,
            description: `${countStr}x ${timePeriod}`,
            payment_method: paymentMethod,
            data: {
                quantity: parseInt(countStr),
                duration: timePeriod == '6 month subscription' ? 6 : 12,
            },
        });
        yield retrieve();
        return results.data;
    });
    const ONES_COST = {
        usd: {
            original: 35, //45,
        },
        cad: {
            original: 42, //54,
        },
        eur: {
            original: 28, //36,
        },
    };
    (0, react_1.useEffect)(() => {
        // set grand total based on count
        const countNum = parseInt(countStr);
        if (!isNaN(countNum)) {
            const tens = Math.floor(countNum / 10);
            const fives = Math.floor((countNum - 10 * tens) / 5);
            const ones = countNum - 10 * tens - 5 * fives;
            setGrandTotal((tens * ONES_COST[currency].original * 7 +
                fives * ONES_COST[currency].original * 4 +
                ones * ONES_COST[currency].original) *
                (timePeriod == '1 year subscription' ? 2 : 1));
        }
    }, [countStr, currency, timePeriod]);
    return (React.createElement(semantic_ui_react_1.Container, null,
        React.createElement(ContainerHeader_1.default, { title: "Gift Cards" }),
        React.createElement(semantic_ui_react_1.Message, { info: true },
            React.createElement(semantic_ui_react_1.Message.Header, { className: "mb-05rem" }, "Updates coming soon!"),
            React.createElement(semantic_ui_react_1.Message.Content, null,
                React.createElement("p", null, "We are temporarily halting the sales of gift cards. Check this space for new pricing soon!"))),
        React.createElement("div", { className: "g-gift-cards" },
            React.createElement("div", { className: "left" },
                React.createElement(semantic_ui_react_1.Card, { fluid: true },
                    React.createElement(semantic_ui_react_1.Card.Content, { header: "Gift Cards pricing" }),
                    React.createElement(semantic_ui_react_1.Card.Content, null,
                        React.createElement("div", { className: "label-style left-align mt-05rem mb-05rem" },
                            "Show me prices for",
                            ' ',
                            React.createElement(semantic_ui_react_1.Dropdown, { inline: true, className: "inline-dropdown", value: timePeriod, onChange: (e, { value }) => {
                                    setTimePeriod(value);
                                }, options: ['6 month subscription', '1 year subscription'].map(time => {
                                    return {
                                        key: time,
                                        value: time,
                                        text: time,
                                    };
                                }) }),
                            ' ',
                            "in",
                            ' ',
                            React.createElement(semantic_ui_react_1.Dropdown, { inline: true, className: "inline-dropdown", value: currency, onChange: (e, { value }) => {
                                    setCurrency(value);
                                }, options: ['usd', 'cad', 'eur'].map(currency => {
                                    return {
                                        key: currency,
                                        value: currency,
                                        text: currency.toUpperCase(),
                                    };
                                }) })),
                        React.createElement("div", { className: "gift-card-container" },
                            React.createElement("div", { className: "gift-card" },
                                React.createElement("div", { className: "flex--space-between" },
                                    React.createElement("div", null,
                                        React.createElement("p", { className: "item" },
                                            "One ",
                                            timePeriod)),
                                    React.createElement("p", { className: "price" }, (0, format_1.toPrice)(ONES_COST[currency].original *
                                        (timePeriod == '1 year subscription' ? 2 : 1), currency)))),
                            React.createElement("div", { className: "gift-card" },
                                React.createElement("div", { className: "flex--space-between" },
                                    React.createElement("div", null,
                                        React.createElement("p", { className: "item" },
                                            "Pack of five ",
                                            timePeriod,
                                            "s"),
                                        React.createElement("span", { className: "description" }, "Buy more & save\u2013 get 1 free!")),
                                    React.createElement("p", { className: "price" }, (0, format_1.toPrice)(ONES_COST[currency].original *
                                        4 *
                                        (timePeriod == '1 year subscription' ? 2 : 1), currency)))),
                            React.createElement("div", { className: "gift-card" },
                                React.createElement("div", { className: "flex--space-between" },
                                    React.createElement("div", null,
                                        React.createElement("p", { className: "item" },
                                            "Pack of ten ",
                                            timePeriod,
                                            "s"),
                                        React.createElement("span", { className: "description" }, "Buy more & save\u2013 get 3 free!")),
                                    React.createElement("p", { className: "price" }, (0, format_1.toPrice)(ONES_COST[currency].original *
                                        7 *
                                        (timePeriod == '1 year subscription' ? 2 : 1), currency))))),
                        React.createElement("span", { className: "label-style" }, "Select quantity of gift cards"),
                        React.createElement("div", { className: "flex--align-center flex--wrap" },
                            "Purchase",
                            ' ',
                            React.createElement(semantic_ui_react_1.Input, { className: "display--inline-block", placeholder: "1", value: countStr, style: {
                                    width: '80px',
                                    marginLeft: '0.5rem',
                                    marginRight: '0.5rem',
                                }, disabled: true, error: error, onChange: (e, { value }) => {
                                    if (value.length > 3) {
                                        setError(true);
                                    }
                                    else {
                                        setError(isNaN(value) || parseInt(value) <= 0 || !value);
                                        setCountStr(value);
                                    }
                                } }),
                            ' ',
                            React.createElement("b", null, timePeriod),
                            React.createElement("span", { className: "mr-05rem ml-05rem" },
                                "gift card",
                                !error && parseInt(countStr) > 1 ? 's' : '',
                                " for a total of"),
                            React.createElement("b", null, (0, format_1.toPrice)(grandTotal, currency))),
                        React.createElement("p", { className: "label-style mt-1rem" }, "Choose payment method"),
                        React.createElement(react_stripe_js_1.Elements, { stripe: stripePromise, options: {
                                fonts: [
                                    {
                                        src: 'https://fonts.googleapis.com/css?family=Inconsolata&display=swap',
                                        family: 'Inconsolata',
                                        style: 'normal',
                                    },
                                ],
                            } },
                            React.createElement(BillingForm_1.default, { disabled: true, count: countStr, total: (0, format_1.toPrice)(grandTotal, currency), onSubmit: (paymentMethod) => __awaiter(void 0, void 0, void 0, function* () {
                                    return yield submitPayment(paymentMethod);
                                }) }),
                            React.createElement("p", { className: "small mt-05rem right-align" },
                                "Note: you will be charged in ",
                                currency.toUpperCase(),
                                ".",
                                React.createElement("br", null),
                                "To change this, adjust the dropdown under \"Gift Cards Pricing\"."))))),
            React.createElement("div", { className: "right" },
                React.createElement(semantic_ui_react_1.Card, { fluid: true },
                    React.createElement(semantic_ui_react_1.Card.Content, { header: "Purchased Gift Cards" }),
                    React.createElement(semantic_ui_react_1.Card.Content, { className: (purchased === null || purchased === void 0 ? void 0 : purchased.length) == 0 ? '' : `no-padding` },
                        (purchased === null || purchased === void 0 ? void 0 : purchased.length) == 0 && (React.createElement("p", null, "You have not purchased any gift cards yet! Once you do, they will show up here with options to send them to friends.")), purchased === null || purchased === void 0 ? void 0 :
                        purchased.map((card, index) => {
                            return (React.createElement("div", { className: "purchased", key: `referred-${index}` },
                                React.createElement("div", null,
                                    React.createElement("div", { className: "font--bold info" },
                                        React.createElement("span", null,
                                            index + 1,
                                            ". Gift card:",
                                            ' ',
                                            card.duration_in_months == 6 ? '6-month' : '1-year',
                                            ' ',
                                            "subscription gift card")),
                                    React.createElement("div", null,
                                        React.createElement("span", null,
                                            "Purchased on",
                                            ' ',
                                            Moment(card.created_at).format(_user.getMonthDayYearFormat()))),
                                    card.status !== 'redeemed' && (React.createElement(semantic_ui_react_1.Button, { className: "mt-05rem", size: "mini", onClick: () => {
                                            setTab(0);
                                            setShowCode(false);
                                            setErrorMessage(null);
                                            setSendGiftCard(card);
                                        } },
                                        "Send to ",
                                        card.status == 'unused' ? 'a' : 'another',
                                        ' ',
                                        "friend"))),
                                React.createElement("div", null,
                                    React.createElement("div", { className: `status ${card.status}` },
                                        React.createElement(semantic_ui_react_1.Icon, { name: card.status == 'redeemed' ? 'check circle' : 'clock', fitted: true }),
                                        ' ',
                                        card.status,
                                        ' ',
                                        card.status !== 'redeemed' &&
                                            card.notes &&
                                            `${(card === null || card === void 0 ? void 0 : card.status) == 'emailed' ? 'to' : 'with'} ${card.notes}`,
                                        card.status == 'redeemed' &&
                                            card.redeemed_by &&
                                            ` by ${card.redeemed_by} ${Moment(card.status_updated_at).fromNow()}`))));
                        }))),
                React.createElement(semantic_ui_react_1.Message, { success: true },
                    React.createElement(semantic_ui_react_1.Message.Header, { className: "mb-05rem" }, "Terms & Conditions"),
                    React.createElement(semantic_ui_react_1.Message.Content, null,
                        React.createElement("p", null,
                            React.createElement("li", null, "Gift cards will never expire."),
                            React.createElement("li", null, "Gift cards may only be redeemed by new users who register for a new account via the claim link."),
                            React.createElement("li", null, "A user may redeem at most one gift card\u2013 multiple gift cards cannot be stacked."),
                            React.createElement("li", null, "After the gifted subscription period ends, the user must enter their billing information to continue their subscription."),
                            React.createElement("li", null,
                                "If your gift card recipient subscribes to a plan after the gifted period ends, they will count as your referral and you will receive a 10% stackable discount per our",
                                ' ',
                                React.createElement(react_router_dom_1.Link, { className: "link clickable", to: '/refer' }, "referral program policy"),
                                "!")))))),
        React.createElement(semantic_ui_react_1.Modal, { centered: false, size: "tiny", open: !!sendGiftCard, dimmer: 'inverted' },
            React.createElement(semantic_ui_react_1.Modal.Header, null,
                "Send ",
                (sendGiftCard === null || sendGiftCard === void 0 ? void 0 : sendGiftCard.duration_in_months) == 6 ? '6 month' : '1 year',
                ' ',
                "subscription to a friend"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                (sendGiftCard === null || sendGiftCard === void 0 ? void 0 : sendGiftCard.status) == 'emailed' ||
                    (sendGiftCard === null || sendGiftCard === void 0 ? void 0 : sendGiftCard.status) == 'shared' ? (React.createElement(semantic_ui_react_1.Message, { info: true },
                    "This code has been previously ", sendGiftCard === null || sendGiftCard === void 0 ? void 0 :
                    sendGiftCard.status,
                    ' ',
                    (sendGiftCard === null || sendGiftCard === void 0 ? void 0 : sendGiftCard.status) == 'emailed' ? 'to' : 'with',
                    ' ', sendGiftCard === null || sendGiftCard === void 0 ? void 0 :
                    sendGiftCard.notes,
                    ". Whoever redeems it first will get the subscription.")) : (React.createElement(semantic_ui_react_1.Message, { info: true }, "You may send this gift card to as many people as you'd like. However, only the first person to register with the link will be able to redeem the gifted subscription.")),
                showCode ? (React.createElement(React.Fragment, null,
                    React.createElement("p", null,
                        "Great! Share this link with ",
                        name,
                        ":"),
                    React.createElement("div", { className: "api-token" },
                        React.createElement(semantic_ui_react_1.Input, { ref: tokenRef, value: `https://my.lunchmoney.app/register?code=${sendGiftCard === null || sendGiftCard === void 0 ? void 0 : sendGiftCard.code}` }),
                        document.queryCommandSupported('copy') && (React.createElement("p", { className: "copy-link clickable", onClick: () => {
                                tokenRef.current.select();
                                document.execCommand('copy');
                                setCopySuccess('Copied!');
                            } }, copySuccess))))) : (React.createElement(semantic_ui_react_1.Tab, { onTabChange: (e, data) => {
                        setErrorMessage(null);
                        setTab(data.activeIndex);
                    }, menu: { secondary: true, pointing: true }, panes: [
                        {
                            menuItem: 'Email',
                            render: () => (React.createElement("div", { className: "mt-2rem" },
                                React.createElement(semantic_ui_react_1.Header, { as: "h3" }, "Send via email with a personalized message"),
                                React.createElement(semantic_ui_react_1.Form, null,
                                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                        React.createElement(semantic_ui_react_1.Form.Field, null,
                                            React.createElement(semantic_ui_react_1.Form.Input, { placeholder: `Your friend's name`, onChange: (e, { value }) => {
                                                    setName(value);
                                                } }))),
                                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                        React.createElement(semantic_ui_react_1.Form.Field, null,
                                            React.createElement(semantic_ui_react_1.Form.Input, { placeholder: `Your friend's email`, onChange: (e, { value }) => {
                                                    setEmail(value);
                                                } }))),
                                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                        React.createElement(semantic_ui_react_1.Form.Field, null,
                                            React.createElement(semantic_ui_react_1.TextArea, { rows: 3, placeholder: `Write a message to your friend!`, onChange: (e, { value }) => {
                                                    setMessage(value);
                                                } })))))),
                        },
                        {
                            menuItem: 'Shareable link',
                            render: () => (React.createElement("div", { className: "mt-2rem" },
                                React.createElement(semantic_ui_react_1.Header, { as: "h3" }, "Send a shareable link"),
                                React.createElement("p", null, "To help keep track of your gift cards and who they were sent to, please enter the name of the person you're sharing this link with first:"),
                                React.createElement(semantic_ui_react_1.Form, null,
                                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                        React.createElement(semantic_ui_react_1.Form.Field, null,
                                            React.createElement(semantic_ui_react_1.Form.Input, { placeholder: `Your friend's name`, onChange: (e, { value }) => {
                                                    setName(value);
                                                } })))))),
                        },
                        {
                            menuItem: 'Snail Mail',
                            render: () => (React.createElement("div", { className: "mt-2rem" },
                                React.createElement(semantic_ui_react_1.Header, { as: "h3" }, "Send via snail mail"),
                                React.createElement(semantic_ui_react_1.Message, null, "If you'd like to go with this option, please contact support@lunchmoney.app. Extra charges will apply. (Pst\u2013 these cards are super cute! \u2728)"))),
                        },
                    ] })),
                errorMessage && React.createElement(semantic_ui_react_1.Message, { error: true }, errorMessage)),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                showCode ? (React.createElement(semantic_ui_react_1.Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        tokenRef.current.select();
                        document.execCommand('copy');
                        _showToast({
                            message: 'Shareable link copied to clipboard!',
                            type: 'success',
                        });
                        setSendGiftCard(null);
                        setShowCode(null);
                        // Re-retrieve
                        yield retrieve();
                    }) }, "Copy & Close")) : (React.createElement(semantic_ui_react_1.Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setSendGiftCard(null);
                        setShowCode(null);
                        // Re-retrieve
                        yield retrieve();
                    }) }, "Close")),
                tab == 0 && (React.createElement(semantic_ui_react_1.Button, { color: "green", disabled: !message || !name || !email, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        var _a;
                        setErrorMessage(null);
                        const results = yield (0, gift_cards_1.emailGiftCard)({
                            code: sendGiftCard.code,
                            name,
                            email,
                            message,
                        });
                        if (((_a = results === null || results === void 0 ? void 0 : results.data) === null || _a === void 0 ? void 0 : _a.type) == 'error') {
                            setErrorMessage(results.data.message);
                        }
                        else {
                            setSendGiftCard(null);
                            // Re-retrieve
                            yield retrieve();
                            _showToast({
                                message: results.data.message,
                                type: results.data.type,
                            });
                        }
                    }) },
                    "Send",
                    ' ',
                    (sendGiftCard === null || sendGiftCard === void 0 ? void 0 : sendGiftCard.duration_in_months) == 6 ? '6 month' : '1 year',
                    ' ',
                    "subscription ",
                    name ? `to ${name}` : '')),
                tab == 1 && !showCode && (React.createElement(semantic_ui_react_1.Button, { color: "green", disabled: !name, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        var _b;
                        setErrorMessage(null);
                        const results = yield (0, gift_cards_1.updateGiftCard)({
                            code: sendGiftCard.code,
                            notes: name,
                            status: 'shared',
                        });
                        if (((_b = results === null || results === void 0 ? void 0 : results.data) === null || _b === void 0 ? void 0 : _b.type) == 'error') {
                            setErrorMessage(results.data.message);
                        }
                        else {
                            setShowCode(true);
                        }
                    }) }, "Save & Get Link"))))));
};
exports.default = GiftCards;
