"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const qs = require("query-string");
const Moment = require("moment");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_router_dom_1 = require("react-router-dom");
const queries_1 = require("@actions/queries");
const SaveQuery = ({ open, onClose, _process, _showToast }) => {
    const history = (0, react_router_dom_1.useHistory)();
    const [queryName, setQueryName] = (0, react_1.useState)(`New Query (${Moment().format('YYYY-MM-DD')})`);
    const [queryDescription, setQueryDescription] = (0, react_1.useState)(null);
    const [saveDatasets, setSaveDatasets] = (0, react_1.useState)(true);
    const [chartBreakdown, setChartBreakdown] = (0, react_1.useState)(true);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [openModal, setOpenModal] = (0, react_1.useState)(open);
    const chartSettings = (0, react_1.useRef)({});
    (0, react_1.useEffect)(() => {
        // Reset everything
        chartSettings.current = {
            // number_type:
            //   localStorage.getItem('_lm_query_tool_number_type') || 'number',
            time_granularity: localStorage.getItem('_lm_query_tool_chart_granularity'),
            show_exclude: localStorage.getItem('_lm_query_tool_show_exclude'),
            show_ungroup: localStorage.getItem('_lm_query_tool_show_ungroup'),
            current_view: localStorage.getItem('_lm_query_tool_current_view') || 'report',
            chart_breakdown: localStorage.getItem('_lm_query_tool_chart_view'),
        };
        setQueryName(`New Query (${Moment().format('YYYY-MM-DD')})`);
        setQueryDescription(null);
        setSaveDatasets(true);
        setChartBreakdown(true);
        setIsLoading(false);
        setOpenModal(open);
    }, [open]);
    return (React.createElement(semantic_ui_react_1.Modal, { open: openModal, onClose: onClose, size: 'small', centered: true },
        React.createElement(semantic_ui_react_1.Modal.Header, null, "Save as a new query"),
        React.createElement(semantic_ui_react_1.Modal.Content, null,
            React.createElement(semantic_ui_react_1.Form, null,
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    React.createElement("label", null, "Query Name"),
                    React.createElement(semantic_ui_react_1.Form.Input, { autoFocus: true, className: "width-100", fluid: true, value: queryName || '', onChange: (e, { value }) => {
                            setQueryName(value);
                        } })),
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    React.createElement("label", null, "Query Description (optional)"),
                    React.createElement(semantic_ui_react_1.Form.Input, { className: "width-100", fluid: true, value: queryDescription || '', onChange: (e, { value }) => {
                            setQueryDescription(value);
                        } })),
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    React.createElement("label", null, "Query Settings"),
                    React.createElement("div", { className: "mb-05rem" },
                        React.createElement(semantic_ui_react_1.Checkbox, { label: "Save dataset filters", toggle: true, checked: saveDatasets, onChange: (e, { checked }) => {
                                setSaveDatasets(checked);
                                if (checked) {
                                    setChartBreakdown(true);
                                    chartSettings.current['chart_breakdown'] = localStorage.getItem('_lm_query_tool_chart_view');
                                }
                            } }),
                        ' ',
                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "Include your dataset filter selections in the saved configuration")),
                    React.createElement("div", { className: "mb-05rem" },
                        React.createElement(semantic_ui_react_1.Checkbox, { label: `Save current view (${localStorage.getItem('_lm_query_tool_current_view') || 'report'})`, toggle: true, defaultChecked: true, onChange: (e, { checked }) => {
                                if (checked) {
                                    chartSettings.current['current_view'] = localStorage.getItem('_lm_query_tool_current_view');
                                }
                                else {
                                    delete chartSettings.current['current_view'];
                                }
                            } })),
                    React.createElement("div", { className: "mb-05rem" },
                        React.createElement(semantic_ui_react_1.Checkbox, { label: `Save time granularity (${localStorage.getItem('_lm_query_tool_chart_granularity')})`, toggle: true, defaultChecked: true, onChange: (e, { checked }) => {
                                if (checked) {
                                    chartSettings.current['time_granularity'] = localStorage.getItem('_lm_query_tool_chart_granularity');
                                }
                                else {
                                    delete chartSettings.current['time_granularity'];
                                }
                            } })),
                    React.createElement("div", { className: "mb-05rem" },
                        React.createElement(semantic_ui_react_1.Checkbox, { label: `Save chart breakdown (${(localStorage.getItem('_lm_query_tool_chart_view') ||
                                'by_category').replace('_', ' ')})`, toggle: true, checked: chartBreakdown, disabled: saveDatasets, onChange: (e, { checked }) => {
                                setChartBreakdown(checked);
                                if (checked) {
                                    chartSettings.current['chart_breakdown'] = localStorage.getItem('_lm_query_tool_chart_view');
                                }
                                else {
                                    delete chartSettings.current['chart_breakdown'];
                                }
                            } })),
                    React.createElement("div", { className: "mb-05rem" },
                        React.createElement(semantic_ui_react_1.Checkbox, { label: `Save 'Exclude from Totals' transactions (${localStorage.getItem('_lm_query_tool_show_exclude') == 'true'
                                ? 'Include'
                                : 'Exclude'})`, toggle: true, defaultChecked: true, onChange: (e, { checked }) => {
                                if (checked) {
                                    chartSettings.current['show_exclude'] = localStorage.getItem('_lm_query_tool_show_exclude');
                                }
                                else {
                                    delete chartSettings.current['show_exclude'];
                                }
                            } })),
                    React.createElement("div", { className: "mb-05rem" },
                        React.createElement(semantic_ui_react_1.Checkbox, { label: `Save ungrouping transactions  (${localStorage.getItem('_lm_query_tool_show_ungroup') == 'true'
                                ? 'Include'
                                : 'Exclude'})`, toggle: true, defaultChecked: true, onChange: (e, { checked }) => {
                                if (checked) {
                                    chartSettings.current['show_ungroup'] = localStorage.getItem('_lm_query_tool_show_ungroup');
                                }
                                else {
                                    delete chartSettings.current['show_ungroup'];
                                }
                            } }))))),
        React.createElement(semantic_ui_react_1.Modal.Actions, null,
            React.createElement(semantic_ui_react_1.Button, { basic: true, color: "grey", icon: "x", labelPosition: "right", content: "Cancel", onClick: onClose }),
            React.createElement(semantic_ui_react_1.Button, { labelPosition: "right", icon: "checkmark", loading: isLoading, disabled: !queryName || isLoading, content: "Save", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setIsLoading(true);
                    const opts = qs.parse(history.location.search);
                    const datasets = opts['opts'].split(',');
                    const finalDatasets = datasets.map((data, index) => {
                        const set = qs.parse(data);
                        if (!saveDatasets) {
                            // Remove datasets from url
                            delete set.hidden;
                        }
                        return qs.stringify(Object.assign(Object.assign({}, set), chartSettings.current));
                    });
                    const finalQuery = qs.stringify({ opts: finalDatasets }, { arrayFormat: 'comma' });
                    const results = yield _process(queries_1.createQuery)({
                        name: queryName,
                        description: queryDescription,
                        query: finalQuery,
                    });
                    if (!results.error) {
                        // Show success message
                        _showToast({
                            message: 'Successfully saved query',
                            type: 'success',
                        });
                    }
                    setIsLoading(false);
                    onClose();
                }) }))));
};
exports.default = SaveQuery;
