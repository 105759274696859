"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateFeedback = exports.createFeedback = void 0;
const common_1 = require("./common");
const createFeedback = opts => {
    return (0, common_1.post)(`feedback`, opts);
};
exports.createFeedback = createFeedback;
const updateFeedback = opts => {
    return (0, common_1.put)(`feedback`, opts);
};
exports.updateFeedback = updateFeedback;
