"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_router_dom_1 = require("react-router-dom");
const rules_1 = require("@actions/rules");
const Moment = require("moment");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const EditablePrice_1 = require("@components/elements/EditablePrice");
const UserProvider_1 = require("@providers/UserProvider");
const ColumnResizer_1 = require("@components/elements/ColumnResizer");
const TransactionTag_1 = require("../elements/TransactionTag");
const RecurringProvider_1 = require("@/providers/RecurringProvider");
const DryRunRules = ({ deleteRules = null, updateRule = null, show, criteriaIds, transactionIds = [], _showToast, _process, amendTransactions = any => { }, closeModal, }) => {
    const history = (0, react_router_dom_1.useHistory)();
    const [rows, setRows] = (0, react_1.useState)(null);
    const [transactions, setTransactions] = (0, react_1.useState)(null);
    const [excludeIds, setExcludeIds] = (0, react_1.useState)([]);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [isLoadingTransactions, setIsLoadingTransactions] = (0, react_1.useState)(true);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _recurring = (0, react_1.useContext)(RecurringProvider_1.RecurringContext);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const _tx_loaded = (0, react_1.useRef)(false);
    // Limits and skips
    const [limit, setLimit] = (0, react_1.useState)(localStorage.getItem('_lm_transactions_limit')
        ? parseInt(localStorage.getItem('_lm_transactions_limit'))
        : 50);
    const [skip, setSkip] = (0, react_1.useState)(0);
    const LIMIT_OPTIONS = [25, 50, 100];
    const LIMIT = 50;
    const submit = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const results = yield _process(rules_1.bulkApply)({
            criteria_ids: criteriaIds,
            dry_run: false,
            exclude_transaction_ids: excludeIds,
            include_transaction_ids: transactionIds,
        });
        // If on the Rules page
        if (!!updateRule) {
            updateRule(criteriaIds, {
                criteria_last_triggered_at: new Date().toISOString(),
            });
        }
        // If on the Transactions page
        if (!!amendTransactions && criteriaIds.length == 1) {
            let rule = {};
            const results = yield _process(rules_1.getRule)(criteriaIds[0]);
            if (!results.error) {
                rule = results;
            }
            const updateObj = {};
            // This only really happens here for category and payee..
            if (rule['category_id']) {
                updateObj['category_id'] = rule['category_id'];
                updateObj['category_name'] = rule['category_name'];
            }
            if (rule['payee_name']) {
                updateObj['payee'] = rule['payee_name'];
                updateObj['display_name'] = rule['payee_name'];
            }
            if (rule['notes']) {
                updateObj['notes'] = rule['notes'];
                updateObj['display_notes'] = rule['notes'];
            }
            if (rule['tags']) {
                updateObj['tags'] = rule['tags'];
            }
            // Not possible?
            if (rule['recurring_id']) {
                updateObj['recurring_id'] = rule['recurring_id'];
                updateObj['recurring_type'] = rule['recurring_type'];
                updateObj['status'] = 'cleared';
                updateObj['display_name'] = rule['recurring_payee'];
                updateObj['display_notes'] = rule['recurring_description'];
                updateObj['category_id'] = rule['recurring_category_id'];
                updateObj['category_name'] = _categories.getName(rule['recurring_category_id']);
            }
            if (rule['mark_as_reviewed']) {
                updateObj['status'] = 'cleared';
            }
            if (rule['mark_as_unreviewed']) {
                updateObj['status'] = 'uncleared';
            }
            if (rule['set_uncategorized']) {
                updateObj['category_id'] = null;
                updateObj['category_name'] = null;
            }
            amendTransactions({
                bulkUpdate: [
                    {
                        ids: transactions
                            .map(o => o.original.id)
                            .filter(o => {
                            return excludeIds.indexOf(o) == -1;
                        }),
                        update: updateObj,
                    },
                ],
            });
        }
        if (!results.error) {
            _showToast({ message: 'Successfully applied rule', type: 'success' });
        }
        setIsLoading(false);
        closeModal();
    });
    (0, react_1.useEffect)(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            const results = yield _process(rules_1.bulkApply)({
                criteria_ids: criteriaIds,
                dry_run: true,
                include_transaction_ids: transactionIds,
            });
            _tx_loaded.current = true;
            setTransactions(results.data);
        });
        if (show) {
            //} && criteriaIds?.length > 0) {
            // reset
            setTransactions(null);
            setIsLoadingTransactions(true);
            setSkip(0);
            setLimit(50);
            setExcludeIds([]);
            _tx_loaded.current = false;
            // fetch new
            fetchData();
        }
    }, [show, criteriaIds]);
    (0, react_1.useEffect)(() => {
        // null transactions means waiting for fetch
        if (!show || !_tx_loaded.current)
            return;
        if (!transactions || (transactions === null || transactions === void 0 ? void 0 : transactions.length) == 0) {
            setIsLoadingTransactions(false);
            _showToast({
                message: 'No updates for matching transactions found. This may be because there are no matched transactions, or all matched transactions already have the rule effect. Non-update effects, such as sending an email, are not applied.',
                type: 'info',
            });
            closeModal();
            return;
        }
        const ui = [];
        transactions.forEach(_tx => {
            const rule = _tx.override;
            const tx = _tx.original;
            if (
            // If split exists
            rule.split &&
                rule.split.length > 0 &&
                // and we're not excluding this transaction
                excludeIds.indexOf(tx.id) == -1) {
                rule.split.forEach((split, index) => {
                    ui.push(Row({
                        original: tx,
                        override: Object.assign({ is_split: true, key: index }, split),
                    }));
                });
            }
            else {
                ui.push(Row({ original: tx, override: rule }));
            }
        });
        setIsLoadingTransactions(false);
        if (ui.length > 0) {
            setRows(ui);
        }
        else {
            if (history.location.pathname.indexOf('rules') > -1) {
                _showToast({
                    message: 'No updates for matching transactions found. This may be because there are no matched transactions, or all matched transactions already have the rule effect. Non-update effects, such as sending an email, are not applied.',
                    type: 'info',
                });
            }
            closeModal();
        }
    }, [show, excludeIds, transactions]);
    const Row = ({ original, override }) => {
        var _a;
        // Is it a recurring? If so, let's override everything.
        if (override.hasOwnProperty('recurring_id') && !!override.recurring_id) {
            try {
                const recurring = _recurring.mapById[override.recurring_id][0];
                // Overwrite category, payee, notes
                override.category_id = recurring.category_id;
                override.payee = recurring.payee;
                override.notes = recurring.description;
            }
            catch (e) {
                console.log(`Recurring ${override.recurring_id} not found in mapping`, _recurring.mapById);
            }
        }
        return (React.createElement(semantic_ui_react_1.Table.Row, { key: `row-${original.id}-${override.key || ''}`, className: `${original.recurring_id && original.recurring_type == 'cleared'
                ? 'recurring'
                : ''}` },
            React.createElement(semantic_ui_react_1.Table.Cell, null,
                React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: excludeIds.indexOf(original.id) === -1, onChange: (e, { checked }) => {
                        if (checked) {
                            // Remove from excludeIds
                            setExcludeIds(excludeIds.filter(o => {
                                return o !== original.id;
                            }));
                        }
                        else {
                            // Add to excludeIds
                            setExcludeIds(excludeIds.concat([original.id]));
                        }
                    } })),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, null,
                React.createElement("span", null, Moment(original.date).format(_user.getMonthDayYearFormat()))),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, null, excludeIds.indexOf(original.id) === -1 &&
                override.hasOwnProperty('category_id') &&
                override.category_id !== original.category_id ? (React.createElement(React.Fragment, null,
                React.createElement("span", { className: `display--flex ${!!original.recurring_id &&
                        (!override.hasOwnProperty('recurring_id') ||
                            override.recurring_id !== null)
                        ? ''
                        : `strikethrough`}` }, _categories.getName(original.category_id || -1)),
                ' ',
                React.createElement("span", { className: "display--flex color--green" },
                    _categories.getName(override.category_id || -1),
                    ' ',
                    !!original.recurring_id &&
                        (!override.hasOwnProperty('recurring_id') ||
                            override.recurring_id !== null) && (React.createElement(semantic_ui_react_1.Popup, { hoverable: true, trigger: React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", name: "info circle" }) }, "This transaction is a recurring item and will inherit the recurring item's category as long as it's linked. If you unlink the transaction from the recurring item, its category will be the one displayed here."))))) : (React.createElement("span", null, _categories.getName(original.category_id || -1)))),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, null, excludeIds.indexOf(original.id) === -1 &&
                override.payee &&
                override.payee !== original.payee ? (React.createElement(React.Fragment, null,
                React.createElement("span", { className: `display--flex ${!!original.recurring_id &&
                        (!override.hasOwnProperty('recurring_id') ||
                            override.recurring_id !== null)
                        ? ''
                        : `strikethrough`}` }, original.payee),
                React.createElement("span", { className: "display--flex color--green" },
                    override.payee,
                    ' ',
                    !!original.recurring_id &&
                        (!override.hasOwnProperty('recurring_id') ||
                            override.recurring_id !== null) && (React.createElement(semantic_ui_react_1.Popup, { hoverable: true, trigger: React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", name: "info circle" }) }, "This transaction is a recurring item and will inherit the recurring item's payee name as long as it's linked. If you unlink the transaction from the recurring item, its payee name will be the one displayed here."))))) : (React.createElement("span", null, original.payee))),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-padding right-align" }, override.is_split ? (React.createElement(EditablePrice_1.default, { identifier: `price-${original.id}`, amount: override.amount, currency: original.currency, state: 'ReadOnly', isSplit: true, isGrouped: false, isPartOfGroup: false, isRecurring: false, onSave: null, 
                // showSignLabel={true}
                location: 'inline-edit' })) : (React.createElement(EditablePrice_1.default, { identifier: `price-${original.id}`, amount: original.amount, currency: original.currency, state: 'ReadOnly', isSplit: !!original.parent_id, isGrouped: original.is_group, isPartOfGroup: !!original.group_id, isRecurring: !!original.recurring_id, onSave: null, location: null }))),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, null, excludeIds.indexOf(original.id) === -1 &&
                override.hasOwnProperty('notes') &&
                override.notes !== original.notes ? (React.createElement(React.Fragment, null,
                React.createElement("span", { className: `display--flex ${!!original.recurring_id &&
                        (!override.hasOwnProperty('recurring_id') ||
                            override.recurring_id !== null)
                        ? ''
                        : `strikethrough`}` }, original.notes),
                React.createElement("span", { className: "display--flex color--green" },
                    override.notes,
                    ' ',
                    !!original.recurring_id &&
                        (!override.hasOwnProperty('recurring_id') ||
                            override.recurring_id !== null) && (React.createElement(semantic_ui_react_1.Popup, { hoverable: true, trigger: React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", name: "info circle" }) }, "This transaction is a recurring item and will inherit the recurring item's notes as long as it's linked. If you unlink the transaction from the recurring item, its notes will be the one displayed here."))))) : (React.createElement("span", null, original.notes))),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, null, excludeIds.indexOf(original.id) === -1 &&
                ((_a = override.tags) === null || _a === void 0 ? void 0 : _a.map(tag_id => {
                    return (React.createElement(TransactionTag_1.default, { readOnly: true, key: `${tag_id}-tag`, transactionId: null, tag: tag_id }));
                })))));
    };
    return (React.createElement(semantic_ui_react_1.Modal, { className: "apply-rules-modal", open: !!show, closeOnDimmerClick: false, onClose: () => {
            // Reset everything
            setRows(null);
            setTransactions(null);
            setExcludeIds([]);
            _tx_loaded.current = false;
            closeModal();
        }, dimmer: 'inverted', size: "large" },
        React.createElement(semantic_ui_react_1.Header, { content: `Apply ${criteriaIds.length || ''} Transaction Rule${criteriaIds.length > 1 ? 's' : ''}` }),
        isLoadingTransactions ? (React.createElement(semantic_ui_react_1.Modal.Content, null,
            React.createElement("div", { className: "mt-0rem mb-0rem" },
                React.createElement(semantic_ui_react_1.Loader, { inline: "centered", size: "small" },
                    React.createElement("p", { className: "monospace" }, "Finding transactions..."))))) : (React.createElement(semantic_ui_react_1.Modal.Content, { scrolling: true }, rows && rows.length > 0 ? (React.createElement(React.Fragment, null,
            criteriaIds.length > 0 && (React.createElement("p", null,
                "The following transactions will be updated by",
                ' ',
                criteriaIds.length > 1 ? 'the selected rules' : 'this rule',
                ". Untoggle a transaction to skip updating it. You may view and manage all your rules from the",
                ' ',
                React.createElement("b", null,
                    React.createElement(react_router_dom_1.Link, { to: { pathname: '/rules' } }, "Rules")),
                ' ',
                "page.")),
            rows.length > 400 && (React.createElement(semantic_ui_react_1.Message, { info: true },
                "We limit the number of transactions that can be updated here. There may be more transactions that can be updated. You may re-apply",
                ' ',
                criteriaIds.length > 1 ? 'the selected rules' : 'this rule',
                ' ',
                "multiple times for full coverage.")),
            React.createElement("div", null,
                excludeIds && excludeIds.length > 0 && (React.createElement(semantic_ui_react_1.Button, { basic: true, color: "orange", size: "small", onClick: () => {
                        setExcludeIds([]);
                    } }, "Select all")),
                excludeIds && rows.length - excludeIds.length > 0 && (React.createElement(semantic_ui_react_1.Button, { basic: true, color: "orange", size: "small", onClick: () => {
                        const exclude = [];
                        for (let i = 0; i < transactions.length; i++) {
                            const _tx = transactions[i];
                            if (_tx.override.split) {
                                for (let i = 0; i < _tx.override.split.length; i++) {
                                    exclude.push(_tx.original.id);
                                }
                            }
                            else {
                                exclude.push(_tx.original.id);
                            }
                        }
                        setExcludeIds(exclude);
                    } }, "Deselect all"))),
            React.createElement(semantic_ui_react_1.Table, { fixed: true, unstackable: true, selectable: true, celled: true, className: "apply-rules-table" },
                React.createElement(semantic_ui_react_1.Table.Header, { className: "sticky" },
                    React.createElement(semantic_ui_react_1.Table.Row, null,
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "cell-toggle" }),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "cell-date" },
                            React.createElement("div", null, "Date")),
                        React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'apply-rules', prevCol: 'date', nextCol: 'category' }),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "cell-category" },
                            React.createElement("div", null, "Category")),
                        React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'apply-rules', prevCol: 'category', nextCol: 'payee' }),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "cell-payee" },
                            React.createElement("div", null, "Payee")),
                        React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'apply-rules', prevCol: 'payee', nextCol: 'amount' }),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "cell-amount right-align" },
                            React.createElement("div", null, "Amount")),
                        React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'apply-rules', prevCol: 'amount', nextCol: 'notes' }),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "cell-notes" },
                            React.createElement("div", null, "Notes")),
                        React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'apply-rules', prevCol: 'notes', nextCol: 'tags' }),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "cell-tags" },
                            React.createElement("div", null, "New Tags")))),
                React.createElement(semantic_ui_react_1.Table.Body, null, rows.slice(skip, skip + limit))),
            !isLoadingTransactions && rows.length > LIMIT && (React.createElement("div", { className: "pagination-container flex--space-between" },
                React.createElement("div", { className: "pagination-amount" }, LIMIT_OPTIONS.map(option => {
                    return (React.createElement("a", { key: `limit-option-${option}`, onClick: () => {
                            setLimit(option);
                            localStorage.setItem('_lm_transactions_limit', option.toString());
                            setSkip(0);
                        }, className: `choice ${limit == option ? 'active' : ''}` }, option));
                })),
                Math.ceil(rows.length / limit) > 1 && (React.createElement(semantic_ui_react_1.Pagination, { activePage: Math.floor(skip / limit) + 1, onPageChange: (e, { activePage }) => {
                        setSkip((activePage - 1) * limit);
                    }, totalPages: Math.ceil(rows.length / limit) })))))) : (React.createElement("p", null, "Applying this rule will not update any transactions, either because transactions matching the criteria already have the properties of the rule, or no transactions match the criteria.")))),
        React.createElement(semantic_ui_react_1.Modal.Actions, null,
            React.createElement(semantic_ui_react_1.Button, { color: "grey", loading: isLoading, onClick: () => {
                    closeModal();
                } }, (rows === null || rows === void 0 ? void 0 : rows.length) == (excludeIds === null || excludeIds === void 0 ? void 0 : excludeIds.length) ? 'Close' : 'Cancel'),
            !isLoadingTransactions &&
                rows &&
                rows.length > 0 &&
                rows.length - excludeIds.length > 0 && (React.createElement(React.Fragment, null,
                (criteriaIds === null || criteriaIds === void 0 ? void 0 : criteriaIds.length) == 1 && !!deleteRules && (React.createElement(semantic_ui_react_1.Button, { color: "red", loading: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setIsLoading(true);
                        yield deleteRules(criteriaIds);
                        setIsLoading(false);
                        closeModal();
                    }) },
                    React.createElement(semantic_ui_react_1.Icon, { name: "trash" }),
                    'Delete rule')),
                React.createElement(semantic_ui_react_1.Button, { color: "green", loading: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield submit();
                    }) },
                    React.createElement(semantic_ui_react_1.Icon, { name: "checkmark" }),
                    " Apply to",
                    ' ',
                    rows.length - excludeIds.length,
                    " transactions"))))));
};
exports.default = DryRunRules;
