"use strict";
/**
 *  SplitElement.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
// Components
const format_1 = require("@helpers/format");
const EditableString_1 = require("@components/elements/EditableString");
const EditableTags_1 = require("@components/elements/EditableTags");
const EditableCategory_1 = require("@components/elements/EditableCategory");
const SplitElement = ({ hasAmount, index, split, setSplit, originalAmount, currency, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    const [showPayee, setShowPayee] = (0, react_1.useState)(!!((_a = split[index]) === null || _a === void 0 ? void 0 : _a.payee));
    const [showCategory, setShowCategory] = (0, react_1.useState)(!!((_b = split[index]) === null || _b === void 0 ? void 0 : _b.category_id));
    const [showTags, setShowTags] = (0, react_1.useState)(!!((_d = (_c = split[index]) === null || _c === void 0 ? void 0 : _c.tags) === null || _d === void 0 ? void 0 : _d.length));
    const [showNotes, setShowNotes] = (0, react_1.useState)(!!((_e = split[index]) === null || _e === void 0 ? void 0 : _e.notes));
    (0, react_1.useEffect)(() => {
        const updatedSplit = split.map((item, j) => {
            if (j === index) {
                return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, item), (!showCategory ? { category_id: null } : {})), (!showTags ? { tags: null } : {})), (!showNotes ? { notes: null } : {})), (!showPayee ? { payee: null } : {}));
            }
            else {
                return item;
            }
        });
        setSplit(updatedSplit);
    }, [showCategory, showTags, showNotes, showPayee]);
    (0, react_1.useEffect)(() => {
        // If hasAmount, then make sure amount_pct and amount are populated
        if (hasAmount) {
            // Map out the correct amount
            const updatedSplit = split.map(o => {
                if (o.amount_pct && !o.amount) {
                    return Object.assign(Object.assign({}, o), { amount: (originalAmount * (o.amount_pct / 100)).toFixed(2) });
                }
                return o;
            });
            // Does it all add up though?
            const total = updatedSplit.reduce((acc, cur) => {
                return parseFloat(cur.amount) + acc;
            }, 0);
            if (total !== originalAmount) {
                updatedSplit[updatedSplit.length - 1].amount -= total - originalAmount;
            }
            setSplit(updatedSplit);
        }
        else {
            // If no hasAmount, then make sure only amount_pct is populated
            setSplit(split.map(o => {
                return Object.assign(Object.assign({}, o), { amount: null });
            }));
        }
    }, []);
    return (React.createElement("div", { className: "transaction-details" },
        React.createElement("h3", { className: "flex--space-between" },
            "Split ",
            index + 1,
            React.createElement(semantic_ui_react_1.Icon, { name: "close", color: "red", className: "clickable", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    // Remove index
                    const list = split.filter((item, j) => {
                        return j !== index;
                    });
                    setSplit(list);
                }) })),
        React.createElement("div", { className: "transaction-detail" },
            React.createElement("div", { className: "flex--space-between" },
                React.createElement("label", null, "Amount"),
                React.createElement("label", null, "Percentage")),
            React.createElement(semantic_ui_react_1.Form, null,
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "margin-0 flex--space-between" },
                    React.createElement(EditableString_1.default, { identifier: `split-amount-${index}`, placeholder: hasAmount ? (0, format_1.toPrice)(0, currency) : 'N/A', firstValue: hasAmount && split[index].amount ? split[index].amount : null, location: 'modal', disabled: !hasAmount, state: 'Editing', shouldSaveOnChange: true, showError: hasAmount &&
                            ((!split[index].amount && !split[index].amount_pct) ||
                                ((_h = (_g = (_f = split[index]) === null || _f === void 0 ? void 0 : _f.amount) === null || _g === void 0 ? void 0 : _g.toString()) === null || _h === void 0 ? void 0 : _h.indexOf('-')) > -1), onSave: (amount) => __awaiter(void 0, void 0, void 0, function* () {
                            if (isNaN(amount))
                                return;
                            // Update the appropriate item in the split array
                            const updatedSplit = split.map((item, j) => {
                                if (j === index) {
                                    return Object.assign(Object.assign({}, item), { amount: amount, amount_pct: ((amount / originalAmount) * 100).toFixed(2) });
                                }
                                else {
                                    return item;
                                }
                            });
                            setSplit(updatedSplit);
                        }) }),
                    React.createElement("span", { className: "ml-05rem mr-05rem" }, "or"),
                    React.createElement(EditableString_1.default, { percentage: true, identifier: `split-percentage-${index}`, placeholder: null, firstValue: hasAmount && split[index].amount
                            ? (_k = (_j = ((split[index].amount / originalAmount) * 100)) === null || _j === void 0 ? void 0 : _j.toFixed(0)) === null || _k === void 0 ? void 0 : _k.toString()
                            : split[index].amount_pct, location: 'modal', state: 'Editing', shouldSaveOnChange: true, showError: (!hasAmount && !((_l = split[index]) === null || _l === void 0 ? void 0 : _l.amount_pct)) ||
                            ((_p = (_o = (_m = split[index]) === null || _m === void 0 ? void 0 : _m.amount) === null || _o === void 0 ? void 0 : _o.toString()) === null || _p === void 0 ? void 0 : _p.indexOf('-')) > -1, onSave: (percentage) => __awaiter(void 0, void 0, void 0, function* () {
                            if (isNaN(percentage))
                                return;
                            // Update the appropriate item in the split array
                            const updatedSplit = split.map((item, j) => {
                                if (j === index) {
                                    return Object.assign(Object.assign({}, item), { amount: hasAmount
                                            ? (originalAmount * (percentage / 100)).toFixed(2)
                                            : null, amount_pct: percentage });
                                }
                                else {
                                    return item;
                                }
                            });
                            setSplit(updatedSplit);
                        }) })))),
        React.createElement("div", { className: "transaction-detail" }, showPayee && (React.createElement(React.Fragment, null,
            React.createElement("label", null, "Payee"),
            React.createElement(EditableString_1.default, { identifier: `split-payee-${index}`, placeholder: "Leave blank to inherit from parent", firstValue: split[index].payee, location: 'modal', state: 'Editing', autosuggest: true, shouldSaveOnChange: true, onSave: (payee) => __awaiter(void 0, void 0, void 0, function* () {
                    // Update the appropriate item in the split array
                    const updatedSplit = split.map((item, j) => {
                        if (j === index) {
                            return Object.assign(Object.assign({}, item), { payee });
                        }
                        else {
                            return item;
                        }
                    });
                    setSplit(updatedSplit);
                }) })))),
        React.createElement("div", { className: "transaction-detail" }, showCategory && (React.createElement(React.Fragment, null,
            React.createElement("label", null, "Category"),
            React.createElement(EditableCategory_1.default, { identifier: `split-category-${index}`, firstValue: split[index].category_id, state: 'Editing', location: 'modal', onSave: category_id => {
                    // Update the appropriate item in the split array
                    const updatedSplit = split.map((item, j) => {
                        if (j === index) {
                            return Object.assign(Object.assign({}, item), { category_id });
                        }
                        else {
                            return item;
                        }
                    });
                    setSplit(updatedSplit);
                } })))),
        React.createElement("div", { className: "transaction-detail" }, showNotes && (React.createElement(React.Fragment, null,
            React.createElement("label", null, "Notes"),
            React.createElement(EditableString_1.default, { identifier: `split-notes-${index}`, placeholder: "Notes", firstValue: split[index].notes, location: 'modal', state: 'Editing', shouldSaveOnChange: true, onSave: (notes) => __awaiter(void 0, void 0, void 0, function* () {
                    // Update the appropriate item in the split array
                    const updatedSplit = split.map((item, j) => {
                        if (j === index) {
                            return Object.assign(Object.assign({}, item), { notes });
                        }
                        else {
                            return item;
                        }
                    });
                    setSplit(updatedSplit);
                }) })))),
        React.createElement("div", { className: "transaction-detail" }, showTags && (React.createElement(React.Fragment, null,
            React.createElement("label", null, "Tags"),
            React.createElement(EditableTags_1.default, { allowAdd: false, showUntagged: false, placeholder: "Search tags", initialValue: ((_q = split[index]) === null || _q === void 0 ? void 0 : _q.tags) || [], onChange: tags => {
                    // Update the appropriate item in the split array
                    const updatedSplit = split.map((item, j) => {
                        if (j === index) {
                            return Object.assign(Object.assign({}, item), { tags });
                        }
                        else {
                            return item;
                        }
                    });
                    setSplit(updatedSplit);
                } })))),
        React.createElement("div", { className: "flex--center mb-1rem" },
            React.createElement("span", { className: "label-style mr-05rem" }, "Update: "),
            React.createElement(semantic_ui_react_1.Button, { size: "mini", basic: true, color: showCategory ? 'orange' : 'grey', onClick: () => {
                    setShowCategory(!showCategory);
                } }, "Category"),
            React.createElement(semantic_ui_react_1.Button, { size: "mini", basic: true, color: showPayee ? 'orange' : 'grey', onClick: () => {
                    setShowPayee(!showPayee);
                } }, "Payee"),
            React.createElement(semantic_ui_react_1.Button, { size: "mini", basic: true, color: showNotes ? 'orange' : 'grey', onClick: () => {
                    setShowNotes(!showNotes);
                } }, "Notes"),
            React.createElement(semantic_ui_react_1.Button, { size: "mini", basic: true, color: showTags ? 'orange' : 'grey', onClick: () => {
                    setShowTags(!showTags);
                } }, "Tags")),
        React.createElement("div", { className: "display--flex" },
            React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, label: "Mark as reviewed", className: "mb-05rem mr-05rem", checked: (_r = split[index]) === null || _r === void 0 ? void 0 : _r.mark_as_reviewed, onChange: (e, { checked }) => {
                    // Update the appropriate item in the split array
                    const updatedSplit = split.map((item, j) => {
                        if (j === index) {
                            return Object.assign(Object.assign({}, item), { mark_as_reviewed: checked });
                        }
                        else {
                            return item;
                        }
                    });
                    setSplit(updatedSplit);
                } }),
            React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", name: "question circle" }) }, "Enable to automatically set newly split transaction to reviewed.")),
        React.createElement("div", { className: "display--flex" },
            React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, label: "Run split transaction through rules", className: "mb-05rem mr-05rem", checked: (_s = split[index]) === null || _s === void 0 ? void 0 : _s.should_run_rules, onChange: (e, { checked }) => {
                    // Update the appropriate item in the split array
                    const updatedSplit = split.map((item, j) => {
                        if (j === index) {
                            return Object.assign(Object.assign({}, item), { should_run_rules: checked });
                        }
                        else {
                            return item;
                        }
                    });
                    setSplit(updatedSplit);
                } }),
            React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", name: "question circle" }) }, "Split transactions cannot be further split."))));
};
exports.default = SplitElement;
