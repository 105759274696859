"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const billing_1 = require("@/actions/billing");
const format_1 = require("@helpers/format");
const BillingProvider_1 = require("@providers/BillingProvider");
const UserProvider_1 = require("@providers/UserProvider");
const Moment = require("moment");
const SubscriptionDetails = ({ _showToast }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const _billing = (0, react_1.useContext)(BillingProvider_1.BillingContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const subscription = _billing.subscription;
    const [isSavingChanges, setIsSavingChanges] = (0, react_1.useState)(false);
    const [portalLink, setPortalLink] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        getLink();
    }, []);
    const getLink = () => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield (0, billing_1.getPortalLink)();
        if (result) {
            setPortalLink(result);
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(semantic_ui_react_1.Card, { fluid: true },
            React.createElement(semantic_ui_react_1.Card.Content, { header: "Subscription Details" }),
            React.createElement(semantic_ui_react_1.Card.Content, null,
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Subscription status"),
                    React.createElement("span", null,
                        ['past_due', 'unpaid', 'incomplete', 'no subscription'].indexOf(_billing.user.stripe_status) > -1 && React.createElement(semantic_ui_react_1.Icon, { name: "exclamation triangle", color: "red" }),
                        _billing.isPaused && (React.createElement(semantic_ui_react_1.Icon, { name: "exclamation triangle", color: "yellow" })),
                        _billing.user.stripe_status == 'no subscription' ? ('There is currently no subscription associated with this account.') : (React.createElement(React.Fragment, null,
                            _user.isAdmin
                                ? 'Your subscription'
                                : 'The subscription for this budget',
                            ' ',
                            _billing.isPaused ? (React.createElement(React.Fragment, null,
                                "has been ",
                                React.createElement("b", null, "paused"),
                                " since",
                                ' ',
                                Moment(_billing.user.paused_at).format(_user.getMonthDayYearFormat()),
                                ". To unpause, select a new subscription plan.")) : (React.createElement(React.Fragment, null,
                                "is currently",
                                ' ',
                                React.createElement("b", null, _billing.user.stripe_status.replace('_', ' ')),
                                _billing.user.stripe_status == 'trialing'
                                    ? ` until ${_billing.trialEnd}`
                                    : '',
                                ".",
                                ' ',
                                _billing.user.stripe_status == 'past_due'
                                    ? `Please update your billing information to regain access to account data!`
                                    : '')))),
                        ' ',
                        "Your billing currency is in",
                        ' ', (_b = (_a = _billing.user) === null || _a === void 0 ? void 0 : _a.stripe_plan_currency) === null || _b === void 0 ? void 0 :
                        _b.toUpperCase(),
                        ".")),
                _user.isAdmin && (React.createElement(React.Fragment, null,
                    ((_c = subscription === null || subscription === void 0 ? void 0 : subscription.balance) === null || _c === void 0 ? void 0 : _c.amount) &&
                        ((_d = subscription === null || subscription === void 0 ? void 0 : subscription.balance) === null || _d === void 0 ? void 0 : _d.currency) ? (React.createElement("div", { className: "transaction-detail" },
                        React.createElement("label", null,
                            "Credit balance",
                            ' ',
                            React.createElement(semantic_ui_react_1.Popup, { size: "small", hoverable: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "Your balance will automatically deduct from your next invoice and is already reflected in the amount under 'Amount due'.")),
                        React.createElement("span", null, (0, format_1.toPrice)(subscription.balance.amount * -1, subscription.balance.currency)))) : (React.createElement(React.Fragment, null)),
                    !_billing.isPaused && (subscription === null || subscription === void 0 ? void 0 : subscription.invoice) && (React.createElement(React.Fragment, null,
                        ((_e = subscription === null || subscription === void 0 ? void 0 : subscription.invoice) === null || _e === void 0 ? void 0 : _e.next_payment_attempt) && (React.createElement("div", { className: "transaction-detail" },
                            React.createElement("label", null,
                                "Next",
                                ' ',
                                _billing.user.stripe_status == 'past_due'
                                    ? 'attempted'
                                    : '',
                                ' ',
                                "payment date"),
                            React.createElement("span", null, Moment((_f = subscription === null || subscription === void 0 ? void 0 : subscription.invoice) === null || _f === void 0 ? void 0 : _f.next_payment_attempt, 'X').format(_user.getMonthDayYearFormat())))),
                        React.createElement("div", { className: "transaction-detail" },
                            React.createElement("label", null, "Amount due"),
                            React.createElement("span", null, (0, format_1.toPrice)(((_g = subscription === null || subscription === void 0 ? void 0 : subscription.invoice) === null || _g === void 0 ? void 0 : _g.amount_due) / 100, (_h = subscription === null || subscription === void 0 ? void 0 : subscription.invoice) === null || _h === void 0 ? void 0 : _h.currency))))),
                    !(subscription === null || subscription === void 0 ? void 0 : subscription.payment) && (React.createElement(semantic_ui_react_1.Message, { error: true }, _billing.user.stripe_status == 'incomplete' ||
                        _billing.user.stripe_status == 'unpaid'
                        ? 'Your subscription is not active until you enter your payment information.'
                        : 'You do not currently have a card on file. Your subscription may get canceled if we cannot charge you at the time of renewal.')),
                    !!(subscription === null || subscription === void 0 ? void 0 : subscription.payment) &&
                        _billing.user.stripe_status == 'trialing' && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Button, { className: "mt-05rem mb-05rem", fluid: true, loading: isSavingChanges, color: "green", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                setIsSavingChanges(true);
                                yield (0, billing_1.endTrial)();
                                _billing.checkBilling();
                            }) }, "End trial & Pay now") }, "Click to end your trial today and make your first payment. You will get to access the members-only channels in our community Discord and the beta testing program.")),
                    React.createElement(semantic_ui_react_1.Button, { loading: isSavingChanges || !portalLink, disabled: isSavingChanges || !portalLink, icon: true, fluid: true, labelPosition: "right", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            window.open(portalLink, '_blank');
                        }) },
                        "View and Manage Payment Details",
                        React.createElement(semantic_ui_react_1.Icon, { name: "external alternate" }))))))));
};
exports.default = SubscriptionDetails;
