"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTickColors = exports.getGridLineColors = void 0;
const getGridLineColors = () => {
    return {
        zeroLineColor: document.body.classList.contains('dark')
            ? 'rgba(255,255,255, 0.1)'
            : 'rgba(0, 0, 0, 0.1)',
        color: document.body.classList.contains('dark')
            ? 'rgba(255,255,255, 0.1)'
            : 'rgba(0, 0, 0, 0.1)',
    };
};
exports.getGridLineColors = getGridLineColors;
const getTickColors = () => {
    return {
        fontSize: 13,
        fontFamily: 'Inconsolata',
        fontColor: document.body.classList.contains('dark')
            ? 'rgba(255,255,255, 0.6)'
            : '#666',
    };
};
exports.getTickColors = getTickColors;
