"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const Moment = require("moment");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
const queries_1 = require("@actions/queries");
const qs = require("query-string");
const EditableString_1 = require("@components/elements/EditableString");
const UserProvider_1 = require("@/providers/UserProvider");
const SavedQueries = ({ _process, _showToast, hideTitle = false, close }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const history = (0, react_router_dom_1.useHistory)();
    const [queries, setQueries] = (0, react_1.useState)([]);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        const fetchQueries = () => __awaiter(void 0, void 0, void 0, function* () {
            setIsLoading(true);
            const results = yield (0, queries_1.getQueries)();
            const allQueries = results.map(result => {
                const opts = qs.parse(result.query);
                let datasets = [];
                if (opts.opts) {
                    datasets = opts['opts'].split(',');
                }
                return Object.assign(Object.assign({}, result), { numDataSets: datasets.length });
            });
            setIsLoading(false);
            setQueries(allQueries);
        });
        fetchQueries();
    }, []);
    const update = (id, body) => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield _process(queries_1.updateQuery)(id, body);
        if (!results.error) {
            // Show success message
            _showToast({ message: 'Successfully updated query', type: 'success' });
        }
    });
    return !isLoading ? (React.createElement(React.Fragment, null,
        !hideTitle && (React.createElement(semantic_ui_react_1.Header, { as: "h2", className: "align-self-flex--start mb-0" }, "Saved Queries")),
        React.createElement(semantic_ui_react_1.Table, { fixed: true, unstackable: true, selectable: true, celled: true, className: "p-saved-queries-table" },
            React.createElement(semantic_ui_react_1.Table.Header, null,
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "p-cell-name" }, "Name"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "p-cell-description" }, "Description"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "p-cell-data-set" }, "Data sets"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "p-cell-date" }, "Created on"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "p-cell-btn" }))),
            React.createElement(semantic_ui_react_1.Table.Body, null,
                queries.length == 0 && (React.createElement(semantic_ui_react_1.Table.Row, { className: "g-empty-row no-hover-tr" },
                    React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: "5", className: "center-align" },
                        React.createElement("div", { className: "empty-mascot" }),
                        React.createElement("p", null, "You have no saved queries yet!"),
                        React.createElement("p", null, "Get started by creating a single or multiple datasets. You can save these configurations if you'd like to go back to them frequently.")))),
                queries.map(query => {
                    return (React.createElement(semantic_ui_react_1.Table.Row, { key: `saved-query-${query.id}` },
                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                            React.createElement(EditableString_1.default, { identifier: `saved-query-name-${query.id}`, location: 'inline', firstValue: query.name, autoFocus: true, shouldSaveOnBlur: true, shouldSaveOnEnter: true, shouldSelectOnFocus: true, onSave: name => {
                                    update(query.id, { name });
                                } })),
                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                            React.createElement(EditableString_1.default, { identifier: `saved-query-desc-${query.id}`, location: 'inline', firstValue: query.description, autoFocus: true, shouldSaveOnBlur: true, shouldSaveOnEnter: true, shouldSelectOnFocus: true, onSave: description => {
                                    update(query.id, { description });
                                } })),
                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                            React.createElement("span", null, query.numDataSets)),
                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                            React.createElement("span", null, Moment(query.updated_at).format(_user.getMonthDayYearFormat()))),
                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                            React.createElement(semantic_ui_react_1.Button, { size: "mini", color: "green", onClick: () => {
                                    history.push({
                                        path: '/analyze',
                                        search: query.query,
                                    });
                                    close();
                                }, icon: "play", content: "run" }),
                            React.createElement(semantic_ui_react_1.Button, { basic: true, className: "no-border", size: "mini", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    const results = yield _process(queries_1.deleteQuery)(query.id);
                                    if (!results.error) {
                                        // Show success message
                                        _showToast({
                                            message: 'Successfully deleted query',
                                            type: 'success',
                                        });
                                        setQueries(queries.filter(o => {
                                            return o.id !== query.id;
                                        }));
                                    }
                                }), icon: "trash", content: "del", color: "red" }))));
                }))))) : (React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "small" },
        React.createElement("span", { className: "monospace" }, "Loading saved queries")));
};
exports.default = SavedQueries;
