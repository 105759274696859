"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildRulesString = exports.buildMatchesString = void 0;
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_1 = require("@helpers/format");
const MATCH_TYPES_MAPPING = {
    exact: 'matches',
    exactly: '=',
    contain: 'contains',
    start_with: 'starts with',
    is_anything: 'is anything',
    less_than: '<',
    'less than': '<',
    greater_than: '>',
    'greater than': '>',
    'less than or equal to': '<=',
    'greater than or equal to': '>=',
    between: 'between',
    'on or after': 'on or after',
    'on or before': 'on or before',
};
const buildMatchesString = (rule, _assets, _categories) => {
    const { rule_id, criteria_payee_name, criteria_payee_name_match, criteria_notes, criteria_notes_match, criteria_amount, criteria_amount_2, criteria_amount_currency, criteria_amount_match, criteria_category_id, criteria_day, criteria_day_2, criteria_day_match, criteria_asset_id, criteria_plaid_account_id, run_on_update, criteria_on_manual, criteria_on_api, criteria_on_plaid, criteria_on_csv, } = rule;
    let string = [];
    if (criteria_payee_name && criteria_payee_name_match) {
        string.push(React.createElement("div", { key: `criteria-payee-${rule_id}`, className: "flex--align-center" },
            React.createElement("span", { className: "sub-row-a" },
                "Payee",
                ' ',
                React.createElement("span", { className: "highlight" }, MATCH_TYPES_MAPPING[criteria_payee_name_match])),
            React.createElement("span", { className: "sub-row-c" },
                React.createElement("span", { className: "highlight-box" }, criteria_payee_name))));
    }
    if (criteria_category_id) {
        string.push(React.createElement("div", { key: `criteria-category-${rule_id}`, className: "flex--align-center" },
            React.createElement("span", { className: "sub-row-a" },
                "Category ",
                React.createElement("span", { className: "highlight" }, "is")),
            React.createElement("span", { className: "sub-row-c" },
                React.createElement("span", { className: "highlight-box" }, _categories.getName(rule.criteria_category_id)))));
    }
    if (criteria_amount && criteria_amount_currency && criteria_amount_match) {
        string.push(React.createElement("div", { key: `rule-amount-${rule_id}`, className: "flex--align-center" },
            React.createElement("span", { className: "sub-row-a" },
                criteria_amount < 0 ? 'Income' : 'Expense',
                ' ',
                React.createElement("span", { className: "highlight" }, MATCH_TYPES_MAPPING[criteria_amount_match])),
            React.createElement("span", { className: `sub-row-c ${criteria_amount_match === 'between' ? 'flex--align-center' : ''}` },
                criteria_amount_match !== 'between' && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "highlight-box" }, (0, format_1.toPrice)(Math.abs(criteria_amount), //flipSign ? -1 * criteria_amount : criteria_amount,
                    criteria_amount_currency)))),
                criteria_amount_match === 'between' && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "highlight-box" }, (0, format_1.toPrice)(Math.abs(criteria_amount) > Math.abs(criteria_amount_2)
                        ? Math.abs(criteria_amount_2)
                        : Math.abs(criteria_amount), criteria_amount_currency)),
                    React.createElement("span", { className: "mr-05rem ml-05rem" }, "and"),
                    React.createElement("span", { className: "highlight-box" }, (0, format_1.toPrice)(Math.abs(criteria_amount) > Math.abs(criteria_amount_2)
                        ? Math.abs(criteria_amount)
                        : Math.abs(criteria_amount_2), criteria_amount_currency)))))));
    }
    if (criteria_day && criteria_day_match) {
        string.push(React.createElement("div", { key: `rule-day-${rule_id}`, className: "flex--align-center" },
            React.createElement("span", { className: "sub-row-a" },
                "Posted",
                ' ',
                React.createElement("span", { className: "highlight" }, criteria_day_match == 'exactly' ? 'on' : criteria_day_match)),
            React.createElement("span", { className: `sub-row-c ${criteria_day_match === 'between' ? 'flex--align-center' : ''}` },
                React.createElement("span", { className: "highlight-box" },
                    "day ",
                    criteria_day),
                criteria_day_match === 'between' && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "mr-05rem ml-05rem" }, "and"),
                    React.createElement("span", { className: "highlight-box" },
                        "day ",
                        criteria_day_2))))));
    }
    if (criteria_asset_id) {
        string.push(React.createElement("div", { key: `criteria-asset-${rule_id}`, className: "flex--align-center" },
            React.createElement("span", { className: "sub-row-a" },
                "From ",
                React.createElement("span", { className: "highlight" }, "account")),
            React.createElement("span", { className: "sub-row-c" },
                React.createElement("span", { className: "highlight-box" }, _assets.getAssetName(criteria_asset_id)))));
    }
    if (criteria_plaid_account_id) {
        string.push(React.createElement("div", { key: `criteria-account-${rule_id}`, className: "flex--align-center" },
            React.createElement("span", { className: "sub-row-a" }, "From account"),
            React.createElement("span", { className: "sub-row-c" },
                React.createElement("span", { className: "highlight-box" }, _assets.getPlaidAccountName(criteria_plaid_account_id)))));
    }
    if (criteria_notes && criteria_notes_match) {
        string.push(React.createElement("div", { key: `criteria-notes-${rule_id}`, className: "flex--align-center" },
            React.createElement("span", { className: "sub-row-a" },
                "Notes",
                ' ',
                React.createElement("span", { className: "highlight" }, MATCH_TYPES_MAPPING[criteria_notes_match])),
            React.createElement("span", { className: "sub-row-c" },
                React.createElement("span", { className: "highlight-box" }, criteria_notes))));
    }
    if (run_on_update) {
        string.push(React.createElement("div", { key: `criteria-run-on-update-${rule_id}`, className: "flex--align-center" },
            React.createElement("span", { className: "sub-row-a" },
                "On transaction",
                ' '),
            React.createElement("span", { className: "sub-row-c" },
                React.createElement("span", { className: "highlight-box" }, "insert and update"))));
    }
    /*
    const addedStr = []
    if (criteria_on_manual) {
      addedStr.push('manual input')
    }
    if (criteria_on_plaid && _assets.plaidAccounts.length > 0) {
      addedStr.push('bank sync')
    }
    if (criteria_on_csv && _assets.assets.length > 0) {
      addedStr.push('CSV')
    }
    if (criteria_on_api && _assets.hasAPIKey) {
      addedStr.push('API')
    }
  
    if (addedStr.length > 0) {
      string.push(
        <div key={`criteria-on-plaid-${rule_id}`} className="flex--align-center">
          <span className="sub-row-a">
            Added <span className="highlight">via</span>
          </span>
          <span className="sub-row-c">
            <span className="highlight-box">{addedStr.join(', ')}</span>
          </span>
        </div>,
      )
    } */
    return string;
};
exports.buildMatchesString = buildMatchesString;
const buildRulesString = ({ rule, flipSign, sendEmailOnly = false, _categories, _tags, }) => {
    if (!rule)
        return;
    const { rule_id, category_id, notes, payee_name, recurring_payee, recurring_amount, recurring_currency, recurring_id, mark_as_reviewed, mark_as_unreviewed, set_uncategorized, should_send_email, tags, split, skip_rule, skip_recurring, criteria_amount_match, criteria_amount_currency, one_time_rule, run_on_update, stop_processing_others, delete_transaction_id, delete_transaction_payee, delete_transaction_amount, delete_transaction_currency, delete_transaction_date, } = rule;
    let string = [];
    if (delete_transaction_id) {
        string.push(React.createElement("div", { key: `rule-split-${rule_id}`, className: "flex--align-center" },
            React.createElement("span", { className: "sub-row-a" }, "Delete transaction"),
            React.createElement("span", { className: "sub-row-c" },
                React.createElement("span", { className: "highlight-box" },
                    delete_transaction_date,
                    ", ",
                    delete_transaction_payee,
                    ",",
                    ' ',
                    (0, format_1.toPrice)(delete_transaction_amount, delete_transaction_currency)))));
    }
    if (!sendEmailOnly) {
        if ((split === null || split === void 0 ? void 0 : split.length) > 0) {
            string.push(React.createElement("div", { key: `rule-split-${rule_id}`, className: "flex--align-center" },
                React.createElement("span", { className: "sub-row-a" }, "Split into"),
                React.createElement("span", { className: "sub-row-c" },
                    React.createElement("span", { className: "highlight-box" },
                        split.length,
                        " transactions (",
                        split.map((o, index) => {
                            const changes = [];
                            if (o.payee) {
                                changes.push(React.createElement(React.Fragment, { key: `split-${index}-payee` },
                                    "Payee: ",
                                    o.payee,
                                    React.createElement("br", null)));
                            }
                            if (o.notes) {
                                changes.push(React.createElement(React.Fragment, { key: `split-${index}-notes` },
                                    "Notes: ",
                                    o.notes,
                                    React.createElement("br", null)));
                            }
                            if (o.category_id) {
                                changes.push(React.createElement(React.Fragment, { key: `split-${index}-cat` },
                                    "Category: ",
                                    _categories.getName(o.category_id),
                                    React.createElement("br", null)));
                            }
                            if (o.tags) {
                                changes.push(React.createElement(React.Fragment, { key: `split-${index}-tags` },
                                    "Tags:",
                                    ' ',
                                    o.tags.map(tag => _tags.getTagName(tag)).join(', '),
                                    React.createElement("br", null)));
                            }
                            if (o.mark_as_reviewed) {
                                changes.push(React.createElement(React.Fragment, { key: `split-${index}-run-rules` }, "This split transaction will be marked as reviewed"));
                            }
                            if (o.should_run_rules) {
                                changes.push(React.createElement(React.Fragment, { key: `split-${index}-run-rules` }, "This split transaction will run through rules once"));
                            }
                            const str = [];
                            str.push(React.createElement(semantic_ui_react_1.Popup, { key: `split-${index}-run-rules-popup`, disabled: changes.length === 0, inverted: true, size: 'tiny', trigger: React.createElement("span", { key: `split-${index}-run-rules` },
                                    criteria_amount_match === 'exactly' && o.amount
                                        ? `${(0, format_1.toPrice)(Math.abs(o.amount), criteria_amount_currency)}`
                                        : `${o.amount_pct}%`,
                                    changes.length > 0 ? '*' : ''), content: changes }));
                            if (index < split.length - 1) {
                                str.push(React.createElement("span", { key: `split-${index}-comma` }, ", "));
                            }
                            return str;
                        }),
                        ")"))));
        }
        if (payee_name) {
            string.push(React.createElement("div", { key: `rule-payee-${rule_id}`, className: "flex--align-center" },
                React.createElement("span", { className: "sub-row-a" },
                    "Set ",
                    React.createElement("span", { className: "highlight" }, "payee"),
                    " to"),
                React.createElement("span", { className: "sub-row-c" },
                    React.createElement("span", { className: "highlight-box" }, payee_name))));
        }
        if (notes) {
            string.push(React.createElement("div", { key: `rule-notes-${rule_id}`, className: "flex--align-center" },
                React.createElement("span", { className: "sub-row-a" },
                    "Set ",
                    React.createElement("span", { className: "highlight" }, "notes"),
                    " to"),
                React.createElement("span", { className: "sub-row-c" },
                    React.createElement("span", { className: "highlight-box" }, notes))));
        }
        if (set_uncategorized) {
            string.push(React.createElement("div", { key: `rule-category-${rule_id}`, className: "flex--align-center" },
                React.createElement("span", { className: "sub-row-a" }, "Do not"),
                React.createElement("span", { className: "sub-row-c" },
                    React.createElement("span", { className: "highlight-box" }, "set category"))));
        }
        else if (category_id) {
            string.push(React.createElement("div", { key: `rule-category-${rule_id}`, className: "flex--align-center" },
                React.createElement("span", { className: "sub-row-a" },
                    "Set ",
                    React.createElement("span", { className: "highlight" }, "category"),
                    " to"),
                React.createElement("span", { className: "sub-row-c" },
                    React.createElement("span", { className: "highlight-box" }, _categories.getName(category_id)))));
        }
        if (recurring_id) {
            string.push(React.createElement("div", { key: `rule-recurring-${rule_id}`, className: "flex--align-center" },
                React.createElement("span", { className: "sub-row-a" },
                    "Link ",
                    React.createElement("span", { className: "highlight" }, "recurring")),
                React.createElement("span", { className: "sub-row-c" },
                    React.createElement("span", { className: "highlight-box" },
                        recurring_payee,
                        ",",
                        ' ',
                        (0, format_1.toPrice)(flipSign ? -1 * recurring_amount : recurring_amount, recurring_currency)))));
        }
        if (tags && tags.length > 0) {
            string.push(React.createElement("div", { key: `rule-tags-${rule_id}`, className: "flex--align-center" },
                React.createElement("span", { className: "sub-row-a" },
                    "Apply ",
                    React.createElement("span", { className: "highlight" }, "tags")),
                React.createElement("span", { className: "sub-row-c" }, tags.map(o => {
                    return (React.createElement("span", { key: `tag-${o.id}-${rule_id}`, className: "highlight-box mr-05rem" }, o.name));
                }))));
        }
        if (mark_as_reviewed) {
            string.push(React.createElement("div", { key: `rule-mark-as-reviewed-${rule_id}`, className: "flex--align-center" },
                React.createElement("span", { className: "sub-row-a" },
                    React.createElement("span", { className: "highlight" }, "Mark"),
                    " as"),
                React.createElement("span", { className: "sub-row-c" },
                    React.createElement("span", { className: "highlight-box" }, "reviewed"))));
        }
        if (mark_as_unreviewed) {
            string.push(React.createElement("div", { key: `rule-mark-as-unreviewed-${rule_id}`, className: "flex--align-center" },
                React.createElement("span", { className: "sub-row-a" },
                    React.createElement("span", { className: "highlight" }, "Mark"),
                    " as"),
                React.createElement("span", { className: "sub-row-c" },
                    React.createElement("span", { className: "highlight-box" }, "unreviewed"))));
        }
    }
    if (should_send_email) {
        string.push(React.createElement("div", { key: `rule-send-email-${rule_id}`, className: "flex--align-center" },
            React.createElement("span", { className: "sub-row-a" }, "Send an"),
            React.createElement("span", { className: "sub-row-c" },
                React.createElement("span", { className: "highlight-box" },
                    "email notification ",
                    React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "envelope outline" })))));
    }
    if (skip_rule) {
        string.push(React.createElement("div", { key: `rule-skip-rule-${rule_id}`, className: "flex--align-center" },
            React.createElement("span", { className: "sub-row-a" }, "Do not"),
            React.createElement("span", { className: "sub-row-c" },
                React.createElement("span", { className: "highlight-box" }, "create rule"))));
    }
    if (skip_recurring) {
        string.push(React.createElement("div", { key: `rule-skip-recurring-${rule_id}`, className: "flex--align-center" },
            React.createElement("span", { className: "sub-row-a" }, "Do not"),
            React.createElement("span", { className: "sub-row-c" },
                React.createElement("span", { className: "highlight-box" }, "link to recurring"))));
    }
    if (one_time_rule) {
        string.push(React.createElement("div", { key: `rule-one-time-${rule_id}`, className: "flex--align-center" },
            React.createElement("span", { className: "sub-row-a" }, "Delete"),
            React.createElement("span", { className: "sub-row-c" },
                React.createElement("span", { className: "highlight-box" },
                    "after use ",
                    React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "trash" })))));
    }
    if (stop_processing_others) {
        string.push(React.createElement("div", { key: `rule-stop-processing-${rule_id}`, className: "flex--align-center" },
            React.createElement("span", { className: "sub-row-a" }, "Stop processing"),
            React.createElement("span", { className: "sub-row-c" },
                React.createElement("span", { className: "highlight-box" },
                    "other rules ",
                    React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "x" })))));
    }
    return string;
};
exports.buildRulesString = buildRulesString;
