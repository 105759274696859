"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const _ = require("lodash");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const format_1 = require("@helpers/format");
/**
 * BalanceUpdated
 *
 * Given the showToast util and a new balance object denoting updates
 * to asset balances, will:
 * 1. display toasts notifying user of updates
 * 2. call AssetsProvider to update the asset balance
 *
 * Used by Transactions components
 *
 * balances is an array of { name: 'asset name', balance: 0, currency: 'usd' }
 *
 */
const BalanceUpdater = ({ _showToast, balances }) => {
    const [lastUpdate, setLastUpdate] = (0, react_1.useState)(null);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    (0, react_1.useEffect)(() => {
        if (!!(balances === null || balances === void 0 ? void 0 : balances.length) && !_.isEqual(lastUpdate, balances)) {
            _showToast({
                message: 'Changes made successfully',
                content: (React.createElement("div", null, balances.map((asset, index) => {
                    // Update in AssetsProvider
                    _assets.updateAsset(asset.id, {
                        balance: asset.balance,
                        currency: asset.currency,
                        to_base: asset.to_base,
                    });
                    return (React.createElement("p", { key: `asset-update-${index}` },
                        React.createElement(semantic_ui_react_1.Icon, { name: "info circle" }),
                        " ",
                        asset.name,
                        "'s balance has been updated to ",
                        (0, format_1.toPrice)(asset.balance, asset.currency),
                        "."));
                }))),
                autoDismissTimeout: 5000,
                type: 'success',
            });
            setLastUpdate(balances);
        }
    }, [balances]);
    return null;
};
exports.default = BalanceUpdater;
