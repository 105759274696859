"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 *  EditModal.tsx
 *  Recurring expenses edit modal
 */
const React = require("react");
const Moment = require("moment");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const transactions_1 = require("@actions/transactions");
const format_1 = require("@helpers/format");
const EditableAsset_1 = require("@components/elements/EditableAsset");
const EditablePrice_1 = require("@components/elements/EditablePrice");
const EditableString_1 = require("@components/elements/EditableString");
const EditableDate_1 = require("@components/elements/EditableDate");
const BalanceUpdater_1 = require("@components/elements/BalanceUpdater");
// Types
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const UserProvider_1 = require("@/providers/UserProvider");
const CreateTransfer = ({ data, useModal, utils, setVisibility }) => {
    const [date, setDate] = (0, react_1.useState)(Moment().format('YYYY-MM-DD'));
    const [amount, setAmount] = (0, react_1.useState)(null);
    const [currency, setCurrency] = (0, react_1.useState)(null);
    const [fromAccount, setFromAccount] = (0, react_1.useState)(null);
    const [toAccount, setToAccount] = (0, react_1.useState)(null);
    const [payee, setPayee] = (0, react_1.useState)(null);
    const [notes, setNotes] = (0, react_1.useState)(null);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const TRANSFER_SMART_ID = 18;
    const [balances, setBalances] = (0, react_1.useState)(null);
    // Setup
    (0, react_1.useEffect)(() => {
        useModal({
            header: (React.createElement("div", null,
                React.createElement("span", { className: "header-text-large" }, "Create transfer between two accounts"))),
            middleButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: true, className: "no-border", onClick: () => {
                    setVisibility(false, true);
                } }, "Cancel")),
        });
    }, []);
    (0, react_1.useEffect)(() => {
        useModal({
            rightButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", disabled: !amount || amount == '' || !(fromAccount === null || fromAccount === void 0 ? void 0 : fromAccount.id) || !(toAccount === null || toAccount === void 0 ? void 0 : toAccount.id), onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    var _a, _b;
                    let expense, credit, category_id;
                    const asset_update = [];
                    // Figure out Payment/Transfer category id
                    const matched = _categories.all.find(o => o.name === 'Payment, Transfer');
                    if (matched && matched.id) {
                        category_id = matched.id;
                    }
                    // Create debit expense
                    const expenseResults = yield utils._process(transactions_1.createTransaction)({
                        date: date,
                        payee: payee || 'Transfer',
                        notes: notes || null,
                        amount: amount,
                        currency: currency,
                        category_id: category_id || null,
                        [fromAccount.source == 'manual'
                            ? 'asset_id'
                            : 'plaid_account_id']: fromAccount.id > -1 ? fromAccount.id : null,
                    }, { should_convert: false });
                    if (!expenseResults.error) {
                        expense = expenseResults.data.transactions[0].id;
                        asset_update.push(...(_a = expenseResults.data) === null || _a === void 0 ? void 0 : _a.asset_update);
                    }
                    // Create credit expense
                    const creditResults = yield utils._process(transactions_1.createTransaction)({
                        date: date,
                        payee: payee || 'Transfer',
                        notes: notes || null,
                        amount: -1 * amount,
                        currency: currency,
                        category_id: category_id || null,
                        [toAccount.source == 'manual'
                            ? 'asset_id'
                            : 'plaid_account_id']: toAccount.id > -1 ? toAccount.id : null,
                    }, { should_convert: false });
                    if (!creditResults.error) {
                        credit = creditResults.data.transactions[0].id;
                        asset_update.push(...(_b = creditResults.data) === null || _b === void 0 ? void 0 : _b.asset_update);
                    }
                    // Create grouped transaction
                    const groupResults = yield utils._process(transactions_1.createTransactionGroup)({
                        transactions: [credit, expense],
                        details: {
                            date: date,
                            payee: payee || 'Transfer',
                            notes: notes || null,
                            category_id: category_id || null,
                        },
                    });
                    if (!groupResults.error) {
                        data.amendTransactions({
                            add: [groupResults.data.parent, ...groupResults.data.children],
                        });
                        if (asset_update && asset_update.filter(o => !!o).length > 0) {
                            setBalances(asset_update.filter(o => !!o));
                        }
                        else {
                            utils._showToast({
                                message: 'Successfully created transfer',
                                type: 'success',
                            });
                        }
                        setVisibility(false, true);
                    }
                }) }, "Create")),
        });
    }, [date, amount, currency, fromAccount, toAccount, payee, notes]);
    return (React.createElement("div", { id: "create-transfer-view" },
        React.createElement(semantic_ui_react_1.Message, { info: true },
            React.createElement("p", null, "A transfer of money between two accounts involves creating an expense from the originating account, and a credit to the destination account."),
            React.createElement("p", null,
                "These two transactions will sum to ",
                (0, format_1.toPrice)(0, _user.primaryCurrency),
                ' ',
                "and will be automatically grouped.")),
        React.createElement("div", { id: "recurring-modal-detail-view" },
            React.createElement("div", { className: "transaction-details" },
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Transfer"),
                    React.createElement(EditablePrice_1.default, { identifier: 'fejwif', state: 'Editing', error: !amount || amount == '', saveOnChange: true, onSave: (amount, currency) => {
                            setAmount(amount);
                            setCurrency(currency);
                        }, location: 'modal' })),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "From account"),
                    React.createElement(EditableAsset_1.default, { showError: !fromAccount, onSave: obj => {
                            setFromAccount(obj);
                        } })),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "To account"),
                    React.createElement(EditableAsset_1.default, { showError: !toAccount, onSave: obj => {
                            setToAccount(obj);
                        } })),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Date"),
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(EditableDate_1.default, { identifier: `transfer-date`, format: 'month_day_year', firstValue: date, state: 'Editing', location: 'modal', onSave: date => {
                                setDate(date);
                            } }))),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Payee name"),
                    React.createElement(EditableString_1.default, { state: 'Editing', location: 'modal', autosuggest: true, identifier: 'jfioewxf', firstValue: 'Transfer', shouldSaveOnChange: true, onSave: payee => {
                            setPayee(payee);
                        } })),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Notes (optional)"),
                    React.createElement(EditableString_1.default, { state: 'Editing', location: 'modal', identifier: 'jfioewf', firstValue: null, shouldSaveOnChange: true, onSave: notes => {
                            setNotes(notes);
                        } })))),
        React.createElement(BalanceUpdater_1.default, { _showToast: utils._showToast, balances: balances })));
};
exports.default = CreateTransfer;
