"use strict";
/**
 *  StripeCredit.tsx
 *  Container for the Stripe Credit page
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const format_1 = require("@helpers/format");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const user_1 = require("@actions/user");
const semantic_ui_react_1 = require("semantic-ui-react");
const StripeCredit = ({}) => {
    const [message, setMessage] = (0, react_1.useState)('Please wait, applying a credit to your account...');
    const [showButton, setShowButton] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        document.title = `Applying credit to your account - Lunch Money`;
        applyCredit();
    }, []);
    const applyCredit = () => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield (0, user_1.applyStripeCredit)();
        setMessage(results.data.message ||
            `Successfully applied a credit of ${(0, format_1.toPrice)(results.data.amount / -100, results.data.currency)} to your account!`);
        setShowButton(true);
    });
    return (React.createElement("div", { className: "g-logged-out" },
        React.createElement("div", { className: "g-logged-out-container" },
            React.createElement("div", { className: "logged-out-content" },
                React.createElement(semantic_ui_react_1.Container, null,
                    React.createElement("div", { id: "logo-container" },
                        React.createElement("div", { id: "logo" }),
                        React.createElement("div", { id: "shadow" })),
                    React.createElement(semantic_ui_react_1.Header, { as: "h2", className: "center-align" }, message),
                    showButton && (React.createElement(react_router_dom_1.Link, { to: "/billing" },
                        React.createElement(semantic_ui_react_1.Button, { className: "mt-1rem", fluid: true }, "Go to Billing"))))))));
};
exports.default = StripeCredit;
