"use strict";
/**
 *  EditModal.tsx
 *  Recurring expenses edit modal
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
// Types
const transactions_1 = require("@actions/transactions");
const transactions_2 = require("@actions/transactions");
const react_1 = require("react");
const EditableString_1 = require("@components/elements/EditableString");
const EditableTags_1 = require("@components/elements/EditableTags");
const EditableCategory_1 = require("@components/elements/EditableCategory");
const EditableDate_1 = require("@components/elements/EditableDate");
const transactions_3 = require("@actions/transactions");
const transaction_file_1 = require("@actions/transaction_file");
const GroupSelectView_1 = require("@components/Transactions/Modal/GroupSelectView");
const transactions_4 = require("@helpers/transactions");
const format_1 = require("@helpers/format");
const TransactionsProvider_1 = require("@providers/TransactionsProvider");
const UserProvider_1 = require("@/providers/UserProvider");
const checks_1 = require("@/helpers/checks");
const GroupView = ({ data, useModal, utils, setVisibility, switchView, setDisableOnClickOutside, }) => {
    var _a, _b, _c, _d;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _transactions = (0, react_1.useContext)(TransactionsProvider_1.TransactionsContext);
    // From a single transaction row
    const modalData = _transactions.modalData.current;
    // From bulk select
    const [hasChanges, setHasChanges] = (0, react_1.useState)(false);
    const bulkSelected = _transactions.bulkSelected.current;
    const [parentTransaction, setParentTransaction] = (0, react_1.useState)(null);
    const [fullTransactions, setFullTransactions] = (0, react_1.useState)([]);
    const [groupedTransactions, setGroupedTransactions] = (0, react_1.useState)({});
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [showUngroupConfirmation, setShowUngroupConfirmation] = (0, react_1.useState)(false);
    const [groupDetails, setGroupDetails] = (0, react_1.useState)({
        date: null,
        payee: null,
        category_id: null,
        notes: null,
        tags: [],
    });
    const [fileActions, setFileActions] = (0, react_1.useState)({});
    /**
     * Setup: Static Details
     */
    (0, react_1.useEffect)(() => {
        if (!parentTransaction)
            return;
        useModal({
            header: (React.createElement("div", null,
                parentTransaction.is_group ||
                    Object.keys(bulkSelected).length == 0 ? (React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        switchView('DETAILS_VIEW');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " Transaction Details")) : (React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        switchView('BULK_EDIT');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " Back to bulk-edit")),
                React.createElement("span", { className: "header-text-large" }, parentTransaction.is_group
                    ? `Edit transaction group`
                    : `Create new transaction group`))),
            middleButton: hasChanges ? (React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "no-border", basic: true, loading: isLoading, onClick: () => {
                    setVisibility(false, true);
                } }, 'Cancel')) : (React.createElement(React.Fragment, null)),
        });
        return () => {
            useModal({
                header: null,
                leftButton: null,
                middleButton: null,
            });
        };
    }, [parentTransaction, hasChanges, isLoading]);
    (0, react_1.useEffect)(() => {
        if (hasChanges) {
            setDisableOnClickOutside(true);
        }
    }, [hasChanges]);
    (0, react_1.useEffect)(() => {
        if (!parentTransaction)
            return;
        useModal({
            leftButton: parentTransaction.is_group ? (React.createElement(semantic_ui_react_1.Button, { className: "no-border", color: 'red', basic: true, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    var _a;
                    setDisableOnClickOutside(true);
                    // Are there any files attached to the group?
                    const files = (_a = parentTransaction.files) === null || _a === void 0 ? void 0 : _a.filter(o => !o.transaction_date && !o.transaction_name);
                    const actions = {};
                    for (let i = 0; i < (files === null || files === void 0 ? void 0 : files.length); i++) {
                        actions[files[i].id] = Object.assign(Object.assign({}, files[i]), { action: 'delete' });
                    }
                    setFileActions(actions);
                    setShowUngroupConfirmation(true);
                }) }, 'Ungroup')) : (React.createElement(React.Fragment, null)),
        });
    }, [parentTransaction]);
    /**
     * Setup: Save function needs to update on every groupedTransactions update
     */
    (0, react_1.useEffect)(() => {
        var _a;
        if (!parentTransaction)
            return;
        useModal({
            rightButton: groupDetails.date &&
                groupDetails.payee &&
                groupDetails.category_id &&
                ((_a = Object.keys(groupedTransactions)) === null || _a === void 0 ? void 0 : _a.length) > 1 ? (React.createElement(semantic_ui_react_1.Button, { loading: isLoading, disabled: !(groupDetails === null || groupDetails === void 0 ? void 0 : groupDetails.category_id), color: "orange", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setIsLoading(true);
                    let results;
                    if (parentTransaction.is_group || !!parentTransaction.group_id) {
                        // Updating
                        results = yield utils._process(transactions_3.updateTransactionGroup)(parentTransaction.id, {
                            transactions: Object.keys(groupedTransactions),
                        });
                        data.amendTransactions &&
                            data.amendTransactions({
                                // Remove existing groups
                                removeGroup: parentTransaction.id,
                                // Set as group
                                bulkUpdate: [
                                    {
                                        ids: Object.keys(groupedTransactions),
                                        update: {
                                            group_id: parentTransaction.id,
                                        },
                                    },
                                ],
                                // Remove parent transaction
                                remove: [parentTransaction.id],
                                // Add latest parent transaction
                                add: [results.data.parent],
                            });
                    }
                    else {
                        // Creating
                        results = yield utils._process(transactions_3.createTransactionGroup)({
                            transactions: Object.keys(groupedTransactions),
                            details: {
                                date: groupDetails.date,
                                payee: groupDetails.payee,
                                category_id: groupDetails.category_id,
                                notes: groupDetails.notes,
                                tags: groupDetails.tags,
                            },
                        });
                        data.amendTransactions &&
                            data.amendTransactions({
                                bulkUpdate: [
                                    {
                                        ids: Object.keys(groupedTransactions),
                                        update: {
                                            group_id: results.data.parent.id,
                                        },
                                    },
                                ],
                                add: [results.data.parent],
                            });
                    }
                    if (!results.error) {
                        utils._showToast({
                            message: `Successfully ${parentTransaction.is_group ? 'updated' : 'created'} transaction`,
                            type: 'success',
                        });
                        data.setTriggerRefresh(new Date().getTime());
                        setVisibility(false, true);
                    }
                    else {
                        setIsLoading(false);
                    }
                }) }, 'Save Changes')) : (React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                    setVisibility(false, true);
                } }, "Close")),
        });
        return () => {
            useModal({ rightButton: null });
        };
    }, [
        groupedTransactions,
        isLoading,
        groupDetails,
        parentTransaction,
        hasChanges,
    ]);
    /**
     * Setup: Populating states with grouped transaction details
     */
    (0, react_1.useEffect)(() => {
        const fetchTransactions = () => __awaiter(void 0, void 0, void 0, function* () {
            // Get Details
            const groupDetails = yield (0, transactions_2.getTransactionGroup)(modalData.id);
            const transactions = groupDetails.children;
            const groupedMap = {};
            transactions.forEach(tx => {
                groupedMap[tx.id] = (0, transactions_4.toGroupFormat)(tx);
            });
            setGroupedTransactions(groupedMap);
            if (modalData === null || modalData === void 0 ? void 0 : modalData.group_id) {
                // This came from a 'part of group' transaction, so it doesn't have parent details
                setParentTransaction(groupDetails.parent);
            }
            else {
                setParentTransaction(modalData);
            }
        });
        if (!!modalData.id) {
            // This is from a single transaction, part of a group!
            if ((modalData === null || modalData === void 0 ? void 0 : modalData.is_group) || (modalData === null || modalData === void 0 ? void 0 : modalData.group_id)) {
                // Getting transactions from server
                fetchTransactions();
            }
            else {
                // This is a new transaction
                setGroupedTransactions({
                    [modalData.id]: (0, transactions_4.toGroupFormat)(modalData),
                });
                setParentTransaction(modalData);
            }
        }
        else if (!!bulkSelected) {
            // This came in from bulk select
            const group = {};
            // Filter
            Object.keys(_transactions.bulkSelected.current).forEach(id => {
                if (_transactions.bulkSelected.current[id]) {
                    const tx = _transactions.bulkSelected.current[id];
                    group[id] = (0, transactions_4.toGroupFormat)(tx);
                }
            });
            setGroupedTransactions(group);
            setParentTransaction(Object.values(group)[0]);
        }
    }, []);
    /**
     * Setup: Populating group details once we have a parentTransaction established
     */
    (0, react_1.useEffect)(() => {
        var _a;
        if (!parentTransaction)
            return;
        // Do we have the list of fullTransactions?
        const fetchAllTransactions = () => __awaiter(void 0, void 0, void 0, function* () {
            const transactions = yield (0, transactions_1.getTransactions)({
                hide_recurring: false,
                exclude_parents: true,
                currency: data.displayCurrency,
                start_date: Moment(parentTransaction.formatted_date)
                    .startOf('month')
                    .format('YYYY-MM-DD'),
                end_date: Moment(parentTransaction.formatted_date)
                    .endOf('month')
                    .format('YYYY-MM-DD'),
            });
            setFullTransactions(transactions);
        });
        setGroupDetails({
            date: parentTransaction.formatted_date || parentTransaction.date,
            payee: parentTransaction.payee,
            category_id: parentTransaction.category_id,
            notes: parentTransaction.notes,
            tags: ((_a = parentTransaction.tags) === null || _a === void 0 ? void 0 : _a.map(o => o.id)) || [],
        });
        if ((fullTransactions === null || fullTransactions === void 0 ? void 0 : fullTransactions.length) == 0) {
            fetchAllTransactions();
        }
    }, [parentTransaction]);
    return parentTransaction ? (React.createElement("div", { id: "grouped-modal-detail-view" },
        (!parentTransaction.is_group ||
            parentTransaction.id !== modalData.id) && (React.createElement("div", { className: `transaction-details` },
            React.createElement("h3", null, "Transaction Group Details"),
            React.createElement(semantic_ui_react_1.Form, null,
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null, "Group Transaction Date"),
                        React.createElement(EditableDate_1.default, { showError: !(groupDetails === null || groupDetails === void 0 ? void 0 : groupDetails.date), identifier: "group-date", format: 'month_day_year', firstValue: parentTransaction.formatted_date || parentTransaction.date, location: 'modal', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () {
                                setHasChanges(true);
                                setGroupDetails(Object.assign(Object.assign({}, groupDetails), { date: date }));
                            }) }))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null, "Group Category"),
                        React.createElement(EditableCategory_1.default, { identifier: `group-category`, firstValue: parentTransaction.category_id, state: 'Editing', location: 'modal', showError: !(groupDetails === null || groupDetails === void 0 ? void 0 : groupDetails.category_id), onSave: (category_id) => __awaiter(void 0, void 0, void 0, function* () {
                                setHasChanges(true);
                                setGroupDetails(Object.assign(Object.assign({}, groupDetails), { category_id: category_id }));
                            }) }))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null, "Group Payee Name"),
                        React.createElement(EditableString_1.default, { identifier: `group-payee`, firstValue: parentTransaction.payee, showError: (0, checks_1.isEmptyString)(groupDetails === null || groupDetails === void 0 ? void 0 : groupDetails.payee), location: 'modal', state: 'Editing', autosuggest: true, shouldSaveOnChange: true, onSave: (payee) => __awaiter(void 0, void 0, void 0, function* () {
                                setHasChanges(true);
                                setGroupDetails(Object.assign(Object.assign({}, groupDetails), { payee: payee }));
                            }) }))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null, "Transaction Group Notes"),
                        React.createElement(EditableString_1.default, { identifier: `group-notes`, placeholder: "Notes (optional)", firstValue: parentTransaction.notes, location: 'modal', state: 'Editing', shouldSaveOnChange: true, onSave: (notes) => __awaiter(void 0, void 0, void 0, function* () {
                                setHasChanges(true);
                                setGroupDetails(Object.assign(Object.assign({}, groupDetails), { notes: notes }));
                            }) }))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null, "Transaction Group Tags"),
                        React.createElement(EditableTags_1.default, { onChange: value => {
                                setHasChanges(true);
                                setGroupDetails(Object.assign(Object.assign({}, groupDetails), { tags: value }));
                            }, initialValue: ((_a = parentTransaction.tags) === null || _a === void 0 ? void 0 : _a.map(o => o.id)) || [] })))))),
        React.createElement(GroupSelectView_1.default, { _process: utils._process, parent: parentTransaction, transactions: groupedTransactions, switchView: switchView, starterTransactions: fullTransactions === null || fullTransactions === void 0 ? void 0 : fullTransactions.filter(tx => {
                if (tx.has_children) {
                    // Don't show parent transactions
                    return false;
                }
                if (tx.recurring_id) {
                    // Don't show recurring transactions
                    return false;
                }
                if (tx.is_group || tx.group_id) {
                    // Don't show other grouped tx or tx groups
                    return false;
                }
                if (tx.is_pending || tx.status.indexOf('delete') > -1) {
                    return false;
                }
                return true;
            }), setTransactions: setGroupedTransactions, showChanges: () => {
                setHasChanges(true);
            } }),
        React.createElement(semantic_ui_react_1.Modal, { size: ((_b = Object.keys(fileActions)) === null || _b === void 0 ? void 0 : _b.length) > 0 ? 'small' : 'tiny', open: showUngroupConfirmation },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Confirm ungrouping"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("p", null, "Are you sure you want to ungroup this transaction?"),
                ((_c = Object.keys(fileActions)) === null || _c === void 0 ? void 0 : _c.length) > 0 && (React.createElement(React.Fragment, null,
                    React.createElement("p", null, "You have files attached directly to this group. What should we do with them?"),
                    React.createElement(semantic_ui_react_1.Table, { fixed: true, unstackable: true, selectable: true, celled: true, className: "ungroup-files-table" },
                        React.createElement(semantic_ui_react_1.Table.Header, null,
                            React.createElement(semantic_ui_react_1.Table.Row, null,
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "File"),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Action"))),
                        React.createElement(semantic_ui_react_1.Table.Body, null, (_d = Object.values(fileActions)) === null || _d === void 0 ? void 0 : _d.map((file) => {
                            var _a;
                            return (React.createElement(semantic_ui_react_1.Table.Row, { key: `file-${file.id}` },
                                React.createElement(semantic_ui_react_1.Table.Cell, null,
                                    React.createElement("span", null,
                                        file.name,
                                        " (",
                                        (0, format_1.toReadableSize)(file.size),
                                        ")")),
                                React.createElement(semantic_ui_react_1.Table.Cell, null,
                                    React.createElement(semantic_ui_react_1.Dropdown, { fluid: true, selection: true, value: (_a = fileActions[file.id]) === null || _a === void 0 ? void 0 : _a.action, options: [
                                            {
                                                key: 'delete',
                                                text: 'Delete file',
                                                value: 'delete',
                                            },
                                            ...Object.values(groupedTransactions).map((tx) => {
                                                return {
                                                    key: `move-to-${tx.id}`,
                                                    text: `Move file to ${tx.payee} (${Moment(tx.date).format(_user.getMonthDayYearFormat())}, ${(0, format_1.toPrice)(tx.amount, tx.currency)})`,
                                                    value: tx.id,
                                                };
                                            }),
                                        ], onChange: (e, { value }) => {
                                            setFileActions(Object.assign(Object.assign({}, fileActions), { [file.id]: Object.assign(Object.assign({}, fileActions[file.id]), { action: value }) }));
                                        } }))));
                        })))))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        setShowUngroupConfirmation(false);
                    }, basic: true, loading: isLoading, color: "orange" }, "No, Cancel"),
                React.createElement(semantic_ui_react_1.Button, { loading: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setIsLoading(true);
                        // Handle any file stuff separately
                        const actions = Object.values(fileActions);
                        for (let i = 0; i < actions.length; i++) {
                            if (actions[i]['action'] == 'delete') {
                                yield (0, transaction_file_1.deleteFile)(actions[i]['id']);
                            }
                            else if (!isNaN(actions[i]['action'])) {
                                yield (0, transaction_file_1.updateFile)(actions[i]['id'], {
                                    transaction_id: actions[i]['action'],
                                });
                            }
                        }
                        const results = yield utils._process(transactions_3.ungroupTransactionGroup)(parentTransaction.id);
                        setShowUngroupConfirmation(false);
                        if (!results.error) {
                            data.amendTransactions &&
                                data.amendTransactions({
                                    bulkUpdate: [
                                        {
                                            ids: Object.keys(groupedTransactions),
                                            update: {
                                                group_id: null,
                                            },
                                        },
                                    ],
                                    remove: [parentTransaction.id],
                                });
                            data.setTriggerRefresh(new Date().getTime());
                            utils._showToast({
                                message: `Successfully ungrouped transactions`,
                                type: 'success',
                            });
                            setVisibility(false);
                        }
                        else {
                            setIsLoading(false);
                        }
                    }) }, "Yes, Confirm"),
                ' ')))) : (React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true }));
};
exports.default = GroupView;
