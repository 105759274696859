"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionsProvider = exports.TransactionsContext = void 0;
const React = require("react");
const react_1 = require("react");
const transactions_1 = require("@actions/transactions");
const UserProvider_1 = require("@/providers/UserProvider");
const TransactionsContext = (0, react_1.createContext)({
    selectMultiple: false,
    setSelectMultiple: val => { },
    // showSaving,
    modalData: {
        current: {
            id: null,
            date: null,
            payee: null,
            amount: null,
            currency: null,
            category_id: null,
            notes: null,
            group_id: null,
            is_group: false,
            parent_id: null,
            recurring_id: null,
            recurring_amount: null,
            recurring_currency: null,
            recurring_description: null,
            recurring_cadence: null,
            recurring_quantity: null,
            recurring_granularity: null,
            status: null,
            formatted_date: null,
            original_name: null,
            display_name: null,
            tags: [],
            files: null,
            is_pending: false,
            is_income: false,
            asset_id: null,
            asset_name: null,
            asset_display_name: null,
            zabo_account_id: null,
            institution_name: null,
            plaid_account_id: null,
            plaid_account_name: null,
            plaid_account_mask: null,
            account_display_name: null,
            plaid_account_display_name: null,
            type: null,
            quantity: null,
            price: null,
            fees: null,
        },
    },
    bulkSelected: { current: {} },
    allFilters: { current: {} },
    merchantNames: { current: [] },
    fetchMerchantNames: () => { },
    addToMerchantNames: val => { },
});
exports.TransactionsContext = TransactionsContext;
const TransactionsProvider = props => {
    const [selectMultiple, setSelectMultiple] = (0, react_1.useState)(false);
    const modalData = (0, react_1.useRef)({});
    const bulkSelected = (0, react_1.useRef)({});
    const allFilters = (0, react_1.useRef)({});
    const hasFetchedRecurring = (0, react_1.useRef)(false);
    const hasFetchedMerchants = (0, react_1.useRef)(false);
    const merchantNames = (0, react_1.useRef)([]);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const fetchMerchantNames = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!hasFetchedMerchants.current) {
            hasFetchedMerchants.current = true;
            const results = yield (0, transactions_1.getMerchantNames)();
            merchantNames.current = results.map(o => o.payee);
        }
    });
    const addToMerchantNames = (value) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (value && ((_a = merchantNames.current) === null || _a === void 0 ? void 0 : _a.indexOf(value)) == -1) {
            merchantNames.current = [...merchantNames.current, value].sort((a, b) => {
                return a
                    .toLowerCase()
                    .trim()
                    .localeCompare(b.toLowerCase().trim());
            });
        }
    });
    return (React.createElement(TransactionsContext.Provider, { value: {
            selectMultiple,
            setSelectMultiple,
            // showSaving,
            modalData,
            bulkSelected,
            allFilters,
            merchantNames,
            fetchMerchantNames,
            addToMerchantNames,
        } }, props.children));
};
exports.TransactionsProvider = TransactionsProvider;
