'use strict';
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports._unescapeAll = exports._del = exports.del = exports._put = exports.put = exports._post = exports.post = exports.get = exports.process = void 0;
const axios_1 = require("axios");
const index_1 = require("./index");
const validator_1 = require("validator");
const Auth = require("./auth");
const http = require("http");
const https = require("https");
/**
 * All API calls will run through this function which will perform the
 * appropriate refresh token tasks if needed
 */
const process = (fn_1, path_1, data_1, ...args_1) => __awaiter(void 0, [fn_1, path_1, data_1, ...args_1], void 0, function* (fn, path, data, operation = '') {
    axios_1.default.defaults.withCredentials = true;
    axios_1.default.defaults.httpAgent = new http.Agent({ keepAlive: true });
    axios_1.default.defaults.httpsAgent = new https.Agent({ keepAlive: true });
    const results = yield fn(path, data);
    const error = results.error || (results.data && results.data.error);
    if (error) {
        const shouldRetry = yield handleAuthenticationError(error);
        if (shouldRetry) {
            // It error'ed out on invalid token, but we've since gotten a new token
            // so try the whole call again.
            return yield fn(path, data);
        }
        else {
            // An error happened for another reason!
            // Sentry.addBreadcrumb({
            //   type: 'debug',
            //   category: 'response payload',
            //   data: {
            //     operation,
            //     path,
            //     data,
            //   },
            //   level: Sentry.Severity.Info,
            // })
            // Sentry.captureException(error)
            return {
                data: null,
                error: error.response ? error.response.data : error,
            };
        }
    }
    else if (results.budget_was_auto_created) {
        // Redirect to /settings#new-budget-created
        window.location.href = '/settings#new-budget-created';
    }
    return results;
});
exports.process = process;
/**
 * CRUD process methods
 */
const get = (path_2, ...args_2) => __awaiter(void 0, [path_2, ...args_2], void 0, function* (path, params = {}) {
    return yield (0, exports.process)(_get, path, params, 'GET');
});
exports.get = get;
const _get = (path_3, ...args_3) => __awaiter(void 0, [path_3, ...args_3], void 0, function* (path, params = {}) {
    try {
        const results = yield axios_1.default.get(`${index_1.default}/${path}`, {
            params,
            withCredentials: true,
        });
        results.data = (0, exports._unescapeAll)(results.data);
        return results.data;
    }
    catch (e) {
        return { error: e };
    }
});
const post = (path_4, ...args_4) => __awaiter(void 0, [path_4, ...args_4], void 0, function* (path, params = {}) {
    return yield (0, exports.process)(exports._post, path, params, 'POST');
});
exports.post = post;
const _post = (path, body) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        let results = yield axios_1.default.post(`${index_1.default}/${path}`, body, {
            withCredentials: true,
        });
        results = (0, exports._unescapeAll)(results);
        return Object.assign(Object.assign({}, results), { error: null });
    }
    catch (e) {
        return { error: e };
    }
});
exports._post = _post;
const put = (path_5, ...args_5) => __awaiter(void 0, [path_5, ...args_5], void 0, function* (path, params = {}) {
    return yield (0, exports.process)(exports._put, path, params, 'PUT');
});
exports.put = put;
const _put = (path, body) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        let results = yield axios_1.default.put(`${index_1.default}/${path}`, body, {
            withCredentials: true,
        });
        results = (0, exports._unescapeAll)(results);
        return Object.assign(Object.assign({}, results), { error: null });
    }
    catch (e) {
        return { error: e };
    }
});
exports._put = _put;
const del = (path_6, ...args_6) => __awaiter(void 0, [path_6, ...args_6], void 0, function* (path, params = {}) {
    return yield (0, exports.process)(exports._del, path, params, 'DEL');
});
exports.del = del;
const _del = (path, body) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        let results = yield axios_1.default.delete(`${index_1.default}/${path}`, {
            params: Object.assign({}, body),
            withCredentials: true,
        });
        results = (0, exports._unescapeAll)(results);
        return Object.assign(Object.assign({}, results), { error: null });
    }
    catch (e) {
        return { error: e };
    }
});
exports._del = _del;
/**
 * Figure out if we need to get a new refresh token or log user out.
 */
const handleAuthenticationError = (errorObj) => __awaiter(void 0, void 0, void 0, function* () {
    let error = null;
    try {
        error = errorObj.response.data.message;
    }
    catch (e) {
        error = errorObj.displayMessage || null;
    }
    console.log('[handleAuthenticationError]', error);
    if ((error === null || error === void 0 ? void 0 : error.indexOf('maintenance mode')) > -1 &&
        window.location.pathname.indexOf('maintenance') == -1 &&
        Auth.isAuthenticatedPage()) {
        window.location.href = '/maintenance';
    }
    else if (error === '2-factor token expired.' &&
        Auth.isAuthenticatedPage()
    // window.location.href.indexOf('logout') == -1
    ) {
        /**
         * Error: 2-factor token expired
         * Device token is expired, so force log out.
         */
        window.location.href = '/logout?force=true&expired=true';
    }
    else if (error === 'Access token expired.' &&
        (Auth.isAuthenticatedPage() ||
            window.location.pathname.indexOf('auth/2fa') > -1)
    // window.location.href.indexOf('logout') == -1
    ) {
        /**
         * Error: Token expired
         * Access token is expired, so refresh token must be used to get a new one.
         */
        const results = yield (0, exports.post)('auth/token/refresh', {});
        if (!results.error) {
            // Successfully got a refresh token, should now retry original call
            return true;
        }
        else {
            // This case should auto-redirect.
            window.location.href = '/logout?expired=true';
        }
    }
    else if (
    // window.location.href.indexOf('logout') == -1 &&
    error == 'Invalid authentication token.' &&
        Auth.isAuthenticatedPage()) {
        /**
         * Error: Invalid authentication token
         * Sends user to login page. Typically happens when refresh token is expired
         * and user cannot get a new access token.
         */
        window.location.href = '/logout?expired=true';
    }
    else if (Auth.isAuthenticatedPage() && error == 'Login required.') {
        /**
         * Error: Invalid authentication token
         * Sends user to login page. Typically happens when refresh token is expired
         * and user cannot get a new access token.
         */
        window.location.href = `/login?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`;
    }
    return false;
});
/**
 * Unescape inputs
 */
const _unescapeAll = (escaped) => {
    if (!escaped) {
        return escaped;
    }
    else if (Array.isArray(escaped)) {
        return escaped.map(e => (0, exports._unescapeAll)(e));
    }
    else if (typeof escaped === 'object') {
        const unescaped = {};
        Object.keys(escaped).forEach(function (k) {
            unescaped[validator_1.default.unescape(k)] = (0, exports._unescapeAll)(escaped[k]);
        });
        return unescaped;
    }
    else if (typeof escaped === 'string') {
        return validator_1.default.unescape(escaped);
    }
    else {
        return escaped;
    }
};
exports._unescapeAll = _unescapeAll;
