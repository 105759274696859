"use strict";
/**
 *  EditModal.tsx
 *  Recurring expenses edit modal
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_1 = require("react");
const format_1 = require("@helpers/format");
const format_2 = require("@helpers/format");
const ModalProvider_1 = require("@providers/ModalProvider");
const TransactionsProvider_1 = require("@providers/TransactionsProvider");
const transaction_log_1 = require("@actions/transaction_log");
const UserProvider_1 = require("@/providers/UserProvider");
const TransactionLog = ({ data, useModal, utils, setVisibility, switchView, setDisableOnClickOutside, }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _modal = (0, react_1.useContext)(ModalProvider_1.ModalContext);
    const _transactions = (0, react_1.useContext)(TransactionsProvider_1.TransactionsContext);
    const [logs, setLogs] = (0, react_1.useState)(null);
    const SOURCE_FORMAT = {
        user: { color: 'olive', icon: 'user' },
        system: { color: 'teal', icon: 'cogs' },
        rule: { color: 'yellow', icon: 'magic' },
        api: { color: 'violet', icon: 'code' },
        'auto-categorization': { color: 'pink', icon: 'sliders horizontal' },
    };
    /**
     * Setup: Static Details
     */
    (0, react_1.useEffect)(() => {
        useModal({
            header: (React.createElement("div", null,
                React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        switchView('DETAILS_VIEW');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " Back to transaction"),
                React.createElement("span", { className: "header-text-large" }, "Transaction Change History"))),
            middleButton: React.createElement(React.Fragment, null),
            leftButton: React.createElement(React.Fragment, null),
            rightButton: (React.createElement(semantic_ui_react_1.Button, { color: 'orange', basic: true, onClick: () => {
                    setVisibility(false, true);
                } }, 'Close')),
        });
        return () => {
            useModal({
                header: null,
                leftButton: null,
                middleButton: null,
                rightButton: null,
            });
        };
    }, []);
    (0, react_1.useEffect)(() => {
        fetchLogs();
    }, []);
    const fetchLogs = () => __awaiter(void 0, void 0, void 0, function* () {
        const _logs = yield (0, transaction_log_1.getTransactionLog)(_transactions.modalData.current.id);
        setLogs(_logs);
    });
    const sanitize = key => {
        if (key == 'category_id') {
            return 'Category';
        }
        else if (key == 'asset_id' || key == 'plaid_account_id') {
            return 'Account';
        }
        else if (key == 'recurring_id') {
            return 'Recurring link';
        }
        else {
            return (0, format_1.capitalize)(key);
        }
    };
    // Source is user, system or rule (or API?)
    const constructLogDetails = logCollection => {
        var _a;
        // 1. Transaction creation
        if (logCollection.action == 'created') {
            return (React.createElement("span", { className: "log-item" },
                "Transaction ",
                logCollection.descriptor));
        }
        return (_a = logCollection.logs) === null || _a === void 0 ? void 0 : _a.map((log, index) => {
            var _a, _b, _c, _d;
            if (log.action.indexOf('split') > -1) {
                return (React.createElement("span", { className: "log-item", key: `log-${index}` },
                    "Transaction was ",
                    log.action));
            }
            else if (log.action.indexOf('update') == 0) {
                return (React.createElement("span", { className: "log-item", key: `log-${index}` },
                    sanitize(log.field),
                    " updated",
                    ' ',
                    (log.original_value ||
                        (((_a = log.metadata) === null || _a === void 0 ? void 0 : _a.original_amount) &&
                            ((_b = log.metadata) === null || _b === void 0 ? void 0 : _b.original_currency))) && (React.createElement("span", null,
                        "from",
                        ' ',
                        React.createElement("b", null, log.field == 'date'
                            ? Moment(log.original_value).format(_user.getMonthDayYearFormat())
                            : log.field == 'amount'
                                ? (0, format_2.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) *
                                    Number(log.metadata.original_amount), log.metadata.original_currency)
                                : log.original_value),
                        ' ')),
                    "to",
                    ' ',
                    React.createElement("b", null, log.field == 'date'
                        ? Moment(log.new_value).format(_user.getMonthDayYearFormat())
                        : log.field == 'amount'
                            ? (0, format_2.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) *
                                Number(log.metadata.new_amount), log.metadata.new_currency)
                            : log.new_value)));
            }
            else if (log.action == 'removed') {
                if (log.field == 'tags') {
                    return (React.createElement("span", { className: "log-item", key: `log-${index}` },
                        sanitize(log.field),
                        " removed:",
                        ' ',
                        React.createElement("b", null, log.original_value.join(', '))));
                }
                else if (log.field == 'group_id') {
                    // Removed to group
                    return (React.createElement("span", { className: "log-item", key: `log-${index}` },
                        "Removed from group:",
                        ' ',
                        React.createElement("b", null,
                            log.original_value,
                            " (",
                            Moment((_c = log.metadata) === null || _c === void 0 ? void 0 : _c.original_date).format(_user.getMonthDayYearFormat()),
                            ")")));
                }
                else if (log.field == 'in_group') {
                    // Removed to group
                    return (React.createElement("span", { className: "log-item", key: `log-${index}` }, "Removed from group"));
                }
                else {
                    return (React.createElement("span", { className: "log-item", key: `log-${index}` },
                        sanitize(log.field),
                        " ",
                        React.createElement("b", null, log.original_value),
                        " removed"));
                }
            }
            else if (log.action == 'added') {
                if (log.field == 'tags') {
                    // Tags or added to group
                    return (React.createElement("span", { className: "log-item", key: `log-${index}` },
                        sanitize(log.field),
                        " added: ",
                        React.createElement("b", null, log.new_value.join(', '))));
                }
                else if (log.field == 'group_id') {
                    // Added to group
                    return (React.createElement("span", { className: "log-item", key: `log-${index}` },
                        "Added to group:",
                        ' ',
                        React.createElement("b", null,
                            log.new_value,
                            " (",
                            Moment((_d = log.metadata) === null || _d === void 0 ? void 0 : _d.new_date).format(_user.getMonthDayYearFormat()),
                            ")")));
                }
                else if (log.field == 'in_group') {
                    // Added to group
                    return (React.createElement("span", { className: "log-item", key: `log-${index}` }, "Added to group"));
                }
            }
        });
    };
    return (React.createElement("div", null,
        !logs && (React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "tiny", text: "Loading transaction changelog, please wait..." })), logs === null || logs === void 0 ? void 0 :
        logs.map((logCollection, index) => {
            var _a, _b;
            return (React.createElement("div", { className: "log-container", key: `log-collection-${index}` },
                React.createElement("div", { className: "log-icon" },
                    React.createElement(semantic_ui_react_1.Icon, { circular: true, fitted: true, inverted: true, color: (_a = SOURCE_FORMAT[logCollection.source]) === null || _a === void 0 ? void 0 : _a.color, name: (_b = SOURCE_FORMAT[logCollection.source]) === null || _b === void 0 ? void 0 : _b.icon }),
                    React.createElement("div", { className: "log-icon-line" })),
                React.createElement("div", { className: "log-details" },
                    React.createElement("span", { className: "log-header" },
                        Moment(logCollection.timestamp).format(_user.getMonthDayYearFormat()),
                        ' ',
                        "(",
                        Moment(logCollection.timestamp).fromNow(),
                        ")",
                        ' ',
                        logCollection.source == 'user' ||
                            (logCollection.source == 'api' &&
                                `by ${logCollection.user_name}`),
                        ' ',
                        logCollection.source == 'rule' &&
                            (logCollection.rule_criteria_id ? (React.createElement(React.Fragment, null,
                                React.createElement("span", null, "by "),
                                React.createElement("span", { className: "link clickable font--bold", onClick: () => {
                                        _modal.data.current.rule_id =
                                            logCollection.rule_criteria_id;
                                        _modal.data.current.previous = 'VIEW_TRANSACTION_LOG';
                                        _modal.data.current.previous_text =
                                            'Transaction Change History';
                                        switchView('ADD_RULE');
                                    } }, "Rule"))) : (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement("span", null,
                                    "by Rule ",
                                    React.createElement(semantic_ui_react_1.Icon, { name: "info circle", fitted: true })), content: "This transaction was created by a rule that has since been deleted or modified from the original.", inverted: true }))),
                        logCollection.source !== 'user' &&
                            logCollection.source !== 'rule' &&
                            `by ${logCollection.source}`,
                        ' ',
                        logCollection.via ? `via ${logCollection.via}` : ''),
                    React.createElement("div", { className: "log-items" }, constructLogDetails(logCollection)))));
        }),
        Moment(_transactions.modalData.current.created_at).isBefore(Moment('2023-02-01')) && (React.createElement(semantic_ui_react_1.Message, null, "Please note that change history for this transaction may not be fully complete."))));
};
exports.default = TransactionLog;
