"use strict";
/**
 *  EditModal.tsx
 *  Recurring expenses edit modal
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const EditableString_1 = require("@components/elements/EditableString");
const EditableDate_1 = require("@components/elements/EditableDate");
const EditableCategory_1 = require("@components/elements/EditableCategory");
const EditableAsset_1 = require("@components/elements/EditableAsset");
const EditableTags_1 = require("@components/elements/EditableTags");
const BalanceUpdater_1 = require("@components/elements/BalanceUpdater");
const transactions_1 = require("@actions/transactions");
const TransactionsProvider_1 = require("@providers/TransactionsProvider");
const AssetsProvider_1 = require("@/providers/AssetsProvider");
const EditableCurrency_1 = require("@/components/elements/EditableCurrency");
const RecurringProvider_1 = require("@/providers/RecurringProvider");
const BulkEdit = ({ data, useModal, utils, setVisibility, setDisableOnClickOutside, switchView, }) => {
    const [transactions, setTransactions] = (0, react_1.useState)([]);
    const [formUI, setFormUI] = (0, react_1.useState)(null);
    // Editable fields
    const [showDate, setShowDate] = (0, react_1.useState)(false);
    const [bulkDate, setBulkDate] = (0, react_1.useState)(null);
    const [showName, setShowName] = (0, react_1.useState)(false);
    const [bulkName, setBulkName] = (0, react_1.useState)(null);
    const [showCategoryId, setShowCategoryId] = (0, react_1.useState)(false);
    const [bulkCategoryId, setBulkCategoryId] = (0, react_1.useState)(null);
    const [showNote, setShowNote] = (0, react_1.useState)(false);
    const [bulkNote, setBulkNote] = (0, react_1.useState)(null);
    const [showCurrency, setShowCurrency] = (0, react_1.useState)(false);
    const [bulkCurrency, setBulkCurrency] = (0, react_1.useState)(null);
    const [bulkRemoveCategories, setBulkRemoveCategories] = (0, react_1.useState)(false);
    const [bulkRemoveNotes, setBulkRemoveNotes] = (0, react_1.useState)(false);
    const [showAsset, setShowAsset] = (0, react_1.useState)(false);
    const [bulkAsset, setBulkAsset] = (0, react_1.useState)(null);
    const [showTags, setShowTags] = (0, react_1.useState)(false);
    const [bulkTags, setBulkTags] = (0, react_1.useState)(null);
    const [bulkRemoveTags, setBulkRemoveTags] = (0, react_1.useState)(false);
    const [showRecurring, setShowRecurring] = (0, react_1.useState)(false);
    const [bulkRecurring, setBulkRecurring] = (0, react_1.useState)(null);
    const [bulkRemoveRecurring, setBulkRemoveRecurring] = (0, react_1.useState)(false);
    const [bulkReviewed, setBulkReviewed] = (0, react_1.useState)(false);
    const [bulkUnreviewed, setBulkUnreviewed] = (0, react_1.useState)(false);
    const [balances, setBalances] = (0, react_1.useState)(null);
    const _transactions = (0, react_1.useContext)(TransactionsProvider_1.TransactionsContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const _recurring = (0, react_1.useContext)(RecurringProvider_1.RecurringContext);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const isGroup = (0, react_1.useRef)(false);
    const isGrouped = (0, react_1.useRef)(false);
    const isRecurring = (0, react_1.useRef)(false);
    const isPlaid = (0, react_1.useRef)(false);
    const isSplit = (0, react_1.useRef)(false);
    (0, react_1.useEffect)(() => {
        setTransactions(Object.values(_transactions.bulkSelected.current).filter(o => {
            if (!o)
                return false;
            if (o['is_group']) {
                isGroup.current = true;
            }
            if (o['group_id']) {
                isGrouped.current = true;
            }
            if (o['recurring_id'] && o['recurring_type'] == 'cleared') {
                isRecurring.current = true;
            }
            if (o['plaid_account_id'] &&
                !!_assets.getPlaidAccount(o['plaid_account_id']) &&
                !_assets.getPlaidAccount(o['plaid_account_id'])['treat_as_manual']) {
                isPlaid.current = true;
            }
            if (o['parent_id']) {
                isSplit.current = true;
            }
            return !!o;
        }));
        // Reset everything
        setBulkDate(null);
        setBulkName(null);
        setBulkCategoryId(null);
        setBulkNote(null);
        setBulkCurrency(null);
        setBulkAsset(null);
        setBulkRemoveTags(false);
        setBulkRecurring(null);
        setBulkRemoveRecurring(false);
        setBulkReviewed(false);
        setBulkUnreviewed(false);
        setShowDate(false);
        setShowName(false);
        setShowCategoryId(false);
        setShowNote(false);
        setShowCurrency(false);
        setShowAsset(false);
    }, [data]);
    // Setup
    (0, react_1.useEffect)(() => {
        useModal({
            header: (React.createElement("span", { className: "header-text-large" },
                "Edit ",
                transactions.length,
                " transactions")),
            leftButton: React.createElement(React.Fragment, null),
            middleButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: true, className: "no-border", onClick: () => {
                    setVisibility(false, true);
                } }, "Cancel")),
        });
        return () => {
            useModal({
                header: null,
                middleButton: null,
                leftButton: null,
            });
        };
    }, [transactions]);
    // Keeping track of unsaved changes
    (0, react_1.useEffect)(() => {
        const notFalsey = [
            bulkDate,
            bulkName,
            bulkCategoryId,
            bulkNote,
            bulkCurrency,
            bulkRemoveCategories,
            bulkRemoveNotes,
            bulkAsset,
            bulkTags,
            bulkRemoveTags,
            bulkRecurring,
            bulkRemoveRecurring,
            bulkReviewed,
            bulkUnreviewed,
        ].filter(o => !!o);
        if (notFalsey.length > 0) {
            setDisableOnClickOutside(true);
        }
    }, [
        bulkDate,
        bulkName,
        bulkCategoryId,
        bulkNote,
        bulkCurrency,
        bulkRemoveCategories,
        bulkRemoveNotes,
        bulkAsset,
        bulkTags,
        bulkRemoveTags,
        bulkRecurring,
        bulkRemoveRecurring,
        bulkReviewed,
        bulkUnreviewed,
    ]);
    // Setup
    (0, react_1.useEffect)(() => {
        useModal({
            rightButton: (React.createElement(semantic_ui_react_1.Button, { loading: isLoading, color: "orange", className: "no-border", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    var _a, _b, _c, _d, _e;
                    setIsLoading(true);
                    const bulkEdits = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ date: showDate ? bulkDate : null, payee: showName ? bulkName : null, category_id: showCategoryId ? bulkCategoryId : null, notes: showNote ? bulkNote || '' : null, currency: showCurrency ? bulkCurrency || '' : null }, (showAsset
                        ? bulkAsset.id == -1
                            ? { plaid_account_id: null, asset_id: null }
                            : {
                                [bulkAsset.source == 'plaid'
                                    ? 'plaid_account_id'
                                    : 'asset_id']: bulkAsset.id,
                                [bulkAsset.source == 'plaid'
                                    ? 'asset_id'
                                    : 'plaid_account_id']: null,
                            }
                        : {})), { tags: showTags ? bulkTags : null }), (bulkReviewed ? { status: 'cleared' } : {})), (bulkUnreviewed ? { status: 'uncleared' } : {})), (showRecurring && !!bulkRecurring
                        ? { recurring_id: bulkRecurring }
                        : {})), (bulkRemoveRecurring ? { recurring_id: null } : {}));
                    Object.keys(bulkEdits).forEach(key => {
                        if (key != 'plaid_account_id' &&
                            key != 'asset_id' &&
                            bulkEdits[key] == null &&
                            // if category_id is set to null, only delete it if bulkRemoveCategories is not true
                            (key != 'category_id' || !bulkRemoveCategories)) {
                            delete bulkEdits[key];
                        }
                    });
                    const results = yield utils._process(transactions_1.bulkUpdateTransactions)({
                        transactionIds: transactions.map(tx => tx.id),
                        updateObj: bulkEdits,
                        opts: {
                            remove_notes: bulkRemoveNotes,
                            remove_tags: bulkRemoveTags,
                        },
                    });
                    if (!results.error) {
                        if (((_a = results.data) === null || _a === void 0 ? void 0 : _a.asset_update) &&
                            ((_b = results.data) === null || _b === void 0 ? void 0 : _b.asset_update.length) > 0) {
                            setBalances(results.data.asset_update);
                        }
                        else {
                            utils._showToast({
                                message: 'Successfully updated transactions',
                                type: 'success',
                            });
                        }
                        if (((_c = results.data) === null || _c === void 0 ? void 0 : _c.recurring_transactions) &&
                            ((_d = results.data) === null || _d === void 0 ? void 0 : _d.recurring_transactions.length) > 0) {
                            data.amendTransactions({
                                updateMultiple: (_e = results.data) === null || _e === void 0 ? void 0 : _e.recurring_transactions,
                            });
                        }
                        if (bulkRemoveTags) {
                            bulkEdits['tags'] = [];
                            bulkEdits['tag_type'] = 'replace';
                        }
                        if (bulkRemoveNotes) {
                            bulkEdits['display_notes'] = null;
                            bulkEdits['notes'] = null;
                        }
                        if (!!bulkEdits.payee) {
                            bulkEdits['display_name'] = bulkEdits['payee'];
                        }
                        if (!!bulkEdits.notes) {
                            bulkEdits['display_notes'] = bulkEdits['notes'];
                        }
                        if (!!bulkEdits.date) {
                            bulkEdits['formatted_date'] = bulkEdits['date'];
                        }
                        data.amendTransactions({
                            bulkUpdate: [
                                {
                                    ids: transactions.map(tx => tx.id),
                                    update: Object.assign({ tag_type: 'append' }, bulkEdits),
                                },
                            ],
                        });
                        setVisibility(false);
                    }
                    else {
                        setIsLoading(false);
                    }
                }) }, "Save Changes")),
        });
        return () => {
            useModal({
                rightButton: null,
            });
        };
    }, [
        transactions,
        bulkDate,
        bulkName,
        bulkCategoryId,
        bulkNote,
        bulkCurrency,
        bulkRemoveCategories,
        bulkRemoveNotes,
        bulkAsset,
        bulkTags,
        bulkRemoveTags,
        bulkRecurring,
        bulkRemoveRecurring,
        bulkReviewed,
        bulkUnreviewed,
        isLoading,
    ]);
    (0, react_1.useEffect)(() => {
        const form = (React.createElement(semantic_ui_react_1.Form, null,
            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: showDate, onChange: (e, { checked }) => {
                            setShowDate(checked);
                        }, label: "Set date" }),
                    showDate && (React.createElement(React.Fragment, null,
                        React.createElement(EditableDate_1.default, { identifier: 'bulk-edit-date', format: 'month_day_year', firstValue: bulkDate, location: 'modal', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () {
                                setBulkDate(date);
                            }) }),
                        React.createElement("div", { className: "mb-05rem" }))))),
            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: showName, onChange: (e, { checked }) => {
                            setShowName(checked);
                        }, label: "Set payee" }),
                    showName && (React.createElement(React.Fragment, null,
                        React.createElement(EditableString_1.default, { placeholder: "Enter name", identifier: `bulk-edit-name`, firstValue: bulkName, autosuggest: true, shouldSaveOnChange: true, location: 'modal', state: 'Editing', onSave: name => {
                                setBulkName(name);
                            } }),
                        React.createElement("div", { className: "mb-05rem" }))))),
            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: showCategoryId, onChange: (e, { checked }) => {
                            setShowCategoryId(checked);
                        }, label: "Set category" }),
                    showCategoryId && (React.createElement(React.Fragment, null,
                        React.createElement(EditableCategory_1.default, { identifier: `bulk-edit-category`, firstValue: bulkCategoryId, state: 'Editing', location: 'modal', onSave: (category) => __awaiter(void 0, void 0, void 0, function* () {
                                setBulkCategoryId(category);
                            }), onShowConfirmation: () => {
                                setDisableOnClickOutside(true);
                            } }),
                        React.createElement("div", { className: "mb-05rem" }))))),
            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: showNote, onChange: (e, { checked }) => {
                            if (checked) {
                                setBulkRemoveNotes(false);
                            }
                            setShowNote(checked);
                        }, label: "Set note" }),
                    showNote && (React.createElement(React.Fragment, null,
                        React.createElement(EditableString_1.default, { placeholder: "Set note", identifier: `bulk-edit-note`, firstValue: bulkNote, shouldSaveOnChange: true, location: 'modal', state: 'Editing', onSave: note => {
                                setBulkNote(note);
                            } }),
                        React.createElement("div", { className: "mb-05rem" }))))),
            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: showCurrency, onChange: (e, { checked }) => {
                            setShowCurrency(checked);
                        }, label: "Set currency" }),
                    showCurrency && (React.createElement(React.Fragment, null,
                        React.createElement(EditableCurrency_1.default, { onSave: currency => {
                                setBulkCurrency(currency);
                            }, firstValue: bulkCurrency, useSupported: true }),
                        React.createElement(semantic_ui_react_1.Message, { info: true }, "Bulk-updating currency will not affect transactions in selected groups and will not update asset balances."),
                        React.createElement("div", { className: "mb-05rem" }))))),
            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: bulkRemoveCategories, onChange: (e, { checked }) => {
                            if (checked) {
                                setShowCategoryId(false);
                            }
                            setBulkRemoveCategories(checked);
                        }, label: "Set all to Uncategorized" }))),
            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: bulkRemoveNotes, onChange: (e, { checked }) => {
                            if (checked) {
                                setShowNote(false);
                            }
                            setBulkRemoveNotes(checked);
                        }, label: "Remove all notes" }))),
            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, disabled: isGroup.current || isPlaid.current, readOnly: isGroup.current || isPlaid.current, checked: showAsset, onChange: (e, { checked }) => {
                            setShowAsset(checked);
                        }, label: "Move to account" }),
                    ' ',
                    React.createElement(semantic_ui_react_1.Popup, { inverted: true, position: "top center", size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", name: "question circle" }) }, isPlaid.current ? (React.createElement(React.Fragment, null,
                        React.createElement("p", null,
                            "One or more of the selected transactions is an",
                            ' ',
                            React.createElement("b", null, "imported transaction"),
                            " from an automatically-synced account without the correct settings, and therefore cannot be moved to another account."),
                        React.createElement("p", null, "Go to the Accounts page, click into an account and under Advanced Settings, toggle on \"Allow modifications to transactions\" to enable this option, or deselect the transactions which are from these accounts."))) : isGroup.current ? (React.createElement("p", null,
                        "One or more of the selected transactions is a",
                        ' ',
                        React.createElement("b", null, "transaction group"),
                        " and cannot be moved to a manually-managed account.")) : (React.createElement(React.Fragment, null,
                        React.createElement("p", null, "Not seeing an account? To move transactions to a synced account, you'll need to turn on the correct settings."),
                        React.createElement("p", null, "Go to the Accounts page, click into an account and under Advanced Settings, toggle on \"Allow modifications to transactions\" to enable this option.")))),
                    showAsset && (React.createElement(React.Fragment, null,
                        React.createElement(EditableAsset_1.default, { onSave: value => {
                                setBulkAsset(value);
                            } }),
                        React.createElement("div", { className: "mb-05rem" }))))),
            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: showTags, onChange: (e, { checked }) => {
                            setShowTags(checked);
                            if (checked) {
                                setBulkRemoveTags(false);
                            }
                        }, label: "Add tags" }),
                    showTags && (React.createElement(React.Fragment, null,
                        React.createElement(EditableTags_1.default, { onChange: value => {
                                setBulkTags(value);
                            } }),
                        React.createElement("div", { className: "mb-05rem" }))))),
            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: showRecurring, onChange: (e, { checked }) => {
                            setShowRecurring(checked);
                            if (!checked) {
                                setBulkRecurring(null);
                            }
                            else {
                                setBulkRemoveRecurring(false);
                            }
                        }, label: "Link all recurring" }),
                    showRecurring && (React.createElement(React.Fragment, null,
                        React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal", 
                            // className="filter-row-dropdown filter-row-dropdown-value"
                            search: true, fluid: true, selection: true, value: bulkRecurring, placeholder: `Select recurring item`, options: _recurring.dropdownOptions, onChange: (e, { value }) => {
                                setBulkRecurring(value);
                            } }),
                        React.createElement("div", { className: "mb-05rem" }))))),
            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: bulkRemoveRecurring, onChange: (e, { checked }) => {
                            if (checked) {
                                setShowRecurring(false);
                                setBulkRecurring(null);
                            }
                            setBulkRemoveRecurring(checked);
                        }, label: "Unlink all recurring" }))),
            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: bulkRemoveTags, onChange: (e, { checked }) => {
                            if (checked) {
                                setShowTags(false);
                            }
                            setBulkRemoveTags(checked);
                        }, label: "Remove all tags" }))),
            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: bulkReviewed, onChange: (e, { checked }) => {
                            if (checked) {
                                setBulkUnreviewed(false);
                            }
                            setBulkReviewed(checked);
                        }, label: "Mark all as reviewed" }))),
            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: bulkUnreviewed, onChange: (e, { checked }) => {
                            if (checked) {
                                setBulkReviewed(false);
                            }
                            setBulkUnreviewed(checked);
                        }, label: "Mark all as unreviewed" })))));
        setFormUI(form);
    }, [
        showDate,
        showName,
        showNote,
        showCurrency,
        showCategoryId,
        showAsset,
        showTags,
        bulkRemoveCategories,
        bulkRemoveNotes,
        bulkAsset,
        bulkTags,
        bulkRemoveTags,
        showRecurring,
        bulkRecurring,
        bulkRemoveRecurring,
        bulkReviewed,
        bulkUnreviewed,
        transactions,
    ]);
    return (React.createElement("div", { id: "transactions-modal-detail-view" },
        React.createElement("div", { className: "transaction-details" },
            React.createElement("h3", null, "Update fields"),
            formUI),
        React.createElement("p", { className: "divider-line mb-17rem" },
            React.createElement("span", null, "or")),
        React.createElement(semantic_ui_react_1.Popup, { position: "top center", size: "small", disabled: !(transactions.length < 2 ||
                isGroup.current ||
                isGrouped.current ||
                isRecurring.current), trigger: React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Button, { color: "orange", fluid: true, className: "mb-1rem", disabled: transactions.length < 2 ||
                        isGroup.current ||
                        isGrouped.current ||
                        isRecurring.current, onClick: () => {
                        switchView('GROUP_VIEW');
                    } },
                    "Create group transaction",
                    (transactions.length < 2 ||
                        isGroup.current ||
                        isGrouped.current ||
                        isRecurring.current) && (React.createElement(React.Fragment, null,
                        ' ',
                        React.createElement(semantic_ui_react_1.Icon, { color: 'grey', name: "question circle" }))))) },
            isRecurring.current && (React.createElement("p", null,
                "One or more of the selected transactions is a ",
                React.createElement("b", null, "recurring item"),
                ", which cannot be grouped into a transaction.")),
            isGrouped.current && (React.createElement("p", null,
                "One or more of the selected transactions is already",
                ' ',
                React.createElement("b", null, "part of a transaction group"),
                ", and cannot be grouped again.")),
            isGroup.current && (React.createElement("p", null,
                "One or more of the selected transactions is a",
                ' ',
                React.createElement("b", null, "transaction group"),
                "and cannot be grouped again."))),
        React.createElement(BalanceUpdater_1.default, { _showToast: utils._showToast, balances: balances })));
};
exports.default = BulkEdit;
