"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.areArraysEqual = exports.areSameNumber = exports.paramCheck = exports.isValidAmount = exports.isValidDate = exports.isEmptyString = exports.isNullOrEmpty = exports.isNull = void 0;
const isNull = (element) => {
    return element === null || typeof element === 'undefined';
};
exports.isNull = isNull;
const isNullOrEmpty = (element) => {
    return (element === null ||
        typeof element === 'undefined' ||
        Object.keys(element).length == 0);
};
exports.isNullOrEmpty = isNullOrEmpty;
const isEmptyString = (str) => {
    return !str || str.toString().length === 0;
};
exports.isEmptyString = isEmptyString;
exports.isValidDate = true; // TODO
exports.isValidAmount = true; // TODO
/**
 *  params object { date: null, payee: null }
 *  required object [ 'date', 'payee' ]
 */
const paramCheck = (params, required) => {
    required.forEach(req => {
        if ((0, exports.isNull)(params[req]) || (0, exports.isEmptyString)(params[req])) {
            throw new Error(`Required field is missing: ${req}`);
        }
    });
};
exports.paramCheck = paramCheck;
const areSameNumber = (value1, value2) => {
    return Number(value1) === Number(value2);
};
exports.areSameNumber = areSameNumber;
const areArraysEqual = (a, b) => {
    if (a === b)
        return true;
    if (a == null || b == null)
        return false;
    if (a.length != b.length)
        return false;
    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.
    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i])
            return false;
    }
    return true;
};
exports.areArraysEqual = areArraysEqual;
