"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const UserProvider_1 = require("@providers/UserProvider");
const tooltips2_1 = require("@helpers/tooltips2");
const react_1 = require("react");
const react_chartjs_2_1 = require("react-chartjs-2");
const colors_1 = require("@helpers/colors");
const chart_js_1 = require("chart.js");
const PieChart = ({ data, dataSetNames, filterDateStart, filterDateEnd, toDisplayPrice, groupBy, showPieGroups, getLink, }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const chartRef = (0, react_1.useRef)({});
    const [chartDataset, setChartDataset] = (0, react_1.useState)([]);
    const [chartLabels, setChartLabels] = (0, react_1.useState)(null);
    chart_js_1.Chart.register(...chart_js_1.registerables);
    (0, react_1.useEffect)(() => {
        if (!data || !(data === null || data === void 0 ? void 0 : data.length))
            return;
        // Show as one pie chart if grouped by totals
        if (data.length > 1 && groupBy !== 'by_totals') {
            handleMultipleDatasets();
        }
        else {
            handleSingleDataset();
        }
    }, [data, groupBy, showPieGroups]);
    const handleMultipleDatasets = () => {
        const labelSets = [];
        const dataSets = [];
        const countSets = [];
        const colorSets = [];
        const idSets = [];
        // Universal colors
        const colors = [];
        let colorIndex = 0;
        data.forEach(datum => {
            const _labels = [];
            const _data = [];
            const _counts = [];
            const _colors = [];
            const _ids = [];
            datum
                .sort((a, b) => {
                return b.total - b.total;
            })
                .forEach((o, index) => {
                // If it's hidden, we still want to calculate the proper colors
                let color;
                if (colors.hasOwnProperty(o.id)) {
                    color = colors[o.id];
                }
                else {
                    color = colors_1.CHART_COLORS[colorIndex++ % colors_1.CHART_COLORS.length];
                    colors[o.id] = color;
                }
                if (
                // Show category groups and categories not in groups
                (showPieGroups && (o.has_children || !o.group_name)) ||
                    // Do not show any category groups
                    (!showPieGroups && !o.has_children)) {
                    if (!o.hidden) {
                        _labels.push(`${o.group_name ? o.group_name + '/' : ''}${o.name}`);
                        _data.push(o.total);
                        _counts.push(o.count);
                        _ids.push(o.id);
                        _colors.push(color);
                    }
                }
            });
            labelSets.push(_labels);
            dataSets.push(_data);
            countSets.push(_counts);
            colorSets.push(_colors);
            idSets.push(_ids);
        });
        setChartDataset(dataSets.map((data, index) => {
            return {
                id: idSets[index],
                borderColor: document.body.classList.contains('dark')
                    ? '#333'
                    : '#fff',
                hoverBorderColor: document.body.classList.contains('dark')
                    ? '#333'
                    : '#fff',
                borderWidth: 0,
                hoverBorderWidth: 0,
                data: data,
                counts: countSets[index],
                backgroundColor: colorSets[index],
                hoverBackgroundColor: colorSets[index],
            };
        }));
        setChartLabels(labelSets);
    };
    const handleSingleDataset = () => {
        const _labels = [];
        const _data = [];
        const _counts = [];
        const _colors = [];
        const _ids = [];
        if (groupBy == 'by_totals' && data.length > 1) {
            data.forEach((dataset, datasetIndex) => {
                _data.push(dataset[0].total);
                _counts.push(dataset[0].count);
                _labels.push(dataSetNames[datasetIndex]);
                _colors.push(colors_1.CHART_COLORS[datasetIndex % colors_1.CHART_COLORS.length]);
                _ids.push(datasetIndex);
            });
        }
        else {
            data[0]
                .sort((a, b) => {
                return b.total - a.total;
            })
                .forEach((o, index) => {
                if (
                // Show category groups and categories not in groups
                (showPieGroups && (o.has_children || !o.group_name)) ||
                    // Do not show any category groups
                    (!showPieGroups && !o.has_children)) {
                    if (!o.hidden) {
                        _labels.push(`${o.group_name ? o.group_name + '/' : ''}${o.name}`);
                        _data.push(o.total);
                        _counts.push(o.count);
                        _ids.push(o.id);
                        _colors.push(colors_1.CHART_COLORS[index % colors_1.CHART_COLORS.length]);
                    }
                }
            });
        }
        setChartDataset([
            {
                id: _ids,
                hoverBorderColor: document.body.classList.contains('dark')
                    ? '#333'
                    : '#fff',
                borderColor: document.body.classList.contains('dark') ? '#333' : '#fff',
                borderWidth: 1,
                hoverBorderWidth: 1,
                data: _data,
                counts: _counts,
                backgroundColor: _colors,
                hoverBackgroundColor: _colors,
            },
        ]);
        setChartLabels([_labels]);
    };
    return (chartLabels &&
        chartDataset &&
        chartLabels.map((labels, datasetIndex) => {
            if ((labels === null || labels === void 0 ? void 0 : labels.length) == 0)
                return;
            return (React.createElement("div", { className: "pie-chart-rel", key: `pie-chart-${datasetIndex}` },
                labels.length > 44 && (React.createElement(semantic_ui_react_1.Message, { compact: true, info: true }, "Legend will only show for less than 45 datasets!")),
                React.createElement(react_chartjs_2_1.Pie, { key: `pie-chart-${datasetIndex}`, ref: reference => {
                        chartRef.current[datasetIndex] = reference;
                    }, data: {
                        labels: labels,
                        datasets: [chartDataset[datasetIndex]],
                    }, options: {
                        cutout: '40%',
                        maintainAspectRatio: true,
                        hover: {
                            mode: 'nearest',
                            intersect: false,
                        },
                        plugins: {
                            title: {
                                display: false,
                            },
                            legend: {
                                display: labels.length > 44 ? false : true,
                                position: 'right',
                                labels: {
                                    // fontColor: document.body.classList.contains('dark')
                                    //   ? 'rgba(255,255,255, 0.6)'
                                    //   : '#666',
                                    font: {
                                        family: 'Avenir',
                                        size: 14,
                                    },
                                },
                            },
                            tooltip: {
                                mode: 'point',
                                intersect: false,
                                enabled: false,
                                external: function (context) {
                                    return (0, tooltips2_1.getTooltip)(context, null);
                                },
                                callbacks: {
                                    label: function (context) {
                                        const sum = context.dataset.data.reduce((accumulator, curValue) => {
                                            return Math.abs(accumulator) + Math.abs(curValue);
                                        });
                                        const value = Math.abs(context.dataset.data[context.dataIndex]);
                                        const index = context.dataIndex;
                                        const display = toDisplayPrice(context.dataset.data[index]);
                                        const link = getLink({
                                            id: context.dataset['id'][context.dataIndex],
                                        }, 'all');
                                        return `<div class="row display--flex">
                            <p class="circle" style="background-color:${context.dataset.backgroundColor[index]}"></p>
                        <div>
                        <p class="monospace mb-0">
                          <span class="number ${display.className}">${display.text}</span><span> (${Number((value / sum) * 100).toFixed(2) +
                                            '%'})
                          </span>
                        </p>
                          <p class="label">${(groupBy == 'by_totals' &&
                                            dataSetNames.length > 1) ||
                                            dataSetNames.length == 1
                                            ? ``
                                            : `${dataSetNames[datasetIndex]}:`} ${context.label}</p>
                          <p class="tx">${context.dataset['counts'][index]} transaction${context.dataset['counts'][index] > 1 ? 's' : ''}
                            <a href="/transactions?${link}">
                              <i aria-hidden="true" class="grey arrow alternate circle right icon clickable"></i>
                            </a>
                          </p>
                        </div>

                      </div>`;
                                    },
                                    title: function (t) {
                                        return `${Moment(filterDateStart).format(_user.getMonthYearFormat())} to ${filterDateEnd
                                            ? Moment(filterDateEnd).format(_user.getMonthYearFormat())
                                            : Moment().format(_user.getMonthYearFormat())}`;
                                    },
                                },
                            },
                        },
                    } })));
        }));
};
exports.default = PieChart;
