"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const TaskCard_1 = require("@components/TaskCard/TaskCard");
const LinkedAccountsTaskCard = ({ hideQuestionMark = true, onClick = null, }) => {
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const [title, setTitle] = (0, react_1.useState)(null);
    const [subtitle, setSubtitle] = (0, react_1.useState)(null);
    const [className, setClassName] = (0, react_1.useState)(null);
    const [accountStatus, setAccountStatus] = (0, react_1.useState)(_assets.getCounts());
    (0, react_1.useEffect)(() => {
        const status = _assets.getCounts();
        if (typeof status === 'undefined') {
            setTitle('Review linked accounts');
            // Nothing.
        }
        else if (status.error > 0) {
            setClassName('');
            setTitle(`${status.error} ${status.error == 1 ? ' account needs ' : ' accounts need '} review`);
            setSubtitle(`Some of your accounts are in error.`);
        }
        else if (status.active === 0) {
            setClassName('');
            setTitle('Setup accounts');
            setSubtitle('Add a synced or manually-managed account');
        }
        else {
            setClassName('task-done');
            setTitle('Review accounts');
            setSubtitle('Everything looks good!');
        }
        setAccountStatus(_assets.getCounts());
    }, [_assets]);
    return (React.createElement(TaskCard_1.default, { type: "accounts", className: className, onClick: () => {
            if (accountStatus.active === 0) {
                onClick('should-add');
            }
            else if (accountStatus.error > 0) {
                onClick('has-error');
            }
            else {
                onClick();
            }
        }, title: title, subtitle: subtitle, hideQuestionMark: hideQuestionMark }));
};
exports.default = LinkedAccountsTaskCard;
