"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const qs = require("query-string");
const semantic_ui_react_1 = require("semantic-ui-react");
const TransactionsContainer_1 = require("./TransactionsContainer");
const PopupTransactions = ({ openTransactionsTable, onClose, headerText, query, _process, _showToast, _removeToast, }) => {
    return (React.createElement(semantic_ui_react_1.Modal, { open: openTransactionsTable, dimmer: 'inverted', centered: false, size: 'large', onClose: onClose },
        React.createElement(semantic_ui_react_1.Modal.Header, null, headerText),
        React.createElement(semantic_ui_react_1.Modal.Content, { scrolling: true, className: "no-padding" },
            React.createElement(TransactionsContainer_1.default
            // Options
            , { 
                // Options
                isEmbedded: true, displaySidebar: false, allowBulkSelect: false, allowNewTransaction: false, watchQueryChanges: true, showHeader: false, showSearchAndFilter: false, showPlaidRefresh: false, preventModal: true, allowApplyRules: false, updateCallback: () => { }, transactionQuery: query, showYearOverride: true, showTotalFooter: true, 
                // Utilities
                _process: _process, _removeToast: _removeToast, _showToast: _showToast })),
        React.createElement(semantic_ui_react_1.Modal.Actions, null,
            React.createElement("div", { className: "flex--space-between" },
                React.createElement(semantic_ui_react_1.Button, { content: "View transactions", labelPosition: "right", icon: "external", onClick: () => {
                        window.open(`/transactions?${qs.stringify(query)}`);
                    }, positive: true }),
                React.createElement("div", { className: "display--flex" },
                    React.createElement(semantic_ui_react_1.Button, { content: "Close", onClick: onClose }))))));
};
exports.default = PopupTransactions;
