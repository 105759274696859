"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
class TaskCard extends React.Component {
    render() {
        return (React.createElement("div", { className: `task-card clickable ${this.props.className}`, onClick: this.props.onClick || null, onMouseEnter: () => {
                if (!!this.props.onHover &&
                    !this.props.isLoading &&
                    this.props.className !== 'task-done') {
                    this.props.onHover();
                }
            }, onMouseLeave: () => {
                if (!!this.props.onBlur &&
                    !this.props.isLoading &&
                    this.props.className !== 'task-done') {
                    this.props.onBlur();
                }
            } },
            ' ',
            React.createElement("div", { className: `task-card-icon ${this.props.type}-task-icon` }),
            React.createElement("div", { className: "task-text" },
                React.createElement("span", { className: "title" }, this.props.title),
                this.props.isLoading ? (React.createElement(semantic_ui_react_1.Loader, { active: true, size: "tiny", inline: "centered" })) : (React.createElement("span", { className: "subtitle" }, this.props.subtitle))),
            !this.props.isLoading && React.createElement("div", { className: "task-checkmark" }),
            !this.props.isLoading && !this.props.hideQuestionMark && (React.createElement("div", { className: "task-help" }))));
    }
}
exports.default = TaskCard;
