"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const _ = require("lodash");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const balance_history_1 = require("@actions/balance_history");
const react_csv_1 = require("react-csv");
const react_router_dom_1 = require("react-router-dom");
const UserProvider_1 = require("@providers/UserProvider");
const assets_1 = require("@actions/assets");
const crypto_1 = require("@actions/crypto");
const networth_1 = require("@helpers/networth");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const Chart_1 = require("@components/NetWorth/Chart");
const Table_1 = require("@components/NetWorth/Table");
const NetWorth = ({ _process, _showToast }) => {
    var _a, _b;
    const history = (0, react_router_dom_1.useHistory)();
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [balanceHistory, setBalanceHistory] = (0, react_1.useState)(null);
    const [currentView, setCurrentView] = (0, react_1.useState)('chart');
    // Settings
    const _percentView = localStorage.getItem('_lm_net_worth_percent_view');
    const [percentView, setPercentView] = (0, react_1.useState)(_percentView || 'pct-change');
    const _hideAccountBreakdown = localStorage.getItem('_lm_net_worth_hide_account_breakdown');
    const [hideAccountBreakdown, setHideAccountBreakdown] = (0, react_1.useState)(_hideAccountBreakdown == 'true');
    const [chartBreakdown, setChartBreakdown] = (0, react_1.useState)('type');
    const [hiddenAccounts, setHiddenAccounts] = (0, react_1.useState)({});
    const [hiddenTypes, setHiddenTypes] = (0, react_1.useState)({});
    const exportable = (0, react_1.useRef)([]);
    const [initExport, setInitExport] = (0, react_1.useState)(false);
    const fetch = () => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield (0, balance_history_1.getBalanceHistory)();
        setBalanceHistory(results);
    });
    (0, react_1.useEffect)(() => {
        document.title = 'Net Worth - Lunch Money';
        fetch();
    }, []);
    const remove = (obj) => __awaiter(void 0, void 0, void 0, function* () {
        const newBalanceHistory = (0, networth_1.removeFromBalanceHistory)(balanceHistory, obj);
        setBalanceHistory(Object.assign({}, newBalanceHistory));
    });
    const update = (_c, prevBalance_1) => __awaiter(void 0, [_c, prevBalance_1], void 0, function* ({ date, balance, currency, _id, asset_id, plaid_account_id, zabo_account_id, crypto_manual_id, zabo_balance, zabo_currency, account_name, account_institution_name, account_type, }, prevBalance) {
        var _d, _e, _f;
        const obj = {
            date,
            balance,
            currency,
            _id,
            asset_id,
            plaid_account_id,
            zabo_account_id,
            crypto_manual_id,
            zabo_balance,
            zabo_currency,
            account_name,
            account_institution_name,
            account_type,
        };
        // If it's for this month and it's an asset, then use another endpoint
        let results;
        let message = 'Successfully updated historical balance';
        if (date ==
            Moment()
                .startOf('month')
                .format('YYYY-MM-DD') &&
            obj.asset_id) {
            results = yield _process(assets_1.updateAsset)(obj.asset_id, {
                balance: obj.balance,
                currency: obj.currency,
            });
            results.data = (_e = (_d = results === null || results === void 0 ? void 0 : results.data) === null || _d === void 0 ? void 0 : _d.updated[0]) === null || _e === void 0 ? void 0 : _e.to_base;
            message = 'Successfully updated balance';
        }
        else if (date ==
            Moment()
                .startOf('month')
                .format('YYYY-MM-DD') &&
            obj.crypto_manual_id) {
            results = yield _process(crypto_1.updateManual)(obj.crypto_manual_id, {
                balance: obj.balance,
                currency: obj.currency,
            });
            results.data = (_f = results === null || results === void 0 ? void 0 : results.data[0]) === null || _f === void 0 ? void 0 : _f.to_base;
            message = 'Successfully updated balance';
        }
        else {
            results = yield _process(balance_history_1.insertBalanceHistory)(obj);
        }
        if (!results.error) {
            _showToast({
                type: 'success',
                message: message,
            });
            // We want to update balanceHistory. Use helper!
            const newBalanceHistory = (0, networth_1.amendBalanceHistory)(balanceHistory, Object.assign(Object.assign({}, obj), { to_base: results.data }));
            setBalanceHistory(Object.assign({}, newBalanceHistory));
        }
    });
    const datasetTypeSelector = () => {
        if (balanceHistory == null || balanceHistory.all_accounts.length == 0)
            return;
        const assetTypes = Object.keys(balanceHistory.all_accounts.assets).sort((a, b) => a.localeCompare(b));
        const liabilityTypes = Object.keys(balanceHistory.all_accounts.liabilities).sort((a, b) => a.localeCompare(b));
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "right-align mb-1rem" },
                React.createElement("span", { onClick: () => {
                        const override = {};
                        setHiddenTypes({});
                        setHiddenAccounts(Object.assign(Object.assign({}, hiddenAccounts), { 'net-worth': false }));
                    }, className: "link clickable" }, "Select all"),
                ' ',
                "|",
                ' ',
                React.createElement("span", { onClick: () => {
                        const override = {};
                        [...assetTypes, ...liabilityTypes].forEach(type => {
                            override[type] = true;
                        });
                        setHiddenAccounts(Object.assign(Object.assign({}, hiddenAccounts), { 'net-worth': true }));
                        setHiddenTypes(Object.assign(Object.assign({}, hiddenTypes), override));
                    }, className: "link clickable" }, "Deselect all")),
            ' ',
            React.createElement("div", { className: `dataset` },
                React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: !hiddenAccounts['net-worth'], onChange: (e, { checked }) => {
                        setHiddenAccounts(Object.assign(Object.assign({}, hiddenAccounts), { ['net-worth']: !checked }));
                    }, label: 'Net Worth' })),
            [...assetTypes, ...liabilityTypes].map(type => {
                return (React.createElement("div", { key: `account-type-${type}`, className: `dataset` },
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: !hiddenTypes[type], onChange: (e, { checked }) => {
                            setHiddenTypes(Object.assign(Object.assign({}, hiddenTypes), { [type]: !checked }));
                        }, label: type })));
            })));
    };
    const datasetSelector = (type, accountType) => {
        return (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "divider" }),
            React.createElement("div", { className: "flex--space-between-flex-start" },
                React.createElement("p", { className: "label-style mb-05rem" }, accountType),
                React.createElement("div", { className: "right-align" },
                    React.createElement("span", { onClick: () => {
                            const override = {};
                            balanceHistory.all_accounts[type][accountType].forEach(account => {
                                override[account._id] = false;
                            });
                            setHiddenAccounts(Object.assign(Object.assign({}, hiddenAccounts), override));
                        }, className: "link clickable" }, "Select all"),
                    ' ',
                    "|",
                    ' ',
                    React.createElement("span", { onClick: () => {
                            const override = {};
                            balanceHistory.all_accounts[type][accountType].forEach(account => {
                                override[account._id] = true;
                            });
                            setHiddenAccounts(Object.assign(Object.assign({}, hiddenAccounts), override));
                        }, className: "link clickable" }, "Deselect all"))),
            balanceHistory.all_accounts[type][accountType]
                .sort((a, b) => {
                return a.name.localeCompare(b.name);
            })
                .map(account => {
                return (React.createElement("div", { key: `account-${account._id}`, className: `dataset` },
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: !hiddenAccounts[account._id], onChange: (e, { checked }) => {
                            setHiddenAccounts(Object.assign(Object.assign({}, hiddenAccounts), { [account._id]: !checked }));
                        }, label: account.name })));
            })));
    };
    return (React.createElement(semantic_ui_react_1.Container, { className: "g-net-worth" },
        React.createElement(ContainerHeader_1.default, { title: "Net Worth Tracker" }),
        React.createElement("div", { className: "query-tool-container" },
            React.createElement("div", { className: "header-controls" },
                React.createElement("div", { className: "switcher-button on-bg", onClick: () => {
                        setCurrentView(currentView == 'chart' ? 'table' : 'chart');
                    } }, currentView == 'chart' ? (React.createElement("span", null,
                    React.createElement(semantic_ui_react_1.Icon, { name: "table" }),
                    " View/edit data")) : (React.createElement("span", null,
                    React.createElement(semantic_ui_react_1.Icon, { name: "chart bar outline" }),
                    " View chart"))),
                React.createElement("div", { className: "ml-05rem display--flex" },
                    currentView == 'table' && (React.createElement(React.Fragment, null,
                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", trigger: React.createElement("div", { className: "mr-05rem icon-container", onClick: () => {
                                    setInitExport(true);
                                    setTimeout(() => {
                                        document.getElementById('export-csv').click();
                                        setInitExport(false);
                                    }, 500);
                                } },
                                React.createElement(semantic_ui_react_1.Icon, { name: "download", fitted: true, color: "black", className: "clickable" })) }, "Export to CSV"),
                        initExport && (React.createElement(react_csv_1.CSVLink, { uFEFF: false, id: "export-csv", filename: `lunchmoney-net-worth-${Moment().format('YYYYMMDDHHmmss')}.csv`, data: exportable.current })))),
                    currentView == 'chart' && (React.createElement("div", { className: "switcher-button", onClick: () => {
                            setChartBreakdown(chartBreakdown == 'account' ? 'type' : 'account');
                        } }, chartBreakdown == 'account' ? (React.createElement("span", null,
                        React.createElement(semantic_ui_react_1.Icon, { name: "eye" }),
                        " by account")) : (React.createElement("span", null,
                        React.createElement(semantic_ui_react_1.Icon, { name: "eye" }),
                        " by type")))),
                    React.createElement(semantic_ui_react_1.Popup, { wide: true, position: "bottom right", trigger: React.createElement("div", { className: "ml-05rem icon-container" },
                            React.createElement(semantic_ui_react_1.Icon, { name: "filter", className: "clickable" })), content: chartBreakdown == 'type' && currentView == 'chart' ? (React.createElement(React.Fragment, null, datasetTypeSelector())) : (React.createElement("div", { className: "dataset-picker" },
                            React.createElement("div", { className: "right-align" },
                                React.createElement("span", { onClick: () => {
                                        setHiddenAccounts({});
                                    }, className: "link clickable" }, "Select all"),
                                ' ',
                                "|",
                                ' ',
                                React.createElement("span", { onClick: () => {
                                        const override = {};
                                        _.flatten([
                                            ...Object.values(balanceHistory === null || balanceHistory === void 0 ? void 0 : balanceHistory.all_accounts.assets),
                                            ...Object.values(balanceHistory === null || balanceHistory === void 0 ? void 0 : balanceHistory.all_accounts.liabilities),
                                        ]).forEach(account => {
                                            override[account['_id']] = true;
                                        });
                                        setHiddenAccounts(Object.assign(Object.assign({}, override), { 'net-worth': true }));
                                    }, className: "link clickable" }, "Deselect all")),
                            currentView == 'chart' && (React.createElement("div", { className: `dataset mt-05rem` },
                                React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: !hiddenAccounts['net-worth'], onChange: (e, { checked }) => {
                                        setHiddenAccounts(Object.assign(Object.assign({}, hiddenAccounts), { ['net-worth']: !checked }));
                                    }, label: 'Net Worth' }))),
                            balanceHistory &&
                                ((_a = Object.keys(balanceHistory === null || balanceHistory === void 0 ? void 0 : balanceHistory.all_accounts.assets)) === null || _a === void 0 ? void 0 : _a.map((assetType, index) => {
                                    return (React.createElement(React.Fragment, { key: `asset-selector-${index}` }, datasetSelector('assets', assetType)));
                                })),
                            balanceHistory &&
                                ((_b = Object.keys(balanceHistory === null || balanceHistory === void 0 ? void 0 : balanceHistory.all_accounts.liabilities)) === null || _b === void 0 ? void 0 : _b.map((liabilityType, index) => {
                                    return (React.createElement(React.Fragment, { key: `asset-selector-${index}` }, datasetSelector('liabilities', liabilityType)));
                                })))), on: "click" }),
                    currentView == 'chart' && (React.createElement(semantic_ui_react_1.Popup, { wide: true, position: "bottom right", on: "click", trigger: React.createElement("div", { className: "ml-05rem icon-container" },
                            React.createElement(semantic_ui_react_1.Icon, { name: "cog", className: "clickable" })), content: React.createElement(React.Fragment, null,
                            React.createElement("div", null,
                                React.createElement("span", null, "Show"),
                                React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-05rem inline-breakdown-dropdown no-wrap", inline: true, value: percentView, onChange: (e, { value }) => {
                                        setPercentView(value);
                                        localStorage.setItem('_lm_net_worth_percent_view', value);
                                    }, options: [
                                        {
                                            key: 'pct-change',
                                            value: 'pct-change',
                                            text: 'percentage change',
                                        },
                                        {
                                            key: 'pct-total',
                                            value: 'pct-total',
                                            text: 'percentage of total',
                                        },
                                        {
                                            key: 'none',
                                            value: 'none',
                                            text: 'no percentages',
                                        },
                                    ] }),
                                ' ',
                                React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "help circle" }), inverted: true, size: "tiny" }, "Configure whether or not the tooltip should show the percentage change from the previous month for each asset and liability line items.")),
                            chartBreakdown == 'type' && (React.createElement("div", { className: "mt-05rem" },
                                React.createElement(semantic_ui_react_1.Dropdown, { className: "mr-05rem inline-breakdown-dropdown no-wrap", inline: true, value: hideAccountBreakdown ? 'hide' : 'show', onChange: (e, { value }) => {
                                        setHideAccountBreakdown(value == 'hide');
                                        localStorage.setItem('_lm_net_worth_hide_account_breakdown', value == 'hide' ? 'true' : 'false');
                                    }, options: [
                                        {
                                            key: 'show',
                                            value: 'show',
                                            text: 'Show',
                                        },
                                        {
                                            key: 'hide',
                                            value: 'hide',
                                            text: 'Hide',
                                        },
                                    ] }),
                                React.createElement("span", null,
                                    "breakdown of accounts",
                                    ' ',
                                    React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "help circle" }), inverted: true, size: "tiny" }, "Configure whether or not the tooltip should show the breakdown of assets and liabilities by type."))))) })))),
            balanceHistory == null || (balanceHistory === null || balanceHistory === void 0 ? void 0 : balanceHistory.by_accounts.length) > 0 ? (currentView == 'chart' ? (React.createElement(Chart_1.default, { hiddenTypes: hiddenTypes, hiddenAccounts: hiddenAccounts, chartBreakdown: chartBreakdown, listOfAccounts: balanceHistory === null || balanceHistory === void 0 ? void 0 : balanceHistory.all_accounts, balanceHistory: balanceHistory === null || balanceHistory === void 0 ? void 0 : balanceHistory.by_date, hideAccountBreakdown: hideAccountBreakdown, percentView: percentView })) : (React.createElement(Table_1.default, { _process: _process, _showToast: _showToast, hiddenAccounts: hiddenAccounts, netWorthArray: balanceHistory ? [...balanceHistory.net_worth] : [], setExportableData: data => {
                    exportable.current = data;
                }, updateData: (obj, newMonth) => {
                    // Adding a new time to the array
                    setBalanceHistory(Object.assign(Object.assign(Object.assign({}, balanceHistory), obj), { by_date: [
                            {
                                label: Moment(newMonth, 'YYYY-MM-DD').format(_user.getMonthYearFormat()),
                                net_worth: null,
                                currency: _user.primaryCurrency,
                                assets: {
                                    by_type: {},
                                    by_account: {},
                                    total: null,
                                },
                                liabilities: {
                                    by_type: {},
                                    by_account: {},
                                    total: null,
                                },
                            },
                            ...balanceHistory.by_date,
                        ] }));
                }, timeArray: balanceHistory ? [...balanceHistory.time_array] : [], balanceHistory: balanceHistory ? [...balanceHistory.by_accounts] : [], update: update, remove: remove, fetch: fetch }))) : (React.createElement("div", { className: `width-100 loader-cell loading-card monospace center-align` },
                React.createElement("div", { className: "empty-mascot" }),
                React.createElement("p", null, "You have no accounts yet!"),
                React.createElement("p", null, "The net worth tracker works by tracking the balances on your accounts over time."),
                React.createElement("p", null,
                    "Get started by adding an account at the",
                    ' ',
                    React.createElement(semantic_ui_react_1.Button, { size: "mini", onClick: () => {
                            history.push('/accounts');
                        } }, "Accounts page")))))));
};
exports.default = NetWorth;
