"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const auth_1 = require("@actions/auth");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
const UserProvider_1 = require("@/providers/UserProvider");
const Confirm = ({ _process, _showToast }) => {
    const history = (0, react_router_dom_1.useHistory)();
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [text, setText] = (0, react_1.useState)('Confirming your email..');
    const { confirm_id } = (0, react_router_dom_1.useParams)();
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = `Confirming email... - Lunch Money`;
        confirm();
    }, []);
    const confirm = () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield (0, auth_1.confirmEmail)(confirm_id);
        yield _user.fetchUserDetails();
        setText(response.data || response.error.message);
    });
    return (React.createElement("div", { className: "g-logged-out" },
        React.createElement("div", { className: "g-logged-out-container" },
            React.createElement("div", { className: "logged-out-content" },
                React.createElement(semantic_ui_react_1.Container, null,
                    React.createElement("div", { id: "logo-container" },
                        React.createElement("div", { id: "logo" }),
                        React.createElement("div", { id: "shadow" })),
                    React.createElement(semantic_ui_react_1.Header, { as: "h2", className: "center-align" }, text),
                    React.createElement(semantic_ui_react_1.Button, { fluid: true, onClick: () => {
                            history.push('/overview');
                        } }, "Return to Lunch Money"))))));
};
exports.default = Confirm;
