"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const CopyCommand_1 = require("@components/elements/CopyCommand");
const Moment = require("moment");
const admin_1 = require("@actions/admin");
const UserInfo = ({ user, accounts, _showToast, refreshData }) => {
    return (React.createElement(React.Fragment, null,
        !!user && (React.createElement(semantic_ui_react_1.Card, { fluid: true, className: "mt-1rem" },
            React.createElement(semantic_ui_react_1.Card.Content, null,
                React.createElement(semantic_ui_react_1.Card.Header, { className: "flex--space-between" },
                    React.createElement("span", null),
                    React.createElement("span", null, "User Details"),
                    React.createElement(semantic_ui_react_1.Label, { circular: true, color: 'teal', empty: true, key: 'teal' }))),
            React.createElement(semantic_ui_react_1.Card.Content, { className: "less-card-padding" },
                React.createElement(semantic_ui_react_1.Table, { basic: "very", padded: true },
                    React.createElement(semantic_ui_react_1.Table.Body, null,
                        React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" },
                                React.createElement("b", null, "User ID")),
                            React.createElement(semantic_ui_react_1.Table.Cell, null,
                                user.id,
                                ' ',
                                React.createElement(CopyCommand_1.default, { value: user.id, tooltipText: 'Copy user id', _showToast: _showToast }))),
                        React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" },
                                React.createElement("b", null, "User Name")),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, user.name)),
                        React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" },
                                React.createElement("b", null, "User Email")),
                            React.createElement(semantic_ui_react_1.Table.Cell, null,
                                user.email,
                                ' ',
                                React.createElement(CopyCommand_1.default, { value: user.email, tooltipText: 'Copy user email', _showToast: _showToast }))),
                        React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" },
                                React.createElement("b", null, "Beta Program")),
                            React.createElement(semantic_ui_react_1.Table.Cell, null,
                                user.is_beta ? 'Joined' : 'Not Joined',
                                !user.is_beta && (React.createElement(semantic_ui_react_1.Button, { className: "ml-05rem", size: "mini", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        const results = yield (0, admin_1.addToBeta)({ id: user.id });
                                        if (results.data) {
                                            refreshData();
                                            _showToast({
                                                type: 'success',
                                                message: 'User added to beta testing program',
                                            });
                                        }
                                    }) }, "Add to Beta")))),
                        user.scheduled_for_deletion && (React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" },
                                React.createElement("b", null, "User")),
                            React.createElement(semantic_ui_react_1.Table.Cell, null,
                                "This user is scheduled for deletion on",
                                ' ',
                                Moment(user.scheduled_for_deletion).format('MMM D, YYYY'),
                                ".")))))))),
        accounts && (React.createElement(semantic_ui_react_1.Card, { fluid: true, className: "mt-1rem" },
            React.createElement(semantic_ui_react_1.Card.Content, null,
                React.createElement(semantic_ui_react_1.Card.Header, { className: "flex--space-between" },
                    React.createElement("span", null),
                    React.createElement("span", null, "Account Details"),
                    React.createElement(semantic_ui_react_1.Label, { circular: true, color: 'teal', empty: true, key: 'teal' }))),
            React.createElement(semantic_ui_react_1.Card.Content, { className: "less-card-padding" },
                React.createElement(semantic_ui_react_1.Table, { basic: "very", padded: true, selectable: true },
                    React.createElement(semantic_ui_react_1.Table.Body, null, accounts === null || accounts === void 0 ? void 0 : accounts.map(account => (React.createElement(React.Fragment, { key: `accounts-${account.id}` },
                        React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap", rowSpan: "4" },
                                React.createElement("b", null,
                                    "Account ",
                                    account.id,
                                    " (",
                                    account.status,
                                    ")")),
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap no-side-padding" },
                                React.createElement("b", null, "Display Name")),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, account.display_name)),
                        React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" },
                                React.createElement("b", null, "Owner")),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, account.is_owner
                                ? 'Yes'
                                : `No (User ${account.owner_id} is the owner)`)),
                        React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" },
                                React.createElement("b", null, "Internal Type")),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, account.type)),
                        React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" },
                                React.createElement("b", null, "Subscription Status")),
                            React.createElement(semantic_ui_react_1.Table.Cell, null,
                                React.createElement("a", { href: `https://dashboard.stripe.com/customers/${account.stripe_customer_id}` },
                                    React.createElement("span", { className: account.stripe_status == 'active' ||
                                            account.stripe_status == 'trialing'
                                            ? 'color--green'
                                            : 'color-red' }, account.stripe_status)),
                                ' ',
                                "(Last updated",
                                ' ',
                                Moment(account.stripe_status_updated_at).fromNow(),
                                ")"))))))))))));
};
exports.default = UserInfo;
