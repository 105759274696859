"use strict";
/**
 *  DataSetModal.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const qs = require("query-string");
const DataSetForm_1 = require("@components/QueryTool/DataSetForm");
const QueryToolProvider_1 = require("@providers/QueryToolProvider");
const react_router_dom_1 = require("react-router-dom");
const DataSetModal = ({ useModal, setVisibility }) => {
    const history = (0, react_router_dom_1.useHistory)();
    const [preset, setPreset] = (0, react_1.useState)('Last 6 months');
    const [startDate, setStartDate] = (0, react_1.useState)(null);
    const [endDate, setEndDate] = (0, react_1.useState)(null);
    const [_queryTool] = (0, react_1.useContext)(QueryToolProvider_1.QueryToolContext);
    const [dataSets, setDataSets] = (0, react_1.useState)([new Date().getTime()]);
    const [ready, setReady] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        useModal({
            header: React.createElement("span", { className: "header-text-large" }, "Configure Datasets"),
            leftButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "no-border", basic: true, onClick: () => {
                    setVisibility(false, true);
                } }, "Cancel")),
            middleButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "no-border", basic: true, onClick: () => {
                    history.push({
                        to: '/analyze',
                        search: qs.stringify({
                            opts: qs.stringify({
                                date_range: 'Last 6 months',
                            }),
                        }),
                    });
                    setVisibility(false, true);
                } }, "Reset")),
        });
        return () => {
            useModal({
                header: null,
                middleButton: null,
                leftButton: null,
            });
        };
    }, []);
    (0, react_1.useEffect)(() => {
        useModal({
            rightButton: (React.createElement(semantic_ui_react_1.Button, { disabled: dataSets.filter(o => !!o).length == 0, color: "orange", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    const dateObj = {};
                    if (preset == 'Custom') {
                        dateObj['start_date'] = startDate;
                        dateObj['end_date'] = endDate;
                        delete dateObj['date_range'];
                    }
                    else {
                        dateObj['date_range'] = preset;
                        delete dateObj['start_date'];
                        delete dateObj['end_date'];
                    }
                    const strings = Object.values(_queryTool.allDataSets.current).map(val => {
                        return qs.stringify(Object.assign(Object.assign({}, val), dateObj));
                    });
                    let str = '';
                    str = qs.stringify({
                        opts: strings,
                    }, { arrayFormat: 'comma' });
                    history.push({
                        search: str,
                    });
                    setVisibility(false);
                }) }, "Apply Changes")),
        });
        return () => {
            useModal({
                rightButton: null,
            });
        };
    }, [startDate, endDate, preset, dataSets]);
    (0, react_1.useEffect)(() => {
        const opts = qs.parse(history.location.search);
        const array = [];
        if (opts.opts) {
            const datasets = opts['opts'].split(',');
            const names = {};
            datasets.forEach((data, index) => {
                const set = qs.parse(data);
                if (set['tags']) {
                    if (typeof set['tags'] == 'string') {
                        set['tags'] = [parseInt(set.tags)];
                    }
                    else if (typeof set['tags'] == 'object') {
                        set['tags'] = set['tags'].map(tag => {
                            return parseInt(tag);
                        });
                    }
                }
                if (set['tags_exclude']) {
                    if (typeof set['tags_exclude'] == 'string') {
                        set['tags_exclude'] = [parseInt(set.tags_exclude)];
                    }
                    else if (typeof set['tags_exclude'] == 'object') {
                        set['tags_exclude'] = set['tags_exclude'].map(tag => {
                            return parseInt(tag);
                        });
                    }
                }
                if (set['category_ids']) {
                    if (typeof set['category_ids'] == 'string') {
                        set['category_ids'] = [parseInt(set.category_ids)];
                    }
                    else if (typeof set['category_ids'] == 'object') {
                        set['category_ids'] = set['category_ids'].map(id => {
                            return parseInt(id);
                        });
                    }
                }
                names[index] = set['name'];
                delete set['name'];
                if (set['end_date']) {
                    setPreset('Custom');
                    setEndDate(set['end_date']);
                }
                if (set['start_date']) {
                    setPreset('Custom');
                    setStartDate(set['start_date']);
                }
                if (set['date_range']) {
                    setPreset(set['date_range']);
                    setEndDate(null);
                    setStartDate(null);
                }
                delete set['end_date'];
                delete set['start_date'];
                _queryTool.allDataSets.current[index] = set;
                array.push(new Date().getTime());
            });
        }
        setDataSets(array);
        setReady(true);
    }, []);
    return (React.createElement(React.Fragment, null,
        ready &&
            dataSets.map((dataset, index) => {
                if (!!dataset) {
                    return (React.createElement(DataSetForm_1.default, { key: `data-set-${index}`, index: index, hideDelete: false, removeDataSet: () => {
                            const currentDataSets = [...dataSets];
                            currentDataSets[index] = null;
                            setDataSets(currentDataSets);
                        } }));
                }
            }),
        React.createElement("div", { className: "mb-1rem" },
            React.createElement(semantic_ui_react_1.Button, { size: "mini", color: "orange", icon: true, basic: true, fluid: true, labelPosition: "left", onClick: () => {
                    setDataSets([...dataSets, new Date().getTime()]);
                } },
                React.createElement(semantic_ui_react_1.Icon, { name: "add" }),
                "Add Another Set"))));
};
exports.default = DataSetModal;
