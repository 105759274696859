"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
const Moment = require("moment");
const semantic_ui_react_2 = require("semantic-ui-react");
// Actions
const format_1 = require("@helpers/format");
const Budget_1 = require("@components/TaskCard/Budget");
const UserProvider_1 = require("@/providers/UserProvider");
const Overview = ({ currentPeriod, budgetedTotal, spentTotal, incomeTotal, isLoading, budgetStatus, remainingRecurring, }) => {
    var _a;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    return (React.createElement(React.Fragment, null,
        React.createElement(Budget_1.default, { budgetStatus: budgetStatus }),
        React.createElement(semantic_ui_react_2.Card, null,
            React.createElement(semantic_ui_react_2.Card.Content, null,
                React.createElement(semantic_ui_react_2.Card.Header, null,
                    Moment(currentPeriod, 'YYYY-MM-DD').format('MMMM'),
                    " Overview")),
            isLoading ? (React.createElement(semantic_ui_react_2.Card.Content, null,
                React.createElement(semantic_ui_react_2.Loader, { active: true, size: "tiny", inline: "centered" }))) : (React.createElement(React.Fragment, null,
                React.createElement(semantic_ui_react_2.Card.Content, null,
                    React.createElement("p", { className: "label-style mb-05rem center-align" },
                        "Income",
                        ' ',
                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", color: "grey" }) },
                            React.createElement("p", null,
                                React.createElement("b", null, "Current income"),
                                " - This is how much you've earned/received so far this month, including recurring income."),
                            React.createElement("p", null,
                                React.createElement("b", null, "Remaining income"),
                                " - This is how much recurring income is left to receive this month, in total. You may want to use this to guide your budgeting."),
                            React.createElement("p", null,
                                React.createElement("b", null, "Total expected income"),
                                " - This is the sum of current income and remaining income and represents an estimation of your total expected income this month."),
                            React.createElement("p", null,
                                React.createElement("b", null, "You budgeted"),
                                " - This represents the sum of how much you've budgeted for categories listed as \"treat as income\"."))),
                    React.createElement("div", { className: `card-content-wrapper` },
                        React.createElement("div", { className: "card-content no-wrap" },
                            React.createElement("span", { className: "card-text ellipsis" },
                                React.createElement(react_router_dom_1.Link, { to: {
                                        pathname: `/transactions/${Moment(currentPeriod, 'YYYY-MM-DD').format('YYYY/MM')}`,
                                        search: `?is_income=true&exclude_from_totals=false`,
                                    }, className: `link clickable` }, "Current income")),
                            React.createElement("span", { className: "card-number" }, (0, format_1.toPrice)(-1 * incomeTotal, _user.primaryCurrency)))),
                    !!(remainingRecurring === null || remainingRecurring === void 0 ? void 0 : remainingRecurring.income) && (React.createElement("div", { className: `card-content-wrapper` },
                        React.createElement("div", { className: "card-content no-wrap" },
                            React.createElement("span", { className: "card-text ellipsis" },
                                React.createElement(react_router_dom_1.Link, { to: {
                                        pathname: `/recurring/${Moment(currentPeriod, 'YYYY-MM-DD').format('YYYY/MM')}`,
                                    }, className: `link clickable` }, "Remaining income")),
                            React.createElement("span", { className: "card-number" }, (0, format_1.toPrice)((remainingRecurring === null || remainingRecurring === void 0 ? void 0 : remainingRecurring.income) * -1, // Need an "absolute"
                            _user.primaryCurrency))))),
                    React.createElement("p", { className: "divider" }),
                    !!incomeTotal && !!(remainingRecurring === null || remainingRecurring === void 0 ? void 0 : remainingRecurring.income) && (React.createElement("div", { className: `card-content-wrapper` },
                        React.createElement("div", { className: "card-content no-wrap" },
                            React.createElement("span", { className: "card-text ellipsis" }, "Total expected"),
                            React.createElement("span", { className: "card-number" }, (0, format_1.toPrice)(-1 * incomeTotal + -1 * (remainingRecurring === null || remainingRecurring === void 0 ? void 0 : remainingRecurring.income), _user.primaryCurrency))))),
                    React.createElement("div", { className: `card-content-wrapper` },
                        React.createElement("div", { className: "card-content no-wrap" },
                            React.createElement("span", { className: "card-text ellipsis" }, "You budgeted"),
                            React.createElement("span", { className: "card-number color--green" }, (budgetedTotal === null || budgetedTotal === void 0 ? void 0 : budgetedTotal.income)
                                ? (0, format_1.toPrice)(budgetedTotal === null || budgetedTotal === void 0 ? void 0 : budgetedTotal.income, _user.primaryCurrency)
                                : '-')))),
                React.createElement(semantic_ui_react_2.Card.Content, null,
                    React.createElement("p", { className: "label-style mb-05rem center-align" },
                        "Expenses",
                        ' ',
                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", color: "grey" }) },
                            React.createElement("p", null,
                                React.createElement("b", null, "Current expenses"),
                                " - This is how much you've spent so far this month, including recurring expenses."),
                            React.createElement("p", null,
                                React.createElement("b", null, "Remaining expenses"),
                                " - This is how much recurring expense is left to spend this month, in total. You may want to use this to guide your budgeting."),
                            React.createElement("p", null,
                                React.createElement("b", null, "Total expected expenses"),
                                " - This is the sum of current expenses and remaining expenses and represents an estimation of your total expected expenses this month."),
                            React.createElement("p", null,
                                React.createElement("b", null, "You budgeted"),
                                " - This represents the sum of how much you've budgeted for categories not listed as \"treat as income\"."))),
                    React.createElement("div", { className: `card-content-wrapper` },
                        React.createElement("div", { className: "card-content no-wrap" },
                            React.createElement("span", { className: "card-text ellipsis" },
                                React.createElement(react_router_dom_1.Link, { to: {
                                        pathname: `/transactions/${Moment(currentPeriod, 'YYYY-MM-DD').format('YYYY/MM')}`,
                                        search: '?is_income=false&exclude_from_totals=false',
                                    }, className: `link clickable` }, "Current expenses")),
                            React.createElement("span", { className: "card-number" }, (0, format_1.toPrice)(spentTotal, _user.primaryCurrency)))),
                    !!(remainingRecurring === null || remainingRecurring === void 0 ? void 0 : remainingRecurring.expense) && (React.createElement("div", { className: `card-content-wrapper` },
                        React.createElement("div", { className: "card-content no-wrap" },
                            React.createElement("span", { className: "card-text ellipsis" },
                                React.createElement(react_router_dom_1.Link, { to: {
                                        pathname: `/recurring/${Moment(currentPeriod, 'YYYY-MM-DD').format('YYYY/MM')}`,
                                    }, className: `link clickable` }, "Remaining expenses")),
                            React.createElement("span", { className: "card-number" }, (0, format_1.toPrice)((_a = remainingRecurring === null || remainingRecurring === void 0 ? void 0 : remainingRecurring.expense) !== null && _a !== void 0 ? _a : null, _user.primaryCurrency))))),
                    React.createElement("p", { className: "divider" }),
                    !!spentTotal && !!(remainingRecurring === null || remainingRecurring === void 0 ? void 0 : remainingRecurring.expense) && (React.createElement("div", { className: `card-content-wrapper` },
                        React.createElement("div", { className: "card-content no-wrap" },
                            React.createElement("span", { className: "card-text ellipsis" }, "Total expected"),
                            React.createElement("span", { className: "card-number" }, (0, format_1.toPrice)(spentTotal + (remainingRecurring === null || remainingRecurring === void 0 ? void 0 : remainingRecurring.expense), _user.primaryCurrency))))),
                    React.createElement("div", { className: `card-content-wrapper` },
                        React.createElement("div", { className: "card-content no-wrap" },
                            React.createElement("span", { className: "card-text ellipsis" }, "You budgeted"),
                            React.createElement("span", { className: "card-number" }, (budgetedTotal === null || budgetedTotal === void 0 ? void 0 : budgetedTotal.expense)
                                ? (0, format_1.toPrice)(budgetedTotal === null || budgetedTotal === void 0 ? void 0 : budgetedTotal.expense, _user.primaryCurrency)
                                : '-')))),
                !!(budgetedTotal === null || budgetedTotal === void 0 ? void 0 : budgetedTotal.income) && !!(budgetedTotal === null || budgetedTotal === void 0 ? void 0 : budgetedTotal.expense) && (React.createElement(semantic_ui_react_2.Card.Content, null,
                    React.createElement("div", { className: `card-content-wrapper footer` },
                        React.createElement("div", { className: "card-content no-wrap" },
                            React.createElement("span", { className: "card-text ellipsis font--bold" }, "Net Budgeted"),
                            React.createElement("span", { className: `card-number ${(budgetedTotal === null || budgetedTotal === void 0 ? void 0 : budgetedTotal.income) > (budgetedTotal === null || budgetedTotal === void 0 ? void 0 : budgetedTotal.expense)
                                    ? 'color--green'
                                    : ''}` }, (0, format_1.toPrice)((budgetedTotal === null || budgetedTotal === void 0 ? void 0 : budgetedTotal.income) - (budgetedTotal === null || budgetedTotal === void 0 ? void 0 : budgetedTotal.expense), _user.primaryCurrency)))))))))));
};
exports.default = Overview;
