"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const UserProvider_1 = require("@providers/UserProvider");
const tooltips2_1 = require("@helpers/tooltips2");
const react_1 = require("react");
const react_chartjs_2_1 = require("react-chartjs-2");
const format_1 = require("@helpers/format");
const colors_1 = require("@helpers/colors");
const charts_1 = require("@helpers/charts");
const chart_js_1 = require("chart.js");
const StackedBar = ({ labels, data, dataSetNames, timeGranularity, normalizeIncome, labelCallback, }) => {
    const [chartRef, setChartRef] = (0, react_1.useState)(null);
    const [chartDataset, setChartDataset] = (0, react_1.useState)([]);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    chart_js_1.Chart.register(...chart_js_1.registerables);
    (0, react_1.useEffect)(() => {
        if (!labels || !data || data.length == 0)
            return;
        if (data.length > 1) {
            handleMultipleDatasets();
        }
        else {
            handleSingleDataset();
        }
    }, [labels, data]);
    const handleMultipleDatasets = () => {
        let datasets = [];
        const colors = [];
        let colorIndex = 0;
        data.forEach((dataset, datasetIndex) => {
            dataset.forEach((o, index) => {
                if (!o.hidden && !o.group_id) {
                    const mappedData = [];
                    const mappedCounts = [];
                    let color;
                    labels.forEach(time => {
                        mappedData.push(o.data[time] || 0);
                        mappedCounts.push(o.counts[time] || 0);
                    });
                    if (colors.hasOwnProperty(o.id)) {
                        color = colors[o.id];
                    }
                    else {
                        color = colors_1.CHART_COLORS[colorIndex++ % colors_1.CHART_COLORS.length];
                        colors[o.id] = color;
                    }
                    // If it's hidden, we still want to calculate the proper colors
                    if (!o.hidden) {
                        datasets.push({
                            id: o.id,
                            stack: dataSetNames[datasetIndex],
                            barThickness: 10,
                            label: `${o.group_name ? o.group_name + '/' : ''}${o.name} (${dataSetNames[datasetIndex]})`,
                            backgroundColor: color,
                            hoverBackgroundColor: color,
                            isIncome: o.is_income,
                            counts: mappedCounts,
                            data: normalizeIncome && o.is_income
                                ? mappedData.map((m) => -1 * m)
                                : mappedData,
                        });
                    }
                }
            });
        });
        setChartDataset(datasets);
    };
    const handleSingleDataset = () => {
        const set = data[0]
            .map((o, index) => {
            if (o.hidden || o.group_id) {
                return -1;
            }
            const mappedData = [];
            const mappedCounts = [];
            labels.forEach(time => {
                mappedData.push(o.data[time] || 0);
                mappedCounts.push(o.counts[time] || 0);
            });
            return {
                id: o.id,
                label: `${o.group_name ? o.group_name + '/' : ''}${o.name}`,
                barThickness: 20,
                borderColor: colors_1.CHART_COLORS[index % colors_1.CHART_COLORS.length],
                backgroundColor: colors_1.CHART_COLORS[index % colors_1.CHART_COLORS.length],
                hoverBackgroundColor: colors_1.CHART_COLORS[index % colors_1.CHART_COLORS.length],
                isIncome: o.is_income,
                counts: mappedCounts,
                data: normalizeIncome && o.is_income
                    ? mappedData.map((m) => -1 * m)
                    : mappedData,
            };
        })
            .filter(o => o !== -1);
        setChartDataset(set);
    };
    return (React.createElement(react_chartjs_2_1.Bar, { ref: reference => {
            setChartRef(reference);
        }, data: {
            labels,
            datasets: chartDataset,
        }, options: {
            indexAxis: 'y',
            plugins: {
                tooltip: {
                    mode: 'point',
                    intersect: false,
                    enabled: false,
                    callbacks: {
                        label: labelCallback,
                    },
                    external: function (context) {
                        return (0, tooltips2_1.getTooltip)(context, timeGranularity == 'month'
                            ? _user.getMonthYearFormat()
                            : timeGranularity == 'year'
                                ? 'YYYY'
                                : _user.getMonthDayFormat());
                    },
                },
                title: {
                    display: false,
                },
                legend: {
                    display: false,
                },
            },
            hover: {
                mode: 'nearest',
                intersect: false,
            },
            scales: {
                x: {
                    stacked: true,
                    border: {
                        display: false,
                    },
                    grid: Object.assign({}, (0, charts_1.getGridLineColors)()),
                    ticks: Object.assign(Object.assign({}, (0, charts_1.getTickColors)()), { callback: function (value, index, values) {
                            if (_user.settings['show_debits_as_negative']) {
                                // Flip axis
                                return (0, format_1.toPrice)(Number(value) * -1, _user.primaryCurrency);
                            }
                            return (0, format_1.toPrice)(value, _user.primaryCurrency);
                        } }),
                },
                y: {
                    stacked: true,
                    border: {
                        display: false,
                    },
                    grid: Object.assign({}, (0, charts_1.getGridLineColors)()),
                    ticks: Object.assign(Object.assign({}, (0, charts_1.getTickColors)()), { callback: function (value, index, values) {
                            return Moment(labels[index], 'YYYY-MM-DD').format(timeGranularity == 'month'
                                ? _user.getMonthYearFormat()
                                : timeGranularity == 'year'
                                    ? 'YYYY'
                                    : _user.getMonthDayFormat());
                        } }),
                },
            },
        } }));
};
exports.default = StackedBar;
