"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_1 = require("react");
const format_1 = require("@helpers/format");
const UserProvider_1 = require("@providers/UserProvider");
const Round = ({ round, setRound }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    return (React.createElement(semantic_ui_react_1.Popup, { position: 'bottom right', on: "hover", hoverable: true, trigger: React.createElement(semantic_ui_react_1.Button, { text: "Settings", icon: "cog", size: "small", basic: true, color: "orange" }), content: React.createElement("div", null,
            React.createElement("div", { className: "mb-05rem" },
                "Round to the nearest",
                React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-05rem inline-breakdown-dropdown", inline: true, defaultValue: round, onChange: (e, { value }) => {
                        setRound(value);
                        localStorage.setItem('_lm_budget_round', value.toString());
                    }, options: [
                        {
                            key: '10',
                            value: 10,
                            text: (0, format_1.toPrice)(10, _user.primaryCurrency, 10),
                        },
                        {
                            key: '1',
                            value: 1,
                            text: (0, format_1.toPrice)(1, _user.primaryCurrency, 1),
                        },
                        {
                            key: '0.01',
                            value: 0.01,
                            text: (0, format_1.toPrice)(0.01, _user.primaryCurrency, 0.01),
                        },
                    ] }))) }));
};
exports.default = Round;
