"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportHelper = void 0;
const Moment = require("moment");
class ReportHelper {
    transformData(opts, utils) {
        if (opts.groupBy == 'by_category') {
            return this._transformByCategory(opts, utils);
        }
        else if (opts.groupBy == 'by_tag') {
            return this._transformByTag(opts, utils);
        }
        else if (opts.groupBy == 'by_account') {
            return this._transformByAccount(opts, utils);
        }
        else if (opts.groupBy == 'by_recurring') {
            return this._transformByRecurring(opts, utils);
        }
        else if (opts.groupBy == 'by_payee') {
            return this._transformByPayee(opts, utils);
        }
        else if (opts.groupBy == 'by_totals') {
            return this._transformByTotals(opts, utils);
        }
    }
    _transformByTotals(opts, utils) {
        const { transactions, timeArray, timeGranularity, showExclude, ungroup, } = opts;
        const emptyTimeObj = {};
        const totalByTime = {};
        const countByTime = {};
        timeArray.map(key => {
            emptyTimeObj[key] = 0;
            totalByTime[key] = 0;
            countByTime[key] = 0;
        });
        let total = 0;
        let count = 0;
        let timeData = {};
        transactions.forEach(tx => {
            //true
            if ((!tx.exclude_from_totals || showExclude) &&
                ((tx.in_group && ungroup) ||
                    (tx.is_group && !ungroup) ||
                    (!tx.is_group && !tx.in_group))) {
                let timeKey;
                if (timeGranularity == 'year') {
                    timeKey =
                        Moment(tx.formatted_date, 'YYYY-MM-DD').format('YYYY') + '-01-01';
                }
                else if (timeGranularity == 'month') {
                    timeKey =
                        Moment(tx.formatted_date, 'YYYY-MM-DD').format('YYYY-MM') + '-01';
                }
                else if (timeGranularity == 'week') {
                    timeKey = Moment(tx.formatted_date, 'YYYY-MM-DD')
                        .startOf('week')
                        .format('YYYY-MM-DD');
                }
                else if (timeGranularity == 'day') {
                    timeKey = tx.formatted_date;
                }
                totalByTime[timeKey] += tx.to_base;
                countByTime[timeKey]++;
                if (timeData.hasOwnProperty(timeKey)) {
                    timeData[timeKey] += tx.to_base;
                }
                else {
                    timeData[timeKey] = tx.to_base;
                }
                total += tx.to_base;
                count++;
            }
        });
        return {
            data: [
                {
                    id: 999,
                    name: '', // Should get picked up by dataSetNames
                    data: timeData,
                    counts: countByTime,
                    has_children: false,
                    group_id: null,
                    group_name: null,
                    total,
                    count,
                },
            ],
            totalByTime,
            countByTime,
        };
    }
    _transformByPayee(opts, utils) {
        const { transactions, timeArray, timeGranularity, showExclude, ungroup, } = opts;
        const emptyTimeObj = {};
        const totalByTime = {};
        const countByTime = {};
        timeArray.map(key => {
            emptyTimeObj[key] = 0;
            totalByTime[key] = 0;
            countByTime[key] = 0;
        });
        const data = {
            Unassigned: {
                index: 999,
                has_children: false,
                group_id: null,
                group_name: null,
                id: null,
                name: 'Cash',
                data: Object.assign({}, emptyTimeObj),
                counts: Object.assign({}, emptyTimeObj),
                total: 0,
                count: 0,
            },
        };
        try {
            const grouped = {};
            transactions.forEach(tx => {
                if (tx.display_name) {
                    this.pushOrCreate(grouped, tx.display_name, tx);
                }
                else {
                    this.pushOrCreate(grouped, `Unassigned`, tx);
                }
            });
            const keys = Object.keys(grouped);
            keys.forEach(key => {
                let total = 0;
                let count = 0;
                let timeData = {};
                let countData = {};
                grouped[key].forEach(tx => {
                    if ((!tx.exclude_from_totals || showExclude) &&
                        ((tx.in_group && ungroup) ||
                            (tx.is_group && !ungroup) ||
                            (!tx.is_group && !tx.in_group))) {
                        let timeKey;
                        if (timeGranularity == 'year') {
                            timeKey =
                                Moment(tx.formatted_date, 'YYYY-MM-DD').format('YYYY') +
                                    '-01-01';
                        }
                        else if (timeGranularity == 'month') {
                            timeKey =
                                Moment(tx.formatted_date, 'YYYY-MM-DD').format('YYYY-MM') +
                                    '-01';
                        }
                        else if (timeGranularity == 'week') {
                            timeKey = Moment(tx.formatted_date, 'YYYY-MM-DD')
                                .startOf('week')
                                .format('YYYY-MM-DD');
                        }
                        else if (timeGranularity == 'day') {
                            timeKey = tx.formatted_date;
                        }
                        totalByTime[timeKey] += tx.to_base;
                        countByTime[timeKey]++;
                        if (timeData.hasOwnProperty(timeKey)) {
                            timeData[timeKey] += tx.to_base;
                            countData[timeKey]++;
                        }
                        else {
                            timeData[timeKey] = tx.to_base;
                            countData[timeKey] = 1;
                        }
                        total += tx.to_base;
                        count++;
                    }
                });
                data[key] = {
                    id: key,
                    name: key,
                    data: timeData,
                    counts: countData,
                    has_children: false,
                    group_id: null,
                    group_name: null,
                    total,
                    count,
                };
            });
        }
        catch (e) {
            console.log('Error in _transformByPayee', e);
        }
        return {
            data: Object.values(data)
                .filter(o => {
                return o.count > 0;
            })
                .sort((a, b) => {
                if (!a.count)
                    return 1;
                if (!b.count)
                    return -1;
                return b.count - a.count;
            })
                .slice(0, 100), // Only take top 100
            totalByTime,
            countByTime,
        };
    }
    _transformByAccount(opts, utils) {
        const { transactions, timeArray, timeGranularity, showExclude, ungroup, secondaryBreakdown, } = opts;
        const emptyTimeObj = {};
        const totalByTime = {};
        const countByTime = {};
        timeArray.map(key => {
            emptyTimeObj[key] = 0;
            totalByTime[key] = 0;
            countByTime[key] = 0;
        });
        const data = {
            cash: {
                index: 999,
                has_children: false,
                group_id: null,
                group_name: null,
                id: 'cash',
                name: 'Cash',
                data: Object.assign({}, emptyTimeObj),
                counts: Object.assign({}, emptyTimeObj),
                total: 0,
                count: 0,
            },
        };
        try {
            const grouped = {};
            transactions.forEach(tx => {
                if (tx.asset_id) {
                    this.pushOrCreate(grouped, `asset_${tx.asset_id}`, tx);
                }
                else if (tx.plaid_account_id) {
                    this.pushOrCreate(grouped, `plaid_${tx.plaid_account_id}`, tx);
                }
                else {
                    this.pushOrCreate(grouped, `cash`, tx);
                }
            });
            const keys = Object.keys(grouped);
            keys.forEach(key => {
                let total = 0;
                let count = 0;
                let timeData = {};
                let countData = {};
                let subdata = {};
                grouped[key].forEach(tx => {
                    if ((!tx.exclude_from_totals || showExclude) &&
                        ((tx.in_group && ungroup) ||
                            (tx.is_group && !ungroup) ||
                            (!tx.is_group && !tx.in_group))) {
                        let timeKey;
                        if (timeGranularity == 'year') {
                            timeKey =
                                Moment(tx.formatted_date, 'YYYY-MM-DD').format('YYYY') +
                                    '-01-01';
                        }
                        else if (timeGranularity == 'month') {
                            timeKey =
                                Moment(tx.formatted_date, 'YYYY-MM-DD').format('YYYY-MM') +
                                    '-01';
                        }
                        else if (timeGranularity == 'week') {
                            timeKey = Moment(tx.formatted_date, 'YYYY-MM-DD')
                                .startOf('week')
                                .format('YYYY-MM-DD');
                        }
                        else if (timeGranularity == 'day') {
                            timeKey = tx.formatted_date;
                        }
                        totalByTime[timeKey] += tx.to_base;
                        countByTime[timeKey]++;
                        if (timeData.hasOwnProperty(timeKey)) {
                            timeData[timeKey] += tx.to_base;
                            countData[timeKey]++;
                        }
                        else {
                            timeData[timeKey] = tx.to_base;
                            countData[timeKey] = 1;
                        }
                        total += tx.to_base;
                        count++;
                        this.buildSubdata(subdata, key, secondaryBreakdown, timeKey, tx, emptyTimeObj, utils);
                    }
                });
                let displayName;
                if (key.indexOf('asset') > -1) {
                    displayName = utils.getAssetName(key.substring(6));
                }
                else if (key.indexOf('plaid') > -1) {
                    displayName = utils.getPlaidAccountName(key.substring(6));
                }
                else if (key == 'cash') {
                    displayName = 'Cash';
                }
                data[key] = {
                    id: key,
                    name: displayName,
                    data: timeData,
                    counts: countData,
                    has_children: false,
                    group_id: null,
                    group_name: null,
                    total,
                    count,
                    subdata,
                };
            });
        }
        catch (e) {
            console.log('Error in _transformByAccount', e);
        }
        return {
            data: Object.values(data).filter(o => {
                return o.count > 0;
            }),
            totalByTime,
            countByTime,
        };
    }
    _transformByRecurring(opts, utils) {
        const { transactions, timeArray, timeGranularity, showExclude, ungroup, } = opts;
        const emptyTimeObj = {};
        const totalByTime = {};
        const countByTime = {};
        timeArray.map(key => {
            emptyTimeObj[key] = 0;
            totalByTime[key] = 0;
            countByTime[key] = 0;
        });
        const data = {
            'Not recurring': {
                index: 999,
                has_children: false,
                group_id: null,
                group_name: null,
                id: null,
                name: 'Cash',
                data: Object.assign({}, emptyTimeObj),
                counts: Object.assign({}, emptyTimeObj),
                total: 0,
                count: 0,
            },
        };
        try {
            const grouped = {};
            transactions.forEach(tx => {
                if (tx.recurring_id && tx.recurring_type == 'cleared') {
                    this.pushOrCreate(grouped, tx.recurring_id, tx);
                }
                else {
                    this.pushOrCreate(grouped, `Not recurring`, tx);
                }
            });
            const keys = Object.keys(grouped);
            keys.forEach(key => {
                let total = 0;
                let count = 0;
                let timeData = {};
                let countData = {};
                let displayName = '';
                grouped[key].forEach(tx => {
                    if ((!tx.exclude_from_totals || showExclude) &&
                        ((tx.in_group && ungroup) ||
                            (tx.is_group && !ungroup) ||
                            (!tx.is_group && !tx.in_group))) {
                        let timeKey;
                        if (timeGranularity == 'year') {
                            timeKey =
                                Moment(tx.formatted_date, 'YYYY-MM-DD').format('YYYY') +
                                    '-01-01';
                        }
                        else if (timeGranularity == 'month') {
                            timeKey =
                                Moment(tx.formatted_date, 'YYYY-MM-DD').format('YYYY-MM') +
                                    '-01';
                        }
                        else if (timeGranularity == 'week') {
                            timeKey = Moment(tx.formatted_date, 'YYYY-MM-DD')
                                .startOf('week')
                                .format('YYYY-MM-DD');
                        }
                        else if (timeGranularity == 'day') {
                            timeKey = tx.formatted_date;
                        }
                        totalByTime[timeKey] += tx.to_base;
                        countByTime[timeKey]++;
                        if (timeData.hasOwnProperty(timeKey)) {
                            timeData[timeKey] += tx.to_base;
                            countData[timeKey]++;
                        }
                        else {
                            timeData[timeKey] = tx.to_base;
                            countData[timeKey] = 1;
                        }
                        total += tx.to_base;
                        count++;
                        displayName = tx.display_name;
                    }
                });
                data[key] = {
                    id: key,
                    name: key == 'Not recurring' ? 'Not recurring' : displayName,
                    data: timeData,
                    counts: countData,
                    has_children: false,
                    group_id: null,
                    group_name: null,
                    total,
                    count,
                };
            });
        }
        catch (e) {
            console.log('Error in _transformByRecurring', e);
        }
        return {
            data: Object.values(data)
                .filter(o => {
                return o.count > 0;
            })
                .sort((a, b) => {
                if (!a.count)
                    return 1;
                if (!b.count)
                    return -1;
                return b.count - a.count;
            }),
            totalByTime,
            countByTime,
        };
    }
    _transformByTag(opts, utils) {
        const { transactions, timeArray, timeGranularity, showExclude, ungroup, secondaryBreakdown, } = opts;
        const emptyTimeObj = {};
        const totalByTime = {};
        const countByTime = {};
        timeArray.map(key => {
            emptyTimeObj[key] = 0;
            totalByTime[key] = 0;
            countByTime[key] = 0;
        });
        const data = {
            Untagged: {
                index: 999,
                has_children: false,
                group_id: null,
                group_name: null,
                id: null,
                name: 'Untagged',
                data: Object.assign({}, emptyTimeObj),
                counts: Object.assign({}, emptyTimeObj),
                total: 0,
                count: 0,
            },
        };
        try {
            const grouped = {};
            transactions.forEach(tx => {
                var _a;
                if (((_a = tx.tags) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    tx.tags.forEach(tag => {
                        this.pushOrCreate(grouped, tag.id, tx);
                    });
                }
                else {
                    this.pushOrCreate(grouped, -1, tx);
                }
            });
            const tagKeys = Object.keys(grouped);
            tagKeys.forEach(tag => {
                let total = 0;
                let count = 0;
                let timeData = {};
                let countData = {};
                let subdata = {};
                grouped[tag].forEach(tx => {
                    if ((!tx.exclude_from_totals || showExclude) &&
                        ((tx.in_group && ungroup) ||
                            (tx.is_group && !ungroup) ||
                            (!tx.is_group && !tx.in_group))) {
                        let timeKey;
                        if (timeGranularity == 'year') {
                            timeKey =
                                Moment(tx.formatted_date, 'YYYY-MM-DD').format('YYYY') +
                                    '-01-01';
                        }
                        else if (timeGranularity == 'month') {
                            timeKey =
                                Moment(tx.formatted_date, 'YYYY-MM-DD').format('YYYY-MM') +
                                    '-01';
                        }
                        else if (timeGranularity == 'week') {
                            timeKey = Moment(tx.formatted_date, 'YYYY-MM-DD')
                                .startOf('week')
                                .format('YYYY-MM-DD');
                        }
                        else if (timeGranularity == 'day') {
                            timeKey = tx.formatted_date;
                        }
                        totalByTime[timeKey] += tx.to_base;
                        countByTime[timeKey]++;
                        if (timeData.hasOwnProperty(timeKey)) {
                            timeData[timeKey] += tx.to_base;
                            countData[timeKey]++;
                        }
                        else {
                            timeData[timeKey] = tx.to_base;
                            countData[timeKey] = 1;
                        }
                        total += tx.to_base;
                        count++;
                        this.buildSubdata(subdata, tag, secondaryBreakdown, timeKey, tx, emptyTimeObj, utils);
                    }
                });
                data[tag == '-1' ? 'Untagged' : tag] = {
                    id: tag,
                    name: utils.getTagName(tag) || 'Untagged',
                    data: timeData,
                    counts: countData,
                    has_children: false,
                    group_id: null,
                    group_name: null,
                    total,
                    count,
                    subdata,
                };
            });
        }
        catch (e) {
            console.log('Error in _transformByTag', e);
        }
        return {
            data: Object.values(data).filter(o => {
                return o.count > 0;
            }),
            totalByTime,
            countByTime,
        };
    }
    _transformByCategory(opts, utils) {
        const { transactions, timeArray, timeGranularity, nestedCategories, allowRecurringCategories, showExclude, ungroup, secondaryBreakdown, } = opts;
        const emptyTimeObj = {};
        const totalByTime = {};
        const countByTime = {};
        timeArray.map(key => {
            emptyTimeObj[key] = 0;
            totalByTime[key] = 0;
            countByTime[key] = 0;
        });
        const data = {
            Uncategorized: {
                index: 999,
                id: -1,
                group_id: null,
                group_name: null,
                has_children: false,
                name: 'Uncategorized',
                data: Object.assign({}, emptyTimeObj),
                counts: Object.assign({}, emptyTimeObj),
                total: 0,
                count: 0,
                is_income: false,
            },
        };
        try {
            if (!allowRecurringCategories) {
                data['Recurring'] = {
                    index: 9999,
                    id: -2,
                    group_id: null,
                    group_name: null,
                    has_children: false,
                    name: 'Recurring',
                    data: Object.assign({}, emptyTimeObj),
                    counts: Object.assign({}, emptyTimeObj),
                    subdata: {},
                    total: 0,
                    count: 0,
                    is_income: false,
                };
            }
            // Create base for data object
            let index = 0;
            nestedCategories.forEach(category => {
                data[category.id] = {
                    index: index++,
                    id: category.id,
                    group_id: null,
                    has_children: !!category.children,
                    name: category.name,
                    data: Object.assign({}, emptyTimeObj),
                    counts: Object.assign({}, emptyTimeObj),
                    subdata: {},
                    total: 0,
                    count: 0,
                    is_income: category.is_income,
                };
                if (category.children) {
                    category.children.map(child => {
                        data[child.id] = {
                            index: index++,
                            id: child.id,
                            group_id: category.id,
                            group_name: category.name,
                            has_children: false,
                            name: child.name,
                            data: Object.assign({}, emptyTimeObj),
                            counts: Object.assign({}, emptyTimeObj),
                            subdata: {},
                            total: 0,
                            count: 0,
                            is_income: category.is_income,
                        };
                    });
                }
            });
            transactions.forEach(tx => {
                if ((!tx.exclude_from_totals || showExclude) &&
                    ((tx.in_group && ungroup) ||
                        (tx.is_group && !ungroup) ||
                        (!tx.is_group && !tx.in_group))) {
                    let timeKey;
                    if (timeGranularity == 'year') {
                        timeKey =
                            Moment(tx.formatted_date, 'YYYY-MM-DD').format('YYYY') + '-01-01';
                    }
                    else if (timeGranularity == 'month') {
                        timeKey =
                            Moment(tx.formatted_date, 'YYYY-MM-DD').format('YYYY-MM') + '-01';
                    }
                    else if (timeGranularity == 'week') {
                        timeKey = Moment(tx.formatted_date, 'YYYY-MM-DD')
                            .startOf('week')
                            .format('YYYY-MM-DD');
                    }
                    else if (timeGranularity == 'day') {
                        timeKey = tx.formatted_date;
                    }
                    let subdataKey = 'Uncategorized';
                    if (!allowRecurringCategories &&
                        tx.recurring_id &&
                        tx.recurring_type == 'cleared') {
                        subdataKey = 'Recurring';
                        data['Recurring'].data[timeKey] += tx.to_base;
                        data['Recurring'].counts[timeKey]++;
                        data['Recurring'].total += tx.to_base;
                        data['Recurring'].count++;
                    }
                    else {
                        if (tx.category_group_id) {
                            data[tx.category_group_id].data[timeKey] += tx.to_base;
                            data[tx.category_group_id].counts[timeKey]++;
                            data[tx.category_group_id].total += tx.to_base;
                            data[tx.category_group_id].count++;
                        }
                        if (tx.category_id) {
                            subdataKey = tx.category_id;
                        }
                        data[subdataKey].data[timeKey] += tx.to_base;
                        data[subdataKey].counts[timeKey]++;
                        data[subdataKey].total += tx.to_base;
                        data[subdataKey].count++;
                        totalByTime[timeKey] += tx.to_base;
                        countByTime[timeKey]++;
                    }
                    this.buildSubdata(data[subdataKey].subdata, subdataKey, secondaryBreakdown, timeKey, tx, emptyTimeObj, utils);
                }
            });
        }
        catch (e) {
            console.log('Error in _transformByCategory', e);
        }
        return {
            data: Object.values(data).filter(o => {
                return o.count > 0;
            }),
            totalByTime,
            countByTime,
        };
    }
    pushOrCreate(obj, key, value) {
        if (obj.hasOwnProperty(key)) {
            obj[key].push(value);
        }
        else {
            obj[key] = [value];
        }
    }
    buildSubdata(subdata, subdataKey, secondaryBreakdown, timeKey, tx, emptyTimeObj, utils) {
        subdata = subdata || {};
        if (secondaryBreakdown == 'tag') {
            if (tx.tags) {
                tx.tags.forEach(tag => {
                    const tag_id = tag.id;
                    if (!(subdata === null || subdata === void 0 ? void 0 : subdata.hasOwnProperty(tag_id))) {
                        subdata[tag_id] = {
                            id: tag_id,
                            name: utils.getTagName(tag_id),
                            data: Object.assign({}, emptyTimeObj),
                            counts: Object.assign({}, emptyTimeObj),
                            is_tag: true,
                            is_secondary: true,
                            total: 0,
                            count: 0,
                            primaryId: subdataKey,
                        };
                    }
                    subdata[tag_id].data[timeKey] += tx.to_base;
                    subdata[tag_id].counts[timeKey]++;
                    subdata[tag_id].total += tx.to_base;
                    subdata[tag_id].count++;
                });
            }
            else {
                if (!(subdata === null || subdata === void 0 ? void 0 : subdata.hasOwnProperty('Untagged'))) {
                    subdata['Untagged'] = {
                        id: `untagged`, // ${subdataKey}-
                        name: 'Untagged',
                        data: Object.assign({}, emptyTimeObj),
                        counts: Object.assign({}, emptyTimeObj),
                        group_id: null,
                        is_tag: true,
                        is_secondary: true,
                        total: 0,
                        count: 0,
                        primaryId: subdataKey,
                    };
                }
                subdata['Untagged'].data[timeKey] += tx.to_base;
                subdata['Untagged'].counts[timeKey]++;
                subdata['Untagged'].total += tx.to_base;
                subdata['Untagged'].count++;
            }
        }
        else if (secondaryBreakdown == 'category') {
            if (tx.category_id) {
                if (!(subdata === null || subdata === void 0 ? void 0 : subdata.hasOwnProperty(tx.category_id))) {
                    subdata[tx.category_id] = {
                        id: tx.category_id,
                        name: utils.getCategoryName(tx.category_id),
                        data: Object.assign({}, emptyTimeObj),
                        counts: Object.assign({}, emptyTimeObj),
                        is_category: true,
                        is_secondary: true,
                        total: 0,
                        count: 0,
                        primaryId: subdataKey,
                    };
                }
                subdata[tx.category_id].data[timeKey] += tx.to_base;
                subdata[tx.category_id].counts[timeKey]++;
                subdata[tx.category_id].total += tx.to_base;
                subdata[tx.category_id].count++;
            }
            else {
                if (!(subdata === null || subdata === void 0 ? void 0 : subdata.hasOwnProperty('Uncategorized'))) {
                    subdata['Uncategorized'] = {
                        id: `uncategorized`, // ${subdataKey}-
                        name: 'Uncategorized',
                        data: Object.assign({}, emptyTimeObj),
                        counts: Object.assign({}, emptyTimeObj),
                        group_id: null,
                        is_category: true,
                        is_secondary: true,
                        total: 0,
                        count: 0,
                        primaryId: subdataKey,
                    };
                }
                subdata['Uncategorized'].data[timeKey] += tx.to_base;
                subdata['Uncategorized'].counts[timeKey]++;
                subdata['Uncategorized'].total += tx.to_base;
                subdata['Uncategorized'].count++;
            }
        }
    }
}
exports.ReportHelper = ReportHelper;
