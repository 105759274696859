"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const react_1 = require("react");
const LineItem_1 = require("../global/LineItem");
const transactions_1 = require("@/actions/transactions");
const format_1 = require("@/helpers/format");
const UserProvider_1 = require("@/providers/UserProvider");
const semantic_ui_react_1 = require("semantic-ui-react");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const SplitPreview = ({ parent, child }) => {
    var _a;
    const [result, setResult] = (0, react_1.useState)({});
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    (0, react_1.useEffect)(() => {
        const fetch = () => __awaiter(void 0, void 0, void 0, function* () {
            const result = yield (0, transactions_1.getTransactionSplitDetails)(parent, {});
            setResult(result);
        });
        fetch();
    }, []);
    return (React.createElement(React.Fragment, null, (result === null || result === void 0 ? void 0 : result.date) ? (React.createElement(React.Fragment, null,
        React.createElement("span", null, "This transaction is split from:"),
        React.createElement(LineItem_1.default, { inPopup: true, lineItemClass: "mb-1rem", keyClass: "no-label", keyValue: `${Moment(result === null || result === void 0 ? void 0 : result.date).format(_user.getFormat('month_day'))}: ${(result === null || result === void 0 ? void 0 : result.display_name) || (result === null || result === void 0 ? void 0 : result.payee)}`, propertyValue: (0, format_1.toPrice)(result === null || result === void 0 ? void 0 : result.amount, result === null || result === void 0 ? void 0 : result.currency) }),
        React.createElement("span", null,
            "The other transaction",
            (result === null || result === void 0 ? void 0 : result.split.length) > 2 ? 's' : '',
            " it was split into ",
            (result === null || result === void 0 ? void 0 : result.split.length) > 2 ? 'are' : 'is',
            ":"), (_a = result === null || result === void 0 ? void 0 : result.split) === null || _a === void 0 ? void 0 :
        _a.filter(o => o.id != child).map((o, index) => {
            return (React.createElement(LineItem_1.default, { key: `line-item-${index}`, inPopup: true, keyClass: "no-label", keyValue: `${Moment(o.date).format(_user.getFormat('month_day'))}: ${o.display_name || o.payee} (${_categories.getName(o.category_id)})`, propertyValue: (0, format_1.toPrice)(o.amount, o.currency) }));
        }))) : (React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "tiny" }))));
};
exports.default = SplitPreview;
