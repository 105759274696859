"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyStripeCredit = exports.getCannyToken = exports.updateNotificationSettings = exports.updateAccountPreferences = exports.getAccountPreferences = exports.verifyEmail = exports.updateUserProfile = exports.getUser = void 0;
const common_1 = require("./common");
/**
 * User
 */
const getUser = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`me`, {});
});
exports.getUser = getUser;
const updateUserProfile = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`me`, body);
});
exports.updateUserProfile = updateUserProfile;
const verifyEmail = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`me/send_verification_email`);
});
exports.verifyEmail = verifyEmail;
/**
 * Account preferences
 */
const getAccountPreferences = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`accounts/preferences`);
});
exports.getAccountPreferences = getAccountPreferences;
const updateAccountPreferences = (prefs) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`accounts/preferences`, prefs);
});
exports.updateAccountPreferences = updateAccountPreferences;
const updateNotificationSettings = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`me/notifications`, body);
});
exports.updateNotificationSettings = updateNotificationSettings;
/**
 * Canny
 */
const getCannyToken = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`me/canny_token`, {});
});
exports.getCannyToken = getCannyToken;
/**
 * Temp: Stripe Apply Credit
 */
const applyStripeCredit = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)('stripe/credit', {});
});
exports.applyStripeCredit = applyStripeCredit;
