"use strict";
/**
 *  BudgetRow.tsx
 *  A row in the transaction table.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_1 = require("@helpers/format");
const checks_1 = require("@helpers/checks");
const budget_1 = require("@helpers/budget");
const EditablePrice_1 = require("@components/elements/EditablePrice");
const react_router_dom_1 = require("react-router-dom");
const dates_1 = require("@helpers/dates");
const UserProvider_1 = require("@/providers/UserProvider");
const ModalProvider_1 = require("@/providers/ModalProvider");
const BudgetRow = ({ currentPeriod, onSave, onDelete, round, keepGroups, budget, config, thisMonthData, openTransactions, openModal, }) => {
    var _a, _b;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _modal = (0, react_1.useContext)(ModalProvider_1.ModalContext);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [difference, setDifference] = (0, react_1.useState)(null);
    const [lastDifference, setLastDifference] = (0, react_1.useState)(null);
    const [currentBudget, setCurrentBudget] = (0, react_1.useState)(budget);
    const [lastMonthData, setLastMonthData] = (0, react_1.useState)(null);
    const [expand, setExpand] = (0, react_1.useState)(false);
    const [averageSpend, setAverageSpend] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        if (!budget)
            return;
        setCurrentBudget(budget);
        const lastMonth = (0, dates_1.getPreviousMonth)(currentPeriod);
        if (budget.data.hasOwnProperty(lastMonth)) {
            // Last month's data won't be changing in this view
            setLastMonthData(budget.data[lastMonth]);
        }
        const averageDates = Object.keys(budget.data).filter(date => {
            return (date !== currentPeriod &&
                budget.data[date].hasOwnProperty('spending_to_base') &&
                budget.data[date].spending_to_base !== null);
        });
        setAverageSpend(averageDates.length > 0
            ? averageDates.reduce((acc, cur) => {
                return (acc +
                    budget.data[cur].spending_to_base * (budget.is_income ? -1 : 1));
            }, 0) / averageDates.length
            : null);
        // Calculate this month's difference
        setDifference((0, budget_1.calculateDifference)(thisMonthData, round, currentBudget.is_income));
        setLastDifference((0, budget_1.calculateDifference)(budget.data[lastMonth], round, currentBudget.is_income));
    }, [budget, thisMonthData, currentPeriod, round]);
    return (React.createElement(React.Fragment, null,
        React.createElement(semantic_ui_react_1.Table.Row, { className: "hover-toggle" },
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "padding" },
                React.createElement("div", { className: "flex--space-between" },
                    React.createElement("div", { className: "flex--align-center ellipsis" },
                        keepGroups && currentBudget.group_id && (React.createElement("span", { className: "hierarchy-line-icon-3" })),
                        currentBudget.category_name,
                        !keepGroups && currentBudget.group_id && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", trigger: React.createElement("span", { className: "in-category-group-icon ml-05rem" }) },
                            "This is part of a category group (",
                            currentBudget.category_group_name,
                            ")")),
                        !keepGroups && currentBudget.is_group && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", trigger: React.createElement("span", { className: "category-group-icon ml-05rem" }) }, "This is a category group")),
                        currentBudget.recurring && (React.createElement(semantic_ui_react_1.Popup, { hoverable: true, trigger: React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", name: "info circle", style: { position: 'relative', top: '-2px' } }) },
                            React.createElement("div", { className: "mb-1rem" },
                                React.createElement("b", null,
                                    currentBudget.category_name,
                                    " recurring",
                                    ' ',
                                    currentBudget.is_income ? 'income' : 'expense',
                                    currentBudget.recurring.list.length > 1 ? 's' : '',
                                    ' ',
                                    "expected this month:")),
                            currentBudget.recurring.list.map((exp, index) => {
                                return (React.createElement("div", { className: "flex--space-between", key: `recurring-list-${currentBudget.category_id}-${index}` },
                                    React.createElement("span", { className: "mr-1rem" }, exp.payee),
                                    ' ',
                                    React.createElement("span", { className: "monospace" }, (0, format_1.toPrice)(exp.amount * (currentBudget.is_income ? -1 : 1), exp.currency))));
                            }),
                            React.createElement("p", { className: "mt-1rem" },
                                "Be sure to pad your budget by",
                                ' ',
                                React.createElement("b", { className: "monospace" }, (0, format_1.toPrice)(Math.abs(currentBudget.recurring.list.reduce((acc, cur) => {
                                    return acc + cur.to_base;
                                }, 0)), _user.primaryCurrency))),
                            React.createElement(react_router_dom_1.Link, { to: {
                                    pathname: `/recurring/${Moment(currentPeriod).format('YYYY/MM')}`,
                                }, className: `link clickable mt-1rem` }, "View recurring expenses")))),
                    React.createElement("div", null,
                        config && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", mouseEnterDelay: 500, trigger: React.createElement("span", { className: `${(config === null || config === void 0 ? void 0 : config.auto_suggest) ? 'automated-icon' : ''} mr-1rem`, onClick: () => {
                                    _modal.data.current.budget = currentBudget;
                                    _modal.data.current.period = currentPeriod;
                                    openModal();
                                } }) }, "Budget suggestions are on for this category. Click to configure.")),
                        Object.keys(currentBudget.data).filter(o => {
                            return o.split('+').length == 2;
                        }).length > 0 && (React.createElement(semantic_ui_react_1.Icon, { name: expand ? 'caret up' : 'caret down', className: "mr-1rem clickable", onClick: () => {
                                setExpand(!expand);
                            } }))))),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "hover-toggle right-align" }, ((config === null || config === void 0 ? void 0 : config.auto_suggest) == 'spent' ||
                (config === null || config === void 0 ? void 0 : config.auto_suggest) == 'budgeted') &&
                (0, checks_1.isNull)(thisMonthData === null || thisMonthData === void 0 ? void 0 : thisMonthData.budget_amount) &&
                ((0, checks_1.isNull)(lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.budget_amount) || (0, checks_1.isNull)(lastMonthData)) ? (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", mouseEnterDelay: 500, trigger: React.createElement(semantic_ui_react_1.Icon, { className: "color--grey", name: "exclamation triangle", fitted: true }) },
                "We cannot display a suggested budget because you are missing",
                ' ',
                config.auto_suggest == 'spent'
                    ? 'spending data'
                    : config.auto_suggest == 'budgeted'
                        ? 'a budget'
                        : '',
                "from the previous period.")) : (thisMonthData === null || thisMonthData === void 0 ? void 0 : thisMonthData.is_automated) ? (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", mouseEnterDelay: 500, trigger: React.createElement("div", { className: "clickable", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setIsLoading(true);
                        yield onSave(currentBudget.category_id, {
                            amount: (thisMonthData === null || thisMonthData === void 0 ? void 0 : thisMonthData.is_automated)
                                ? (0, format_1.roundTo)(thisMonthData === null || thisMonthData === void 0 ? void 0 : thisMonthData.budget_amount, round)
                                : thisMonthData === null || thisMonthData === void 0 ? void 0 : thisMonthData.budget_amount,
                            currency: thisMonthData === null || thisMonthData === void 0 ? void 0 : thisMonthData.budget_currency,
                        });
                        setIsLoading(false);
                    }) }, isLoading ? (React.createElement(semantic_ui_react_1.Icon, { fitted: true, loading: true, name: "circle notch", className: "color--grey" })) : (React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "check circle", className: "color--grey dark-grey-on-hover" }))) },
                "This is a suggested budget",
                ['fixed-rollover', 'spent'].indexOf(config === null || config === void 0 ? void 0 : config.auto_suggest) > -1
                    ? ` which is subject to change as you
              update your transactions`
                    : '',
                ". Click to lock in this budgeted amount.")) : !(0, checks_1.isNull)(thisMonthData === null || thisMonthData === void 0 ? void 0 : thisMonthData.budget_amount) ? (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", mouseEnterDelay: 500, trigger: isLoading ? (React.createElement(semantic_ui_react_1.Icon, { fitted: true, loading: true, name: "circle notch", className: "color--grey" })) : (React.createElement("div", { className: "show-on-hover" },
                    React.createElement(semantic_ui_react_1.Icon, { className: "clickable", name: "remove circle", color: "red", fitted: true, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setIsLoading(true);
                            yield onDelete(currentBudget);
                            setIsLoading(false);
                        }) }))) }, "Unset this budget")) : isLoading ? (React.createElement(semantic_ui_react_1.Icon, { fitted: true, loading: true, name: "circle notch", className: "color--grey" })) : (React.createElement(React.Fragment, null))),
            React.createElement(semantic_ui_react_1.Popup, { size: "large", position: 'right center', mouseEnterDelay: 200, hoverable: true, disabled: true, trigger: React.createElement(semantic_ui_react_1.Table.Cell, { className: `editable right-align ${(thisMonthData === null || thisMonthData === void 0 ? void 0 : thisMonthData.is_automated) ? 'color--grey' : ''} ${currentBudget.is_income ? 'color--green' : ''}` },
                    React.createElement(EditablePrice_1.default, { identifier: `budget-${currentBudget.category_id}`, amount: (thisMonthData === null || thisMonthData === void 0 ? void 0 : thisMonthData.is_automated)
                            ? (0, format_1.roundTo)(thisMonthData === null || thisMonthData === void 0 ? void 0 : thisMonthData.budget_amount, round)
                            : thisMonthData === null || thisMonthData === void 0 ? void 0 : thisMonthData.budget_amount, currency: thisMonthData === null || thisMonthData === void 0 ? void 0 : thisMonthData.budget_currency, state: 'Default', nullValue: 'Set budget', location: 'inline-edit', allowNegative: true, displayNegativeSign: true, suggestions: [
                            (lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.spending_to_base)
                                ? {
                                    amount: (0, format_1.roundTo)((currentBudget.is_income ? -1 : 1) *
                                        (lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.spending_to_base), round),
                                    currency: _user.primaryCurrency,
                                    text: "Last period's spend",
                                }
                                : null,
                            (lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.spending_to_base) && (lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.difference)
                                ? {
                                    amount: (0, format_1.roundTo)((lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.spending_to_base) -
                                        ((lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.difference) || 0), round),
                                    currency: _user.primaryCurrency,
                                    text: 'Last spend with rollover',
                                }
                                : null,
                            (lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.budget_amount)
                                ? {
                                    amount: (0, format_1.roundTo)(lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.budget_amount, round),
                                    currency: lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.budget_currency,
                                    text: 'Last budgeted',
                                }
                                : null,
                            (lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.budget_to_base) && (lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.difference)
                                ? {
                                    amount: (0, format_1.roundTo)((lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.budget_to_base) -
                                        ((lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.difference) || 0), round),
                                    currency: _user.primaryCurrency,
                                    text: 'Last budgeted with rollover',
                                }
                                : null,
                            (lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.difference)
                                ? {
                                    amount: (0, format_1.roundTo)(-1 * (lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.difference), round),
                                    currency: _user.primaryCurrency,
                                    text: 'Rollover only',
                                }
                                : null,
                            averageSpend
                                ? {
                                    amount: (0, format_1.roundTo)(averageSpend, round),
                                    currency: _user.primaryCurrency,
                                    text: 'Avg spend last 3 months',
                                }
                                : null,
                            ((_a = budget.config) === null || _a === void 0 ? void 0 : _a.auto_suggest) == 'fixed-rollover'
                                ? {
                                    amount: (0, format_1.roundTo)(budget.config.to_base - (lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.difference), round),
                                    currency: _user.primaryCurrency,
                                    text: 'Fixed rollover',
                                }
                                : null,
                            ((_b = budget.config) === null || _b === void 0 ? void 0 : _b.auto_suggest) == 'fixed'
                                ? {
                                    amount: (0, format_1.roundTo)(budget.config.to_base, round),
                                    currency: _user.primaryCurrency,
                                    text: 'Fixed amount',
                                }
                                : null,
                        ].filter(o => !!o), onSave: (amount, currency) => __awaiter(void 0, void 0, void 0, function* () {
                            setIsLoading(true);
                            const results = yield onSave(currentBudget.category_id, {
                                amount: amount,
                                currency: currency || _user.primaryCurrency,
                            });
                            setIsLoading(false);
                            if (results === false) {
                                return {
                                    amount: thisMonthData === null || thisMonthData === void 0 ? void 0 : thisMonthData.budget_amount,
                                    currency: thisMonthData === null || thisMonthData === void 0 ? void 0 : thisMonthData.budget_currency,
                                };
                            }
                        }) })) }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell-lr right-align" }, (thisMonthData === null || thisMonthData === void 0 ? void 0 : thisMonthData.num_transactions) > 0 ||
                !!(thisMonthData === null || thisMonthData === void 0 ? void 0 : thisMonthData.spending_to_base) ? (React.createElement("div", { className: "flex--space-between" },
                React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", mouseEnterDelay: 500, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "search plus", fitted: true, className: "color--grey clickable dark-grey-on-hover", style: { position: 'relative', top: '-2px' }, onClick: () => {
                            openTransactions({
                                start_date: thisMonthData.period_start,
                                end_date: thisMonthData.period_end,
                                total: (0, format_1.toPrice)(currentBudget.is_income
                                    ? thisMonthData.spending_to_base * -1
                                    : thisMonthData.spending_to_base, _user.primaryCurrency),
                            });
                        } }) }, "View transactions (pop-up)"),
                React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", mouseEnterDelay: 500, trigger: React.createElement("span", { className: `underline-link ml-1rem`, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setIsLoading(true);
                            yield onSave(currentBudget.category_id, {
                                amount: (0, format_1.roundTo)(currentBudget.is_income
                                    ? thisMonthData.spending_to_base * -1
                                    : thisMonthData.spending_to_base, round),
                                currency: _user.primaryCurrency,
                            });
                            setIsLoading(false);
                        }) }, (0, format_1.toPrice)(currentBudget.is_income
                        ? thisMonthData.spending_to_base * -1
                        : thisMonthData.spending_to_base, _user.primaryCurrency, round || 1)) }, "Click to set your budget"))) : (React.createElement("span", null, "-"))),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: `padded-cell-lr number ${((difference !== null && difference !== void 0 ? difference : 0) > 0 && !currentBudget.is_income) ||
                    ((difference !== null && difference !== void 0 ? difference : 0) < 0 && currentBudget.is_income)
                    ? 'critical'
                    : ''} ${((difference !== null && difference !== void 0 ? difference : 0) > 0 && currentBudget.is_income) ||
                    ((difference !== null && difference !== void 0 ? difference : 0) < 0 && !currentBudget.is_income)
                    ? 'green'
                    : ''}` }, !(0, checks_1.isNull)(difference) ? (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", trigger: React.createElement("span", null, (0, format_1.toPrice)(difference, _user.primaryCurrency, round || 1, undefined, undefined, 'exceptZero')) },
                difference == 0 && (React.createElement(React.Fragment, null,
                    "You ",
                    currentBudget.is_income ? 'earned' : 'spent',
                    " exactly what you budgeted!")),
                (difference !== null && difference !== void 0 ? difference : 0) > 0 && !currentBudget.is_income && (React.createElement(React.Fragment, null,
                    "You spent",
                    ' ',
                    (0, format_1.toPrice)(difference, _user.primaryCurrency, round || 1),
                    " more than you budgeted.")),
                (difference !== null && difference !== void 0 ? difference : 0) < 0 && currentBudget.is_income && (React.createElement(React.Fragment, null,
                    "You earned",
                    ' ',
                    (0, format_1.toPrice)(Math.abs(difference), _user.primaryCurrency, round || 1),
                    ' ',
                    "less than you budgeted.")),
                (difference !== null && difference !== void 0 ? difference : 0) > 0 && currentBudget.is_income && (React.createElement(React.Fragment, null,
                    "You earned",
                    ' ',
                    (0, format_1.toPrice)(difference, _user.primaryCurrency, round || 1),
                    " more than you budgeted.")),
                (difference !== null && difference !== void 0 ? difference : 0) < 0 && !currentBudget.is_income && (React.createElement(React.Fragment, null,
                    "You spent",
                    ' ',
                    (0, format_1.toPrice)(Math.abs(difference), _user.primaryCurrency, round || 1),
                    ' ',
                    "less than you budgeted.")))) : (React.createElement("span", null, "-"))),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell-lr right-align" }, !(0, checks_1.isNull)(lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.budget_amount) ? (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", mouseEnterDelay: 500, trigger: React.createElement("span", { className: 'underline-link', onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setIsLoading(true);
                        yield onSave(currentBudget.category_id, {
                            amount: (0, format_1.roundTo)(lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.budget_amount, round),
                            currency: lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.budget_currency,
                        });
                        setIsLoading(false);
                    }) }, (0, format_1.toPrice)(lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.budget_amount, lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.budget_currency, round || 1)) }, "Click to set your budget")) : (React.createElement("span", null, "-"))),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell-lr right-align" }, (lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.num_transactions) > 0 ||
                !!(lastMonthData === null || lastMonthData === void 0 ? void 0 : lastMonthData.spending_to_base) ? (React.createElement("div", { className: "flex--space-between" },
                React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", mouseEnterDelay: 500, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "search plus", fitted: true, className: "color--grey clickable dark-grey-on-hover", onClick: () => {
                            openTransactions({
                                start_date: lastMonthData.period_start,
                                end_date: lastMonthData.period_end,
                                total: (0, format_1.toPrice)(currentBudget.is_income
                                    ? lastMonthData.spending_to_base * -1
                                    : lastMonthData.spending_to_base, _user.primaryCurrency),
                            });
                        } }) }, "View transactions (pop-up)"),
                React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", mouseEnterDelay: 500, trigger: React.createElement("span", { className: `underline-link`, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setIsLoading(true);
                            yield onSave(currentBudget.category_id, {
                                amount: (0, format_1.roundTo)(currentBudget.is_income
                                    ? lastMonthData.spending_to_base * -1
                                    : lastMonthData.spending_to_base, round),
                                currency: _user.primaryCurrency,
                            });
                            setIsLoading(false);
                        }) }, (0, format_1.toPrice)(currentBudget.is_income
                        ? lastMonthData.spending_to_base * -1
                        : lastMonthData.spending_to_base, _user.primaryCurrency, round || 1)) }, "Click to set your budget"))) : (React.createElement("span", null, "-"))),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: `padded-cell-lr number ${((lastDifference !== null && lastDifference !== void 0 ? lastDifference : 0) > 0 && !currentBudget.is_income) ||
                    ((lastDifference !== null && lastDifference !== void 0 ? lastDifference : 0) < 0 && currentBudget.is_income)
                    ? 'critical'
                    : ''} ${((lastDifference !== null && lastDifference !== void 0 ? lastDifference : 0) > 0 && currentBudget.is_income) ||
                    ((lastDifference !== null && lastDifference !== void 0 ? lastDifference : 0) < 0 && !currentBudget.is_income)
                    ? 'green'
                    : ''}` }, !(0, checks_1.isNull)(lastDifference) ? (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", trigger: React.createElement("span", null, (0, format_1.toPrice)(lastDifference, _user.primaryCurrency, round || 1, undefined, undefined, 'exceptZero')) },
                lastDifference == 0 && (React.createElement(React.Fragment, null,
                    "You ",
                    currentBudget.is_income ? 'earned' : 'spent',
                    " exactly what you budgeted!")),
                (lastDifference !== null && lastDifference !== void 0 ? lastDifference : 0) > 0 && !currentBudget.is_income && (React.createElement(React.Fragment, null,
                    "You spent",
                    ' ',
                    (0, format_1.toPrice)(lastDifference, _user.primaryCurrency, round || 1),
                    ' ',
                    "more than you budgeted.")),
                (lastDifference !== null && lastDifference !== void 0 ? lastDifference : 0) < 0 && currentBudget.is_income && (React.createElement(React.Fragment, null,
                    "You earned",
                    ' ',
                    (0, format_1.toPrice)(Math.abs(lastDifference), _user.primaryCurrency, round || 1),
                    ' ',
                    "less than you budgeted.")),
                (lastDifference !== null && lastDifference !== void 0 ? lastDifference : 0) > 0 && currentBudget.is_income && (React.createElement(React.Fragment, null,
                    "You earned",
                    ' ',
                    (0, format_1.toPrice)(lastDifference, _user.primaryCurrency, round || 1),
                    ' ',
                    "more than you budgeted.")),
                (lastDifference !== null && lastDifference !== void 0 ? lastDifference : 0) < 0 && !currentBudget.is_income && (React.createElement(React.Fragment, null,
                    "You spent",
                    ' ',
                    (0, format_1.toPrice)(Math.abs(lastDifference), _user.primaryCurrency, round || 1),
                    ' ',
                    "less than you budgeted.")))) : (React.createElement("span", null, "-"))),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align clickable", onClick: () => {
                    _modal.data.current.budget = currentBudget;
                    _modal.data.current.period = currentPeriod;
                    openModal();
                } },
                React.createElement(semantic_ui_react_1.Icon, { name: "angle right", fitted: true, color: "grey" }))),
        expand &&
            Object.keys(currentBudget.data)
                .filter(o => {
                return o.split('+').length == 2;
            })
                .map(o => {
                const data = currentBudget.data[o];
                return (React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell-lr right-align" },
                        React.createElement("span", null,
                            Moment(data.period_start).format('MMM D'),
                            " -",
                            ' ',
                            Moment(data.period_end).format('MMM D'))),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
                    React.createElement(semantic_ui_react_1.Table.Cell, null),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell-lr right-align" },
                        React.createElement("span", null, (0, format_1.toPrice)(data.budget_amount, data.budget_currency))),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell-lr right-align" },
                        React.createElement("span", null, (0, format_1.toPrice)(data.spending_to_base, _user.primaryCurrency))),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell-lr right-align" }, (0, format_1.toPrice)(data.budget_to_base - data.spending_to_base, _user.primaryCurrency)),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
                    React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: 5 }),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
                    React.createElement(semantic_ui_react_1.Table.Cell, null)));
            })));
};
exports.default = BudgetRow;
