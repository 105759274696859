"use strict";
/**
 *  MergeAndDelete.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const categories_1 = require("@actions/categories");
const react_1 = require("react");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const MergeAndDelete = ({ data, useModal, utils, setVisibility, switchView, setDisableOnClickOutside, }) => {
    var _a;
    const [selectedCategory, setSelectedCategory] = (0, react_1.useState)(null);
    const [categoriesUI, setCategoriesUI] = (0, react_1.useState)([]);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const [categoryName, setCategoryName] = (0, react_1.useState)(null);
    const [categoryId, setCategoryId] = (0, react_1.useState)(null);
    const [count, setCount] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        var _a, _b;
        const fetchCount = () => __awaiter(void 0, void 0, void 0, function* () {
            var _c;
            const count = yield (0, categories_1.countDependents)((_c = data === null || data === void 0 ? void 0 : data.category) === null || _c === void 0 ? void 0 : _c.id);
            setCount(count);
        });
        setDisableOnClickOutside(false);
        setCategoryName((_a = data === null || data === void 0 ? void 0 : data.category) === null || _a === void 0 ? void 0 : _a.name);
        setCategoryId((_b = data === null || data === void 0 ? void 0 : data.category) === null || _b === void 0 ? void 0 : _b.id);
        fetchCount();
    }, [(_a = data === null || data === void 0 ? void 0 : data.category) === null || _a === void 0 ? void 0 : _a.id]);
    (0, react_1.useEffect)(() => {
        setCategoriesUI(_categories.assignable.map((category, index) => {
            return !category.is_group && category.id !== categoryId ? (React.createElement("div", { key: `category-child-${index}`, className: `setup-category ${selectedCategory === category.id ? 'added' : ''}`, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setSelectedCategory(category.id);
                    setDisableOnClickOutside(true);
                }) },
                React.createElement("span", { className: "category-payee" },
                    category.name,
                    ' ',
                    category.group_id
                        ? `(${_categories.getName(category.group_id)})`
                        : ''),
                selectedCategory === category.id && React.createElement(semantic_ui_react_1.Icon, { name: "check circle" }))) : null;
        }));
    }, [categoryId, selectedCategory]);
    // Setup
    (0, react_1.useEffect)(() => {
        useModal({
            header: (React.createElement("div", null,
                React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        switchView('EDIT_CATEGORY');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " Category Details"),
                React.createElement("span", { className: "header-text-large" },
                    "Merge and Delete ",
                    categoryName))),
            leftButton: React.createElement(React.Fragment, null),
            middleButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: true, className: "no-border", onClick: () => {
                    setVisibility(false, true);
                } }, "Cancel")),
        });
        return () => {
            useModal({
                header: null,
                leftButton: null,
                middleButton: null,
            });
        };
    }, [data, categoryName]);
    (0, react_1.useEffect)(() => {
        useModal({
            rightButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", disabled: selectedCategory == null, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    const results = yield utils._process(categories_1.mergeAndDelete)({
                        original_category_id: categoryId,
                        new_category_id: selectedCategory,
                    });
                    if (!results.error) {
                        _categories.remove(categoryId);
                        utils._showToast({
                            message: 'Successfully merged & deleted category',
                            type: 'success',
                        });
                        setVisibility(false);
                    }
                }) }, "Merge & Delete")),
        });
        return () => {
            useModal({
                rightButton: null,
            });
        };
    }, [selectedCategory, categoryId]);
    return (React.createElement("div", { className: "g-categories-setup" },
        count && (React.createElement(semantic_ui_react_1.Message, { info: true },
            React.createElement("p", null, "Use this tool to merge two categories into one."),
            React.createElement("p", null,
                React.createElement("b", null, "The following will be re-categorized:")),
            React.createElement(semantic_ui_react_1.Message.List, null,
                React.createElement(semantic_ui_react_1.Message.Item, null,
                    count.transactions || 0,
                    " transactions"),
                React.createElement(semantic_ui_react_1.Message.Item, null,
                    count.category_rules || 0,
                    " rules"),
                React.createElement(semantic_ui_react_1.Message.Item, null,
                    count.recurring || 0,
                    " recurring item categories"),
                React.createElement(semantic_ui_react_1.Message.Item, null,
                    count.plaid_cats || 0,
                    " imported transaction auto-categorizations")),
            React.createElement("p", null,
                React.createElement("b", null, "The following will be deleted:")),
            React.createElement(semantic_ui_react_1.Message.List, null,
                React.createElement(semantic_ui_react_1.Message.Item, null,
                    count.budget || 0,
                    " budgets"),
                React.createElement(semantic_ui_react_1.Message.Item, null,
                    "The ",
                    categoryName,
                    " category")))),
        React.createElement("div", { className: "category-content" },
            React.createElement("h2", { className: "center-align" },
                "Re-categorize ",
                categoryName,
                " items to:"),
            categoriesUI)));
};
exports.default = MergeAndDelete;
