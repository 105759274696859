"use strict";
/**
 *  Footer.tsx
 *  Global footer
 */
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const ConsentBanner_1 = require("@components/global/ConsentBanner");
const Feedback_1 = require("@containers/Feedback");
const SyncingSupportModal_1 = require("../LinkedAccounts/SyncingSupportModal");
const Footer = () => {
    const history = (0, react_router_dom_1.useHistory)();
    const ref = (0, react_1.useRef)(null);
    const [showBanner, setShowBanner] = (0, react_1.useState)(false);
    const [isDarkMode, setIsDarkMode] = (0, react_1.useState)(document.body.classList.contains('dark'));
    const [show, setShow] = (0, react_1.useState)(false);
    const [showHelp, setShowHelp] = (0, react_1.useState)(false);
    const [showFeedback, setShowFeedback] = (0, react_1.useState)(false);
    const [showSyncingSupport, setShowSyncingSupport] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        const listener = event => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            setShow(false);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ConsentBanner_1.default, { show: showBanner, setParentShow: setShowBanner }),
        React.createElement(SyncingSupportModal_1.default, null),
        React.createElement(Feedback_1.default, { showFeedback: showFeedback, onClose: () => {
                setShowFeedback(false);
            } })));
};
exports.default = Footer;
