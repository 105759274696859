"use strict";
/**
 *  EditModal.tsx
 *  Recurring expenses edit modal
 */
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_1 = require("react");
const react_json_view_1 = require("@microlink/react-json-view");
const TransactionsProvider_1 = require("@/providers/TransactionsProvider");
const PlaidMetadata = ({ data, useModal, utils, setVisibility, switchView, setDisableOnClickOutside, }) => {
    const _transactions = (0, react_1.useContext)(TransactionsProvider_1.TransactionsContext);
    (0, react_1.useEffect)(() => {
        useModal({
            header: (React.createElement("div", null,
                React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        switchView('DETAILS_VIEW');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " Back to transaction"),
                React.createElement("span", { className: "header-text-large" }, "Imported Transaction Metadata"))),
            middleButton: React.createElement(React.Fragment, null),
            leftButton: React.createElement(React.Fragment, null),
            rightButton: (React.createElement(semantic_ui_react_1.Button, { color: 'orange', basic: true, onClick: () => {
                    setVisibility(false, true);
                } }, 'Close')),
        });
        return () => {
            useModal({
                header: null,
                leftButton: null,
                middleButton: null,
                rightButton: null,
            });
        };
    }, []);
    return (React.createElement("div", null,
        React.createElement(semantic_ui_react_1.Message, null, "Below is the complete set of metadata we receive from Plaid when we imported this transaction."),
        React.createElement(react_json_view_1.default, { theme: document.body.classList.contains('dark') ? 'tomorrow' : 'rjv-default', enableClipboard: false, displayObjectSize: false, name: 'transaction', src: JSON.parse(_transactions.modalData.current.plaid_metadata || null) || {
                message: 'Please wait, loading...',
            }, displayDataTypes: false })));
};
exports.default = PlaidMetadata;
