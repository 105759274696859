"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const billing_1 = require("@actions/billing");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_1 = require("@helpers/format");
const BillingProvider_1 = require("@providers/BillingProvider");
const UserProvider_1 = require("@providers/UserProvider");
const Moment = require("moment");
const Plans = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
    // Coupons
    const RANGE_MIN = 5;
    const RANGE_MAX = 15;
    const RANGE_STEP = 1;
    const _billing = (0, react_1.useContext)(BillingProvider_1.BillingContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const subscription = _billing.subscription;
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [planType, setPlanType] = (0, react_1.useState)((_a = _billing.subscription.plan) === null || _a === void 0 ? void 0 : _a.type);
    const [quantity, setQuantity] = (0, react_1.useState)(Math.max(RANGE_MIN, ((_c = (_b = _billing.subscription.plan) === null || _b === void 0 ? void 0 : _b.upcoming) === null || _c === void 0 ? void 0 : _c.quantity) ||
        ((_d = _billing.subscription.plan) === null || _d === void 0 ? void 0 : _d.quantity) ||
        0));
    const [showConfirmation, setShowConfirmation] = (0, react_1.useState)(false);
    const getBaseFeatures = (isCurrent = false) => {
        return (React.createElement(React.Fragment, null,
            React.createElement("span", null,
                React.createElement(semantic_ui_react_1.Icon, { name: "checkmark", color: "green" }),
                " All product features included",
                ' '),
            React.createElement("span", null,
                React.createElement(semantic_ui_react_1.Icon, { name: "checkmark", color: "green" }),
                " Access to",
                ' ',
                React.createElement(react_router_dom_1.Link, { to: { pathname: '/community' } }, "community Discord channel"),
                ' '),
            React.createElement("span", null,
                React.createElement(semantic_ui_react_1.Icon, { name: "checkmark", color: "green" }),
                " Access to",
                ' ',
                React.createElement(react_router_dom_1.Link, { to: { pathname: '/community' } }, "join the beta testing program"),
                ' '),
            isCurrent && _billing.user.stripe_status == 'trialing' && (React.createElement("span", null,
                React.createElement(semantic_ui_react_1.Icon, { name: "calendar", color: "teal" }),
                " Your free trial ends",
                ' ',
                Moment(_billing.trialEnd).fromNow(),
                " on",
                ' ',
                Moment(_billing.trialEnd).format(_user.getFormat('month_day_year')),
                "."))));
    };
    const _updatePlan = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (override = {}) {
        setIsLoading(true);
        if (_billing.trialEnd ||
            !_billing.subscription.plan ||
            _billing.status == 'incomplete') {
            yield (0, billing_1.updatePlan)(Object.assign({ plan_type: planType, plan_currency: 'usd', multiplier: quantity }, override));
        }
        else {
            yield (0, billing_1.scheduleChange)(Object.assign({ plan_type: planType, plan_currency: 'usd', multiplier: quantity }, override));
        }
        setShowConfirmation(false);
        _billing.checkBilling(true);
        setIsLoading(false);
    });
    const getPlanButton = plan_type => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        const currentPlanType = (_a = _billing.subscription.plan) === null || _a === void 0 ? void 0 : _a.type;
        const currentPlanQuantity = (_b = _billing.subscription.plan) === null || _b === void 0 ? void 0 : _b.quantity;
        const hasUpcoming = ((_c = _billing.subscription.plan) === null || _c === void 0 ? void 0 : _c.upcoming) !== null;
        const upcomingPlanType = (_e = (_d = _billing.subscription.plan) === null || _d === void 0 ? void 0 : _d.upcoming) === null || _e === void 0 ? void 0 : _e.type;
        const upcomingPlanQuantity = (_g = (_f = _billing.subscription.plan) === null || _f === void 0 ? void 0 : _f.upcoming) === null || _g === void 0 ? void 0 : _g.quantity;
        const isPaused = _billing.isPaused;
        if (!isPaused) {
            if (!hasUpcoming) {
                if (currentPlanType == plan_type &&
                    (plan_type == 'monthly' || currentPlanQuantity == quantity)) {
                    return { text: '(Current plan)', disabled: true };
                }
                else if (((_h = _billing.user) === null || _h === void 0 ? void 0 : _h.stripe_status) == 'no subscription') {
                    return { text: 'Choose this plan', disabled: false };
                }
                else {
                    return { text: 'Switch to this plan', disabled: false };
                }
            }
            else {
                if (upcomingPlanType == plan_type &&
                    (plan_type == 'monthly' || upcomingPlanQuantity == quantity)) {
                    return {
                        text: `New plan starting ${Moment((_j = _billing.subscription.plan) === null || _j === void 0 ? void 0 : _j.current_period_end, 'X').format(_user.getFormat('month_day'))}`,
                        disabled: true,
                    };
                }
                else if (((_k = _billing.user) === null || _k === void 0 ? void 0 : _k.stripe_status) == 'no subscription') {
                    return { text: 'Choose this plan', disabled: false };
                }
                else {
                    return { text: 'Switch to this plan', disabled: false };
                }
            }
        }
        else {
            return { text: 'Unpause & select', disabled: false };
        }
    };
    const switchPlan = (type) => __awaiter(void 0, void 0, void 0, function* () {
        if (_user.isAdmin) {
            setPlanType(type);
            if (_billing.subscription.plan) {
                setShowConfirmation(true);
            }
            else {
                yield _updatePlan({ plan_type: type });
            }
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(semantic_ui_react_1.Card, { fluid: true },
            React.createElement(semantic_ui_react_1.Card.Content, { header: "Choose your Plan" }),
            !subscription ? (React.createElement(semantic_ui_react_1.Card.Content, null,
                React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "tiny" }))) : (React.createElement(semantic_ui_react_1.Card.Content, null,
                !_billing.isPaused &&
                    (['legacy', 'friends', 'contributor'].indexOf((_e = _billing.subscription.plan) === null || _e === void 0 ? void 0 : _e.type) > -1 ||
                        (((_f = _billing.subscription.plan) === null || _f === void 0 ? void 0 : _f.quantity) < RANGE_MIN &&
                            ((_g = _billing.subscription.plan) === null || _g === void 0 ? void 0 : _g.type) == 'yearly')) && (React.createElement("div", { className: `plan-details mb-1rem` },
                    React.createElement("div", { className: "sub-header" },
                        React.createElement("h3", { className: "flex--space-between" },
                            (0, format_1.toPrice)(((_h = _billing.subscription.plan) === null || _h === void 0 ? void 0 : _h.amount) *
                                (((_j = _billing.subscription.plan) === null || _j === void 0 ? void 0 : _j.type) == 'yearly'
                                    ? (_k = _billing.subscription.plan) === null || _k === void 0 ? void 0 : _k.quantity
                                    : 1), (_l = _billing.subscription.plan) === null || _l === void 0 ? void 0 : _l.currency),
                            ' ',
                            "per ", (_m = _billing.subscription.plan) === null || _m === void 0 ? void 0 :
                            _m.interval,
                            ' ',
                            _billing.subscription.plan.type == 'legacy' &&
                                `(discontinued)`)),
                    getBaseFeatures(true),
                    _billing.subscription.plan.type == 'legacy' ||
                        _billing.subscription.plan.quantity < RANGE_MIN ? (React.createElement("span", null,
                        React.createElement(semantic_ui_react_1.Icon, { name: "exclamation triangle", color: "yellow" }),
                        " You are on a grandfathered plan. If you switch to another plan, you won't be able to go back to this price.")) : (React.createElement("span", null,
                        React.createElement(semantic_ui_react_1.Icon, { name: "heart", color: "red" }),
                        " You are on a free",
                        ' ',
                        _billing.subscription.plan.type == 'friends'
                            ? 'friends & family'
                            : 'contributor',
                        ' ',
                        "plan. We appreciate you!")),
                    React.createElement(semantic_ui_react_1.Button, { fluid: true, className: "mt-1rem", disabled: true, size: "small", color: 'grey' }, "(Current plan)"))),
                React.createElement("div", { className: "plan-details-container" },
                    React.createElement("div", { className: `flex--column-space-between plan-details mb-1rem ${((_o = _billing.subscription.plan) === null || _o === void 0 ? void 0 : _o.type) == 'monthly' &&
                            !_billing.isPaused
                            ? 'selected'
                            : ''}` },
                        React.createElement("div", { className: "width-100" },
                            React.createElement("div", { className: "header-style" }, "Monthly"),
                            React.createElement("div", { className: "sub-header" },
                                React.createElement("h3", null,
                                    (0, format_1.toPrice)(10, _billing.user.stripe_plan_currency),
                                    " per month")),
                            getBaseFeatures(((_p = _billing.subscription.plan) === null || _p === void 0 ? void 0 : _p.type) == 'monthly')),
                        React.createElement("div", { className: "width-100" },
                            React.createElement(semantic_ui_react_1.Button, { fluid: true, className: "mt-1rem", size: "small", loading: isLoading, disabled: isLoading || getPlanButton('monthly').disabled, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    yield switchPlan('monthly');
                                }), color: getPlanButton('monthly').disabled ? 'grey' : 'yellow' }, getPlanButton('monthly').text))),
                    React.createElement("div", { className: `plan-details mb-1rem ${((_q = _billing.subscription.plan) === null || _q === void 0 ? void 0 : _q.type) == 'yearly' &&
                            ((_r = _billing.subscription.plan) === null || _r === void 0 ? void 0 : _r.quantity) == quantity &&
                            !_billing.isPaused
                            ? 'selected'
                            : ''}` },
                        React.createElement("div", { className: "header-style" },
                            React.createElement("div", null, "Annual"),
                            React.createElement("div", { className: "emph" }, "Set your own price!")),
                        React.createElement("div", { className: "sub-header" },
                            React.createElement("h3", null,
                                (0, format_1.toPrice)(quantity * 10, _billing.user.stripe_plan_currency),
                                ' ',
                                "per year"),
                            React.createElement("span", null,
                                (0, format_1.toPrice)((quantity * 10) / 12, _billing.user.stripe_plan_currency),
                                ' ',
                                "per month, billed annually")),
                        React.createElement("div", null,
                            React.createElement(semantic_ui_react_1.Input, { min: RANGE_MIN, max: RANGE_MAX, name: "duration", onChange: (e, data) => {
                                    setQuantity(parseInt(data.value));
                                }, step: RANGE_STEP, type: "range", value: quantity, list: "markers", className: "width-100" }),
                            React.createElement("div", { style: {
                                    display: 'flex',
                                    position: 'relative',
                                    top: '-10px',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    height: '20px',
                                    marginRight: '6px',
                                } },
                                React.createElement("div", { style: {
                                        position: 'absolute',
                                        left: `calc(${(100 / (RANGE_MAX - RANGE_MIN)) *
                                            (10 - RANGE_MIN)}% - 4.5px)`,
                                    } },
                                    React.createElement(semantic_ui_react_1.Icon, { name: "star", color: "yellow", fitted: true, size: "small" })),
                                React.createElement("div", { style: {
                                        position: 'absolute',
                                        left: `calc(${(100 / (RANGE_MAX - RANGE_MIN)) *
                                            (12 - RANGE_MIN)}% - 8px)`,
                                    } },
                                    React.createElement(semantic_ui_react_1.Icon, { name: "heart", color: "red", fitted: true, size: "small" })),
                                React.createElement("div", { style: {
                                        position: 'absolute',
                                        right: '0.5px',
                                    } },
                                    React.createElement(semantic_ui_react_1.Icon, { name: "gem", color: "blue", fitted: true, size: "small" })))),
                        getBaseFeatures(((_s = _billing.subscription.plan) === null || _s === void 0 ? void 0 : _s.type) == 'yearly' &&
                            ((_t = _billing.subscription.plan) === null || _t === void 0 ? void 0 : _t.quantity) == quantity),
                        quantity >= 10 && (React.createElement("span", null,
                            React.createElement(semantic_ui_react_1.Icon, { name: "star", color: "yellow" }),
                            " Thanks for helping us stay sustainable!")),
                        quantity >= 12 && (React.createElement(React.Fragment, null,
                            React.createElement("span", null,
                                React.createElement(semantic_ui_react_1.Icon, { name: "heart", color: "red" }),
                                " Receive a gift on your first anniversary!",
                                ' '))),
                        quantity >= 15 && (React.createElement(React.Fragment, null,
                            React.createElement("span", null,
                                React.createElement(semantic_ui_react_1.Icon, { name: "diamond", color: "blue" }),
                                " You rock! You'll get something super special on your second anniversary!"))),
                        ((_u = _billing.subscription.redemptions) === null || _u === void 0 ? void 0 : _u.tier1.length) >= 2 && (React.createElement(semantic_ui_react_1.Button, { className: "mt-1rem", fluid: true, size: "small", basic: true, icon: true, labelPosition: "right", color: "green", onClick: () => {
                                var _a;
                                window.open(`https://form.lunchmoney.app/subscription-gift?tier=${((_a = _billing.subscription.redemptions) === null || _a === void 0 ? void 0 : _a.tier2.length) >= 3
                                    ? 2
                                    : 1}&user_id=${_user.userId}`, '_blank');
                            }, disabled: ((_v = _billing.subscription.redemptions) === null || _v === void 0 ? void 0 : _v.redeemedTier2) ||
                                (((_w = _billing.subscription.redemptions) === null || _w === void 0 ? void 0 : _w.redeemedTier1) &&
                                    ((_x = _billing.subscription.redemptions) === null || _x === void 0 ? void 0 : _x.tier2.length) < 3) },
                            React.createElement(semantic_ui_react_1.Icon, { name: "external square alternate" }),
                            "Redeem anniversary gift")),
                        React.createElement(semantic_ui_react_1.Button, { fluid: true, className: "mt-05rem", size: "small", loading: isLoading, disabled: isLoading || getPlanButton('yearly').disabled, color: getPlanButton('yearly').disabled ? 'grey' : 'yellow', onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                switchPlan('yearly');
                            }) }, getPlanButton('yearly').text)))))),
        React.createElement(semantic_ui_react_1.Modal, { open: showConfirmation, closeOnEscape: false, closeOnDimmerClick: false, size: 'mini', onClose: () => {
                setShowConfirmation(false);
            } },
            React.createElement(semantic_ui_react_1.Modal.Header, null,
                "Switching to ",
                planType,
                " plan"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("div", null,
                    "After the current billing period ends on",
                    ' ',
                    React.createElement("b", null, Moment((_y = _billing.subscription.plan) === null || _y === void 0 ? void 0 : _y.current_period_end, 'X').format(_user.getFormat('month_day_year'))),
                    ' ',
                    "(",
                    Moment((_z = _billing.subscription.plan) === null || _z === void 0 ? void 0 : _z.current_period_end, 'X').fromNow(),
                    "), you will be billed ",
                    planType,
                    " at a rate of",
                    ' ',
                    planType == 'monthly'
                        ? (0, format_1.toPrice)(10, _billing.user.stripe_plan_currency)
                        : (0, format_1.toPrice)(quantity * 10, _billing.user.stripe_plan_currency),
                    ' ',
                    "per ",
                    planType == 'monthly' ? 'month' : 'year',
                    ".")),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { loading: isLoading, disabled: isLoading, onClick: () => {
                        setShowConfirmation(false);
                    } }, "Cancel"),
                React.createElement(semantic_ui_react_1.Button, { color: "green", loading: isLoading, disabled: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield _updatePlan();
                    }), content: `Confirm` })))));
};
exports.default = Plans;
