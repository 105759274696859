"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.switchAccount = exports.getAll = exports.deleteBudget = exports.removeDemo = exports.createNew = exports.startFresh = exports.resendInvitation = exports.acceptInvitation = exports.getCollaboratorInvite = exports.respondCollaboratorInvite = exports.removeCollaborator = exports.inviteCollaborator = exports.getCollaborators = void 0;
const common_1 = require("./common");
const getCollaborators = () => {
    return (0, common_1.get)(`accounts/collaborators`);
};
exports.getCollaborators = getCollaborators;
const inviteCollaborator = body => {
    return (0, common_1.post)(`accounts/collaborators`, body);
};
exports.inviteCollaborator = inviteCollaborator;
const removeCollaborator = user_id => {
    return (0, common_1.del)(`accounts/collaborators/${user_id}`, {});
};
exports.removeCollaborator = removeCollaborator;
const respondCollaboratorInvite = (account_id, response) => {
    return (0, common_1.post)(`accounts/collaborators/${account_id}`, { response });
};
exports.respondCollaboratorInvite = respondCollaboratorInvite;
const getCollaboratorInvite = invite_token => {
    return (0, common_1.get)(`invitation/${invite_token}`);
};
exports.getCollaboratorInvite = getCollaboratorInvite;
const acceptInvitation = body => {
    return (0, common_1.post)(`invitation/${body.invite_token}/accept`, body);
};
exports.acceptInvitation = acceptInvitation;
const resendInvitation = body => {
    return (0, common_1.post)(`invitation/resend`, body);
};
exports.resendInvitation = resendInvitation;
const startFresh = opts => {
    return (0, common_1.post)(`accounts/start_fresh`, opts);
};
exports.startFresh = startFresh;
const createNew = opts => {
    return (0, common_1.post)(`accounts`, opts);
};
exports.createNew = createNew;
const removeDemo = () => {
    return (0, common_1.post)(`accounts/remove_demo`, {});
};
exports.removeDemo = removeDemo;
const deleteBudget = account_id => {
    return (0, common_1.del)(`accounts/${account_id}`, {});
};
exports.deleteBudget = deleteBudget;
const getAll = () => {
    return (0, common_1.get)(`accounts/all`);
};
exports.getAll = getAll;
const switchAccount = account_id => {
    return (0, common_1.post)(`accounts/switch/${account_id}`);
};
exports.switchAccount = switchAccount;
