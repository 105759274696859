"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const qs = require("query-string");
const react_router_dom_1 = require("react-router-dom");
/** Containers */
const Admin2_1 = require("./containers/Admin2");
const Register_1 = require("./containers/Register");
const Login_1 = require("./containers/Login");
const Logout_1 = require("./containers/Logout");
const Reset_1 = require("./containers/Reset");
const Billing_1 = require("./containers/Billing");
const Demo_1 = require("./containers/Demo");
const CannyRedirect_1 = require("./containers/CannyRedirect");
const Community_1 = require("./containers/Community");
const EmailFeedback_1 = require("./containers/EmailFeedback");
const Referral_1 = require("./containers/Referral");
const TwoFactor_1 = require("./containers/TwoFactor");
const Profile_1 = require("./containers/Profile");
const QuickAdd_1 = require("./containers/QuickAdd");
const Calendar_1 = require("./containers/Calendar");
const Transactions_1 = require("./containers/Transactions");
const MerchantNames_1 = require("./containers/MerchantNames");
const CSVImport_1 = require("./containers/CSVImport");
const LinkedAccounts_1 = require("./containers/LinkedAccounts");
const RecurringExpenses_1 = require("./containers/RecurringExpenses");
const SuggestedRecurring_1 = require("./containers/SuggestedRecurring");
const Confirm_1 = require("./containers/Confirm");
const ExtendTrial_1 = require("./containers/ExtendTrial");
const Accept_1 = require("./containers/Accept");
const Unsubscribe_1 = require("./containers/Unsubscribe");
const Budget_1 = require("./containers/Budget");
const Stats_1 = require("./containers/Stats");
const Trends_1 = require("./containers/Trends");
const Categories_1 = require("./containers/Categories");
const Tags_1 = require("./containers/Tags");
const Analyze_1 = require("./containers/Analyze");
const Settings_1 = require("./containers/Settings");
const GiftCards_1 = require("./containers/GiftCards");
const Rules_1 = require("./containers/Rules");
const Main_1 = require("./containers/Main");
const NetWorth_1 = require("./containers/NetWorth");
const Onboarding_1 = require("./containers/Onboarding");
const Playground_1 = require("./containers/Playground");
// import Setup from './containers/Setup'
const Developers_1 = require("./containers/Developers");
const NotFound_1 = require("./containers/NotFound");
const StripeCredit_1 = require("./containers/StripeCredit");
// Providers
// import { useToasts } from 'react-toast-notifications'
const TransactionsProvider_1 = require("@providers/TransactionsProvider");
const ModalProvider_1 = require("@providers/ModalProvider");
const QueryToolProvider_1 = require("@providers/QueryToolProvider");
const react_1 = require("react");
const GlobalProvider_1 = require("@providers/GlobalProvider");
const AuthProvider_1 = require("@providers/AuthProvider");
const BillingProvider_1 = require("@providers/BillingProvider");
const Autocategorization_1 = require("./containers/Autocategorization");
const Maintenance_1 = require("./containers/Maintenance");
const Routes = ({}) => {
    const _global = (0, react_1.useContext)(GlobalProvider_1.GlobalContext);
    const _auth = (0, react_1.useContext)(AuthProvider_1.AuthContext);
    const _billing = (0, react_1.useContext)(BillingProvider_1.BillingContext);
    const history = (0, react_router_dom_1.useHistory)();
    const showToast = (opts) => {
        _global.showToast(opts);
    };
    const AuthedRoute = (_a) => {
        var { component: Component } = _a, rest = __rest(_a, ["component"]);
        return (React.createElement(react_router_dom_1.Route, Object.assign({}, rest, { render: props => {
                var _a;
                if (_auth.isLoggedIn) {
                    if ((_billing.isActive && !_billing.isPaused) ||
                        ((_a = props.location) === null || _a === void 0 ? void 0 : _a.pathname.indexOf('/billing')) > -1) {
                        const query = qs.parse(location.search);
                        if (query['redirect'] && query['redirect'].indexOf('https') == -1) {
                            history.push(decodeURIComponent(query['redirect']));
                        }
                        else {
                            return (React.createElement("div", null,
                                React.createElement(Component, Object.assign({}, props, { _process: fn => {
                                        return function () {
                                            var arguments_1 = arguments;
                                            return __awaiter(this, void 0, void 0, function* () {
                                                const results = yield fn.apply(this, arguments_1);
                                                if (results && results.error) {
                                                    showToast({
                                                        message: results.error.message ||
                                                            'Operation failed. Please try again or contact support@lunchmoney.app',
                                                        type: 'error',
                                                    });
                                                }
                                                return results;
                                            });
                                        }; //.bind(this)
                                    }, _showToast: showToast, _removeToast: id => {
                                        _global.removeToast(id);
                                    } }))));
                        }
                    }
                    else {
                        return (React.createElement(react_router_dom_1.Redirect, { to: {
                                pathname: '/billing',
                                state: { from: props.location },
                            } }));
                    }
                }
                else {
                    return (React.createElement(react_router_dom_1.Redirect, { to: {
                            pathname: '/login',
                            state: { from: props.location },
                        } }));
                }
            } })));
    };
    const layout = (React.createElement(react_router_dom_1.Switch, null,
        React.createElement(react_router_dom_1.Route, { exact: true, path: "/", render: () => {
                if (_auth.isLoggedIn) {
                    const query = qs.parse(location.search);
                    if (query['redirect'] && query['redirect'].indexOf('https') == -1) {
                        history.push(decodeURIComponent(query['redirect']));
                    }
                    else {
                        return React.createElement(react_router_dom_1.Redirect, { to: "/overview" });
                    }
                }
                else {
                    return React.createElement(react_router_dom_1.Redirect, { to: "/login" });
                }
            } }),
        React.createElement(react_router_dom_1.Route, { path: "/login", component: Login_1.default }),
        React.createElement(react_router_dom_1.Route, { path: "/register/new", component: Register_1.default }),
        React.createElement(react_router_dom_1.Route, { path: "/register", component: Register_1.default }),
        React.createElement(react_router_dom_1.Route, { path: "/refer/:referral_token", render: props => {
                var _a, _b;
                if ((_b = (_a = props === null || props === void 0 ? void 0 : props.match) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.referral_token) {
                    window.location.href =
                        'https://lunchmoney.app?refer=' +
                            props.match.params.referral_token;
                }
                else {
                    window.location.href = 'https://lunchmoney.app';
                }
            } }),
        React.createElement(react_router_dom_1.Route, { path: "/reset/:reset_token", component: Reset_1.default }),
        React.createElement(react_router_dom_1.Route, { path: "/reset", component: Reset_1.default }),
        React.createElement(react_router_dom_1.Route, { path: "/maintenance", component: Maintenance_1.default }),
        React.createElement(react_router_dom_1.Route, { path: "/auth/2fa", component: TwoFactor_1.default }),
        React.createElement(react_router_dom_1.Route, { path: "/confirm/:confirm_id", component: Confirm_1.default }),
        React.createElement(react_router_dom_1.Route, { path: "/trial_extension/:extension_id", component: ExtendTrial_1.default }),
        React.createElement(react_router_dom_1.Route, { path: "/accept/:invite_token", component: Accept_1.default }),
        React.createElement(react_router_dom_1.Route, { path: "/logout", component: Logout_1.default }),
        React.createElement(react_router_dom_1.Route, { path: "/unsubscribe/:unsubscribe_token", component: Unsubscribe_1.default }),
        React.createElement(AuthedRoute, { path: "/canny", component: CannyRedirect_1.default }),
        React.createElement(AuthedRoute, { path: "/feedback/:feedback_token/:feedback", component: EmailFeedback_1.default }),
        (window.location.href.indexOf('localhost') > -1 ||
            window.location.href.indexOf('staging.lunchmoney') > -1) && (React.createElement(AuthedRoute, { path: "/admin2", component: Admin2_1.default })),
        React.createElement(AuthedRoute, { path: "/transactions/import", component: CSVImport_1.default }),
        React.createElement(AuthedRoute, { path: "/transactions/new", component: QuickAdd_1.default }),
        React.createElement(AuthedRoute, { path: "/calendar/:year/:month/:day", component: Calendar_1.default }),
        React.createElement(AuthedRoute, { path: "/calendar/:year/:month", component: Calendar_1.default }),
        React.createElement(AuthedRoute, { path: "/calendar", component: Calendar_1.default }),
        React.createElement(AuthedRoute, { path: "/merchants", component: MerchantNames_1.default }),
        React.createElement(AuthedRoute, { path: "/transactions/:year/:month", component: Transactions_1.default }),
        React.createElement(AuthedRoute, { path: "/transactions", component: Transactions_1.default }),
        React.createElement(AuthedRoute, { path: "/recurring/suggested", component: SuggestedRecurring_1.default }),
        React.createElement(AuthedRoute, { path: "/recurring/:year/:month", component: RecurringExpenses_1.default }),
        React.createElement(AuthedRoute, { path: "/recurring", component: RecurringExpenses_1.default }),
        React.createElement(AuthedRoute, { path: "/recurring/:year/:month", component: RecurringExpenses_1.default }),
        React.createElement(AuthedRoute, { path: "/recurring", component: RecurringExpenses_1.default }),
        React.createElement(AuthedRoute, { path: "/analyze", component: Analyze_1.default }),
        React.createElement(AuthedRoute, { path: "/accounts", component: LinkedAccounts_1.default }),
        React.createElement(AuthedRoute, { path: "/budget/:year/:month", component: Budget_1.default }),
        React.createElement(AuthedRoute, { path: "/budget", component: Budget_1.default }),
        React.createElement(AuthedRoute, { path: "/categories/auto", component: Autocategorization_1.default }),
        React.createElement(AuthedRoute, { path: "/categories", component: Categories_1.default }),
        React.createElement(AuthedRoute, { path: "/tags", component: Tags_1.default }),
        React.createElement(AuthedRoute, { path: "/rules", component: Rules_1.default }),
        React.createElement(AuthedRoute, { path: "/setup/:current", component: Onboarding_1.default }),
        React.createElement(AuthedRoute, { path: "/demo", component: Demo_1.default }),
        React.createElement(AuthedRoute, { path: "/developers", component: Developers_1.default }),
        React.createElement(AuthedRoute, { path: "/trends", component: Trends_1.default }),
        React.createElement(AuthedRoute, { path: "/stats", component: Stats_1.default }),
        React.createElement(AuthedRoute, { path: "/net-worth", component: NetWorth_1.default }),
        React.createElement(AuthedRoute, { path: "/overview", component: Main_1.default }),
        React.createElement(AuthedRoute, { path: "/gift-cards", component: GiftCards_1.default }),
        React.createElement(AuthedRoute, { path: "/settings", component: Settings_1.default }),
        React.createElement(AuthedRoute, { path: "/profile", component: Profile_1.default }),
        React.createElement(AuthedRoute, { path: "/billing", component: Billing_1.default }),
        React.createElement(AuthedRoute, { path: "/community", component: Community_1.default }),
        React.createElement(AuthedRoute, { path: "/refer", component: Referral_1.default }),
        React.createElement(AuthedRoute, { path: "/playground", component: Playground_1.default }),
        React.createElement(AuthedRoute, { path: "/apply_credit", component: StripeCredit_1.default }),
        React.createElement(react_router_dom_1.Route, { path: "*", component: NotFound_1.default })));
    return (React.createElement(QueryToolProvider_1.QueryToolProvider, null,
        React.createElement(TransactionsProvider_1.TransactionsProvider, null,
            React.createElement(ModalProvider_1.ModalProvider, null, layout))));
};
exports.default = Routes;
