"use strict";
/**
 *  NewTransactionView.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const EditableDate_1 = require("@components/elements/EditableDate");
const EditableString_1 = require("@components/elements/EditableString");
const EditableCategory_1 = require("@components/elements/EditableCategory");
const EditablePrice_1 = require("@components/elements/EditablePrice");
const EditableTags_1 = require("@components/elements/EditableTags");
const EditableAsset_1 = require("@components/elements/EditableAsset");
const UserProvider_1 = require("@providers/UserProvider");
const NewTransactionView = ({ data, useModal, setVisibility, setDisableOnClickOutside, _modal, }) => {
    var _a, _b, _c, _d, _e;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [transaction, setTransaction] = (0, react_1.useState)(_modal.data.current.dupe_tx || {
        date: ((_b = (_a = _modal.data) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.date) ||
            (Moment(data === null || data === void 0 ? void 0 : data.currentPeriod).format('YYYY-MM') ===
                Moment().format('YYYY-MM')
                ? Moment().format('YYYY-MM-DD')
                : `${Moment(data.currentPeriod).format('YYYY-MM-DD')}T12:00:00Z`),
        category_id: null,
        payee: null,
        amount: null,
        currency: localStorage.getItem('_lm_last_currency_used') || _user.primaryCurrency,
        notes: null,
        tags: null,
        asset_id: ((_d = (_c = _modal.data) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.asset_id) ||
            _user.settings['default_asset_id'] ||
            null,
        plaid_account_id: null,
    });
    // Setup
    (0, react_1.useEffect)(() => {
        const submittable = transaction.date &&
            transaction.payee &&
            transaction.amount &&
            transaction.currency;
        useModal({
            header: React.createElement("span", { className: "header-text-large" }, "Add new transaction"),
            leftButton: (React.createElement(semantic_ui_react_1.Button, { color: 'orange', basic: true, onClick: () => {
                    setVisibility(false, true);
                } }, 'Cancel')),
            rightButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "no-border", disabled: !submittable, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    // Update assets
                    if (submittable) {
                        yield data.onCreate(transaction);
                        setVisibility(false);
                    }
                }) }, 'Create Transaction')),
            middleButton: React.createElement(React.Fragment, null),
        });
        return () => {
            useModal({
                header: React.createElement(React.Fragment, null),
                rightButton: React.createElement(React.Fragment, null),
                middleButton: React.createElement(React.Fragment, null),
                leftButton: React.createElement(React.Fragment, null),
            });
        };
    }, [data, transaction]);
    return (React.createElement("div", { id: "transactions-modal-detail-view" },
        React.createElement("div", { className: "transaction-details" },
            React.createElement("h3", { className: "flex--space-between" }, "Details"),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Date"),
                React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(EditableDate_1.default, { identifier: `transaction-date`, firstValue: transaction.date, format: 'month_day_year', location: 'modal', state: 'Editing', onSave: date => {
                            setTransaction(Object.assign(Object.assign({}, transaction), { date: date }));
                            setDisableOnClickOutside(true);
                        } }))),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Category"),
                React.createElement(semantic_ui_react_1.Form, { widths: "equal" },
                    React.createElement(EditableCategory_1.default, { identifier: `transaction-category`, firstValue: transaction.category_id, location: 'modal', state: 'Editing', onSave: category_id => {
                            setTransaction(Object.assign(Object.assign({}, transaction), { category_id: category_id }));
                            setDisableOnClickOutside(true);
                        } }))),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Payee Name"),
                React.createElement(semantic_ui_react_1.Form, { widths: "equal" },
                    React.createElement(EditableString_1.default, { identifier: `transaction-payee`, firstValue: transaction.payee, location: 'modal', state: 'Editing', autosuggest: true, shouldSaveOnChange: true, onSave: payee => {
                            setTransaction(Object.assign(Object.assign({}, transaction), { payee: payee }));
                            setDisableOnClickOutside(true);
                        } }))),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Amount"),
                React.createElement(semantic_ui_react_1.Form, { widths: "equal" },
                    React.createElement(EditablePrice_1.default, { identifier: `transaction-amount`, amount: transaction.amount, showSignLabel: true, currency: transaction.currency, location: 'modal', state: 'Editing', saveOnChange: true, onSave: (amount, currency) => {
                            setTransaction(Object.assign(Object.assign({}, transaction), { amount,
                                currency }));
                            setDisableOnClickOutside(true);
                        } }))),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Notes"),
                React.createElement(semantic_ui_react_1.Form, { widths: "equal" },
                    React.createElement(EditableString_1.default, { identifier: `transaction-notes`, firstValue: transaction.notes, location: 'modal', state: 'Editing', shouldSaveOnChange: true, onSave: value => {
                            setTransaction(Object.assign(Object.assign({}, transaction), { notes: value }));
                            setDisableOnClickOutside(true);
                        } }))),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Transaction tag(s)"),
                React.createElement(semantic_ui_react_1.Form, { widths: "equal" },
                    React.createElement(EditableTags_1.default, { onChange: value => {
                            setTransaction(Object.assign(Object.assign({}, transaction), { tags: value }));
                            setDisableOnClickOutside(true);
                        }, initialValue: ((_e = transaction === null || transaction === void 0 ? void 0 : transaction.tags) === null || _e === void 0 ? void 0 : _e.map(o => o.id)) || [] }))),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Paid from"),
                React.createElement(EditableAsset_1.default, { defaultSelection: {
                        source: transaction.plaid_account_id
                            ? 'plaid'
                            : transaction.asset_id
                                ? 'manual'
                                : null,
                        id: transaction.plaid_account_id || transaction.asset_id || -1,
                    }, onSave: obj => {
                        setTransaction(Object.assign(Object.assign({}, transaction), { [obj.source == 'plaid' ? 'plaid_account_id' : 'asset_id']: obj.id > -1 ? obj.id : null, [obj.source == 'plaid' ? 'asset_id' : 'plaid_account_id']: null }));
                        setDisableOnClickOutside(true);
                    } })))));
};
exports.default = NewTransactionView;
