"use strict";
/**
 *  EditModal.tsx
 *  Recurring expenses edit modal
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const EditableDate_1 = require("@components/elements/EditableDate");
const dates_1 = require("@helpers/dates");
const checks_1 = require("@helpers/checks");
const format_1 = require("@helpers/format");
const react_router_dom_1 = require("react-router-dom");
const react_1 = require("react");
const recurring_expenses_1 = require("@actions/recurring_expenses");
const ModalProvider_1 = require("@providers/ModalProvider");
const CategoriesProvider_1 = require("@providers/CategoriesProvider");
const UserProvider_1 = require("@providers/UserProvider");
const AssetsProvider_1 = require("@/providers/AssetsProvider");
const RecurringProvider_1 = require("@/providers/RecurringProvider");
const EditableString_1 = require("../elements/EditableString");
const EditablePrice_1 = require("../elements/EditablePrice");
const EditableAsset_1 = require("../elements/EditableAsset");
const EditableCadence_1 = require("../elements/EditableCadence");
const EditableCategory_1 = require("../elements/EditableCategory");
const MATCH_TYPES_MAPPING = {
    exact: 'matches',
    exactly: 'exactly',
    contain: 'contains',
    start_with: 'starts with',
    less_than: 'less than',
    'less than': 'less than',
    greater_than: 'greater than',
    'greater than': 'greater than',
    'less than or equal to': 'less or equal to',
    'greater than or equal to': 'greater or equal to',
    between: 'between',
    'on or after': 'on or after',
    'on or before': 'on or before',
};
const EditModal = ({ 
/** Recurring expense to be edited */
data, useModal, utils, setVisibility, switchView, setDisableOnClickOutside, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    let history = (0, react_router_dom_1.useHistory)();
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const _modal = (0, react_1.useContext)(ModalProvider_1.ModalContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const _recurring = (0, react_1.useContext)(RecurringProvider_1.RecurringContext);
    const [expense, setExpense] = (0, react_1.useState)(data.recurring ||
        _recurring.getById((_b = (_a = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.recurring_id) ||
        {});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = (0, react_1.useState)(false);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [recurringTxsUi, setRecurringTxsUi] = (0, react_1.useState)(null);
    const [currentView, setCurrentView] = (0, react_1.useState)(((_d = (_c = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.edit_recurring_view) || 'details');
    (0, react_1.useEffect)(() => {
        var _a, _b, _c, _d;
        useModal({
            header: (React.createElement("div", null,
                ((_b = (_a = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.previous) && (React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        var _a, _b;
                        switchView((_b = (_a = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.previous);
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " ", (_d = (_c = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 :
                    _d.previous_text)),
                currentView == 'rules' && (React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        setCurrentView('details');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " Recurring Item Details")),
                React.createElement("span", { className: "header-text-large" }, "Edit Recurring Item"))),
            middleButton: currentView == 'details' ? (React.createElement(semantic_ui_react_1.Button, { className: "no-border", color: 'orange', basic: true, onClick: () => {
                    _modal.data.current.recurring_id = null;
                    _modal.data.current.edit_recurring_view = null;
                    setVisibility(false, true);
                } }, 'Cancel')) : (React.createElement(React.Fragment, null)),
        });
        return () => {
            useModal({
                header: React.createElement(React.Fragment, null),
                middleButton: React.createElement(React.Fragment, null),
            });
        };
    }, [currentView]);
    (0, react_1.useEffect)(() => {
        useModal({
            leftButton: currentView == 'details' ? (React.createElement(React.Fragment, null,
                React.createElement(semantic_ui_react_1.Button, { className: "no-border", color: 'red', basic: true, loading: isLoading, onClick: () => {
                        setDisableOnClickOutside(true);
                        setShowDeleteConfirmation(true);
                    } }, 'Delete'),
                React.createElement(semantic_ui_react_1.Confirm, { open: showDeleteConfirmation, onConfirm: () => __awaiter(void 0, void 0, void 0, function* () {
                        setShowDeleteConfirmation(false);
                        setIsLoading(true);
                        const results = yield utils._process(recurring_expenses_1.deleteRecurringExpense)(expense.id);
                        if (!results.error) {
                            // Show success message
                            if (data.hasOwnProperty('refreshExpenses')) {
                                yield data.refreshExpenses();
                            }
                            utils._showToast({
                                message: 'Successfully deleted recurring item',
                                type: 'success',
                            });
                            _modal.data.current.recurring_id = null;
                            _modal.data.current.edit_recurring_view = null;
                            setVisibility(false, true);
                        }
                        setIsLoading(false);
                    }), header: 'Confirm deletion', confirmButton: 'Yes, Confirm', size: 'tiny', onCancel: () => {
                        setShowDeleteConfirmation(false);
                        setDisableOnClickOutside(false);
                    }, cancelButton: 'No, Cancel', content: 'Are you sure you want to delete this recurring item?' }))) : (React.createElement(React.Fragment, null)),
        });
        return () => {
            useModal({
                leftButton: React.createElement(React.Fragment, null),
            });
        };
    }, [showDeleteConfirmation, currentView]);
    (0, react_1.useEffect)(() => {
        useModal({
            rightButton: currentView == 'details' ? (React.createElement(semantic_ui_react_1.Button, { color: 'orange', loading: isLoading, disabled: (0, checks_1.isNullOrEmpty)(expense.billing_date) ||
                    (0, checks_1.isNullOrEmpty)(expense.cadence) ||
                    (expense.cadence == 'custom' &&
                        (expense.granularity == null ||
                            isNaN(expense.quantity) ||
                            expense.quantity < 1 ||
                            expense.quantity == null)) ||
                    (0, checks_1.isNullOrEmpty)(expense.payee), onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setDisableOnClickOutside(true);
                    // Check if start date and end date are kosher
                    if (expense.start_date && expense.end_date) {
                        if (Moment(expense.start_date).isSameOrAfter(Moment(expense.end_date))) {
                            utils._showToast({
                                message: 'Recurring item end date cannot be before the start date!',
                                type: 'error',
                            });
                            return;
                        }
                    }
                    setIsLoading(true);
                    const results = yield utils._process(recurring_expenses_1.updateRecurringExpense)(expense.id, Object.assign({}, expense));
                    if (!results.error) {
                        if (data.hasOwnProperty('refreshExpenses')) {
                            yield data.refreshExpenses();
                        }
                        if (data.hasOwnProperty('amendTransactions')) {
                            yield data.amendTransactions({
                                bulkUpdate: [
                                    {
                                        where: { recurring_id: expense.id },
                                        update: Object.assign(Object.assign(Object.assign({}, ((expense === null || expense === void 0 ? void 0 : expense.payee) && {
                                            display_name: expense.payee,
                                        })), ((expense === null || expense === void 0 ? void 0 : expense.description) && {
                                            display_notes: expense.description,
                                        })), ((expense === null || expense === void 0 ? void 0 : expense.category_id) && {
                                            category_id: expense.category_id,
                                        })),
                                    },
                                ],
                            });
                        }
                        utils._showToast({
                            message: 'Successfully updated recurring item',
                            type: 'success',
                        });
                        _recurring.fetchItems();
                        setIsLoading(false);
                        _modal.data.current.recurring_id = null;
                        _modal.data.current.edit_recurring_view = null;
                        setVisibility(false, true);
                    }
                    setIsLoading(false);
                }) }, 'Save Changes')) : (React.createElement(semantic_ui_react_1.Button, { color: "orange", onClick: () => {
                    _modal.data.current.recurring_id = null;
                    _modal.data.current.edit_recurring_view = null;
                    setVisibility(false);
                } }, "Close")),
        });
    }, [expense, currentView, isLoading]);
    (0, react_1.useEffect)(() => {
        if (!expense) {
            return;
        }
        const pastPeriod = [];
        const currentPeriod = [];
        const nextPeriod = [];
        const startDate = Moment(data.currentPeriod).startOf('month');
        const endDate = Moment(data.currentPeriod).endOf('month');
        Object.keys(expense.occurrences).forEach(date => {
            const transactions = expense.occurrences[date];
            if ((transactions === null || transactions === void 0 ? void 0 : transactions.length) > 0) {
                transactions.forEach(tx => {
                    if (Moment(tx.date).isBefore(startDate)) {
                        pastPeriod.push(tx);
                    }
                    else if (Moment(tx.date).isBetween(startDate, endDate, 'days', '[]')) {
                        currentPeriod.push(tx);
                    }
                    else {
                        nextPeriod.push(tx);
                    }
                });
            }
            else {
                if (Moment(date).isBefore(startDate)) {
                    pastPeriod.push({ date });
                }
                else if (Moment(date).isBetween(startDate, endDate, 'days', '[]')) {
                    currentPeriod.push({ date });
                }
                else {
                    nextPeriod.push({ date });
                }
            }
        });
        const ui = [pastPeriod, currentPeriod, nextPeriod].map((period, periodIndex) => {
            if ((period === null || period === void 0 ? void 0 : period.length) == 0)
                return React.createElement(React.Fragment, null);
            const HAS_TRANSACTIONS = !!period.find(tx => tx === null || tx === void 0 ? void 0 : tx.id);
            return (React.createElement("div", { className: "log-container" },
                React.createElement("div", { className: "log-icon" },
                    React.createElement(semantic_ui_react_1.Icon, { circular: true, fitted: true, bordered: HAS_TRANSACTIONS ? false : true, 
                        // size={IS_CURRENT_PERIOD ? null : 'small'}
                        color: HAS_TRANSACTIONS
                            ? 'green'
                            : periodIndex == 0
                                ? 'grey'
                                : 'grey', inverted: HAS_TRANSACTIONS ? true : false, name: periodIndex == 0
                            ? 'arrow up'
                            : periodIndex == 1
                                ? 'calendar'
                                : 'arrow down' }),
                    React.createElement("div", { className: `log-icon-line thick` })),
                React.createElement("div", { className: "log-details" },
                    React.createElement("span", { className: "log-header large" }, periodIndex == 0
                        ? 'Previous Period'
                        : periodIndex == 1
                            ? 'Current Period'
                            : 'Next Period'),
                    React.createElement("div", { className: "log-items" }, period === null || period === void 0 ? void 0 :
                        period.sort((a, b) => {
                            return Moment(a).isBefore(Moment(b)) ? -1 : 1;
                        }).map((tx, idx) => {
                            if (tx.id) {
                                return (React.createElement("p", { key: `period-${idx}`, className: `monospace ${idx == period.length - 1 ? '' : 'mb-05rem'}` },
                                    React.createElement(semantic_ui_react_1.Icon, { name: "check circle", color: "green" }),
                                    ' ',
                                    Moment(tx.date).format(_user.getFormat('month_day_year')),
                                    ' ',
                                    "for",
                                    ' ',
                                    React.createElement("span", { className: tx.amount < 0 ? 'color--green' : '' }, (0, format_1.toPrice)(Math.abs(tx.amount), tx.currency))));
                            }
                            else {
                                return (React.createElement("p", { key: `period-${idx}`, className: `monospace ${idx == period.length - 1 ? '' : 'mb-05rem'}` },
                                    Moment(tx.date).isBefore(Moment()) ? (React.createElement(semantic_ui_react_1.Icon, { name: "exclamation circle", color: "yellow" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "clock outline", color: "grey" })),
                                    ' ',
                                    "Expected",
                                    ' ',
                                    Moment(tx.date).format(_user.getFormat('month_day_year')),
                                    ' ',
                                    "(",
                                    Moment(tx.date).fromNow(),
                                    ")"));
                            }
                        }),
                        (period === null || period === void 0 ? void 0 : period.filter(o => !!o.id).length) > 1 &&
                            expense.cadence == 'monthly' && (React.createElement(semantic_ui_react_1.Message, null, "Looks like you have multiple recurring transactions for this period. To consolidate, consider grouping these transactions or changing the dates of one of them."))))));
        });
        setRecurringTxsUi(ui);
    }, [expense]);
    (0, react_1.useEffect)(() => {
        var _a, _b;
        setExpense(data.recurring || _recurring.getById((_b = (_a = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.recurring_id));
    }, [data.recurring]);
    return !expense ? null : (React.createElement("div", { id: "recurring-modal-detail-view", className: "g-recurring-modal" },
        currentView == 'details' && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "transaction-details" },
                React.createElement("h3", { className: "flex--space-between" }, "Recurring transactions"),
                React.createElement("div", null, recurringTxsUi),
                React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", className: "mb-1rem", icon: true, labelPosition: "right", onClick: () => {
                        _modal.data.current.recurring_id = null;
                        _modal.data.current.edit_recurring_view = null;
                        setVisibility(false);
                        history.push({
                            pathname: '/transactions',
                            search: `?match=any&recurring=${expense.id}&time=all`,
                        });
                    } },
                    "View past recurring transactions",
                    React.createElement(semantic_ui_react_1.Icon, { name: "arrow right" }))),
            React.createElement("div", { className: `transaction-details` },
                React.createElement("h3", { className: "flex--space-between" }, "Details"),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Merchant Name"),
                    React.createElement(semantic_ui_react_1.Form, { widths: "equal" },
                        React.createElement(EditableString_1.default, { showError: (0, checks_1.isNullOrEmpty)(expense.payee), identifier: `transaction-payee`, firstValue: expense.payee, location: 'modal', autosuggest: true, state: 'Editing', shouldSaveOnChange: true, onSave: payee => {
                                setDisableOnClickOutside(true);
                                setExpense(Object.assign(Object.assign({}, expense), { payee }));
                            } }))),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Expected Amount"),
                    React.createElement(semantic_ui_react_1.Form, { widths: "equal", className: "mb-05rem" },
                        React.createElement(EditablePrice_1.default, { identifier: `transaction-amount`, amount: expense.amount, showSignLabel: true, currency: expense.currency, location: 'modal', state: 'Editing', saveOnChange: true, onSave: (amount, currency) => {
                                setDisableOnClickOutside(true);
                                setExpense(Object.assign(Object.assign({}, expense), { amount, currency }));
                            } }))),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Description"),
                    React.createElement(semantic_ui_react_1.Form, { widths: "equal" },
                        React.createElement(EditableString_1.default, { identifier: `expense-description`, firstValue: expense.description, location: 'modal', autosuggest: true, state: 'Editing', shouldSaveOnChange: true, onSave: description => {
                                setDisableOnClickOutside(true);
                                setExpense(Object.assign(Object.assign({}, expense), { description }));
                            } }))),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Paid From"),
                    React.createElement(semantic_ui_react_1.Form, { widths: "equal" },
                        React.createElement(EditableAsset_1.default, { defaultSelection: {
                                source: expense.plaid_account_id
                                    ? 'plaid'
                                    : expense.asset_id
                                        ? 'manual'
                                        : null,
                                id: expense.plaid_account_id || expense.asset_id || -1,
                            }, onSave: value => {
                                setDisableOnClickOutside(true);
                                setExpense(Object.assign(Object.assign({}, expense), { [value.source == 'plaid'
                                        ? 'plaid_account_id'
                                        : 'asset_id']: value.id > -1 ? value.id : null, [value.source == 'plaid'
                                        ? 'asset_id'
                                        : 'plaid_account_id']: null }));
                            } }))),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Category"),
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(EditableCategory_1.default, { identifier: `bulk-edit-category`, firstValue: expense.category_id, placeholder: 'Select category', state: 'Editing', location: 'modal', allowSelectingGroups: true, onSave: (value) => __awaiter(void 0, void 0, void 0, function* () {
                                        setDisableOnClickOutside(true);
                                        setExpense(Object.assign(Object.assign({}, expense), { category_id: value }));
                                    }) }))))),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Cadence"),
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(EditableCadence_1.default, { state: 'Editing', recurringItem: expense, onSave: obj => {
                                        setDisableOnClickOutside(true);
                                        setExpense(Object.assign(Object.assign({}, expense), obj));
                                    } }))))),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null,
                        "Anchor date",
                        ' ',
                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) },
                            React.createElement("p", null, "Specify a date to help calculate expected transaction dates. For example, if you have a recurring item that is due on the 15th of every month, set the anchor date to the 15th of this month."),
                            React.createElement("p", null, "For end of the month, set the anchor date to the 31st of a valid month."))),
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(EditableDate_1.default, { showError: !expense.billing_date, identifier: "recurring-billing-date", firstValue: expense.anchor_date, state: 'Editing', location: 'modal', format: 'month_day_year', onSave: anchor_date => {
                                        setDisableOnClickOutside(true);
                                        setExpense(Object.assign(Object.assign({}, expense), { billing_date: anchor_date }));
                                    } }))))),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Notes"),
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(semantic_ui_react_1.TextArea, { rows: 2, value: expense.notes || '', onChange: (e, { value }) => {
                                        setDisableOnClickOutside(true);
                                        setExpense(Object.assign(Object.assign({}, expense), { notes: value }));
                                    } }))))),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Created On"),
                    React.createElement("span", null, Moment(expense.created_at).format(_user.getMonthDayYearFormat())))),
            expense.type === 'cleared' && (React.createElement("div", { className: "transaction-details" },
                React.createElement("h3", null, "Active period"),
                React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", null,
                                "Start Date",
                                React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) },
                                    "Specify a start date for your recurring",
                                    ' ',
                                    ((_e = _categories.get(expense.category_id)) === null || _e === void 0 ? void 0 : _e.is_income)
                                        ? 'income'
                                        : 'expense',
                                    ". For example, if you started a Netflix subscription this month, set the start date and it will only start counting in your recurring",
                                    ' ',
                                    ((_f = _categories.get(expense.category_id)) === null || _f === void 0 ? void 0 : _f.is_income)
                                        ? 'income'
                                        : 'expense',
                                    "s from that day!")),
                            React.createElement(EditableDate_1.default, { identifier: "recurring-active-period", firstValue: expense.start_date, state: 'Editing', monthPicker: true, location: 'modal', format: 'month_day_year', onSave: start_date => {
                                    setDisableOnClickOutside(true);
                                    setExpense(Object.assign(Object.assign({}, expense), { start_date }));
                                } })),
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", null,
                                "End Date",
                                ' ',
                                React.createElement(semantic_ui_react_1.Popup, { inverted: true, position: 'top left', trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) },
                                    "Specify an end date for your recurring",
                                    ' ',
                                    ((_g = _categories.get(expense.category_id)) === null || _g === void 0 ? void 0 : _g.is_income)
                                        ? 'income'
                                        : 'expense',
                                    ". For example, if you cancelled a Netflix subscription this month, set the end date and it will stop counting in your recurring",
                                    ' ',
                                    ((_h = _categories.get(expense.category_id)) === null || _h === void 0 ? void 0 : _h.is_income)
                                        ? 'income'
                                        : 'expense',
                                    "s after that day!")),
                            React.createElement(EditableDate_1.default, { identifier: "recurring-active-period", firstValue: expense.end_date, state: 'Editing', monthPicker: true, jumpToEnd: true, location: 'modal', format: 'month_day_year', onSave: end_date => {
                                    setDisableOnClickOutside(true);
                                    setExpense(Object.assign(Object.assign({}, expense), { end_date: end_date ? (0, dates_1.getThisMonthEnd)(end_date) : null }));
                                } })))))),
            React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", className: "mb-05rem", color: "orange", loading: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setDisableOnClickOutside(true);
                    // Switch view
                    _modal.data.current.previous = 'EDIT_RECURRING';
                    _modal.data.current.previous_text = 'Recurring Item Details';
                    _modal.data.current.recurring = {
                        id: expense.id,
                        payee: expense.payee,
                        category_id: expense.category_id,
                        institution_name: expense.institution_name,
                        asset_institution_name: expense.asset_institution_name,
                        plaid_account_name: expense.plaid_account_name,
                        asset_name: expense.asset_name,
                        amount: expense.amount,
                        currency: expense.currency,
                        flipSign: _user.settings['show_debits_as_negative'],
                        transactions: expense.transactions,
                    };
                    switchView('MERGE_RECURRING');
                }) }, "Merge with existing recurring item"))),
        currentView == 'rules' && (React.createElement(React.Fragment, null,
            ((_j = expense.rules) === null || _j === void 0 ? void 0 : _j.length) == 0 || !expense.rules ? (React.createElement(semantic_ui_react_1.Message, { error: true },
                React.createElement("p", null,
                    React.createElement("b", null,
                        React.createElement(semantic_ui_react_1.Icon, { name: "exclamation triangle" }),
                        " No rule configuration found")),
                React.createElement("p", null,
                    "There is no associated rule found, which means there is no way for the system to automatically match future transactions to this recurring",
                    ' ',
                    ((_k = _categories.get(expense.category_id)) === null || _k === void 0 ? void 0 : _k.is_income)
                        ? 'income'
                        : 'expense',
                    "."),
                React.createElement("p", null,
                    React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "mini", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setDisableOnClickOutside(true);
                            // Set modal data
                            _modal.data.current.rule = {
                                criteria_payee_name: expense.payee,
                                criteria_payee_name_match: 'contain',
                                criteria_amount: expense.amount,
                                criteria_amount_currency: expense.currency,
                                criteria_amount_match: 'exactly',
                                criteria_asset_id: expense.asset_id
                                    ? expense.asset_id
                                    : null,
                                criteria_plaid_account_id: expense.plaid_account_id
                                    ? `plaid_${expense.plaid_account_id}`
                                    : null,
                                criteria_notes: expense.notes,
                                criteria_day: Moment(expense.billing_date, 'YYYY-MM-DD').format('D'),
                                criteria_day_match: 'exactly',
                                recurring_id: expense.id,
                            };
                            _modal.data.current.previous = 'EDIT_RECURRING';
                            _modal.data.current.previous_text = 'Recurring Item Details';
                            // Switch view
                            switchView('ADD_RULE');
                        }) }, "Create rule configuration")))) : (React.createElement(semantic_ui_react_1.Message, { info: true },
                "Rules help determine which transactions will get automatically linked to this recurring item. A recurring item can have multiple rules associated. Click on the ",
                React.createElement(semantic_ui_react_1.Icon, { name: "edit", fitted: true }),
                " icon to edit any rule below.")), (_l = expense.rules) === null || _l === void 0 ? void 0 :
            _l.map((rule, index) => {
                return (React.createElement("div", { className: "transaction-details", key: `rule-${index}` },
                    React.createElement("h3", { className: "flex--space-between" },
                        "Match Configuration",
                        ' ',
                        expense.rules.length > 1
                            ? `(${index + 1} of ${expense.rules.length})`
                            : '',
                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, position: "top right", size: "mini", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "edit", color: "green", className: "clickable", onClick: () => {
                                    setDisableOnClickOutside(true);
                                    // Set modal data
                                    _modal.data.current.rule = rule;
                                    _modal.data.current.previous = 'EDIT_RECURRING';
                                    _modal.data.current.previous_text =
                                        'Recurring Item Details';
                                    // Switch view
                                    switchView('ADD_RULE');
                                } }) }, "Edit Rule")),
                    rule.criteria_payee_name && rule.criteria_payee_name_match && (React.createElement("div", { className: "transaction-detail" },
                        React.createElement("label", null,
                            "Payee",
                            ' ',
                            MATCH_TYPES_MAPPING[rule.criteria_payee_name_match]),
                        React.createElement("span", null, rule.criteria_payee_name))),
                    rule.criteria_amount &&
                        rule.criteria_amount_currency &&
                        rule.criteria_amount_match && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "transaction-detail" },
                            React.createElement("label", null,
                                "Amount",
                                ' ',
                                MATCH_TYPES_MAPPING[rule.criteria_amount_match]),
                            rule.criteria_amount_match !== 'between' && (React.createElement("span", { className: `${parseInt(rule.criteria_amount) < 0
                                    ? 'color--green'
                                    : ''}` }, (0, format_1.toPrice)(data.flipSign
                                ? -1 * rule.criteria_amount
                                : rule.criteria_amount, rule.criteria_amount_currency))),
                            rule.criteria_amount_match === 'between' && (React.createElement(React.Fragment, null,
                                React.createElement("span", null,
                                    React.createElement("span", { className: `display--inline-block ${parseInt(rule.criteria_amount) < 0
                                            ? 'color--green'
                                            : ''}` }, (0, format_1.toPrice)(data.flipSign
                                        ? -1 * rule.criteria_amount
                                        : rule.criteria_amount, rule.criteria_amount_currency)),
                                    ' ',
                                    "and",
                                    ' ',
                                    React.createElement("span", { className: `display--inline-block ${parseInt(rule.criteria_amount_2) < 0
                                            ? 'color--green'
                                            : ''}` }, (0, format_1.toPrice)(data.flipSign
                                        ? -1 * rule.criteria_amount_2
                                        : rule.criteria_amount_2, rule.criteria_amount_currency)))))))),
                    rule.criteria_day && rule.criteria_day_match && (React.createElement("div", { className: "transaction-detail" },
                        React.createElement("label", null,
                            "Paid",
                            ' ',
                            rule.criteria_day_match == 'exactly'
                                ? 'on'
                                : rule.criteria_day_match),
                        React.createElement("span", null,
                            "Day ",
                            rule.criteria_day,
                            rule.criteria_day_match === 'between' && (React.createElement(React.Fragment, null,
                                " and day ",
                                rule.criteria_day_2))))),
                    rule.criteria_asset_id && (React.createElement("div", { className: "transaction-detail" },
                        React.createElement("label", null, "Paid from"),
                        React.createElement("span", null, _assets.getAssetName(rule.criteria_asset_id)))),
                    rule.criteria_plaid_account_id && (React.createElement("div", { className: "transaction-detail" },
                        React.createElement("label", null, "Posted from account"),
                        React.createElement("span", null, _assets.getPlaidAccountName(rule.criteria_plaid_account_id)))),
                    rule.criteria_notes && rule.criteria_notes_match && (React.createElement("div", { className: "transaction-detail" },
                        React.createElement("label", null,
                            "Notes ",
                            MATCH_TYPES_MAPPING[rule.criteria_notes_match]),
                        React.createElement("span", null, rule.criteria_notes)))));
            })))));
};
exports.default = EditModal;
