"use strict";
/**
 *  Nav.tsx
 *  Global navigation
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const qs = require("query-string");
const react_router_dom_1 = require("react-router-dom");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_1 = require("@helpers/format");
const UserProvider_1 = require("@providers/UserProvider");
const SwitchAccount_1 = require("./SwitchAccount");
const plausible_1 = require("@/helpers/plausible");
const user_1 = require("@/actions/user");
// Maps top-level url to section
const Nav = ({ hasBanner }) => {
    var _a, _b;
    let history = (0, react_router_dom_1.useHistory)();
    let location = (0, react_router_dom_1.useLocation)();
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [navItem, setNavItem] = (0, react_1.useState)('home');
    const [subNavItem, setSubNavItem] = (0, react_1.useState)('overview');
    const [openMenu, setOpenMenu] = (0, react_1.useState)(null);
    const [openHelp, setOpenHelp] = (0, react_1.useState)(false);
    const [openSwitch, setOpenSwitch] = (0, react_1.useState)(false);
    const [showLoggingOut, setShowLoggingOut] = (0, react_1.useState)(false);
    const NavItems = {
        overview: 'home',
        trends: 'home',
        stats: 'home',
        'net-worth': 'home',
        calendar: 'home',
        // finances
        budget: 'finances',
        transactions: 'finances',
        // holdings: 'finances',
        recurring: 'finances',
        analyze: 'finances',
        // setup
        categories: 'setup',
        accounts: 'setup',
        tags: 'setup',
        rules: 'setup',
        merchants: 'setup',
        // profile: 'preferences',
        settings: 'settings',
        billing: 'settings',
        community: 'settings',
        developers: 'settings',
        profile: 'settings',
        refer: 'settings',
        // 'gift-cards': 'gift-cards',
    };
    const MenuItems = {
        login: ['login', 'register'],
        home: ['overview', 'net-worth', 'stats', 'trends', 'calendar'],
        finances: [
            ...new Set([
                _user.settings['finances_default_view'] || 'budget',
                ...['budget', 'transactions', 'recurring', 'analyze'],
            ]),
        ],
        setup: [
            ...new Set([
                _user.settings['setup_default_view'] || 'accounts',
                ...['accounts', 'categories', 'tags', 'rules', 'merchants'],
            ]),
        ],
        // preferences: ['profile'],
        // 'gift-cards': ['gift-cards'],
        settings: [
            ...new Set([
                ...['settings', 'billing', 'community', 'developers', 'profile'],
                ...(_user.isAdmin ? ['refer'] : []),
            ]),
        ],
    };
    (0, react_1.useEffect)(() => {
        // This triggers for other open tabs
        window.addEventListener('storage', obj => {
            if (obj.key == '_lm_account_switched') {
                window.location.reload();
            }
        });
        getToken();
    }, []);
    const getToken = () => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield (0, user_1.getCannyToken)();
        // Initialize Canny
        window['Canny']('initChangelog', Object.assign({ appID: '5e8dfd734afa670fb480a3d6', position: 'bottom', align: 'right' }, (results ? { ssoToken: results } : {})));
    });
    (0, react_1.useEffect)(() => {
        if (location.hash == '#profile') {
            history.push('/profile');
        }
        else if (location.hash == '#refer') {
            history.push('/refer');
        }
        else if (location.hash == '#switch') {
            setOpenSwitch(true);
        }
    }, [location.hash]);
    (0, react_1.useEffect)(() => {
        setOpenMenu(false);
        setOpenSwitch(false);
        window.scrollTo(0, 0);
        if (location.pathname.indexOf('setup') > -1)
            return;
        const page = location.pathname.match(/([a-zA-Z_-]+)/g);
        if ((page === null || page === void 0 ? void 0 : page.length) > 0) {
            setSubNavItem(page[0]);
            setNavItem(NavItems[page[0]]);
        }
    }, [location.pathname]);
    const subMenuItems = MenuItems[navItem]
        ? MenuItems[navItem].map(item => {
            return (React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-${item}`, name: (0, format_1.capitalize)(item.replace('-', ' ')), active: subNavItem === item, as: react_router_dom_1.Link, to: `/${item}` }));
        })
        : [];
    return (React.createElement("div", { className: `${hasBanner ? 'has-fixed-banner' : ''}` },
        React.createElement("div", { className: `nav-mobile` },
            React.createElement(semantic_ui_react_1.Menu, { icon: true },
                React.createElement(semantic_ui_react_1.Menu.Menu, { position: "left" },
                    React.createElement(semantic_ui_react_1.Menu.Item, { name: "home" },
                        React.createElement("div", { className: "yellow-mascot fitted clickable", onClick: () => {
                                history.push('/overview');
                            } }))),
                React.createElement(semantic_ui_react_1.Menu.Menu, { position: "right" },
                    React.createElement(semantic_ui_react_1.Menu.Item, { name: 'switch', style: { width: '67px' }, active: false, onClick: () => {
                            setOpenSwitch(true);
                        } },
                        React.createElement("div", { className: "account-icon-letter ml-1rem absolute" },
                            React.createElement("span", { className: "letter" }, Array.from(((_a = _user.allAccounts.find(o => o.account_id == _user.accountId)) === null || _a === void 0 ? void 0 : _a.account_display_name) || '')[0]),
                            _user.allAccounts.filter(o => o.pending).length ? (React.createElement("span", { className: "corner" },
                                React.createElement("span", { className: "corner-number" }, _user.allAccounts.filter(o => o.pending).length))) : (React.createElement(React.Fragment, null)))),
                    React.createElement(semantic_ui_react_1.Menu.Item, { name: "open", active: false, onClick: () => {
                            setOpenMenu(!openMenu);
                        } },
                        React.createElement(semantic_ui_react_1.Icon, { name: "bars" })))),
            openMenu && (React.createElement(semantic_ui_react_1.Menu, { fluid: true, secondary: true, vertical: true },
                React.createElement(semantic_ui_react_1.Menu.Item, null,
                    React.createElement(semantic_ui_react_1.Menu.Header, null, "Home"),
                    React.createElement(semantic_ui_react_1.Menu.Menu, null,
                        React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-overview`, name: 'Overview', active: subNavItem === 'overview', as: react_router_dom_1.Link, to: `/overview` }),
                        React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-net-worth`, name: 'Net Worth', active: subNavItem === 'net-worth', as: react_router_dom_1.Link, to: `/net-worth` }),
                        React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-stats`, name: 'Stats', active: subNavItem === 'stats', as: react_router_dom_1.Link, to: `/stats` }),
                        React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-trends`, name: 'Trends', active: subNavItem === 'trends', as: react_router_dom_1.Link, to: `/trends` }),
                        React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-calendar`, name: 'Calendar', active: subNavItem === 'calendar', as: react_router_dom_1.Link, to: `/calendar` }))),
                React.createElement(semantic_ui_react_1.Menu.Item, null,
                    React.createElement(semantic_ui_react_1.Menu.Header, null, "Finances"),
                    React.createElement(semantic_ui_react_1.Menu.Menu, null,
                        React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-transactions`, name: 'Transactions', active: subNavItem === 'transactions', as: react_router_dom_1.Link, to: `/transactions` }),
                        React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-budget`, name: 'Budget', active: subNavItem === 'budget', as: react_router_dom_1.Link, to: `/budget` }),
                        React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-recurring`, name: 'Recurring', active: subNavItem === 'recurring', as: react_router_dom_1.Link, to: `/recurring` }),
                        React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-analyze`, name: 'Analyze', active: subNavItem === 'analyze', as: react_router_dom_1.Link, to: `/analyze` }))),
                React.createElement(semantic_ui_react_1.Menu.Item, null,
                    React.createElement(semantic_ui_react_1.Menu.Header, null, "Setup"),
                    React.createElement(semantic_ui_react_1.Menu.Menu, null,
                        React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-accounts`, name: 'Accounts', active: subNavItem === 'accounts', as: react_router_dom_1.Link, to: `/accounts` }),
                        React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-categories`, name: 'Categories', active: subNavItem === 'categories', as: react_router_dom_1.Link, to: `/categories` }),
                        React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-tags`, name: 'Tags', active: subNavItem === 'tags', as: react_router_dom_1.Link, to: `/tags` }),
                        React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-rules`, name: 'Rules', active: subNavItem === 'rules', as: react_router_dom_1.Link, to: `/rules` }),
                        React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-merchants`, name: 'Merchants', active: subNavItem === 'merchants', as: react_router_dom_1.Link, to: `/merchants` }))),
                React.createElement(semantic_ui_react_1.Menu.Item, null,
                    React.createElement(semantic_ui_react_1.Menu.Header, null, "Settings"),
                    React.createElement(semantic_ui_react_1.Menu.Menu, null,
                        React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-settings`, name: 'General', active: subNavItem === 'settings', as: react_router_dom_1.Link, to: `/settings` }),
                        React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-billing`, name: 'Billing', active: subNavItem === 'billing', as: react_router_dom_1.Link, to: `/billing` }),
                        React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-developers`, name: 'Developers', active: subNavItem === 'developers', as: react_router_dom_1.Link, to: `/developers` }),
                        React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-community`, name: 'Community', active: subNavItem === 'community', as: react_router_dom_1.Link, to: `/community` }),
                        React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-profile`, name: 'Profile', active: subNavItem === 'profile', as: react_router_dom_1.Link, to: `/profile` }),
                        _user.isAdmin && (React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-refer`, name: 'Refer', active: subNavItem === 'refer', as: react_router_dom_1.Link, to: `/refer` })))),
                React.createElement(semantic_ui_react_1.Menu.Item, null,
                    React.createElement(semantic_ui_react_1.Menu.Menu, null,
                        React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-help`, name: 'Help', active: false, onClick: () => {
                                console.log('Test');
                                setOpenHelp(true);
                            } }),
                        React.createElement(semantic_ui_react_1.Menu.Item, { key: `subnav-item-logout`, name: 'Logout', active: subNavItem === 'logout', onClick: () => {
                                setShowLoggingOut(true);
                                setOpenMenu(false);
                                _user.signout(false);
                            } })))))),
        React.createElement("div", { className: `nav-full` },
            React.createElement(semantic_ui_react_1.Menu, { tabular: true },
                React.createElement(semantic_ui_react_1.Container, null,
                    React.createElement(semantic_ui_react_1.Menu.Item, { name: "Home", active: navItem === 'home', as: react_router_dom_1.Link, to: `/${MenuItems['home'][0].replace(/\W/, '')}` }),
                    React.createElement(semantic_ui_react_1.Menu.Item, { name: "Finances", active: navItem === 'finances', as: react_router_dom_1.Link, to: `/${MenuItems['finances'][0].replace(/\W/, '')}` }),
                    React.createElement(semantic_ui_react_1.Menu.Item, { name: "setup", active: navItem === 'setup', as: react_router_dom_1.Link, to: `/${MenuItems['setup'][0].replace(/\W/, '')}` }),
                    React.createElement(semantic_ui_react_1.Menu.Menu, { position: "right" },
                        React.createElement(semantic_ui_react_1.Menu.Item, { name: "settings", active: navItem === 'settings', as: react_router_dom_1.Link, to: `/${MenuItems['settings'][0].replace(/\W/, '')}` },
                            React.createElement("span", { className: "cog-icon" })),
                        React.createElement(semantic_ui_react_1.Menu.Item, { name: "bullhorn", as: react_router_dom_1.Link, to: '#' },
                            React.createElement(semantic_ui_react_1.Icon.Group, null,
                                React.createElement(semantic_ui_react_1.Icon, { name: "bullhorn", "data-canny-changelog": true, onClick: () => {
                                        (0, plausible_1.trackEvent)('Opened Canny changelog');
                                    } }))),
                        React.createElement(semantic_ui_react_1.Menu.Item, { name: 'help', active: false, onClick: () => {
                                setOpenHelp(true);
                            } }),
                        React.createElement(semantic_ui_react_1.Menu.Item, { name: 'switch', active: false, onClick: () => {
                                setOpenSwitch(true);
                            } },
                            React.createElement("div", { className: "account-icon-letter absolute" },
                                React.createElement("span", { className: "letter" }, Array.from(((_b = _user.allAccounts.find(o => o.account_id == _user.accountId)) === null || _b === void 0 ? void 0 : _b.account_display_name) || '')[0]),
                                _user.allAccounts.filter(o => o.pending).length ? (React.createElement("span", { className: "corner" },
                                    React.createElement("span", { className: "corner-number" }, _user.allAccounts.filter(o => o.pending).length))) : (React.createElement(React.Fragment, null)))),
                        React.createElement(semantic_ui_react_1.Menu.Item, { name: "logout", active: subNavItem === 'logout', onClick: () => {
                                history.push('/logout');
                                // setShowLoggingOut(true)
                                // _user.signout(false)
                            } })))),
            React.createElement(semantic_ui_react_1.Menu, { pointing: true, secondary: true },
                React.createElement(semantic_ui_react_1.Container, null, subMenuItems))),
        React.createElement(SwitchAccount_1.default, { open: openSwitch, onClose: () => {
                setOpenSwitch(false);
            } }),
        React.createElement(semantic_ui_react_1.Modal, { open: showLoggingOut, onClose: () => {
                setShowLoggingOut(false);
            }, size: "mini", dimmer: 'inverted' },
            React.createElement(semantic_ui_react_1.Modal.Header, { className: "center-align" }, "See you next time!"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("div", { className: "flex--column flex--center" },
                    React.createElement("div", { id: "logo-container" },
                        React.createElement("div", { id: "logo" }),
                        React.createElement("div", { id: "shadow" })),
                    React.createElement("p", null, "Please wait while we log you out...")))),
        React.createElement(semantic_ui_react_1.Modal, { open: openHelp, onClose: () => {
                setOpenHelp(false);
            }, size: "tiny", dimmer: 'inverted' },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Help & Support"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("div", { className: "help-row", onClick: () => {
                        setOpenHelp(false);
                        history.push({
                            search: qs.stringify(Object.assign(Object.assign({}, qs.parse(history.location.search)), { show_feedback: 'question' })),
                        });
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "bug", size: "large" }),
                    React.createElement("span", null, "Submit a bug or feedback")),
                React.createElement("div", { className: "help-row", onClick: () => {
                        setOpenHelp(false);
                        history.push({
                            search: qs.stringify(Object.assign(Object.assign({}, qs.parse(history.location.search)), { sync_support: 'question' })),
                        });
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "cloud download", size: "large" }),
                    React.createElement("span", null, "Open bank syncing support ticket")),
                React.createElement("div", { className: "help-row", onClick: () => {
                        window.open('https://my.lunchmoney.app/canny');
                        setOpenHelp(false);
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "idea", size: "large" }),
                    React.createElement("span", null, "View and submit feature requests")),
                React.createElement("div", { className: "help-row", onClick: () => {
                        window.open('https://support.lunchmoney.app');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "book", size: "large" }),
                    React.createElement("span", null, "Visit our knowledge base")),
                React.createElement("div", { className: "help-row", onClick: () => {
                        history.push('/community');
                        setOpenHelp(false);
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "users", size: "large" }),
                    React.createElement("span", null, "Join our Discord community")),
                React.createElement("div", { className: "help-row", onClick: () => {
                        window.open('mailto:support@lunchmoney.app');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "mail", size: "large" }),
                    React.createElement("span", null, "Email support@lunchmoney.app"))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        setOpenHelp(false);
                    } }, "Close")))));
};
exports.default = Nav;
