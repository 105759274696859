"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteCategory = exports.updateOrder = exports.updateCategory = exports.getAllCategories = exports.editCategoryGroup = exports.createCategoryGroup = exports.countDependents = exports.mergeAndDelete = exports.createCategory = exports.deleteCategories = exports.createCategories = void 0;
const common_1 = require("./common");
/**
 *
 *
 */
const createCategories = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`categories/bulk`, body);
});
exports.createCategories = createCategories;
/**
 *
 *
 */
const deleteCategories = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`categories/bulk/delete`, body);
});
exports.deleteCategories = deleteCategories;
/**
 *
 *
 */
const createCategory = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`categories`, body);
});
exports.createCategory = createCategory;
/**
 *
 *
 */
const mergeAndDelete = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`categories/merge`, opts);
});
exports.mergeAndDelete = mergeAndDelete;
const countDependents = (category_id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`categories/${category_id}/dependents`);
});
exports.countDependents = countDependents;
/**
 *
 *
 */
const createCategoryGroup = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`categories/group`, body);
});
exports.createCategoryGroup = createCategoryGroup;
/**
 *
 *
 */
const editCategoryGroup = (group_id, body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`categories/group/${group_id}`, body);
});
exports.editCategoryGroup = editCategoryGroup;
/**
 *
 *
 */
const getAllCategories = (params) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`categories`, params);
});
exports.getAllCategories = getAllCategories;
/**
 *
 *
 */
const updateCategory = (id, body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`categories/${id}`, body);
});
exports.updateCategory = updateCategory;
/**
 *
 *
 */
const updateOrder = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`categories/order`, body);
});
exports.updateOrder = updateOrder;
/**
 *
 *
 */
const deleteCategory = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.del)(`categories/${id}`, {});
});
exports.deleteCategory = deleteCategory;
