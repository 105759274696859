"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
const ContainerHeader = ({ backLink = null, forwardLink = null, title, dropdownOnChange = (e, { value }) => { }, dropdownOptions = [], showBackToCurrentMonthButton = false, jumpBackToCurrentMonth = () => { }, }) => {
    return (React.createElement("div", { className: "toggle-header-container" },
        backLink && forwardLink ? (React.createElement("div", { className: "flex--align-center" },
            React.createElement("div", { className: "mr-1rem mt-05rem display--flex" },
                React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(react_router_dom_1.Link, { to: backLink },
                        React.createElement(semantic_ui_react_1.Icon, { name: "chevron left", color: "grey" })), inverted: true, size: "tiny" }, "Previous month"),
                React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(react_router_dom_1.Link, { to: forwardLink },
                        React.createElement(semantic_ui_react_1.Icon, { name: "chevron right", className: "ml-1rem", color: "grey" })), inverted: true, size: "tiny" }, "Next month")),
            React.createElement(semantic_ui_react_1.Header, { as: "h1" }, title))) : (React.createElement(semantic_ui_react_1.Header, { as: "h1" }, title)),
        React.createElement("div", { className: "flex--center" },
            showBackToCurrentMonthButton && (React.createElement(React.Fragment, null,
                React.createElement(semantic_ui_react_1.Button, { onClick: jumpBackToCurrentMonth, className: "no-border", basic: true, color: "orange" }, "Back to this month"))),
            dropdownOnChange && dropdownOptions && dropdownOptions.length > 0 && (React.createElement(semantic_ui_react_1.Dropdown, { className: "jump-dropdown", placeholder: "Jump to Month", value: '', onChange: dropdownOnChange, selectOnBlur: false, selection: true, options: dropdownOptions })))));
};
exports.default = ContainerHeader;
