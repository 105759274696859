"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trackEvent = exports.enableAutoPageviews = void 0;
const plausible_tracker_1 = require("plausible-tracker");
let plausibleClient = null;
let resetDomain = false;
const getClient = (domain = null) => {
    if (resetDomain || domain || !plausibleClient) {
        if (domain == null) {
            domain = 'localhost';
            if (window.location.href.indexOf('staging.lunchmoney') >= 0) {
                domain = 'staging.lunchmoney.app';
            }
            else if (window.location.href.indexOf('beta.lunchmoney') >= 0) {
                domain = 'beta.lunchmoney.app';
            }
            else if (window.location.href.indexOf('my.lunchmoney') >= 0) {
                domain = 'my.lunchmoney.app';
            }
            resetDomain = false;
        }
        else {
            // We're using a special domain right now. Make sure we go back to the original one next.
            resetDomain = true;
        }
        // We may want to force the domain sometimes
        plausibleClient = (0, plausible_tracker_1.default)({
            domain,
            trackLocalhost: false,
        });
    }
    return plausibleClient;
};
const enableAutoPageviews = () => {
    getClient().enableAutoPageviews();
};
exports.enableAutoPageviews = enableAutoPageviews;
const trackEvent = (eventName, props = {}, domain = null) => {
    getClient(domain).trackEvent(eventName, {
        // callback: () => console.log(`Sent event:`, { eventName, props, domain }),
        props,
    });
};
exports.trackEvent = trackEvent;
