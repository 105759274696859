"use strict";
/**
 * ModalProvider
 * Useful for passing data between modals, especially of different types
 * i.e. going to a Rules modal from Transactions page
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalProvider = exports.ModalContext = void 0;
const React = require("react");
const react_1 = require("react");
const NewModal_1 = require("@components/global/NewModal");
const ModalContext = (0, react_1.createContext)(undefined);
exports.ModalContext = ModalContext;
const ModalProvider = props => {
    const data = (0, react_1.useRef)({});
    const [showModalView, setShowModalView] = (0, react_1.useState)(null);
    const [modalData, setModalData] = (0, react_1.useState)({});
    const [modalUtils, setModalUtils] = (0, react_1.useState)({});
    const [modalCallback, setModalCallback] = (0, react_1.useState)(null);
    return (React.createElement(ModalContext.Provider, { value: {
            data,
            setShowModalView,
            setModalData,
            setModalUtils,
            setModalCallback,
        } },
        props.children,
        !!showModalView && (React.createElement(NewModal_1.default, { show: !!showModalView, setShow: (show) => {
                if (!show) {
                    setShowModalView(false);
                }
            }, data: modalData, utils: modalUtils, view: showModalView, closeCallback: () => __awaiter(void 0, void 0, void 0, function* () {
                !!modalCallback && modalCallback();
            }) }))));
};
exports.ModalProvider = ModalProvider;
