"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const AccountSetupOptions = ({ open, showExplainer = false }) => {
    const [option, setOption] = (0, react_1.useState)('start-fresh');
    return (React.createElement(semantic_ui_react_1.Modal, { open: open, size: "tiny", dimmer: "inverted" },
        React.createElement(semantic_ui_react_1.Modal.Content, null,
            showExplainer && (React.createElement(semantic_ui_react_1.Message, { info: true },
                React.createElement("p", null, "We auto-created a new budget for you! This happens when you have no available budgets associated with your account because all your budget(s) have been deleted or are being reset by you or the owner of a budget you're collaborating on."),
                React.createElement("p", null, "You can delete this budget at any time by going to Settings."))),
            React.createElement("div", { className: "display--flex flex--column width-100 g-register-modal" },
                React.createElement("div", { className: "display--flex" },
                    React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setOption('start-fresh');
                        }), className: `setup-option setup-primary-option flex--column mr-1rem ${option == 'start-fresh' ? 'added' : ''}` },
                        React.createElement(semantic_ui_react_1.Header, { as: "h2" }, "Start fresh"),
                        React.createElement("img", { src: "/public/images/fresh-circle.png" }),
                        React.createElement("p", null, "We'll walk you through setting up the basics in Lunch Money to help get you started!"),
                        React.createElement(semantic_ui_react_1.Icon, { name: option == 'start-fresh' ? 'check circle' : 'circle outline' })),
                    React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setOption('demo');
                        }), className: `setup-option setup-primary-option flex--column flex--align-center ${option == 'demo' ? 'added' : ''}` },
                        React.createElement(semantic_ui_react_1.Header, { as: "h2" }, "Set up demo"),
                        React.createElement("img", { src: "/public/images/demo-circle.png" }),
                        React.createElement("p", null, "Choose this option to explore Lunch Money with pre-filled data. You can start fresh at any time."),
                        React.createElement(semantic_ui_react_1.Icon, { name: option == 'demo' ? 'check circle' : 'circle outline' }))),
                React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setOption('mint');
                    }), className: `setup-option setup-secondary-option mb-1rem ${option == 'mint' ? 'added' : ''}` },
                    React.createElement("div", { className: "flex--align-center" },
                        React.createElement("span", { className: "mint-logo", style: { top: '1px', marginRight: '1rem' } }),
                        React.createElement("b", null, "Import my data from Mint")),
                    React.createElement(semantic_ui_react_1.Icon, { style: { top: '3px', position: 'relative' }, name: option == 'mint' ? 'check circle' : 'circle outline' })),
                React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setOption('none');
                    }), className: `setup-option setup-secondary-option ${option == 'none' ? 'added' : ''}` },
                    React.createElement("p", null, "Skip the walkthrough, just take me to my account!"),
                    React.createElement(semantic_ui_react_1.Icon, { name: option == 'none' ? 'check circle' : 'circle outline' })))),
        React.createElement(semantic_ui_react_1.Modal.Actions, null,
            React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                    if (option == 'demo') {
                        window.location.href = '/demo';
                    }
                    else if (option == 'start-fresh') {
                        window.location.href = '/setup/categories';
                    }
                    else if (option == 'mint') {
                        window.location.href = '/transactions/import';
                    }
                    else {
                        window.location.href = '/overview';
                    }
                } }, "Next"))));
};
exports.default = AccountSetupOptions;
