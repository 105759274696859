"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const format_1 = require("@helpers/format");
const checks_1 = require("@helpers/checks");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const EditableString_1 = require("@components/elements/EditableString");
const EditablePrice_1 = require("@components/elements/EditablePrice");
const EditableCadence_1 = require("@components/elements/EditableCadence");
const EditableCategory_1 = require("@components/elements/EditableCategory");
const EditableDate_1 = require("@components/elements/EditableDate");
const UserProvider_1 = require("@providers/UserProvider");
const RecurringExpenseRow = ({ onSave, onOpenModal, expense, currentPeriod, groupBy = null, flipSign, openTable, parentName = null, }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [saving, setSaving] = (0, react_1.useState)(false);
    const saveChanges = (id, obj) => __awaiter(void 0, void 0, void 0, function* () {
        setSaving(true);
        yield onSave(id, obj);
        setSaving(false);
    });
    const IS_YEARLY_EXPENSE = (expense === null || expense === void 0 ? void 0 : expense.cadence) === 'yearly';
    const EXPENSE_ALREADY_PAID = !(0, checks_1.isNullOrEmpty)(expense.transaction);
    const YEARLY_EXPENSE_SHOULD_BE_THIS_MONTH = !(0, checks_1.isNullOrEmpty)(expense.transaction)
        ? Moment(expense.transaction.date).month() ===
            Moment(currentPeriod).month() &&
            Moment(expense.transaction.date).year() === Moment(currentPeriod).year()
        : Moment(expense.billing_date).month() === Moment(currentPeriod).month() &&
            Moment(expense.billing_date).year() === Moment(currentPeriod).year();
    return (React.createElement(semantic_ui_react_1.Table.Row, null,
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "editable hover-toggle" },
            React.createElement(EditableString_1.default, { identifier: `recurring-${expense.id}-payee`, firstValue: expense.payee, autoFocus: true, location: 'inline', 
                // state={saving ? 'ReadOnly' : 'Default'}
                shouldSaveOnBlur: true, shouldSaveOnEnter: true, shouldSelectOnFocus: true, onSave: (payee) => __awaiter(void 0, void 0, void 0, function* () {
                    if (payee !== expense.payee) {
                        yield saveChanges(expense.id, { payee });
                    }
                }) })),
        _user.settings['allow_recurring_categories'] && groupBy !== 'category' && (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "editable" },
                React.createElement(EditableCategory_1.default, { state: 'Default', autoFocus: true, identifier: `recurring-${expense.id}-category`, firstValue: expense.category_id, location: 'inline', onSave: (category_id) => __awaiter(void 0, void 0, void 0, function* () {
                        if (category_id !== expense.category_id) {
                            yield saveChanges(expense.id, { category_id });
                        }
                    }) })))),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "editable hover-toggle" },
            React.createElement(EditableString_1.default, { identifier: `recurring-${expense.id}-description`, firstValue: expense.description, autoFocus: true, location: 'inline', emptyText: 'Add a description', blankIfNull: true, shouldSaveOnBlur: true, shouldSaveOnEnter: true, shouldSelectOnFocus: true, onSave: (description) => __awaiter(void 0, void 0, void 0, function* () {
                    if (description !== expense.description) {
                        yield saveChanges(expense.id, { description });
                    }
                }) })),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        groupBy !== 'cadence' && (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable padded-cell", onClick: () => {
                    onOpenModal(expense, 'EDIT_RECURRING');
                } },
                React.createElement(EditableCadence_1.default, { state: 'ReadOnly', recurringItem: expense, onSave: (cadence) => __awaiter(void 0, void 0, void 0, function* () {
                        yield saveChanges(expense.id, { cadence });
                    }) })),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }))),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: `${EXPENSE_ALREADY_PAID ? 'clickable padded-cell' : 'editable'}`, onClick: () => {
                if (EXPENSE_ALREADY_PAID) {
                    onOpenModal(expense, 'EDIT_RECURRING');
                }
            } }, EXPENSE_ALREADY_PAID ? (IS_YEARLY_EXPENSE && !YEARLY_EXPENSE_SHOULD_BE_THIS_MONTH ? (React.createElement("span", { className: "no-wrap" }, Moment(expense.transaction.date).format(_user.getMonthDayYearFormat()))) : (React.createElement("span", { className: "no-wrap" }, Moment(expense.transaction.date).format(_user.getMonthDayFormat())))) : (React.createElement(EditableDate_1.default, { identifier: `recurring-${expense.id}-billing-date`, firstValue: expense.billing_date, 
            // state={saving ? 'ReadOnly' : 'Default'}
            location: 'inline', autoFocus: true, format: IS_YEARLY_EXPENSE && !YEARLY_EXPENSE_SHOULD_BE_THIS_MONTH
                ? 'month_day_year'
                : 'month_day', onSave: (billing_date) => __awaiter(void 0, void 0, void 0, function* () {
                yield saveChanges(expense.id, { billing_date });
            }) }))),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: `right-align ${EXPENSE_ALREADY_PAID ? 'clickable padded-cell' : 'editable'}`, onClick: () => {
                if (EXPENSE_ALREADY_PAID) {
                    onOpenModal(expense, 'EDIT_RECURRING');
                }
            } }, EXPENSE_ALREADY_PAID ? (IS_YEARLY_EXPENSE && !YEARLY_EXPENSE_SHOULD_BE_THIS_MONTH ? (React.createElement("span", { className: `no-wrap ${flipSign && expense.transaction.amount < 0 ? 'color--green' : ''}` },
            (0, format_1.toPrice)(flipSign
                ? (-1 * expense.transaction.amount) / 12
                : expense.transaction.amount / 12, expense.transaction.currency),
            ' ',
            React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "info circle", color: "grey" }), position: "bottom left" },
                React.createElement("p", null,
                    "This is the paid amount (",
                    (0, format_1.toPrice)(flipSign
                        ? -1 * expense.transaction.amount
                        : expense.transaction.amount, expense.transaction.currency),
                    ") from your annual expense, divided by 12."),
                React.createElement("p", null, "This is for informational purposes only and is not reflected in your totals for this month.")))) : (React.createElement("span", { className: `no-wrap ${flipSign && expense.transaction.amount < 0 ? 'color--green' : ''}` }, (0, format_1.toPrice)(flipSign
            ? -1 * expense.transaction.amount
            : expense.transaction.amount, expense.transaction.currency)))) : (React.createElement(EditablePrice_1.default, { showSignLabel: true, identifier: `recurring-${expense.id}-amount`, amount: expense.amount, 
            // state={saving ? 'ReadOnly' : 'Default'}
            currency: expense.currency, location: 'inline-edit', onSave: (amount, currency) => __awaiter(void 0, void 0, void 0, function* () {
                yield saveChanges(expense.id, { amount, currency });
            }) }))),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable center-align", onClick: () => {
                onOpenModal(expense, 'EDIT_RECURRING');
            } },
            expense.notes && (React.createElement(semantic_ui_react_1.Popup, { position: 'top right', trigger: React.createElement(semantic_ui_react_1.Icon, { name: "sticky note outline", color: "grey" }) }, expense.notes)),
            !(0, checks_1.isNullOrEmpty)(expense.transaction) ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle", color: expense.type == 'suggested' ? 'grey' : 'green' })) : Moment().isAfter(Moment(expense.billing_date).add(1, 'day')) ? (React.createElement(semantic_ui_react_1.Icon, { name: "warning circle", color: expense.type == 'suggested' ? 'grey' : 'yellow' })) : (React.createElement(semantic_ui_react_1.Icon, { name: "wait", className: "color--grey" }))),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable center-align", onClick: () => {
                openTable(expense);
            } },
            React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "search plus", className: ' color--grey dark-grey-on-hover' }), inverted: true, size: "small" }, "View transactions (pop-up)")),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable center-align", onClick: () => {
                onOpenModal(expense, 'EDIT_RECURRING');
            } }, saving ? (React.createElement(semantic_ui_react_1.Loader, { size: "mini", active: true, inline: true, className: "margin-5" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "angle right", fitted: true, className: ' green-on-hover color--grey' })))));
};
exports.default = RecurringExpenseRow;
