"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteQuery = exports.updateQuery = exports.getQueries = exports.createQuery = void 0;
const common_1 = require("./common");
const createQuery = (obj) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`queries`, obj);
});
exports.createQuery = createQuery;
const getQueries = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`queries`, {});
});
exports.getQueries = getQueries;
const updateQuery = (query_id, update) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`queries/${query_id}`, update);
});
exports.updateQuery = updateQuery;
const deleteQuery = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.del)(`queries/${id}`, {});
});
exports.deleteQuery = deleteQuery;
