"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const LineChart_1 = require("@components/QueryTool/Chart/LineChart");
const BarChart_1 = require("@components/QueryTool/Chart/BarChart");
const StackedBar_1 = require("@components/QueryTool/Chart/StackedBar");
const PieChart_1 = require("@components/QueryTool/Chart/PieChart");
const UserProvider_1 = require("@providers/UserProvider");
const format_1 = require("@helpers/format");
const qs = require("query-string");
const Chart = ({ groupBy, baseUrl, transformedData, hiddenKeys, dataSetNames, timeGranularity, timeArray, tooltipType, currentView, filterDateStart, filterDateEnd, normalizeIncome, showPieGroups, toQuery, }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [data, setData] = (0, react_1.useState)([]);
    (0, react_1.useEffect)(() => {
        const chartEl = document.getElementById('query-tool-chart');
        const listener = event => {
            if (!chartEl.contains(event.toElement)) {
                document.getElementById('query-tool-tip').style.display = 'none';
            }
        };
        chartEl === null || chartEl === void 0 ? void 0 : chartEl.addEventListener('mouseleave', listener);
        return () => {
            chartEl === null || chartEl === void 0 ? void 0 : chartEl.removeEventListener('mouseleave', listener);
        };
    });
    /**
     * Tooltip callback
     */
    const labelCallback = context => {
        const index = context.datasetIndex;
        const timeIndex = context.dataIndex;
        const datum = context.dataset;
        const display = toDisplayPrice(datum.isIncome ? -1 * datum.data[timeIndex] : datum.data[timeIndex]);
        let percentChange = null;
        if (timeIndex > 0) {
            const curr = datum.data[timeIndex];
            const prev = datum.data[timeIndex - 1];
            if (prev) {
                percentChange = (0, format_1.toPercentage)((curr - prev) / prev);
                if (percentChange[0] != '-') {
                    percentChange = `+${percentChange}`;
                }
            }
        }
        const link = qs.stringify(Object.assign(Object.assign({}, baseUrl), toQuery(datum, timeArray[timeIndex])));
        return `<div class="row display--flex">
    <p class="circle" style="background-color:${datum.backgroundColor}"></p>
    <div>
      <p class="monospace mb-0">
        <span class="number ${display.className}">${display.text}</span>
        ${percentChange
            ? `<span class="percent ${percentChange[0] == '-' ? 'color--red' : 'color--green'}">(${percentChange})</span>`
            : ''}
      </p>
      <p class="label">${datum.label}</p>
      ${datum.counts[timeIndex] > 0
            ? `<p class="tx">${datum.counts[timeIndex]} transaction${datum.counts[timeIndex] > 1 ? 's' : ''}
            <a href="/transactions?${link}">
              <i aria-hidden="true" class="grey arrow alternate circle right icon clickable"></i>
            </a>
          </p>`
            : ''}
    </div>
  </div>`;
    };
    /**
     * Helper function to display the price in the label
     */
    const toDisplayPrice = price => {
        let text = '';
        let className = '';
        if (_user.settings['show_debits_as_negative']) {
            if (price < 0) {
                // This is income and should be positive and green
                className = 'color--green';
                text = (0, format_1.toPrice)(Math.abs(price), _user.primaryCurrency);
            }
            else {
                // This is expense and should be displayed with negative
                text = (0, format_1.toPrice)(price * -1, _user.primaryCurrency);
            }
        }
        else if (!_user.settings['show_debits_as_negative']) {
            if (price < 0) {
                // This is income and should be negative and green
                className = 'color--green';
                text = (0, format_1.toPrice)(price, _user.primaryCurrency);
            }
            else {
                // This is expense and should be displayed with positive
                text = (0, format_1.toPrice)(price, _user.primaryCurrency);
            }
        }
        return { text, className };
    };
    (0, react_1.useEffect)(() => {
        const _data = transformedData.map((dataset, datasetIndex) => {
            if (hiddenKeys.indexOf(`dataset-${datasetIndex}`) > -1) {
                return [];
            }
            else {
                return dataset.data.map(datum => {
                    if (!hiddenKeys ||
                        (hiddenKeys.indexOf(datum.id.toString()) == -1 &&
                            hiddenKeys.indexOf(`${datasetIndex}/${datum.id}`) == -1)) {
                        return datum;
                    }
                    else {
                        return Object.assign(Object.assign({}, datum), { hidden: true });
                    }
                });
            }
        });
        setData(_data);
    }, [hiddenKeys, transformedData]);
    return (React.createElement("div", { id: "query-tool-chart" },
        React.createElement("div", { id: "chart-container" },
            currentView == 'line' && (React.createElement(LineChart_1.default, { data: data, labels: timeArray, dataSetNames: dataSetNames, tooltipType: tooltipType, timeGranularity: timeGranularity, normalizeIncome: normalizeIncome, labelCallback: labelCallback })),
            currentView == 'bar' && (React.createElement(BarChart_1.default, { data: data, labels: timeArray, dataSetNames: dataSetNames, timeGranularity: timeGranularity, normalizeIncome: normalizeIncome, labelCallback: labelCallback })),
            currentView == 'stacked' && (React.createElement(StackedBar_1.default, { data: data, labels: timeArray, dataSetNames: dataSetNames, timeGranularity: timeGranularity, normalizeIncome: normalizeIncome, labelCallback: labelCallback })),
            currentView == 'pie' && (React.createElement(PieChart_1.default, { data: data, dataSetNames: dataSetNames, filterDateStart: filterDateStart, filterDateEnd: filterDateEnd, toDisplayPrice: toDisplayPrice, groupBy: groupBy, showPieGroups: showPieGroups, getLink: (datum, date) => {
                    return qs.stringify(Object.assign(Object.assign({}, baseUrl), toQuery(datum, date)));
                } }))),
        React.createElement("div", { id: "chart-background" }),
        React.createElement("div", { id: "query-tool-tip" }),
        currentView == 'line' && React.createElement("div", { id: "query-vertical" })));
};
exports.default = Chart;
