"use strict";
/**
 *  Community.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const qs = require("query-string");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const global_1 = require("@actions/global");
const discord_1 = require("@actions/discord");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_router_dom_2 = require("react-router-dom");
const BillingProvider_1 = require("@providers/BillingProvider");
const UserProvider_1 = require("@providers/UserProvider");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const Community = ({ _process, _showToast }) => {
    var _a, _b, _c, _d;
    const _billing = (0, react_1.useContext)(BillingProvider_1.BillingContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const history = (0, react_router_dom_2.useHistory)();
    const [showWelcomeBeta, setShowWelcomeBeta] = (0, react_1.useState)(false);
    const [discordStatus, setDiscordStatus] = (0, react_1.useState)(null);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [linkDiscord, setLinkDiscord] = (0, react_1.useState)(false);
    const [discordSuccess, setDiscordSuccess] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = 'Community - Lunch Money';
        refreshDiscordStatus();
    }, []);
    const refreshDiscordStatus = () => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield (0, discord_1.getStatus)();
        setDiscordStatus(results);
    });
    const getDiscordLink = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const results = yield (0, discord_1.connectDiscord)();
        setIsLoading(false);
        return `${results.data.url}?${qs.stringify(results.data.query, {
            strict: false,
            encode: false,
        })}`;
    });
    (0, react_1.useEffect)(() => {
        const opts = qs.parse(history.location.search);
        if (opts.code) {
            setIsLoading(true);
            _exchangeCode({ code: opts.code });
        }
        else if (opts.link) {
            setLinkDiscord(true);
        }
    }, [history.location]);
    const _exchangeCode = (opts) => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield (0, discord_1.exchangeCode)(opts);
        setIsLoading(false);
        if (results.data) {
            setDiscordSuccess(true);
            // Remove code
            history.replace({
                search: null,
            });
            refreshDiscordStatus();
        }
        else {
            console.log(results);
            _showToast({
                message: 'Hmm.. something went wrong connecting to Discord. Please try again.',
                type: 'error',
            });
        }
    });
    return (React.createElement(semantic_ui_react_1.Container, null,
        React.createElement(ContainerHeader_1.default, { title: "Community" }),
        React.createElement("div", { className: "g-referral" },
            React.createElement("div", { className: "left" },
                React.createElement(semantic_ui_react_1.Message, { info: true },
                    React.createElement(semantic_ui_react_1.Message.Header, { className: "mb-05rem" }, "Lunch Money community on Discord (new!)"),
                    React.createElement(semantic_ui_react_1.Message.Content, null,
                        React.createElement("p", null, "Come join our community Discord! Share product ideas, seek help with the product or developer API or discuss all things personal finance with other like-minded users. The Discord community is open to all subscribers and non-subscribers."),
                        React.createElement(semantic_ui_react_1.Button, { icon: true, fluid: true, style: { backgroundColor: '#7289DA' }, labelPosition: "right", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                const results = yield (0, global_1.getDiscordInviteLink)();
                                open(results);
                            }) },
                            "Join the Discord community ",
                            React.createElement(semantic_ui_react_1.Icon, { name: "external alternate" })),
                        ' ',
                        _billing.status == 'active' &&
                            (discordStatus ? (React.createElement("p", { className: "center-align" },
                                React.createElement("b", { className: "clickable", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        yield (0, discord_1.unlinkDiscord)();
                                        _showToast({
                                            message: 'Your Lunch Money account has been successfully unlinked from Discord.',
                                            type: 'success',
                                        });
                                        refreshDiscordStatus();
                                    }) }, "Unlink my account"),
                                ' ',
                                "(you will lose all flairs and privileges in the Discord!)")) : (React.createElement(React.Fragment, null,
                                React.createElement("p", null, "After joining the community, link your Lunch Money account to Discord to unlock special access and unique flairs!"),
                                React.createElement(semantic_ui_react_1.Button, { fluid: true, style: { backgroundColor: '#7289DA' }, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        setLinkDiscord(true);
                                    }) },
                                    "Link my Lunch Money account to Discord",
                                    ' '))))))),
            React.createElement("div", { className: "right" },
                React.createElement(semantic_ui_react_1.Message, { info: true },
                    React.createElement(semantic_ui_react_1.Message.Header, { className: "mb-05rem" }, "Lunch Money beta testing program"),
                    React.createElement(semantic_ui_react_1.Message.Content, null,
                        React.createElement("p", null, "Paid subscribers may join the private beta testing group. You will receive early access to new features and help us continuously improve Lunch Money with your feedback. You'll also get to join the private beta channel within our community Discord!"),
                        _billing.status !== 'active' &&
                            _billing.subscription.user_type !== 'early-adopter' ? (React.createElement(React.Fragment, null,
                            React.createElement("p", null,
                                React.createElement("b", null, "You're still in a trial period."),
                                " When your first invoice gets paid, you'll be able to join the beta testing program."),
                            React.createElement("p", null,
                                React.createElement("b", null, "Want earlier access?"),
                                ' ',
                                React.createElement(react_router_dom_1.Link, { to: "/billing", className: "link clickable" }, "Enter your billing information"),
                                ' ',
                                "and choose the option to end your trial early."))) : (React.createElement(React.Fragment, null)),
                        React.createElement(semantic_ui_react_1.Button, { icon: true, disabled: _billing.status !== 'active' &&
                                _billing.subscription.user_type !== 'early-adopter', color: _user.isBeta ? 'green' : 'orange', fluid: true, labelPosition: "right", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                if (_user.isBeta) {
                                    setShowWelcomeBeta(true);
                                }
                                else {
                                    const results = yield (0, global_1.joinBeta)();
                                    yield _user.fetchUserDetails();
                                    if (results) {
                                        setShowWelcomeBeta(true);
                                    }
                                }
                            }) },
                            _user.isBeta
                                ? `You're in the beta testing program!`
                                : 'Click to join the beta testing program',
                            React.createElement(semantic_ui_react_1.Icon, { name: _user.isBeta ? 'info' : 'add' })))))),
        React.createElement(semantic_ui_react_1.Modal, { open: showWelcomeBeta, closeOnEscape: false, closeOnDimmerClick: false, size: 'small', onClose: () => {
                setShowWelcomeBeta(false);
            } },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Welcome to the beta testing program!"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("h3", null, "How do I access the beta build of Lunch Money?"),
                React.createElement("p", null,
                    "You may now access the beta build at",
                    ' ',
                    React.createElement("a", { href: "https://beta.lunchmoney.app", className: "link clickable", target: "_blank" },
                        "beta.lunchmoney.app ",
                        React.createElement(semantic_ui_react_1.Icon, { name: "external", fitted: true })),
                    "."),
                React.createElement("h3", null, "Can I go back to the public build?"),
                React.createElement("p", null,
                    "The public, production build will always be available to you at",
                    ' ',
                    React.createElement("a", { href: "https://my.lunchmoney.app", className: "link clickable", target: "_blank" },
                        "my.lunchmoney.app ",
                        React.createElement(semantic_ui_react_1.Icon, { name: "external", fitted: true })),
                    "."),
                React.createElement("h3", null, "Is the data synced?"),
                React.createElement("p", null, "The beta and production build will display the same data."),
                React.createElement("h3", null, "How do I find out about new features?"),
                React.createElement("p", null, "You will receive updates on latest features to test on beta via an emails newsletter and the private Discord channel."),
                React.createElement("h3", null, "How do I join the private Beta Testers channel in Discord??"),
                React.createElement("p", null, "To gain access, ensure you have Linked Roles enabled. To do so, at the top left corner of the Discord, click 'Lunch Money' and choose 'Linked Roles'. From there, select Beta Testers.")),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        setShowWelcomeBeta(false);
                    } }, "Close"))),
        React.createElement(semantic_ui_react_1.Modal, { open: linkDiscord, closeOnEscape: false, closeOnDimmerClick: false, size: 'tiny', onClose: () => {
                setLinkDiscord(false);
            } },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Link my Lunch Money account to Discord"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("p", null, "Connect your Lunch Money account to Discord to access special features and areas of the community!"),
                React.createElement("p", null, "You are currently eligible for the following roles. You may choose which ones within the Discord app after you link."),
                React.createElement("ul", null,
                    _user.isBeta && (React.createElement("li", null,
                        React.createElement("b", null, "Beta Tester"),
                        " - You will get exclusive access to the private Beta Testers channel")),
                    _billing.status == 'active' && (React.createElement("li", null,
                        React.createElement("b", null, "Paid Subscriber"),
                        " - You will be recognized in the channel for being an awesome paid subscriber of Lunch Money!")),
                    _billing.status == 'active' &&
                        ((_b = (_a = _billing.subscription) === null || _a === void 0 ? void 0 : _a.plan) === null || _b === void 0 ? void 0 : _b.type) == 'annual' &&
                        ((_d = (_c = _billing.subscription) === null || _c === void 0 ? void 0 : _c.plan) === null || _d === void 0 ? void 0 : _d.quantity) > 12 && (React.createElement("li", null,
                        React.createElement("b", null, "Super Subscriber"),
                        " - You will be recognized in the channel for being an awesome super subscriber of Lunch Money!"))),
                discordStatus && (React.createElement(semantic_ui_react_1.Message, { info: true }, "You already linked your Lunch Money account to Discord!"))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        setLinkDiscord(false);
                    } }, "Close"),
                React.createElement(semantic_ui_react_1.Button, { icon: true, loading: isLoading, disabled: discordStatus || isLoading, style: { backgroundColor: '#7289DA' }, labelPosition: "right", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        const link = yield getDiscordLink();
                        window.location.href = link;
                    }) },
                    "Link to Discord ",
                    React.createElement(semantic_ui_react_1.Icon, { name: "arrow right" })))),
        React.createElement(semantic_ui_react_1.Modal, { open: discordSuccess, closeOnEscape: false, closeOnDimmerClick: false, size: 'tiny', onClose: () => {
                setDiscordSuccess(false);
            } },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Link my Lunch Money account to Discord"),
            React.createElement(semantic_ui_react_1.Modal.Content, null, isLoading ? (React.createElement(semantic_ui_react_1.Loader, { inline: true, centered: true }, "We are working behind the scenes... this shouldn't take long!")) : (React.createElement(React.Fragment, null,
                React.createElement("p", null, "Successfully connected to Discord! You may close this page and return to Discord to select your linked roles."),
                React.createElement(semantic_ui_react_1.Message, { info: true }, "Not sure how to access Linked Roles? Click 'Lunch Money' at the top right corner of the Discord window and choose 'Linked Roles'. From there, select the roles you'd like to be associated with.")))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        setDiscordSuccess(false);
                    } }, "Close")))));
};
exports.default = Community;
