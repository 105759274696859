"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
const categories_1 = require("@actions/categories");
const CategoriesSetup_1 = require("@components/Onboarding/CategoriesSetup");
const Preferences_1 = require("@components/Onboarding/Preferences");
const Final_1 = require("@components/Onboarding/Final");
const CategoriesProvider_1 = require("@providers/CategoriesProvider");
var OnboardingSteps;
(function (OnboardingSteps) {
    OnboardingSteps[OnboardingSteps["Categories"] = 1] = "Categories";
    OnboardingSteps[OnboardingSteps["Preferences"] = 2] = "Preferences";
    OnboardingSteps[OnboardingSteps["Finish"] = 3] = "Finish";
})(OnboardingSteps || (OnboardingSteps = {}));
const Onboarding = () => {
    let { current } = (0, react_router_dom_1.useParams)();
    const history = (0, react_router_dom_1.useHistory)();
    const [step, setStep] = (0, react_1.useState)(OnboardingSteps.Categories);
    // Step 1: Link Account
    const [accountsByBank, setAccountsByBank] = (0, react_1.useState)([]);
    // Step 2: Categories
    const [userCategories, setUserCategories] = (0, react_1.useState)([]);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = 'Welcome to Lunch Money';
    }, []);
    const goNext = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        if (step == OnboardingSteps.Categories) {
            // Save userCategories
            const results = yield (0, categories_1.createCategories)({
                categories: userCategories,
            });
            if (((_b = (_a = results === null || results === void 0 ? void 0 : results.data) === null || _a === void 0 ? void 0 : _a.results) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                _categories.add((_c = results === null || results === void 0 ? void 0 : results.data) === null || _c === void 0 ? void 0 : _c.results);
            }
            history.replace('/setup/preferences');
        }
        if (step == OnboardingSteps.Preferences) {
            history.replace('/setup/finish');
        }
        if (step == OnboardingSteps.Finish) {
            history.replace('/overview');
        }
    });
    const goBack = () => {
        if (step == OnboardingSteps.Preferences) {
            history.replace('/setup/categories');
        }
        if (step == OnboardingSteps.Finish) {
            history.replace('/setup/preferences');
        }
    };
    (0, react_1.useEffect)(() => {
        // Set the current step
        switch (current) {
            case 'categories':
                setStep(OnboardingSteps.Categories);
                break;
            case 'preferences':
                setStep(OnboardingSteps.Preferences);
                break;
            case 'finish':
                setStep(OnboardingSteps.Finish);
                break;
            default:
                setStep(OnboardingSteps.Categories);
                break;
        }
        document.getElementById('main-onboarding-content').scroll(0, 0);
    }, [current]);
    /**
     * Refresh accounts by taking metadata returned and adding to our front-end
     */
    const _refreshAccounts = (accounts, institution) => __awaiter(void 0, void 0, void 0, function* () {
        // Get current list of accounts already fetch from server
        const currentAccounts = accountsByBank;
        // Pop the dummy row, if any
        if (currentAccounts[currentAccounts.length - 1].institution_name === null &&
            currentAccounts[currentAccounts.length - 1].count === null) {
            currentAccounts.pop();
        }
        // Push the latest account added
        currentAccounts.push({
            institution_name: institution.name,
            count: accounts.length,
        });
        // Save for display
        setAccountsByBank(currentAccounts);
    });
    /** Add dummy account row to show user things are working behind the scenes */
    const _addDummyAccountRow = () => {
        const currentAccounts = accountsByBank;
        currentAccounts.push({
            institution_name: null,
            count: null,
        });
        setAccountsByBank(currentAccounts);
    };
    return (React.createElement("div", { className: "g-onboarding" },
        React.createElement("div", { className: "setup-content" },
            React.createElement("div", { className: "left-content" },
                React.createElement("div", { className: "left-content-main" },
                    React.createElement("div", { className: "progress-name" },
                        React.createElement("span", null, "Account Setup"),
                        React.createElement("span", null,
                            step,
                            "/3")),
                    React.createElement("div", { className: "status-bar-background" },
                        React.createElement("div", { className: "status-bar", style: {
                                width: `${step == 3 ? 100 : step * 33}%`,
                            } }))),
                React.createElement("div", { className: "footer-meta" },
                    React.createElement("div", { id: "mascot" }),
                    React.createElement("span", { className: "subtext" },
                        "We will never sell your information to third-party companies and you won't be seeing ads ever.",
                        ' ',
                        React.createElement("a", { style: { color: '#fff', display: 'block', fontWeight: 600 }, href: "https://lunchmoney.app/privacy", target: "_blank" }, "Read our privacy policy here.")))),
            React.createElement("div", { className: "right-content" },
                React.createElement("div", { id: "main-onboarding-content" },
                    React.createElement("div", { className: "main-content" },
                        step === OnboardingSteps.Categories && (React.createElement(CategoriesSetup_1.default, { update: userCategories => {
                                setUserCategories(userCategories);
                            } })),
                        step === OnboardingSteps.Preferences && React.createElement(Preferences_1.default, null),
                        step === OnboardingSteps.Finish && React.createElement(Final_1.default, null))),
                React.createElement("div", { className: "footer" },
                    React.createElement("span", null),
                    React.createElement("div", { className: "display--flex" },
                        step > OnboardingSteps.Categories && (React.createElement(semantic_ui_react_1.Button, { color: "orange", labelPosition: "left", icon: true, onClick: goBack, basic: true, size: "small" },
                            React.createElement(semantic_ui_react_1.Icon, { name: "arrow left" }),
                            "Back")),
                        React.createElement(semantic_ui_react_1.Button, { onClick: goNext, labelPosition: "right", icon: true, size: "small" },
                            step == OnboardingSteps.Finish
                                ? 'Take me to my overview'
                                : 'Next',
                            React.createElement(semantic_ui_react_1.Icon, { name: "arrow right" }))))))));
};
exports.default = Onboarding;
