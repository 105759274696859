"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const qs = require("query-string");
const _ = require("lodash");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_router_dom_1 = require("react-router-dom");
const RulesFilterRow_1 = require("@components/Rules/RulesFilterRow");
const TransactionsProvider_1 = require("@providers/TransactionsProvider");
const UserProvider_1 = require("@providers/UserProvider");
const RulesFilter = ({ disabled = false, hasFiltersApplied = false }) => {
    const _transactions = (0, react_1.useContext)(TransactionsProvider_1.TransactionsContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [ready, setReady] = (0, react_1.useState)(false);
    const [match, setMatch] = (0, react_1.useState)('all');
    const [filters, setFilters] = (0, react_1.useState)([new Date().getTime()]);
    const [numFilters, setNumFilters] = (0, react_1.useState)(0);
    const [searchValue, setSearchValue] = (0, react_1.useState)('');
    const location = (0, react_router_dom_1.useLocation)();
    const history = (0, react_router_dom_1.useHistory)();
    const [open, setOpen] = (0, react_1.useState)(false);
    let timeout = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        var _a;
        // On load
        const query = qs.parse(location.search);
        if (query.search !== searchValue) {
            setSearchValue(((_a = query.search) === null || _a === void 0 ? void 0 : _a.toString()) || '');
        }
        const input = document.getElementById('search-rules-input');
        input.addEventListener('keydown', handler, false);
        return () => {
            input.removeEventListener('keydown', handler);
        };
    }, []);
    (0, react_1.useEffect)(() => {
        // We only care if this was cleared
        const query = qs.parse(location.search);
        if (!query.search && searchValue.length > 0) {
            setSearchValue('');
        }
    }, [location]);
    const handler = () => {
        const input = document.getElementById('search-rules-input');
        clearTimeout(timeout.current);
        // Make a new timeout set to go off in 1000ms (1 second)
        const timeoutId = setTimeout(function () {
            const query = qs.parse(history.location.search);
            const search = input.value;
            if (search.length == 0) {
                delete query.search;
                history.replace({
                    search: qs.stringify(Object.assign({}, ((query === null || query === void 0 ? void 0 : query.filter) ? { filter: query.filter } : {}))),
                });
            }
            else {
                history.replace({
                    search: qs.stringify(Object.assign(Object.assign({}, ((query === null || query === void 0 ? void 0 : query.filter) ? { filter: query.filter } : {})), { search })),
                });
            }
        }, 750);
        timeout.current = timeoutId;
    };
    (0, react_1.useEffect)(() => {
        convertFromQuery();
    }, [location.search]);
    /**
     * This takes what's in the URL and converts it to a format where we can
     * display it in the dropdowns
     */
    const convertFromQuery = () => {
        setFilters([]);
        let index = 0;
        const query = qs.parse(location.search);
        const current = {};
        const increment = () => {
            setFilters(curr => [...curr, new Date().getTime()]);
            return index++;
        };
        setMatch(query.match || 'all');
        const STRING_COMPARE_FILTERS = [
            'trigger_payee',
            'trigger_notes',
            'effect_payee',
            'effect_notes',
        ];
        for (let i = 0; i < STRING_COMPARE_FILTERS.length; i++) {
            const filterName = STRING_COMPARE_FILTERS[i];
            if (query[`${filterName}_is_anything`]) {
                current[increment()] = {
                    criteria: filterName,
                    relation: 'is_anything',
                    value: null,
                };
            }
            if (query[`${filterName}_contain`]) {
                _.flatten([query[`${filterName}_contain`]]).forEach(cat => {
                    current[increment()] = {
                        criteria: filterName,
                        relation: 'contain',
                        value: cat,
                    };
                });
            }
            if (query[`${filterName}_exact`]) {
                _.flatten([query[`${filterName}_exact`]]).forEach(cat => {
                    current[increment()] = {
                        criteria: filterName,
                        relation: 'exact',
                        value: cat,
                    };
                });
            }
            if (query[`${filterName}_start_with`]) {
                _.flatten([query[`${filterName}_start_with`]]).forEach(cat => {
                    current[increment()] = {
                        criteria: filterName,
                        relation: 'start_with',
                        value: cat,
                    };
                });
            }
        }
        const NUMBER_COMPARE_FILTERS = ['trigger_income', 'trigger_expense'];
        for (let i = 0; i < NUMBER_COMPARE_FILTERS.length; i++) {
            const filterName = NUMBER_COMPARE_FILTERS[i];
            if (query[`${filterName}_is_anything`]) {
                current[increment()] = {
                    criteria: filterName,
                    relation: 'is_anything',
                    value: null,
                };
            }
            if (query[`${filterName}_greater_than`]) {
                _.flatten([query[`${filterName}_greater_than`]]).forEach(cat => {
                    const split = cat.split('|');
                    current[increment()] = {
                        criteria: filterName,
                        relation: 'greater_than',
                        value: parseFloat(split[0]),
                        currency: split[1] || _user.primaryCurrency,
                    };
                });
            }
            if (query[`${filterName}_greater_equal`]) {
                _.flatten([query[`${filterName}_greater_equal`]]).forEach(cat => {
                    const split = cat.split('|');
                    current[increment()] = {
                        criteria: filterName,
                        relation: 'greater_equal',
                        value: parseFloat(split[0]),
                        currency: split[1] || _user.primaryCurrency,
                    };
                });
            }
            if (query[`${filterName}_less_than`]) {
                _.flatten([query[`${filterName}_less_than`]]).forEach(cat => {
                    const split = cat.split('|');
                    current[increment()] = {
                        criteria: filterName,
                        relation: 'less_than',
                        value: parseFloat(split[0]),
                        currency: split[1] || _user.primaryCurrency,
                    };
                });
            }
            if (query[`${filterName}_less_equal`]) {
                _.flatten([query[`${filterName}_less_equal`]]).forEach(cat => {
                    const split = cat.split('|');
                    current[increment()] = {
                        criteria: filterName,
                        relation: 'less_equal',
                        value: parseFloat(split[0]),
                        currency: split[1] || _user.primaryCurrency,
                    };
                });
            }
            if (query[`${filterName}_exactly`]) {
                _.flatten([query[`${filterName}_exactly`]]).forEach(cat => {
                    const split = cat.split('|');
                    current[increment()] = {
                        criteria: filterName,
                        relation: 'exactly',
                        value: parseFloat(split[0]),
                        currency: split[1] || _user.primaryCurrency,
                    };
                });
            }
            if (query[`${filterName}_between`]) {
                _.flatten([query[`${filterName}_between`]]).forEach(cat => {
                    const split = cat.split('|');
                    current[increment()] = {
                        criteria: filterName,
                        relation: 'between',
                        value: parseFloat(split[0]),
                        value2: parseFloat(split[1]),
                        currency: split[2] || _user.primaryCurrency,
                    };
                });
            }
        }
        if (query.general_search) {
            _.flatten([query.general_search]).forEach(cat => {
                current[increment()] = {
                    criteria: 'general_search',
                    relation: 'is',
                    value: cat,
                };
            });
        }
        if (query.category) {
            _.flatten([query.category]).forEach(cat => {
                current[increment()] = {
                    criteria: 'category',
                    relation: 'is',
                    value: parseInt(cat),
                };
            });
        }
        if (query.category_exclude) {
            _.flatten([query.category_exclude]).forEach(cat => {
                current[increment()] = {
                    criteria: 'category',
                    relation: 'is not',
                    value: parseInt(cat),
                };
            });
        }
        if (query.uncategorized) {
            current[increment()] = {
                criteria: 'category',
                relation: query.uncategorized === 'true' ? 'is' : 'is not',
                value: 'uncategorized',
            };
        }
        if (query.is_income) {
            current[increment()] = {
                criteria: 'category',
                relation: query.is_income === 'true' ? 'is' : 'is not',
                value: 'income',
            };
        }
        if (query.exclude_from_totals) {
            current[increment()] = {
                criteria: 'category',
                relation: query.exclude_from_totals === 'true' ? 'is' : 'is not',
                value: 'exclude_from_totals',
            };
        }
        if (query.exclude_from_budget) {
            current[increment()] = {
                criteria: 'category',
                relation: query.exclude_from_budget === 'true' ? 'is' : 'is not',
                value: 'exclude_from_budget',
            };
        }
        if (query.account) {
            _.flatten([query.account]).forEach(acc => {
                current[increment()] = {
                    criteria: 'account',
                    relation: 'is',
                    value: parseInt(acc),
                };
            });
        }
        if (query.account_exclude) {
            _.flatten([query.account_exclude]).forEach(acc => {
                current[increment()] = {
                    criteria: 'account',
                    relation: 'is not',
                    value: parseInt(acc),
                };
            });
        }
        if (query.asset) {
            _.flatten([query.asset]).forEach(acc => {
                current[increment()] = {
                    criteria: 'asset',
                    relation: 'is',
                    value: acc == 'cash' ? 'cash' : `${acc}`,
                };
            });
        }
        if (query.asset_exclude) {
            _.flatten([query.asset_exclude]).forEach(acc => {
                current[increment()] = {
                    criteria: 'asset',
                    relation: 'is not',
                    value: acc == 'cash' ? 'cash' : `${acc}`,
                };
            });
        }
        if (query.attachment) {
            current[increment()] = {
                criteria: 'file',
                relation: query.attachment == 'true' ? 'is' : 'is not',
                value: 'attached',
            };
        }
        if (query.recurring) {
            _.flatten([query.recurring]).forEach(rec => {
                current[increment()] = {
                    criteria: 'recurring',
                    relation: 'is',
                    value: rec == 'any' ? 'any' : parseInt(rec),
                };
            });
        }
        if (query.recurring_exclude) {
            _.flatten([query.recurring_exclude]).forEach(rec => {
                current[increment()] = {
                    criteria: 'recurring',
                    relation: 'is not',
                    value: rec == 'any' ? 'any' : parseInt(rec),
                };
            });
        }
        if (query.tag) {
            _.flatten([query.tag]).forEach(cat => {
                current[increment()] = {
                    criteria: 'tag',
                    relation: 'is',
                    value: parseInt(cat),
                };
            });
        }
        if (query.tag_exclude) {
            _.flatten([query.tag_exclude]).forEach(cat => {
                current[increment()] = {
                    criteria: 'tag',
                    relation: 'is not',
                    value: parseInt(cat),
                };
            });
        }
        if (query.status) {
            _.flatten([query.status]).forEach(cat => {
                current[increment()] = {
                    criteria: 'status',
                    relation: 'is',
                    value: cat,
                };
            });
        }
        if (query.status_exclude) {
            _.flatten([query.status_exclude]).forEach(cat => {
                current[increment()] = {
                    criteria: 'status',
                    relation: 'is not',
                    value: cat,
                };
            });
        }
        if (query.effect_action) {
            _.flatten([query.effect_action]).forEach(cat => {
                current[increment()] = {
                    criteria: 'effect_action',
                    relation: 'is',
                    value: cat,
                };
            });
        }
        if (query.effect_action_exclude) {
            _.flatten([query.effect_action_exclude]).forEach(cat => {
                current[increment()] = {
                    criteria: 'effect_action',
                    relation: 'is not',
                    value: cat,
                };
            });
        }
        if (query.untagged) {
            current[increment()] = {
                criteria: 'tag',
                relation: query.untagged == 'true' ? 'is' : 'is not',
                value: 'untagged',
            };
        }
        if (index == 0) {
            setFilters([new Date().getTime()]); // If none, start with one
        }
        _transactions.allFilters.current = current;
        setReady(true);
        setNumFilters(index);
    };
    /**
     * On Apply, this takes what's in the dropdowns and converts it into a
     * search query to insert into the URL so it can be digested by Transactions
     * container
     */
    const convertToQuery = () => {
        const queries = Object.values(_transactions.allFilters.current);
        let count = 0;
        // Final Query
        const grouped = {
            match,
        };
        const add = (key, val) => {
            if (grouped.hasOwnProperty(key)) {
                grouped[key].push(val);
            }
            else {
                grouped[key] = [val];
            }
        };
        queries.forEach(query => {
            if (query.criteria !== null &&
                (query.value != null || query.relation == 'is_anything')) {
                if (query.criteria === 'trigger_expense' ||
                    query.criteria === 'trigger_income') {
                    const addOn = _user.primaryCurrency == query.currency ? '' : `|${query.currency}`;
                    if (query.relation === 'is_anything') {
                        count++;
                        add(`${query.criteria}_${query.relation}`, true);
                    }
                    else if (query.value.toString().length > 0 &&
                        !isNaN(parseInt(query.value)) &&
                        (query.relation !== 'between' || !isNaN(parseInt(query.value2)))) {
                        count++;
                        add(`${query.criteria}_${query.relation}`, query.relation === 'between'
                            ? `${query.value}|${query.value2}${addOn}`
                            : `${query.value}${addOn}`);
                    }
                }
                else if (query.criteria === 'category') {
                    count++;
                    if (query.value === 'uncategorized') {
                        if (query.relation === 'is not') {
                            grouped['uncategorized'] = false;
                        }
                        else {
                            grouped['uncategorized'] = true;
                        }
                    }
                    else if (query.value === 'income') {
                        if (query.relation === 'is not') {
                            grouped['is_income'] = false;
                        }
                        else {
                            grouped['is_income'] = true;
                        }
                    }
                    else if (query.value === 'exclude_from_totals') {
                        if (query.relation === 'is not') {
                            grouped['exclude_from_totals'] = false;
                        }
                        else {
                            grouped['exclude_from_totals'] = true;
                        }
                    }
                    else if (query.value === 'exclude_from_budget') {
                        if (query.relation === 'is not') {
                            grouped['exclude_from_budget'] = false;
                        }
                        else {
                            grouped['exclude_from_budget'] = true;
                        }
                    }
                    else {
                        if (query.relation === 'is not') {
                            add('category_exclude', query.value);
                        }
                        else {
                            add('category', query.value);
                        }
                    }
                }
                else if (query.criteria === 'tag') {
                    count++;
                    if (query.value === 'untagged') {
                        if (query.relation === 'is not') {
                            grouped['untagged'] = false;
                        }
                        else {
                            grouped['untagged'] = true;
                        }
                    }
                    else {
                        if (query.relation === 'is not') {
                            add('tag_exclude', query.value);
                        }
                        else {
                            add('tag', query.value);
                        }
                    }
                }
                else if (query.criteria === 'asset') {
                    // Asset ID
                    count++;
                    if (query.value === 'cash') {
                        if (query.relation === 'is not') {
                            add('asset_exclude', 'cash');
                        }
                        else {
                            add('asset', 'cash');
                        }
                    }
                    else {
                        if (query.relation === 'is not') {
                            add('asset_exclude', query.value);
                        }
                        else {
                            add('asset', query.value);
                        }
                    }
                }
                else if (query.criteria === 'account') {
                    // Plaid Account ID
                    count++;
                    if (query.relation === 'is not') {
                        add('account_exclude', query.value);
                    }
                    else {
                        add('account', query.value);
                    }
                }
                else if (query.criteria === 'recurring') {
                    count++;
                    if (query.value === 'any') {
                        if (query.relation === 'is not') {
                            add('recurring_exclude', 'any');
                        }
                        else {
                            add('recurring', 'any');
                        }
                    }
                    else {
                        if (query.relation === 'is not') {
                            add('recurring_exclude', query.value);
                        }
                        else {
                            add('recurring', query.value);
                        }
                    }
                }
                else if (query.criteria === 'effect_action' ||
                    query.criteria === 'general_search' ||
                    query.criteria === 'type' ||
                    query.criteria === 'status') {
                    count++;
                    if (query.relation === 'is not') {
                        add(`${query.criteria}_exclude`, query.value);
                    }
                    else {
                        add(query.criteria, query.value);
                    }
                }
                else if (query.criteria.indexOf('payee') > -1 ||
                    query.criteria.indexOf('notes') > -1) {
                    count++;
                    add(`${query.criteria}_${query.relation}`, query.value || true);
                }
                else if (query.criteria === 'general search') {
                    count++;
                    add(`search`, query.value);
                }
            }
        });
        setNumFilters(count);
        if (count > 0) {
            history.replace({
                search: `?${qs.stringify(grouped)}`,
            });
        }
        else {
            history.replace({
                search: ``,
            });
        }
    };
    return (React.createElement(React.Fragment, null,
        qs.parse(location.search).filter !== 'suggested' && (React.createElement(semantic_ui_react_1.Form, { className: "mb-0 mr-05rem" },
            React.createElement(semantic_ui_react_1.Input, { id: "search-rules-input", className: "search-input", icon: "search", iconPosition: "left", size: "large", value: searchValue, placeholder: `Search ${qs.parse(location.search).filter == 'custom'
                    ? 'Custom Rules'
                    : qs.parse(location.search).filter == 'payee'
                        ? 'Payee Rules'
                        : qs.parse(location.search).filter == 'category'
                            ? 'Category Rules'
                            : qs.parse(location.search).filter == 'tags'
                                ? 'Tag Rules'
                                : qs.parse(location.search).filter == 'recurring'
                                    ? 'Recurring Rules'
                                    : qs.parse(location.search).filter == 'split'
                                        ? 'Split Rules'
                                        : qs.parse(location.search).filter == 'email'
                                            ? 'Email Rules'
                                            : 'All Rules'}`, onChange: (e, { value }) => {
                    setSearchValue(value);
                } }))),
        React.createElement(semantic_ui_react_1.Modal, { id: "filter-modal", open: open, closeOnEscape: true, size: 'small', closeOnDimmerClick: true, onClose: () => {
                // Reset dropdown values, if any changes
                convertFromQuery();
                setOpen(false);
            } },
            ready && (React.createElement(semantic_ui_react_1.Modal.Content, { className: "filter-modal-content" },
                React.createElement("div", { className: "display--flex mb-1rem flex--align-end sub-header" },
                    React.createElement("span", { className: "label-style" }, "Match "),
                    React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-05rem mr-05rem p-match-inline", inline: true, defaultValue: match, options: [
                            {
                                value: 'any',
                                key: 'any',
                                text: 'any',
                            },
                            {
                                value: 'all',
                                key: 'all',
                                text: 'all',
                            },
                        ], onChange: (e, { value }) => {
                            setMatch(value);
                        } }),
                    ' ',
                    React.createElement("span", { className: "label-style" }, "of the following filters:")),
                React.createElement("div", { className: "transaction-filter-popup" },
                    filters.map((filter, index) => {
                        if (!!filter) {
                            return (React.createElement(RulesFilterRow_1.default, { key: `filter-row-${index}`, index: index, remove: index => {
                                    const currentFilters = [...filters];
                                    currentFilters[index] = null;
                                    setFilters(currentFilters);
                                }, duplicate: obj => {
                                    const newIndex = filters.length;
                                    setFilters([...filters, new Date().getTime()]);
                                    _transactions.allFilters.current[newIndex] = obj;
                                }, allFilters: _transactions.allFilters }));
                        }
                    }),
                    React.createElement("div", { className: "new-filter-row mb-1rem" },
                        React.createElement(semantic_ui_react_1.Button, { size: "mini", basic: true, color: "orange", onClick: () => {
                                setFilters([...filters, new Date().getTime()]);
                            } }, "Add another filter"))))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                numFilters > 0 ? (React.createElement(semantic_ui_react_1.Button, { content: "Clear filters", basic: true, color: "orange", className: "no-border", onClick: () => {
                        history.push({
                            search: ``,
                        });
                        setOpen(false);
                    } })) : (React.createElement(semantic_ui_react_1.Button, { content: "Cancel", basic: true, color: "orange", className: "no-border", onClick: () => {
                        setOpen(false);
                    } })),
                React.createElement(semantic_ui_react_1.Button, { content: "Apply filters", onClick: () => {
                        convertToQuery();
                        setOpen(false);
                    } })))));
};
exports.default = RulesFilter;
