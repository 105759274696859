"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAllLogs = exports.getTransactionLog = void 0;
const common_1 = require("./common");
const getTransactionLog = transaction_id => {
    return (0, common_1.get)(`transaction_log/${transaction_id}`);
};
exports.getTransactionLog = getTransactionLog;
const getAllLogs = () => {
    return (0, common_1.get)(`transaction_log/all/`);
};
exports.getAllLogs = getAllLogs;
