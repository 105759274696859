"use strict";
/**
 *  EditableCategory.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const react_1 = require("react");
const format_1 = require("@helpers/format");
// Components
const semantic_ui_react_1 = require("semantic-ui-react");
const AssetsProvider_1 = require("@providers/AssetsProvider");
var State;
(function (State) {
    /** Default display */
    State["Default"] = "Default";
    /** Is currently being edited */
    State["Editing"] = "Editing";
    /** Is read only; not editable */
    State["ReadOnly"] = "ReadOnly";
})(State || (State = {}));
const EditableAsset = ({ identifier = new Date().getTime().toString(), className = '', placeholder = null, isReadOnly = false, onSave, state = 'Editing', location = 'modal', excludeId = null, excludeCash = false, showError = false, cashNameOverride = null, closeOnEscape = true, defaultOpen = false, assignableOnly = true, defaultSelection = {}, }) => {
    let history = (0, react_router_dom_1.useHistory)();
    /**
     * selected contains:
     * source (manual, plaid)
     * id (asset_id or plaid_account_id)
     * display_name (optional)
     */
    const [selected, setSelected] = (0, react_1.useState)(null);
    const [options, setOptions] = (0, react_1.useState)([]);
    const [dropdownOpen, setDropdownOpen] = (0, react_1.useState)(defaultOpen);
    const [currentState, setCurrentState] = (0, react_1.useState)(!!state ? State[state] : State.Default);
    const [inputId, setInputId] = (0, react_1.useState)(identifier);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const [searchQuery, setSearchQuery] = (0, react_1.useState)('');
    (0, react_1.useEffect)(() => {
        if (state !== currentState) {
            setCurrentState(state);
        }
    }, [state]);
    (0, react_1.useEffect)(() => {
        try {
            if (!selected ||
                defaultSelection['source'] != (selected === null || selected === void 0 ? void 0 : selected.source) ||
                defaultSelection['id'] != (selected === null || selected === void 0 ? void 0 : selected.id)) {
                // Does defaultSelection have display_name?
                setSelected(defaultSelection);
            }
        }
        catch (e) {
            console.log('Caught error', { e, defaultSelection });
        }
    }, [defaultSelection]);
    const accountExists = (source, id) => {
        if (source == 'manual') {
            return _assets.getAsset(id) != null;
        }
        else if (source == 'plaid') {
            return _assets.getPlaidAccount(id) != null;
        }
    };
    const getAccountName = () => {
        if ((selected === null || selected === void 0 ? void 0 : selected.source) == 'manual') {
            if (accountExists('manual', selected === null || selected === void 0 ? void 0 : selected.id)) {
                return _assets.getAssetName(selected === null || selected === void 0 ? void 0 : selected.id);
            }
            else {
                return (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement("span", null,
                        React.createElement(semantic_ui_react_1.Icon, { name: "warning sign", color: "red" }),
                        " Missing Account"), size: "small", inverted: true, content: "The associated account was deleted. Click for more details." }));
            }
        }
        else if ((selected === null || selected === void 0 ? void 0 : selected.source) == 'plaid') {
            if (accountExists('plaid', selected === null || selected === void 0 ? void 0 : selected.id)) {
                return _assets.getPlaidAccountName(selected === null || selected === void 0 ? void 0 : selected.id);
            }
            else {
                return (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement("span", null,
                        React.createElement(semantic_ui_react_1.Icon, { name: "warning sign", color: "red" }),
                        " Missing Account"), size: "small", inverted: true, content: "The associated account was deleted. Click for more details." }));
            }
        }
        else if ((selected === null || selected === void 0 ? void 0 : selected.id) == -1) {
            return 'Cash transaction';
        }
    };
    (0, react_1.useEffect)(() => {
        if (location == 'inline' && currentState == State.Editing) {
            setTimeout(() => {
                if (document.getElementById(inputId) &&
                    document.getElementById(inputId).getElementsByTagName('input')) {
                    document
                        .getElementById(inputId)
                        .getElementsByTagName('input')[0]
                        .focus();
                    document
                        .getElementById(inputId)
                        .getElementsByTagName('input')[0].spellcheck = false;
                }
            }, 100);
        }
    }, [inputId, currentState]);
    // Get list of accounts and assets
    (0, react_1.useEffect)(() => {
        const fetchAccountsAndAssets = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const filtered = (_a = (assignableOnly
                ? _assets.getAssignableAccounts()
                : [..._assets.assets, ..._assets.plaidAccounts])) === null || _a === void 0 ? void 0 : _a.filter(o => {
                return (!excludeId ||
                    !(excludeId['source'] == o.source && excludeId['id'] == o.id));
            }).map(o => {
                return {
                    key: `${o.source}_${o.id}`,
                    value: `${o.source}_${o.id}`,
                    text: `${o.display_name} (${(0, format_1.capitalize)(o.type)})`,
                    onClick: (e, { value }) => {
                        if (value) {
                            setSelected({
                                id: o.id,
                                source: o.source,
                                display_name: o.display_name,
                            });
                            onSave({
                                id: o.id,
                                source: o.source,
                                display_name: o.display_name,
                            });
                            setDropdownOpen(false);
                            setCurrentState(location.indexOf('modal') > -1 ||
                                location === 'inline-new' ||
                                location === 'filter-row'
                                ? State.Editing
                                : State.Default);
                            setSearchQuery('');
                        }
                    },
                };
            }).sort((a, b) => {
                return a.text.localeCompare(b.text);
            });
            let dropdownOptions = [];
            if (!excludeCash) {
                const cashOption = {
                    key: 'cash',
                    value: -1,
                    text: cashNameOverride || 'Cash transaction',
                    onClick: (e, { value }) => {
                        if (value) {
                            setSelected({
                                id: -1,
                                source: null,
                                display_name: 'Cash',
                            });
                            onSave({
                                id: -1,
                                source: null,
                                display_name: 'Cash',
                            });
                            setDropdownOpen(false);
                            setSearchQuery('');
                            setCurrentState(location.indexOf('modal') > -1 ||
                                location === 'inline-new' ||
                                location === 'filter-row'
                                ? State.Editing
                                : State.Default);
                        }
                    },
                };
                dropdownOptions = [cashOption, ...filtered];
            }
            else {
                dropdownOptions = filtered;
            }
            setOptions(dropdownOptions);
        });
        fetchAccountsAndAssets();
    }, [onSave]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `${location == 'filter-row' ? '' : 'g-editable-text'} ${currentState === State.Editing && location.indexOf('inline') > -1
                ? 'selected'
                : ''}` },
            currentState === State.ReadOnly && (React.createElement("span", { className: "readonly" }, selected || location !== 'inline' ? getAccountName() : '')),
            currentState === State.Default && (React.createElement("div", { className: `default-state`, onMouseLeave: () => {
                    setCurrentState(State.Default);
                }, onClick: () => {
                    setCurrentState(State.Editing);
                    setDropdownOpen(true);
                } }, selected || location !== 'inline' ? (getAccountName()) : (React.createElement("span", { className: "show-on-hover" }, "Add an account")))),
            currentState === State.Editing && (React.createElement(semantic_ui_react_1.Dropdown, { id: inputId, error: showError, disabled: isReadOnly, className: `category-dropdown p-category-${location} ${className}`, noResultsMessage: "No results found.", placeholder: placeholder || 'Select account', fluid: true, search: true, searchQuery: searchQuery, onSearchChange: (e, { searchQuery }) => {
                    setSearchQuery(searchQuery);
                }, selection: location === 'modal' || location == 'filter-row', open: dropdownOpen, selectOnNavigation: false, closeOnEscape: closeOnEscape, closeOnChange: true, onBlur: () => {
                    setDropdownOpen(false);
                    setCurrentState(location.indexOf('modal') > -1 ||
                        location === 'inline-new' ||
                        location === 'filter-row'
                        ? State.Editing
                        : State.Default);
                }, onFocus: () => {
                    setDropdownOpen(true);
                }, onClick: (e, b) => {
                    if (!dropdownOpen) {
                        setDropdownOpen(true);
                    }
                }, value: selected
                    ? (selected === null || selected === void 0 ? void 0 : selected.source)
                        ? `${selected === null || selected === void 0 ? void 0 : selected.source}_${selected['id']}`
                        : selected['id']
                    : null, options: options, onChange: (e, obj) => __awaiter(void 0, void 0, void 0, function* () {
                    if (obj.value == 'multiple') {
                        setSelected({
                            id: 'multiple',
                            source: null,
                        });
                        onSave({
                            id: 'multiple',
                            source: null,
                        });
                        return;
                    }
                    if (e.key == 'Enter') {
                        if (obj.value == -1) {
                            setSelected({
                                id: -1,
                                source: null,
                            });
                            onSave({
                                id: -1,
                                source: null,
                            });
                        }
                        else {
                            setSelected({
                                id: obj.value.toString().split('_')[1],
                                source: obj.value.toString().indexOf('plaid_') == 0
                                    ? 'plaid'
                                    : 'manual',
                            });
                            onSave({
                                id: obj.value.toString().split('_')[1],
                                source: obj.value.toString().indexOf('plaid_') == 0
                                    ? 'plaid'
                                    : 'manual',
                            });
                        }
                        setDropdownOpen(false);
                        setCurrentState(location.indexOf('modal') > -1 ||
                            location === 'inline-new' ||
                            location === 'filter-row'
                            ? State.Editing
                            : State.Default);
                        setSearchQuery('');
                    }
                }), onClose: () => {
                    setDropdownOpen(false);
                    setCurrentState(location.indexOf('modal') > -1 ||
                        location === 'inline-new' ||
                        location === 'filter-row'
                        ? State.Editing
                        : State.Default);
                    setSearchQuery('');
                } })))));
};
exports.default = EditableAsset;
