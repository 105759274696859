"use strict";
/**
 *  Rules.tsx
 *  Container for the Rules page
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const _ = require("lodash");
const qs = require("query-string");
const rules_1 = require("../actions/rules");
const NewModal_1 = require("@components/global/NewModal");
// Semantic UI
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
// Components
const react_router_dom_1 = require("react-router-dom");
const RulesFilter_1 = require("@components/Rules/RulesFilter");
const DryRunRules_1 = require("@components/Rules/DryRunRules");
const ContainerHeader_1 = require("../components/elements/ContainerHeader");
const Loader_1 = require("../components/global/Loader");
const RuleRow_1 = require("../components/Rules/RuleRow");
const react_1 = require("react");
const rules_2 = require("@helpers/rules");
const UserProvider_1 = require("@providers/UserProvider");
const ModalProvider_1 = require("@providers/ModalProvider");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const TagsProvider_1 = require("@/providers/TagsProvider");
const Rules = ({ history, _process, _showToast }) => {
    var _a;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _modal = (0, react_1.useContext)(ModalProvider_1.ModalContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const _tags = (0, react_1.useContext)(TagsProvider_1.TagsContext);
    const [showModal, setShowModal] = (0, react_1.useState)(false);
    const [editRule, setEditRule] = (0, react_1.useState)(null);
    const [rules, setRules] = (0, react_1.useState)([]);
    const [visibleRules, setVisibleRules] = (0, react_1.useState)([]);
    const [suggestedRules, setSuggestedRules] = (0, react_1.useState)([]);
    const [showSuggested, setShowSuggested] = (0, react_1.useState)(false);
    const [skip, setSkip] = (0, react_1.useState)(0);
    const [limit, setLimit] = (0, react_1.useState)(localStorage.getItem('_lm_rules_limit')
        ? parseInt(localStorage.getItem('_lm_rules_limit'))
        : 50);
    const LIMIT_OPTIONS = [25, 50, 100, 10000];
    const LIMIT = 20;
    const query = qs.parse(history.location.search);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [isLoadingDelete, setIsLoadingDelete] = (0, react_1.useState)(false);
    const [showRecurringEditConfirm, setShowRecurringEditConfirm] = (0, react_1.useState)(false);
    const [showRecurringDeleteConfirm, setShowRecurringDeleteConfirm] = (0, react_1.useState)(false);
    // Confirmations
    const [showConfirmDelete, setShowConfirmDelete] = (0, react_1.useState)(false);
    // Applying rule
    const [showDryRunRules, setShowDryRunRules] = (0, react_1.useState)(null);
    // Filters
    const [selectedIds, setSelectedIds] = (0, react_1.useState)([]);
    // Sorting
    const [sortColumn, setSortColumn] = (0, react_1.useState)('criteria_last_triggered_at');
    const [sortDirection, setSortDirection] = (0, react_1.useState)('descending');
    // Unsubscribing
    const [unsubscribeRules, setUnsubscribeRules] = (0, react_1.useState)([]);
    (0, react_1.useEffect)(() => {
        document.title = 'Rules - Lunch Money';
        fetchRules();
    }, []);
    (0, react_1.useEffect)(() => {
        const filters = qs.parse(location.search);
        const _filter = filters.filter;
        let _rules = [];
        if (_filter == 'suggested') {
            setSkip(0);
            setShowSuggested(true);
            setSelectedIds([]);
            return;
        }
        else if (!!_filter) {
            const filteredRules = rules.filter(rule => {
                if (_filter == 'custom') {
                    return (!!rule.delete_transaction_id ||
                        !!rule.skip_recurring ||
                        !!rule.skip_rule ||
                        !!rule.has_split ||
                        !!rule.mark_as_reviewed ||
                        !!rule.mark_as_unreviewed ||
                        !!rule.set_uncategorized ||
                        !!rule.send_email ||
                        !!rule.stop_processing_others ||
                        !!rule.one_time_rule ||
                        !!rule.run_on_update);
                }
                else if (_filter == 'update') {
                    return !!rule.run_on_update;
                }
                else if (_filter == 'payee') {
                    return !!rule.payee_name;
                }
                else if (_filter == 'category') {
                    return !!rule.category_id;
                }
                else if (_filter == 'tags') {
                    return rule.tags && rule.tags.length > 0;
                }
                else if (_filter == 'recurring') {
                    return !!rule.recurring_id;
                }
                else if (_filter == 'split') {
                    return !!rule.has_split;
                }
                else if (_filter == 'email') {
                    return !!rule.send_email;
                }
            });
            _rules = filteredRules; //.sort(sortByLastTriggered)
        }
        else {
            _rules = rules; //.sort(sortByLastTriggered)
        }
        if (filters['search']) {
            _rules = _rules.filter(rule => {
                let str = rule.criteria_payee_name || '';
                str += ` ${rule.criteria_notes || ''}`;
                str += ` ${rule.criteria_amount || ''}`;
                str += ` ${rule.criteria_amount_2 || ''}`;
                str += ` ${rule.criteria_day || ''}`;
                str += ` ${rule.criteria_day_2 || ''}`;
                if (rule.criteria_asset_id) {
                    str += ` ${_assets.getAssetName(rule.criteria_asset_id)}`;
                    str += ` ${_assets.getAssetInstitutionName(rule.criteria_asset_id)}`;
                }
                if (rule.criteria_plaid_account_id) {
                    str += ` ${_assets.getPlaidAccountName(rule.criteria_plaid_account_id)}`;
                    str += ` ${_assets.getPlaidInstitutionName(rule.criteria_plaid_account_id)}`;
                }
                str += ` ${_categories.getName(rule.criteria_category_id)}`;
                str += ` ${rule.payee_name || ''}`;
                str += ` ${rule.notes || ''}`;
                str += ` ${_categories.getName(rule.category_id)}`;
                str += ` ${rule.recurring_payee || ''}`;
                str += ` ${rule.description || ''}`;
                if (rule.tags && rule.tags.length) {
                    str += rule.tags
                        .map(tag => {
                        return ` ${tag.name}`;
                    })
                        .join(' ');
                }
                return (str
                    .toLowerCase()
                    .indexOf(filters['search'].toLowerCase()) > -1);
            });
        }
        setSkip(0);
        setSortColumn(null);
        setShowSuggested(false);
        setVisibleRules(_rules);
    }, [rules, location.search]);
    const deleteRules = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (id = null) {
        setShowConfirmDelete(false);
        setIsLoadingDelete(true);
        const results = yield _process(rules_1.bulkDelete)(id || selectedIds);
        if (!results.error) {
            // Update master list
            const newRules = rules.filter(o => {
                return selectedIds.indexOf(o.rule_criteria_id) == -1;
            });
            setRules(newRules);
            const newVisibleRules = visibleRules.filter(o => {
                return selectedIds.indexOf(o.rule_criteria_id) == -1;
            });
            setVisibleRules(newVisibleRules);
            const newSuggestedRules = suggestedRules.filter(o => {
                return selectedIds.indexOf(o.rule_criteria_id) == -1;
            });
            setSuggestedRules(newSuggestedRules);
            if (showSuggested && newSuggestedRules.length == 0) {
                history.push('/rules');
            }
            _showToast({
                message: `Successfully deleted rule${(id || selectedIds).length > 1 ? 's' : ''}`,
                type: 'success',
            });
        }
        setShowDryRunRules(null);
        setSelectedIds([]);
        setIsLoadingDelete(false);
    });
    const sortByLastTriggered = (a, b) => {
        if (!a.criteria_last_triggered_at && b.criteria_last_triggered_at) {
            return 1;
        }
        else if (a.criteria_last_triggered_at && !b.criteria_last_triggered_at) {
            return -1;
        }
        else if (!a.criteria_last_triggered_at && !b.criteria_last_triggered_at) {
            return Moment(a.criteria_created_at).isAfter(Moment(b.criteria_created_at))
                ? -1
                : 1;
        }
        return Moment(a.criteria_last_triggered_at).isAfter(Moment(b.criteria_last_triggered_at))
            ? -1
            : 1;
    };
    const fetchRules = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const allResults = [];
        const LIMIT = 100;
        let done = false;
        let offset = 0;
        let results = null;
        while (!done) {
            results = yield _process(rules_1.getRules)({
                offset,
                limit: LIMIT,
            });
            allResults.push(...results.rules);
            if (results.total_returned == LIMIT) {
                offset += LIMIT;
            }
            else {
                done = true;
            }
        }
        const sortedResults = allResults.sort(sortByLastTriggered);
        setSuggestedRules(sortedResults.filter(o => o.criteria_suggested));
        setRules(sortedResults.filter(o => !o.criteria_suggested));
        if (!!(query === null || query === void 0 ? void 0 : query.unsubscribe)) {
            const unsubscribe = allResults.filter(rule => {
                return (query.unsubscribe
                    .split(',')
                    .indexOf(rule.rule_criteria_id.toString()) > -1 &&
                    rule.should_send_email);
            });
            setUnsubscribeRules(unsubscribe);
        }
        setIsLoading(false);
    });
    const handleSort = (column, dirOverride = null) => {
        if (column == sortColumn && dirOverride == null) {
            setSortDirection(sortDirection == 'ascending' ? 'descending' : 'ascending');
            setVisibleRules(visibleRules.reverse());
        }
        else {
            // Keep direction, re-sort by new column
            const sorted = visibleRules.sort(column == 'criteria_last_triggered_at'
                ? sortByLastTriggered
                : (a, b) => {
                    var _a, _b;
                    if (column == 'priority') {
                        return b.criteria_priority - a.criteria_priority;
                    }
                    else if (column == 'trigger') {
                        return (a.criteria_payee_name || '').localeCompare(b.criteria_payee_name || '');
                    }
                    else if (column == 'effect') {
                        if (query.filter == 'category') {
                            return _categories
                                .getName(a.category_id)
                                .localeCompare(_categories.getName(b.category_id));
                        }
                        else if (query.filter == 'recurring') {
                            return a.recurring_payee.localeCompare(b.recurring_payee);
                        }
                        else if (query.filter == 'tag') {
                            return (((_a = a.tag[0]) === null || _a === void 0 ? void 0 : _a.name) || '').localeCompare(((_b = b.tag[0]) === null || _b === void 0 ? void 0 : _b.name) || '');
                        }
                        return (a.payee_name ||
                            _categories.getName(a.category_id) ||
                            a.recurring_payee ||
                            '').localeCompare(b.payee_name ||
                            _categories.getName(b.category_id) ||
                            b.recurring_payee ||
                            '');
                    }
                });
            if (sortDirection == 'ascending') {
                setVisibleRules(sorted.reverse());
            }
            else {
                setVisibleRules(sorted);
            }
            setSortColumn(column);
        }
    };
    return (React.createElement(semantic_ui_react_1.Container, { className: "g-rules" },
        React.createElement(ContainerHeader_1.default, { title: `${showSuggested
                ? `Suggested Rules (${suggestedRules.length})`
                : `Transaction Rules ${visibleRules.length > 0
                    ? `(${visibleRules.length !== rules.length
                        ? `${visibleRules.length}/`
                        : ''}${rules.length})`
                    : ''}`}` }),
        React.createElement("div", { className: "width-100 rules-toolbar flex--space-between-flex-end" },
            React.createElement("div", null,
                !showSuggested && (React.createElement(semantic_ui_react_1.Button, { className: "mr-05rem", onClick: () => {
                        setEditRule(null);
                        _modal.data.current.rule = null;
                        setShowModal(true);
                    } }, "Add A New Rule")),
                showSuggested ? (React.createElement(React.Fragment, null,
                    React.createElement(semantic_ui_react_1.Button, { disabled: selectedIds.length == 0 || isLoadingDelete, loading: isLoadingDelete, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setIsLoadingDelete(true);
                            const results = yield _process(rules_1.approveRules)(selectedIds);
                            if (!results.error) {
                                const approvedRules = suggestedRules
                                    .filter(rule => selectedIds.includes(rule.rule_criteria_id))
                                    .map(o => {
                                    return Object.assign(Object.assign({}, o), { criteria_suggested: false, criteria_last_triggered_at: o.criteria_created_at });
                                });
                                setRules(approvedRules.concat(rules));
                                setSuggestedRules(suggestedRules.filter(rule => !selectedIds.includes(rule.rule_criteria_id)));
                                _showToast({
                                    message: `Successfully approved rule${selectedIds.length > 1 ? 's' : ''}`,
                                    type: 'success',
                                });
                                setSelectedIds([]);
                                if (suggestedRules.filter(rule => !selectedIds.includes(rule.rule_criteria_id)).length == 0) {
                                    history.push('/rules');
                                }
                            }
                            setIsLoadingDelete(false);
                        }) },
                        "Approve Selected (",
                        selectedIds.length,
                        ")"))) : (React.createElement(semantic_ui_react_1.Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setIsLoadingDelete(true);
                        setShowDryRunRules(true);
                        setIsLoadingDelete(false);
                    }), disabled: isLoadingDelete || selectedIds.length == 0, loading: isLoadingDelete },
                    "Apply Selected (",
                    selectedIds.length,
                    ")")),
                selectedIds.length > 0 ? (React.createElement(React.Fragment, null,
                    React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: true, disabled: selectedIds.length == 0 || isLoadingDelete, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setSelectedIds([]);
                        }), loading: isLoadingDelete },
                        "Unselect All (",
                        selectedIds.length,
                        ")"),
                    React.createElement(semantic_ui_react_1.Button, { disabled: selectedIds.length == 0 || isLoadingDelete, color: "red", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setShowConfirmDelete(true);
                        }), loading: isLoadingDelete },
                        "Delete Selected (",
                        selectedIds.length,
                        ")"))) : (React.createElement(React.Fragment, null))),
            React.createElement("div", { className: "flex--end buttons" },
                React.createElement(RulesFilter_1.default, { disabled: showSuggested || isLoadingDelete }))),
        React.createElement("div", { className: "p-rules-container" },
            React.createElement("div", { className: "rules-container-left" },
                React.createElement(semantic_ui_react_1.Table, { fixed: true, selectable: true, celled: true, unstackable: true, className: "p-rules-table" },
                    React.createElement(semantic_ui_react_1.Table.Body, null,
                        React.createElement(semantic_ui_react_1.Table.Row, { className: `padded-row ${!query.filter ? 'highlighted' : ''}` },
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable", onClick: () => {
                                    history.replace({
                                        search: ``,
                                    });
                                } },
                                React.createElement("span", null,
                                    ' ',
                                    React.createElement(semantic_ui_react_1.Icon, { name: "list" }),
                                    " All Rules"))),
                        React.createElement(semantic_ui_react_1.Table.Row, { className: `padded-row ${query.filter == 'custom' ? 'highlighted' : ''}` },
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable", onClick: () => {
                                    history.replace({
                                        search: qs.stringify({ filter: 'custom' }),
                                    });
                                } },
                                React.createElement("span", null,
                                    React.createElement(semantic_ui_react_1.Icon, { name: "cog" }),
                                    " Custom Rules"))),
                        React.createElement(semantic_ui_react_1.Table.Row, { className: `padded-row ${query.filter == 'update' ? 'highlighted' : ''}` },
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable", onClick: () => {
                                    history.replace({
                                        search: qs.stringify({ filter: 'update' }),
                                    });
                                } },
                                React.createElement("span", null,
                                    React.createElement(semantic_ui_react_1.Icon, { name: "pencil" }),
                                    " Update Rules"))),
                        React.createElement(semantic_ui_react_1.Table.Row, { className: `padded-row ${query.filter == 'payee' ? 'highlighted' : ''}` },
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable", onClick: () => {
                                    history.replace({
                                        search: qs.stringify({ filter: 'payee' }),
                                    });
                                } },
                                React.createElement("span", null,
                                    React.createElement(semantic_ui_react_1.Icon, { name: "address card outline" }),
                                    " Payee Rules"))),
                        React.createElement(semantic_ui_react_1.Table.Row, { className: `padded-row ${query.filter == 'category' ? 'highlighted' : ''}` },
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable", onClick: () => {
                                    history.replace({
                                        search: qs.stringify({ filter: 'category' }),
                                    });
                                } },
                                React.createElement("span", null,
                                    React.createElement(semantic_ui_react_1.Icon, { name: "folder open outline" }),
                                    " Category Rules"))),
                        React.createElement(semantic_ui_react_1.Table.Row, { className: `padded-row ${query.filter == 'tags' ? 'highlighted' : ''}` },
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable", onClick: () => {
                                    history.replace({
                                        search: qs.stringify({ filter: 'tags' }),
                                    });
                                } },
                                React.createElement("span", null,
                                    React.createElement(semantic_ui_react_1.Icon, { name: "tags" }),
                                    " Tag Rules"))),
                        React.createElement(semantic_ui_react_1.Table.Row, { className: `padded-row ${query.filter == 'recurring' ? 'highlighted' : ''}` },
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable", onClick: () => {
                                    history.replace({
                                        search: qs.stringify({ filter: 'recurring' }),
                                    });
                                } },
                                React.createElement("span", null,
                                    ' ',
                                    React.createElement(semantic_ui_react_1.Icon, { name: "registered" }),
                                    " Recurring Rules"))),
                        React.createElement(semantic_ui_react_1.Table.Row, { className: `padded-row ${query.filter == 'split' ? 'highlighted' : ''}` },
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable", onClick: () => {
                                    history.replace({
                                        search: qs.stringify({ filter: 'split' }),
                                    });
                                } },
                                React.createElement("span", null,
                                    ' ',
                                    React.createElement("span", { className: "split-icon mr-05rem" }),
                                    " Split Rules"))),
                        React.createElement(semantic_ui_react_1.Table.Row, { className: `padded-row ${query.filter == 'email' ? 'highlighted' : ''}` },
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable", onClick: () => {
                                    history.replace({
                                        search: qs.stringify({ filter: 'email' }),
                                    });
                                } },
                                React.createElement("span", null,
                                    ' ',
                                    React.createElement(semantic_ui_react_1.Icon, { name: "envelope outline" }),
                                    " Email Rules"))),
                        React.createElement(semantic_ui_react_1.Table.Row, { className: `padded-row ${query.filter == 'suggested' ? 'highlighted' : ''}` },
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable", onClick: () => {
                                    history.replace({
                                        search: qs.stringify({
                                            filter: 'suggested',
                                        }),
                                    });
                                } },
                                React.createElement("span", null, "Suggested Rules")))))),
            React.createElement("div", { className: "rules-container-right" },
                React.createElement(semantic_ui_react_1.Table, { fixed: true, selectable: true, sortable: true, celled: true, unstackable: true, className: "p-rules-table" },
                    React.createElement(semantic_ui_react_1.Table.Header, { className: "sticky" },
                        React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { onClick: () => {
                                    const currentRules = (showSuggested
                                        ? suggestedRules
                                        : visibleRules)
                                        .slice(skip, skip + limit)
                                        .map(o => o.rule_criteria_id);
                                    if (_.intersection(selectedIds, currentRules).length ==
                                        currentRules.length) {
                                        setSelectedIds(_.difference(selectedIds, currentRules));
                                    }
                                    else {
                                        setSelectedIds(_.uniq(selectedIds.concat(currentRules)));
                                    }
                                }, className: "center-align table-cell-toggle clickable" },
                                React.createElement("span", { className: `bulk-checkbox ${!isLoading &&
                                        (showSuggested ? suggestedRules : visibleRules).length >
                                            0 &&
                                        _.intersection(selectedIds, (showSuggested ? suggestedRules : visibleRules)
                                            .slice(skip, skip + limit)
                                            .map(o => o.rule_criteria_id)).length ==
                                            (showSuggested ? suggestedRules : visibleRules).slice(skip, skip + limit).length
                                        ? 'checked'
                                        : 'empty'}` })),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { sorted: sortColumn === 'criteria_last_triggered_at'
                                    ? sortDirection
                                    : null, onClick: () => {
                                    handleSort('criteria_last_triggered_at');
                                }, className: "table-cell-date" }, showSuggested ? (React.createElement("span", null, "Created")) : (React.createElement("span", null, "Last Triggered"))),
                            !showSuggested && (React.createElement(React.Fragment, null,
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { sorted: sortColumn === 'priority' ? sortDirection : null, onClick: () => {
                                        handleSort('priority');
                                    }, className: "table-cell-priority center-align" },
                                    React.createElement(React.Fragment, null,
                                        "Priority",
                                        ' ',
                                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", name: "question circle" }) }, "Rules are additive and will be applied in priority order with 20 being lowest and 1 being highest. For example, a matched rule updating the payee name of priority 20 will be overriden by a matched rule updating the payee name of priority 1."))))),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-description center-align", sorted: sortColumn === 'trigger' ? sortDirection : null, onClick: () => {
                                    handleSort('trigger');
                                } }, "Rule Trigger"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "not-sortable-th table-cell-if-then" }),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-description center-align", sorted: sortColumn === 'effect' ? sortDirection : null, onClick: () => {
                                    handleSort('effect');
                                } }, "Rule Effect"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-arrow" }))),
                    React.createElement(semantic_ui_react_1.Table.Body, { className: (showSuggested ? suggestedRules : visibleRules).length === 0
                            ? 'g-empty-body'
                            : '' },
                        ((_a = query['search']) === null || _a === void 0 ? void 0 : _a.length) > 0 && (React.createElement(semantic_ui_react_1.Table.Row, { className: "no-hover-tr padded-row" },
                            React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: 9, className: "center-align clickable", onClick: () => {
                                    history.push({
                                        search: qs.stringify(Object.assign({}, ((query === null || query === void 0 ? void 0 : query.filter) ? { filter: query.filter } : {}))),
                                    });
                                } },
                                React.createElement("span", { className: "link ellipsis" },
                                    React.createElement(semantic_ui_react_1.Icon, { name: "x" }),
                                    " Clear search filter (",
                                    query['search'],
                                    ")")))),
                        !isLoading && !showSuggested && visibleRules.length === 0 && (React.createElement(semantic_ui_react_1.Table.Row, { className: "g-empty-row no-hover-tr" },
                            React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: "9", className: "center-align" },
                                React.createElement("div", { className: "empty-mascot" }),
                                !showSuggested &&
                                    visibleRules.length == 0 &&
                                    rules.length !== 0 && (React.createElement("p", null, "No rules found matching the selected filters.")),
                                !showSuggested && rules.length == 0 && (React.createElement(React.Fragment, null,
                                    React.createElement("p", null, "You have no rules yet!"),
                                    React.createElement("p", null, "Rules are created to automatically update certain fields of a transaction based on some criteria. The purpose of a rule is to keep your transactions organized and categorized in the way that you choose."),
                                    React.createElement("p", null,
                                        React.createElement("a", { href: "https://support.lunchmoney.app/setup/rules", className: "link" }, "Click here to learn more about rules")),
                                    React.createElement("p", null,
                                        "Get started by clicking",
                                        ' ',
                                        React.createElement(semantic_ui_react_1.Button, { size: "mini", onClick: () => {
                                                setEditRule(null);
                                                setShowModal(true);
                                            } }, "add a new rule"),
                                        ' ',
                                        "above or start categorizing your transactions!"))),
                                showSuggested && suggestedRules.length == 0 && (React.createElement(React.Fragment, null,
                                    React.createElement("p", null, "You have no suggested rules."),
                                    React.createElement("p", null,
                                        "Suggested rules are created when you update the name of a payee from the",
                                        ' ',
                                        React.createElement(react_router_dom_1.Link, { className: "link", to: "/transactions" }, "Transactions page"),
                                        ".")))))),
                        isLoading && (React.createElement(Loader_1.default, { text: 'Loading rules..', colSpan: "9" })),
                        (showSuggested ? suggestedRules : visibleRules)
                            .slice(skip, skip + limit)
                            .map(rule => {
                            return (React.createElement(RuleRow_1.default, { key: `rules-row-${rule.rule_id}-${rule.criteria_last_triggered_at}`, selected: selectedIds.includes(rule.rule_criteria_id), setSelected: selected => {
                                    if (selected) {
                                        // add
                                        setSelectedIds(selectedIds.concat([rule.rule_criteria_id]));
                                    }
                                    else {
                                        // remove
                                        setSelectedIds(selectedIds.filter(o => {
                                            return o !== rule.rule_criteria_id;
                                        }));
                                    }
                                }, buildMatchesString: rules_2.buildMatchesString, buildRulesString: o => {
                                    return (0, rules_2.buildRulesString)({
                                        rule: o,
                                        flipSign: _user.settings['show_debits_as_negative'],
                                        _categories,
                                        _tags,
                                    });
                                }, rule: rule, _process: _process, _showToast: _showToast, setEditRule: o => {
                                    setEditRule(o);
                                }, setShowModal: o => {
                                    setShowModal(o);
                                }, setShowRecurringEditConfirm: o => {
                                    setShowRecurringEditConfirm(o);
                                }, isLoadingDelete: isLoadingDelete }));
                        }))),
                !isLoading &&
                    !isLoadingDelete &&
                    (showSuggested ? suggestedRules : visibleRules).length > LIMIT && (React.createElement("div", { className: "pagination-container flex--space-between" },
                    React.createElement("div", { className: "pagination-amount" }, LIMIT_OPTIONS.map(option => {
                        return (React.createElement("a", { key: `limit-option-${option}`, onClick: () => {
                                setLimit(option);
                                localStorage.setItem('_lm_rules_limit', option.toString());
                                setSkip(0);
                            }, className: `choice ${limit == option ? 'active' : ''}` }, option == 10000 ? 'All' : option));
                    })),
                    Math.ceil((showSuggested ? suggestedRules : visibleRules).length /
                        limit) > 1 && (React.createElement(semantic_ui_react_1.Pagination, { activePage: Math.floor(skip / limit) + 1, onPageChange: (e, { activePage }) => {
                            setSkip((activePage - 1) * limit);
                        }, totalPages: Math.ceil((showSuggested ? suggestedRules : visibleRules).length /
                            limit) })))))),
        showModal && (React.createElement(NewModal_1.default, { show: showModal, setShow: (show) => {
                setShowModal(show);
            }, data: {
                currency: _user.primaryCurrency,
                rule: editRule,
                setEditRule: setEditRule,
                flipSign: _user.settings['show_debits_as_negative'],
                submitCallback: (results, _applyRules) => {
                    if (!editRule) {
                        // From creating a new rule, can't be suggested
                        if (!results.error) {
                            // Add results.data to rules array
                            setRules([results.data].concat(rules));
                            setVisibleRules([results.data].concat(visibleRules));
                            if (_applyRules) {
                                setSelectedIds([results.data.rule_criteria_id]);
                                setShowDryRunRules(true);
                            }
                        }
                    }
                    else {
                        if (!results.error) {
                            // Update
                            const updatedRules = rules.map(o => {
                                if (o.rule_criteria_id == editRule.rule_criteria_id) {
                                    return Object.assign(Object.assign(Object.assign({}, o), { tags: null }), results.data);
                                }
                                else {
                                    return o;
                                }
                            });
                            setRules(updatedRules);
                            // Update
                            const updatedVisibleRules = visibleRules.map(o => {
                                if (o.rule_criteria_id == editRule.rule_criteria_id) {
                                    return Object.assign(Object.assign(Object.assign({}, o), { tags: null }), results.data);
                                }
                                else {
                                    return o;
                                }
                            });
                            setVisibleRules(updatedVisibleRules);
                            // Update
                            const updatedSuggestedRules = suggestedRules.map(o => {
                                if (o.rule_criteria_id == editRule.rule_criteria_id) {
                                    return Object.assign(Object.assign({}, o), results.data);
                                }
                                else {
                                    return o;
                                }
                            });
                            setSuggestedRules(updatedSuggestedRules);
                            setSortColumn(null);
                            if (_applyRules) {
                                setSelectedIds([editRule.rule_criteria_id]);
                                setShowDryRunRules(true);
                            }
                        }
                    }
                },
                deleteCallback: () => {
                    // Update master list
                    const newRules = rules.filter(o => {
                        return o.rule_criteria_id !== editRule.rule_criteria_id;
                    });
                    setRules(newRules);
                    const newVisibleRules = visibleRules.filter(o => {
                        return o.rule_criteria_id !== editRule.rule_criteria_id;
                    });
                    setVisibleRules(newVisibleRules);
                    const newSuggestedRules = suggestedRules.filter(o => {
                        return o.rule_criteria_id !== editRule.rule_criteria_id;
                    });
                    setSuggestedRules(newSuggestedRules);
                },
            }, utils: {
                _process: _process,
                _showToast: _showToast,
            }, view: 'ADD_RULE', closeCallback: () => {
                // this.refreshData()
            } })),
        React.createElement(semantic_ui_react_1.Confirm, { open: showRecurringEditConfirm || showRecurringDeleteConfirm, onConfirm: () => __awaiter(void 0, void 0, void 0, function* () {
                if (showRecurringEditConfirm) {
                    setShowModal(true);
                    setShowRecurringEditConfirm(false);
                }
                else {
                    const criteria_id = showRecurringDeleteConfirm;
                    const results = yield _process(rules_1.deleteRule)(criteria_id);
                    if (!results.error) {
                        // Update master list
                        const newRules = rules.filter(o => {
                            return o.rule_criteria_id !== criteria_id;
                        });
                        setRules(newRules);
                        const newVisibleRules = visibleRules.filter(o => {
                            return o.rule_criteria_id !== criteria_id;
                        });
                        setVisibleRules(newVisibleRules);
                        _showToast({
                            message: 'Successfully deleted rule',
                            type: 'success',
                        });
                    }
                    setShowRecurringDeleteConfirm(false);
                }
            }), onCancel: () => {
                setEditRule(null);
                setShowRecurringEditConfirm(false);
                setShowRecurringDeleteConfirm(false);
            }, confirmButton: showRecurringEditConfirm ? 'Edit this rule' : 'Delete this rule', cancelButton: 'Cancel', header: `This is a system-created rule for recurring items`, content: React.createElement("div", { className: "content" },
                React.createElement("p", null,
                    "We recommend that you don't",
                    ' ',
                    showRecurringEditConfirm ? 'modify' : 'delete',
                    " this rule. If you do, please note that automatic recurring item detection for this recurring item ",
                    showRecurringEditConfirm ? 'may' : 'will',
                    " no longer work.")) }),
        unsubscribeRules.length > 0 && (React.createElement(semantic_ui_react_1.Confirm, { className: "g-rules-unsubscribe", open: true, onConfirm: () => __awaiter(void 0, void 0, void 0, function* () {
                const criteria_ids = unsubscribeRules
                    .filter(o => {
                    return !o.exclude;
                })
                    .map(o => o.rule_criteria_id);
                const results = yield _process(rules_1.unsubscribeEmailRules)(criteria_ids);
                if (!results.error) {
                    // Update master list
                    const newRules = rules.filter(o => {
                        return criteria_ids.indexOf(o.rule_criteria_id) == -1;
                    });
                    setRules(newRules);
                    const newVisibleRules = visibleRules.filter(o => {
                        return criteria_ids.indexOf(o.rule_criteria_id) == -1;
                    });
                    setVisibleRules(newVisibleRules);
                    const query = qs.parse(history.location.search, {
                        arrayFormat: 'comma',
                    });
                    delete query.unsubscribe;
                    history.replace({
                        search: `?${qs.stringify(query)}`,
                    });
                    setUnsubscribeRules([]);
                    _showToast({
                        message: 'Successfully unsubscribed from emails',
                        type: 'success',
                    });
                }
            }), onCancel: () => {
                const query = qs.parse(history.location.search, {
                    arrayFormat: 'comma',
                });
                delete query.unsubscribe;
                history.replace({
                    search: `?${qs.stringify(query)}`,
                });
                setUnsubscribeRules([]);
            }, confirmButton: 'Unsubscribe', cancelButton: 'Cancel', header: `Unsubscribe from email notifications`, content: React.createElement("div", { className: "margin-1rem" },
                React.createElement("p", null, "The following rules will be removed. Uncheck to keep a rule."),
                React.createElement(semantic_ui_react_1.Table, { className: "p-rules-table monospace tight" },
                    React.createElement(semantic_ui_react_1.Table.Body, null, unsubscribeRules.map(rule => {
                        return (React.createElement(semantic_ui_react_1.Table.Row, { key: `unsubscribe-${rule.rule_criteria_id}`, className: "padded-row" },
                            React.createElement(semantic_ui_react_1.Table.Cell, null,
                                React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: !rule.exclude, onChange: (e, { checked }) => {
                                        setUnsubscribeRules(unsubscribeRules.map(o => {
                                            if (o.rule_criteria_id === rule.rule_criteria_id) {
                                                return Object.assign(Object.assign({}, o), { exclude: !checked });
                                            }
                                            else {
                                                return o;
                                            }
                                        }));
                                    } })),
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "hang-right" }, (0, rules_2.buildMatchesString)(rule, _assets, _categories)),
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "table-cell-if-then" },
                                React.createElement(semantic_ui_react_1.Icon, { name: "arrow alternate circle right" })),
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "hang-left" }, (0, rules_2.buildRulesString)({
                                rule,
                                flipSign: _user.settings['show_debits_as_negative'],
                                sendEmailOnly: true,
                                _categories,
                                _tags,
                            }))));
                    })))) })),
        React.createElement(DryRunRules_1.default, { show: showDryRunRules, deleteRules: deleteRules, updateRule: (criteria_ids, update) => {
                const newRules = rules.map(o => {
                    if (criteria_ids.includes(o.rule_criteria_id)) {
                        return Object.assign(Object.assign({}, o), update);
                    }
                    else {
                        return o;
                    }
                });
                setRules(newRules);
                const newVisibleRules = visibleRules.map(o => {
                    if (criteria_ids.includes(o.rule_criteria_id)) {
                        return Object.assign(Object.assign({}, o), update);
                    }
                    else {
                        return o;
                    }
                });
                setVisibleRules(newVisibleRules);
                const newSuggestedRules = suggestedRules.map(o => {
                    if (criteria_ids.includes(o.rule_criteria_id)) {
                        return Object.assign(Object.assign({}, o), update);
                    }
                    else {
                        return o;
                    }
                });
                setSuggestedRules(newSuggestedRules);
            }, closeModal: () => {
                setShowDryRunRules(null);
            }, criteriaIds: selectedIds, _showToast: _showToast, _process: _process }),
        React.createElement(semantic_ui_react_1.Modal, { open: !!showConfirmDelete, onClose: () => {
                setShowConfirmDelete(false);
            }, size: "tiny" },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Confirm rule deletion"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("div", { className: "content" },
                    React.createElement("p", null,
                        "Are you sure you want to delete ",
                        selectedIds.length,
                        " rule",
                        selectedIds.length > 1 ? 's' : '',
                        "?"))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        setShowConfirmDelete(false);
                    }, negative: true }, "No, cancel"),
                React.createElement(semantic_ui_react_1.Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield deleteRules();
                    }) }, "Yes, delete")))));
};
exports.default = Rules;
