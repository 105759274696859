"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoriesProvider = exports.CategoriesContext = void 0;
const React = require("react");
const react_1 = require("react");
const categories_1 = require("@actions/categories");
const UserProvider_1 = require("@/providers/UserProvider");
const CategoriesContext = (0, react_1.createContext)({
    isReady: false,
    nested: [],
    assignable: [],
    flattened: [],
    defaultCategories: [],
    removeFromGroup: id => { },
    all: [],
    getName: id => {
        return 'Uncategorized';
    },
    get: id => {
        return {
            id: null,
            name: '',
            is_group: false,
            group_id: null,
            group_category_name: '',
            is_income: false,
        };
    },
    update: (id, body) => { },
    remove: id => { },
    add: category => { },
    fetchCategories: () => { },
});
exports.CategoriesContext = CategoriesContext;
const CategoriesProvider = props => {
    const [isReady, setIsReady] = (0, react_1.useState)(false);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    // List of all default categories (shown at onboarding)
    const [defaultCategories, setDefaultCategories] = (0, react_1.useState)([]);
    // List of categories with children nested within parent category
    const [nested, setNested] = (0, react_1.useState)([]);
    // List of categories assignable to a transaction, i.e. no groups
    const [assignable, setAssignable] = (0, react_1.useState)([]);
    // List of categories flattened for EditableCategory
    const [flattened, setFlattened] = (0, react_1.useState)([]);
    // List of all categories, flattened
    const [all, setAll] = (0, react_1.useState)([]);
    const hasFetchedCategories = React.useRef(false);
    /** GENERAL */
    (0, react_1.useEffect)(() => {
        console.log('[Categories Provider] Loaded.');
    }, []);
    (0, react_1.useEffect)(() => {
        if (_user.hasFetchedUser &&
            _user.hasFetchedSettings &&
            _user.hasFetchedAccountSettings &&
            !isReady &&
            !hasFetchedCategories.current) {
            fetchCategories();
        }
    }, [_user]);
    const getName = category_id => {
        if (category_id == -1 || category_id == null || isNaN(category_id)) {
            return 'Uncategorized';
        }
        const match = all.find(opt => {
            return opt.id == Number(category_id);
        });
        if (match) {
            return match.name;
        }
        else {
            return undefined;
        }
    };
    const get = category_id => {
        const match = all.find(opt => {
            return opt.id == category_id;
        });
        if (match) {
            return match;
        }
        else {
            return undefined;
        }
    };
    const fetchCategories = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        hasFetchedCategories.current = true;
        const allResults = yield (0, categories_1.getAllCategories)({});
        setNested(allResults.nested);
        setAssignable(allResults.assignable);
        setDefaultCategories(allResults.default);
        const _flattened = [];
        (_a = allResults.nested) === null || _a === void 0 ? void 0 : _a.forEach(category => {
            _flattened.push(category);
            if (category.children) {
                category.children.forEach(child => {
                    _flattened.push(child);
                });
            }
        });
        setFlattened(_flattened);
        setAll(_flattened);
        setIsReady(true);
        console.log('[Categories Provider] Done fetching categories.');
    });
    const _update = (o, id, body) => {
        if (o.id === id) {
            return Object.assign(Object.assign(Object.assign(Object.assign({}, o), body), { matched: true }), (o.hasOwnProperty('children')
                ? { children: o.children.map(child => _update(child, id, body)) }
                : {}));
        }
        if (o.hasOwnProperty('children')) {
            return Object.assign(Object.assign({}, o), { children: o.children.map(child => _update(child, id, body)) });
        }
        return o;
    };
    // Use this for simple vanity updates, like name, description and settings
    const update = (id, body) => {
        setNested(nested.map(o => _update(o, id, body)));
        setFlattened(flattened.map(o => {
            return _update(o, id, body);
        }));
        setAssignable(assignable.map(o => {
            return _update(o, id, body);
        }));
        setAll(all.map(o => {
            return _update(o, id, body);
        }));
    };
    // Server call to remove is in EditCategory.tsx
    // This is just to remove from context
    const remove = category_id => {
        // Figure out what kind of category we're removing
        const categoryObj = all.find(o => o.id == category_id);
        if (!categoryObj)
            return;
        if (categoryObj.is_group) {
            // If we're removing a category group then we need the original settings
            // of all the categories (exclude from budget/total, is income) since
            // they're inherited by the parent. So let's just make the server call.
            fetchCategories();
        }
        else {
            // Otherwise, just remove it!
            setAll(all.filter(o => o.id !== category_id));
            setFlattened(flattened.filter(o => o.id !== category_id));
            setNested(nested
                .map(o => {
                if (o.id === category_id) {
                    return null;
                }
                else if (o.id === categoryObj.group_id) {
                    const children = o.children.filter(n => n.id !== category_id);
                    return Object.assign(Object.assign({}, o), { children });
                }
                else {
                    return o;
                }
            })
                .filter(o => !!o));
            setAssignable(assignable.filter(o => o.id !== category_id));
        }
    };
    const removeFromGroup = category_id => {
        // Should just fetch from server b/c we need the original settings
        fetchCategories();
    };
    // This comes from a generic add, no groups and children
    // Safe to add to Nested, Assignable, and All
    // createCategory server call done from Categories and EditableCategory
    const add = categories => {
        const _nested = [
            ...(Array.isArray(categories) ? categories : [categories]),
            ...nested,
        ];
        setNested(_nested);
        setAssignable([
            ...(Array.isArray(categories) ? categories : [categories]),
            ...assignable,
        ]);
        setAll([...(Array.isArray(categories) ? categories : [categories]), ...all]);
        const _flattened = [];
        _nested.forEach(category => {
            _flattened.push(category);
            if (category.children) {
                category.children.forEach(child => {
                    _flattened.push(child);
                });
            }
        });
        setFlattened(_flattened);
    };
    return (React.createElement(CategoriesContext.Provider, { value: {
            isReady,
            nested,
            assignable,
            flattened,
            defaultCategories,
            removeFromGroup,
            all,
            getName,
            get,
            update,
            remove,
            add,
            fetchCategories,
        } }, props.children));
};
exports.CategoriesProvider = CategoriesProvider;
