"use strict";
/**
 *  EditableCadence.tsx
 */
Object.defineProperty(exports, "__esModule", { value: true });
const format_1 = require("@/helpers/format");
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const EditableCadence = ({ recurringItem = { cadence: '', quantity: 0, granularity: '' }, onSave = obj => { }, state, }) => {
    const [cadenceValue, setCadenceValue] = (0, react_1.useState)(recurringItem.cadence);
    const [quantityValue, setQuantityValue] = (0, react_1.useState)(recurringItem.quantity);
    const [granularityValue, setGranularityValue] = (0, react_1.useState)(recurringItem.granularity || null);
    const getReadableValue = item => {
        if (item.cadence === 'custom') {
            return `Every ${item.quantity} ${item.granularity}${item.quantity > 1 ? 's' : ''}`;
        }
        return (0, format_1.capitalize)(item.cadence);
    };
    return (React.createElement("div", null,
        state === 'ReadOnly' && (React.createElement("span", { className: `label-${recurringItem === null || recurringItem === void 0 ? void 0 : recurringItem.cadence.replace(/\W/g, '-')}` }, getReadableValue(recurringItem))),
        state === 'Editing' && (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Dropdown, { placeholder: 'Select cadence', value: cadenceValue, selection: true, search: true, className: `p-category-modal category-dropdown`, onChange: (e, { value }) => {
                    setCadenceValue(value.toString());
                    onSave(Object.assign({ cadence: value }, (value !== 'custom'
                        ? { quantity: null, granularity: null }
                        : {})));
                }, options: [
                    'once a week',
                    'twice a month',
                    'every 2 weeks',
                    'monthly',
                    'every 2 months',
                    'every 3 months',
                    'every 4 months',
                    'twice a year',
                    'yearly',
                    'custom',
                ].map(o => {
                    return {
                        key: o,
                        value: o,
                        text: (0, format_1.capitalize)(o),
                    };
                }) }),
            cadenceValue == 'custom' && (React.createElement("div", { className: "flex--space-between mt-05rem" },
                React.createElement("label", { style: {
                        display: 'flex',
                        position: 'relative',
                        top: '2px',
                        whiteSpace: 'nowrap',
                    } },
                    React.createElement("span", { className: "hierarchy-line-icon-1", style: { top: '4px !important' } }),
                    ' ',
                    "Repeat every"),
                React.createElement(semantic_ui_react_1.Form.Input, { type: "text", placeholder: "30", action: true },
                    React.createElement(semantic_ui_react_1.Input, { style: {
                            width: '140px',
                            height: '38px',
                            paddingRight: '3px',
                        }, value: quantityValue, className: "p-input-modal", error: quantityValue < 1 || isNaN(quantityValue), onChange: (e, { value }) => {
                            if (Number(value) < 1 || isNaN(Number(value))) {
                                setQuantityValue(value);
                                onSave({ quantity: value });
                            }
                            else {
                                setQuantityValue(Number(value));
                                onSave({ quantity: Number(value) });
                            }
                        } }),
                    React.createElement(semantic_ui_react_1.Form.Select, { compact: true, placeholder: "Select", value: granularityValue, options: ['day(s)', 'week(s)', 'month(s)', 'year(s)'].map(o => {
                            return {
                                key: o,
                                value: o.replace('(s)', ''),
                                text: o,
                            };
                        }), onChange: (e, { value }) => {
                            setGranularityValue(value.toString());
                            onSave({ granularity: value });
                        } }))))))));
};
exports.default = EditableCadence;
