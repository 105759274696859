"use strict";
/**
 *  Transactions.tsx
 *  Container for the Transactions page
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthProvider = exports.AuthContext = void 0;
const React = require("react");
const Routes_1 = require("@/Routes");
const react_1 = require("react");
const GlobalProvider_1 = require("@providers/GlobalProvider");
const StaticProvider_1 = require("@providers/StaticProvider");
const user_1 = require("@actions/user");
const auth_1 = require("@actions/auth");
const AuthContext = (0, react_1.createContext)({
    isLoggedIn: false,
    setIsLoggedIn: bool => { },
    ping: () => __awaiter(void 0, void 0, void 0, function* () { }),
    userLogin: (email, password) => __awaiter(void 0, void 0, void 0, function* () {
        return {
            request_2fa: null,
            method: null,
            id: null,
            email: null,
        };
    }),
});
exports.AuthContext = AuthContext;
const AuthProvider = props => {
    const _static = (0, react_1.useContext)(StaticProvider_1.StaticContext);
    const [isLoggedIn, setIsLoggedIn] = (0, react_1.useState)(null); // Null = hasn't loaded yet
    (0, react_1.useEffect)(() => {
        ping();
    }, []);
    // Ping
    const ping = () => __awaiter(void 0, void 0, void 0, function* () {
        let results = yield (0, user_1.getUser)();
        if (typeof results == 'undefined') {
            // Retry
            results = yield (0, user_1.getUser)();
        }
        if (!(results === null || results === void 0 ? void 0 : results.error)) {
            // document.cookie = `_lm_authenticated=true; domain=.lunchmoney.app`
            setIsLoggedIn(true);
        }
        else {
            // document.cookie = `_lm_authenticated=''; max-age=-1; domain=.lunchmoney.app`
            setIsLoggedIn(false);
        }
    });
    const userLogin = (email, password) => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield (0, auth_1.login)(email, password);
        yield ping();
        return results;
    });
    return (React.createElement(AuthContext.Provider, { value: { isLoggedIn, setIsLoggedIn, userLogin, ping } }, _static.isReady && isLoggedIn !== null ? (isLoggedIn ? (React.createElement(React.Fragment, null, props.children)) : (React.createElement(GlobalProvider_1.default, null,
        React.createElement(Routes_1.default, null)))) : (React.createElement("div", { className: "g-loading" }))));
};
exports.AuthProvider = AuthProvider;
