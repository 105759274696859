"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_1 = require("@helpers/format");
const _ = require("lodash");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const UserProvider_1 = require("@providers/UserProvider");
const plaid_1 = require("@/actions/plaid");
const AccountSummary = ({ _showToast, _process }) => {
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [accounts, setAccounts] = (0, react_1.useState)([
        ..._assets.assets,
        ..._assets.plaidAccounts,
        ..._assets.crypto,
        ..._assets.syncedCrypto,
        ..._assets.manualCrypto,
    ].filter(o => {
        return (o.status !== 'inactive' &&
            o.status !== 'closed' &&
            (o.source != 'synced-crypto' || !!o.coingecko_id));
    }));
    const [isRefreshing, setIsRefreshing] = (0, react_1.useState)(false);
    const [lastUpdated, setLastUpdated] = (0, react_1.useState)(null);
    // Currently the only setting is a currency setting.
    const [shouldShowSettings, setShouldShowSettings] = (0, react_1.useState)(false);
    const [displayInPrimaryCurrency, setDisplayInPrimaryCurrency] = (0, react_1.useState)(localStorage.getItem('monthly_overview_show_in_primary_currency') == 'true');
    const [rowsUI, setRowsUI] = (0, react_1.useState)(null);
    const [display, setDisplay] = (0, react_1.useState)({
        depository: localStorage.getItem(`_lm_overview_display_depository`) !== 'hide',
        investment: localStorage.getItem(`_lm_overview_display_investment`) !== 'hide',
        'real estate': localStorage.getItem(`_lm_overview_display_real_estate`) !== 'hide',
        cryptocurrency: localStorage.getItem(`_lm_overview_display_cryptocurrency`) !== 'hide',
        'employee compensation': localStorage.getItem(`_lm_overview_display_employee_compensation`) !==
            'hide',
        vehicle: localStorage.getItem(`_lm_overview_display_vehicle`) !== 'hide',
        brokerage: localStorage.getItem(`_lm_overview_display_brokerage`) !== 'hide',
        credit: localStorage.getItem(`_lm_overview_display_credit`) !== 'hide',
        loan: localStorage.getItem(`_lm_overview_display_loan`) !== 'hide',
        'other liability': localStorage.getItem(`_lm_overview_display_other_liability`) !== 'hide',
        'other asset': localStorage.getItem(`_lm_overview_display_other_asset`) !== 'hide',
    });
    const [totals, setTotals] = (0, react_1.useState)({ total: 0 });
    const triggerFetch = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!isRefreshing) {
            // No spamming
            setIsRefreshing(true);
            const results = yield _process(plaid_1.fetchTransactions)();
            if (!results.error) {
                setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
                    // This will trigger a refresh
                    yield _assets.fetchAssets();
                    setAccounts([
                        ..._assets.assets,
                        ..._assets.plaidAccounts,
                        ..._assets.crypto,
                        ..._assets.syncedCrypto,
                        ..._assets.manualCrypto,
                    ].filter(o => {
                        return (o.status !== 'inactive' &&
                            o.status !== 'closed' &&
                            (o.source != 'synced-crypto' || !!o.coingecko_id));
                    }));
                    setLastUpdated('just now');
                    setIsRefreshing(false);
                }), 9000);
                _showToast({
                    message: 'Syncing accounts in the background. This should be fairly instant but can sometimes take up to a minute or two!',
                    type: 'success',
                    autoDismissTimeout: 5000,
                });
            }
        }
    });
    (0, react_1.useEffect)(() => {
        if (_assets.plaidAccounts.length > 0) {
            // Fetch on load if it's been over an hour
            const maxLastFetch = Moment.max(_assets.plaidAccounts
                .map(o => (o.last_fetch ? Moment(o.last_fetch) : null))
                .filter(o => !!o));
            if (maxLastFetch && Moment().diff(maxLastFetch, 'minutes') > 60) {
                triggerFetch();
            }
        }
    }, []);
    (0, react_1.useEffect)(() => {
        const newTotals = { total: 0 };
        const accountCurrencies = new Set();
        // Group by type and calculate totals
        accounts === null || accounts === void 0 ? void 0 : accounts.forEach(account => {
            if (account &&
                account.hasOwnProperty('to_base') &&
                account.to_base !== null &&
                (account.status === 'active' || account.status === 'relink')) {
                if (newTotals.hasOwnProperty(account.type)) {
                    newTotals[account.type].sum += account.to_base;
                    newTotals[account.type].count += 1;
                }
                else {
                    newTotals[account.type] = { sum: account.to_base, count: 1 };
                }
                if (account.type !== 'other liability' &&
                    account.type !== 'credit' &&
                    account.type !== 'loan') {
                    newTotals.total += account.to_base;
                }
                else {
                    if (account.type == 'loan') {
                        // Subtract the absolute value
                        newTotals.total -= Math.abs(account.to_base);
                    }
                    else {
                        newTotals.total -= account.to_base;
                    }
                }
                accountCurrencies.add(account.currency);
            }
        });
        if (accountCurrencies.size > 1) {
            setShouldShowSettings(true);
        }
        setTotals(newTotals);
    }, [accounts]);
    (0, react_1.useEffect)(() => {
        let showCryptoInfo = false;
        const accountsByType = _.groupBy(accounts
            .filter(o => {
            if (o.is_zabo && (!o.verified_balance || o.to_base == null)) {
                showCryptoInfo = true;
                return false;
            }
            return true;
        })
            .sort((a, b) => {
            return b.to_base - a.to_base;
        }), 'type');
        const rows = [];
        const ORDERING = [
            'depository',
            'cash',
            'investment',
            'brokerage',
            'cryptocurrency',
            'employee compensation',
            'vehicle',
            'real estate',
            'other asset',
            //
            'credit',
            'loan',
            'other liability',
        ];
        ORDERING.forEach((type, index) => {
            var _a;
            if (type !== 'total') {
                if (totals[type]) {
                    rows.push(React.createElement("div", { className: `card-content-wrapper`, key: `card-${index}` },
                        React.createElement("div", { className: "card-content no-wrap" },
                            React.createElement("span", { className: "card-text ellipsis" },
                                React.createElement("span", { className: `clickable`, onClick: () => {
                                        const newValue = !display[type];
                                        localStorage.setItem(`_lm_overview_display_${type.replace(' ', '_')}`, newValue ? 'show' : 'hide');
                                        setDisplay(Object.assign(Object.assign({}, display), { [type]: newValue }));
                                    } },
                                    React.createElement(semantic_ui_react_1.Icon, { name: display[type] ? 'caret down' : 'caret right' }),
                                    type === 'depository' ? (React.createElement(React.Fragment, null,
                                        "Cash (",
                                        totals[type].count,
                                        ")")) : (React.createElement(React.Fragment, null,
                                        (0, format_1.capitalize)(type),
                                        ' ',
                                        type == 'cryptocurrency' && showCryptoInfo && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "exclamation circle", color: "grey" }), inverted: true, size: "small" }, "Some cryptocurrencies have been filtered out due to having an unverified or null balance. You can see the complete list from the Accounts page.")),
                                        ' ',
                                        "(",
                                        totals[type].count,
                                        ")")))),
                            React.createElement("span", { className: "card-number" },
                                React.createElement("span", { className: type === 'loan' || type === 'credit' ? `color--red` : '' }, type === 'loan' || type === 'credit' ? (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", position: 'right center', trigger: React.createElement("span", null, (0, format_1.toPrice)(totals[type].sum, _user.primaryCurrency)) },
                                    (0, format_1.capitalize)(type),
                                    " accounts are deducted from your net worth.")) : ((0, format_1.toPrice)(totals[type].sum, _user.primaryCurrency)))))));
                    (_a = accountsByType[type]) === null || _a === void 0 ? void 0 : _a.forEach((account, accountIndex) => {
                        var _a;
                        if (account.status === 'active' || account.status === 'relink') {
                            rows.push(React.createElement("div", { className: `card-content-wrapper ${display[type] ? '' : 'display--none'}`, key: `card-${index}-account-${accountIndex}` },
                                React.createElement("div", { className: "card-content no-wrap" },
                                    React.createElement("span", { className: "card-text ellipsis" },
                                        React.createElement("div", { className: "display--flex" },
                                            React.createElement("span", { className: "hierarchy-line-icon-4" }),
                                            React.createElement("span", { className: `account-sub-name` }, ((account.type == 'depository' ||
                                                account.type == 'credit' ||
                                                (account.type == 'investment' &&
                                                    ((_a = account.billed_products) === null || _a === void 0 ? void 0 : _a.indexOf('investment')) >
                                                        -1 &&
                                                    !account.skip_transactions)) &&
                                                account.source == 'plaid') ||
                                                (!account.exclude_transactions &&
                                                    account.source == 'manual') ? (React.createElement(react_router_dom_1.Link, { to: {
                                                    pathname: '/transactions',
                                                    search: `?${account.source == 'plaid'
                                                        ? 'account'
                                                        : 'asset'}=${account.id}&match=any&time=year`,
                                                }, className: "link clickable" }, account.display_name)) : (account.display_name)))),
                                    React.createElement("span", { className: "card-number" },
                                        account.currency !== _user.primaryCurrency ? (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "exchange", color: "yellow", className: "mr-05rem" }), inverted: true, size: "small" },
                                            "Converted",
                                            ' ',
                                            displayInPrimaryCurrency
                                                ? `from 
                          ${(0, format_1.toPrice)(account.balance, account.currency)}`
                                                : `to 
                          ${(0, format_1.toPrice)(account.to_base, _user.primaryCurrency)}`,
                                            ' ',
                                            "at ",
                                            React.createElement("br", null),
                                            (0, format_1.toPrice)(1, account.currency),
                                            " =",
                                            ' ',
                                            (0, format_1.toPrice)(parseFloat(account.to_base) /
                                                parseFloat(account.balance), _user.primaryCurrency, 0.0001))) : (React.createElement(React.Fragment, null)),
                                        React.createElement("span", { className: `account-sub-amount` }, !displayInPrimaryCurrency &&
                                            (account.source == 'zabo' ||
                                                account.source == 'manual-crypto' ||
                                                account.source == 'synced-crypto')
                                            ? (0, format_1.toCrypto)(account.balance, account.currency)
                                            : (0, format_1.toPrice)(displayInPrimaryCurrency
                                                ? account.to_base
                                                : account.balance, displayInPrimaryCurrency
                                                ? _user.primaryCurrency
                                                : account.currency))))));
                        }
                    });
                }
            }
        });
        setRowsUI(rows);
    }, [accounts, totals, display, displayInPrimaryCurrency]);
    return (React.createElement(semantic_ui_react_1.Card, null,
        React.createElement(semantic_ui_react_1.Card.Content, null,
            React.createElement(semantic_ui_react_1.Card.Header, null,
                React.createElement("div", { className: "flex--space-between-flex-start" },
                    React.createElement("span", null),
                    React.createElement("div", null,
                        "Accounts Overview",
                        ' ',
                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }), position: "right center" }, "The balances shown here are current, and are not affected by custom period changes.")),
                    React.createElement("div", null,
                        _assets.plaidAccounts.length > 0 && (React.createElement(semantic_ui_react_1.Popup, { size: "small", inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { loading: isRefreshing, name: "refresh", color: "grey", className: isRefreshing ? '' : 'clickable', onClick: triggerFetch }), content: `Refresh synced accounts (last updated ${lastUpdated ||
                                Moment.max(_assets.plaidAccounts
                                    .map(o => (o.last_fetch ? Moment(o.last_fetch) : null))
                                    .filter(o => !!o)).fromNow()})` })),
                        shouldShowSettings ? (React.createElement(semantic_ui_react_1.Popup, { wide: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "cog", color: "grey", className: "clickable" }), content: React.createElement("div", null,
                                React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: displayInPrimaryCurrency, onChange: (e, { checked }) => {
                                        localStorage.setItem('monthly_overview_show_in_primary_currency', checked ? 'true' : null);
                                        setDisplayInPrimaryCurrency(checked);
                                    }, label: `Show balances in ${_user.primaryCurrency}` }),
                                ' ',
                                React.createElement(semantic_ui_react_1.Popup, { hoverable: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) },
                                    React.createElement("p", null, "You have accounts displaying in more than one currency. Toggle this setting on to view all balances in your primary currency."))), on: "click", position: "bottom center" })) : (React.createElement("span", null)))))),
        React.createElement(semantic_ui_react_1.Card.Content, null,
            rowsUI || React.createElement(semantic_ui_react_1.Loader, { active: true, size: "tiny", inline: "centered" }),
            rowsUI && rowsUI.length == 0 && (React.createElement(react_router_dom_1.Link, { to: { pathname: `/accounts` } },
                React.createElement(semantic_ui_react_1.Icon, { name: "arrow alternate circle right" }),
                " Set up accounts"))),
        React.createElement(semantic_ui_react_1.Card.Content, null,
            React.createElement("div", { className: "card-content-wrapper footer" },
                React.createElement("div", { className: "card-content no-wrap" },
                    React.createElement("span", { className: "card-text ellipsis" },
                        React.createElement("b", null, "Estd. Net Worth")),
                    React.createElement("span", { className: "card-number" }, (0, format_1.toPrice)(totals.total, _user.primaryCurrency)))))));
};
exports.default = AccountSummary;
