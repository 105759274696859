"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const qs = require("query-string");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
const UserProvider_1 = require("@providers/UserProvider");
const ColumnResizer_1 = require("@components/elements/ColumnResizer");
const TransactionTableHeader = ({ hiddenCols = [], preventModal, sortColumn, sortDirection, numBulkSelected, showBulkSelect, hasUnreviewed, checkReviewAll, onDeselectAll, onSelectAll, }) => {
    const location = (0, react_router_dom_1.useLocation)();
    const history = (0, react_router_dom_1.useHistory)();
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [dateWidth, setDateWidth] = (0, react_1.useState)(localStorage.getItem(`transactions-date`));
    const [categoryWidth, setCategoryWidth] = (0, react_1.useState)(localStorage.getItem(`transactions-category`));
    const [payeeWidth, setPayeeWidth] = (0, react_1.useState)(localStorage.getItem(`transactions-payee`));
    const [amountWidth, setAmountWidth] = (0, react_1.useState)(localStorage.getItem(`transactions-amount`));
    const [notesWidth, setNotesWidth] = (0, react_1.useState)(localStorage.getItem(`transactions-notes`));
    const [accountWidth, setAccountWidth] = (0, react_1.useState)(localStorage.getItem(`transactions-accounts`));
    const [tagsWidth, setTagsWidth] = (0, react_1.useState)(localStorage.getItem(`transactions-tags`));
    (0, react_1.useEffect)(() => {
        // removeLargestWidth
        const columns = [
            'date',
            'category',
            'payee',
            'amount',
            'notes',
            'accounts',
            'tags',
        ];
        let missing = false;
        let maxCol;
        let maxWidth = 0;
        columns.forEach(col => {
            if (localStorage.getItem(`transactions-${col}`) == null &&
                (hiddenCols === null || hiddenCols === void 0 ? void 0 : hiddenCols.indexOf(col)) == -1) {
                missing = true;
            }
            else {
                const width = parseInt(localStorage.getItem(`transactions-${col}`) || '0');
                if (width > maxWidth) {
                    maxCol = col;
                    maxWidth = width;
                }
            }
        });
        // Only do this if all widths are set
        if (!missing) {
            localStorage.removeItem(`transactions-${maxCol}`);
        }
        setDateWidth(localStorage.getItem(`transactions-date`));
        setCategoryWidth(localStorage.getItem(`transactions-category`));
        setPayeeWidth(localStorage.getItem(`transactions-payee`));
        setAmountWidth(localStorage.getItem(`transactions-amount`));
        setNotesWidth(localStorage.getItem(`transactions-notes`));
        setAccountWidth(localStorage.getItem(`transactions-accounts`));
        setTagsWidth(localStorage.getItem(`transactions-tags`));
    }, [hiddenCols]);
    const sort = column => {
        const query = qs.parse(location.search);
        let direction = query.direction;
        if (column == query.sort) {
            // Reverse direction
            if (query.direction == 'ascending') {
                direction = 'descending';
            }
            else {
                direction = 'ascending';
            }
        }
        history.replace({
            search: `?${qs.stringify(Object.assign(Object.assign({}, query), { sort: column, direction: direction || 'descending' }))}`,
        });
    };
    return (React.createElement(semantic_ui_react_1.Table.Header, { className: "sticky" },
        React.createElement(semantic_ui_react_1.Table.Row, null,
            showBulkSelect && (React.createElement(React.Fragment, null,
                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "center-align", style: { width: '50px' } },
                    React.createElement("div", { className: `bulk-checkbox ${numBulkSelected > 0 ? 'deselect' : 'cta'}`, onClick: () => {
                            if (numBulkSelected > 0) {
                                onDeselectAll();
                            }
                            else {
                                onSelectAll();
                            }
                        } })),
                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }))),
            React.createElement(semantic_ui_react_1.Table.HeaderCell, { key: `date-col-${dateWidth}`, className: "table-cell-date", sorted: sortColumn === 'date' || !sortColumn
                    ? sortDirection || 'descending'
                    : null, onClick: () => {
                    sort('date');
                }, style: dateWidth
                    ? {
                        width: `${dateWidth}px`,
                    }
                    : {} },
                React.createElement("div", null, "Date")),
            React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'transactions', prevCol: 'date', nextCol: 'category' }),
            React.createElement(semantic_ui_react_1.Table.HeaderCell, { key: `category-col-${categoryWidth}`, className: "table-cell-category", sorted: sortColumn === 'category' ? sortDirection : null, onClick: () => {
                    sort('category');
                }, style: categoryWidth
                    ? {
                        width: `${categoryWidth}px`,
                    }
                    : {} },
                React.createElement("div", null, "Category")),
            React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'transactions', prevCol: 'category', nextCol: 'payee' }),
            React.createElement(semantic_ui_react_1.Table.HeaderCell, { key: `payee-col-${payeeWidth}`, className: "table-cell-payee", sorted: sortColumn === 'payee' ? sortDirection : null, onClick: () => {
                    sort('payee');
                }, style: payeeWidth
                    ? {
                        width: `${payeeWidth}px`,
                    }
                    : {} },
                React.createElement("div", null, "Payee")),
            React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'transactions', prevCol: 'payee', nextCol: 'amount' }),
            React.createElement(semantic_ui_react_1.Table.HeaderCell, { key: `amount-col-${amountWidth}`, className: "table-cell-amount number", sorted: sortColumn === 'amount' ? sortDirection : null, onClick: () => {
                    sort('amount');
                }, style: amountWidth
                    ? {
                        width: `${amountWidth}px`,
                    }
                    : {} },
                React.createElement("div", null,
                    "Amount",
                    ' ',
                    React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", fitted: true }) },
                        React.createElement("p", null, _user.settings['show_debits_as_negative']
                            ? 'Negative numbers represent money going out (eg.spending); positive numbers represents money coming in (eg. income)'
                            : 'Negative numbers represent money coming in (eg. income); positive numbers represents money going out (eg. spending)'),
                        React.createElement("p", null, "You can always change this in the Settings page.")))),
            (hiddenCols || []).indexOf('notes') == -1 && (React.createElement(React.Fragment, null,
                React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'transactions', prevCol: 'amount', nextCol: 'notes' }),
                React.createElement(semantic_ui_react_1.Table.HeaderCell, { key: `notes-col-${notesWidth}`, className: "table-cell-notes", sorted: sortColumn === 'notes' ? sortDirection : null, onClick: () => {
                        sort('notes');
                    }, style: notesWidth
                        ? {
                            width: `${notesWidth}px`,
                        }
                        : {} },
                    React.createElement("div", null, "Notes")))),
            (hiddenCols || []).indexOf('accounts') == -1 && (React.createElement(React.Fragment, null,
                React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'transactions', prevCol: (hiddenCols || []).indexOf('notes') == -1 ? 'notes' : 'amount', nextCol: 'accounts' }),
                React.createElement(semantic_ui_react_1.Table.HeaderCell, { key: `account-col-${accountWidth}`, className: "table-cell-account", sorted: sortColumn === 'account' ? sortDirection : null, onClick: () => {
                        sort('account');
                    }, style: accountWidth
                        ? {
                            width: `${accountWidth}px`,
                        }
                        : {} },
                    React.createElement("div", null, "Account")))),
            (hiddenCols || []).indexOf('tags') == -1 && (React.createElement(React.Fragment, null,
                React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'transactions', prevCol: (hiddenCols || []).indexOf('accounts') == -1
                        ? 'accounts'
                        : (hiddenCols || []).indexOf('notes') == -1
                            ? 'notes'
                            : 'amount', nextCol: 'tags' }),
                React.createElement(semantic_ui_react_1.Table.HeaderCell, { key: `tags-col-${tagsWidth}`, className: "no-hover table-cell-tags", style: tagsWidth
                        ? {
                            width: `${tagsWidth}px`,
                        }
                        : {} }, "Tags"))),
            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-circle no-side-padding" }, hasUnreviewed && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, position: "top right", mouseEnterDelay: 500, size: "tiny", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "check circle", className: "clickable", color: "green", onClick: checkReviewAll }) }, "Mark all transactions as reviewed"))),
            !preventModal && React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-arrow" }))));
};
exports.default = TransactionTableHeader;
