"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getErrors = exports.unlinkPlaidAccount = exports.handleRemovePlaidAccount = exports.getAccountSiblings = exports.getPublicToken = exports.populatePlaidCategory = exports.createAndSetPlaidCategories = exports.setPlaidCategory = exports.getUserPlaidCategories = exports.getPlaidCategories = exports.fetchTransactions = exports.detectDeactivations = exports.overrideWithOriginalName = exports.updateAccount = exports.getAccounts = exports.createLinkToken = exports.initialize = void 0;
const common_1 = require("./common");
const initialize = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`plaid/initialize`, body);
});
exports.initialize = initialize;
const createLinkToken = (obj) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`plaid/link_token`, obj || {});
});
exports.createLinkToken = createLinkToken;
const getAccounts = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`plaid/accounts`, opts);
});
exports.getAccounts = getAccounts;
const updateAccount = (id, opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`plaid/accounts/${id}`, opts);
});
exports.updateAccount = updateAccount;
const overrideWithOriginalName = (id, opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`plaid/accounts/${id}/override_original`, opts);
});
exports.overrideWithOriginalName = overrideWithOriginalName;
const detectDeactivations = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`plaid/accounts/detect_deactivation`, opts);
});
exports.detectDeactivations = detectDeactivations;
const fetchTransactions = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`plaid/transactions/fetch`, opts);
});
exports.fetchTransactions = fetchTransactions;
const getPlaidCategories = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`plaid/categories`, {});
});
exports.getPlaidCategories = getPlaidCategories;
const getUserPlaidCategories = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`plaid/categories/user`, {});
});
exports.getUserPlaidCategories = getUserPlaidCategories;
const setPlaidCategory = (obj) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`plaid/categories/set`, obj);
});
exports.setPlaidCategory = setPlaidCategory;
const createAndSetPlaidCategories = (obj) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`plaid/categories/create_and_set`, obj);
});
exports.createAndSetPlaidCategories = createAndSetPlaidCategories;
const populatePlaidCategory = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`plaid/categories/populate`, {});
});
exports.populatePlaidCategory = populatePlaidCategory;
const getPublicToken = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`plaid/accounts/public_token`, opts);
});
exports.getPublicToken = getPublicToken;
const getAccountSiblings = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`plaid/accounts/${id}/siblings`, {});
});
exports.getAccountSiblings = getAccountSiblings;
const handleRemovePlaidAccount = (id, opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`plaid/accounts/${id}/remove`, opts);
});
exports.handleRemovePlaidAccount = handleRemovePlaidAccount;
const unlinkPlaidAccount = (id, opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`plaid/accounts/${id}/unlink`, opts);
});
exports.unlinkPlaidAccount = unlinkPlaidAccount;
const getErrors = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`plaid/errors`, {});
});
exports.getErrors = getErrors;
