"use strict";
/**
 *  NewTransactionRow.tsx
 *  Row for adding a new transaction to the transaction table
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
// Components
const EditableString_1 = require("@components/elements/EditableString");
class NewCategoryRow extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            name: undefined,
            description: undefined,
            is_income: false,
            exclude_from_budget: false,
            exclude_from_totals: false,
        };
    }
    render() {
        return (React.createElement(semantic_ui_react_1.Table.Row, { className: "editing" },
            React.createElement(semantic_ui_react_1.Table.Cell, null,
                React.createElement(EditableString_1.default, { autoFocus: true, shouldSaveOnChange: true, location: 'inline', identifier: "new-category-payee", placeholder: this.props.showNewCategory == 'group'
                        ? 'Category Group name'
                        : 'Category name', firstValue: this.state.name, state: 'Editing', onSave: name => {
                        this.setState({ name });
                    }, onEnter: () => __awaiter(this, void 0, void 0, function* () {
                        this.props.onSave({
                            name: this.state.name,
                            description: this.state.description,
                        });
                    }) })),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, null,
                React.createElement(EditableString_1.default, { shouldSaveOnChange: true, identifier: "new-category-description", location: 'inline', firstValue: this.state.description, state: 'Editing', onSave: description => {
                        this.setState({ description });
                    }, onEnter: () => __awaiter(this, void 0, void 0, function* () {
                        this.props.onSave({
                            name: this.state.name,
                            description: this.state.description,
                            is_income: this.state.is_income,
                            exclude_from_budget: this.state.exclude_from_budget,
                            exclude_from_totals: this.state.exclude_from_totals,
                        });
                    }) })),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align" },
                React.createElement(semantic_ui_react_1.Checkbox, { style: { position: 'relative', top: '5px' }, toggle: true, checked: this.state.is_income, onChange: (e, { checked }) => {
                        this.setState({ is_income: checked });
                    } })),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align" },
                React.createElement(semantic_ui_react_1.Checkbox, { style: { position: 'relative', top: '5px' }, toggle: true, checked: this.state.exclude_from_budget, onChange: (e, { checked }) => {
                        this.setState({ exclude_from_budget: checked });
                    } })),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align" },
                React.createElement(semantic_ui_react_1.Checkbox, { style: { position: 'relative', top: '5px' }, toggle: true, checked: this.state.exclude_from_totals, onChange: (e, { checked }) => {
                        this.setState({ exclude_from_totals: checked });
                    } })),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: 3, className: "center-align" },
                React.createElement(semantic_ui_react_1.Button, { className: 'green-hover', size: "tiny", color: "green", onClick: () => __awaiter(this, void 0, void 0, function* () {
                        this.props.onSave({
                            name: this.state.name,
                            description: this.state.description,
                            is_income: this.state.is_income,
                            exclude_from_budget: this.state.exclude_from_budget,
                            exclude_from_totals: this.state.exclude_from_totals,
                        });
                    }) }, "Add"))));
    }
}
exports.default = NewCategoryRow;
