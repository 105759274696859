"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const qs = require("query-string");
const Moment = require("moment");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const PeriodPicker_1 = require("@components/QueryTool/PeriodPicker");
const StatsCard_1 = require("@components/Stats/StatsCard");
const EditablePrice_1 = require("@components/elements/EditablePrice");
const stats_1 = require("@actions/stats");
const UserProvider_1 = require("@providers/UserProvider");
const Stats = ({}) => {
    const history = (0, react_router_dom_1.useHistory)();
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [data, setData] = (0, react_1.useState)(null);
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    const [startDate, setStartDate] = (0, react_1.useState)(null);
    const [endDate, setEndDate] = (0, react_1.useState)(null);
    const [includeRecurring, setIncludeRecurring] = (0, react_1.useState)(_user.settings['stats_include_recurring'] || false);
    const [includeExcludeFromTotals, setIncludeExcludeFromTotals] = (0, react_1.useState)(_user.settings['stats_include_exclude_from_totals'] || false);
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = 'Stats - Lunch Money';
        init();
    }, []);
    const init = () => __awaiter(void 0, void 0, void 0, function* () {
        // On load, if nothing exists, then go for Month to date
        if (history.location.search.length == 0 &&
            _user.settings['stats_time_period']) {
            setTimePeriods({ date_range: _user.settings['stats_time_period'] });
        }
        else {
            // No default yet
            setTimePeriods({ date_range: 'Last 30 days' });
        }
    });
    (0, react_1.useEffect)(() => {
        if (startDate && endDate) {
            fetch();
        }
    }, [startDate, endDate, includeRecurring, includeExcludeFromTotals]);
    const fetch = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const results = yield (0, stats_1.getStats)({
            start_date: startDate,
            end_date: endDate,
            include_recurring: includeRecurring,
            include_exclude_from_totals: includeExcludeFromTotals,
        });
        setData(results);
        setIsLoading(false);
    });
    // If changes are detected from the period picker, it comes here
    (0, react_1.useEffect)(() => {
        if (history.location.search.length > 0) {
            const dateObj = qs.parse(history.location.search);
            setTimePeriods(dateObj);
        }
    }, [history.location.search]);
    const setTimePeriods = dateObj => {
        let _startDate;
        let _endDate;
        if (dateObj['date_range'] == 'Month to date') {
            _startDate = Moment()
                .startOf('month')
                .format('YYYY-MM-DD');
            _endDate = Moment().format('YYYY-MM-DD');
        }
        else if (dateObj['date_range'] == 'Year to date') {
            _startDate = Moment()
                .startOf('year')
                .format('YYYY-MM-DD');
            _endDate = Moment().format('YYYY-MM-DD');
        }
        else if (dateObj['date_range'] == 'Last 7 days') {
            _startDate = Moment()
                .subtract(6, 'days')
                .format('YYYY-MM-DD');
            _endDate = Moment().format('YYYY-MM-DD');
        }
        else if (dateObj['date_range'] == 'Last 14 days') {
            _startDate = Moment()
                .subtract(13, 'days')
                .format('YYYY-MM-DD');
            _endDate = Moment().format('YYYY-MM-DD');
        }
        else if (dateObj['date_range'] == 'Last 30 days') {
            _startDate = Moment()
                .subtract(29, 'days')
                .format('YYYY-MM-DD');
            _endDate = Moment().format('YYYY-MM-DD');
        }
        else if (dateObj['date_range'] == 'Last month') {
            _startDate = Moment()
                .subtract(1, 'months')
                .startOf('month')
                .format('YYYY-MM-DD');
            _endDate = Moment()
                .subtract(1, 'months')
                .endOf('month')
                .format('YYYY-MM-DD');
        }
        else if (dateObj['date_range'] == 'Last 3 months') {
            _startDate = Moment()
                .startOf('month')
                .subtract(2, 'months')
                .format('YYYY-MM-DD');
            _endDate = Moment()
                .endOf('month')
                .format('YYYY-MM-DD');
        }
        else if (dateObj['date_range'] == 'Last 6 months') {
            _startDate = Moment()
                .startOf('month')
                .subtract(5, 'months')
                .format('YYYY-MM-DD');
            _endDate = Moment()
                .endOf('month')
                .format('YYYY-MM-DD');
        }
        else if (dateObj['date_range'] == 'Last 12 months') {
            _startDate = Moment()
                .startOf('month')
                .subtract(11, 'months')
                .format('YYYY-MM-DD');
            _endDate = Moment()
                .endOf('month')
                .format('YYYY-MM-DD');
        }
        else if (dateObj['date_range'] == 'Last year') {
            _startDate = Moment()
                .subtract(1, 'year')
                .startOf('year')
                .format('YYYY-MM-DD');
            _endDate = Moment()
                .subtract(1, 'year')
                .endOf('year')
                .format('YYYY-MM-DD');
        }
        else if (dateObj['date_range'] == 'This year') {
            _startDate = Moment()
                .startOf('year')
                .format('YYYY-MM-DD');
            _endDate = Moment()
                .endOf('year')
                .format('YYYY-MM-DD');
        }
        else if (dateObj['start_date'] && dateObj['end_date']) {
            _startDate = dateObj['start_date'];
            _endDate = dateObj['end_date'];
        }
        if (_startDate && _endDate) {
            setStartDate(_startDate);
            setEndDate(_endDate);
        }
        if (dateObj['date_range'] &&
            _user.settings['stats_time_period'] != dateObj['date_range']) {
            _user.updateSetting('stats_time_period', dateObj['date_range']);
        }
    };
    return (React.createElement(semantic_ui_react_1.Container, { id: "g-stats" },
        React.createElement(ContainerHeader_1.default, { title: 'Statistics' }),
        React.createElement("div", { className: "stats-header-bar" },
            React.createElement(PeriodPicker_1.default, { showArrows: true, disabled: isLoading, firstValue: _user.settings['stats_time_period'] || 'Month to date', className: 'display--inline-block overview-period-picker', useSelectedDate: true }),
            React.createElement("div", { className: "display--flex stats-checkboxes" },
                React.createElement("div", { className: "display--flex mr-1rem" },
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: includeRecurring, onChange: (e, { checked }) => {
                            setIncludeRecurring(checked);
                            _user.updateSetting('stats_include_recurring', checked);
                        }, label: "Include recurring" }),
                    React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }) }, "Check this box to include recurring transactions in these statistics. Recurring transactions will be denoted in green.")),
                React.createElement("div", { className: "display--flex" },
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: includeExcludeFromTotals, onChange: (e, { checked }) => {
                            setIncludeExcludeFromTotals(checked);
                            _user.updateSetting('stats_include_exclude_from_totals', checked);
                        }, label: "Include 'exclude from totals'" }),
                    React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }) }, "Check this box to include transactions which are categorized as \"exclude from totals\". These transactions will be denoted in red.")))),
        React.createElement("div", { id: "stats-container" },
            React.createElement(StatsCard_1.default, { header: "Top expensive purchases", data: data === null || data === void 0 ? void 0 : data.by_price_desc, isLoading: isLoading, mapFn: (o, index) => {
                    return (React.createElement(semantic_ui_react_1.Table.Row, { className: `padded-row ${!!o.recurring_id ? 'recurring' : ''} ${o.exclude_from_totals ? 'excluded' : ''}`, key: `transaction-cost-row-${index}` },
                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap", style: { width: '70px' } }, Moment(o.date).format(_user.getMonthDayFormat())),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, o.payee),
                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "cell-number right-align" },
                            React.createElement("div", { className: "flex--end" },
                                React.createElement(EditablePrice_1.default, { className: 'short', identifier: `spending-breakdown-${new Date().getTime()}`, amount: o.amount, currency: o.currency, state: 'ReadOnly', showSignLabel: true, location: 'inline', onSave: (amount, currency) => { } }),
                                React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "mini", mouseEnterDelay: 1000, position: "top center", trigger: React.createElement(react_router_dom_1.Link, { target: "_blank", to: {
                                            pathname: `/transactions/${Moment(o.date, 'YYYY-MM-DD').format('YYYY/MM')}`,
                                            search: qs.stringify({
                                                start_date: o.date,
                                                end_date: o.date,
                                                payee_exact: o.payee,
                                                time: 'custom',
                                                match: 'all',
                                            }),
                                        } },
                                        React.createElement(semantic_ui_react_1.Icon, { name: "external", className: "ml-1rem color--grey" })) }, "View transaction(s)")))));
                } }),
            React.createElement(StatsCard_1.default, { header: "New merchants", data: data === null || data === void 0 ? void 0 : data.new_merchants, isLoading: isLoading, mapFn: (o, index) => {
                    const opts = {};
                    if (o.recurring_id) {
                        opts['recurring'] = o.recurring_id;
                    }
                    else if (o.payee) {
                        opts['payee_exact'] = o.payee;
                    }
                    return (React.createElement(semantic_ui_react_1.Table.Row, { className: `padded-row ${!!o.recurring_id ? 'recurring' : ''} ${o.exclude_from_totals ? 'excluded' : ''}`, key: `new-merchant-count-row-${index}` },
                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                            index + 1,
                            ". ",
                            o.display_name),
                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "cell-number right-align" },
                            React.createElement("div", { className: "flex--end" },
                                React.createElement(EditablePrice_1.default, { className: 'short', identifier: `spending-breakdown-${new Date().getTime()}`, amount: o.total, currency: o.currency, state: 'ReadOnly', showSignLabel: true, location: 'inline', onSave: (amount, currency) => { } }),
                                React.createElement("div", { className: "ml-05rem" },
                                    "(",
                                    o.count,
                                    ")"),
                                React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "mini", mouseEnterDelay: 1000, position: "top center", trigger: React.createElement(react_router_dom_1.Link, { target: "_blank", to: {
                                            pathname: `/transactions/${Moment().format('YYYY/MM')}`,
                                            search: qs.stringify(Object.assign(Object.assign({ start_date: startDate, end_date: endDate }, opts), { time: 'custom', match: 'all' })),
                                        } },
                                        React.createElement(semantic_ui_react_1.Icon, { name: "external", className: "ml-1rem color--grey" })) }, "View transaction(s)")))));
                } }),
            React.createElement(StatsCard_1.default, { header: "Top merchants by total cost", data: data === null || data === void 0 ? void 0 : data.by_merchant_cost, isLoading: isLoading, mapFn: (o, index) => {
                    const opts = {};
                    if (o.recurring_id) {
                        opts['recurring'] = o.recurring_id;
                    }
                    else if (o.payee) {
                        opts['payee_exact'] = o.payee;
                    }
                    return (React.createElement(semantic_ui_react_1.Table.Row, { className: `padded-row ${!!o.recurring_id ? 'recurring' : ''} ${o.exclude_from_totals ? 'excluded' : ''}`, key: `merchant-cost-row-${index}` },
                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                            index + 1,
                            ". ",
                            o.display_name),
                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "cell-number" },
                            React.createElement("div", { className: "flex--end" },
                                React.createElement(EditablePrice_1.default, { className: 'short', identifier: `spending-breakdown-${new Date().getTime()}`, amount: o.total, currency: o.currency, state: 'ReadOnly', showSignLabel: true, location: 'inline', onSave: (amount, currency) => { } }),
                                React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "mini", mouseEnterDelay: 1000, position: "top center", trigger: React.createElement(react_router_dom_1.Link, { target: "_blank", to: {
                                            pathname: `/transactions/${Moment().format('YYYY/MM')}`,
                                            search: qs.stringify(Object.assign(Object.assign({ start_date: startDate, end_date: endDate }, opts), { time: 'custom', match: 'all' })),
                                        } },
                                        React.createElement(semantic_ui_react_1.Icon, { name: "external", className: "ml-1rem color--grey" })) }, "View transaction(s)")))));
                } }),
            React.createElement(StatsCard_1.default, { header: "Top merchants by # of transactions", data: data === null || data === void 0 ? void 0 : data.by_merchant_count, isLoading: isLoading, mapFn: (o, index) => {
                    const opts = {};
                    if (o.recurring_id) {
                        opts['recurring'] = o.recurring_id;
                    }
                    else if (o.payee) {
                        opts['payee_exact'] = o.payee;
                    }
                    return (React.createElement(semantic_ui_react_1.Table.Row, { className: `padded-row ${!!o.recurring_id ? 'recurring' : ''} ${o.exclude_from_totals ? 'excluded' : ''}`, key: `merchant-count-row-${index}` },
                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                            index + 1,
                            ". ",
                            o.display_name),
                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "cell-number right-align" },
                            React.createElement("div", { className: "flex--end" },
                                o.count,
                                React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "mini", mouseEnterDelay: 1000, position: "top center", trigger: React.createElement(react_router_dom_1.Link, { target: "_blank", to: {
                                            pathname: `/transactions/${Moment().format('YYYY/MM')}`,
                                            search: qs.stringify(Object.assign(Object.assign({ start_date: startDate, end_date: endDate }, opts), { time: 'custom', match: 'all' })),
                                        } },
                                        React.createElement(semantic_ui_react_1.Icon, { name: "external", className: "ml-1rem color--grey" })) }, "View transaction(s)")))));
                } }),
            React.createElement(StatsCard_1.default, { header: "Top categories by total cost", data: data === null || data === void 0 ? void 0 : data.by_category_cost, isLoading: isLoading, mapFn: (o, index) => {
                    const opts = {};
                    if (o.category_id) {
                        opts['category'] = o.category_id;
                    }
                    else {
                        opts['uncategorized'] = true;
                    }
                    return (React.createElement(semantic_ui_react_1.Table.Row, { className: `padded-row ${o.exclude_from_totals ? 'excluded' : ''}`, key: `category-cost-row-${index}` },
                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                            index + 1,
                            ". ",
                            o.category_name || 'Uncategorized'),
                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "cell-number right-align" },
                            React.createElement("div", { className: "flex--end" },
                                React.createElement(EditablePrice_1.default, { className: 'short', identifier: `spending-breakdown-${new Date().getTime()}`, amount: o.total, currency: o.currency, state: 'ReadOnly', showSignLabel: true, location: 'inline', onSave: (amount, currency) => { } }),
                                React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "mini", mouseEnterDelay: 1000, position: "top center", trigger: React.createElement(react_router_dom_1.Link, { target: "_blank", to: {
                                            pathname: `/transactions/${Moment().format('YYYY/MM')}`,
                                            search: qs.stringify(Object.assign(Object.assign({}, opts), { start_date: startDate, end_date: endDate, time: 'custom', match: 'all' })),
                                        } },
                                        React.createElement(semantic_ui_react_1.Icon, { name: "external", className: "ml-1rem color--grey" })) }, "View transaction(s)")))));
                } }),
            React.createElement(StatsCard_1.default, { header: "Top categories by # of transactions", data: data === null || data === void 0 ? void 0 : data.by_category_count, isLoading: isLoading, mapFn: (o, index) => {
                    const opts = {};
                    if (o.category_id) {
                        opts['category'] = o.category_id;
                    }
                    else {
                        opts['uncategorized'] = true;
                    }
                    return (React.createElement(semantic_ui_react_1.Table.Row, { className: `padded-row ${o.exclude_from_totals ? 'excluded' : ''}`, key: `category-count-row-${index}` },
                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                            index + 1,
                            ". ",
                            o.category_name || 'Uncategorized'),
                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "cell-number right-align" },
                            React.createElement("div", { className: "flex--end" },
                                o.count,
                                React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "mini", mouseEnterDelay: 1000, position: "top center", trigger: React.createElement(react_router_dom_1.Link, { target: "_blank", to: {
                                            pathname: `/transactions/${Moment().format('YYYY/MM')}`,
                                            search: qs.stringify(Object.assign(Object.assign({}, opts), { start_date: startDate, end_date: endDate, time: 'custom', match: 'all' })),
                                        } },
                                        React.createElement(semantic_ui_react_1.Icon, { name: "external", className: "ml-1rem color--grey" })) }, "View transaction(s)")))));
                } }))));
};
exports.default = Stats;
