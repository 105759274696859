"use strict";
/**
 *  EditModal.tsx
 *  Recurring expenses edit modal
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const _ = require("lodash");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const GlobalProvider_1 = require("@providers/GlobalProvider");
const transactions_1 = require("@actions/transactions");
const TransactionsProvider_1 = require("@providers/TransactionsProvider");
const ConfirmBulkDelete = ({ setBalances, amendTransactions, _process, close, show, }) => {
    const _global = (0, react_1.useContext)(GlobalProvider_1.GlobalContext);
    const [transactions, setTransactions] = (0, react_1.useState)([]);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = (0, react_1.useState)(false);
    // Editable fields
    const _transactions = (0, react_1.useContext)(TransactionsProvider_1.TransactionsContext);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [ungroupOnly, setUngroupOnly] = (0, react_1.useState)(true);
    const [unsplitOnly, setUnsplitOnly] = (0, react_1.useState)(true);
    const isGroup = (0, react_1.useRef)([]);
    const isGrouped = (0, react_1.useRef)([]);
    const isSplit = (0, react_1.useRef)([]);
    const transactionIds = (0, react_1.useRef)([]);
    (0, react_1.useEffect)(() => {
        setShowDeleteConfirmation(show);
        if (show) {
            isGroup.current = [];
            isGrouped.current = [];
            isSplit.current = [];
            transactionIds.current = [];
            setTransactions(Object.values(_transactions.bulkSelected.current).filter(o => {
                if (!o)
                    return false;
                if (o['is_group']) {
                    // TODO
                    isGroup.current.push(o);
                }
                else if (o['parent_id']) {
                    // Unsplits
                    isSplit.current.push(o);
                }
                else if (o['group_id']) {
                    isGrouped.current.push(o);
                    // Grouped transactions will simply get removed
                    // TODO: Needs to somehow update parent transaction amount..
                }
                transactionIds.current.push(o['id']);
                return !!o;
            }));
        }
    }, [show]);
    return (React.createElement(semantic_ui_react_1.Modal, { open: showDeleteConfirmation, size: 'small' },
        React.createElement(semantic_ui_react_1.Modal.Header, null, "Confirm transaction deletion"),
        React.createElement(semantic_ui_react_1.Modal.Content, null,
            React.createElement(semantic_ui_react_1.Message, { info: true },
                "Are you sure you want to delete ",
                transactions.length,
                " transaction",
                transactions.length > 1 ? 's' : '',
                " in total?"),
            isGroup.current.length > 0 && (React.createElement(React.Fragment, null,
                React.createElement("h4", null,
                    "You selected ",
                    isGroup.current.length,
                    ' ',
                    React.createElement("b", null,
                        "transaction group",
                        isGroup.current.length > 1 && 's'),
                    "."),
                React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setUngroupOnly(true);
                    }), className: `link-recurring-row ${ungroupOnly ? 'selected' : ''}` },
                    React.createElement("span", null,
                        "I want to ungroup the selected transaction group",
                        isGroup.current.length > 1 && 's',
                        " only"),
                    ungroupOnly ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))),
                React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setUngroupOnly(false);
                    }), className: `link-recurring-row ${ungroupOnly ? '' : 'selected'}` },
                    React.createElement("span", null,
                        "I want to ungroup and delete all transactions within the transaction group",
                        isGroup.current.length > 1 && 's'),
                    !ungroupOnly ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))))),
            isGrouped.current.length > 0 && (React.createElement(React.Fragment, null,
                React.createElement("h4", null,
                    "You selected ",
                    isGrouped.current.length,
                    ' ',
                    React.createElement("b", null,
                        "grouped transaction",
                        isGrouped.current.length > 1 && 's'),
                    ' ',
                    "(part of a transaction group)"),
                React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () { }), className: `link-recurring-row selected` },
                    React.createElement("span", null,
                        "I want to remove the transaction from the transaction group",
                        isGrouped.current.length > 1 && 's'),
                    true ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))))),
            isSplit.current.length > 0 && (React.createElement(React.Fragment, null,
                React.createElement("h4", null,
                    "You selected ",
                    isSplit.current.length,
                    ' ',
                    React.createElement("b", null,
                        "split transaction",
                        isSplit.current.length > 1 && 's')),
                React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setUnsplitOnly(true);
                    }), className: `link-recurring-row ${unsplitOnly ? 'selected' : ''}` },
                    React.createElement("span", null,
                        "I want to unsplit and keep the parent transaction",
                        isSplit.current.length > 1 && 's'),
                    unsplitOnly ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))),
                React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setUnsplitOnly(false);
                    }), className: `link-recurring-row ${unsplitOnly ? '' : 'selected'}` },
                    React.createElement("span", null,
                        "I want to unsplit and delete the parent transaction",
                        isSplit.current.length > 1 && 's'),
                    !unsplitOnly ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" })))))),
        React.createElement(semantic_ui_react_1.Modal.Actions, null,
            React.createElement(semantic_ui_react_1.Button, { basic: true, loading: isLoading, color: "grey", onClick: () => {
                    setShowDeleteConfirmation(false);
                    close();
                } }, "No, Cancel"),
            React.createElement(semantic_ui_react_1.Button, { negative: true, loading: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    var _a, _b, _c, _d, _e, _f;
                    // For amend
                    const amendRemove = [];
                    const amendBulkUpdate = [];
                    setIsLoading(true);
                    // First, unsplit
                    if (isSplit.current.length > 0) {
                        const parentIds = _.uniq(isSplit.current.map(tx => tx.parent_id));
                        const results = yield _process(transactions_1.unsplitTransactions)({
                            transaction_ids: parentIds,
                            remove_parents: !unsplitOnly,
                        });
                        if (!results.error) {
                            if (unsplitOnly) {
                                _global.showToast({
                                    message: 'Successfully unsplit transaction',
                                    type: 'success',
                                });
                            }
                            amendBulkUpdate.push({
                                ids: parentIds,
                                update: { has_children: false, split: [] },
                            });
                            amendRemove.push(_.uniq([
                                ...(results.data || []),
                                ...(isSplit.current.map(tx => tx.id) || null),
                            ])); // prev splits
                            if (((_a = results.data) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                                // Remove from transactionIds.current so there's no overlap
                                transactionIds.current = transactionIds.current.filter(id => !results.data.includes(id));
                            }
                            isSplit.current = [];
                        }
                        else {
                            _global.showToast({
                                message: 'Error unsplitting transaction(s)',
                                type: 'error',
                            });
                        }
                    }
                    // Amend isGrouped's parent transaction totals
                    if (isGrouped.current.length > 0) {
                        isGrouped.current.forEach(tx => {
                            amendBulkUpdate.push({
                                ids: [tx.group_id],
                                subtract: tx.to_base,
                                update: {},
                            });
                        });
                    }
                    // Ungroup
                    if (isGroup.current.length > 0) {
                        const results = yield _process(transactions_1.ungroupTransactions)({
                            transaction_ids: isGroup.current.map(o => o.id),
                        });
                        if (!results.error) {
                            if (!ungroupOnly) {
                                // Add ids to be deleted
                                (_b = results.data.orphaned) === null || _b === void 0 ? void 0 : _b.forEach(tx => {
                                    transactionIds.current.push(tx);
                                });
                            }
                            else {
                                // Update existing ids to be ungrouped
                                amendBulkUpdate.push({
                                    ids: results.data.orphaned || [],
                                    update: {
                                        group_id: null,
                                    },
                                });
                            }
                            amendRemove.push(isGroup.current.map(o => o.id));
                            if (ungroupOnly) {
                                _global.showToast({
                                    message: `Successfully ungrouped transactions`,
                                    type: 'success',
                                });
                            }
                            isGroup.current = [];
                        }
                    }
                    if (transactionIds.current.length > 0) {
                        const results = yield _process(transactions_1.deleteTransactions)({
                            transaction_ids: transactionIds.current,
                        });
                        if (!results.error) {
                            if (((_c = results.data) === null || _c === void 0 ? void 0 : _c.asset_update) &&
                                ((_d = results.data) === null || _d === void 0 ? void 0 : _d.asset_update.length) > 0) {
                                setBalances(results.data.asset_update);
                            }
                            else {
                                if ((_e = results.data) === null || _e === void 0 ? void 0 : _e.background_job) {
                                    // Show success message
                                    _global.showToast({
                                        message: 'Deleting transactions in the background',
                                        content: 'Expense totals will update when operation is done.',
                                        type: 'success',
                                        autoDismissTimeout: 3500,
                                    });
                                }
                                else {
                                    // Show success message
                                    _global.showToast({
                                        message: 'Successfully deleted transaction',
                                        type: 'success',
                                    });
                                }
                            }
                            // Remove from transactions list
                            amendRemove.push(transactions.map(o => o.id));
                            // extra_removed contains other IDs that were removed in the
                            // process, such as groups and splits
                            if ((_f = results.data) === null || _f === void 0 ? void 0 : _f.extra_removed) {
                                amendRemove.push(...results.data.extra_removed);
                            }
                            transactionIds.current = [];
                        }
                        else {
                        }
                    }
                    if (amendTransactions) {
                        amendTransactions({
                            bulkUpdate: amendBulkUpdate,
                            remove: _.flatten(_.uniq(amendRemove)),
                        });
                    }
                    setShowDeleteConfirmation(false);
                    setIsLoading(false);
                    close();
                }) }, "Yes, Delete"))));
};
exports.default = ConfirmBulkDelete;
