"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTimeArray = exports.getNextMonth = exports.getPreviousMonth = exports.getThisMonthEnd = exports.getThisMonthStart = exports.getFormattedMonth = exports.getNext2Weeks = exports.getPrevious2Weeks = exports.getStartOfWeek = exports.calculateTimeUntil = exports.getMonthProgress = void 0;
const Moment = require("moment");
const MomentTZ = require("moment-timezone");
const getMonthProgress = () => {
    const today = new Date();
    const date = today.getDate();
    const month = today.getMonth(); // Months are zero-indexed
    if (month == 3 || month == 5 || month == 8 || month == 10) {
        return date / 30;
    }
    else if (month == 0 ||
        month == 2 ||
        month == 4 ||
        month == 6 ||
        month == 7 ||
        month == 9 ||
        month == 11) {
        // thirty-one days
        return date / 31;
    }
    else if (month == 1) {
        // twenty-eight days unless leap year
        return date / 28;
    }
    else {
        return null;
    }
};
exports.getMonthProgress = getMonthProgress;
const calculateTimeUntil = maintenanceTime => {
    // Define the maintenance time and time zone
    const timeZone = 'America/Los_Angeles'; // Pacific Time
    // Get the current time in the target time zone
    const now = MomentTZ.tz(timeZone);
    // Create today's maintenance window time
    let maintenanceToday = MomentTZ.tz(`${now.format('YYYY-MM-DD')} ${maintenanceTime}`, 'YYYY-MM-DD HH:mm', timeZone);
    // Check if the maintenance window today has already passed
    if (now.isAfter(maintenanceToday)) {
        // If passed, calculate tomorrow's maintenance window
        maintenanceToday = maintenanceToday.add(1, 'day');
    }
    return maintenanceToday.fromNow();
};
exports.calculateTimeUntil = calculateTimeUntil;
const getStartOfWeek = (year, month, day, week_starts_on) => {
    const offset = week_starts_on == 'monday' ? 1 : 0;
    return Moment(`${year}-${month}-${day}`)
        .day(offset)
        .format('YYYY-MM-DD');
};
exports.getStartOfWeek = getStartOfWeek;
// Given a date in format YYYY-MM-DD, returns the date 2 weeks ago, inclusive
const getPrevious2Weeks = (dateStr) => {
    return Moment(dateStr, 'YYYY-MM-DD')
        .subtract(2, 'weeks')
        .format('YYYY-MM-DD');
};
exports.getPrevious2Weeks = getPrevious2Weeks;
// Given a date in format YYYY-MM-DD, returns the date 2 weeks later, inclusive
const getNext2Weeks = (dateStr) => {
    return Moment(dateStr, 'YYYY-MM-DD')
        .add(2, 'weeks')
        .format('YYYY-MM-DD');
};
exports.getNext2Weeks = getNext2Weeks;
// Returns a date in format YYYY-MM-01
// This is the date used universally in the server/database
const getFormattedMonth = (year, month) => {
    if (!year && !month) {
        // Return current
        return Moment()
            .startOf('month')
            .format('YYYY-MM-DD');
    }
    else {
        return Moment(`${year}-${month}`, 'YYYY-M')
            .startOf('month')
            .format('YYYY-MM-DD');
    }
};
exports.getFormattedMonth = getFormattedMonth;
// Given a date in format YYYY-MM-DD, returns the start of the current month,
// i.e. 2019-01-14 returns 2019-01-01
const getThisMonthStart = (dateStr) => {
    if (!dateStr) {
        return Moment()
            .startOf('month')
            .format('YYYY-MM-DD');
    }
    else {
        return Moment(dateStr, 'YYYY-MM-DD')
            .startOf('month')
            .format('YYYY-MM-DD');
    }
};
exports.getThisMonthStart = getThisMonthStart;
const getThisMonthEnd = (dateStr) => {
    if (!dateStr) {
        return Moment()
            .endOf('month')
            .format('YYYY-MM-DD');
    }
    else {
        return Moment(dateStr, 'YYYY-MM-DD')
            .endOf('month')
            .format('YYYY-MM-DD');
    }
};
exports.getThisMonthEnd = getThisMonthEnd;
// Given a date in format YYYY-MM-01, returns the previous month,
// i.e. 2019-01-01 returns 2018-12-01
const getPreviousMonth = (dateStr) => {
    if (!dateStr) {
        return Moment()
            .subtract(1, 'month')
            .startOf('month')
            .format('YYYY-MM-DD');
    }
    else {
        return Moment(dateStr, 'YYYY-MM-DD')
            .subtract(1, 'month')
            .startOf('month')
            .format('YYYY-MM-DD');
    }
};
exports.getPreviousMonth = getPreviousMonth;
// Given a date in format YYYY-MM-01, returns the next month,
// i.e. 2018-12-01 returns 2019-01-01
const getNextMonth = (dateStr) => {
    if (!dateStr) {
        return Moment()
            .add(1, 'month')
            .startOf('month')
            .format('YYYY-MM-DD');
    }
    else {
        return Moment(dateStr, 'YYYY-MM-DD')
            .add(1, 'month')
            .startOf('month')
            .format('YYYY-MM-DD');
    }
};
exports.getNextMonth = getNextMonth;
const getTimeArray = (minDate, maxDate, granularity) => {
    const array = [];
    let currentDate = Moment(minDate, 'YYYY-MM-DD').startOf(granularity);
    let currentMax = Moment(maxDate, 'YYYY-MM-DD').endOf(granularity);
    while (currentDate.isSameOrBefore(currentMax)) {
        array.push({
            label: `${currentDate.format(granularity === 'month' ? 'MMM YYYY' : 'MMM D, YYYY')}`,
            key: parseInt(currentDate.format('YYYYMMDD')),
        });
        currentDate = currentDate.add(1, granularity);
    }
    return array;
};
exports.getTimeArray = getTimeArray;
