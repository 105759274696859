"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getImportConfigs = exports.saveImportConfig = exports.mergeAccounts = exports.mergeWithSynced = exports.getAssetStatus = exports.bulkDeleteAssets = exports.deleteAsset = exports.updateAsset = exports.getAssetSummary = exports.getAssets = exports.createAssets = exports.createAsset = exports.getAllSubtypes = exports.getTypes = void 0;
const common_1 = require("./common");
const getTypes = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)('assets/types', {});
});
exports.getTypes = getTypes;
const getAllSubtypes = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`assets/subtypes`, {});
});
exports.getAllSubtypes = getAllSubtypes;
const createAsset = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`assets`, opts);
});
exports.createAsset = createAsset;
const createAssets = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`assets/bulk`, opts);
});
exports.createAssets = createAssets;
const getAssets = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`assets`, {});
});
exports.getAssets = getAssets;
const getAssetSummary = (params) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`assets/summary`, params);
});
exports.getAssetSummary = getAssetSummary;
const updateAsset = (id, body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`assets/${id}`, body);
});
exports.updateAsset = updateAsset;
const deleteAsset = (id, body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`assets/${id}/delete`, body);
});
exports.deleteAsset = deleteAsset;
const bulkDeleteAssets = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`assets/bulk/delete`, body);
});
exports.bulkDeleteAssets = bulkDeleteAssets;
const getAssetStatus = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`assets/${id}/status`, {});
});
exports.getAssetStatus = getAssetStatus;
const mergeWithSynced = (id, plaid_account_id, opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`assets/${id}/merge_with_synced/${plaid_account_id}`, opts);
});
exports.mergeWithSynced = mergeWithSynced;
const mergeAccounts = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`assets/merge`, opts);
});
exports.mergeAccounts = mergeAccounts;
const saveImportConfig = (config) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`import_configs`, config);
});
exports.saveImportConfig = saveImportConfig;
const getImportConfigs = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`import_configs`, {});
});
exports.getImportConfigs = getImportConfigs;
