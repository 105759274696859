"use strict";
/**
 *  InactiveAccount.tsx
 *  Shows options for an inactive Plaid account
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_1 = require("react");
const plaid_1 = require("@actions/plaid");
const ModalProvider_1 = require("@providers/ModalProvider");
const UserProvider_1 = require("@/providers/UserProvider");
const TroubleshootAccount = ({ data, useModal, switchView, utils, setVisibility, setDisableOnClickOutside, }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _modal = (0, react_1.useContext)(ModalProvider_1.ModalContext);
    const [selectedAction, setSelectedAction] = (0, react_1.useState)(null);
    const [selectedScope, setSelectedScope] = (0, react_1.useState)(null);
    const [accountSiblings, setAccountSiblings] = (0, react_1.useState)([]);
    const [showConfirmation, setShowConfirmation] = (0, react_1.useState)(false);
    const attemptFetch = () => {
        const results = utils._process(plaid_1.fetchTransactions)({
            plaid_account_id: data.account.id,
        });
        if (!results.error) {
            utils._showToast({
                message: 'Successfully triggered a refresh for this account, data will auto-refresh when this disappears (more new data may still roll in after).',
                type: 'success',
                autoDismissTimeout: 9000,
            });
            setTimeout(() => {
                utils.triggerFetch();
            }, 9000);
            setVisibility(false);
        }
    };
    (0, react_1.useEffect)(() => {
        // Check if there are account siblings
        const fetchAccountSiblings = () => __awaiter(void 0, void 0, void 0, function* () {
            const results = yield (0, plaid_1.getAccountSiblings)(data.account.id);
            setAccountSiblings(results);
            if (results.length == 1) {
                // No siblings, treat as 'all'
                setSelectedScope('all');
            }
            else if (data.account.status == 'deleting') {
                setSelectedScope('one');
            }
        });
        fetchAccountSiblings();
    }, []);
    const EXPLANATIONS = {
        syncing: (React.createElement("span", null, "This means we are still working on fetching your initial set of data. This shouldn't take more than a few minutes. If you're still not seeing transactions, we recommend triggering a refresh for this account.")),
        closed: (React.createElement("span", null,
            "This account existed when you first connected to",
            ' ',
            data.account.institution_name,
            " but we can no longer find any information about it, meaning it was most likely closed.")),
        deleting: (React.createElement("span", null, "This means we are in the process of deleting the data associated with this account. This can take over 30 minutes if you have a lot of data! If it's been a while, feel free to trigger the deletion again.")),
        error: (React.createElement("span", null, "This means we ran into an unidentifiable problem with your account. Try triggering a refresh for the account and contact customer support if you need further assistance.")),
        inactive: (React.createElement("span", null, "This means that we are not actively fetching transactions and balance updates for this account.")),
        'not found': (React.createElement("span", null, "This means we were once syncing for this account and for some reason, Plaid stopped acknowledging the existence of this account. This might happen if you closed an account, for example. Contact customer support if you need help!")),
        'not supported': (React.createElement("span", null,
            "This account exists but importing transactions automatically is not currently supported. The reasons could include unsupported multi-factor authentication methods or an ongoing issue between Plaid and",
            ' ',
            data.account.institution_name,
            ".")),
        revoked: (React.createElement("span", null,
            "This means we no longer have access to sync transactions and balances. The only way to start syncing again is to create a new connection to",
            ' ',
            data.account.institution_name,
            ".")),
        relink: (React.createElement(React.Fragment, null,
            React.createElement("span", null,
                "This means we temporarily lost access to your bank data. This can happen from time to time depending on your institution and is usually easily fixable by updating the connection to your bank via Plaid. To start this process, click on the button labeled",
                ' ',
                React.createElement("b", null,
                    "Fix connection to ",
                    data.account.institution_name),
                " on the previous page."),
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Button, { size: "mini", className: "mt-05rem", onClick: () => {
                        switchView('LINKED_ACCOUNT_VIEW');
                    } }, "Go back to fix connection")))),
    };
    const ACTIONS = [
        /**
         * Remove one
         * - prompt user to remove from Plaid Link flow
         * - remove item from plaid_accounts, permanently stop syncing
         */
        {
            title: `${data.account.deactivated_at ||
                ['active', 'relink'].indexOf(data.account.status) == -1
                ? 'Remove account'
                : 'Stop syncing'} and delete all associated data`,
            key: 'remove',
        },
        /**
         * Convert one
         * - Prompt to remove from Plaid Update Mode
         * - Reassign all data
         * - Remove item from plaid_accounts so it doesn't sync
         */
        {
            title: `${data.account.deactivated_at ||
                ['active', 'relink'].indexOf(data.account.status) == -1
                ? 'Remove account'
                : 'Stop syncing'} and keep data in a new manually-managed account`,
            key: 'convert',
            shouldShow: () => {
                return (data.account.status !== 'deleting' &&
                    data.account.status !== 'syncing');
            },
        },
        /**
         * Merge account
         */
        {
            title: `Merge account data into another existing account`,
            key: 'merge',
            shouldShow: () => {
                return (data.account.status !== 'deleting' &&
                    data.account.status !== 'syncing');
            },
        },
        /**
         * Pause
         * - flip status to 'inactive
         */
        {
            title: 'Temporarily pause syncing this account',
            key: 'pause',
            shouldShow: () => {
                var _a;
                return (((_a = data.account.access_token) === null || _a === void 0 ? void 0 : _a.indexOf('development')) == -1 &&
                    data.account.status == 'active');
            },
        },
        /**
         * Reactivate
         * - if disconnected_at, prompt to Plaid Link
         * - else, flip status to 'active'
         */
        {
            title: 'Resume syncing this account',
            description: data.account.deactivated_at ? (React.createElement("p", null, "To resume automatic imports for this account, you will need to enable permissions directly with your bank via Plaid")) : null,
            key: 'reactivate',
            shouldShow: () => {
                return data.account.status == 'inactive';
            },
        },
        {
            title: 'Trigger a refresh for this account',
            description: (React.createElement("p", null, "Forces a refresh to get the latest transactions and balances")),
            key: 'refresh',
            shouldShow: () => {
                var _a;
                return (((_a = data.account.access_token) === null || _a === void 0 ? void 0 : _a.indexOf('development')) == -1 &&
                    ['active', 'error', 'syncing', 'not supported'].indexOf(data.account.status) > -1);
            },
        },
        {
            title: 'Get help from customer support',
            description: React.createElement("p", null, "Having troubles with this account? Let us help you!"),
            key: 'support',
        },
    ];
    // Setup
    (0, react_1.useEffect)(() => {
        // Always reset
        delete _modal.data.current.default_state;
        useModal({
            header: (React.createElement("div", null,
                data.account.status !== 'inactive' && (React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        switchView('LINKED_ACCOUNT_VIEW');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " Edit Account")),
                React.createElement("span", { className: "header-text-large" }, "Account Troubleshooting"))),
            leftButton: (React.createElement(semantic_ui_react_1.Button, { className: "no-border", color: 'orange', basic: true, onClick: () => {
                    setVisibility(false, true);
                } }, 'Cancel')),
            middleButton: React.createElement(React.Fragment, null),
            rightButton: (React.createElement(semantic_ui_react_1.Button, { disabled: !selectedAction ||
                    (['convert', 'remove'].indexOf(selectedAction) > -1 &&
                        !selectedScope), color: "orange", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    let results;
                    if (selectedAction == 'reactivate') {
                        if (data.account.deactivated_at) {
                            utils.relinkAccount(data.account.id);
                            setVisibility(false);
                        }
                        else {
                            // Flip a bit
                            results = yield utils._process(plaid_1.updateAccount)(data.account.id, {
                                status: 'active',
                            });
                            if (!results.error) {
                                setVisibility(false);
                                utils._showToast({
                                    message: 'Successfully re-activated account! We are fetching transactions for this account in the background.',
                                    type: 'success',
                                });
                            }
                        }
                    }
                    else if (selectedAction == 'merge') {
                        switchView('MERGE_ACCOUNTS');
                    }
                    else if (selectedAction == 'pause' &&
                        !data.account.deactivated_at) {
                        // Can only do this if it doesn't have a deactivated_at
                        results = yield utils._process(plaid_1.updateAccount)(data.account.id, {
                            status: 'inactive',
                        });
                        if (!results.error) {
                            setVisibility(false);
                            utils._showToast({
                                message: 'Syncing temporarily paused for this account!',
                                type: 'success',
                            });
                        }
                    }
                    else if (selectedAction == 'refresh') {
                        yield attemptFetch();
                    }
                    else if (selectedAction == 'support') {
                        utils.setShowHelpModalAccount(data.account);
                    }
                    else if (selectedAction == 'remove' &&
                        data.account.status == 'deleting') {
                        // Go straight to deleting
                        results = yield utils._process(plaid_1.handleRemovePlaidAccount)(data.account.id, {
                            account_to_remove: 'one',
                            items_action: 'remove',
                        });
                        if (!results.error) {
                            setVisibility(false);
                            utils._showToast({
                                message: 'Savings changed! It may take a few minutes to see results.',
                                type: 'success',
                            });
                        }
                    }
                    else {
                        setDisableOnClickOutside(true);
                        setShowConfirmation(true);
                    }
                }) }, selectedAction == 'reactivate' ? 'Start Reactivation' : 'Confirm')),
        });
    }, [selectedAction, selectedScope, accountSiblings]);
    return (React.createElement("div", { className: "g-networth-modal", id: "recurring-modal-detail-view" },
        React.createElement(React.Fragment, null,
            EXPLANATIONS.hasOwnProperty(data.account.status) && (React.createElement(semantic_ui_react_1.Message, { info: true },
                React.createElement("p", null,
                    data.account.display_name || data.account.name,
                    " is marked as",
                    ' ',
                    React.createElement("b", null, data.account.status),
                    ". ",
                    EXPLANATIONS[data.account.status]),
                data.account.deactivated_at && (React.createElement("p", null,
                    "This account was automatically deactivated on",
                    ' ',
                    Moment(data.account.deactivated_at).format(_user.getMonthDayYearFormat()),
                    ' ',
                    "due to a loss of permissions from your bank.")))),
            React.createElement("h2", { className: "mt-1rem" }, "I want to..."),
            ACTIONS.filter(action => !action.hasOwnProperty('shouldShow') || action.shouldShow()).map(action => (React.createElement("div", { key: action.key, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setSelectedAction(action.key);
                }), className: `link-recurring-row ${selectedAction == action.key ? 'selected' : ''}` },
                React.createElement("div", null,
                    React.createElement("span", null, action.title),
                    action.description && (React.createElement("div", { className: "mt-05rem" }, action.description))),
                selectedAction == action.key ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))))),
            ['convert', 'remove'].indexOf(selectedAction) > -1 &&
                (accountSiblings === null || accountSiblings === void 0 ? void 0 : accountSiblings.length) > 1 &&
                data.account.status !== 'deleting' && (React.createElement("div", { className: "mt-1rem" },
                React.createElement("h2", null, "and apply this change to..."),
                React.createElement("div", { key: 'one', onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setSelectedScope('one');
                    }), className: `link-recurring-row ${selectedScope == 'one' ? 'selected' : ''}` },
                    React.createElement("div", null,
                        React.createElement("span", null,
                            "this account (",
                            data.account.display_name || data.account.name,
                            ") only")),
                    selectedScope == 'one' ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))),
                React.createElement("div", { key: 'all', onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setSelectedScope('all');
                    }), className: `link-recurring-row ${selectedScope == 'all' ? 'selected' : ''}` },
                    React.createElement("div", null,
                        React.createElement("span", null,
                            "all accounts under this connection to",
                            ' ',
                            data.account.institution_name,
                            ":"),
                        React.createElement("span", null, accountSiblings === null || accountSiblings === void 0 ? void 0 : accountSiblings.map(o => (React.createElement("li", { key: o.name },
                            o.display_name || o.name,
                            " (",
                            o.mask,
                            ") - ",
                            o.status))))),
                    selectedScope == 'all' ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" })))))),
        React.createElement(semantic_ui_react_1.Modal, { open: showConfirmation, closeOnEscape: false, closeOnDimmerClick: false, onClose: () => {
                setDisableOnClickOutside(false);
                setShowConfirmation(false);
            }, size: "small" },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Please confirm your changes"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("div", { className: "content" },
                    React.createElement("p", null, "The following operations will be performed:"),
                    React.createElement("ul", null,
                        React.createElement("li", null,
                            "All data (transactions, recurring items, balance history, rules)",
                            ' ',
                            selectedScope == 'all' ? (React.createElement(React.Fragment, null,
                                React.createElement("b", null,
                                    "from ",
                                    accountSiblings.length,
                                    " account",
                                    accountSiblings.length > 1 ? 's' : ''),
                                ' ',
                                "associated with ",
                                React.createElement("b", null, data.account.institution_name))) : (React.createElement(React.Fragment, null,
                                "associated with",
                                ' ',
                                React.createElement("b", null, data.account.display_name || data.account.name))),
                            selectedAction == 'remove'
                                ? ' will be permanently deleted.'
                                : ` will be moved to ${selectedScope == 'all' && accountSiblings.length > 1
                                    ? 'new manually-managed accounts.'
                                    : 'a new manually-managed account.'}. The original synced account will then be deleted.`),
                        selectedScope == 'one' && !data.account.deactivated_at && (React.createElement("li", null,
                            "Transactions and account balances will no longer be synced for",
                            ' ',
                            React.createElement("b", null, data.account.display_name || data.account.name),
                            " only.*")),
                        selectedScope == 'all' && (React.createElement("li", null,
                            "Transactions and account balances will no longer be synced for",
                            ' ',
                            React.createElement("b", null, data.account.institution_name),
                            "."))),
                    selectedScope == 'one' && !data.account.deactivated_at && (React.createElement(semantic_ui_react_1.Message, { info: true }, "* To ensure data sync is completely stopped, we recommend revoking access to the particular account via Plaid. To do so, click on any other active account within the same institution and select \"Add/remove accounts from this bank\".")),
                    selectedScope == 'all' && (React.createElement(semantic_ui_react_1.Message, { info: true }, "If you want to resume syncing for this institution in the future, you'll need to create a new connection via \"Auto-import from my bank\".")),
                    React.createElement(semantic_ui_react_1.Message, { negative: true },
                        React.createElement("b", null, "This is a nuclear option and cannot be reversed.")))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        setDisableOnClickOutside(false);
                        setShowConfirmation(false);
                    }, negative: true }, "Cancel"),
                React.createElement(semantic_ui_react_1.Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        let results;
                        // selectedAction possibilities: remove-one, remove-all, convert-one, convert-all
                        results = yield utils._process(plaid_1.handleRemovePlaidAccount)(data.account.id, {
                            account_to_remove: selectedScope,
                            items_action: selectedAction,
                        });
                        if (!results.error) {
                            setVisibility(false);
                            utils._showToast({
                                message: 'Savings changed! It may take a few minutes to see results.',
                                type: 'success',
                            });
                        }
                    }) }, "Confirm Changes")))));
};
exports.default = TroubleshootAccount;
