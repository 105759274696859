"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_1 = require("react");
const EditableAsset_1 = require("@components/elements/EditableAsset");
const assets_1 = require("@actions/assets");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const DeleteAssetModal = ({ data, useModal, utils, setVisibility, switchView, }) => {
    const [keepItems, setKeepItems] = (0, react_1.useState)(false);
    const [keepHistory, setKeepHistory] = (0, react_1.useState)(false);
    const [step, setStep] = (0, react_1.useState)(3);
    const [view, setView] = (0, react_1.useState)(null);
    const [newAsset, setNewAsset] = (0, react_1.useState)('cash');
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    (0, react_1.useEffect)(() => {
        useModal({
            header: (React.createElement("div", null,
                React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        switchView('LINKED_ACCOUNT_VIEW');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " Account Details"),
                React.createElement("span", { className: "header-text-large" }, "Remove Bank Account"))),
            leftButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "no-border", basic: true, onClick: () => {
                    setVisibility(false, true);
                } }, 'Cancel')),
        });
        return () => {
            useModal({
                header: null,
                leftButton: null,
            });
        };
    }, []);
    (0, react_1.useEffect)(() => {
        let saveText = 'Save changes';
        if (step < 3) {
            saveText = 'Next';
        }
        useModal({
            middleButton: step > 1 ? (React.createElement(React.Fragment, null)) : (
            // <Button
            //   color="orange"
            //   className="no-border"
            //   basic
            //   onClick={() => {
            //     setStep(step - 1)
            //   }}
            // >
            //   {'Back'}
            // </Button>
            React.createElement(React.Fragment, null)),
            rightButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", disabled: (keepItems === null && step == 1) ||
                    (keepHistory == null && step == 2), loading: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    if (step == 3) {
                        setIsLoading(true);
                        // Save changes!
                        let results = yield utils._process(assets_1.deleteAsset)(data.account.id, {
                            keep_items: keepItems,
                            keep_history: keepHistory,
                            new_asset_id: newAsset,
                        });
                        if (!results.error) {
                            // If we're not keeping history, then reload
                            if (!keepHistory && utils.remove) {
                                utils.remove([{ asset_id: data.account.id }]);
                            }
                            setVisibility(false);
                            _assets.removeAsset(data.account.id);
                            utils._showToast({
                                message: 'Successfully started deletion process for asset. It may take a minute for changes to reflect here.',
                                type: 'success',
                                autoDismissTimeout: 5000,
                            });
                            setIsLoading(false);
                        }
                    }
                    else {
                        setStep(step + 1);
                    }
                }) }, saveText)),
        });
        return () => {
            useModal({
                middleButton: null,
                rightButton: null,
            });
        };
    }, [data, step, keepHistory, keepItems, newAsset, isLoading]);
    (0, react_1.useEffect)(() => {
        if (step == 1) {
            setView(React.createElement(React.Fragment, null,
                React.createElement("p", null,
                    "There may be transactions and recurring items associated with",
                    ' ',
                    data.account.name,
                    "."),
                React.createElement("p", null, "Would you like to keep these transactions and recurring items but disassociate them from this bank account, or would you like to remove everything entirely?"),
                React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setKeepItems(true);
                    }), className: `link-recurring-row ${keepItems == true ? 'selected' : ''}` },
                    React.createElement("div", null,
                        React.createElement("span", null,
                            "I want to keep my",
                            ' ',
                            data.account.source == 'manual' ? 'associated' : 'imported',
                            ' ',
                            "transactions and recurring items."),
                        keepItems && (React.createElement(React.Fragment, null,
                            React.createElement("span", { className: "display--block mt-1rem" }, "Reassign my transactions to.."),
                            React.createElement(EditableAsset_1.default, { className: "mt-05rem", onSave: value => {
                                    setNewAsset(value);
                                }, excludeId: data.account.id })))),
                    keepItems ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))),
                React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setKeepItems(false);
                    }), className: `link-recurring-row ${keepItems == false ? 'selected' : ''}` },
                    React.createElement("div", null,
                        React.createElement("span", null, "I want to remove all associated transactions and recurring expenses.")),
                    keepItems == false ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" })))));
        }
        else if (step == 2) {
            setView(React.createElement(React.Fragment, null,
                React.createElement("p", null,
                    "There may be balance history associated with ",
                    data.account.name,
                    " for calculating your net worth over time."),
                React.createElement("p", null, "Would you like to keep the balance history on this account, or remove it completely?"),
                React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setKeepHistory(true);
                    }), className: `link-recurring-row ${keepHistory == true ? 'selected' : ''}` },
                    React.createElement("div", null,
                        React.createElement("span", null,
                            "I want to keep ",
                            data.account.name,
                            "'s balance history.")),
                    keepHistory ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))),
                React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setKeepHistory(false);
                    }), className: `link-recurring-row ${keepHistory == false ? 'selected' : ''}` },
                    React.createElement("div", null,
                        React.createElement("span", null,
                            "I want to remove ",
                            data.account.name,
                            "'s balance history.")),
                    keepHistory == false ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" })))));
        }
        else if (step == 3) {
            setView(React.createElement(React.Fragment, null,
                React.createElement("p", null, "Please confirm the following operations:"),
                React.createElement("div", { className: `not-clickable link-recurring-row selected` },
                    React.createElement("div", null,
                        React.createElement("span", null,
                            "Remove ",
                            React.createElement("b", null, data.account.name),
                            " from my account")),
                    React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })),
                React.createElement("div", { className: `not-clickable link-recurring-row selected` },
                    React.createElement("span", null, keepItems
                        ? 'Keep associated transactions and recurring items'
                        : 'Remove associated transactions and recurring items'),
                    React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })),
                React.createElement("div", { className: `not-clickable link-recurring-row selected` },
                    React.createElement("span", null, keepHistory
                        ? 'Keep account balance history'
                        : 'Remove account balance history'),
                    React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })),
                React.createElement("p", { className: "mt-1rem" }, "Note: This operation may take a few minutes depending on how much data exists for your account.")));
        }
    }, [data, step, keepItems, keepHistory]);
    return view;
};
exports.default = DeleteAssetModal;
