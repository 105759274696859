"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const CopyCommand = ({ value, tooltipText, _showToast, style = {} }) => {
    return (document.queryCommandSupported('copy') && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { style: style, name: "copy outline", className: "clickable", onClick: () => {
                var tempInput = document.createElement('input');
                tempInput.style.position = 'absolute';
                tempInput.style.left = '-1000px';
                tempInput.style.top = '-1000px';
                tempInput.value = value;
                document.body.appendChild(tempInput);
                tempInput.select();
                document.execCommand('copy');
                document.body.removeChild(tempInput);
                _showToast({
                    type: 'success',
                    message: `Copied to clipboard!`,
                });
            } }) }, tooltipText)));
};
exports.default = CopyCommand;
