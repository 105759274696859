"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const qs = require("query-string");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const _ = require("lodash");
const AssetsProvider_1 = require("@/providers/AssetsProvider");
const format_1 = require("@/helpers/format");
const EditablePrice_1 = require("../elements/EditablePrice");
const react_router_dom_1 = require("react-router-dom");
const assets_1 = require("@/actions/assets");
const Finish = ({ _process, _showToast, insertResults, currentConfig, goBack, startOver, }) => {
    var _a, _b, _c, _d, _e;
    const history = (0, react_router_dom_1.useHistory)();
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const [assets, setAssets] = (0, react_1.useState)([]);
    const [plaidAccounts, setPlaidAccounts] = (0, react_1.useState)([]);
    const [cash, setCash] = (0, react_1.useState)(null);
    const [balanceUpdates, setBalanceUpdates] = (0, react_1.useState)({});
    (0, react_1.useEffect)(() => {
        var _a, _b, _c;
        if (insertResults && !insertResults.background_job) {
            const insertedAssets = _.groupBy((_a = insertResults === null || insertResults === void 0 ? void 0 : insertResults.transactions) === null || _a === void 0 ? void 0 : _a.filter(o => !!o.asset_id), 'asset_id');
            const insertedPlaid = _.groupBy((_b = insertResults === null || insertResults === void 0 ? void 0 : insertResults.transactions) === null || _b === void 0 ? void 0 : _b.filter(o => !!o.plaid_account_id), 'plaid_account_id');
            const insertedCash = (_c = insertResults === null || insertResults === void 0 ? void 0 : insertResults.transactions) === null || _c === void 0 ? void 0 : _c.filter(o => !o.asset_id && !o.plaid_account_id);
            setAssets(Object.keys(insertedAssets).map(assetId => {
                // Update the balance
                const matchedBalanceUpdate = insertResults === null || insertResults === void 0 ? void 0 : insertResults.asset_update.find(o => o.id == assetId);
                _assets.updateAsset(assetId, {
                    balance: matchedBalanceUpdate === null || matchedBalanceUpdate === void 0 ? void 0 : matchedBalanceUpdate.balance,
                    currency: matchedBalanceUpdate === null || matchedBalanceUpdate === void 0 ? void 0 : matchedBalanceUpdate.currency,
                    balance_as_of: Moment().format(),
                });
                return {
                    asset_id: assetId,
                    num_transactions: insertedAssets[assetId].length,
                    balance_update: matchedBalanceUpdate,
                    last_day: insertedAssets[assetId].length
                        ? insertedAssets[assetId].sort((a, b) => {
                            return Moment(b.date).diff(Moment(a.date));
                        })[0].date
                        : null,
                };
            }));
            setPlaidAccounts(Object.keys(insertedPlaid).map(plaidAccountId => {
                return {
                    plaid_account_id: plaidAccountId,
                    num_transactions: insertedPlaid[plaidAccountId].length,
                    last_day: insertedPlaid[plaidAccountId].length
                        ? insertedPlaid[plaidAccountId].sort((a, b) => {
                            return Moment(b.date).diff(Moment(a.date));
                        })[0].date
                        : null,
                };
            }));
            setCash({
                num_transactions: insertedCash.length,
                last_day: insertedCash.length > 0
                    ? insertedCash.sort((a, b) => {
                        return Moment(b.date).diff(Moment(a.date));
                    })[0].date
                    : null,
            });
        }
    }, [insertResults]);
    const shareFeedback = () => {
        return (React.createElement(React.Fragment, null,
            React.createElement("p", null, "Would you mind sharing some feedback on this CSV Import tool? It would help us improve it!"),
            React.createElement("p", null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        history.push({
                            search: qs.stringify(Object.assign(Object.assign({}, qs.parse(history.location.search)), { show_feedback: 'general-feedback' })),
                        });
                    }, size: "mini" }, "Share feedback")),
            React.createElement("p", null, "Want to have automatically synced transactions as well? Check out this guide on how to prevent duplicates!"),
            React.createElement("p", null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        window.open('https://support.lunchmoney.app/migrating-from-mint#ive-imported-my-historical-transactions.-how-do-i-get-my-accounts-to-sync-now');
                    }, icon: true, labelPosition: "right", size: "mini" },
                    "View guide",
                    React.createElement(semantic_ui_react_1.Icon, { name: "external alternate" })))));
    };
    return (React.createElement(React.Fragment, null,
        !insertResults && React.createElement(semantic_ui_react_1.Loader, { inline: true, active: true }),
        (insertResults === null || insertResults === void 0 ? void 0 : insertResults.background_job) && (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Message, { info: true },
                React.createElement(semantic_ui_react_1.Message.Header, null,
                    React.createElement("p", null, "You have quite a lot of transactions to import! We are working behind the scenes to insert your transactions and we will email you when it's finished in just a few moments."),
                    shareFeedback())),
            React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                    startOver();
                } }, "Upload another CSV"))),
        insertResults && !(insertResults === null || insertResults === void 0 ? void 0 : insertResults.background_job) && (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Message, { info: true },
                React.createElement(semantic_ui_react_1.Message.Header, null, ((_a = insertResults === null || insertResults === void 0 ? void 0 : insertResults.transactions) === null || _a === void 0 ? void 0 : _a.length) > 0 ? (React.createElement(React.Fragment, null,
                    React.createElement("p", null,
                        "Successfully imported ", (_b = insertResults === null || insertResults === void 0 ? void 0 : insertResults.transactions) === null || _b === void 0 ? void 0 :
                        _b.length,
                        ' ',
                        "transaction",
                        ((_c = insertResults === null || insertResults === void 0 ? void 0 : insertResults.transactions) === null || _c === void 0 ? void 0 : _c.length) > 1 ? 's' : '',
                        "!"),
                    shareFeedback())) : (React.createElement(React.Fragment, null,
                    React.createElement("p", null, "We were unable to upload any transactions."),
                    (currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.id) && (React.createElement("p", null,
                        React.createElement(semantic_ui_react_1.Icon, { name: "exclamation triangle" }),
                        ' ',
                        React.createElement("b", null, "You used a saved configuration to upload this CSV file."),
                        ' ',
                        "Using a saved configuration that does not match up with the columns in your uploaded CSV file will result in no transactions being loaded. Are you uploading to an account that you previously updated a different CSV format for? You can disable using a saved configuration in Step 1 of the CSV importing tool.")))))),
            ((_d = insertResults === null || insertResults === void 0 ? void 0 : insertResults.transactions) === null || _d === void 0 ? void 0 : _d.length) > 0 && (React.createElement(semantic_ui_react_1.Table, { fixed: true, unstackable: true, selectable: true, celled: true, className: "p-transactions-table" },
                React.createElement(semantic_ui_react_1.Table.Header, null,
                    React.createElement(semantic_ui_react_1.Table.Row, null,
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null,
                            React.createElement("div", null, "Account")),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-200" },
                            React.createElement("div", null, "Transactions Imported")),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-200" },
                            React.createElement("div", null,
                                "Updated Balance",
                                ' ',
                                React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "This reflects the updated balance of your manually-managed accounts, determined from the imported transactions. Synced account balances are automatically updated from your bank."))),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null,
                            React.createElement("div", null,
                                "Update Balance",
                                ' ',
                                React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "If our calculated balance is not correct, use this to update the asset's balance directly. Synced account balances cannot be modified."))),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null))),
                React.createElement(semantic_ui_react_1.Table.Body, null, assets === null || assets === void 0 ? void 0 :
                    assets.map(asset => {
                        var _a, _b, _c, _d, _e, _f;
                        return (React.createElement(semantic_ui_react_1.Table.Row, { className: "no-hover-tr padded-row", key: `asset-row-${asset.asset_id}` },
                            React.createElement(semantic_ui_react_1.Table.Cell, null, _assets.getAssetName(asset.asset_id)),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, asset.num_transactions),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, (0, format_1.toPrice)((_a = asset.balance_update) === null || _a === void 0 ? void 0 : _a.balance, (_b = asset.balance_update) === null || _b === void 0 ? void 0 : _b.currency)),
                            React.createElement(semantic_ui_react_1.Table.Cell, null,
                                React.createElement("div", { className: "flex--center" },
                                    React.createElement(EditablePrice_1.default, { amount: (_c = balanceUpdates[asset.asset_id]) === null || _c === void 0 ? void 0 : _c.balance, currency: (_d = balanceUpdates[asset.asset_id]) === null || _d === void 0 ? void 0 : _d.currency, identifier: `asset-balance-update-${asset.id}`, state: 'Editing', location: 'modal', autoFocus: false, saveOnChange: true, onSave: (amount, currency) => {
                                            setBalanceUpdates(Object.assign(Object.assign({}, balanceUpdates), { [asset.asset_id]: {
                                                    balance: amount,
                                                    currency: currency,
                                                } }));
                                        } }),
                                    React.createElement(semantic_ui_react_1.Button, { disabled: !((_e = balanceUpdates[asset.asset_id]) === null || _e === void 0 ? void 0 : _e.balance) ||
                                            !((_f = balanceUpdates[asset.asset_id]) === null || _f === void 0 ? void 0 : _f.currency), size: "mini", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                            const results = yield _process(assets_1.updateAsset)(asset.asset_id, balanceUpdates[asset.asset_id]);
                                            if (!results.error) {
                                                _showToast({
                                                    message: `Balance for ${_assets.getAssetName(asset.asset_id)} successfully updated to ${(0, format_1.toPrice)(balanceUpdates[asset.asset_id].balance, balanceUpdates[asset.asset_id].currency)}.`,
                                                    type: 'success',
                                                });
                                                _assets.updateAsset(asset.asset_id, Object.assign(Object.assign({}, balanceUpdates[asset.asset_id]), { balance_as_of: Moment().format() }));
                                                setBalanceUpdates(Object.assign(Object.assign({}, balanceUpdates), { [asset.asset_id]: Object.assign(Object.assign({}, balanceUpdates[asset.asset_id]), { balance: null }) }));
                                            }
                                        }) }, "Update"))),
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "right-align" },
                                React.createElement(semantic_ui_react_1.Button, { size: "mini", onClick: () => {
                                        history.push({
                                            pathname: `/transactions/${Moment(asset.last_day, 'YYYY-MM-DD').format('YYYY/MM')}?asset=${asset.asset_id}&match=all&time=month`,
                                        });
                                    }, icon: true, labelPosition: "right" },
                                    "View Transactions ",
                                    React.createElement(semantic_ui_react_1.Icon, { name: "arrow right" })))));
                    }), plaidAccounts === null || plaidAccounts === void 0 ? void 0 :
                    plaidAccounts.map(plaidAccount => {
                        return (React.createElement(semantic_ui_react_1.Table.Row, { className: "no-hover-tr padded-row", key: `plaid-row-${plaidAccount.plaid_account_id}` },
                            React.createElement(semantic_ui_react_1.Table.Cell, null, _assets.getPlaidAccountName(plaidAccount.plaid_account_id)),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, plaidAccount.num_transactions),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, "-"),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, "-"),
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "right-align" },
                                React.createElement(semantic_ui_react_1.Button, { size: "mini", onClick: () => {
                                        history.push({
                                            pathname: `/transactions/${Moment(plaidAccount.last_day, 'YYYY-MM-DD').format('YYYY/MM')}?account=${plaidAccount.plaid_account_id}&match=all&time=month`,
                                        });
                                    }, icon: true, labelPosition: "right" },
                                    "View Transactions ",
                                    React.createElement(semantic_ui_react_1.Icon, { name: "arrow right" })))));
                    }),
                    (cash === null || cash === void 0 ? void 0 : cash.num_transactions) > 0 && (React.createElement(semantic_ui_react_1.Table.Row, { className: "no-hover-tr padded-row" },
                        React.createElement(semantic_ui_react_1.Table.Cell, null, "Default Cash Account"),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, cash === null || cash === void 0 ? void 0 : cash.num_transactions),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, "-"),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, "-"),
                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "right-align" },
                            React.createElement(semantic_ui_react_1.Button, { size: "mini", onClick: () => {
                                    history.push({
                                        pathname: `/transactions/${Moment(cash === null || cash === void 0 ? void 0 : cash.last_day, 'YYYY-MM-DD').format('YYYY/MM')}?asset=cash&match=all&time=month`,
                                    });
                                }, icon: true, labelPosition: "right" },
                                "View Transactions ",
                                React.createElement(semantic_ui_react_1.Icon, { name: "arrow right" })))))))),
            React.createElement("div", { className: "display--flex" },
                ((_e = insertResults === null || insertResults === void 0 ? void 0 : insertResults.transactions) === null || _e === void 0 ? void 0 : _e.length) == 0 && (React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        goBack();
                    } }, "Back")),
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        startOver();
                    } }, "Upload another CSV"))))));
};
exports.default = Finish;
