"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.unsubscribeEmailRules = exports.getRule = exports.getRules = exports.bulkDelete = exports.deleteAllSuggested = exports.deleteRule = exports.updateRulePriority = exports.updateRule = exports.approveRules = exports.approveRule = exports.bulkApply = exports.createRule = void 0;
const common_1 = require("./common");
const createRule = (obj) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`rules`, obj);
});
exports.createRule = createRule;
const bulkApply = (obj) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`rules/apply`, obj);
});
exports.bulkApply = bulkApply;
const approveRule = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`rules/${id}/approve`, {});
});
exports.approveRule = approveRule;
const approveRules = (criteria_ids) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`rules/approve`, { criteria_ids });
});
exports.approveRules = approveRules;
const updateRule = (id, obj) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`rules/${id}`, obj);
});
exports.updateRule = updateRule;
const updateRulePriority = (id, priority) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`rules/${id}/priority`, { priority });
});
exports.updateRulePriority = updateRulePriority;
const deleteRule = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.del)(`rules/${id}`);
});
exports.deleteRule = deleteRule;
const deleteAllSuggested = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`rules/delete_suggested`, {});
});
exports.deleteAllSuggested = deleteAllSuggested;
const bulkDelete = (ids) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`rules/bulk_delete`, { criteria_ids: ids });
});
exports.bulkDelete = bulkDelete;
const getRules = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`rules`, opts);
});
exports.getRules = getRules;
const getRule = (criteria_id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`rules/${criteria_id}`, {});
});
exports.getRule = getRule;
const unsubscribeEmailRules = (criteria_ids) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`rules/unsubscribe_email`, { criteria_ids });
});
exports.unsubscribeEmailRules = unsubscribeEmailRules;
