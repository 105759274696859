"use strict";
/**
 *  Autocategorization.tsx
 *  Container for the Autocategorization page
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const _ = require("lodash");
// Semantic UI
const semantic_ui_react_1 = require("semantic-ui-react");
const react_router_dom_1 = require("react-router-dom");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const format_1 = require("@helpers/format");
const plaid_1 = require("@/actions/plaid");
const EditableCategory_1 = require("@/components/elements/EditableCategory");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const Loader_1 = require("@/components/global/Loader");
const AssetsProvider_1 = require("@/providers/AssetsProvider");
const DryRunRules_1 = require("@/components/Rules/DryRunRules");
const UserProvider_1 = require("@/providers/UserProvider");
const Autocategorization = ({ _showToast, _process }) => {
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const history = (0, react_router_dom_1.useHistory)();
    const [categories, setCategories] = (0, react_1.useState)([]);
    const [userCategories, setUserCategories] = (0, react_1.useState)({});
    const [missingCategories, setMissingCategories] = (0, react_1.useState)([]);
    const [selectedPrimary, setSelectedPrimary] = (0, react_1.useState)('BANK_FEES');
    const [showIntro, setShowIntro] = (0, react_1.useState)(!_user.settings['viewed_autocategorization_intro']);
    const [showRules, setShowRules] = (0, react_1.useState)(null);
    const [rows, setRows] = (0, react_1.useState)([]);
    const [menuRows, setMenuRows] = (0, react_1.useState)([]);
    const [showDryRunRules, setShowDryRunRules] = (0, react_1.useState)(false);
    const [uncategorizedTxIds, setUncategorizedTxIds] = (0, react_1.useState)([]);
    const [buttonIsLoading, setButtonIsLoading] = (0, react_1.useState)(null);
    const init = () => __awaiter(void 0, void 0, void 0, function* () {
        yield (0, plaid_1.populatePlaidCategory)();
        const results = yield (0, plaid_1.getPlaidCategories)();
        setCategories(_.groupBy(results, 'primary'));
        const userResults = yield (0, plaid_1.getUserPlaidCategories)();
        const _results = [];
        results.forEach(cat => {
            if (cat.tx) {
                if (
                // We only want to know if the user has assigned a category to this primary or detailed category
                !!userResults.find(o => o.primary == cat.primary &&
                    (o.detailed == cat.detailed || o.detailed == null))) {
                    _results.push(...cat.tx);
                }
            }
        });
        setUncategorizedTxIds(_results);
        const _userCategories = {};
        userResults === null || userResults === void 0 ? void 0 : userResults.forEach(user_category => {
            _userCategories[(user_category === null || user_category === void 0 ? void 0 : user_category.detailed) || (user_category === null || user_category === void 0 ? void 0 : user_category.primary)] = {
                category_id: user_category === null || user_category === void 0 ? void 0 : user_category.category_id,
                count: user_category === null || user_category === void 0 ? void 0 : user_category.count,
            };
        });
        setUserCategories(_userCategories);
    });
    const createAndSet = (_a) => __awaiter(void 0, [_a], void 0, function* ({ categories, runRules = false, transactionIds = [], }) {
        setButtonIsLoading(categories.length > 1
            ? true
            : categories[0].detailed || categories[0].primary);
        const results = yield _process(plaid_1.createAndSetPlaidCategories)(categories);
        if (results) {
            // Add category to provider
            _categories.add(_.uniqBy(results.data, 'id'));
            const _userCategories = {};
            results.data.forEach(newCategory => {
                _userCategories[newCategory.detailed || newCategory.primary] = {
                    category_id: newCategory.id,
                };
            });
            setUserCategories(Object.assign(Object.assign({}, userCategories), _userCategories));
            setButtonIsLoading(null);
            _showToast({
                type: 'success',
                message: `Created and assigned ${results.data[0].name}`,
            });
            if (runRules) {
                setUncategorizedTxIds(transactionIds);
                if (categories.length > 1) {
                    setShowRules(categories[0].detailed || categories[0].primary);
                }
            }
        }
    });
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = 'Autocategorization - Lunch Money';
        init();
    }, []);
    (0, react_1.useEffect)(() => {
        const missing = [];
        const allDetailed = _.flatten(Object.values(categories)).map(o => {
            return {
                primary: o.primary,
                detailed: o.detailed,
                detailed_display_name: o.detailed_display_name,
                transaction_ids: o.tx,
                count: o.count,
            };
        });
        allDetailed.forEach(detailedCat => {
            if (detailedCat.count) {
                if (!userCategories.hasOwnProperty(detailedCat.detailed)) {
                    missing.push(detailedCat);
                }
            }
        });
        setMissingCategories(missing);
        // Set missing categories
        const _rows = [];
        const _menuRows = [];
        Object.keys(categories)
            .sort((a, b) => a.localeCompare(b))
            .forEach(primary => {
            var _a, _b, _c;
            const primaryName = (0, format_1.capitalize)(primary)
                .replace(/_+/g, ' ')
                .trim();
            let isFilled = true;
            let hasSomeFilled = false;
            if ((_a = userCategories[primary]) === null || _a === void 0 ? void 0 : _a.category_id) {
                // continue
                hasSomeFilled = true;
            }
            else {
                categories[primary].forEach(o => {
                    if (!userCategories[o['detailed']]) {
                        isFilled = false;
                    }
                    else {
                        hasSomeFilled = true;
                    }
                });
            }
            const primaryTx = [];
            const primaryCount = categories[primary].reduce((acc, cur) => {
                if ((cur === null || cur === void 0 ? void 0 : cur.count) > 0) {
                    primaryTx.push(...cur === null || cur === void 0 ? void 0 : cur.tx);
                    return acc + (cur === null || cur === void 0 ? void 0 : cur.count);
                }
                return acc;
            }, 0);
            _menuRows.push(React.createElement(semantic_ui_react_1.Table.Row, { className: `padded-row ${selectedPrimary == primary ? 'highlighted' : ''}`, key: `menu-${primary}` },
                React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable", onClick: () => {
                        setSelectedPrimary(primary);
                    } },
                    React.createElement("div", { className: "flex--align-center" },
                        React.createElement(semantic_ui_react_1.Popup, { disabled: primaryCount == 0, trigger: React.createElement(semantic_ui_react_1.Icon, { style: {
                                    position: 'relative',
                                    top: '-2px',
                                }, name: primaryCount > 0
                                    ? 'exclamation circle'
                                    : isFilled
                                        ? 'check circle'
                                        : !hasSomeFilled
                                            ? 'circle outline'
                                            : 'check circle', className: `mr-05rem ${primaryCount == 0 && hasSomeFilled ? 'color--grey' : ''}`, color: primaryCount > 0
                                    ? 'yellow'
                                    : isFilled
                                        ? 'green'
                                        : !hasSomeFilled
                                            ? 'grey'
                                            : null }), inverted: true, size: "small" },
                            "You currently have ",
                            primaryCount,
                            " uncategorized transactions within this pre-assigned category group. Assign a category and re-run autocategorization to fix this."),
                        React.createElement("div", { style: {
                                position: 'relative',
                                top: primaryCount > 0 ? '1px' : '2px',
                            } }, primaryName)))));
            if (selectedPrimary == primary) {
                // Primary header
                _rows.push(React.createElement(semantic_ui_react_1.Table.Row, { className: "padded-row highlighted", id: categories['primary_display_name'], key: `table-${primary}` },
                    React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: 2 },
                        (0, format_1.capitalize)(primary)
                            .replace(/_+/g, ' ')
                            .trim(),
                        ' '),
                    React.createElement(semantic_ui_react_1.Table.Cell, null,
                        React.createElement("div", { className: "display--flex flex--center" },
                            !((_b = userCategories[primary]) === null || _b === void 0 ? void 0 : _b.category_id) && (React.createElement(React.Fragment, null,
                                React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Button, { loading: buttonIsLoading == primary, disabled: !!buttonIsLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                            yield createAndSet({
                                                categories: [
                                                    {
                                                        primary,
                                                        detailed: null,
                                                        category_name: (0, format_1.capitalize)(primary)
                                                            .replace(/_+/g, ' ')
                                                            .trim(),
                                                        treat_as_income: primary == 'INCOME',
                                                    },
                                                ],
                                                runRules: !!primaryCount,
                                                transactionIds: primaryTx,
                                            });
                                        }) }, "Create"), inverted: true, size: "small" },
                                    "Create a new category called",
                                    ' ',
                                    React.createElement("b", null, (0, format_1.capitalize)(primary)
                                        .replace(/_+/g, ' ')
                                        .trim()),
                                    ' ',
                                    "and assign it to this category."),
                                React.createElement("div", { className: "ml-05rem mr-05rem" }, "or"))),
                            React.createElement(EditableCategory_1.default, { containerClassName: "flex-grow", identifier: `bulk-edit-category`, firstValue: ((_c = userCategories[primary]) === null || _c === void 0 ? void 0 : _c.category_id) || null, placeholder: 'Search, add new, or leave uncategorized', state: 'Editing', location: 'modal', allowAdditions: true, showUncategorized: true, allowSelectingGroups: false, onSave: (value) => __awaiter(void 0, void 0, void 0, function* () {
                                    const results = yield _process(plaid_1.setPlaidCategory)({
                                        primary,
                                        detailed: null,
                                        category_id: value,
                                    });
                                    if (primaryCount) {
                                        setUncategorizedTxIds(primaryTx);
                                        setShowRules(categories['primary_display_name']);
                                    }
                                    setUserCategories(Object.assign(Object.assign({}, userCategories), { [primary]: { category_id: value } }));
                                    if (results) {
                                        _showToast({
                                            type: 'success',
                                            message: `Assigned ${value ? _categories.getName(value) : 'uncategorized'} to ${categories['primary_display_name']}`,
                                        });
                                    }
                                }) })))));
                // Detailed rows
                categories[primary].forEach(category => {
                    var _a, _b, _c;
                    _rows.push(React.createElement(semantic_ui_react_1.Table.Row, { className: "padded-row", key: `table-${category['detailed']}` },
                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                            category['detailed_display_name'],
                            ' ',
                            (category === null || category === void 0 ? void 0 : category.count) ? (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "exclamation circle", fitted: true }), inverted: true, size: "small" },
                                "You currently have ", category === null || category === void 0 ? void 0 :
                                category.count,
                                " uncategorized transactions with this pre-assigned category. Assign a category and click 'Re-run categorization' at the top to fix this.")) : ('')),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, category['description']),
                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                            React.createElement("div", { className: "display--flex flex--center" },
                                !((_a = userCategories[category['detailed']]) === null || _a === void 0 ? void 0 : _a.category_id) && (React.createElement(React.Fragment, null,
                                    React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Button, { loading: buttonIsLoading == category['detailed'], disabled: !!buttonIsLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                yield createAndSet({
                                                    categories: [
                                                        {
                                                            primary: category['primary'],
                                                            detailed: category['detailed'],
                                                            category_name: (0, format_1.capitalize)(category['detailed']
                                                                .replace(category['primary'], '')
                                                                .replace(/_+/g, ' ')
                                                                .trim()),
                                                            treat_as_income: category['primary'] == 'INCOME',
                                                        },
                                                    ],
                                                    runRules: !!(category === null || category === void 0 ? void 0 : category.count),
                                                    transactionIds: category === null || category === void 0 ? void 0 : category.tx,
                                                });
                                            }) }, "Create"), inverted: true, size: "small" },
                                        "Create a new category called",
                                        ' ',
                                        React.createElement("b", null, category['detailed_display_name']),
                                        " and assign it to this category."),
                                    React.createElement("div", { className: "ml-05rem mr-05rem" }, "or"))),
                                React.createElement(EditableCategory_1.default, { containerClassName: "flex-grow", identifier: `bulk-edit-category`, firstValue: ((_b = userCategories[category['detailed']]) === null || _b === void 0 ? void 0 : _b.category_id) ||
                                        null, placeholder: `${((_c = userCategories[primary]) === null || _c === void 0 ? void 0 : _c.category_id)
                                        ? `Leave blank to inherit from ${categories['primary_display_name']}.`
                                        : `Leave blank for uncategorized.`}`, state: 'Editing', location: 'modal', showUncategorized: true, allowSelectingGroups: false, onSave: (value) => __awaiter(void 0, void 0, void 0, function* () {
                                        const results = yield _process(plaid_1.setPlaidCategory)({
                                            primary: category['primary'],
                                            detailed: category['detailed'],
                                            category_id: value,
                                        });
                                        if (category === null || category === void 0 ? void 0 : category.count) {
                                            setUncategorizedTxIds(category === null || category === void 0 ? void 0 : category.tx);
                                            setShowRules(category['detailed_display_name']);
                                        }
                                        setUserCategories(Object.assign(Object.assign({}, userCategories), { [category['detailed']]: { category_id: value } }));
                                        if (results) {
                                            _showToast({
                                                type: 'success',
                                                message: `Assigned ${value
                                                    ? _categories.getName(value)
                                                    : 'uncategorized'} to ${category['detailed_display_name']}`,
                                            });
                                        }
                                    }) })))));
                });
            }
        });
        setMenuRows(_menuRows);
        setRows(_rows);
    }, [categories, userCategories, selectedPrimary, buttonIsLoading]);
    return (React.createElement(semantic_ui_react_1.Container, { className: "g-auto-categories" },
        React.createElement(ContainerHeader_1.default, { title: "Auto-Categorization Settings" }),
        React.createElement("div", { className: "width-100 flex--space-between-flex-end" },
            React.createElement(semantic_ui_react_1.Button, { icon: true, labelPosition: "left", onClick: () => {
                    history.push('/categories');
                } },
                React.createElement(semantic_ui_react_1.Icon, { name: "arrow left" }),
                "Back to Categories"),
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Button, { loading: buttonIsLoading, disabled: buttonIsLoading || missingCategories.length == 0, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            const missingCats = missingCategories.map(cat => {
                                return {
                                    primary: cat.primary,
                                    detailed: cat.detailed,
                                    category_name: cat.detailed_display_name,
                                    treat_as_income: cat.primary == 'INCOME',
                                    transfer_category: cat.primary == 'TRANSFER_IN' ||
                                        cat.detailed == 'TRANSFER_OUT',
                                };
                            });
                            yield createAndSet({
                                categories: missingCats,
                                runRules: true,
                                transactionIds: _.flatten(missingCategories.map(o => o.transaction_ids)),
                            });
                        }) },
                        "Create missing categories (",
                        missingCategories.length,
                        ")"), hoverable: true, inverted: true, size: "small" },
                    React.createElement("p", null,
                        "Click to create the ",
                        missingCategories.length,
                        " categories corresponding to uncategorized transactions, denoted by",
                        ' ',
                        React.createElement(semantic_ui_react_1.Icon, { name: "exclamation circle", fitted: true }),
                        ". Auto-categorization will run automatically after this operation.")),
                React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Button, { icon: true, disabled: uncategorizedTxIds.length == 0, labelPosition: "right", onClick: () => {
                            setShowDryRunRules(true);
                        } },
                        React.createElement(semantic_ui_react_1.Icon, { name: "sync alternate" }),
                        uncategorizedTxIds.length == 0
                            ? 'No categorizations to re-run'
                            : 'Re-run categorization'), hoverable: true, inverted: true, size: "small" },
                    React.createElement("p", null,
                        "Click to run these auto-categorization rules against any uncategorized imported transactions. There are currently",
                        ' ',
                        uncategorizedTxIds.length == 0
                            ? 'none'
                            : uncategorizedTxIds.length,
                        "."),
                    React.createElement("p", null, "Note that these uncategorized transactions will also run against your other rules. Auto-categorization is run after rules, only if no rule has set a category.")),
                React.createElement(semantic_ui_react_1.Button, { icon: true, onClick: () => {
                        setShowIntro(true);
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "info circle" })))),
        React.createElement("div", { className: "p-rules-container" },
            React.createElement("div", { className: "rules-container-left" }, menuRows && (React.createElement(semantic_ui_react_1.Table, { fixed: true, selectable: true, celled: true, unstackable: true, className: "p-rules-table" },
                React.createElement(semantic_ui_react_1.Table.Body, null, menuRows)))),
            React.createElement("div", { className: "rules-container-right" },
                (selectedPrimary == 'TRANSFER_IN' ||
                    selectedPrimary == 'TRANSFER_OUT') &&
                    !_categories.all.find(o => o.exclude_from_budget &&
                        o.exclude_from_totals &&
                        o.name.indexOf('Transfer') > -1) && (React.createElement(semantic_ui_react_1.Message, { info: true },
                    React.createElement("p", null,
                        "For ",
                        React.createElement("b", null, "Transfer-type categories"),
                        ", we highly recommend using a default category called ",
                        React.createElement("b", null, "Payment/Transfers"),
                        " with the category properties 'Exclude from budget' and 'Exclude from totals'."),
                    React.createElement("p", null,
                        "You do not currently have this default category. Click below to create this default category and assign to",
                        ' ',
                        React.createElement("b", null, "Transfer In"),
                        " below."),
                    React.createElement("div", { className: "mt-1rem" },
                        React.createElement(semantic_ui_react_1.Button, { loading: buttonIsLoading, disabled: buttonIsLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                yield createAndSet({
                                    categories: [
                                        {
                                            primary: 'TRANSFER_IN',
                                            detailed: null,
                                            transfer_category: true,
                                        },
                                        {
                                            primary: 'TRANSFER_OUT',
                                            detailed: null,
                                            transfer_category: true,
                                        },
                                    ],
                                });
                            }) }, "Create and Assign 'Payment, Transfer' category")))),
                React.createElement(semantic_ui_react_1.Table, { unstackable: true, sortable: true, fixed: true, selectable: true, celled: true, className: "p-rules-table" },
                    React.createElement(semantic_ui_react_1.Table.Header, { className: "sticky" },
                        React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-name" },
                                React.createElement("div", null, "Name")),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null,
                                React.createElement("div", null, "Description")),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null,
                                React.createElement("div", null, "Assign to Category")))),
                    React.createElement(semantic_ui_react_1.Table.Body, null,
                        rows.length == 0 && React.createElement(Loader_1.default, { colSpan: 3 }),
                        rows)))),
        React.createElement(semantic_ui_react_1.Modal, { open: showIntro, dimmer: 'inverted', size: 'tiny' },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Welcome to Auto-categorization!"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("div", { className: "content" },
                    React.createElement("p", null, "When transactions are imported, they are pre-assigned one of the following categories listed on this page. Ensure all your transactions arrive categorized by mapping your own categories to these predefined ones."),
                    React.createElement(semantic_ui_react_1.Message, { info: true },
                        React.createElement("b", null, "Note: Auto-categorizations are run before rules."),
                        " This means that any applicable rules that update the category will overwrite your auto-categorizations."),
                    React.createElement("p", null,
                        React.createElement("b", null, "Auto-categorization only works for transactions that are imported automatically"),
                        ' ',
                        "from your bank through Plaid. This includes the following accounts (and any future accounts you link to Lunch Money):"),
                    React.createElement("ul", null, _assets.plaidAccounts
                        .filter(o => o.status == 'active' || o.status == 'relink')
                        .map((account, index) => {
                        return (React.createElement("li", { key: `account-${index}` }, account.display_name ||
                            account.institution_name + ' ' + account.name));
                    })))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { icon: true, labelPosition: "right", color: "green", onClick: () => {
                        setShowIntro(false);
                        _user.updateSetting('viewed_autocategorization_intro', true);
                    } },
                    "Got it ",
                    React.createElement(semantic_ui_react_1.Icon, { name: "check" })))),
        React.createElement(semantic_ui_react_1.Modal, { open: !!showRules, dimmer: 'inverted', size: 'tiny' },
            React.createElement(semantic_ui_react_1.Modal.Header, null,
                uncategorizedTxIds.length == 1
                    ? '1 uncategorized transaction'
                    : `${uncategorizedTxIds.length} uncategorized transactions`,
                ' ',
                "can be fixed"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("div", { className: "content" },
                    React.createElement("p", null,
                        "There",
                        ' ',
                        uncategorizedTxIds.length == 1
                            ? 'is 1 uncategorized transaction'
                            : `are ${uncategorizedTxIds.length} uncategorized transactions`,
                        ' ',
                        "associated with ",
                        showRules,
                        ". Would you like to run all rules, including this auto-categorization, against these transactions?"),
                    React.createElement(semantic_ui_react_1.Message, { info: true }, "Auto-categorization is run after rules, only if no rule has set a category."))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { icon: true, basic: true, labelPosition: "right", color: "orange", onClick: () => {
                        setShowRules(null);
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "x" }),
                    "Not now"),
                React.createElement(semantic_ui_react_1.Button, { icon: true, labelPosition: "right", color: "green", onClick: () => {
                        setShowRules(null);
                        setShowDryRunRules(true);
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "arrow right" }),
                    "Run rules"))),
        React.createElement(DryRunRules_1.default, { show: showDryRunRules, closeModal: () => __awaiter(void 0, void 0, void 0, function* () {
                yield init();
                setShowDryRunRules(null);
            }), transactionIds: uncategorizedTxIds, criteriaIds: [], _showToast: _showToast, _process: _process })));
};
exports.default = Autocategorization;
