"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const Moment = require("moment");
const EditableString_1 = require("@components/elements/EditableString");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_1 = require("@helpers/format");
const transaction_file_1 = require("@actions/transaction_file");
const UserProvider_1 = require("@/providers/UserProvider");
const Files = ({ amendTransactions, files, transaction_id, setDisableOnClickOutside, }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const dropRef = (0, react_1.useRef)(null);
    const [isDragged, setIsDragged] = (0, react_1.useState)(false);
    const [editing, setEditing] = (0, react_1.useState)(null);
    const [allFiles, setAllFiles] = (0, react_1.useState)(files || []);
    const [confirmDelete, setConfirmDelete] = (0, react_1.useState)(null);
    const [stringValue, setStringValue] = (0, react_1.useState)('');
    const [openImage, setOpenImage] = (0, react_1.useState)(null);
    const [imageUrl, setImageUrl] = (0, react_1.useState)(null);
    const [buttonLoading, setButtonLoading] = (0, react_1.useState)(false);
    const [processingDrop, setProcessingDrop] = (0, react_1.useState)(false);
    const [sizeErrors, setSizeErrors] = (0, react_1.useState)([]);
    const update = (file_id, updateObj) => __awaiter(void 0, void 0, void 0, function* () {
        setButtonLoading(true);
        setAllFiles(allFiles.map(file => {
            if (file.id == file_id) {
                return Object.assign(Object.assign({}, file), updateObj);
            }
            else {
                return file;
            }
        }));
        yield (0, transaction_file_1.updateFile)(file_id, updateObj);
        setEditing(null);
        setStringValue('');
        setButtonLoading(false);
    });
    const remove = (file_id) => __awaiter(void 0, void 0, void 0, function* () {
        setButtonLoading(true);
        setAllFiles(allFiles.filter(file => file.id !== file_id));
        yield (0, transaction_file_1.deleteFile)(file_id);
        setConfirmDelete(null);
        setButtonLoading(false);
    });
    (0, react_1.useEffect)(() => {
        const check = (e, invokeOnContain, handler) => {
            // Do nothing if  clicking ref's element or descendent elements
            if (!dropRef.current) {
                return;
            }
            if (invokeOnContain && !dropRef.current.contains(e.target)) {
                return;
            }
            else if (!invokeOnContain &&
                dropRef.current.contains(e.target) &&
                e.target.classList.value !== 'drag-over show') {
                return;
            }
            handler(e);
        };
        if (dropRef) {
            dropRef.current.addEventListener('dragenter', e => {
                check(e, true, handleDragIn);
            });
            dropRef.current.addEventListener('dragleave', e => {
                check(e, false, handleDragOut);
            });
            dropRef.current.addEventListener('dragover', e => {
                check(e, true, handleDrag);
            });
            dropRef.current.addEventListener('drop', e => {
                check(e, true, handleDrop);
            });
            return () => {
                var _a, _b, _c, _d;
                (_a = dropRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('dragenter', handleDragIn);
                (_b = dropRef.current) === null || _b === void 0 ? void 0 : _b.removeEventListener('dragleave', handleDragOut);
                (_c = dropRef.current) === null || _c === void 0 ? void 0 : _c.removeEventListener('dragover', handleDrag);
                (_d = dropRef.current) === null || _d === void 0 ? void 0 : _d.removeEventListener('drop', handleDrop);
            };
        }
    }, [dropRef]);
    const handleDrag = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragIn = e => {
        e.preventDefault();
        e.stopPropagation();
        if (
        // e.dataTransfer.items &&
        // e.dataTransfer.items.length > 0 &&
        !processingDrop) {
            setIsDragged(true);
        }
    };
    const handleDragOut = e => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragged(false);
    };
    const handleDrop = e => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files &&
            e.dataTransfer.files.length > 0 &&
            !processingDrop) {
            handleFiles([...e.dataTransfer.files]);
        }
    };
    const handleFiles = (files) => __awaiter(void 0, void 0, void 0, function* () {
        setIsDragged(false);
        setProcessingDrop(true);
        const errors = [];
        const data = new FormData();
        data.append('return_all', 'true');
        files.forEach(file => {
            // File size limit
            if (file.size < 20000000) {
                data.append('file', file);
            }
            else {
                errors.push({
                    name: file.name,
                    size: (0, format_1.toReadableSize)(file.size / 1000),
                });
            }
        });
        if (errors.length < files.length) {
            // Return all of a user's files so we don't have to append it
            const results = yield (0, transaction_file_1.uploadFile)(transaction_id, data);
            setAllFiles(results.data);
        }
        if (errors.length > 0) {
            setDisableOnClickOutside(true);
            setSizeErrors(errors);
        }
        setProcessingDrop(false);
    });
    (0, react_1.useEffect)(() => {
        // When allFiles updates, make sure to update in the Transactions container as well
        amendTransactions &&
            amendTransactions({
                update: {
                    id: transaction_id,
                    files: allFiles,
                },
            });
    }, [allFiles]);
    return (React.createElement("div", { className: `transaction-details position--relative`, ref: dropRef },
        React.createElement("div", { className: `drag-over ${isDragged ? 'show' : ''}` },
            React.createElement(semantic_ui_react_1.Icon, { name: "upload", size: "large" }),
            React.createElement(semantic_ui_react_1.Header, { as: "h2" }, "Release here to upload file(s)")),
        React.createElement("div", { className: "transaction-detail" },
            React.createElement("h3", { className: "flex--space-between" }, "Attachments"), allFiles === null || allFiles === void 0 ? void 0 :
            allFiles.map((file, index) => {
                return (React.createElement("div", { className: "file-box", key: `file-${index}-${file.id}` }, editing == file.id ? (React.createElement(semantic_ui_react_1.Form.Group, { className: "width-100" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null, "File Name"),
                        React.createElement(EditableString_1.default, { identifier: `transaction-payee`, firstValue: stringValue, location: 'modal', state: 'Editing', shouldSaveOnBlur: false, shouldSaveOnEnter: false, shouldSaveOnChange: true, autoFocus: true, shouldSelectOnFocus: true, onSave: value => {
                                setStringValue(value);
                            }, onEnter: () => {
                                update(file.id, { name: stringValue });
                            } })),
                    React.createElement("div", { className: "display--flex mt-05rem" },
                        React.createElement(semantic_ui_react_1.Button, { loading: buttonLoading, disabled: buttonLoading, size: "mini", className: "mr-05rem", onClick: () => {
                                update(file.id, { name: stringValue });
                            } }, "Save"),
                        React.createElement(semantic_ui_react_1.Button, { loading: buttonLoading, disabled: buttonLoading, size: "mini", onClick: () => {
                                setEditing(null);
                                setStringValue('');
                            } }, "Cancel")))) : (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "display--flex" },
                        React.createElement("div", { className: "file-box-icon" },
                            React.createElement(semantic_ui_react_1.Icon, { name: file.type.indexOf('image') > -1
                                    ? 'file image outline'
                                    : file.type.indexOf('pdf') > -1
                                        ? 'file pdf outline'
                                        : file.type.indexOf('zip') > -1
                                            ? 'file archive outline'
                                            : file.type.indexOf('video') > -1
                                                ? 'file video outline'
                                                : 'file outline', fitted: true, size: "large" })),
                        React.createElement("div", { className: "file-box-content" },
                            React.createElement("span", { className: "file-box-content-filename", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    if (file.type.indexOf('image') > -1) {
                                        setDisableOnClickOutside(true);
                                        setOpenImage(file.name);
                                    }
                                    // Get file URL
                                    const results = yield (0, transaction_file_1.getFileUrl)(file.id);
                                    if (file.type.indexOf('image') > -1) {
                                        setImageUrl(results);
                                    }
                                    else {
                                        open(results);
                                    }
                                }) }, file.name),
                            React.createElement("span", { className: "file-box-content-meta" },
                                Moment(file.created_at).format(_user.getFullTimeFormat()),
                                ' ',
                                "\u00B7 ",
                                (0, format_1.toReadableSize)(file.size)),
                            file.transaction_name && file.transaction_date && (React.createElement("span", { className: "file-box-content-meta" },
                                "Inherited from ",
                                file.transaction_name,
                                " (",
                                Moment(file.transaction_date).format(_user.getMonthDayYearFormat()),
                                ")")))),
                    React.createElement("div", { className: "options" },
                        React.createElement(semantic_ui_react_1.Dropdown, { icon: "ellipsis vertical", item: true, direction: "right" },
                            React.createElement(semantic_ui_react_1.Dropdown.Menu, null,
                                React.createElement(semantic_ui_react_1.Dropdown.Item, { text: "Edit name", onClick: () => {
                                        setStringValue(file.name);
                                        setEditing(file.id);
                                    } }),
                                React.createElement(semantic_ui_react_1.Dropdown.Item, { text: "Download", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        setEditing(null);
                                        setStringValue('');
                                        // Get file URL
                                        const results = yield (0, transaction_file_1.downloadFile)(file.id);
                                        open(results);
                                    }) }),
                                React.createElement(semantic_ui_react_1.Dropdown.Divider, null),
                                React.createElement(semantic_ui_react_1.Dropdown.Item, { text: "Delete", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        setDisableOnClickOutside(true);
                                        setConfirmDelete(file.id);
                                        // Reset in case something is editing
                                        setEditing(null);
                                        setStringValue('');
                                    }) }))))))));
            }),
            React.createElement("div", { id: "file-drop", className: `file-drop-area` }, processingDrop ? (React.createElement("div", { className: "align--center" },
                React.createElement(semantic_ui_react_1.Icon, { loading: true, name: "spinner" }),
                React.createElement("p", null, "Uploading & processing.."))) : (React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Icon, { name: "upload" }),
                React.createElement("p", null, "Drop file(s) here to upload"),
                React.createElement("div", null,
                    React.createElement("label", { htmlFor: "files", className: "file-btn" }, "Select files"),
                    React.createElement("input", { type: "file", id: "files", multiple: true, onChange: e => {
                            handleFiles([...e.target.files]);
                        } })))))),
        React.createElement(semantic_ui_react_1.Modal, { size: "tiny", open: confirmDelete !== null },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Confirm deletion"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("p", null, "Are you sure you want to permanently delete this file?")),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { loading: buttonLoading, disabled: buttonLoading, color: "red", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield remove(confirmDelete);
                    }) }, "Yes, delete"),
                React.createElement(semantic_ui_react_1.Button, { loading: buttonLoading, disabled: buttonLoading, onClick: () => {
                        setConfirmDelete(null);
                    } }, "No, cancel"))),
        React.createElement(semantic_ui_react_1.Modal, { size: "tiny", open: sizeErrors.length > 0, onClose: () => {
                setSizeErrors([]);
            } },
            React.createElement(semantic_ui_react_1.Modal.Header, null,
                "Your file",
                sizeErrors.length > 1 ? 's are ' : 'is ',
                "too big!"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("p", null, "The following files were not uploaded because the file size limit is 20MB:"),
                React.createElement(semantic_ui_react_1.List, { bulleted: true }, sizeErrors.map((error, index) => {
                    return (React.createElement(semantic_ui_react_1.List.Item, { key: `error-${index}` },
                        error.name,
                        " (",
                        error.size,
                        ")"));
                }))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        setSizeErrors([]);
                    } }, "Okay"))),
        React.createElement(semantic_ui_react_1.Modal, { open: !!openImage },
            React.createElement(semantic_ui_react_1.Modal.Header, null, openImage),
            React.createElement(semantic_ui_react_1.Modal.Content, { image: true },
                React.createElement("div", { className: "modal-image-container" },
                    React.createElement(semantic_ui_react_1.Image, { src: imageUrl, fluid: true, centered: true }),
                    React.createElement("div", { className: "modal-image-loader" },
                        React.createElement(semantic_ui_react_1.Icon, { loading: true, name: "spinner" })))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        open(imageUrl);
                    }, positive: true, icon: true, labelPosition: "left" },
                    React.createElement(semantic_ui_react_1.Icon, { name: "external" }),
                    "Open in new window"),
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        setImageUrl(null);
                        setOpenImage(null);
                    } }, "Close")))));
};
exports.default = Files;
