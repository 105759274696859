"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
const EmptyState = ({ colSpan, hasFilters, addNewTransaction }) => {
    let history = (0, react_router_dom_1.useHistory)();
    return (React.createElement(semantic_ui_react_1.Table.Row, { className: "g-empty-row no-hover-tr" },
        React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: colSpan, className: "center-align" },
            React.createElement("div", { className: "empty-mascot" }),
            hasFilters ? (React.createElement("p", null, "You have no transactions matching this filter.")) : (React.createElement(React.Fragment, null,
                React.createElement("p", null, "You have no transactions yet!"),
                React.createElement("p", null, "There are 4 ways to add transactions:"),
                React.createElement("p", null,
                    React.createElement(semantic_ui_react_1.Button, { size: "mini", onClick: addNewTransaction }, "Manually add a transaction")),
                React.createElement("p", null,
                    React.createElement(semantic_ui_react_1.Button, { size: "mini", className: "ml-1rem", onClick: () => {
                            history.push('/transactions/import');
                        } }, "Import CSV file")),
                React.createElement("p", null,
                    React.createElement(semantic_ui_react_1.Button, { size: "mini", className: "ml-1rem", onClick: () => {
                            history.push('/accounts');
                        } }, "Link your bank account")),
                React.createElement("p", null,
                    React.createElement(semantic_ui_react_1.Button, { size: "mini", className: "ml-1rem", onClick: () => {
                            history.push('/developers');
                        } }, "Use the developer API")))))));
};
exports.default = EmptyState;
