"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toGroupFormat = exports.buildFilters = exports.buildQuery = exports.buildUrl = void 0;
const format_1 = require("@helpers/format");
const _ = require("lodash");
const Moment = require("moment");
/**
 * Build transactions URL query
 * coming from query tool report
 */
const buildUrl = query => {
    const final = { match: 'all', time: 'custom' };
    if (query.category_ids && query.category_ids.length > 0) {
        if (query.category_ids.indexOf(-1) >= 0) {
            final['uncategorized'] = 'true';
        }
        final['category'] = query.category_ids;
    }
    if (query.category_exclude && query.category_exclude.length > 0) {
        final['category_exclude'] = query.category_exclude;
    }
    if (query.exclude_income == 'true') {
        final['is_income'] = false;
    }
    if (query.exclude_unreviewed) {
        final['status_exclude'] = 'unreviewed';
    }
    if (query.notes && query.notes_match_type) {
        final[`notes_${query.notes_match_type}`] = query.notes;
    }
    if (query.payee && query.payee_match_type) {
        final[`payee_${query.payee_match_type}`] = query.payee;
    }
    if (query.tags && query.tags.length > 0) {
        if (query.tags.indexOf('-1') >= 0) {
            final['untagged'] = 'true';
        }
        final['tag'] = query.tags;
    }
    if (query.type && query.type.length > 0) {
        final['type'] = query.type;
    }
    if (query.type_exclude && query.type_exclude.length > 0) {
        final['type_exclude'] = query.type_exclude;
    }
    if (!!query.plaid_account_id) {
        final['account'] = query.plaid_account_id;
    }
    if (!!query.attachment) {
        final['attachment'] = query.attachment;
    }
    if (query.asset_id == '-1') {
        final['asset'] = 'cash';
    }
    else if (!!query.asset_id) {
        final['asset'] = query.asset_id;
    }
    return final;
};
exports.buildUrl = buildUrl;
/**
 * Building query to send to server
 */
const buildQuery = (query, currentPeriod = null) => {
    const opts = {};
    if (query.ids) {
        opts['ids'] = _.flatten([query.ids]);
    }
    if (query.category) {
        opts['category_ids'] = _.flatten([query.category]);
    }
    if (query.category_exclude) {
        opts['category_ids_exclude'] = _.flatten([query.category_exclude]);
    }
    if (query.hasOwnProperty('uncategorized')) {
        if (query.uncategorized == true || query.uncategorized === 'true') {
            opts['category_ids'] = [...(opts['category_ids'] || []), -1];
        }
        else {
            opts['category_ids_exclude'] = [
                ...(opts['category_ids_exclude'] || []),
                -1,
            ];
        }
    }
    if (query.tag) {
        opts['tags'] = _.flatten([query.tag]);
    }
    if (query.tag_exclude) {
        opts['tags_exclude'] = _.flatten([query.tag_exclude]);
    }
    if (query.hasOwnProperty('untagged')) {
        if (query.untagged == true || query.untagged === 'true') {
            opts['tags'] = [...(opts['tags'] || []), -1];
        }
        else {
            opts['tags_exclude'] = [...(opts['tags_exclude'] || []), -1];
        }
    }
    if (query.account) {
        opts['plaid_account_ids'] = _.flatten([query.account]);
    }
    if (query.account_exclude) {
        opts['plaid_account_ids_exclude'] = _.flatten([query.account_exclude]);
    }
    if (query.asset) {
        opts['asset_ids'] = _.flatten([query.asset]).map(asset => {
            if (asset === 'cash') {
                return -1;
            }
            else {
                return asset;
            }
        });
    }
    if (query.asset_exclude) {
        opts['asset_ids_exclude'] = _.flatten([query.asset_exclude]).map(asset => {
            if (asset === 'cash') {
                return -1;
            }
            else {
                return asset;
            }
        });
    }
    if (query.hasOwnProperty('attachment')) {
        opts['attachment'] = query.attachment;
    }
    if (query.recurring) {
        opts['recurring_ids'] = _.flatten([query.recurring])
            .map(recurring => {
            if (recurring === 'any') {
                opts['is_recurring'] = true;
                return null;
            }
            else if (recurring === 'suggested') {
                opts['is_recurring_suggested'] = true;
                return null;
            }
            else {
                return recurring;
            }
        })
            .filter(o => !!o);
    }
    if (query.recurring_exclude) {
        // Array
        opts['recurring_ids_exclude'] = _.flatten([query.recurring_exclude])
            .map(recurring => {
            if (recurring === 'any') {
                opts['is_recurring'] = false;
                return null;
            }
            else if (recurring === 'suggested') {
                opts['is_recurring_suggested'] = false;
                return null;
            }
            else {
                return recurring;
            }
        })
            .filter(o => !!o);
    }
    if (query.status) {
        if (query.status == 'pending') {
            opts['exclude_pending'] = false;
        }
        else {
            _.flatten([query.status]).forEach(o => {
                opts[`is_${o.replace(' ', '_')}`] = true;
            });
        }
    }
    if (query.status_exclude) {
        if (query.status == 'pending') {
            opts['exclude_pending'] = true;
        }
        else {
            _.flatten([query.status_exclude]).forEach(o => {
                opts[`is_${o.replace(' ', '_')}`] = false;
            });
        }
    }
    if (query.type) {
        _.flatten([query.type]).forEach(o => {
            opts[`is_${o.replace(/ /g, '_')}`] = true;
        });
    }
    if (query.type_exclude) {
        _.flatten([query.type_exclude]).forEach(o => {
            opts[`is_${o.replace(/ /g, '_')}`] = false;
        });
    }
    if (query.hasOwnProperty('payee_contain')) {
        opts['payee_contain'] = _.flatten([query.payee_contain]);
    }
    if (query.hasOwnProperty('payee_exact')) {
        opts['payee_exact'] = _.flatten([query.payee_exact]);
    }
    if (query.hasOwnProperty('payee_start_with')) {
        opts['payee_start_with'] = _.flatten([query.payee_start_with]);
    }
    if (query.hasOwnProperty('notes_contain')) {
        opts['notes_contain'] = _.flatten([query.notes_contain]);
    }
    if (query.hasOwnProperty('notes_exact')) {
        opts['notes_exact'] = _.flatten([query.notes_exact]);
    }
    if (query.hasOwnProperty('notes_start_with')) {
        opts['notes_start_with'] = _.flatten([query.notes_start_with]);
    }
    // Amount - Expense
    if (query.hasOwnProperty('expense_greater_than')) {
        opts['expense_greater_than'] = _.flatten([query.expense_greater_than]);
    }
    if (query.hasOwnProperty('expense_greater_equal')) {
        opts['expense_greater_equal'] = _.flatten([query.expense_greater_equal]);
    }
    if (query.hasOwnProperty('expense_less_than')) {
        opts['expense_less_than'] = _.flatten([query.expense_less_than]);
    }
    if (query.hasOwnProperty('expense_less_equal')) {
        opts['expense_less_equal'] = _.flatten([query.expense_less_equal]);
    }
    if (query.hasOwnProperty('expense_exactly')) {
        opts['expense_exactly'] = _.flatten([query.expense_exactly]);
    }
    if (query.hasOwnProperty('expense_between')) {
        opts['expense_between'] = _.flatten([query.expense_between]);
    }
    // Amount - Income
    if (query.hasOwnProperty('income_greater_than')) {
        opts['income_greater_than'] = _.flatten([query.income_greater_than]);
    }
    if (query.hasOwnProperty('income_greater_equal')) {
        opts['income_greater_equal'] = _.flatten([query.income_greater_equal]);
    }
    if (query.hasOwnProperty('income_less_than')) {
        opts['income_less_than'] = _.flatten([query.income_less_than]);
    }
    if (query.hasOwnProperty('income_less_equal')) {
        opts['income_less_equal'] = _.flatten([query.income_less_equal]);
    }
    if (query.hasOwnProperty('income_exactly')) {
        opts['income_exactly'] = _.flatten([query.income_exactly]);
    }
    if (query.hasOwnProperty('income_between')) {
        opts['income_between'] = _.flatten([query.income_between]);
    }
    if (query.hasOwnProperty('is_income')) {
        opts['is_income'] = query.is_income;
    }
    if (query.hasOwnProperty('exclude_from_totals')) {
        opts['exclude_from_totals'] = query.exclude_from_totals;
    }
    if (query.hasOwnProperty('exclude_from_budget')) {
        opts['exclude_from_budget'] = query.exclude_from_budget;
    }
    if (query.hasOwnProperty('search')) {
        opts['search'] = _.flatten([query.search]);
    }
    if (query.time == 'year') {
        opts['start_date'] = Moment()
            .startOf('year')
            .format('YYYY-MM-DD');
        opts['end_date'] = Moment()
            .endOf('year')
            .format('YYYY-MM-DD');
    }
    else if (query.time == 'last-year') {
        opts['start_date'] = Moment()
            .subtract(1, 'year')
            .startOf('year')
            .format('YYYY-MM-DD');
        opts['end_date'] = Moment()
            .subtract(1, 'year')
            .endOf('year')
            .format('YYYY-MM-DD');
    }
    else if (query.time == 'last-month') {
        opts['start_date'] = Moment(currentPeriod)
            .subtract(1, 'month')
            .startOf('month')
            .format('YYYY-MM-DD');
        opts['end_date'] = Moment(currentPeriod)
            .subtract(1, 'month')
            .endOf('month')
            .format('YYYY-MM-DD');
    }
    else if (query.time == 'all') {
        delete opts['start_date'];
        delete opts['end_date'];
    }
    else if (query.time == 'custom') {
        opts['start_date'] = query.start_date;
        opts['end_date'] = query.end_date;
    }
    else {
        opts['start_date'] = currentPeriod;
        opts['end_date'] = Moment(currentPeriod)
            .endOf('month')
            .format('YYYY-MM-DD');
    }
    opts['match'] = query.match;
    return opts;
};
exports.buildQuery = buildQuery;
/**
 * Filter to run monthly transactions through
 */
const buildFilters = (query, opts) => {
    const filterFns = [];
    /** IDs */
    const idFilter = query.ids;
    if (idFilter) {
        _.flatten([idFilter]).forEach(o => {
            filterFns.push(tx => {
                return tx.id === parseInt(o);
            });
        });
    }
    if (query.search) {
        const searchFilter = query === null || query === void 0 ? void 0 : query.search.toString().toLowerCase();
        // Update transactionsToFilter
        filterFns.push(tx => {
            return ((tx.category_name &&
                tx.category_name.toLowerCase().indexOf(searchFilter) >= 0) ||
                (tx.original_name &&
                    tx.original_name.toLowerCase().indexOf(searchFilter) >= 0) ||
                (tx.display_name &&
                    tx.display_name.toLowerCase().indexOf(searchFilter) >= 0) ||
                (tx.display_notes &&
                    tx.display_notes.toLowerCase().indexOf(searchFilter) >= 0) ||
                (tx.amount &&
                    (0, format_1.toPrice)(tx.amount, tx.currency).indexOf(searchFilter) >= 0) ||
                (tx.amount &&
                    !isNaN((0, format_1.parseNumber)(searchFilter)) &&
                    ((0, format_1.toPrice)(tx.amount, tx.currency) ==
                        (0, format_1.toPrice)((0, format_1.parseNumber)(searchFilter), tx.currency) ||
                        Math.abs((0, format_1.parseNumber)(tx.amount)) ==
                            Math.abs((0, format_1.parseNumber)(searchFilter)))) ||
                (tx.currency && tx.currency.toString().indexOf(searchFilter) >= 0) ||
                (tx.tags &&
                    tx.tags
                        .map(o => o.name)
                        .join(',')
                        .indexOf(searchFilter) >= 0));
        });
    }
    /** Payee */
    if (query.payee_contain) {
        const payeeContainFilter = _.flatten([query.payee_contain]);
        payeeContainFilter.forEach(payee => {
            filterFns.push(tx => {
                return ((tx.original_name &&
                    tx.original_name.toLowerCase().indexOf(payee.toLowerCase()) >= 0) ||
                    (tx.display_name &&
                        tx.display_name.toLowerCase().indexOf(payee.toLowerCase()) >= 0));
            });
        });
    }
    if (query.payee_start_with) {
        const payeeStartWithFilter = _.flatten([query.payee_start_with]);
        payeeStartWithFilter.forEach(payee => {
            filterFns.push(tx => {
                return (tx.display_name &&
                    tx.display_name.toLowerCase().indexOf(payee.toLowerCase()) == 0);
            });
        });
    }
    if (query.payee_exact) {
        const payeeExactFilter = _.flatten([query.payee_exact]);
        payeeExactFilter.forEach(payee => {
            filterFns.push(tx => {
                return (tx.display_name &&
                    tx.display_name.toLowerCase() == payee.toLowerCase());
            });
        });
    }
    /** Notes */
    if (query.notes_contain) {
        const notesContainFilter = _.flatten([query.notes_contain]);
        notesContainFilter.forEach(notes => {
            filterFns.push(tx => {
                return (tx.display_notes &&
                    tx.display_notes.toLowerCase().indexOf(notes.toLowerCase()) >= 0);
            });
        });
    }
    if (query.notes_start_with) {
        const notesStartWithFilter = _.flatten([query.notes_start_with]);
        notesStartWithFilter.forEach(notes => {
            filterFns.push(tx => {
                return (tx.display_notes &&
                    tx.display_notes.toLowerCase().indexOf(notes.toLowerCase()) == 0);
            });
        });
    }
    if (query.notes_exact) {
        const notesExactFilter = _.flatten([query.notes_exact]);
        notesExactFilter.forEach(notes => {
            filterFns.push(tx => {
                return (tx.display_notes &&
                    tx.display_notes.toLowerCase() == notes.toLowerCase());
            });
        });
    }
    /** Category */
    const categoryFilter = query.category;
    if (categoryFilter) {
        _.flatten([categoryFilter]).forEach(o => {
            filterFns.push(tx => {
                return ((tx.category_id === parseInt(o) ||
                    tx.category_group_id === parseInt(o)) &&
                    (opts.allow_recurring_categories || tx.recurring_id === null));
            });
        });
    }
    const categoryExcludeFilter = query.category_exclude;
    if (categoryExcludeFilter) {
        _.flatten([categoryExcludeFilter]).forEach(o => {
            filterFns.push(tx => {
                return (tx.category_id !== parseInt(o) &&
                    tx.category_group_id !== parseInt(o) &&
                    (opts.allow_recurring_categories || tx.recurring_id === null));
            });
        });
    }
    /** Uncategorized */
    const uncategorizedFilter = query.uncategorized;
    if (uncategorizedFilter) {
        if (uncategorizedFilter == true || uncategorizedFilter === 'true') {
            filterFns.push(tx => {
                return (!tx.category_name &&
                    (opts.allow_recurring_categories || !tx.recurring_id));
            });
        }
        else {
            filterFns.push(tx => {
                return (!!tx.category_name &&
                    (opts.allow_recurring_categories || !tx.recurring_id));
            });
        }
    }
    /** Account */
    const accountFilter = query.account;
    if (accountFilter) {
        _.flatten([accountFilter]).forEach(account => {
            filterFns.push(tx => {
                return tx.plaid_account_id == parseInt(account);
            });
        });
    }
    const accountExcludeFilter = query.account_exclude;
    if (accountExcludeFilter) {
        _.flatten([accountExcludeFilter]).forEach(account => {
            filterFns.push(tx => {
                return tx.plaid_account_id !== parseInt(account);
            });
        });
    }
    /** Asset */
    const assetFilter = query.asset;
    if (assetFilter) {
        _.flatten([assetFilter]).forEach(o => {
            if (o === 'cash') {
                filterFns.push(tx => {
                    return !tx.asset_id && !tx.plaid_account_id;
                });
            }
            else {
                filterFns.push(tx => {
                    return tx.asset_id == parseInt(o);
                });
            }
        });
    }
    const assetExcludeFilter = query.asset_exclude;
    if (assetExcludeFilter) {
        _.flatten([assetExcludeFilter]).forEach(o => {
            if (o === 'cash') {
                filterFns.push(tx => {
                    return !!tx.asset_id || !!tx.plaid_account_id;
                });
            }
            else {
                filterFns.push(tx => {
                    return tx.asset_id !== parseInt(o);
                });
            }
        });
    }
    /** Amount **/
    const processAmountFilter = (query, twoValues = false) => {
        const split = query.split('|');
        const amount = parseFloat(split[0]);
        let amount2 = null;
        let currency = split[1] || opts.currency;
        if (twoValues) {
            amount2 = parseFloat(split[1]);
            currency = split[2] || opts.currency;
        }
        return {
            amount,
            currency,
            amount2,
        };
    };
    if (query.expense_greater_than) {
        _.flatten([query.expense_greater_than]).forEach(expense => {
            const { amount, currency } = processAmountFilter(expense);
            filterFns.push(tx => {
                return tx.amount > amount && tx.currency == currency;
            });
        });
    }
    if (query.expense_greater_equal) {
        _.flatten([query.expense_greater_equal]).forEach(expense => {
            const { amount, currency } = processAmountFilter(expense);
            filterFns.push(tx => {
                return tx.amount >= amount && tx.currency == currency;
            });
        });
    }
    if (query.expense_less_than) {
        _.flatten([query.expense_less_than]).forEach(expense => {
            const { amount, currency } = processAmountFilter(expense);
            filterFns.push(tx => {
                return tx.amount < amount && tx.amount >= 0 && tx.currency == currency;
            });
        });
    }
    if (query.expense_less_equal) {
        _.flatten([query.expense_less_equal]).forEach(expense => {
            const { amount, currency } = processAmountFilter(expense);
            filterFns.push(tx => {
                return tx.amount <= amount && tx.amount >= 0 && tx.currency == currency;
            });
        });
    }
    if (query.expense_exactly) {
        _.flatten([query.expense_exactly]).forEach(expense => {
            const { amount, currency } = processAmountFilter(expense);
            filterFns.push(tx => {
                return tx.amount == amount && tx.currency == currency;
            });
        });
    }
    if (query.expense_between) {
        _.flatten([query.expense_between]).forEach(expense => {
            const { amount, currency, amount2 } = processAmountFilter(expense, true);
            filterFns.push(tx => {
                return (tx.amount >= Math.min(amount, amount2) &&
                    tx.amount <= Math.max(amount, amount2) &&
                    tx.currency == currency);
            });
        });
    }
    if (query.income_greater_than) {
        _.flatten([query.income_greater_than]).forEach(income => {
            const { amount, currency } = processAmountFilter(income);
            filterFns.push(tx => {
                return tx.amount < -1 * amount && tx.currency == currency;
            });
        });
    }
    if (query.income_greater_equal) {
        _.flatten([query.income_greater_equal]).forEach(income => {
            const { amount, currency } = processAmountFilter(income);
            filterFns.push(tx => {
                return tx.amount <= -1 * amount && tx.currency == currency;
            });
        });
    }
    if (query.income_less_than) {
        _.flatten([query.income_less_than]).forEach(income => {
            const { amount, currency } = processAmountFilter(income);
            filterFns.push(tx => {
                return (tx.amount > -1 * amount && tx.amount <= 0 && tx.currency == currency);
            });
        });
    }
    if (query.income_less_equal) {
        _.flatten([query.income_less_equal]).forEach(income => {
            const { amount, currency } = processAmountFilter(income);
            filterFns.push(tx => {
                return (tx.amount >= -1 * amount && tx.amount <= 0 && tx.currency == currency);
            });
        });
    }
    if (query.income_exactly) {
        _.flatten([query.income_exactly]).forEach(income => {
            const { amount, currency } = processAmountFilter(income);
            filterFns.push(tx => {
                return tx.amount == -1 * amount && tx.currency == currency;
            });
        });
    }
    if (query.income_between) {
        _.flatten([query.income_between]).forEach(income => {
            const { amount, amount2, currency } = processAmountFilter(income, true);
            filterFns.push(tx => {
                return (tx.amount >= Math.min(-1 * amount, -1 * amount2) &&
                    tx.amount <= Math.max(-1 * amount, -1 * amount2) &&
                    tx.currency == currency);
            });
        });
    }
    /** Recurring */
    const recurringFilter = query.recurring;
    if (recurringFilter) {
        _.flatten([recurringFilter]).forEach(recurring => {
            if (recurring == 'any') {
                filterFns.push(tx => {
                    return tx.recurring_id !== null && tx.recurring_type == 'cleared';
                });
            }
            else if (recurring == 'suggested') {
                filterFns.push(tx => {
                    return tx.recurring_id !== null && tx.recurring_type == 'suggested';
                });
            }
            else {
                filterFns.push(tx => {
                    return tx.recurring_id == parseInt(recurring);
                });
            }
        });
    }
    // Includes recurring expenses which are NOT this
    const recurringExcludeFilter = query.recurring_exclude;
    if (recurringExcludeFilter) {
        _.flatten([recurringExcludeFilter]).forEach(recurring => {
            if (recurring == 'any') {
                filterFns.push(tx => {
                    return tx.recurring_id == null || tx.recurring_type == 'suggested';
                });
            }
            else if (recurring == 'suggested') {
                filterFns.push(tx => {
                    return tx.recurring_type !== 'suggested';
                });
            }
            else {
                filterFns.push(tx => {
                    return tx.recurring_id !== parseInt(recurring);
                });
            }
        });
    }
    /** Tag */
    const tagFilter = query.tag;
    if (tagFilter) {
        _.flatten([tagFilter]).forEach(tag => {
            filterFns.push(tx => {
                return tx.tags && tx.tags.map(o => o.id).indexOf(parseInt(tag)) > -1;
            });
        });
    }
    const tagExcludeFilter = query.tag_exclude;
    if (tagExcludeFilter) {
        _.flatten([tagExcludeFilter]).forEach(tag => {
            filterFns.push(tx => {
                return (!tx.tags ||
                    (tx.tags && tx.tags.map(o => o.id).indexOf(parseInt(tag)) == -1));
            });
        });
    }
    /** Untagged */
    const untaggedFilter = query.untagged;
    if (untaggedFilter) {
        filterFns.push(tx => {
            var _a, _b;
            if (untaggedFilter == true || untaggedFilter === 'true') {
                return !tx.tags || ((_a = tx.tags) === null || _a === void 0 ? void 0 : _a.length) == 0;
            }
            else {
                return !!tx.tags && ((_b = tx.tags) === null || _b === void 0 ? void 0 : _b.length) > 0;
            }
        });
    }
    /**
     * Status
     */
    const statusFilter = query.status;
    if (statusFilter) {
        _.flatten([statusFilter]).forEach(status => {
            if (status === 'unreviewed') {
                filterFns.push(tx => {
                    return tx.status == 'uncleared';
                });
            }
            else if (status === 'reviewed') {
                filterFns.push(tx => {
                    return tx.status == 'cleared';
                });
            }
            else if (status === 'delete pending') {
                filterFns.push(tx => {
                    return tx.status == 'delete_pending';
                });
            }
            else if (status === 'pending') {
                filterFns.push(tx => {
                    return tx.is_pending;
                });
            }
        });
    }
    const statusExcludeFilter = query.status_exclude;
    if (statusExcludeFilter) {
        _.flatten([statusExcludeFilter]).forEach(status => {
            if (status === 'unreviewed') {
                filterFns.push(tx => {
                    return tx.status !== 'uncleared';
                });
            }
            else if (status === 'reviewed') {
                filterFns.push(tx => {
                    return tx.status !== 'cleared';
                });
            }
            else if (status === 'pending') {
                filterFns.push(tx => {
                    return !tx.is_pending;
                });
            }
        });
    }
    /**
     * Type
     */
    const typeFilter = query.type;
    if (typeFilter) {
        _.flatten([typeFilter]).forEach(o => {
            if (o === 'group') {
                filterFns.push(tx => {
                    return tx.is_group;
                });
            }
            else if (o === 'split') {
                filterFns.push(tx => {
                    return !!tx.parent_id;
                });
            }
            else if (o === 'part_of_group') {
                filterFns.push(tx => {
                    return !!tx.group_id;
                });
            }
        });
    }
    const typeExcludeFilter = query.type_exclude;
    if (typeExcludeFilter) {
        _.flatten([typeExcludeFilter]).forEach(o => {
            if (o === 'group') {
                filterFns.push(tx => {
                    return !tx.is_group;
                });
            }
            else if (o === 'split') {
                filterFns.push(tx => {
                    return tx.parent_id == null;
                });
            }
            else if (o === 'part_of_group') {
                filterFns.push(tx => {
                    return tx.group_id == null;
                });
            }
        });
    }
    /** Unreviewed */
    const unreviewedFilter = query.unreviewed;
    if (unreviewedFilter) {
        filterFns.push(tx => {
            return tx.status === 'uncleared';
        });
    }
    /** All Income */
    const allIncomeFilter = query.is_income;
    if (allIncomeFilter) {
        if (allIncomeFilter == true || allIncomeFilter == 'true') {
            filterFns.push(tx => {
                return (tx.is_income && (opts.allow_recurring_categories || !tx.recurring_id));
            });
        }
        else {
            filterFns.push(tx => {
                return (!tx.is_income && (opts.allow_recurring_categories || !tx.recurring_id));
            });
        }
    }
    /** Exclude from totals */
    const excludeFromTotalsFilter = query.exclude_from_totals;
    if (excludeFromTotalsFilter) {
        if (excludeFromTotalsFilter == true || excludeFromTotalsFilter == 'true') {
            filterFns.push(tx => {
                return tx.exclude_from_totals;
            });
        }
        else {
            filterFns.push(tx => {
                return !tx.exclude_from_totals;
            });
        }
    }
    /** Exclude from budget */
    const excludeFromBudgetFilter = query.exclude_from_budget;
    if (excludeFromBudgetFilter) {
        if (excludeFromBudgetFilter == true || excludeFromBudgetFilter == 'true') {
            filterFns.push(tx => {
                return tx.exclude_from_budget;
            });
        }
        else {
            filterFns.push(tx => {
                return !tx.exclude_from_budget;
            });
        }
    }
    /**
     * File
     */
    const fileFilter = query.attachment;
    if (fileFilter == true || fileFilter == 'true') {
        filterFns.push(tx => {
            var _a;
            return ((_a = tx.files) === null || _a === void 0 ? void 0 : _a.length) > 0;
        });
    }
    else if (fileFilter == false || fileFilter == 'false') {
        filterFns.push(tx => {
            var _a;
            return !tx.files || ((_a = tx.files) === null || _a === void 0 ? void 0 : _a.length) == 0;
        });
    }
    return filterFns;
};
exports.buildFilters = buildFilters;
/**
 * Format of transaction used in grouped transactions modal
 */
const toGroupFormat = tx => {
    return {
        id: tx.id,
        payee: tx.payee,
        date: tx.formatted_date,
        amount: tx.amount,
        currency: tx.currency,
        category_id: tx.category_id,
        notes: tx.notes,
        to_base: tx.to_base,
        tags: tx.tags,
        asset_id: tx.asset_id,
        asset: `${[tx.asset_institution_name, tx.asset_name].join(' ')}`.trim(),
        plaid_account_id: tx.plaid_account_id,
        plaid_account: `${[tx.institution_name, tx.plaid_account_name].join(' ')}${tx.plaid_account_mask ? ` (${tx.plaid_account_mask})` : ''}`.trim(),
    };
};
exports.toGroupFormat = toGroupFormat;
