"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const qs = require("query-string");
const _ = require("lodash");
const semantic_ui_react_1 = require("semantic-ui-react");
const Moment = require("moment");
const react_router_dom_1 = require("react-router-dom");
const FilterRow_1 = require("@components/Transactions/FilterRow");
const EditableDate_1 = require("@components/elements/EditableDate");
const TransactionsProvider_1 = require("@providers/TransactionsProvider");
const UserProvider_1 = require("@providers/UserProvider");
const Filter = ({ disabled, currentPeriod, hasFiltersApplied }) => {
    const _transactions = (0, react_1.useContext)(TransactionsProvider_1.TransactionsContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [ready, setReady] = (0, react_1.useState)(false);
    const [match, setMatch] = (0, react_1.useState)('all');
    const [filters, setFilters] = (0, react_1.useState)([new Date().getTime()]);
    const [time, setTime] = (0, react_1.useState)('month');
    const [startDate, setStartDate] = (0, react_1.useState)(Moment()
        .startOf('month')
        .format('YYYY-MM-DD'));
    const [endDate, setEndDate] = (0, react_1.useState)(Moment()
        .endOf('month')
        .format('YYYY-MM-DD'));
    const [numFilters, setNumFilters] = (0, react_1.useState)(0);
    const location = (0, react_router_dom_1.useLocation)();
    const history = (0, react_router_dom_1.useHistory)();
    const [open, setOpen] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        convertFromQuery();
    }, [location]);
    /**
     * This takes what's in the URL and converts it to a format where we can
     * display it in the dropdowns
     */
    const convertFromQuery = () => {
        setFilters([]);
        let index = 0;
        const query = qs.parse(location.search);
        const current = {};
        const increment = () => {
            setFilters(curr => [...curr, new Date().getTime()]);
            return index++;
        };
        setMatch(query.match || 'all');
        setTime(query.time || 'month');
        setStartDate(query.start_date ||
            Moment()
                .startOf('month')
                .format('YYYY-MM-DD'));
        setEndDate(query.end_date ||
            Moment()
                .endOf('month')
                .format('YYYY-MM-DD'));
        if (query.payee_contain) {
            _.flatten([query.payee_contain]).forEach(cat => {
                current[increment()] = {
                    criteria: 'payee',
                    relation: 'contain',
                    value: cat,
                };
            });
        }
        if (query.payee_exact) {
            _.flatten([query.payee_exact]).forEach(cat => {
                current[increment()] = {
                    criteria: 'payee',
                    relation: 'exact',
                    value: cat,
                };
            });
        }
        if (query.payee_start_with) {
            _.flatten([query.payee_start_with]).forEach(cat => {
                current[increment()] = {
                    criteria: 'payee',
                    relation: 'start_with',
                    value: cat,
                };
            });
        }
        if (query.notes_contain) {
            _.flatten([query.notes_contain]).forEach(cat => {
                current[increment()] = {
                    criteria: 'notes',
                    relation: 'contain',
                    value: cat,
                };
            });
        }
        if (query.notes_exact) {
            _.flatten([query.notes_exact]).forEach(cat => {
                current[increment()] = {
                    criteria: 'notes',
                    relation: 'exact',
                    value: cat,
                };
            });
        }
        if (query.notes_start_with) {
            _.flatten([query.notes_start_with]).forEach(cat => {
                current[increment()] = {
                    criteria: 'notes',
                    relation: 'start_with',
                    value: cat,
                };
            });
        }
        if (query.search) {
            _.flatten([query.search]).forEach(cat => {
                current[increment()] = {
                    criteria: 'general search',
                    relation: 'contain',
                    value: cat,
                };
            });
        }
        if (query.category) {
            _.flatten([query.category]).forEach(cat => {
                current[increment()] = {
                    criteria: 'category',
                    relation: 'is',
                    value: parseInt(cat),
                };
            });
        }
        if (query.category_exclude) {
            _.flatten([query.category_exclude]).forEach(cat => {
                current[increment()] = {
                    criteria: 'category',
                    relation: 'is not',
                    value: parseInt(cat),
                };
            });
        }
        if (query.uncategorized) {
            current[increment()] = {
                criteria: 'category',
                relation: query.uncategorized === 'true' ? 'is' : 'is not',
                value: 'uncategorized',
            };
        }
        if (query.is_income) {
            current[increment()] = {
                criteria: 'category',
                relation: query.is_income === 'true' ? 'is' : 'is not',
                value: 'income',
            };
        }
        if (query.exclude_from_totals) {
            current[increment()] = {
                criteria: 'category',
                relation: query.exclude_from_totals === 'true' ? 'is' : 'is not',
                value: 'exclude_from_totals',
            };
        }
        if (query.exclude_from_budget) {
            current[increment()] = {
                criteria: 'category',
                relation: query.exclude_from_budget === 'true' ? 'is' : 'is not',
                value: 'exclude_from_budget',
            };
        }
        if (query.account) {
            _.flatten([query.account]).forEach(acc => {
                current[increment()] = {
                    criteria: 'account',
                    relation: 'is',
                    value: parseInt(acc),
                };
            });
        }
        if (query.account_exclude) {
            _.flatten([query.account_exclude]).forEach(acc => {
                current[increment()] = {
                    criteria: 'account',
                    relation: 'is not',
                    value: parseInt(acc),
                };
            });
        }
        if (query.asset) {
            _.flatten([query.asset]).forEach(acc => {
                current[increment()] = {
                    criteria: 'asset',
                    relation: 'is',
                    value: acc == 'cash' ? 'cash' : `${acc}`,
                };
            });
        }
        if (query.asset_exclude) {
            _.flatten([query.asset_exclude]).forEach(acc => {
                current[increment()] = {
                    criteria: 'asset',
                    relation: 'is not',
                    value: acc == 'cash' ? 'cash' : `${acc}`,
                };
            });
        }
        if (query.attachment) {
            current[increment()] = {
                criteria: 'file',
                relation: query.attachment == 'true' ? 'is' : 'is not',
                value: 'attached',
            };
        }
        if (query.recurring) {
            _.flatten([query.recurring]).forEach(rec => {
                current[increment()] = {
                    criteria: 'recurring',
                    relation: 'is',
                    value: rec == 'any'
                        ? 'any'
                        : rec == 'suggested'
                            ? 'suggested'
                            : parseInt(rec),
                };
            });
        }
        if (query.recurring_exclude) {
            _.flatten([query.recurring_exclude]).forEach(rec => {
                current[increment()] = {
                    criteria: 'recurring',
                    relation: 'is not',
                    value: rec == 'any'
                        ? 'any'
                        : rec == 'suggested'
                            ? 'suggested'
                            : parseInt(rec),
                };
            });
        }
        if (query.tag) {
            _.flatten([query.tag]).forEach(cat => {
                current[increment()] = {
                    criteria: 'tag',
                    relation: 'is',
                    value: parseInt(cat),
                };
            });
        }
        if (query.tag_exclude) {
            _.flatten([query.tag_exclude]).forEach(cat => {
                current[increment()] = {
                    criteria: 'tag',
                    relation: 'is not',
                    value: parseInt(cat),
                };
            });
        }
        if (query.expense_greater_than) {
            _.flatten([query.expense_greater_than]).forEach(cat => {
                const split = cat.split('|');
                current[increment()] = {
                    criteria: 'expense',
                    relation: 'greater_than',
                    value: parseFloat(split[0]),
                    currency: split[1] || _user.primaryCurrency,
                };
            });
        }
        if (query.expense_greater_equal) {
            _.flatten([query.expense_greater_equal]).forEach(cat => {
                const split = cat.split('|');
                current[increment()] = {
                    criteria: 'expense',
                    relation: 'greater_equal',
                    value: parseFloat(split[0]),
                    currency: split[1] || _user.primaryCurrency,
                };
            });
        }
        if (query.expense_less_than) {
            _.flatten([query.expense_less_than]).forEach(cat => {
                const split = cat.split('|');
                current[increment()] = {
                    criteria: 'expense',
                    relation: 'less_than',
                    value: parseFloat(split[0]),
                    currency: split[1] || _user.primaryCurrency,
                };
            });
        }
        if (query.expense_less_equal) {
            _.flatten([query.expense_less_equal]).forEach(cat => {
                const split = cat.split('|');
                current[increment()] = {
                    criteria: 'expense',
                    relation: 'less_equal',
                    value: parseFloat(split[0]),
                    currency: split[1] || _user.primaryCurrency,
                };
            });
        }
        if (query.expense_exactly) {
            _.flatten([query.expense_exactly]).forEach(cat => {
                const split = cat.split('|');
                current[increment()] = {
                    criteria: 'expense',
                    relation: 'exactly',
                    value: parseFloat(split[0]),
                    currency: split[1] || _user.primaryCurrency,
                };
            });
        }
        if (query.expense_between) {
            _.flatten([query.expense_between]).forEach(cat => {
                const split = cat.split('|');
                current[increment()] = {
                    criteria: 'expense',
                    relation: 'between',
                    value: parseFloat(split[0]),
                    value2: parseFloat(split[1]),
                    currency: split[2] || _user.primaryCurrency,
                };
            });
        }
        if (query.income_greater_than) {
            _.flatten([query.income_greater_than]).forEach(cat => {
                const split = cat.split('|');
                current[increment()] = {
                    criteria: 'income',
                    relation: 'greater_than',
                    value: parseFloat(split[0]),
                    currency: split[1] || _user.primaryCurrency,
                };
            });
        }
        if (query.income_greater_equal) {
            _.flatten([query.income_greater_equal]).forEach(cat => {
                const split = cat.split('|');
                current[increment()] = {
                    criteria: 'income',
                    relation: 'greater_equal',
                    value: parseFloat(split[0]),
                    currency: split[1] || _user.primaryCurrency,
                };
            });
        }
        if (query.income_less_than) {
            _.flatten([query.income_less_than]).forEach(cat => {
                const split = cat.split('|');
                current[increment()] = {
                    criteria: 'income',
                    relation: 'less_than',
                    value: parseFloat(split[0]),
                    currency: split[1] || _user.primaryCurrency,
                };
            });
        }
        if (query.income_less_equal) {
            _.flatten([query.income_less_equal]).forEach(cat => {
                const split = cat.split('|');
                current[increment()] = {
                    criteria: 'income',
                    relation: 'less_equal',
                    value: parseFloat(split[0]),
                    currency: split[1] || _user.primaryCurrency,
                };
            });
        }
        if (query.income_exactly) {
            _.flatten([query.income_exactly]).forEach(cat => {
                const split = cat.split('|');
                current[increment()] = {
                    criteria: 'income',
                    relation: 'exactly',
                    value: parseFloat(split[0]),
                    currency: split[1] || _user.primaryCurrency,
                };
            });
        }
        if (query.income_between) {
            _.flatten([query.income_between]).forEach(cat => {
                const split = cat.split('|');
                current[increment()] = {
                    criteria: 'income',
                    relation: 'between',
                    value: parseFloat(split[0]),
                    value2: parseFloat(split[1]),
                    currency: split[2] || _user.primaryCurrency,
                };
            });
        }
        if (query.status) {
            _.flatten([query.status]).forEach(cat => {
                current[increment()] = {
                    criteria: 'status',
                    relation: 'is',
                    value: cat,
                };
            });
        }
        if (query.status_exclude) {
            _.flatten([query.status_exclude]).forEach(cat => {
                current[increment()] = {
                    criteria: 'status',
                    relation: 'is not',
                    value: cat,
                };
            });
        }
        if (query.type) {
            _.flatten([query.type]).forEach(cat => {
                current[increment()] = {
                    criteria: 'type',
                    relation: 'is',
                    value: cat,
                };
            });
        }
        if (query.source) {
            _.flatten([query.source]).forEach(cat => {
                current[increment()] = {
                    criteria: 'source',
                    relation: 'is',
                    value: cat,
                };
            });
        }
        if (query.type_exclude) {
            _.flatten([query.type_exclude]).forEach(cat => {
                current[increment()] = {
                    criteria: 'type',
                    relation: 'is not',
                    value: cat.replace(/_/g, ' '),
                };
            });
        }
        if (query.untagged) {
            current[increment()] = {
                criteria: 'tag',
                relation: query.untagged == 'true' ? 'is' : 'is not',
                value: 'untagged',
            };
        }
        if (index == 0) {
            setFilters([new Date().getTime()]); // If none, start with one
        }
        _transactions.allFilters.current = current;
        setReady(true);
        setNumFilters(index);
    };
    /**
     * On Apply, this takes what's in the dropdowns and converts it into a
     * search query to insert into the URL so it can be digested by Transactions
     * container
     */
    const convertToQuery = () => {
        const queries = Object.values(_transactions.allFilters.current);
        let count = 0;
        // Final Query
        const grouped = {
            match,
            time,
        };
        if (time == 'custom') {
            grouped['start_date'] = startDate;
            grouped['end_date'] = endDate;
        }
        if (time != 'month') {
            count++;
        }
        const add = (key, val) => {
            if (grouped.hasOwnProperty(key)) {
                grouped[key].push(val);
            }
            else {
                grouped[key] = [val];
            }
        };
        queries.forEach(query => {
            if (query.criteria !== null && query.value != null) {
                if (query.criteria === 'expense' || query.criteria === 'income') {
                    const addOn = _user.primaryCurrency == query.currency ? '' : `|${query.currency}`;
                    if (query.value.toString().length > 0 &&
                        !isNaN(parseInt(query.value)) &&
                        (query.relation !== 'between' || !isNaN(parseInt(query.value2)))) {
                        count++;
                        add(`${query.criteria}_${query.relation}`, query.relation === 'between'
                            ? `${query.value}|${query.value2}${addOn}`
                            : `${query.value}${addOn}`);
                    }
                }
                else if (query.criteria === 'category') {
                    count++;
                    if (query.value === 'uncategorized') {
                        if (query.relation === 'is not') {
                            grouped['uncategorized'] = false;
                        }
                        else {
                            grouped['uncategorized'] = true;
                        }
                    }
                    else if (query.value === 'income') {
                        if (query.relation === 'is not') {
                            grouped['is_income'] = false;
                        }
                        else {
                            grouped['is_income'] = true;
                        }
                    }
                    else if (query.value === 'exclude_from_totals') {
                        if (query.relation === 'is not') {
                            grouped['exclude_from_totals'] = false;
                        }
                        else {
                            grouped['exclude_from_totals'] = true;
                        }
                    }
                    else if (query.value === 'exclude_from_budget') {
                        if (query.relation === 'is not') {
                            grouped['exclude_from_budget'] = false;
                        }
                        else {
                            grouped['exclude_from_budget'] = true;
                        }
                    }
                    else {
                        if (query.relation === 'is not') {
                            add('category_exclude', query.value);
                        }
                        else {
                            add('category', query.value);
                        }
                    }
                }
                else if (query.criteria === 'tag') {
                    count++;
                    if (query.value === 'untagged') {
                        if (query.relation === 'is not') {
                            grouped['untagged'] = false;
                        }
                        else {
                            grouped['untagged'] = true;
                        }
                    }
                    else {
                        if (query.relation === 'is not') {
                            add('tag_exclude', query.value);
                        }
                        else {
                            add('tag', query.value);
                        }
                    }
                }
                else if (query.criteria === 'asset') {
                    // Asset ID
                    count++;
                    if (query.value === 'cash') {
                        if (query.relation === 'is not') {
                            add('asset_exclude', 'cash');
                        }
                        else {
                            add('asset', 'cash');
                        }
                    }
                    else {
                        if (query.relation === 'is not') {
                            add('asset_exclude', query.value);
                        }
                        else {
                            add('asset', query.value);
                        }
                    }
                }
                else if (query.criteria === 'account') {
                    // Plaid Account ID
                    count++;
                    if (query.relation === 'is not') {
                        add('account_exclude', query.value);
                    }
                    else {
                        add('account', query.value);
                    }
                }
                else if (query.criteria === 'recurring') {
                    count++;
                    if (query.value === 'any') {
                        if (query.relation === 'is not') {
                            add('recurring_exclude', 'any');
                        }
                        else {
                            add('recurring', 'any');
                        }
                    }
                    else if (query.value === 'suggested') {
                        if (query.relation === 'is not') {
                            add('recurring_exclude', 'suggested');
                        }
                        else {
                            add('recurring', 'suggested');
                        }
                    }
                    else {
                        if (query.relation === 'is not') {
                            add('recurring_exclude', query.value);
                        }
                        else {
                            add('recurring', query.value);
                        }
                    }
                }
                else if (query.criteria === 'type' || query.criteria === 'status') {
                    count++;
                    if (query.relation === 'is not') {
                        add(`${query.criteria}_exclude`, query.value);
                    }
                    else {
                        add(query.criteria, query.value);
                    }
                }
                else if (query.criteria === 'file') {
                    count++;
                    if (query.relation === 'is not') {
                        add('attachment', false);
                    }
                    else {
                        add('attachment', true);
                    }
                }
                else if (query.criteria === 'payee' || query.criteria === 'notes') {
                    count++;
                    add(`${query.criteria}_${query.relation}`, query.value);
                }
                else if (query.criteria === 'general search') {
                    count++;
                    add(`search`, query.value);
                }
            }
        });
        setNumFilters(count);
        if (count > 0) {
            history.replace({
                search: `?${qs.stringify(grouped)}`,
            });
        }
        else {
            history.replace({
                search: ``,
            });
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(semantic_ui_react_1.Button.Group, { className: "mr-1rem" },
            React.createElement(semantic_ui_react_1.Button, { disabled: disabled, basic: !hasFiltersApplied, color: "orange", className: "filter-button", onClick: () => {
                    setOpen(!open);
                } },
                React.createElement("div", { className: "display--flex filter-button-content" },
                    ' ',
                    React.createElement(semantic_ui_react_1.Icon, { name: "sliders horizontal" }),
                    " Filters",
                    ' ',
                    numFilters > 0 && React.createElement("span", { className: "ml-05rem" },
                        "(",
                        numFilters,
                        ")"))),
            numFilters > 0 && (React.createElement(semantic_ui_react_1.Button, { basic: true, color: "orange", onClick: () => {
                    history.push({
                        search: ``,
                    });
                } }, "Clear"))),
        React.createElement(semantic_ui_react_1.Modal, { id: "filter-modal", open: open, closeOnEscape: true, size: 'small', closeOnDimmerClick: true, onClose: () => {
                // Reset dropdown values, if any changes
                convertFromQuery();
                setOpen(false);
            } },
            ready && (React.createElement(semantic_ui_react_1.Modal.Content, { className: "filter-modal-content" },
                React.createElement("div", { className: "display--flex mb-1rem flex--align-end sub-header" },
                    React.createElement("span", { className: "label-style" }, "Match "),
                    React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-05rem mr-05rem p-match-inline", inline: true, defaultValue: match, options: [
                            {
                                value: 'any',
                                key: 'any',
                                text: 'any',
                            },
                            {
                                value: 'all',
                                key: 'all',
                                text: 'all',
                            },
                        ], onChange: (e, { value }) => {
                            setMatch(value);
                        } }),
                    ' ',
                    React.createElement("span", { className: "label-style" }, "of the following filters:")),
                React.createElement("div", { className: "transaction-filter-popup" },
                    filters.map((filter, index) => {
                        if (!!filter) {
                            return (React.createElement(FilterRow_1.default, { key: `filter-row-${index}`, index: index, remove: index => {
                                    const currentFilters = [...filters];
                                    currentFilters[index] = null;
                                    setFilters(currentFilters);
                                }, duplicate: obj => {
                                    const newIndex = filters.length;
                                    setFilters([...filters, new Date().getTime()]);
                                    _transactions.allFilters.current[newIndex] = obj;
                                }, allFilters: _transactions.allFilters }));
                        }
                    }),
                    React.createElement("div", { className: "new-filter-row mb-1rem" },
                        React.createElement(semantic_ui_react_1.Button, { size: "mini", basic: true, color: "orange", onClick: () => {
                                setFilters([...filters, new Date().getTime()]);
                            } }, "Add another filter"))),
                React.createElement("div", { className: "pre-footer" },
                    React.createElement("div", { className: "display--flex flex--align-end" },
                        React.createElement("span", { className: "label-style" }, "Within the following time frame:"),
                        React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-05rem mr-05rem p-match-inline flex--align-center no-wrap", inline: true, value: time, options: [
                                {
                                    value: 'month',
                                    key: 'month',
                                    text: `${Moment(currentPeriod).format('MMMM YYYY')}`,
                                },
                                {
                                    value: 'last-month',
                                    key: 'last-month',
                                    text: `${Moment(currentPeriod)
                                        .subtract(1, 'month')
                                        .format('MMMM YYYY')}`,
                                },
                                {
                                    value: 'year',
                                    key: 'year',
                                    text: `${Moment().format('YYYY')}`,
                                },
                                {
                                    value: 'last-year',
                                    key: 'last-year',
                                    text: `${Moment()
                                        .subtract(1, 'year')
                                        .format('YYYY')}`,
                                },
                                {
                                    value: 'all',
                                    key: 'all',
                                    text: 'all time',
                                },
                                {
                                    value: 'custom',
                                    key: 'custom',
                                    text: 'custom',
                                },
                            ], onChange: (e, { value }) => {
                                setTime(value);
                            } })),
                    time === 'custom' && (React.createElement("div", { className: "display--flex mt-05rem" },
                        React.createElement(semantic_ui_react_1.Form, { className: "mb-0" },
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-0" },
                                React.createElement(semantic_ui_react_1.Form.Field, { className: "flex--center-align" },
                                    React.createElement("label", { className: "mr-05rem" }, "From"),
                                    React.createElement(EditableDate_1.default, { identifier: "filter-custom-start", firstValue: startDate, state: 'Editing', location: 'modal', format: 'month_day_year', onSave: start_date => {
                                            setStartDate(start_date);
                                        } })),
                                React.createElement(semantic_ui_react_1.Form.Field, { className: "flex--center-align" },
                                    React.createElement("label", { className: "mr-05rem" }, "To"),
                                    React.createElement(EditableDate_1.default, { identifier: "filter-custom-end", firstValue: endDate, state: 'Editing', location: 'modal', format: 'month_day_year', onSave: end_date => {
                                            setEndDate(end_date);
                                        } }))))))))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                numFilters > 0 ? (React.createElement(semantic_ui_react_1.Button, { content: "Clear filters", basic: true, color: "orange", className: "no-border", onClick: () => {
                        history.push({
                            search: ``,
                        });
                        setOpen(false);
                    } })) : (React.createElement(semantic_ui_react_1.Button, { content: "Cancel", basic: true, color: "orange", className: "no-border", onClick: () => {
                        setOpen(false);
                    } })),
                React.createElement(semantic_ui_react_1.Button, { content: "Apply filters", onClick: () => {
                        convertToQuery();
                        setOpen(false);
                    } })))));
};
exports.default = Filter;
