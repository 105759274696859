"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const format_1 = require("@helpers/format");
const semantic_ui_react_1 = require("semantic-ui-react");
const EditablePrice_1 = require("@components/elements/EditablePrice");
const UserProvider_1 = require("@providers/UserProvider");
const Summary = ({ budgetHistory, isLoading, startDate, endDate, openTable, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [hasRecurringIncome, setHasRecurringIncome] = (0, react_1.useState)(false);
    const [hasRecurringExpenses, setHasRecurringExpenses] = (0, react_1.useState)(false);
    const [hasRemainingIncome, setHasRemainingIncome] = (0, react_1.useState)(false);
    const [hasRemainingExpenses, setHasRemainingExpenses] = (0, react_1.useState)(false);
    const [display, setDisplay] = (0, react_1.useState)({
        total_income: localStorage.getItem(`_lm_summary_display_total_income`) !== 'hide',
        projected_income: localStorage.getItem(`_lm_summary_display_projected_income`) !== 'hide',
        total_expenses: localStorage.getItem(`_lm_summary_display_total_expenses`) !== 'hide',
        projected_expenses: localStorage.getItem(`_lm_summary_display_projected_expenses`) !== 'hide',
    });
    (0, react_1.useEffect)(() => {
        var _a, _b, _c, _d;
        setHasRecurringIncome(((_a = budgetHistory === null || budgetHistory === void 0 ? void 0 : budgetHistory.totals) === null || _a === void 0 ? void 0 : _a.actual_recurring_income) !== 0);
        setHasRecurringExpenses(((_b = budgetHistory === null || budgetHistory === void 0 ? void 0 : budgetHistory.totals) === null || _b === void 0 ? void 0 : _b.actual_recurring_expenses) !== 0);
        setHasRemainingIncome(((_c = budgetHistory === null || budgetHistory === void 0 ? void 0 : budgetHistory.totals) === null || _c === void 0 ? void 0 : _c.remaining_recurring_income) !== 0);
        setHasRemainingExpenses(((_d = budgetHistory === null || budgetHistory === void 0 ? void 0 : budgetHistory.totals) === null || _d === void 0 ? void 0 : _d.remaining_recurring_expenses) !== 0);
    }, [budgetHistory]);
    const getRow = ({ group, label, value, isParent, isChild, isIncome, link = null, tooltip = null, }) => {
        if (!display[group] && isChild) {
            return null;
        }
        return (React.createElement("div", { className: "card-content-wrapper" },
            React.createElement("div", { className: "card-content" },
                React.createElement("div", { className: "display--flex" },
                    isParent && (React.createElement("div", { className: "mr-05rem" },
                        React.createElement(semantic_ui_react_1.Icon, { style: { position: 'relative', top: '-2px' }, fitted: true, color: "grey", name: display[group] ? 'caret down' : 'caret right', className: `clickable`, onClick: () => {
                                const newValue = !display[group];
                                localStorage.setItem(`_lm_summary_display_${group}`, newValue ? 'show' : 'hide');
                                setDisplay(Object.assign(Object.assign({}, display), { [group]: newValue }));
                            } }))),
                    isChild && React.createElement("span", { className: "hierarchy-line-icon-4" }),
                    value && link ? (React.createElement("span", { className: "link clickable font--bold", onClick: () => {
                            openTable(Object.assign({ start_date: startDate, end_date: endDate, match: 'all', time: 'custom' }, link));
                        } }, label)) : (React.createElement("span", { className: "font--normal" }, label)),
                    tooltip && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { style: { position: 'relative', top: '-2px' }, name: "question circle", className: "ml-05rem", color: "grey" }), inverted: true, size: "small" }, tooltip))),
                React.createElement("span", { className: "card-number no-wrap" },
                    React.createElement("span", { className: `amount ${isIncome ? 'color--green' : ''} ${isParent &&
                            'font--bold'}` }, (0, format_1.toPrice)(value, _user.primaryCurrency))))));
    };
    const totalIncome = ((_a = budgetHistory === null || budgetHistory === void 0 ? void 0 : budgetHistory.totals) === null || _a === void 0 ? void 0 : _a.income) * -1; // Non-recurring income
    const spentRecurringIncome = ((_b = budgetHistory === null || budgetHistory === void 0 ? void 0 : budgetHistory.totals) === null || _b === void 0 ? void 0 : _b.actual_recurring_income) * -1; // Recurring income
    const remainingRecurringIncome = ((_c = budgetHistory === null || budgetHistory === void 0 ? void 0 : budgetHistory.totals) === null || _c === void 0 ? void 0 : _c.remaining_recurring_income) * -1; // Remaining recurring income
    const uncategorizedIncome = ((_d = budgetHistory === null || budgetHistory === void 0 ? void 0 : budgetHistory.totals) === null || _d === void 0 ? void 0 : _d.uncategorized_income) * -1; // Uncategorized income
    const totalExpense = (_e = budgetHistory === null || budgetHistory === void 0 ? void 0 : budgetHistory.totals) === null || _e === void 0 ? void 0 : _e.expenses; // Non-recurring expense
    const spentRecurringExpense = (_f = budgetHistory === null || budgetHistory === void 0 ? void 0 : budgetHistory.totals) === null || _f === void 0 ? void 0 : _f.actual_recurring_expenses; // Recurring expense
    const remainingRecurringExpense = (_g = budgetHistory === null || budgetHistory === void 0 ? void 0 : budgetHistory.totals) === null || _g === void 0 ? void 0 : _g.remaining_recurring_expenses; // Remaining recurring expense
    const uncategorizedExpenseSpend = (_h = budgetHistory === null || budgetHistory === void 0 ? void 0 : budgetHistory.totals) === null || _h === void 0 ? void 0 : _h.uncategorized_expenses; // Uncategorized expense
    const totalCurrentExpenses = totalExpense + spentRecurringExpense + uncategorizedExpenseSpend; // Total actual expenses
    const totalCurrentIncome = totalIncome + spentRecurringIncome + uncategorizedIncome; // Total actual income
    const projectedExpenses = totalCurrentExpenses + remainingRecurringExpense; // Total actual + projected
    const projectedIncome = totalCurrentIncome + remainingRecurringIncome; // Total actual + projected
    const netIncome = -1 * totalCurrentIncome + totalCurrentExpenses;
    const projectedNetIncome = -1 * projectedIncome + projectedExpenses;
    return (React.createElement(semantic_ui_react_1.Card, null,
        React.createElement(semantic_ui_react_1.Card.Content, { header: 'Period summary' }),
        React.createElement(semantic_ui_react_1.Card.Content, null, isLoading ? (React.createElement(semantic_ui_react_1.Loader, { active: true, size: "tiny", inline: "centered" })) : (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "label-style mb-05rem center-align" }, "Income"),
            getRow({
                group: 'total_income',
                label: 'Total Income Earned',
                value: totalCurrentIncome,
                isParent: hasRecurringIncome,
                isChild: false,
                isIncome: true,
                link: {
                    is_income: true,
                    exclude_from_totals: false,
                },
            }),
            hasRecurringIncome &&
                getRow({
                    group: 'total_income',
                    label: 'Recurring Income',
                    value: spentRecurringIncome,
                    isParent: false,
                    isChild: true,
                    isIncome: true,
                    link: {
                        is_income: true,
                        exclude_from_totals: false,
                        recurring: 'any',
                        status_exclude: 'suggested recurring',
                    },
                }),
            hasRecurringIncome &&
                getRow({
                    group: 'total_income',
                    label: 'Other Income',
                    value: totalIncome,
                    isParent: false,
                    isChild: true,
                    isIncome: true,
                    tooltip: 'Other Income excludes recurring income and uncategorized income',
                    link: {
                        is_income: true,
                        exclude_from_totals: false,
                        uncategorized: false,
                        recurring_exclude: 'any',
                    },
                }),
            !!((_j = budgetHistory === null || budgetHistory === void 0 ? void 0 : budgetHistory.totals) === null || _j === void 0 ? void 0 : _j.uncategorized_income) &&
                getRow({
                    group: 'total_income',
                    label: 'Uncategorized Income',
                    value: uncategorizedIncome,
                    isParent: false,
                    isChild: true,
                    isIncome: true,
                    link: {
                        uncategorized: true,
                        exclude_from_totals: false,
                        recurring_exclude: 'any',
                        income_greater_than: 0,
                    },
                }),
            hasRemainingIncome &&
                getRow({
                    group: 'projected_income',
                    label: 'Projected Income',
                    value: projectedIncome,
                    isParent: true,
                    isChild: false,
                    isIncome: true,
                    tooltip: 'Projected Income is your current total income plus any remaining recurring income',
                }),
            hasRemainingIncome &&
                getRow({
                    group: 'projected_income',
                    label: 'Remaining Recurring Income',
                    value: remainingRecurringIncome,
                    isParent: false,
                    isChild: true,
                    isIncome: true,
                })))),
        isLoading ? (React.createElement(semantic_ui_react_1.Card.Content, null,
            React.createElement(semantic_ui_react_1.Loader, { active: true, size: "tiny", inline: "centered" }))) : (React.createElement(semantic_ui_react_1.Card.Content, null,
            React.createElement("p", { className: "label-style mb-05rem center-align" }, "Expenses"),
            getRow({
                group: 'total_expenses',
                label: 'Total Expenses',
                value: totalCurrentExpenses,
                isParent: hasRecurringExpenses,
                isChild: false,
                isIncome: false,
                link: {
                    is_income: false,
                    exclude_from_totals: false,
                },
            }),
            hasRecurringExpenses &&
                getRow({
                    group: 'total_expenses',
                    label: 'Recurring Expenses',
                    value: spentRecurringExpense,
                    isParent: false,
                    isChild: true,
                    isIncome: false,
                    link: {
                        recurring: 'any',
                        status_exclude: 'suggested recurring',
                        exclude_from_totals: false,
                        is_income: false,
                    },
                }),
            hasRecurringExpenses &&
                getRow({
                    group: 'total_expenses',
                    label: 'Other Expenses',
                    value: totalExpense,
                    isParent: false,
                    isChild: true,
                    isIncome: false,
                    tooltip: 'Other Expenses excludes recurring expenses and uncategorized expenses',
                    link: {
                        recurring_exclude: 'any',
                        is_income: false,
                        exclude_from_totals: false,
                        uncategorized: false,
                    },
                }),
            !!((_k = budgetHistory === null || budgetHistory === void 0 ? void 0 : budgetHistory.totals) === null || _k === void 0 ? void 0 : _k.uncategorized_expenses) &&
                getRow({
                    group: 'total_expenses',
                    label: 'Uncategorized Expenses',
                    value: uncategorizedExpenseSpend,
                    isParent: false,
                    isChild: true,
                    isIncome: false,
                    link: {
                        expense_greater_than: 0,
                        recurring_exclude: 'any',
                        uncategorized: true,
                    },
                }),
            hasRemainingExpenses &&
                remainingRecurringExpense !== 0 &&
                getRow({
                    group: 'projected_expenses',
                    label: 'Projected Expenses',
                    value: projectedExpenses,
                    isParent: true,
                    isChild: false,
                    isIncome: false,
                    tooltip: 'Projected Expenses is your current total expenses plus any remaining recurring expenses',
                }),
            hasRemainingExpenses &&
                remainingRecurringExpense !== 0 &&
                getRow({
                    group: 'projected_expenses',
                    label: 'Remaining Recurring Expenses',
                    value: remainingRecurringExpense,
                    isParent: false,
                    isChild: true,
                    isIncome: false,
                }))),
        !isLoading && (React.createElement(semantic_ui_react_1.Card.Content, null,
            React.createElement("div", { className: "card-content-wrapper" },
                React.createElement("div", { className: "card-content" },
                    React.createElement("div", null,
                        "Net Income",
                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }), inverted: true, size: "small" }, "Your net income is your total income minus total expenses, excluding any remaining recurring items.")),
                    React.createElement("span", { className: `card-number no-wrap ` },
                        React.createElement(EditablePrice_1.default, { className: "overview-amount", identifier: `spending-breakdown-expense`, amount: netIncome, showSignLabel: true, state: 'ReadOnly', location: 'inline' })))),
            hasRemainingIncome && hasRemainingExpenses && (React.createElement("div", { className: "card-content-wrapper" },
                React.createElement("div", { className: "card-content" },
                    React.createElement("div", null,
                        "Projected Net Income",
                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }), inverted: true, size: "small" }, "Your projected net income is your total current expenses (including any remaining recurring expenses) minus total current income (including any remaining recurring income).")),
                    React.createElement("span", { className: `card-number no-wrap ` },
                        React.createElement(EditablePrice_1.default, { className: "overview-amount", identifier: `spending-breakdown-expense`, amount: projectedNetIncome, showSignLabel: true, state: 'ReadOnly', location: 'inline' }))))))),
        !isLoading &&
            ((hasRemainingIncome && hasRemainingExpenses) ||
                !!totalCurrentIncome) && (React.createElement(semantic_ui_react_1.Card.Content, null,
            !!totalCurrentIncome && (React.createElement("div", { className: "card-content-wrapper" },
                React.createElement("div", { className: "card-content" },
                    React.createElement("div", null,
                        "Current Savings Rate",
                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }), inverted: true, size: "small" }, "This is your current savings rate, calculated using Total Income Earned and Total Expenses.")),
                    React.createElement("span", { className: `card-number no-wrap ${totalCurrentIncome > totalCurrentExpenses
                            ? 'color--green'
                            : ''}` }, (0, format_1.toPercentage)((totalCurrentIncome - totalCurrentExpenses) /
                        totalCurrentIncome, 2))))),
            hasRemainingIncome && hasRemainingExpenses && (React.createElement("div", { className: "card-content-wrapper" },
                React.createElement("div", { className: "card-content" },
                    React.createElement("div", null,
                        "Projected Savings Rate",
                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }), inverted: true, size: "small" }, "This is your projected current savings rate including any remaining recurring items, calculated using Projected Income and Projected Expenses.")),
                    React.createElement("span", { className: `card-number no-wrap ${projectedIncome > projectedExpenses ? 'color--green' : ''}` }, (0, format_1.toPercentage)((projectedIncome - projectedExpenses) / projectedIncome, 2)))))))));
};
exports.default = Summary;
