"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const UserProvider_1 = require("@providers/UserProvider");
const semantic_ui_react_1 = require("semantic-ui-react");
const account_1 = require("@actions/account");
const account_2 = require("@actions/account");
const Collaborators = ({ _process, _showToast }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [inviteEmail, setInviteEmail] = (0, react_1.useState)('');
    const [isDeleting, setIsDeleting] = (0, react_1.useState)(false);
    const [revokeUser, setRevokeUser] = (0, react_1.useState)(null);
    const [collaborators, setCollaborators] = (0, react_1.useState)([]);
    const [invitedUI, setInvitedUI] = (0, react_1.useState)([]);
    const [collaboratorsUI, setCollaboratorsUI] = (0, react_1.useState)([]);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [isResending, setIsResending] = (0, react_1.useState)(false);
    const invite = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const response = yield (0, account_1.inviteCollaborator)({
            email: inviteEmail,
        });
        if (response.error) {
            _showToast({
                message: response.error.message,
                type: 'error',
            });
        }
        else {
            yield refreshCollaborators();
            _showToast({
                message: response.data,
                type: 'success',
            });
            setInviteEmail('');
        }
        setIsLoading(false);
    });
    (0, react_1.useEffect)(() => {
        refreshCollaborators();
    }, []);
    const refreshCollaborators = () => __awaiter(void 0, void 0, void 0, function* () {
        const currentAccount = yield _user.fetchAccountDetails();
        setCollaborators(currentAccount.collaborators);
    });
    (0, react_1.useEffect)(() => {
        const collabUI = collaborators
            .filter(o => !o.pending)
            .map((user, index) => {
            return (React.createElement("div", { className: "collaborator", key: `collaborator-${index}` },
                React.createElement("span", { className: "info" },
                    user.name
                        ? `${user.name} ${user.id === _user.userId ? '(you)' : ''} - `
                        : '',
                    React.createElement("span", { className: "email" }, user.email)),
                React.createElement("div", null, _user.isAdmin && _user.userId !== user.id && (React.createElement(semantic_ui_react_1.Button, { type: "button", color: "red", size: "mini", loading: isResending, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setRevokeUser(user);
                    }) }, "Revoke")))));
        });
        setCollaboratorsUI(collabUI);
        const invited = collaborators
            .filter(o => o.pending)
            .map((user, index) => {
            return (React.createElement("div", { className: "collaborator", key: `collaborator-${index}` },
                React.createElement("span", { className: "info" },
                    user.name ? `${user.name} - ` : '',
                    React.createElement("span", { className: "email" }, user.email)),
                React.createElement("div", null, _user.isAdmin && _user.userId !== user.id && (React.createElement(React.Fragment, null,
                    React.createElement(semantic_ui_react_1.Button, { type: "button", size: "mini", loading: isResending, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setIsResending(true);
                            const results = yield _process(account_2.resendInvitation)({
                                email: user.email,
                            });
                            if (!results.error) {
                                _showToast({
                                    message: `Email invitation resent to ${user.email}`,
                                    type: 'success',
                                });
                            }
                            setIsResending(false);
                        }) }, "Resend invite"),
                    React.createElement(semantic_ui_react_1.Button, { type: "button", color: "red", size: "mini", loading: isResending, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setRevokeUser(user);
                        }) }, "Revoke"))))));
        });
        setInvitedUI(invited);
    }, [collaborators]);
    return (React.createElement(React.Fragment, null,
        React.createElement(semantic_ui_react_1.Form, { className: "mb-1rem" },
            React.createElement(semantic_ui_react_1.Card, null,
                React.createElement(semantic_ui_react_1.Card.Content, { header: "Collaborators" }),
                React.createElement(semantic_ui_react_1.Card.Content, null,
                    _user.isAdmin && (React.createElement(semantic_ui_react_1.Message, { info: true }, "Collaborators are able to view and edit the data. They may not invite other collaborators or delete your budget.")),
                    (collaboratorsUI === null || collaboratorsUI === void 0 ? void 0 : collaboratorsUI.length) ? (React.createElement(React.Fragment, null,
                        React.createElement("span", { className: "form-label" }, "Active collaborators"),
                        React.createElement("div", { className: "collaborators" }, collaboratorsUI))) : (React.createElement(React.Fragment, null)),
                    (invitedUI === null || invitedUI === void 0 ? void 0 : invitedUI.length) ? (React.createElement(React.Fragment, null,
                        React.createElement("span", { className: "form-label mt-1rem" }, "Invited collaborators"),
                        React.createElement("div", { className: "collaborators" }, invitedUI))) : (React.createElement(React.Fragment, null)),
                    React.createElement("p", null),
                    _user.isAdmin && (React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", null, "Invite a new collaborator"),
                            React.createElement(semantic_ui_react_1.Form.Input, { type: "text", value: inviteEmail, onChange: e => {
                                    setInviteEmail(e.target.value);
                                }, placeholder: "e-mail address", action: true },
                                React.createElement("input", null),
                                React.createElement(semantic_ui_react_1.Button, { loading: isLoading, type: "submit", size: "small", onClick: invite }, "Invite")))))))),
        React.createElement(semantic_ui_react_1.Modal, { open: !!revokeUser, size: 'tiny', onClose: () => {
                setRevokeUser(null);
                setIsDeleting(false);
            } },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Confirm removing this collaborator"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("p", null,
                    "By revoking ",
                    (revokeUser === null || revokeUser === void 0 ? void 0 : revokeUser.name) || 'their',
                    " (", revokeUser === null || revokeUser === void 0 ? void 0 :
                    revokeUser.email,
                    ")'s access to this budget, they will no longer be able to view or edit your data. Your data, including changes they've made, will remain intact."),
                React.createElement("p", null,
                    "We will not notify ",
                    (revokeUser === null || revokeUser === void 0 ? void 0 : revokeUser.name) || 'them',
                    " that their access has been revoked."),
                React.createElement("p", null,
                    "You may, at any time, re-invite ",
                    (revokeUser === null || revokeUser === void 0 ? void 0 : revokeUser.name) || 'them',
                    " to access your budget.")),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: true, loading: isDeleting, disabled: isDeleting, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setRevokeUser(null);
                        setIsDeleting(false);
                    }) }, "Cancel"),
                React.createElement(semantic_ui_react_1.Button, { color: "orange", loading: isDeleting, disabled: isDeleting, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setIsDeleting(true);
                        const results = yield _process(account_1.removeCollaborator)(revokeUser === null || revokeUser === void 0 ? void 0 : revokeUser.id);
                        if (results.data) {
                            yield refreshCollaborators();
                            _showToast({
                                message: 'Successfully removed collaborator',
                                type: 'success',
                            });
                        }
                        else {
                            _showToast({
                                message: 'Failed to remove collaborator. You may not have permissions. Contact support@lunchmoney.app for further assistance.',
                                type: 'error',
                            });
                        }
                        setRevokeUser(null);
                        setIsDeleting(false);
                    }) }, "Yes, revoke access")))));
};
exports.default = Collaborators;
