"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const QueryLoader = ({ noBorder = false }) => {
    return (React.createElement("div", { className: `width-100 loader-cell loading-card monospace center-align ${noBorder ? 'no-border' : ''}` },
        React.createElement("div", { className: "yellow-mascot" }),
        React.createElement("h2", null, "We are crunching some numbers!"),
        React.createElement(semantic_ui_react_1.Loader, { active: true, inline: "centered", size: "small" }, 'Running query and generating charts, please wait...')));
};
exports.default = QueryLoader;
