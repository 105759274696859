"use strict";
/**
 *  SplitView.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const _ = require("lodash");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_1 = require("react");
// Components
const format_1 = require("@helpers/format");
const transactions_1 = require("@actions/transactions");
const EditableCategory_1 = require("@components/elements/EditableCategory");
const SplitElement_1 = require("@components/Transactions/Modal/SplitElement");
const TransactionsProvider_1 = require("@providers/TransactionsProvider");
const UserProvider_1 = require("@/providers/UserProvider");
const SplitView = ({ data, useModal, utils, setVisibility, switchView, setDisableOnClickOutside, }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [split, setSplit, setOriginalAmount, originalAmount, unaccountedAmount,] = useSplit();
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [parentTx, setParentTx] = (0, react_1.useState)(null);
    const [splitRows, setSplitRows] = (0, react_1.useState)(null);
    const [isReadonly, setIsReadonly] = (0, react_1.useState)(false);
    const [splitQuantity, setSplitQuantity] = (0, react_1.useState)(2);
    const _transactions = (0, react_1.useContext)(TransactionsProvider_1.TransactionsContext);
    const transaction = _transactions.modalData.current;
    // Setup
    (0, react_1.useEffect)(() => {
        setDisableOnClickOutside(true);
        useModal({
            header: (React.createElement("div", null,
                React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        switchView('DETAILS_VIEW');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " Original Transaction Details"),
                React.createElement("span", { className: "header-text-large" }, "Split Transaction"),
                !isReadonly && parentTx && split.length > 0 && (React.createElement(semantic_ui_react_1.Message, { error: parseFloat(unaccountedAmount) !== 0, success: parseFloat(unaccountedAmount) == 0 },
                    React.createElement("div", { className: "split-amount-left" },
                        "Original amount:",
                        ' ',
                        (0, format_1.toPrice)(Math.abs(parentTx.amount), parentTx.currency),
                        React.createElement("br", null),
                        "Amount left to split:",
                        ' ',
                        isNaN(parseInt(unaccountedAmount)) ? (React.createElement("span", { className: "color--red" }, "fix error")) : (React.createElement("span", { className: parseFloat(unaccountedAmount) == 0
                                ? 'color--green'
                                : 'color--red' }, (0, format_1.toPrice)(unaccountedAmount, parentTx.currency)))))))),
        });
        return () => {
            useModal({
                header: React.createElement(React.Fragment, null),
            });
        };
    }, [isReadonly, parentTx, split, data, unaccountedAmount]);
    /** Unsplit function has a confirmation component */
    const [showUnsplitConfirmation, setShowUnsplitConfirmation] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        useModal({
            leftButton: transaction.parent_id ? (React.createElement(React.Fragment, null,
                React.createElement(semantic_ui_react_1.Button, { color: "red", className: "no-border", basic: true, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setDisableOnClickOutside(true);
                        setShowUnsplitConfirmation(true);
                    }) }, 'Unsplit'),
                React.createElement(semantic_ui_react_1.Modal, { size: 'tiny', open: showUnsplitConfirmation },
                    React.createElement(semantic_ui_react_1.Modal.Header, null, "Confirm unsplit"),
                    React.createElement(semantic_ui_react_1.Modal.Content, null,
                        React.createElement("p", null, "Are you sure you want to unsplit this transaction?")),
                    React.createElement(semantic_ui_react_1.Modal.Actions, null,
                        React.createElement(semantic_ui_react_1.Button, { basic: true, className: "no-border", color: "orange", loading: isLoading, disabled: isLoading, onClick: () => {
                                setDisableOnClickOutside(false);
                                setShowUnsplitConfirmation(false);
                            } }, "No, Cancel"),
                        React.createElement(semantic_ui_react_1.Button, { loading: isLoading, disabled: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                var _a;
                                setIsLoading(true);
                                const results = yield (0, transactions_1.unsplitTransactions)({
                                    transaction_ids: [transaction.parent_id],
                                    remove_parents: false,
                                });
                                if (!results.error) {
                                    utils._showToast({
                                        message: 'Successfully unsplit transaction',
                                        type: 'success',
                                    });
                                    if (data.amendTransactions) {
                                        data.amendTransactions({
                                            update: Object.assign(Object.assign({}, parentTx), { has_children: false, split: [] }), // parent tx
                                            remove: ((_a = parentTx.split) === null || _a === void 0 ? void 0 : _a.map(o => o.id)) || null, // prev splits
                                        });
                                        setShowUnsplitConfirmation(false);
                                        setVisibility(false, true);
                                    }
                                    else {
                                        setShowUnsplitConfirmation(false);
                                        setVisibility(false);
                                    }
                                }
                            }) }, "Yes, Confirm"))))) : (React.createElement(React.Fragment, null)),
        });
        return () => {
            useModal({
                leftButton: React.createElement(React.Fragment, null),
            });
        };
    }, [isLoading, showUnsplitConfirmation]);
    // Update save function
    (0, react_1.useEffect)(() => {
        useModal({
            rightButton: (React.createElement(semantic_ui_react_1.Button, { loading: isLoading, disabled: isLoading ||
                    parseFloat(unaccountedAmount) !== 0 ||
                    !!_.find(split, o => {
                        return !(o.category_id && o.payee && o.formatted_date && o.amount);
                    }), color: "orange", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    var _a;
                    if (!isReadonly) {
                        setIsLoading(true);
                        // Figure out if the sign should be flipped.
                        const shouldFlip = parentTx.amount < 0;
                        const results = yield utils._process(transactions_1.splitTransaction)(parentTx.id, {
                            split: _.filter(split, o => {
                                return !!o.amount; // Don't include ones with null or 0
                            }).map(o => {
                                return {
                                    date: o.formatted_date,
                                    payee: o.payee,
                                    category_id: o.category_id,
                                    amount: shouldFlip ? -1 * o.amount : o.amount,
                                    notes: o.notes,
                                    tags: o.tags,
                                };
                            }),
                        });
                        if (!results.error) {
                            // Show success message
                            utils._showToast({
                                message: 'Successfully updated split transaction',
                                type: 'success',
                            });
                            if (data.amendTransactions) {
                                data.amendTransactions({
                                    update: { id: parentTx.id, has_children: true }, // parent tx
                                    add: results.data, // splits
                                    remove: ((_a = parentTx.split) === null || _a === void 0 ? void 0 : _a.map(o => o.id)) || null, // prev splits
                                });
                                setIsLoading(false);
                                setVisibility(false, true);
                            }
                            else {
                                setIsLoading(false);
                                setVisibility(false);
                            }
                        }
                        else {
                            setIsLoading(false);
                        }
                    }
                    else {
                        setIsLoading(false);
                        setVisibility(false, true);
                    }
                }) }, isReadonly ? 'Close' : 'Save changes')),
        });
        return () => {
            useModal({
                rightButton: React.createElement(React.Fragment, null),
            });
        };
    }, [parentTx, split, unaccountedAmount, isReadonly, isLoading]);
    (0, react_1.useEffect)(() => {
        useModal({
            middleButton: isReadonly ? (React.createElement(React.Fragment, null)) : (React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "no-border", basic: true, onClick: () => {
                    setVisibility(false, true);
                } }, 'Cancel')),
        });
        return () => {
            useModal({
                middleButton: React.createElement(React.Fragment, null),
            });
        };
    }, [isReadonly]);
    // Get split details, if any
    (0, react_1.useEffect)(() => {
        function getDetails() {
            return __awaiter(this, void 0, void 0, function* () {
                const results = yield (0, transactions_1.getTransactionSplitDetails)(transaction.parent_id, {});
                if (!results.error) {
                    setSplit(results.split.map(o => {
                        // If it's the same sign as results.amount, then +
                        // else -
                        if (o.amount * results.amount > 0) {
                            return Object.assign(Object.assign({}, o), { amount: Math.abs(o.amount) });
                        }
                        else {
                            return Object.assign(Object.assign({}, o), { amount: Math.abs(o.amount) * -1 });
                        }
                    }));
                    setOriginalAmount(Math.abs(results.amount));
                    setParentTx(results);
                    // Check if it's editable
                    setIsReadonly(!!_.find(results.split, tx => {
                        return tx.group_id !== null || tx.recurring_id !== null;
                    }));
                }
            });
        }
        if (transaction.hasOwnProperty('parent_id') &&
            transaction.parent_id !== null) {
            getDetails();
        }
        else {
            // set parent TX to current transaction
            setParentTx(transaction);
            setOriginalAmount(Math.abs(transaction.amount));
            setSplit([]);
        }
    }, []);
    (0, react_1.useEffect)(() => {
        const rows = split.map((row, index) => {
            var _a, _b;
            if (isReadonly) {
                return (React.createElement("div", { id: "transactions-modal-detail-view", key: `split-${index}` },
                    React.createElement("div", { className: "transaction-details" },
                        React.createElement("h3", null,
                            "Split ",
                            index + 1),
                        React.createElement("div", { className: "transaction-detail" },
                            React.createElement("label", null, "Date"),
                            React.createElement("span", null, Moment(split[index].formatted_date).format(_user.getMonthDayYearFormat()))),
                        React.createElement("div", { className: "transaction-detail" },
                            React.createElement("label", null, "Payee"),
                            React.createElement("span", null, split[index].payee)),
                        React.createElement("div", { className: "flex--space-between" },
                            React.createElement("div", { className: "transaction-detail" },
                                React.createElement("label", null, "Category"),
                                React.createElement(EditableCategory_1.default, { identifier: `split-category-${index}`, firstValue: split[index].category_id, state: 'ReadOnly', location: 'modal', onSave: (category_id) => __awaiter(void 0, void 0, void 0, function* () { }) })),
                            React.createElement("div", { className: "transaction-detail" },
                                React.createElement("label", null, "Amount"),
                                React.createElement("span", null, (0, format_1.toPrice)(split[index].amount, split[index].currency)))),
                        split[index].notes && (React.createElement("div", { className: "transaction-detail" },
                            React.createElement("label", null, "Notes"),
                            React.createElement("span", null, split[index].notes))),
                        (split[index].group_id || split[index].recurring_id) && (React.createElement("div", { className: "transaction-detail" },
                            React.createElement("label", null, "Type"),
                            React.createElement("div", null,
                                split[index].group_id && (React.createElement("span", null,
                                    "Grouped",
                                    ' ',
                                    React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "exclamation triangle", color: "red" }) },
                                        React.createElement("p", null, "This split transaction is part of a transaction group."),
                                        React.createElement("p", null,
                                            "If you want to edit the split, you must either:",
                                            React.createElement("br", null),
                                            " 1. remove from the transaction group first, or",
                                            React.createElement("br", null),
                                            " 2. unsplit the transaction.")))),
                                split[index].recurring_id && (React.createElement("span", null,
                                    "Recurring",
                                    ' ',
                                    React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "exclamation triangle", color: "red" }) },
                                        React.createElement("p", null, "This transaction is linked to a recurring item."),
                                        React.createElement("p", null,
                                            "If you want to edit the split, you may either:",
                                            React.createElement("br", null),
                                            " 1. unlink from the recurring item, or",
                                            React.createElement("br", null),
                                            " 2. unsplit the transaction."))))))))));
            }
            return (React.createElement(SplitElement_1.default, { key: `split-element-${index}`, index: index, split: split, setSplit: setSplit, originalAmount: originalAmount, currency: transaction.currency, moveFiles: ((_a = transaction.files) === null || _a === void 0 ? void 0 : _a.length) > 0 && index == 0
                    ? (_b = transaction.files) === null || _b === void 0 ? void 0 : _b.length
                    : null }));
        });
        setSplitRows(rows);
    }, [split, originalAmount, isReadonly]);
    return parentTx !== null && unaccountedAmount !== null ? (React.createElement("div", { id: "split-view", className: "detail-buttons" },
        splitRows,
        !isReadonly && split.length === 0 && (React.createElement(semantic_ui_react_1.Button.Group, null,
            React.createElement(semantic_ui_react_1.Button, { icon: "minus", disabled: splitQuantity < 3, onClick: () => {
                    setSplitQuantity(splitQuantity - 1);
                } }),
            React.createElement(semantic_ui_react_1.Button, { size: "small", onClick: () => {
                    var _a;
                    // const newLength = split.length + 1
                    const dividedAmount = Math.abs(parentTx.amount / splitQuantity).toFixed(2);
                    let lastAmount = Math.abs(parentTx.amount -
                        parseFloat(dividedAmount) * (splitQuantity - 1)).toFixed(2);
                    const _split = [];
                    for (let i = 1; i <= splitQuantity; i++) {
                        _split.push({
                            formatted_date: parentTx.formatted_date || parentTx.date,
                            payee: parentTx.payee,
                            tags: ((_a = parentTx.tags) === null || _a === void 0 ? void 0 : _a.map(o => o.id)) || [],
                            category_id: parentTx.category_id,
                            notes: parentTx.notes || '',
                            amount: i == splitQuantity ? lastAmount : dividedAmount,
                        });
                    }
                    setSplit(_split);
                } },
                "Split evenly ",
                splitQuantity,
                " ways"),
            React.createElement(semantic_ui_react_1.Button, { icon: "plus", disabled: splitQuantity > 10, onClick: () => {
                    setSplitQuantity(splitQuantity + 1);
                } }))),
        !isReadonly && split.length > 0 && parseFloat(unaccountedAmount) !== 0 && (React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", color: "orange", onClick: () => {
                setSplit([
                    ...split,
                    {
                        formatted_date: parentTx.formatted_date || parentTx.date,
                        payee: parentTx.payee,
                        category_id: null,
                        notes: parentTx.notes || '',
                        amount: unaccountedAmount,
                    },
                ]);
            } }, "Split the rest")),
        !isReadonly && parseFloat(unaccountedAmount) !== 0 && (React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", color: "orange", onClick: () => {
                var _a;
                setSplit(split.length === 0
                    ? [
                        {
                            formatted_date: parentTx.formatted_date || parentTx.date,
                            payee: parentTx.payee,
                            tags: ((_a = parentTx.tags) === null || _a === void 0 ? void 0 : _a.map(o => o.id)) || [],
                            category_id: parentTx.category_id,
                            notes: parentTx.notes || '',
                            amount: null,
                        },
                        {
                            formatted_date: parentTx.formatted_date || parentTx.date,
                            payee: parentTx.payee,
                            category_id: parentTx.category_id,
                            notes: parentTx.notes || '',
                            amount: null,
                        },
                    ]
                    : [
                        ...split,
                        {
                            formatted_date: parentTx.formatted_date || parentTx.date,
                            payee: parentTx.payee,
                            category_id: parentTx.category_id,
                            notes: parentTx.notes || '',
                            amount: null,
                        },
                    ]);
            } }, "Split transaction (custom)")),
        !isReadonly && split.length === 0 && (React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", color: "orange", onClick: () => {
                switchView('AMORTIZE_VIEW');
            } }, "Split in equal payments over time")))) : (React.createElement(semantic_ui_react_1.Loader, { active: true, inline: "centered", size: "small" }));
};
exports.default = SplitView;
function useSplit() {
    const [split, setSplit] = (0, react_1.useState)([]);
    const [unaccountedAmount, setUnaccountedAmount] = (0, react_1.useState)(null);
    const [originalAmount, setOriginalAmount] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        // Calculate new unaccounted amount when split is updated
        setUnaccountedAmount(split.reduce((acc, curr) => {
            return (acc - curr.amount).toFixed(2);
        }, originalAmount));
    }, [split, originalAmount]);
    (0, react_1.useEffect)(() => {
        // Set unaccounted amount when we get the original amount
        if (unaccountedAmount === null) {
            setUnaccountedAmount(originalAmount);
        }
    }, [originalAmount]);
    return [split, setSplit, setOriginalAmount, originalAmount, unaccountedAmount];
}
