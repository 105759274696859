"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const qs = require("query-string");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
const ClearFilterRow = ({ cols, showReapply = true, onReapply = () => { }, resetAllTransactions = () => { }, }) => {
    const history = (0, react_router_dom_1.useHistory)();
    return (React.createElement(semantic_ui_react_1.Table.Row, { className: "no-hover-tr padded-row" },
        React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: showReapply ? Math.floor(cols / 2) : cols, className: "center-align clickable", onClick: () => {
                var _a;
                // If it's on a special time, we need to reset
                const isSpecialTime = ['year', 'last-year', 'last-month', 'all', 'custom'].indexOf((_a = qs.parse(location.search)) === null || _a === void 0 ? void 0 : _a.time) > -1;
                if (isSpecialTime) {
                    resetAllTransactions();
                }
                history.push({
                    search: ``,
                });
            } },
            React.createElement("span", { className: "link ellipsis" },
                React.createElement(semantic_ui_react_1.Icon, { name: "x" }),
                " Clear filter and show all transactions")),
        showReapply && (React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: Math.ceil(cols / 2), className: "center-align clickable", onClick: onReapply },
            React.createElement("span", { className: "link" },
                React.createElement(semantic_ui_react_1.Icon, { name: "repeat" }),
                " Re-apply filter")))));
};
exports.default = ClearFilterRow;
