"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendBasicFeedback = exports.receiveFeedback = exports.addCrypto = exports.sendSyncingSupportEmail = exports.sendPlaidSupportEmail = void 0;
const common_1 = require("./common");
/**
 *
 *
 */
const sendPlaidSupportEmail = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)('support/plaid_account', opts);
});
exports.sendPlaidSupportEmail = sendPlaidSupportEmail;
const sendSyncingSupportEmail = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)('support/syncing', opts);
});
exports.sendSyncingSupportEmail = sendSyncingSupportEmail;
const addCrypto = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`support/add_crypto`, body);
});
exports.addCrypto = addCrypto;
// General feedback
const receiveFeedback = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`support/feedback`, body);
});
exports.receiveFeedback = receiveFeedback;
const sendBasicFeedback = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`support/basic_feedback`, body);
});
exports.sendBasicFeedback = sendBasicFeedback;
