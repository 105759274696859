"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_1 = require("@helpers/format");
const UserProvider_1 = require("@providers/UserProvider");
const tinycolor = require("tinycolor2");
const LineItem_1 = require("../global/LineItem");
const HorizontalBar = ({ budgetHistory, allIncome, allExpenses, totalRemainingRecurringExpense, totalRemainingRecurringIncome, incomeTotal, expenseTotal, colors, barSetting, startDate, endDate, openTable, }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [incomeStyles, setIncomeStyles] = (0, react_1.useState)([]);
    const [expenseStyles, setExpenseStyles] = (0, react_1.useState)([]);
    const MIN_WIDTH = 1.3;
    const getWidth = (category, type) => {
        let numerator = null;
        if (type == 'spent') {
            numerator = category.spent * (category.is_income ? -1 : 1);
        }
        else if (type == 'recurring_remaining') {
            numerator = category.recurring_remaining * (category.is_income ? -1 : 1);
        }
        if (numerator == 0) {
            return 0;
        }
        else {
            const final = Math.min(Math.max(MIN_WIDTH, (numerator / getDenominator(category)) * 100), 100);
            return parseFloat(final.toFixed(1));
        }
    };
    const getDenominator = category => {
        let results = null;
        if (category.is_income) {
            results = (incomeTotal + totalRemainingRecurringIncome) * -1;
        }
        else {
            results = expenseTotal + totalRemainingRecurringExpense;
        }
        return results;
    };
    (0, react_1.useEffect)(() => {
        setIncomeStyles(getStyles(allIncome, 'income'));
    }, [allIncome, incomeTotal, totalRemainingRecurringIncome]);
    (0, react_1.useEffect)(() => {
        setExpenseStyles(getStyles(allExpenses, 'expense'));
    }, [allExpenses, expenseTotal, totalRemainingRecurringExpense]);
    const getStyles = (data, type) => {
        const spentStyles = [];
        const remainingStyles = [];
        let total = 0;
        data.forEach((datum, index) => {
            const slicedColors = type == 'expense' ? colors : (0, format_1.reverseArray)(colors);
            const color = slicedColors[index % slicedColors.length];
            // Expenses in the database should always be positive
            spentStyles.push({
                backgroundColor: color,
                value: datum.spent,
                display: (type == 'expense'
                    ? datum.spent < 0
                    : datum.spent > 0)
                    ? 'none'
                    : 'block',
                category_id: datum.category_id,
                exclude_from_totals: datum.exclude_from_totals,
                tooltip: `You ${datum.is_income ? 'earned' : 'spent'} ${(0, format_1.toPrice)(datum.spent * (datum.is_income ? -1 : 1), _user.primaryCurrency)} in ${datum.category_name}`,
                width: Math.max(0, getWidth(datum, 'spent')) + '%',
            });
            total += getWidth(datum, 'spent');
            if (datum.recurring_remaining != 0) {
                remainingStyles.push({
                    background: `repeating-linear-gradient(
                    135deg,
                    ${tinycolor(color).lighten(30)},
                    ${tinycolor(color).lighten(30)} 5px,
                    ${tinycolor(color).lighten(20)} 5px,
                    ${tinycolor(color).lighten(20)} 10px
                  )`,
                    value: datum.recurring_remaining,
                    display: 'block',
                    tooltip: `You're projected to ${datum.is_income ? 'earn' : 'spend'} ${(0, format_1.toPrice)(datum.recurring_remaining * (datum.is_income ? -1 : 1), _user.primaryCurrency)} more ${_user.settings['allow_recurring_categories'] &&
                        `in ${datum.category_name}`} in recurring ${datum.is_income ? 'income' : 'expenses'}.`,
                    category_id: datum.category_id,
                    exclude_from_totals: datum.exclude_from_totals,
                    width: Math.max(0, getWidth(datum, 'recurring_remaining')) + '%',
                });
                total += getWidth(datum, 'recurring_remaining');
            }
        });
        return [...spentStyles, ...remainingStyles];
    };
    const getPills = (data, type) => {
        return data === null || data === void 0 ? void 0 : data.map((o, index) => {
            if (o.width == '0%' || o.display == 'none' || o.exclude_from_totals) {
                return;
            }
            const bar = (React.createElement("div", { id: `horizontal-bar-${type}-${index}`, key: `bar-section-${index}`, className: "bar-section", style: o, onMouseOver: () => {
                    document.querySelectorAll('.highlighted-row').forEach(el => {
                        el.classList.remove('highlighted-row');
                    });
                    try {
                        document
                            .getElementById(`spending-breakdown-${o.category_id}`)
                            .classList.add('highlighted-row');
                    }
                    catch (e) { }
                }, onMouseLeave: () => {
                    document.querySelectorAll('.highlighted-row').forEach(el => {
                        el.classList.remove('highlighted-row');
                    });
                } }));
            if (o.tooltip) {
                return (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", position: "top left", trigger: bar, key: `bar-section-popup-${index}` }, o.tooltip));
            }
            else {
                return bar;
            }
        });
    };
    return (React.createElement("div", { className: "horizontal-bar-container" },
        React.createElement("div", { className: "flex--space-between" },
            React.createElement("span", { className: "label" },
                React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", mouseEnterDelay: 200, trigger: React.createElement("span", { className: "link clickable", onClick: () => {
                            openTable({
                                start_date: startDate,
                                end_date: endDate,
                                is_income: true,
                                time: 'custom',
                                match: 'all',
                                exclude_from_totals: false,
                            });
                        } }, "Income") }, "View transactions (pop-up)"),
                React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem" }) },
                    React.createElement("p", null, "This is your total income across categories not marked as 'exclude from totals'."))),
            React.createElement("span", { className: "monospace ml-05rem" },
                React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement("span", { className: "color--green font--bold" }, (0, format_1.toPrice)(incomeTotal * -1, _user.primaryCurrency)), inverted: true, position: "top center", size: "small" }, "This is how much you've earned this period."),
                totalRemainingRecurringIncome != 0 && (React.createElement(React.Fragment, null,
                    ' ',
                    "/",
                    ' ',
                    React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement("span", { className: "color--black-text" }, (0, format_1.toPrice)((incomeTotal + totalRemainingRecurringIncome) * -1, _user.primaryCurrency)), inverted: true, size: "small", position: "top right" },
                        "This is your total projected earning. So far you have earned",
                        ' ',
                        (0, format_1.toPrice)(incomeTotal * -1, _user.primaryCurrency),
                        " and you have",
                        ' ',
                        (0, format_1.toPrice)(totalRemainingRecurringIncome * -1, _user.primaryCurrency),
                        ' ',
                        "left in recurring income."))))),
        React.createElement("div", { className: "horizontal-bar-row" },
            React.createElement("div", { className: "horizontal-bar", style: {
                    width: barSetting == 'relative'
                        ? Math.abs(incomeTotal + totalRemainingRecurringIncome) >
                            Math.abs(expenseTotal + totalRemainingRecurringExpense)
                            ? '100%'
                            : Math.max(2, (Math.abs(incomeTotal + totalRemainingRecurringIncome) /
                                Math.abs(expenseTotal + totalRemainingRecurringExpense)) *
                                100) + '%'
                        : '100%',
                } }, getPills(incomeStyles, 'income'))),
        React.createElement("div", { className: "flex--space-between" },
            React.createElement("span", { className: "label" },
                React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", mouseEnterDelay: 200, trigger: React.createElement("span", { className: "link clickable", onClick: () => {
                            openTable({
                                start_date: startDate,
                                end_date: endDate,
                                is_income: false,
                                time: 'custom',
                                match: 'all',
                                exclude_from_totals: false,
                            });
                        } }, "Expenses") }, "View transactions (pop-up)"),
                React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem" }) },
                    React.createElement("p", null, "This is your total spending across categories not marked as 'exclude from totals'."))),
            React.createElement("span", { className: "monospace ml-05rem" },
                React.createElement(semantic_ui_react_1.Popup, { position: "top center", trigger: React.createElement("span", { className: "font--bold" }, (0, format_1.toPrice)(expenseTotal, _user.primaryCurrency)), 
                    // hoverable
                    inverted: true, size: "small" },
                    "This is how much you've spent this period.",
                    React.createElement(LineItem_1.default, { keyClass: "no-label", keyValue: 'Expenses', propertyValue: (0, format_1.toPrice)(budgetHistory.totals.expenses, _user.primaryCurrency), inPopup: true }),
                    React.createElement(LineItem_1.default, { keyClass: "no-label", keyValue: 'Recurring Expenses', propertyValue: (0, format_1.toPrice)(budgetHistory.totals.actual_recurring_expenses, _user.primaryCurrency), inPopup: true }),
                    React.createElement(LineItem_1.default, { keyClass: "no-label", keyValue: 'Uncategorized Expenses', propertyValue: (0, format_1.toPrice)(budgetHistory.totals.uncategorized_expenses, _user.primaryCurrency), inPopup: true }),
                    React.createElement(LineItem_1.default, { keyClass: "no-label", keyValue: 'Total', propertyValue: (0, format_1.toPrice)(budgetHistory.totals.expenses +
                            budgetHistory.totals.actual_recurring_expenses +
                            budgetHistory.totals.uncategorized_expenses, _user.primaryCurrency), inPopup: true })),
                totalRemainingRecurringExpense != 0 && (React.createElement(React.Fragment, null,
                    ' ',
                    "/",
                    ' ',
                    React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement("span", { className: "color--black-text" }, (0, format_1.toPrice)(expenseTotal + totalRemainingRecurringExpense, _user.primaryCurrency)), inverted: true, size: "small" },
                        "This is your total projected spending. So far you have spent",
                        ' ',
                        (0, format_1.toPrice)(expenseTotal, _user.primaryCurrency),
                        " and you have",
                        ' ',
                        (0, format_1.toPrice)(totalRemainingRecurringExpense, _user.primaryCurrency),
                        ' ',
                        "left in recurring expenses."))))),
        React.createElement("div", { className: "horizontal-bar-row" },
            React.createElement("div", { className: "horizontal-bar", style: {
                    width: barSetting == 'relative'
                        ? Math.abs(expenseTotal + totalRemainingRecurringExpense) >
                            Math.abs(incomeTotal + totalRemainingRecurringIncome)
                            ? '100%'
                            : Math.max(2, (Math.abs(expenseTotal + totalRemainingRecurringExpense) /
                                Math.abs(incomeTotal + totalRemainingRecurringIncome)) *
                                100) + '%'
                        : '100%',
                } }, getPills(expenseStyles, 'expense')))));
};
exports.default = HorizontalBar;
