"use strict";
/**
 *  ImportTransactions.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const transactions_1 = require("@actions/transactions");
const format_1 = require("@helpers/format");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const Settings_1 = require("@components/CSV/Settings");
const Assign_1 = require("@components/CSV/Assign");
const Upload_1 = require("@components/CSV/Upload");
const Review_1 = require("@components/CSV/Review");
const Finish_1 = require("@components/CSV/Finish");
const StaticProvider_1 = require("@providers/StaticProvider");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const TagsProvider_1 = require("@/providers/TagsProvider");
const UserProvider_1 = require("@/providers/UserProvider");
var IMPORT_STEPS;
(function (IMPORT_STEPS) {
    IMPORT_STEPS[IMPORT_STEPS["Upload"] = 1] = "Upload";
    IMPORT_STEPS[IMPORT_STEPS["Assign"] = 2] = "Assign";
    IMPORT_STEPS[IMPORT_STEPS["Settings"] = 3] = "Settings";
    IMPORT_STEPS[IMPORT_STEPS["Review"] = 4] = "Review";
    IMPORT_STEPS[IMPORT_STEPS["Finish"] = 5] = "Finish";
})(IMPORT_STEPS || (IMPORT_STEPS = {}));
const ImportTransactions = ({ _process, _showToast }) => {
    const _static = (0, react_1.useContext)(StaticProvider_1.StaticContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const _tags = (0, react_1.useContext)(TagsProvider_1.TagsContext);
    // General
    const [currentConfig, setCurrentConfig] = (0, react_1.useState)(null);
    const [step, setStep] = (0, react_1.useState)(IMPORT_STEPS['Upload']);
    const [account, setAccount] = (0, react_1.useState)(null);
    // Step 1: Upload
    const [rawData, setRawData] = (0, react_1.useState)(null);
    // Step 2: Assign
    const [indexedData, setIndexedData] = (0, react_1.useState)([]);
    // Step 3: Settings
    const [reviewableData, setReviewableData] = (0, react_1.useState)(null);
    // Final state
    const [insertResults, setInsertResults] = (0, react_1.useState)(null);
    const [settingsStartAtLastStep, setSettingsStartAtLastStep] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        document.title = 'Import CSV - Lunch Money';
        setCurrentConfig({
            _skip_duplicates: true,
            _apply_category_rules: true,
            _set_reviewed: true,
            _currency: _user.primaryCurrency,
        });
    }, []);
    (0, react_1.useEffect)(() => {
        if (step == IMPORT_STEPS['Upload']) {
            // Reset everything
            setAccount(null);
            setCurrentConfig({
                _skip_duplicates: true,
                _apply_category_rules: true,
                _set_reviewed: true,
                _currency: _user.primaryCurrency,
            });
            setRawData(null);
            setIndexedData([]);
            setReviewableData(null);
            setInsertResults(null);
            setSettingsStartAtLastStep(false);
        }
        window.scrollTo(0, 0);
    }, [step]);
    (0, react_1.useEffect)(() => {
        if (reviewableData) {
            setStep(IMPORT_STEPS['Review']);
        }
    }, [reviewableData]);
    /**
     * Send everything to server
     */
    const submit = (insertableData) => __awaiter(void 0, void 0, void 0, function* () {
        const insertObjs = insertableData.map(o => {
            return {
                date: o.date ? o.date.trim() : null,
                amount: o.amount,
                category_id: o.category_id || null,
                currency: o.currency,
                payee: o.payee,
                notes: o.notes,
                tags: o.tags,
                asset_id: o.asset_id == 'cash' ? null : o.asset_id,
                plaid_account_id: o.plaid_account_id,
                status: o.status,
                recurring_id: o.recurring_id,
                original_name: o.original_name,
                split: o.split,
            };
        });
        if (insertObjs.length > 0) {
            const results = yield _process(transactions_1.bulkInsertTransactions)({
                transactions: insertObjs,
                // should convert: false
            });
            if (!results.error) {
                setInsertResults(results.data);
            }
        }
        else {
            setInsertResults({ transactions: [], total_count: 0, asset_update: [] });
        }
        setStep(IMPORT_STEPS['Finish']);
    });
    const separateCorruptAndGoodData = data => {
        const corruptLines = [];
        const passData = [];
        for (let index = 0; index < data.length; index++) {
            const tx = data[index];
            try {
                // Set the date
                tx.date = Moment(tx.original_date, (currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._date_format) || null).format('YYYY-MM-DD');
                if (!tx ||
                    !tx.date ||
                    tx.date == '' ||
                    tx.date == 'Invalid date' ||
                    isNaN(parseFloat(tx.amount))) {
                    corruptLines.push(Object.assign({ id: index }, tx));
                    continue;
                }
                passData.push(Object.assign({ id: index }, tx));
            }
            catch (e) {
                corruptLines.push(Object.assign({ id: index }, data[index]));
                continue;
            }
        }
        return { good: passData, bad: corruptLines };
    };
    // From the CSV, build a general transactions object
    // Do this after we have all our indices
    const buildTransactionsFromIndices = (isMint = false) => {
        var _a, _b, _c;
        const _getIndex = (column, override = null) => {
            var _a;
            const match = (_a = (override || (currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._indices))) === null || _a === void 0 ? void 0 : _a.find(o => {
                return o.column == column;
            });
            if (match) {
                return Number(match.index);
            }
            else {
                return null;
            }
        };
        // Get all possible indices
        const dateIndex = _getIndex('date');
        const payeeIndex = _getIndex('payee');
        const notesIndex = _getIndex('notes');
        const amountIndex = _getIndex('amount');
        const accountIndex = _getIndex('account');
        const typeIndex = _getIndex('type');
        const inflowIndex = _getIndex('inflow');
        const outflowIndex = _getIndex('outflow');
        const categoryIndex = _getIndex('category');
        const currencyIndex = _getIndex('currency');
        const tagsIndex = _getIndex('tags');
        const originalNameIndex = _getIndex('original_name');
        const allTransactions = rawData.map((datum, index) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            try {
                // Set the amount
                let amount;
                if (!!inflowIndex && !!outflowIndex) {
                    // double
                    amount = (0, format_1.parseNumber)(datum[inflowIndex])
                        ? -1 * Math.abs((0, format_1.parseNumber)(datum[inflowIndex]))
                        : Math.abs((0, format_1.parseNumber)(datum[outflowIndex]));
                }
                else if (!!typeIndex && !!amountIndex) {
                    if (((_a = datum[typeIndex]) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'debit' ||
                        ((_b = datum[typeIndex]) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === 'charge' ||
                        ((_c = datum[typeIndex]) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === 'debet' ||
                        ((_d = datum[typeIndex]) === null || _d === void 0 ? void 0 : _d.toLowerCase()) === 'outflow' ||
                        ((_e = datum[typeIndex]) === null || _e === void 0 ? void 0 : _e.toLowerCase()) === 'af' ||
                        ((_f = datum[typeIndex]) === null || _f === void 0 ? void 0 : _f.toLowerCase()) === 'dr' ||
                        ((_g = datum[typeIndex]) === null || _g === void 0 ? void 0 : _g.toLowerCase()) === 'd' ||
                        ((_h = datum[typeIndex]) === null || _h === void 0 ? void 0 : _h.toLowerCase()) === 's' ||
                        ((_j = datum[typeIndex]) === null || _j === void 0 ? void 0 : _j.toLowerCase()[0]) === 'd' ||
                        ((_k = datum[typeIndex]) === null || _k === void 0 ? void 0 : _k.toLowerCase()) === 'charge') {
                        amount = Math.abs((0, format_1.parseNumber)(datum[amountIndex]));
                    }
                    else {
                        amount = -1 * Math.abs((0, format_1.parseNumber)(datum[amountIndex]));
                    }
                }
                else if (!!amountIndex) {
                    amount = (0, format_1.parseNumber)(datum[amountIndex]);
                }
                let _currency = currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._currency;
                const verifiedCurrencies = [];
                if (datum[currencyIndex]) {
                    const custom = datum[currencyIndex].trim().toLowerCase();
                    // Check to make sure this is a valid currency
                    if (verifiedCurrencies.indexOf(custom) > -1) {
                        _currency = custom;
                    }
                    else {
                        const match = _static.allCurrencies.find(o => o.value == custom);
                        if (match) {
                            verifiedCurrencies.push(match.value);
                            _currency = custom;
                        }
                    }
                }
                return Object.assign(Object.assign({ status: (currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._set_reviewed) ? 'cleared' : 'uncleared', date: datum[dateIndex], original_date: datum[dateIndex], payee: datum[payeeIndex]
                        ? datum[payeeIndex].trim()
                        : datum[payeeIndex], amount: amount, currency: _currency, category_original: datum[categoryIndex] || null, category_id: datum[categoryIndex] ? null : null, original_name: datum[originalNameIndex] || null, tags_original: datum[tagsIndex] || null }, ((account === null || account === void 0 ? void 0 : account.source)
                    ? {
                        [(account === null || account === void 0 ? void 0 : account.source) == 'plaid' ? 'plaid_account_id' : 'asset_id']: (account === null || account === void 0 ? void 0 : account.id) == -1 ? null : account === null || account === void 0 ? void 0 : account.id,
                    }
                    : { account_original: datum[accountIndex] || 'cash' })), { notes: datum[notesIndex]
                        ? datum[notesIndex].trim()
                        : datum[notesIndex] });
            }
            catch (e) {
                console.log('Error in building transaction', datum);
            }
        });
        // After indices, begin to construct the currentConfig that Settings will build on
        const _categoryMatches = {};
        const _tagMatches = {};
        const _accountMatches = {};
        for (let index = 1; index < allTransactions.length; index++) {
            const tx = allTransactions[index];
            // Build categories match object
            if (!!((_a = currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._indices) === null || _a === void 0 ? void 0 : _a.find(o => o.column == 'category')) &&
                tx.category_original) {
                const categoryName = tx.category_original.trim();
                // Any exact matches?
                const match = _categories.assignable.find(cat => {
                    return cat.name.trim() == categoryName;
                });
                _categoryMatches[tx.category_original.trim()] = (match === null || match === void 0 ? void 0 : match.id) || null;
            }
            // Build tags match object
            if (!!((_b = currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._indices) === null || _b === void 0 ? void 0 : _b.find(o => o.column == 'tags')) &&
                tx.tags_original) {
                tx.tags_original
                    .toString()
                    .split(',')
                    .forEach(tag => {
                    const tagName = tag === null || tag === void 0 ? void 0 : tag.trim();
                    // Any exact matches?
                    const match = _tags.tags.find(tag => {
                        var _a;
                        return ((_a = tag.name) === null || _a === void 0 ? void 0 : _a.trim()) == tagName;
                    });
                    _tagMatches[tagName] = (match === null || match === void 0 ? void 0 : match.id) || null;
                });
            }
            // Build accounts match object
            if (!account && // No selected account
                !!((_c = currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._indices) === null || _c === void 0 ? void 0 : _c.find(o => o.column == 'account')) &&
                tx.account_original) {
                _accountMatches[tx.account_original] = null;
            }
            else if (!account) {
                // If there's just no account and no mention of account, allow the user the opportunity to create a new account
                _accountMatches['cash'] = null;
            }
        }
        setCurrentConfig(Object.assign(Object.assign({ _originalCategories: (currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._categories) || {}, _originalTags: (currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._tags) || {} }, currentConfig), { _tags: _tagMatches, _categories: _categoryMatches, _accounts: _accountMatches }));
        setIndexedData(allTransactions);
    };
    return (React.createElement(semantic_ui_react_1.Container, { className: "g-transactions", id: "g-import" },
        React.createElement(ContainerHeader_1.default, { title: "Import Transactions via CSV" }),
        step == 1 && (React.createElement(Upload_1.default, { setAccount: setAccount, setCurrentConfig: setCurrentConfig, _showToast: _showToast, rawData: rawData, setRawData: setRawData, goNext: ({ selection, useMatchedConfig }) => {
                if (useMatchedConfig) {
                    try {
                        buildTransactionsFromIndices();
                        setStep(IMPORT_STEPS['Settings']);
                    }
                    catch (e) {
                        setStep(IMPORT_STEPS['Assign']);
                    }
                }
                else if (selection == 'mint') {
                    buildTransactionsFromIndices(true);
                    setStep(IMPORT_STEPS['Settings']);
                }
                else {
                    setStep(IMPORT_STEPS['Assign']);
                }
            } })),
        step == 2 && (React.createElement("div", { className: "", style: { overflowX: 'hidden', marginBottom: '2rem' } },
            React.createElement(Assign_1.default, { hasSelectedAccount: !!(account === null || account === void 0 ? void 0 : account.id), rawData: rawData, currentConfig: currentConfig, setCurrentConfig: setCurrentConfig, setIndexValue: (index, value) => {
                    var _a;
                    // Remove any current definitions for index and value
                    const filteredIndices = ((_a = currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._indices) === null || _a === void 0 ? void 0 : _a.filter(o => {
                        // If it's amount, then make sure inflow/outflow is not selected
                        // If it's type, make sure inflow/outflow is not selected
                        // if it's inflow/outflow, make sure amount and type are not selected
                        if (value == 'amount' || value == 'type') {
                            return (o.index !== index &&
                                o.column !== value &&
                                o.column !== 'inflow' &&
                                o.column !== 'outflow');
                        }
                        else if (value == 'inflow' || value == 'outflow') {
                            return (o.index !== index &&
                                o.column !== value &&
                                o.column !== 'amount' &&
                                o.column !== 'type');
                        }
                        return o.index !== index && o.column !== value;
                    })) || [];
                    // Add current definition
                    filteredIndices.push({
                        index: index,
                        column: value,
                    });
                    setCurrentConfig(Object.assign(Object.assign({}, currentConfig), { _indices: filteredIndices }));
                }, indices: currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._indices, goBack: () => {
                    setStep(step - 1);
                }, goNext: () => {
                    buildTransactionsFromIndices();
                    setStep(IMPORT_STEPS['Settings']);
                } }))),
        step == 3 && (React.createElement(Settings_1.default, { data: indexedData, currentConfig: currentConfig, setCurrentConfig: setCurrentConfig, separateCorruptAndGoodData: separateCorruptAndGoodData, goNext: ({ data, tagMatches, categoryMatches, accountMatches }) => {
                setReviewableData(data);
                setSettingsStartAtLastStep(false);
                setCurrentConfig(Object.assign(Object.assign({}, currentConfig), { _tags: tagMatches, _categories: categoryMatches, _accounts: accountMatches }));
            }, goBack: () => {
                setStep(step - 1);
            }, selectedAccount: account, startAtLastStep: settingsStartAtLastStep })),
        step == 4 && (React.createElement(Review_1.default, { _showToast: _showToast, data: reviewableData, currentConfig: currentConfig, goBack: () => {
                setSettingsStartAtLastStep(true);
                setStep(step - 1);
            }, goNext: insertableData => {
                submit(insertableData);
            } })),
        step == 5 && (React.createElement(Finish_1.default, { _process: _process, _showToast: _showToast, insertResults: insertResults, currentConfig: currentConfig, startOver: () => {
                setStep(IMPORT_STEPS['Upload']);
            }, goBack: () => {
                setSettingsStartAtLastStep(true);
                setStep(IMPORT_STEPS['Settings']);
            } }))));
};
exports.default = ImportTransactions;
