"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const LineItem = ({ keyValue, propertyValue, lineItemClass = '', keyClass = '', propertyClass = '', isNested = false, inPopup = false, hideUnderline = false, onClick = () => { }, }) => (React.createElement("div", { className: `line-item ${lineItemClass}`, onClick: onClick },
    React.createElement("div", { className: `key ${keyClass}` },
        isNested && React.createElement("span", { className: "hierarchy-line-icon-5" }),
        keyValue),
    !hideUnderline && (React.createElement("div", { className: `underline ${inPopup ? 'in-popup' : ''}` })),
    React.createElement("div", { className: `property ${propertyClass}` }, propertyValue)));
exports.default = LineItem;
