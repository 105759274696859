"use strict";
/**
 *  Referral.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const react_1 = require("react");
const referral_1 = require("@actions/referral");
const semantic_ui_react_1 = require("semantic-ui-react");
const BillingProvider_1 = require("@providers/BillingProvider");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const Referral = ({ _process, _showToast }) => {
    var _a;
    const _billing = (0, react_1.useContext)(BillingProvider_1.BillingContext);
    const isFreeAccount = ((_a = _billing === null || _billing === void 0 ? void 0 : _billing.subscription) === null || _a === void 0 ? void 0 : _a.user_type) == 'early-adopter';
    const referralRef = (0, react_1.useRef)(null);
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    const [token, setToken] = (0, react_1.useState)(null);
    const [numActive, setNumActive] = (0, react_1.useState)(null);
    const [referred, setReferred] = (0, react_1.useState)([]);
    const [email, setEmail] = (0, react_1.useState)('');
    const [buttonLoading, setButtonLoading] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = 'Referral - Lunch Money';
        getReferrals();
    }, []);
    const getReferrals = () => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield _process(referral_1.getReferred)();
        setNumActive(results.count_active);
        setReferred(results.users);
        setToken(results.token);
        setIsLoading(false);
    });
    return (React.createElement(semantic_ui_react_1.Container, null,
        React.createElement(ContainerHeader_1.default, { title: "Referral" }),
        React.createElement("div", { className: "g-referral" },
            React.createElement("div", { className: "left" },
                React.createElement(semantic_ui_react_1.Message, { info: true },
                    React.createElement(semantic_ui_react_1.Message.Header, { className: "mb-05rem" }, "Help us spread the word about Lunch Money!"),
                    React.createElement(semantic_ui_react_1.Message.Content, null,
                        isFreeAccount ? (React.createElement("p", null, "Refer a friend to give them one free month of Lunch Money after their trial ends! Pst\u2013 you already have Lunch Money for free for life!")) : (React.createElement(React.Fragment, null,
                            React.createElement("p", { className: "mt-05rem" }, "Your friend will receive one free month after their trial. For every friend who signs up after the free month and stays active, you will receive a stackable 10% discount on your next invoice."),
                            React.createElement("p", null, "For instance, if two of your referred friends have subscribed to a plan, you will receive 20% off all future invoices as long as they stay active!"))),
                        React.createElement("p", null,
                            React.createElement("a", { href: "https://lunchmoney.app/referral", className: "link clickable", target: "_blank" }, "Click here to read more about the referral program.")))),
                React.createElement(semantic_ui_react_1.Card, { fluid: true },
                    React.createElement(semantic_ui_react_1.Card.Content, { header: "Refer friends" }),
                    React.createElement(semantic_ui_react_1.Card.Content, null,
                        React.createElement("p", { className: "center-align label-style" }, "Invite via referral link"),
                        token ? (React.createElement(semantic_ui_react_1.Form, null,
                            React.createElement(semantic_ui_react_1.Form.Group, { className: "mb-1rem" },
                                React.createElement(semantic_ui_react_1.Form.Field, { className: "flex--align-center display--flex width-100" },
                                    React.createElement(semantic_ui_react_1.Input, { fluid: true, ref: referralRef, onClick: () => {
                                            referralRef.current.select();
                                        }, value: `https://lunchmoney.app/?refer=${token}`, icon: document.queryCommandSupported('copy') && (React.createElement(semantic_ui_react_1.Icon, { onClick: () => {
                                                referralRef.current.select();
                                                document.execCommand('copy');
                                                _showToast({
                                                    message: 'Copied referral link to clipboard!',
                                                    type: 'success',
                                                });
                                            }, name: "copy outline", fitted: true, link: true })) }))))) : (React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "tiny", className: "mb-1rem" })),
                        React.createElement("p", { className: "divider-line" },
                            React.createElement("span", null, "or")),
                        React.createElement("p", { className: "center-align label-style" }, "Invite via email"),
                        React.createElement(semantic_ui_react_1.Form, null,
                            React.createElement(semantic_ui_react_1.Form.Group, null,
                                React.createElement(semantic_ui_react_1.Form.Input, { className: "width-100", fluid: true, placeholder: "your_friend@email.com", value: email, onChange: (e, { value }) => {
                                        setEmail(value);
                                    }, action: {
                                        color: 'orange',
                                        content: 'Send',
                                        disabled: buttonLoading,
                                        loading: buttonLoading,
                                        onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                            setButtonLoading(true);
                                            const results = yield _process(referral_1.referByEmail)(email);
                                            if (results.data &&
                                                results.data.message &&
                                                results.data.type) {
                                                _showToast({
                                                    message: results.data.message,
                                                    type: results.data.type,
                                                });
                                                setEmail('');
                                            }
                                            setButtonLoading(false);
                                        }),
                                    } })))))),
            React.createElement("div", { className: "right" },
                React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(semantic_ui_react_1.Card, { fluid: true },
                        React.createElement(semantic_ui_react_1.Card.Content, { header: React.createElement("div", { className: "header" },
                                "Current referrals",
                                ' ',
                                React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "Friends you've referred who haven't signed up yet will not show up here!")) }),
                        React.createElement(semantic_ui_react_1.Card.Content, null,
                            isLoading && React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "tiny" }),
                            !isLoading && !isFreeAccount && !!numActive && (React.createElement(semantic_ui_react_1.Message, { success: true },
                                "You currently have ",
                                React.createElement("b", null, numActive),
                                " active referral",
                                numActive > 1 ? 's' : '',
                                ", totaling a discount of",
                                ' ',
                                Math.min(numActive * 10, 100),
                                "% off your invoices!")),
                            !isLoading && (referred === null || referred === void 0 ? void 0 : referred.length) == 0 && (React.createElement("p", null, "You have no referrals yet!")),
                            !isLoading &&
                                (referred === null || referred === void 0 ? void 0 : referred.map((user, index) => (React.createElement("div", { className: "referred", key: `referred-${index}` },
                                    React.createElement("div", null,
                                        React.createElement("span", { className: "info" },
                                            index + 1,
                                            ". ",
                                            user.name)),
                                    React.createElement("span", { className: "join" },
                                        "Joined ",
                                        Moment().to(user.join_date),
                                        ' ',
                                        React.createElement("span", { className: `status ${user.status}` },
                                            React.createElement(semantic_ui_react_1.Icon, { name: user.status == 'trialing'
                                                    ? 'clock'
                                                    : 'check circle', fitted: true }),
                                            ' ',
                                            user.status)))))))))))));
};
exports.default = Referral;
