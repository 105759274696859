"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInteractiveTooltip = exports.getLineChartTooltip = exports.getBarChartTooltip = exports.getTrendsTooltip = void 0;
/** Interactive.tsx tooltip */
const format_1 = require("@helpers/format");
/**
 *  Stats year - bar chart
 */
const getTrendsTooltip = (chartContext, tooltip, tooltipData, tooltipIdentifier) => {
    var _a, _b, _c, _d;
    const tooltipEl = document.getElementById(`tooltip-year-${tooltipIdentifier}`);
    const positionY = chartContext.canvas.offsetTop;
    const positionX = chartContext.canvas.offsetLeft;
    const canvasHeight = chartContext.canvas.scrollHeight;
    if (!tooltip) {
        tooltipEl.style.display = 'none';
        return;
    }
    if (!tooltip.dataPoints || tooltip.dataPoints.length == 0) {
        // outside of range
        tooltipEl.style.display = 'none';
        return;
    }
    const index = tooltip.dataPoints[0].dataIndex;
    if (!tooltipData.data[index]) {
        tooltipEl.style.display = 'none';
        // Nodata
        return;
    }
    else {
        if (!((_a = tooltipData.data[index].map(o => o.rawValue)) === null || _a === void 0 ? void 0 : _a.find(o => o !== null))) {
            // Really no data
            tooltipEl.style.display = 'none';
            return;
        }
    }
    let content = '';
    for (let i = 0; i < tooltipData.data[index].length; i++) {
        const percentChange = index > 0
            ? _getPercentChange(((_b = tooltipData.data[index - 1][i]) === null || _b === void 0 ? void 0 : _b.rawValue) || null, ((_c = tooltipData.data[index][i]) === null || _c === void 0 ? void 0 : _c.rawValue) || null)
            : null;
        content += `<div class='flex--space-between-flex-start tooltip-data-row'>
        <div class="flex--align-center">
          ${tooltipData.data[index][i].color
            ? `<div class="tooltip-circle" style="background-color:${tooltipData.data[index][i].color};"></div>`
            : ''}
          <span class="font--bold">${tooltipData.data[index][i].key}</span></div>
        <div class="display--flex">
          <span class="monospace number no-wrap ${tooltipData.data[index][i].link ? 'mr-05rem' : ''}">${percentChange
            ? `<span class="color--${percentChange['color']}">(${percentChange['percentChangeAsset']})</span>`
            : ''} ${tooltipData.data[index][i].value}</span>
          ${tooltipData.data[index][i].link
            ? `<a href="${tooltipData.data[index][i].link}" target="_blank"><i aria-hidden="true" class="fitted external icon clickable link"></i></a>`
            : ''}
        </div>
      </div>`;
    }
    // Links
    let linkEl = '';
    (_d = tooltipData.links[index]) === null || _d === void 0 ? void 0 : _d.forEach(link => {
        if (link.display) {
            linkEl += `<a href="${link.url}" target="_blank" class="link">${link.text} <i class="external icon"></i></a>`;
        }
    });
    tooltipEl.innerHTML = `<p class='header-text flex--space-between'>
      <span>${tooltipData.header[index]}</span>

    </p>
    ${content}
    <p class="footer-text">${linkEl}</p>`;
    tooltipEl.style.display = 'block';
    const tooltipWidth = tooltipEl.scrollWidth;
    const tooltipHeight = tooltipEl.scrollHeight;
    // Figure out the padding
    const widthOfEachSection = (chartContext.chartArea.right - chartContext.chartArea.left) /
        tooltipData.data.length;
    const startOfChart = chartContext.chartArea.left;
    // Get the closest
    let current = startOfChart;
    while (current < tooltip.dataPoints[tooltip.dataPoints.length - 1].element.x) {
        current += widthOfEachSection;
    }
    const defaultLeft = positionX +
        current +
        // tooltip.dataPoints[tooltip.dataPoints.length - 1].x +
        tooltipWidth / 2 -
        2;
    const alternateLeft = positionX +
        current -
        widthOfEachSection -
        // tooltip.dataPoints[tooltip.dataPoints.length > 1 ? 1 : 0].x -
        tooltipWidth / 2 +
        2;
    const absPositionX = document.getElementById(`year-stats-container`)
        .offsetLeft;
    // if (defaultLeft + tooltipWidth + 100 > parseInt(canvasWidth)) {
    if (alternateLeft - 50 - absPositionX > 0) {
        tooltipEl.style.left = alternateLeft + 'px';
    }
    else {
        tooltipEl.style.left = defaultLeft + 'px';
    }
    const dataPointsWithValue = tooltip.dataPoints.filter(o => {
        return !!parseInt(o.value);
    });
    const averageY = dataPointsWithValue.reduce((acc, cur) => {
        return acc + cur.y;
    }, 0) / dataPointsWithValue.length;
    tooltipEl.style.top =
        positionY + (averageY || canvasHeight / 2) - tooltipHeight / 2 + 'px';
};
exports.getTrendsTooltip = getTrendsTooltip;
/**
 *  Main page - bar chart (income vs spending [daily, monthly])
 */
const getBarChartTooltip = (chartRef, _displayCurrency, urlDateArray, allowRecurringCategories) => {
    return function (tooltip) {
        const positionY = chartRef.chartInstance.canvas.offsetTop;
        const positionX = chartRef.chartInstance.canvas.offsetLeft;
        if (!tooltip) {
            return;
        }
        if (!tooltip.dataPoints || tooltip.dataPoints.length == 0) {
            // outside of range
            return;
        }
        // [0: Income]
        // [1: Recurring expenses]
        // [2: Daily spending]
        const incomeBar = tooltip.dataPoints[0];
        const firstBar = tooltip.dataPoints[1];
        const secondBar = tooltip.dataPoints[2];
        if (incomeBar) {
            const url_date = urlDateArray[tooltip.dataPoints[0].index];
            const url_base = '/transactions/';
            const url_filter = '?is_income=true';
            const tooltipEl = document.getElementById(`tooltip-bar-0`);
            tooltipEl.innerHTML = `<p class='header flex--space-between-flex-start expanded-header'>${incomeBar.xLabel} <a href="${url_base}${url_date}${url_filter}">View</a></p>
          <span class="trends-tooltip-row ">You earned ${(0, format_1.toPrice)(incomeBar.yLabel, _displayCurrency)}.</span>`;
            tooltipEl.style.opacity = '1';
            tooltipEl.style.display = 'block';
            // Show up to the left of the bar...
            tooltipEl.style.left =
                positionX + incomeBar.x - tooltipEl.offsetWidth / 2 - 13 + 'px';
            tooltipEl.style.top = positionY + incomeBar.y + 'px';
        }
        if (firstBar || secondBar) {
            const primaryBar = !!secondBar ? secondBar : firstBar;
            const url_date = urlDateArray[tooltip.dataPoints[0].index];
            const url_base_tx = '/transactions/';
            const url_base_rc = '/recurring/';
            let content = '';
            if (!allowRecurringCategories && firstBar && secondBar) {
                content = `<span class='flex--space-between-flex-start trends-tooltip-row less-padding'>
          <span class='label'>Recurring items</span>
          <span class='text'>${(0, format_1.toPrice)(firstBar.yLabel, _displayCurrency)}</span>
        </span>
        <span class='flex--space-between-flex-start trends-tooltip-row less-padding'>
          <span class='label'>Daily spending</span>
          <span class='text'>${(0, format_1.toPrice)(secondBar.yLabel, _displayCurrency)}</span>
        </span>
        <span class='divider flex--space-between-flex-start trends-tooltip-row'>
          <span class='label'>You spent</span>
          <span class='text'>${(0, format_1.toPrice)(firstBar.yLabel + secondBar.yLabel, _displayCurrency)}</span>
        </span>`;
            }
            else if (!allowRecurringCategories && firstBar) {
                content = `<span class='text trends-tooltip-row '>You spent ${(0, format_1.toPrice)(firstBar.yLabel, _displayCurrency)} on recurring items.</span>`;
            }
            else if (!allowRecurringCategories && secondBar) {
                content = `<span class='text trends-tooltip-row '>You spent ${(0, format_1.toPrice)(secondBar.yLabel, _displayCurrency)}.</span>`;
            }
            else if (allowRecurringCategories && firstBar) {
                content = `<span class='text trends-tooltip-row '>You spent ${(0, format_1.toPrice)(firstBar.yLabel, _displayCurrency)}.</span>`;
            }
            const tooltipEl = document.getElementById(`tooltip-bar-1`);
            tooltipEl.innerHTML = `<p class='expanded-header flex--space-between-flex-start'>${primaryBar.xLabel} <a href="${url_base_tx}${url_date}">View</a></p>${content}`;
            tooltipEl.style.display = 'block';
            // Or to the right of the bar...
            tooltipEl.style.left =
                positionX + primaryBar.x + tooltipEl.offsetWidth / 2 + 13 + 'px';
            tooltipEl.style.top = positionY + primaryBar.y + 'px';
        }
    };
};
exports.getBarChartTooltip = getBarChartTooltip;
/**
 *  Main page - line chart (overall budgeted vs spending)
 */
const getLineChartTooltip = (chartRef, _displayCurrency, urlDateArray) => {
    return function (tooltip) {
        const positionY = chartRef.chartInstance.canvas.offsetTop;
        const positionX = chartRef.chartInstance.canvas.offsetLeft;
        if (!tooltip) {
            return;
        }
        if (!tooltip.dataPoints) {
            // outside of range
            return;
        }
        if (tooltip.dataPoints.length > 0) {
            // Should we invert?
            let invert = false;
            if (tooltip.dataPoints.length > 1) {
                const diff = Math.abs(tooltip.dataPoints[0].y - tooltip.dataPoints[1].y);
                if (tooltip.dataPoints[0].y > tooltip.dataPoints[1].y) {
                    invert = false;
                }
                else if (diff > 10 && diff < 120) {
                    invert = true;
                }
            }
            tooltip.dataPoints.forEach(function (dataPoint) {
                const url_date = urlDateArray[dataPoint.index];
                // Budgeted dataset
                if (dataPoint.datasetIndex === 0) {
                    const url_base = '/budget/';
                    var content = `<p class='expanded-header flex--space-between-flex-start'>${dataPoint.xLabel} <a href="${url_base}${url_date}">View</a></p>
            <span class='text expanded-tooltip-row'>You budgeted ${(0, format_1.toPrice)(dataPoint.yLabel, _displayCurrency)}</span>`;
                }
                else {
                    // Spent dataset
                    // Build url
                    const url_base = '/transactions/';
                    var content = `<p class='expanded-header flex--space-between-flex-start'>${dataPoint.xLabel} <a href="${url_base}${url_date}">View</a></p><span class='expanded-tooltip-row text'>You spent ${(0, format_1.toPrice)(dataPoint.yLabel, _displayCurrency)}</span>`;
                }
                var $tooltip = document.getElementById('tooltip-' + dataPoint.datasetIndex);
                document.getElementById('tooltip-' + dataPoint.datasetIndex).innerHTML = content;
                $tooltip.style.opacity = '1';
                $tooltip.style.display = 'block';
                if (invert ? dataPoint.datasetIndex !== 1 : dataPoint.datasetIndex === 1) {
                    $tooltip.style.top = positionY + dataPoint.y - 60 + 'px';
                }
                else {
                    $tooltip.style.top = positionY + dataPoint.y + 8 + 'px';
                }
                $tooltip.style.left = positionX + dataPoint.x + 'px';
            });
        }
    };
};
exports.getLineChartTooltip = getLineChartTooltip;
/**
 *  Expanded budget tooltip
 */
const getInteractiveTooltip = (chartRef, query, _displayCurrency, setTransactionMonth, budgetedDisplayAmount, setBudgetedDisplayAmount, spentDisplayAmount, setSpentDisplayAmount, tooltipDate, setTooltipDate, setUpdateChart, updateChart, monthsArray) => {
    return function (tooltip) {
        if (!chartRef)
            return;
        // Get position of the chart
        const positionY = chartRef.chartInstance.canvas.offsetTop;
        const positionX = chartRef.chartInstance.canvas.offsetLeft;
        // If there's nothing to display or we're out of range, then abort
        if (!tooltip) {
            return;
        }
        if (!tooltip.dataPoints) {
            // outside of range
            return;
        }
        if (tooltip.dataPoints.length > 0) {
            // Should we invert to avoid collision?
            let invert = false;
            if (tooltip.dataPoints.length > 1) {
                const diff = Math.abs(tooltip.dataPoints[0].y - tooltip.dataPoints[1].y);
                if (tooltip.dataPoints[0].y > tooltip.dataPoints[1].y) {
                    invert = false;
                }
                else if (diff > 0 && diff < 120) {
                    invert = true;
                }
            }
            let shouldUpdate = false;
            tooltip.dataPoints.forEach(function (dataPoint) {
                const newTooltipDate = monthsArray[dataPoint.index];
                if (newTooltipDate !== tooltipDate) {
                    shouldUpdate = true;
                    setTooltipDate(newTooltipDate);
                }
                if (dataPoint.datasetIndex === 0) {
                    // Budgeted dataset
                    const newBudgetedDisplayAmount = (0, format_1.toPrice)(dataPoint.yLabel, _displayCurrency);
                    if (newBudgetedDisplayAmount !== budgetedDisplayAmount) {
                        shouldUpdate = true;
                        setBudgetedDisplayAmount(newBudgetedDisplayAmount);
                    }
                }
                else {
                    // Spent dataset
                    const newSpentDisplayAmount = (0, format_1.toPrice)(dataPoint.yLabel, _displayCurrency);
                    if (newSpentDisplayAmount !== spentDisplayAmount) {
                        shouldUpdate = true;
                        setSpentDisplayAmount(newSpentDisplayAmount);
                    }
                }
                const tooltipEl = document.getElementById(`tooltip-${dataPoint.datasetIndex === 0 ? 'budgeted' : 'spent'}`);
                // tooltipEl.innerHTML = content
                tooltipEl.style.opacity = '1';
                tooltipEl.style.display = 'block';
                if (invert ? dataPoint.datasetIndex !== 1 : dataPoint.datasetIndex === 1) {
                    tooltipEl.style.top = positionY + dataPoint.y - 60 + 'px';
                }
                else {
                    tooltipEl.style.top = positionY + dataPoint.y + 8 + 'px';
                }
                tooltipEl.style.left = positionX + dataPoint.x + 'px';
            });
            if (shouldUpdate) {
                setUpdateChart(!updateChart);
            }
        }
    };
};
exports.getInteractiveTooltip = getInteractiveTooltip;
const _getPercentChange = (prev, curr) => {
    let color = 'red';
    if (prev == 0 && curr == 0) {
        return `+$0.0%`;
    }
    else if (prev == null ||
        curr == null ||
        prev == '-' ||
        curr == '-' ||
        prev == 0) {
        return null;
    }
    else if (prev * curr < 0) {
        return null; // No percentage changes between negative and positives
    }
    const flipSign = curr < 0 && prev < 0;
    let percentChangeAsset = (0, format_1.toPercentage)((curr - prev) / prev, 1);
    if (flipSign) {
        if (percentChangeAsset[0] != '-') {
            percentChangeAsset = `-${percentChangeAsset}`;
        }
        else {
            percentChangeAsset = `+${percentChangeAsset.slice(1)}`;
        }
    }
    else {
        if (percentChangeAsset[0] != '-') {
            color = 'green';
            percentChangeAsset = `+${percentChangeAsset}`;
        }
    }
    return {
        color,
        percentChangeAsset,
    };
};
