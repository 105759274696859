"use strict";
/**
 *  Logout.tsx
 *  Container for the Transactions page
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const qs = require("query-string");
const react_router_dom_1 = require("react-router-dom");
const react_1 = require("react");
const react_router_dom_2 = require("react-router-dom");
// Semantic UI
const semantic_ui_react_1 = require("semantic-ui-react");
// Context
const UserProvider_1 = require("@providers/UserProvider");
const Logout = ({}) => {
    let history = (0, react_router_dom_1.useHistory)();
    const [isExpired, setIsExpired] = (0, react_1.useState)(false);
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    (0, react_1.useEffect)(() => {
        const logout = () => __awaiter(void 0, void 0, void 0, function* () {
            const query = qs.parse(history.location.search);
            yield _user.signout(!!query.force);
            setIsLoading(false);
            if (query.expired) {
                setIsExpired(true);
                // Redirect to login
                setTimeout(() => {
                    window.location.href = '/login';
                }, 1500);
            }
        });
        setIsLoading(true);
        logout();
        // document.cookie = `_lm_authenticated=''; max-age=-1; domain=.lunchmoney.app`
    }, []);
    return (React.createElement("div", { className: "g-logged-out" },
        React.createElement("div", { className: "g-logged-out-container" },
            React.createElement("div", { className: "logged-out-content" },
                React.createElement(semantic_ui_react_1.Container, { className: "center-align" },
                    React.createElement("div", { id: "logo-container" },
                        React.createElement("div", { id: "logo" }),
                        React.createElement("div", { id: "shadow" })),
                    isLoading ? (React.createElement(semantic_ui_react_1.Header, { as: "h2", className: "center-align" }, "Logging you out, please wait!")) : isExpired ? (React.createElement("div", { className: "mb-1rem" },
                        React.createElement(semantic_ui_react_1.Header, { as: "h2", className: "center-align" }, "For security reasons, you were logged out."),
                        React.createElement(semantic_ui_react_1.Loader, { active: true, inline: "centered", size: "small", content: "Redirecting to login in 2 seconds.." }))) : (React.createElement(semantic_ui_react_1.Header, { as: "h1", className: "center-align" }, "See you again soon!")),
                    !isLoading && (React.createElement(React.Fragment, null,
                        React.createElement(react_router_dom_2.Link, { to: { pathname: '/login' } }, "Log back in"),
                        React.createElement("br", null),
                        React.createElement("a", { href: window.location.origin }, "Back to home"))))))));
};
exports.default = Logout;
