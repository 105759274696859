"use strict";
/**
 *  SplitElement.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
// Components
const format_1 = require("@helpers/format");
const RuleActionOptions = ({ actions, setActions, switchView, setDisableOnClickOutside, transactionId, }) => {
    const filters = [
        // { value: 'payee', key: '_payee', disabled: true },
        !actions.payee_name
            ? {
                value: 'set payee',
                key: 'payee_name',
                disabled: actions.hasOwnProperty('split'),
            }
            : null,
        // { value: 'append to payee', key: 'append_payee', disabled: false },
        // { value: 'prepend to payee', key: 'prepend_payee', disabled: false },
        // { value: 'notes', key: '_notes', disabled: true },
        !actions.notes
            ? {
                value: 'set notes',
                key: 'notes',
                disabled: actions.hasOwnProperty('split'),
            }
            : null,
        // { value: 'category', key: '_category', disabled: true },
        !actions.category_id
            ? {
                value: 'set category',
                key: 'category_id',
                disabled: actions.hasOwnProperty('split') ||
                    actions.hasOwnProperty('set_uncategorized'),
            }
            : null,
        !actions.set_uncategorized
            ? {
                value: 'set uncategorized',
                key: 'set_uncategorized',
                disabled: actions.hasOwnProperty('split') ||
                    actions.hasOwnProperty('category_id'),
            }
            : null,
        // { value: 'tags', key: '_tags', disabled: true },
        !actions.tags
            ? {
                value: 'add tags',
                key: 'tags',
                disabled: actions.hasOwnProperty('split'),
            }
            : null,
        // { value: 'replace tags', key: 'replace_tags', disabled: false },
        // { value: 'recurring', key: '_recurring', disabled: true },
        !actions.delete_transaction_id
            ? {
                value: 'delete transaction',
                key: 'delete_transaction_id',
                disabled: !transactionId ||
                    actions.hasOwnProperty('delete_transaction_id') ||
                    actions.hasOwnProperty('split'),
            }
            : null,
        !actions.recurring_id
            ? {
                value: 'link to recurring item',
                key: 'recurring_id',
                disabled: actions.hasOwnProperty('split'),
            }
            : null,
        !actions.skip_recurring
            ? {
                value: "don't link to recurring item",
                key: 'skip_recurring',
                disabled: actions.hasOwnProperty('split'),
            }
            : null,
        // { value: 'utilities', disabled: true },
        // { value: 'group transaction', disabled: false },
        !actions.skip_rule
            ? {
                value: "don't create a rule",
                key: 'skip_rule',
                disabled: actions.hasOwnProperty('split'),
            }
            : null,
        // { value: 'status', disabled: true },
        !actions.hasOwnProperty('split')
            ? {
                value: 'split transaction',
                key: 'split',
                disabled: actions.hasOwnProperty('payee_name') ||
                    actions.hasOwnProperty('notes') ||
                    actions.hasOwnProperty('category_id') ||
                    actions.hasOwnProperty('set_uncategorized') ||
                    actions.hasOwnProperty('tags') ||
                    actions.hasOwnProperty('delete_transaction_id') ||
                    actions.hasOwnProperty('recurring_id') ||
                    actions.hasOwnProperty('mark_as_reviewed') ||
                    actions.hasOwnProperty('mark_as_unreviewed'),
            }
            : null,
        !actions.mark_as_reviewed
            ? {
                value: 'mark as reviewed',
                key: 'mark_as_reviewed',
                disabled: actions.hasOwnProperty('split') ||
                    actions.hasOwnProperty('mark_as_unreviewed'),
            }
            : null,
        !actions.mark_as_unreviewed
            ? {
                value: 'mark as unreviewed',
                key: 'mark_as_unreviewed',
                disabled: actions.hasOwnProperty('split') ||
                    actions.hasOwnProperty('mark_as_reviewed'),
            }
            : null,
        // { value: 'mark as unreviewed', disabled: false },
        // { value: 'notifications', disabled: true },
        !actions.should_send_email
            ? { value: 'send me an email', key: 'should_send_email', disabled: false }
            : null,
        // { value: 'send me an sms', disabled: false },
        // { value: 'send me a webhook', disabled: false },
    ].filter(o => !!o);
    return (React.createElement(semantic_ui_react_1.Dropdown, { className: `category-dropdown p-category-modal modal-dropdown`, style: { borderColor: '#97d094' }, placeholder: 'Add action', fluid: true, selection: true, selectOnNavigation: false, selectOnBlur: false, closeOnEscape: true, closeOnChange: true, value: null, 
        // value={selectedAction}
        options: (filters === null || filters === void 0 ? void 0 : filters.length) > 0
            ? filters.map(filter => {
                return {
                    disabled: filter.disabled || actions.hasOwnProperty(filter.key),
                    key: filter.key || filter.value,
                    value: filter.value,
                    text: (0, format_1.capitalize)(filter.value),
                };
            })
            : [
                {
                    disabled: true,
                    key: 'none',
                    value: 'No options left to select.',
                    text: 'No options left to select.',
                },
            ], onChange: (e_1, _a) => __awaiter(void 0, [e_1, _a], void 0, function* (e, { value }) {
            setDisableOnClickOutside(true);
            const key = filters.find(o => o.value == value).key;
            if (key == 'skip_rule' ||
                key == 'skip_recurring' ||
                key == 'should_send_email' ||
                key == 'set_uncategorized') {
                setActions(Object.assign(Object.assign({}, actions), { [key]: true }));
            }
            else if (key == 'delete_transaction_id') {
                setActions(Object.assign(Object.assign({}, actions), { [key]: transactionId, one_time_rule: true }));
            }
            else {
                setActions(Object.assign(Object.assign({}, actions), { [key]: null }));
                if (key == 'split') {
                    switchView('SPLIT_RULE');
                }
            }
        }) }));
};
exports.default = RuleActionOptions;
