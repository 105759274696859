"use strict";
/**
 *  Developers.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const EditableString_1 = require("@components/elements/EditableString");
const auth_1 = require("@actions/auth");
const react_qrcode_logo_1 = require("react-qrcode-logo");
const user_1 = require("@actions/user");
const UserProvider_1 = require("@providers/UserProvider");
const react_1 = require("react");
const BackupCodes_1 = require("@components/Profile/BackupCodes");
const semantic_ui_react_1 = require("semantic-ui-react");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const Developers = ({ _process, _showToast }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    // TOTP
    const [totpStatus, setTotpStatus] = (0, react_1.useState)(null);
    const [totpMethod, setTotpMethod] = (0, react_1.useState)(null);
    const [qrCode, setQRCode] = (0, react_1.useState)(null);
    const [showPhoneNumber, setShowPhoneNumber] = (0, react_1.useState)(false);
    const [phone, setPhone] = (0, react_1.useState)(null);
    const [message, setMessage] = (0, react_1.useState)(null);
    const [error, setError] = (0, react_1.useState)(null);
    const [code, setCode] = (0, react_1.useState)(null);
    const [verifyLoading, setVerifyLoading] = (0, react_1.useState)(false);
    // User
    const [name, setName] = (0, react_1.useState)(_user.name);
    const [email, setEmail] = (0, react_1.useState)(_user.email);
    const [oldPassword, setOldPassword] = (0, react_1.useState)(null);
    const [newPassword, setNewPassword] = (0, react_1.useState)(null);
    const tokenRef = (0, react_1.useRef)(null);
    const [copySuccess, setCopySuccess] = (0, react_1.useState)('Copy');
    const getStatus = () => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield (0, auth_1.getTotpStatus)();
        setTotpStatus(result.step);
        setTotpMethod(result.method);
    });
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = 'Profile - Lunch Money';
        getStatus();
    }, []);
    return (React.createElement(semantic_ui_react_1.Container, null,
        React.createElement(ContainerHeader_1.default, { title: "Profile" }),
        React.createElement("div", { id: "g-developers" },
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Card, { fluid: true },
                    React.createElement(semantic_ui_react_1.Card.Content, { header: "Your Personal Details" }),
                    React.createElement(semantic_ui_react_1.Card.Content, null,
                        React.createElement(semantic_ui_react_1.Message, { info: true },
                            "\uD83C\uDF88 You've been a member since",
                            ' ',
                            Moment(_user.joinDate).format(_user.getFormat('month_day_year')),
                            ' ',
                            "\u2013 that's ",
                            Moment(_user.joinDate).fromNow(),
                            "!"),
                        React.createElement(semantic_ui_react_1.Form, null,
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement("label", null, "Your Name"),
                                    React.createElement(EditableString_1.default, { identifier: "profile-name", firstValue: name, shouldSaveOnChange: true, state: 'Editing', location: 'modal', onSave: name => {
                                            setName(name);
                                        } }))),
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement("label", null, "Your E-mail Address"),
                                    React.createElement(EditableString_1.default, { identifier: "profile-email", firstValue: email, shouldSaveOnChange: true, state: 'Editing', location: 'modal', onSave: email => {
                                            setEmail(email);
                                        } }),
                                    !_user.verifiedEmail && (React.createElement(semantic_ui_react_1.Message, { negative: true },
                                        React.createElement(semantic_ui_react_1.Icon, { name: "exclamation triangle" }),
                                        " You have not verified your email address yet.",
                                        ' ',
                                        React.createElement("span", { className: "link clickable", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                yield (0, user_1.verifyEmail)();
                                                _showToast({
                                                    message: `Verification email sent to ${_user.email}`,
                                                    type: 'success',
                                                });
                                            }) }, "Verify now."))))),
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement("label", null, "Old Password"),
                                    React.createElement(EditableString_1.default, { identifier: `profile-old-password`, firstValue: '', shouldSaveOnChange: true, location: 'modal', state: 'Editing', isPassword: true, onSave: old_password => {
                                            setOldPassword(old_password);
                                        } }))),
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement("label", null, "New Password"),
                                    React.createElement(EditableString_1.default, { identifier: `profile-new-password`, firstValue: '', shouldSaveOnChange: true, location: 'modal', state: 'Editing', isPassword: true, onSave: new_password => {
                                            setNewPassword(new_password);
                                        } })))),
                        React.createElement(semantic_ui_react_1.Button, { color: "orange", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                // Update user
                                const userResults = yield _process(user_1.updateUserProfile)({
                                    name,
                                    email,
                                });
                                // Update password, if any
                                let passwordResults = { error: null };
                                if (oldPassword && newPassword) {
                                    passwordResults = yield _process(auth_1.updatePassword)(oldPassword, newPassword);
                                }
                                if (!userResults.error && !passwordResults.error) {
                                    _user.updateUser({ name, email });
                                    setOldPassword(null);
                                    setNewPassword(null);
                                    _showToast({
                                        message: 'Successfully updated profile',
                                        type: 'success',
                                    });
                                }
                            }) }, 'Save Changes')))),
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Card, { fluid: true },
                    React.createElement(semantic_ui_react_1.Card.Content, { header: "2-factor authentication" }),
                    React.createElement(semantic_ui_react_1.Card.Content, null,
                        totpStatus == 'enabled' && (React.createElement(React.Fragment, null,
                            React.createElement("p", null,
                                React.createElement(semantic_ui_react_1.Icon, { name: "check circle", color: "green" }),
                                " Your account currently has 2-factor authentication set up via",
                                ' ',
                                totpMethod === 'sms'
                                    ? 'text message'
                                    : 'Authenticator app',
                                "."),
                            React.createElement(semantic_ui_react_1.Button, { fluid: true, color: "red", basic: true, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    yield (0, auth_1.revokeTotp)();
                                    setTotpStatus('disabled');
                                    setQRCode(null);
                                }) }, "Revoke 2-factor authentication"))),
                        totpStatus == 'disabled' && (React.createElement(React.Fragment, null,
                            !showPhoneNumber && (React.createElement(React.Fragment, null,
                                React.createElement(semantic_ui_react_1.Message, { info: true }, "Set up 2-factor authentication to make your account more secure!"),
                                React.createElement("p", { className: "center-align" }, "What's your preferred method for receiving 2FA codes?"),
                                React.createElement("div", { className: "flex--center" },
                                    React.createElement(semantic_ui_react_1.Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                            const results = yield (0, auth_1.setupTotp)({ type: 'app' });
                                            if (results.data) {
                                                setTotpStatus('needs verification');
                                                setQRCode(results.data);
                                                setTotpMethod('app');
                                            }
                                        }) }, "Authenticator app"),
                                    React.createElement(semantic_ui_react_1.Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                            setShowPhoneNumber(true);
                                        }) }, "text message (US/Canada only)")))),
                            showPhoneNumber && (React.createElement(semantic_ui_react_1.Form, null,
                                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                    React.createElement(semantic_ui_react_1.Form.Field, null,
                                        React.createElement("label", null, "What phone number should we send codes to?"),
                                        React.createElement(semantic_ui_react_1.Input, { label: { content: '+1' }, labelPosition: "left", onChange: (e, { value }) => {
                                                setPhone(value);
                                            } }))),
                                message && React.createElement("p", { className: "color--red" }, message),
                                React.createElement("div", { className: "flex--center" },
                                    React.createElement(semantic_ui_react_1.Button, { className: "mt-05rem", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                            const results = yield (0, auth_1.setupTotp)({
                                                type: 'sms',
                                                phone: `+1${phone}`,
                                            });
                                            if (results.data && results.data.message) {
                                                setMessage(results.data.message);
                                            }
                                            else {
                                                setTotpStatus('needs verification');
                                                setTotpMethod('sms');
                                                setMessage(null);
                                            }
                                        }) }, "Send me a test code"),
                                    React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "mt-05rem", basic: true, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                            setShowPhoneNumber(false);
                                        }) }, "Go back")))))),
                        qrCode && (React.createElement("div", { className: "width-100 flex--column flex--center" },
                            React.createElement("p", null,
                                "Scan the following QR code with your authenticator app (We recommend",
                                ' ',
                                React.createElement("a", { href: "https://1password.com", target: "_blank" }, "1Password"),
                                ",",
                                ' ',
                                React.createElement("a", { href: "https://authy.com/", target: "_blank" }, "Authy"),
                                ' ',
                                "or the Google Authenticator app for iOS/Android)"),
                            React.createElement(react_qrcode_logo_1.QRCode, { value: qrCode }),
                            React.createElement("div", { className: "api-token width-100" },
                                React.createElement(semantic_ui_react_1.Input, { ref: tokenRef, value: qrCode.split('secret=')[1], className: "mr-1rem width-100" }),
                                document.queryCommandSupported('copy') && (React.createElement("p", { className: "copy-link clickable", onClick: () => {
                                        tokenRef.current.select();
                                        document.execCommand('copy');
                                        setCopySuccess('Copied!');
                                    } }, copySuccess))))),
                        totpStatus == 'needs verification' && (React.createElement(React.Fragment, null,
                            React.createElement("p", null, "We need to verify that your 2-factor authentication is set up properly before we can proceed."),
                            React.createElement("p", null, totpMethod === 'sms'
                                ? 'What is the code in the text message we sent you?'
                                : 'What is the code that shows up for Lunch Money?'),
                            React.createElement(EditableString_1.default, { autoFocus: true, identifier: "verify-2fa", firstValue: code, shouldSaveOnChange: true, state: 'Editing', location: 'modal', onSave: _code => {
                                    setCode(_code);
                                    setError(null);
                                } }),
                            error && React.createElement("p", { className: `color--red mt-05rem` }, error),
                            React.createElement("div", { className: "flex--center" },
                                React.createElement(semantic_ui_react_1.Button, { loading: verifyLoading, className: "mt-05rem", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        setVerifyLoading(true);
                                        const results = yield (0, auth_1.verifyTotp)({
                                            code: code,
                                            test: true,
                                            type: totpMethod,
                                        });
                                        if (!results.data) {
                                            setError('Code is incorrect, please try again.');
                                            setVerifyLoading(false);
                                        }
                                        else {
                                            setTotpStatus('enabled');
                                            setQRCode(null);
                                            setError(null);
                                            setVerifyLoading(false);
                                        }
                                    }) }, "Verify code"),
                                ' ',
                                React.createElement(semantic_ui_react_1.Button, { color: "red", basic: true, className: "mt-05rem", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        yield (0, auth_1.revokeTotp)();
                                        setTotpStatus('disabled');
                                        setQRCode(null);
                                        setError(null);
                                        setCopySuccess('Copy!');
                                    }) }, "Start over")))))),
                totpStatus == 'enabled' && React.createElement(BackupCodes_1.default, { process: _process })))));
};
exports.default = Developers;
