"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 *  EditModal.tsx
 *  Recurring expenses edit modal
 */
const transactions_1 = require("@actions/transactions");
const React = require("react");
const Moment = require("moment");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
// Types
const format_1 = require("@helpers/format");
const TransactionsProvider_1 = require("@providers/TransactionsProvider");
const ModalProvider_1 = require("@providers/ModalProvider");
const UserProvider_1 = require("@providers/UserProvider");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const RecurringProvider_1 = require("@/providers/RecurringProvider");
const RecurringView = ({ data, useModal, utils, setVisibility, setDisableOnClickOutside, switchView, }) => {
    const [selectedRecurring, setSelectedRecurring] = (0, react_1.useState)('current');
    const [unmatchedRecurring, setUnmatchedRecurring] = (0, react_1.useState)(data.unmatchedRecurring || null);
    const _transactions = (0, react_1.useContext)(TransactionsProvider_1.TransactionsContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _modal = (0, react_1.useContext)(ModalProvider_1.ModalContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const _recurring = (0, react_1.useContext)(RecurringProvider_1.RecurringContext);
    const transaction = _transactions.modalData.current;
    (0, react_1.useEffect)(() => {
        const fetchUnmatched = () => __awaiter(void 0, void 0, void 0, function* () {
            const results = yield _recurring.getUnmatched(Moment(transaction.formatted_date)
                .startOf('month')
                .format('YYYY-MM-DD'), Moment(transaction.formatted_date)
                .endOf('month')
                .format('YYYY-MM-DD'));
            setUnmatchedRecurring(results || []);
        });
        if (unmatchedRecurring == null) {
            fetchUnmatched();
        }
    }, []);
    // Setup
    (0, react_1.useEffect)(() => {
        useModal({
            header: (React.createElement("div", null,
                React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        switchView('DETAILS_VIEW');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " Transaction Details"),
                React.createElement("span", { className: "header-text-large" }, "Transaction to Recurring Item"))),
            middleButton: React.createElement(React.Fragment, null),
            leftButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: true, className: "no-border", onClick: () => {
                    setVisibility(false, true);
                } }, "Cancel")),
        });
    }, []);
    /** Unlink function has a confirmation component */
    const [showUnlinkConfirmation, setShowUnlinkConfirmation] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        if (transaction.recurring_type !== 'cleared') {
            useModal({
                leftButton: React.createElement(React.Fragment, null),
            });
        }
        else {
            useModal({
                leftButton: (React.createElement(React.Fragment, null,
                    React.createElement(semantic_ui_react_1.Button, { color: "red", basic: true, className: "no-border", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setShowUnlinkConfirmation(true);
                            setDisableOnClickOutside(true);
                        }) }, "Unlink"),
                    React.createElement(semantic_ui_react_1.Confirm, { open: showUnlinkConfirmation, onConfirm: () => __awaiter(void 0, void 0, void 0, function* () {
                            const results = yield utils._process(transactions_1.unlinkRecurring)({
                                id: transaction.id,
                            });
                            if (!results.error) {
                                // Show success message
                                utils._showToast({
                                    message: 'Successfully unlinked transaction from recurring item',
                                    type: 'success',
                                });
                                data.amendTransactions({
                                    update: results.data,
                                });
                                setVisibility(false, true);
                            }
                            setShowUnlinkConfirmation(false);
                        }), header: 'Confirm unlinking', confirmButton: 'Yes, Confirm', size: 'tiny', onCancel: () => {
                            setShowUnlinkConfirmation(false);
                        }, cancelButton: 'No, Cancel', content: 'Are you sure you want to unlink this transaction from recurring item?' }))),
            });
        }
    }, [showUnlinkConfirmation]);
    /** Save function */
    (0, react_1.useEffect)(() => {
        useModal({
            rightButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    let results, successMessage;
                    if (selectedRecurring === 'unlink') {
                        results = yield utils._process(transactions_1.unlinkRecurring)({
                            id: transaction.id,
                        });
                        successMessage =
                            'Successfully unlinked transaction from recurring item';
                        if (data.amendTransactions) {
                            data.amendTransactions({
                                update: results.data,
                            });
                        }
                    }
                    else if (typeof selectedRecurring === 'number') {
                        results = yield utils._process(transactions_1.linkRecurring)({
                            id: transaction.id,
                            recurring_id: selectedRecurring,
                        });
                        successMessage =
                            'Successfully linked transaction to recurring item';
                        if (data.amendTransactions) {
                            data.amendTransactions({
                                update: results.data,
                            });
                        }
                    }
                    if (!results.error) {
                        // Show success message
                        utils._showToast({ message: successMessage, type: 'success' });
                        if (data.amendTransactions) {
                            setVisibility(false, true);
                        }
                        else {
                            setVisibility(false);
                        }
                    }
                }) }, "Save Changes")),
        });
    }, [selectedRecurring]);
    return (React.createElement("div", null, transaction && (React.createElement("div", null,
        React.createElement(semantic_ui_react_1.Form, null,
            transaction.recurring_type == 'cleared' && (React.createElement("div", { id: "transactions-modal-detail-view" },
                React.createElement("div", { className: `transaction-details clickable ${selectedRecurring === 'current' ? 'selected' : ''}`, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setSelectedRecurring('current');
                    }) },
                    React.createElement("h3", { className: "flex--space-between" },
                        "Currently linked to:",
                        selectedRecurring === 'current' && (React.createElement(semantic_ui_react_1.Icon, { name: "check circle", color: "green" })),
                        selectedRecurring !== 'current' && (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))),
                    React.createElement("div", { className: "transaction-detail" },
                        React.createElement("label", null, "Payee"),
                        React.createElement("span", null,
                            React.createElement("b", null, transaction.display_name))),
                    React.createElement("div", { className: "flex--space-between" },
                        React.createElement("div", { className: "transaction-detail" },
                            React.createElement("label", null, "Repeats"),
                            React.createElement("span", null, transaction.recurring_cadence == 'custom'
                                ? `Every ${transaction.recurring_quantity} ${transaction.recurring_granularity}${transaction.recurring_quantity > 1 ? 's' : ''}`
                                : transaction.recurring_cadence)),
                        React.createElement("div", { className: "transaction-detail" },
                            React.createElement("label", null, "Amount"),
                            React.createElement("span", null, (0, format_1.toPrice)(data.flipSign
                                ? -1 * transaction.recurring_amount
                                : transaction.recurring_amount, transaction.recurring_currency)))),
                    transaction.recurring_description && (React.createElement("div", { className: "transaction-detail" },
                        React.createElement("label", null, "Description"),
                        React.createElement("span", null, transaction.recurring_description))),
                    React.createElement(semantic_ui_react_1.Button, { className: "mb-1rem", fluid: true, size: "mini", onClick: () => {
                            _modal.data.current.recurring_id =
                                transaction.recurring_id;
                            _modal.data.current.recurring_id =
                                transaction.recurring_id;
                            _modal.data.current.previous_text =
                                'Recurring Transaction Details';
                            _modal.data.current.previous = 'RECURRING_VIEW';
                            switchView('EDIT_RECURRING');
                        } }, "Edit recurring item details")),
                React.createElement("div", { key: `link-recurring-unlink`, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setSelectedRecurring('unlink');
                    }), className: `link-recurring-row ${selectedRecurring === 'unlink' ? 'selected' : ''}` },
                    React.createElement("span", { className: "normal-font" }, "Unlink this from recurring item and turn back into a normal transaction"),
                    selectedRecurring === 'unlink' && (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })),
                    selectedRecurring !== 'unlink' && (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))))),
            !transaction.recurring_id && (React.createElement(semantic_ui_react_1.Button, { className: "mb-1rem", fluid: true, onClick: () => {
                    // Set modal data
                    _modal.data.current.recurring = {
                        original_name: transaction.original_name,
                        payee: transaction.display_name || transaction.payee,
                        amount: Math.abs(transaction.amount),
                        category_id: transaction.category_id,
                        description: transaction.notes,
                        currency: transaction.currency,
                        amount_type: transaction.amount > 0 ? 'expenses' : 'credits',
                        asset_id: transaction.asset_id
                            ? transaction.asset_id
                            : null,
                        plaid_account_id: transaction.plaid_account_id
                            ? transaction.plaid_account_id
                            : null,
                        billing_date: transaction.formatted_date || transaction.date,
                        start_date: Moment(transaction.formatted_date || transaction.date)
                            .startOf('month')
                            .format('YYYY-MM-DD'),
                        transaction_id: transaction.id,
                    };
                    // Switch view
                    _modal.data.current.previous = 'RECURRING_VIEW';
                    _modal.data.current.previous_text = 'Transaction Details';
                    switchView('ADD_RECURRING');
                } }, "Create new recurring item"))),
        unmatchedRecurring == null && (React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "small" }, "Loading recurring expenses...")),
        (unmatchedRecurring === null || unmatchedRecurring === void 0 ? void 0 : unmatchedRecurring.length) > 0 && (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "divider-line" },
                React.createElement("span", null, "or")),
            transaction.recurring_type == 'cleared' ? (React.createElement("h2", null, "Link to another recurring item:")) : (React.createElement("h2", null, "Link to an existing recurring item:")),
            [
                unmatchedRecurring.filter(o => (transaction.plaid_account_id &&
                    o.plaid_account_id == transaction.plaid_account_id) ||
                    (transaction.asset_id &&
                        o.asset_id == transaction.asset_id)),
                unmatchedRecurring.filter(o => !((transaction.plaid_account_id &&
                    o.plaid_account_id == transaction.plaid_account_id) ||
                    (transaction.asset_id &&
                        o.asset_id == transaction.asset_id))),
            ].map((arr, index) => {
                return (React.createElement(React.Fragment, { key: `row-${index}` },
                    React.createElement("h3", { className: "header" }, index === 0 ? ((arr === null || arr === void 0 ? void 0 : arr.length) > 0 ? (`Paid from ${_assets.getPlaidAccountName(transaction.plaid_account_id) || _assets.getAssetName(transaction.asset_id)}`) : (React.createElement(React.Fragment, null))) : ('Paid from other account')),
                    arr
                        .sort((a, b) => {
                        return (a.payee || '')
                            .trim()
                            .toLowerCase()
                            .localeCompare((b.payee || '').toLowerCase().trim());
                    })
                        .map((rec, index) => {
                        return (React.createElement("div", { key: `link-recurring-${index}`, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                setSelectedRecurring(rec.id);
                            }), className: `link-recurring-row ${selectedRecurring === rec.id ? 'selected' : ''}` },
                            React.createElement("div", null,
                                React.createElement("p", null,
                                    rec.payee,
                                    ":",
                                    ' ',
                                    (0, format_1.toPrice)(_user.settings['show_debits_as_negative']
                                        ? -1 * rec.amount
                                        : rec.amount, rec.currency),
                                    ' ',
                                    "(",
                                    rec.cadence == 'custom'
                                        ? `Every ${rec.quantity} ${rec.granularity}${rec.quantity > 1 ? 's' : ''}`
                                        : rec.cadence,
                                    ")"),
                                (rec.plaid_account_id || rec.asset_id) &&
                                    (!transaction.plaid_account_id ||
                                        rec.plaid_account_id !==
                                            transaction.plaid_account_id) &&
                                    (!transaction.asset_id ||
                                        rec.asset_id !== transaction.asset_id) ? (React.createElement("p", null,
                                    React.createElement(semantic_ui_react_1.Icon, { name: "university", className: "mr-05rem" }),
                                    "Paid from",
                                    ' ',
                                    _assets.getPlaidAccountName(rec.plaid_account_id) || _assets.getAssetName(rec.asset_id))) : (React.createElement(React.Fragment, null)),
                                rec.description ? (React.createElement("p", null,
                                    React.createElement(semantic_ui_react_1.Icon, { name: "sticky note outline", className: "mr-05rem" }),
                                    rec.description)) : (React.createElement(React.Fragment, null))),
                            selectedRecurring === rec.id && (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })),
                            selectedRecurring !== rec.id && (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))));
                    })));
            })))))));
};
exports.default = RecurringView;
