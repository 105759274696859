"use strict";
/**
 *  SplitView.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
// Components
const format_1 = require("@helpers/format");
const EditableString_1 = require("@components/elements/EditableString");
const EditableDate_1 = require("@components/elements/EditableDate");
const EditableTags_1 = require("@components/elements/EditableTags");
const EditableCategory_1 = require("@components/elements/EditableCategory");
const SplitElement = ({ index, split, setSplit, originalAmount, currency, moveFiles, }) => {
    var _a, _b, _c, _d;
    return (React.createElement("div", { className: "transaction-details", key: `split-${index}` },
        React.createElement("h3", { className: "flex--space-between" },
            "Split ",
            index + 1,
            React.createElement(semantic_ui_react_1.Icon, { name: "close", color: "red", className: "clickable", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    // Remove index
                    const list = split.filter((item, j) => {
                        return j !== index;
                    });
                    setSplit(list);
                }) })),
        !!moveFiles && (React.createElement(semantic_ui_react_1.Message, { info: true },
            moveFiles,
            " file attachment",
            moveFiles > 1 ? 's' : '',
            " will be moved to this transaction.")),
        React.createElement("div", { className: "transaction-detail" },
            React.createElement("label", null, "Date"),
            React.createElement(semantic_ui_react_1.Form, null,
                React.createElement(EditableDate_1.default, { showError: !split[index].formatted_date, identifier: `split-category-${index}`, firstValue: split[index].formatted_date, state: 'Editing', format: 'month_day_year', location: 'modal', onSave: date => {
                        // Update the appropriate item in the split array
                        const updatedSplit = split.map((item, j) => {
                            if (j === index) {
                                return Object.assign(Object.assign({}, item), { formatted_date: date });
                            }
                            else {
                                return item;
                            }
                        });
                        setSplit(updatedSplit);
                    } }))),
        React.createElement("div", { className: "transaction-detail" },
            React.createElement("label", null, "Payee"),
            React.createElement(EditableString_1.default, { identifier: `split-payee-${index}`, placeholder: "Payee", firstValue: split[index].payee, location: 'modal', state: 'Editing', autosuggest: true, showError: !((_a = split[index].payee) === null || _a === void 0 ? void 0 : _a.length), shouldSaveOnChange: true, onSave: (payee) => __awaiter(void 0, void 0, void 0, function* () {
                    // Update the appropriate item in the split array
                    const updatedSplit = split.map((item, j) => {
                        if (j === index) {
                            return Object.assign(Object.assign({}, item), { payee });
                        }
                        else {
                            return item;
                        }
                    });
                    setSplit(updatedSplit);
                }) })),
        React.createElement("div", { className: "transaction-detail" },
            React.createElement("label", null, "Category"),
            React.createElement(EditableCategory_1.default, { identifier: `split-category-${index}`, firstValue: split[index].category_id, state: 'Editing', location: 'modal', showError: split[index].category_id == null, onSave: category_id => {
                    // Update the appropriate item in the split array
                    const updatedSplit = split.map((item, j) => {
                        if (j === index) {
                            return Object.assign(Object.assign({}, item), { category_id });
                        }
                        else {
                            return item;
                        }
                    });
                    setSplit(updatedSplit);
                } })),
        React.createElement("div", { className: "transaction-detail" },
            React.createElement("div", { className: "flex--space-between" },
                React.createElement("label", null, "Amount"),
                React.createElement("label", null, "Percentage")),
            React.createElement(semantic_ui_react_1.Form, null,
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "margin-0 flex--space-between" },
                    React.createElement(EditableString_1.default, { identifier: `split-amount-${index}`, placeholder: (0, format_1.toPrice)(0, currency), firstValue: split[index].amount, location: 'modal', state: 'Editing', shouldSaveOnChange: true, showError: !split[index].amount, onSave: (amount) => __awaiter(void 0, void 0, void 0, function* () {
                            if (isNaN(amount))
                                return;
                            // Update the appropriate item in the split array
                            const updatedSplit = split.map((item, j) => {
                                if (j === index) {
                                    return Object.assign(Object.assign({}, item), { amount });
                                }
                                else {
                                    return item;
                                }
                            });
                            setSplit(updatedSplit);
                        }) }),
                    React.createElement("span", { className: "ml-05rem mr-05rem" }, "or"),
                    React.createElement(EditableString_1.default, { percentage: true, identifier: `split-percentage-${index}`, placeholder: '50', firstValue: (_c = (_b = ((split[index].amount / originalAmount) * 100)) === null || _b === void 0 ? void 0 : _b.toFixed(0)) === null || _c === void 0 ? void 0 : _c.toString(), location: 'modal', state: 'Editing', shouldSaveOnChange: true, onSave: (percentage) => __awaiter(void 0, void 0, void 0, function* () {
                            if (isNaN(percentage))
                                return;
                            // Update the appropriate item in the split array
                            const updatedSplit = split.map((item, j) => {
                                if (j === index) {
                                    return Object.assign(Object.assign({}, item), { amount: (originalAmount * (percentage / 100)).toFixed(2) });
                                }
                                else {
                                    return item;
                                }
                            });
                            setSplit(updatedSplit);
                        }) })))),
        React.createElement("div", { className: "transaction-detail" },
            React.createElement("label", null, "Notes (optional)"),
            React.createElement(EditableString_1.default, { identifier: `split-notes-${index}`, placeholder: "Notes", firstValue: split[index].notes, location: 'modal', state: 'Editing', shouldSaveOnChange: true, onSave: (notes) => __awaiter(void 0, void 0, void 0, function* () {
                    // Update the appropriate item in the split array
                    const updatedSplit = split.map((item, j) => {
                        if (j === index) {
                            return Object.assign(Object.assign({}, item), { notes });
                        }
                        else {
                            return item;
                        }
                    });
                    setSplit(updatedSplit);
                }) })),
        React.createElement("div", { className: "transaction-detail" },
            React.createElement("label", null, "Tags (optional)"),
            React.createElement(EditableTags_1.default, { allowAdd: false, showUntagged: false, placeholder: "Search tags", initialValue: ((_d = split[index]) === null || _d === void 0 ? void 0 : _d.tags) || [], onChange: tags => {
                    // Update the appropriate item in the split array
                    const updatedSplit = split.map((item, j) => {
                        if (j === index) {
                            return Object.assign(Object.assign({}, item), { tags });
                        }
                        else {
                            return item;
                        }
                    });
                    setSplit(updatedSplit);
                } }))));
};
exports.default = SplitElement;
