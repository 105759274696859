"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateDifference = exports.handleBudgetResponse = void 0;
const checks_1 = require("@helpers/checks");
const format_1 = require("@helpers/format");
const handleBudgetResponse = (_showToast, results) => {
    if (results.data.success == 0) {
        _showToast({
            message: `No budget was set.`,
            type: 'warning',
        });
    }
    else if (results.data.errors.length == 0) {
        _showToast({
            message: `Successfully set ${results.data.success} budget${results.data.success == 1 ? '' : 's'}.`,
            type: 'success',
        });
    }
    else {
        _showToast({
            message: `Successfully set ${results.data.success} budget${results.data.success == 1 ? '' : 's'}. ${results.data.errors.length} budget${results.data.errors.length == 1 ? '' : 's'} could not be set: ${results.data.errors.join(', ')}`,
            type: 'success',
        });
    }
};
exports.handleBudgetResponse = handleBudgetResponse;
const calculateDifference = (budget, round, isIncome) => {
    if (!(0, checks_1.isNull)(budget === null || budget === void 0 ? void 0 : budget.budget_to_base) &&
        ((budget === null || budget === void 0 ? void 0 : budget.spending_to_base) || (budget === null || budget === void 0 ? void 0 : budget.num_transactions) > 0)) {
        let a, b;
        if (isIncome) {
            a = budget.spending_to_base * -1;
            b = budget.budget_to_base;
        }
        else {
            a = budget.spending_to_base;
            b = budget.budget_to_base;
        }
        return (0, format_1.roundTo)(a, round) - (0, format_1.roundTo)(b, round);
    }
    else {
        return null;
    }
};
exports.calculateDifference = calculateDifference;
