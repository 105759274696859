"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const date_fns_1 = require("date-fns");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_router_dom_1 = require("react-router-dom");
const EditablePrice_1 = require("@components/elements/EditablePrice");
const EditableDate_1 = require("@components/elements/EditableDate");
const EditableCurrency_1 = require("@components/elements/EditableCurrency");
const format_1 = require("@helpers/format");
const plaid_1 = require("@actions/plaid");
const react_1 = require("react");
const FormField_1 = require("@components/elements/FormField");
const format_2 = require("@helpers/format");
const assets_1 = require("@actions/assets");
const crypto_1 = require("@actions/crypto");
const zabo_1 = require("@actions/zabo");
const ModalProvider_1 = require("@providers/ModalProvider");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const UserProvider_1 = require("@providers/UserProvider");
const EditModal = ({ data, useModal, utils, setVisibility, switchView, setDisableOnClickOutside, }) => {
    var _a, _b, _c, _d, _e, _f;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _modal = (0, react_1.useContext)(ModalProvider_1.ModalContext);
    let history = (0, react_router_dom_1.useHistory)();
    const [account, setAccount] = (0, react_1.useState)(data.account);
    const [typeName, setTypeName] = (0, react_1.useState)(null);
    const [subtypeName, setSubtypeName] = (0, react_1.useState)(null);
    const [accountName, setAccountName] = (0, react_1.useState)(null);
    const [accountBalance, setAccountBalance] = (0, react_1.useState)(null);
    const [accountCurrency, setAccountCurrency] = (0, react_1.useState)(null);
    const [excludeTransactions, setExcludeTransactions] = (0, react_1.useState)(false);
    const [defaultAccount, setDefaultAccount] = (0, react_1.useState)(_user.settings['default_asset_id']);
    const [importStartDate, setImportStartDate] = (0, react_1.useState)(null);
    const [displayName, setDisplayName] = (0, react_1.useState)(null);
    const [blockImportBeforeStartDate, setBlockImportBeforeStartDate] = (0, react_1.useState)(null);
    const [treatAsManual, setTreatAsManual] = (0, react_1.useState)(false);
    const [status, setStatus] = (0, react_1.useState)(null);
    const [closeDate, setCloseDate] = (0, react_1.useState)(Moment().format('YYYY-MM-DD'));
    // For assets
    const [institutionName, setInstitutionName] = (0, react_1.useState)(null);
    //
    const [refreshLoading, setRefreshLoading] = (0, react_1.useState)(false);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    // Advanced settings
    const [currencyOverride, setCurrencyOverride] = (0, react_1.useState)(null);
    const [useOriginalName, setUseOriginalName] = (0, react_1.useState)(false);
    const [useAuthorizedDate, setUseAuthorizedDate] = (0, react_1.useState)(false);
    const [skipTransactions, setSkipTransactions] = (0, react_1.useState)(false);
    const [showOriginalNameModal, setShowOriginalNameModal] = (0, react_1.useState)(null);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const IS_ZABO = data.account.source == 'zabo';
    const IS_MANUAL = data.account.source == 'manual';
    const IS_MANUAL_CRYPTO = data.account.source == 'manual-crypto';
    const IS_SYNCED_CRYPTO = data.account.source == 'synced-crypto';
    const IS_PLAID = data.account.source == 'plaid';
    (0, react_1.useEffect)(() => {
        setIsLoading(false);
        setAccount(data.account);
        setStatus(data.account.status);
        setCloseDate(data.account.closed_on || Moment().format('YYYY-MM-DD'));
        setTypeName(data.account.type == 'depository' ? 'cash' : data.account.type);
        setSubtypeName(data.account.subtype);
        setAccountName(data.account.name);
        setDisplayName(data.account.display_name ||
            `${data.account.institution_name} ${data.account.name}`);
        setAccountBalance(data.account.balance);
        setAccountCurrency(data.account.currency);
        setInstitutionName(data.account.institution_name);
        setImportStartDate(data.account.import_start_date);
        setBlockImportBeforeStartDate(data.account.block_import_before_start_date);
        setTreatAsManual(data.account.treat_as_manual);
        setExcludeTransactions(data.account.exclude_transactions);
        setCurrencyOverride(data.account.currency_override);
        setUseOriginalName(data.account.use_original_name);
        setUseAuthorizedDate(data.account.use_authorized_date);
        setSkipTransactions(data.account.skip_transactions);
    }, [data]);
    (0, react_1.useEffect)(() => {
        useModal({
            header: React.createElement("span", { className: "header-text-large" }, "Edit Account"),
            middleButton: null,
            leftButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "no-border", basic: true, onClick: () => {
                    setVisibility(false);
                } }, 'Cancel')),
            rightButton: (React.createElement(semantic_ui_react_1.Button, { loading: isLoading, disabled: isLoading, color: "orange", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    var _a, _b;
                    setIsLoading(true);
                    let results;
                    if (IS_MANUAL) {
                        const updateObj = {
                            type_name: typeName,
                            type_id: null,
                            subtype_name: subtypeName,
                            subtype_id: null,
                            name: accountName,
                            balance: accountBalance,
                            currency: accountCurrency,
                            institution_name: institutionName,
                            exclude_transactions: excludeTransactions,
                            status,
                            closed_on: closeDate,
                            display_name: displayName,
                        };
                        results = yield utils._process(assets_1.updateAsset)(account.id, updateObj);
                        _assets.updateAsset(account.id, updateObj);
                        if (defaultAccount == account.id) {
                            // This account is the new default
                            _user.updateSetting('default_asset_id', account.id);
                        }
                        else if (_user.settings['default_asset_id'] == account.id) {
                            // The old setting was equal to this one, but is no longer
                            _user.updateSetting('default_asset_id', null);
                        }
                    }
                    else if (IS_MANUAL_CRYPTO) {
                        results = yield utils._process(crypto_1.updateManual)(account.id, {
                            display_name: displayName,
                            name: accountName,
                            balance: accountBalance,
                            institution_name: institutionName,
                        });
                        _assets.updateManualCrypto(account.id, {
                            display_name: displayName,
                            name: accountName,
                            balance: accountBalance,
                            institution_name: institutionName,
                        });
                    }
                    else if (IS_SYNCED_CRYPTO) {
                        results = yield utils._process(crypto_1.updateSynced)(account.id, {
                            display_name: displayName,
                            coingecko_id: account.coingecko_id,
                        });
                        _assets.updateSyncedCrypto(account.id, account.coingecko_id, {
                            display_name: displayName,
                        });
                    }
                    else if (IS_ZABO) {
                        results = yield utils._process(zabo_1.updateZabo)(account.id, {
                            display_name: displayName,
                            currency: accountCurrency,
                        });
                        _assets.updateCrypto(account.id, {
                            display_name: displayName,
                            currency: accountCurrency,
                        });
                    }
                    else if (IS_PLAID) {
                        const updateObj = {};
                        if (importStartDate !== data.account.import_start_date ||
                            blockImportBeforeStartDate !==
                                data.account.block_import_before_start_date) {
                            updateObj['import_start_date'] = importStartDate;
                            updateObj['block_import_before_start_date'] = blockImportBeforeStartDate;
                        }
                        if (currencyOverride) {
                            updateObj['currency_override'] = currencyOverride;
                        }
                        results = yield utils._process(plaid_1.updateAccount)(account.id, Object.assign({ type: typeName, subtype: subtypeName, name: accountName, display_name: displayName, use_original_name: useOriginalName, use_authorized_date: useAuthorizedDate, treat_as_manual: treatAsManual, skip_transactions: skipTransactions }, updateObj));
                        _assets.updatePlaidAccount(account.id, Object.assign({ type: typeName, subtype: subtypeName, name: accountName, display_name: displayName, use_original_name: useOriginalName, use_authorized_date: useAuthorizedDate, treat_as_manual: treatAsManual }, updateObj));
                    }
                    if (!results.error) {
                        setIsLoading(false);
                        if ((_a = results.data) === null || _a === void 0 ? void 0 : _a.num_original_mismatched) {
                            // Ask if the user wants us to retro-actively update all transactions
                            setShowOriginalNameModal((_b = results.data) === null || _b === void 0 ? void 0 : _b.num_original_mismatched);
                        }
                        else {
                            setVisibility(false);
                        }
                        if (utils.setRerender) {
                            utils.setRerender(new Date().getTime());
                        }
                        utils._showToast({
                            message: 'Successfully updated account',
                            type: 'success',
                        });
                    }
                }) }, 'Save Changes')),
        });
        return () => {
            useModal({
                header: null,
                leftButton: null,
                middleButton: null,
            });
        };
    }, [
        data,
        typeName,
        subtypeName,
        accountName,
        displayName,
        accountBalance,
        accountCurrency,
        institutionName,
        importStartDate,
        blockImportBeforeStartDate,
        defaultAccount,
        excludeTransactions,
        status,
        closeDate,
        currencyOverride,
        useOriginalName,
        skipTransactions,
        useAuthorizedDate,
        treatAsManual,
        isLoading,
    ]);
    const getBankColors = account => {
        var _a;
        if (((_a = account.access_token) === null || _a === void 0 ? void 0 : _a.indexOf('development')) > -1) {
            return {
                lineClassName: 'red',
                iconColor: 'red',
                lineIcon: 'x',
                content: React.createElement(React.Fragment, null),
            };
        }
        else if (['relink'].indexOf(account.status) > -1 ||
            account.update_type == 'user_present_required') {
            return {
                lineClassName: 'grey',
                iconColor: 'grey',
                lineIcon: 'x',
                content: (React.createElement("span", { className: "monospace" }, "Awaiting relink. No access to new updates.")),
            };
        }
        else if (['revoked'].indexOf(account.status) > -1) {
            return {
                lineClassName: 'red',
                iconColor: 'red',
                lineIcon: 'x',
                content: React.createElement("span", { className: "monospace" }, "No access to new updates."),
            };
        }
        else if (['not found', 'deactivated', 'closed', 'not supported', 'error'].indexOf(account.status) > -1) {
            return {
                lineClassName: 'grey',
                iconColor: 'grey',
                lineIcon: 'x',
                content: React.createElement("span", { className: "monospace" }, "No access to new updates."),
            };
        }
        else {
            // Active
            if (Moment().diff(Moment(account.plaid_last_successful_update), 'd') >= 7 &&
                Moment().diff(Moment(account.last_fetch), 'h') < 24) {
                return {
                    lineClassName: 'yellow',
                    iconColor: 'yellow',
                    lineIcon: 'clock outline',
                    content: (React.createElement(semantic_ui_react_1.Message, { warning: true },
                        "Looks like it's been a while since the last update from your bank.",
                        ' ',
                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, position: "top center", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "yellow", name: "question circle" }) },
                            React.createElement("p", null, "Missing transactions? Account balance out of sync? Create a bank syncing support ticket by clicking on 'I need help with this account' below, and we'll direct your issues over to Plaid for further investigation.")))),
                };
            }
            else {
                return {
                    lineClassName: 'moving-green',
                    iconColor: 'green',
                };
            }
        }
    };
    const getPlaidColors = account => {
        var _a;
        if (((_a = account.access_token) === null || _a === void 0 ? void 0 : _a.indexOf('development')) > -1) {
            return {
                lineClassName: 'red',
                iconColor: 'red',
                lineIcon: 'x',
                content: (React.createElement(semantic_ui_react_1.Message, { error: true },
                    "A new connection is required to receive new updates to this account.",
                    React.createElement(semantic_ui_react_1.Button, { fluid: true, className: "mt-05rem", size: "small", color: "orange", onClick: () => {
                            utils.launchPlaidLink();
                            setVisibility(false);
                        } }, "Create new connection"))),
            };
        }
        else if (['relink'].indexOf(account.status) > -1) {
            return {
                lineClassName: 'red',
                iconColor: 'red',
                lineIcon: 'x',
                content: account.status == 'relink' ? (React.createElement(semantic_ui_react_1.Message, { warning: true },
                    "A relink is required to receive new updates to this account.",
                    React.createElement(semantic_ui_react_1.Button, { fluid: true, className: "mt-05rem", size: "small", color: "orange", onClick: () => {
                            utils.relinkAccount(account.id);
                            setVisibility(false);
                        } },
                        "Relink to ",
                        account.institution_name))) : (''),
            };
        }
        else if (['revoked'].indexOf(account.status) > -1) {
            return {
                lineClassName: 'red',
                iconColor: 'red',
                lineIcon: 'x',
                content: (React.createElement(semantic_ui_react_1.Message, { error: true }, "Our connection to this account has been irreversibly revoked. You will need to create a new connection.")),
            };
        }
        else if (['not found', 'deactivated', 'closed'].indexOf(account.status) > -1) {
            return {
                lineClassName: 'yellow',
                iconColor: 'yellow',
                content: (React.createElement(semantic_ui_react_1.Message, { warning: true },
                    "We are no longer receiving data on this account. This may be fixed by reconnecting to ",
                    account.institution_name,
                    " and ensuring that",
                    ' ',
                    account.display_name || account.name,
                    " is selected.",
                    React.createElement(semantic_ui_react_1.Button, { fluid: true, className: "mt-05rem", size: "small", color: "orange", onClick: () => {
                            utils.relinkAccount(account.id);
                            setVisibility(false);
                        } },
                        "Relink to ",
                        account.institution_name))),
            };
        }
        else if (['not supported'].indexOf(account.status) > -1) {
            return {
                lineClassName: 'yellow',
                iconColor: 'yellow',
                lineIcon: 'x',
                content: (React.createElement(semantic_ui_react_1.Message, { warning: true }, "This account exists but syncing data is not currently supported.")),
            };
        }
        else if (['error'].indexOf(account.status) > -1) {
            return {
                lineClassName: 'grey',
                iconColor: 'grey',
                lineIcon: 'question',
                content: (React.createElement(semantic_ui_react_1.Message, { grey: true },
                    "We are running into unexpected errors fetching data for this account.",
                    React.createElement(semantic_ui_react_1.Button, { fluid: true, className: "mt-05rem", size: "small", color: "orange", onClick: () => {
                            utils.relinkAccount(account.id);
                            setVisibility(false);
                        } }, "Force reconnect"))),
            };
        }
        else if (account.update_type == 'user_present_required') {
            return {
                lineClassName: 'red',
                iconColor: 'red',
                lineIcon: 'x',
                content: account.update_type == 'user_present_required' ? (React.createElement(semantic_ui_react_1.Message, { info: true, className: "mb-1rem" },
                    React.createElement("p", null, "Perpetual one-time passcode (POTP) is a setting that requires consumers of certain financial institutions to enter a one-time passcode at every login attempt."),
                    React.createElement("p", null,
                        "You have POTP enabled for your accounts at",
                        ' ',
                        account.institution_name,
                        ". You will need to manually trigger an update every time you wish to fetch fresh data."),
                    ' ',
                    React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", color: "orange", onClick: () => {
                            utils.relinkAccount(account.id);
                            setVisibility(false);
                        } },
                        "Relink to ",
                        account.institution_name))) : (React.createElement(React.Fragment, null)),
            };
        }
        else {
            if (Moment().diff(Moment(account.last_fetch), 'h') >= 24) {
                return {
                    lineClassName: 'moving-green',
                    iconColor: 'green',
                    content: (React.createElement(semantic_ui_react_1.Message, { info: true },
                        "It's been a while since the last update from Plaid.",
                        React.createElement(semantic_ui_react_1.Button, { fluid: true, isLoading: refreshLoading, disabled: refreshLoading, size: "small", color: "orange", onClick: () => {
                                attemptFetch();
                            }, className: "mt-05rem" }, "Trigger refresh"))),
                };
            }
            else {
                return {
                    lineClassName: 'moving-green',
                    iconColor: 'green',
                };
            }
        }
    };
    const attemptFetch = (opts = {}) => {
        setRefreshLoading(true);
        const results = utils._process(plaid_1.fetchTransactions)(Object.assign({ plaid_account_id: account.id }, opts));
        if (!results.error) {
            utils._showToast({
                message: 'Successfully triggered a refresh for this account, data will auto-refresh when this disappears (more new data may still roll in after).',
                type: 'success',
                autoDismissTimeout: 10000,
            });
            setTimeout(() => {
                utils.triggerFetch();
                setRefreshLoading(false);
            }, 10000);
            // setVisibility(false)
        }
    };
    return (React.createElement("div", { id: "grouped-modal-detail-view" },
        ((_a = account.access_token) === null || _a === void 0 ? void 0 : _a.indexOf('development')) > -1 && (React.createElement(semantic_ui_react_1.Message, { warning: true },
            React.createElement(semantic_ui_react_1.Message.Header, { className: "mb-1rem" }, "New Connection Required"),
            React.createElement(semantic_ui_react_1.Message.Content, null,
                React.createElement("p", null, "You'll need to create a new connection to continue syncing from this institution. This is an easy one-time fix and is due to a recent internal Plaid migration that affected this particular connection on your account."),
                React.createElement("p", null,
                    "Click 'Auto-import from my bank' below. Select your bank and all the accounts you are already tracking in Lunch Money. When prompted, elect to ",
                    React.createElement("b", null, "'Merge with existing synced account'"),
                    " and match up the existing accounts with the new ones to resume syncing."),
                React.createElement(semantic_ui_react_1.Button, { fluid: true, onClick: () => {
                        utils.launchPlaidLink();
                        setVisibility(false);
                    } }, "Auto-import from my bank")))),
        React.createElement("div", { className: `transaction-details` },
            React.createElement("h3", null, "Account Information"),
            React.createElement(semantic_ui_react_1.Form, null,
                React.createElement(FormField_1.default, { identifier: 'account-name', label: 'Account Name', disabled: account.status == 'closed', type: 'string', setFn: (name) => __awaiter(void 0, void 0, void 0, function* () {
                        setAccountName(name);
                    }), firstValue: accountName, placeholder: 'Personal Credit Card', location: 'modal', state: 'Editing', shouldSaveOnChange: true }),
                React.createElement(FormField_1.default, { identifier: 'display-name', label: 'Display Name', disabled: account.status == 'closed', type: 'string', tooltip: `Change the way this account is displayed throughout Lunch
            Money. By default, the display name consists of the
            institution name and the account name.`, setFn: (name) => __awaiter(void 0, void 0, void 0, function* () {
                        setDisplayName(name);
                    }), firstValue: displayName, location: 'modal', state: 'Editing', shouldSaveOnChange: true }),
                React.createElement(FormField_1.default, { identifier: 'institution-name', label: 'Institution Name', disabled: (!IS_MANUAL && !IS_MANUAL_CRYPTO) ||
                        IS_SYNCED_CRYPTO ||
                        account.status == 'closed', type: 'string', setFn: (name) => __awaiter(void 0, void 0, void 0, function* () {
                        setInstitutionName(name);
                    }), firstValue: institutionName, placeholder: '(optional)', location: 'modal', state: 'Editing', shouldSaveOnChange: true }),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", null, "Account Type"),
                            React.createElement(semantic_ui_react_1.Dropdown, { fluid: true, disabled: IS_ZABO ||
                                    IS_MANUAL_CRYPTO ||
                                    IS_SYNCED_CRYPTO ||
                                    account.status == 'closed', selection: true, className: "category-dropdown p-category-modal", value: typeName, placeholder: "Select type", onChange: (e, { value }) => {
                                    setTypeName(value);
                                    setSubtypeName(null);
                                }, options: _assets.types.map(type => {
                                    return {
                                        key: type.id,
                                        value: type.name,
                                        text: type.name,
                                    };
                                }) })),
                        typeName && _assets.subtypes[typeName] && (React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", null, "Account Subtype"),
                            React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal", fluid: true, disabled: IS_MANUAL_CRYPTO ||
                                    IS_SYNCED_CRYPTO ||
                                    IS_ZABO ||
                                    account.status == 'closed', selection: true, value: subtypeName, placeholder: "(optional)", onChange: (e, { value }) => {
                                    setSubtypeName(value);
                                }, options: typeName
                                    ? (_b = _assets === null || _assets === void 0 ? void 0 : _assets.subtypes[typeName]) === null || _b === void 0 ? void 0 : _b.map(type => {
                                        return {
                                            key: type.subtype_id,
                                            value: type.subtype_name,
                                            text: type.subtype_name,
                                        };
                                    })
                                    : [] })))))),
            account.limit && (React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Limit"),
                React.createElement("span", null, (0, format_1.toPrice)(account.limit, account.currency)))),
            React.createElement("div", { className: "flex--space-between" },
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, IS_MANUAL || IS_MANUAL_CRYPTO ? 'Created on' : 'Date Linked'),
                    React.createElement("span", null, Moment(account.date_linked || account.date).format(_user.getMonthDayYearFormat()))),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", { className: "display--block right-align" }, "Linked By"),
                    React.createElement("span", { className: "right-align" }, account.linked_by_name)))),
        (IS_SYNCED_CRYPTO || IS_PLAID) && (React.createElement("div", { className: "transaction-details" },
            React.createElement("h3", { className: "flex--space-between" },
                "Status",
                account.status === 'active' ? (React.createElement("span", { className: "display--flex" },
                    React.createElement("span", { className: "color--green" }, "Active"))) : account.institution_id == 16 || account.source == 'zabo' ? (React.createElement("span", { className: 'color--grey' }, "Deprecated")) : (React.createElement("div", null,
                    React.createElement("span", { className: `${account.status === 'inactive' ||
                            account.status === 'processing' ||
                            account.status === 'syncing'
                            ? 'color--grey'
                            : 'color--red'}` },
                        (0, format_2.capitalize)(account.status),
                        ' ')))),
            React.createElement("div", null,
                React.createElement("div", { className: "log-container" },
                    React.createElement("div", { className: "log-icon" },
                        React.createElement(semantic_ui_react_1.Icon, { circular: true, fitted: true, inverted: true, color: getBankColors(account).iconColor, name: 'university' }),
                        React.createElement("div", { className: `log-icon-line thick ${getBankColors(account).lineClassName}` }, !!getBankColors(account).lineIcon && (React.createElement("span", { className: "mid" },
                            React.createElement(semantic_ui_react_1.Icon, { name: getBankColors(account).lineIcon, color: getBankColors(account).iconColor }))))),
                    React.createElement("div", { className: "log-details" },
                        React.createElement("span", { className: "log-header large" }, account.institution_name),
                        React.createElement("div", { className: "log-items" },
                            getBankColors(account).content || React.createElement(React.Fragment, null),
                            React.createElement("p", { className: "monospace" },
                                "Last update received",
                                ' ',
                                account.plaid_last_successful_update ? (React.createElement(React.Fragment, null, Moment().to(Moment(account.plaid_last_successful_update)))) : ('never'),
                                ' ',
                                React.createElement(semantic_ui_react_1.Popup, { inverted: true, position: "top center", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", name: "info circle" }) },
                                    React.createElement("p", null,
                                        "Timestamp of the last time Plaid successfully connected with ",
                                        account.institution_name,
                                        " for new transaction updates, regardless of whether any new data was available in the update."),
                                    React.createElement("p", null, Moment(account.plaid_last_successful_update).format(_user.getFullTimeFormat()))))))),
                React.createElement("div", { className: "log-container" },
                    React.createElement("div", { className: "log-icon" },
                        React.createElement(semantic_ui_react_1.Icon, { circular: true, fitted: true, inverted: true, color: getPlaidColors(account).iconColor, name: 'factory' }),
                        React.createElement("div", { className: `log-icon-line thick  ${getPlaidColors(account).lineClassName}` }, !!getPlaidColors(account).lineIcon && (React.createElement("span", { className: "mid" },
                            React.createElement(semantic_ui_react_1.Icon, { name: getPlaidColors(account).lineIcon, color: getPlaidColors(account).iconColor }))))),
                    React.createElement("div", { className: "log-details" },
                        React.createElement("span", { className: "log-header large" }, "Plaid"),
                        React.createElement("div", { className: "log-items" },
                            getPlaidColors(account).content || React.createElement(React.Fragment, null),
                            React.createElement("p", { className: "monospace" },
                                "Last update received",
                                ' ',
                                account.last_fetch ? (React.createElement(React.Fragment, null, Moment().to(Moment(account.last_fetch)))) : ('never'),
                                ' ',
                                React.createElement(semantic_ui_react_1.Popup, { inverted: true, position: "top center", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", name: "info circle" }) },
                                    React.createElement("p", null, "Timestamp of the last successful check from Lunch Money for updated data or timestamps from Plaid."),
                                    React.createElement("p", null, Moment(account.last_fetch).format(_user.getFullTimeFormat()))))))),
                React.createElement("div", { className: "log-container" },
                    React.createElement("div", { className: "log-icon" },
                        React.createElement(semantic_ui_react_1.Icon, { circular: true, fitted: true, inverted: true, color: 'green', name: 'check' }),
                        React.createElement("div", { className: "log-icon-line" })),
                    React.createElement("div", { className: "log-details" },
                        React.createElement("span", { className: "log-header large" }, "Lunch Money"),
                        React.createElement("div", { className: "log-items" },
                            !!account.last_import && (React.createElement("p", { className: "mb-0rem" },
                                React.createElement("span", { className: "monospace" },
                                    "New data last imported",
                                    ' ',
                                    account.last_import ? (React.createElement(React.Fragment, null, Moment().to(Moment(account.last_import)))) : ('never')),
                                ' ',
                                React.createElement(semantic_ui_react_1.Popup, { inverted: true, position: "top center", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", name: "info circle" }) },
                                    React.createElement("p", null, "Timestamp of the last time Lunch Money imported new data from Plaid for this connection."),
                                    React.createElement("p", null, Moment(account.last_import).format(_user.getFullTimeFormat()))))),
                            account.update_type !== 'user_present_required' &&
                                account.status == 'active' &&
                                account.access_token.indexOf('development') == -1 && (React.createElement(React.Fragment, null,
                                Moment().diff(Moment(account.last_fetch), 'h') <
                                    24 && (React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", isLoading: refreshLoading, disabled: refreshLoading, color: "orange", onClick: () => {
                                        attemptFetch();
                                    }, className: "mb-05rem" }, "Trigger refresh")),
                                React.createElement(semantic_ui_react_1.Button, { fluid: true, basic: true, size: "small", color: "orange", onClick: () => {
                                        utils.relinkAccount(account.id);
                                        setVisibility(false);
                                    } }, "Force reconnect"))))))),
            IS_SYNCED_CRYPTO && (React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null,
                    "Last fetch",
                    React.createElement(semantic_ui_react_1.Popup, { inverted: true, position: "top center", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", className: "ml-05rem", name: "info circle" }) },
                        "This is when we last fetched your account balance directly from ",
                        account.institution_name,
                        ".")),
                React.createElement("span", null,
                    account.last_import ? (React.createElement(React.Fragment, null,
                        Moment(account.last_import).format(_user.getFullTimeFormat()),
                        ' ',
                        "(",
                        Moment().to(Moment(account.last_import)),
                        ")")) : ('never'),
                    ' '))))),
        IS_PLAID && account.status !== 'revoked' && (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Message, { negative: true, className: "mt-0 mb-2rem clickable", onClick: () => {
                    utils.setShowHelpModalAccount(account);
                    // setShowHelpModal(true)
                    setDisableOnClickOutside(true);
                } },
                React.createElement("p", null, "Are you experiencing issues with this account? Let us help troubleshoot."),
                React.createElement(semantic_ui_react_1.Button, { fluid: true, color: "red", className: "mt-05rem" }, "I need help with this account")))),
        account.status !== 'inactive' && status !== 'closed' && (React.createElement("div", { className: "transaction-details" },
            React.createElement("h3", { className: "flex--space-between" }, "Balance"),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null,
                    "Current balance",
                    ' ',
                    (IS_MANUAL || IS_MANUAL_CRYPTO || IS_SYNCED_CRYPTO) && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { color: data.account.balance_as_of ? 'grey' : 'red', name: data.account.balance_as_of
                                ? 'question circle'
                                : 'exclamation triangle' }) },
                        data.account.balance_as_of ? (React.createElement("p", null,
                            "You last reconciled your balance on",
                            ' ',
                            Moment(data.account.balance_as_of).format(_user.getMonthDayYearFormat()),
                            ".")) : (React.createElement("p", null, "You have never reconciled your account balance.")),
                        React.createElement("p", null, "The balance that shows up throughout Lunch Money is calculated based off of this reconciliation. If your balance is off, use this field to let us know what the balance is as of now."),
                        React.createElement("p", null, "Your balance should be a positive number. If this is a credit card and you have a credit on the account, then your balance should be a negative number.")))),
                IS_ZABO && (React.createElement("span", null, (0, format_1.toCrypto)(account.balance, account.currency))),
                IS_PLAID && (React.createElement("span", null, (0, format_1.toPrice)(account.balance, account.currency))),
                IS_SYNCED_CRYPTO && (React.createElement("span", null, (0, format_1.toCrypto)(account.balance, account.currency))),
                (IS_MANUAL || IS_MANUAL_CRYPTO) && (React.createElement(EditablePrice_1.default, { amount: data.account.balance, currency: data.account.currency, additionalCurrencies: [data.account.currency], disableCurrency: IS_MANUAL_CRYPTO, isCrypto: data.account.type == 'cryptocurrency' || IS_ZABO, identifier: `asset-price-${account.id}`, state: 'Editing', location: 'modal', autoFocus: false, saveOnChange: true, onSave: (amount, currency) => {
                        if (amount !== accountBalance ||
                            currency !== accountCurrency) {
                            setAccountBalance(amount);
                            if (!IS_MANUAL_CRYPTO) {
                                setAccountCurrency(currency);
                            }
                        }
                    } }))),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null,
                    IS_MANUAL_CRYPTO
                        ? 'Exchange rate last fetched'
                        : 'Balance last updated',
                    IS_MANUAL_CRYPTO && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, position: "top center", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", className: "ml-05rem", name: "info circle" }) }, "Latest cryptocurrency exchange rates are fetched automatically once every 5 minutes."))),
                React.createElement("span", null,
                    account.balance_last_update || data.account.balance_as_of ? (React.createElement(React.Fragment, null,
                        Moment(account.balance_last_update || data.account.balance_as_of).format(_user.getFullTimeFormat()),
                        ' ',
                        "(",
                        Moment().to(Moment(account.balance_last_update || data.account.balance_as_of)),
                        ")")) : ('never'),
                    ' ')))),
        (IS_MANUAL || IS_PLAID) && (React.createElement("div", { className: "transaction-details" },
            React.createElement("h3", { className: "flex--space-between" }, "Advanced Settings"),
            IS_MANUAL && (React.createElement(semantic_ui_react_1.Form, null,
                status !== 'closed' && (React.createElement(React.Fragment, null,
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: defaultAccount == data.account.id, onChange: (e, { checked }) => {
                                    if (checked) {
                                        setDefaultAccount(data.account.id);
                                        setStatus('active');
                                        setExcludeTransactions(false);
                                        setCloseDate(null);
                                    }
                                    else {
                                        setDefaultAccount(null);
                                    }
                                }, label: 'Set as default account' }),
                            React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", className: "ml-05rem", name: "question circle" }) }, "Enable this to set this as the default account when adding new transactions."))),
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: !!excludeTransactions, onChange: (e, { checked }) => {
                                    setExcludeTransactions(checked);
                                    if (checked) {
                                        setDefaultAccount(null);
                                    }
                                }, label: 'Do not track transactions' }),
                            React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", className: "ml-05rem", name: "question circle" }) }, "Enable this to only track the balance for this account. This account will no longer show up under the dropdown of accounts to choose from when creating or updating transactions and other items."))))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-1rem" },
                    React.createElement(semantic_ui_react_1.Form.Field, { className: "mb-0" },
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: status == 'closed', onChange: (e, { checked }) => {
                                if (checked) {
                                    setStatus('closed');
                                    setDefaultAccount(null);
                                }
                                else {
                                    setStatus('active');
                                }
                            }, label: 'Mark as closed' }),
                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", className: "ml-05rem", name: "question circle" }) }, "Mark this account as closed to keep its transactions and history intact. It will only show up in Net Worth Tracker."))),
                status == 'closed' && (React.createElement(React.Fragment, null,
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mt-0" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", { className: "display--block" },
                                "Closed on",
                                React.createElement(semantic_ui_react_1.Popup, { inverted: true, position: "top center", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", className: "ml-05rem", name: "exclamation triangle" }) }, "Updating this value will also update it for all accounts under the same bank. If an earlier date is chosen, a fetch will be triggered to retrieve historical transactions.")),
                            React.createElement(EditableDate_1.default, { identifier: 'linked-account', format: 'month_day_year', maxDate: (0, date_fns_1.parseISO)(Moment().format('YYYY-MM-DD')), firstValue: closeDate, placeholder: Moment().format(_user.getMonthDayYearFormat()), location: 'modal', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () {
                                    setCloseDate(Moment(date).format('YYYY-MM-DD'));
                                }) }))),
                    (account.status !== 'closed' ||
                        !Moment(account.closed_on).isSame(closeDate)) && (React.createElement(semantic_ui_react_1.Message, { info: true, className: "mb-1rem" }, "Note: balance history for this account after the indicated close date will be deleted.")))))),
            IS_PLAID && (React.createElement(React.Fragment, null,
                React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: skipTransactions, onChange: (e, { checked }) => {
                        setSkipTransactions(checked);
                    }, label: 'Do not import transactions', className: skipTransactions ? 'mb-1rem' : '' }),
                React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", className: "ml-05rem", name: "question circle" }) }, "Toggle this on to disable importing transactions this account, regardless if they are supported. Balance updates will still occur."))),
            IS_PLAID && !skipTransactions && (React.createElement(semantic_ui_react_1.Form, { className: "mb-1rem" },
                account.status !== 'inactive' && (React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", { className: "display--block" },
                            "Earliest import date",
                            React.createElement(semantic_ui_react_1.Popup, { inverted: true, position: "top center", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", className: "ml-05rem", name: "exclamation triangle" }) }, "If set, we will not automatically import transactions before this date. Updating this field will not remove existing transactions, but may trigger a fetch for new transactions.")),
                        React.createElement(EditableDate_1.default, { identifier: 'linked-account', format: 'month_day_year', maxDate: (0, date_fns_1.parseISO)(Moment().format('YYYY-MM-DD')), firstValue: importStartDate, location: 'modal', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () {
                                setImportStartDate(date);
                            }) })))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: !!treatAsManual, onChange: (e, { checked }) => {
                                if (checked) {
                                    setTreatAsManual(true);
                                }
                                else {
                                    setTreatAsManual(false);
                                }
                            }, label: 'Allow modifications to transactions' }),
                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", className: "ml-05rem", name: "question circle" }) }, "Toggle this on to enable adding manual transactions and editing transaction amounts for automatically synced accounts. Please note that balance of the account will not be modified from these changes and will continue to be synced via Plaid."))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: !!useOriginalName, onChange: (e, { checked }) => {
                                if (checked) {
                                    setUseOriginalName(true);
                                }
                                else {
                                    setUseOriginalName(false);
                                }
                            }, label: 'Use original transaction name' }),
                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", className: "ml-05rem", name: "question circle" }) }, "Toggle this on to display the original, raw transaction name instead of Plaid's cleaned-up merchant name."))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: !!useAuthorizedDate, onChange: (e, { checked }) => {
                                if (checked) {
                                    setUseAuthorizedDate(true);
                                }
                                else {
                                    setUseAuthorizedDate(false);
                                }
                            }, label: 'Use authorized date' }),
                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", className: "ml-05rem", name: "question circle" }) }, "By default, we use the authorized_date field to populate the date for an imported transactions. Toggle this off to use the normal date field instead. You can view this information by clicking into an imported transaction and then clicking on \"View Plaid metadata\"."))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null,
                            React.createElement("div", null,
                                "Override synced currency",
                                ' ',
                                React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "info circle" }), position: 'bottom right' }, "Set this currency to override the currency of the account returned by Plaid."))),
                        React.createElement(EditableCurrency_1.default, { onSave: currency => {
                                setCurrencyOverride(currency);
                            }, firstValue: currencyOverride, useSupported: true }))),
                ((_d = (_c = data.account) === null || _c === void 0 ? void 0 : _c.available_products) === null || _d === void 0 ? void 0 : _d.split(',').indexOf('investments')) > -1 &&
                    ((_f = (_e = data.account) === null || _e === void 0 ? void 0 : _e.billed_products) === null || _f === void 0 ? void 0 : _f.split(',').indexOf('investments')) == -1 &&
                    data.account.type == 'investment' && (React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", isLoading: refreshLoading, disabled: refreshLoading, color: "orange", onClick: () => {
                        attemptFetch({
                            fetch_investment_transactions: true,
                        });
                    }, className: "mb-05rem" }, "Enable Investment Transactions")))))),
        account.status !== 'processing' && (React.createElement("div", { className: `transaction-details` },
            React.createElement("h3", null, "Actions"),
            !IS_ZABO && !IS_MANUAL_CRYPTO && !IS_SYNCED_CRYPTO && (React.createElement(semantic_ui_react_1.Button, { size: "small", className: 'mb-05rem', onClick: () => {
                    history.push({
                        pathname: '/transactions',
                        search: `?time=all&match=any&${IS_MANUAL ? 'asset' : 'account'}=${account.id}`,
                    });
                }, fluid: true }, "View all account transactions")),
            account.status !== 'deleting' &&
                !IS_MANUAL_CRYPTO &&
                [..._assets.assets, ..._assets.plaidAccounts].length > 0 && (React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", className: "mb-05rem", color: "orange", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    switchView('MERGE_ACCOUNTS');
                }) },
                "Merge into another account",
                ' ',
                React.createElement(semantic_ui_react_1.Icon, { name: "arrow alternate circle right" }))),
            account.status !== 'deleting' &&
                ((IS_MANUAL && !excludeTransactions) ||
                    (IS_PLAID &&
                        _assets.getPlaidAccount(account.id)['treat_as_manual'])) && (React.createElement(semantic_ui_react_1.Button, { size: "small", className: "mb-05rem", onClick: () => {
                    history.push({
                        pathname: '/transactions/import',
                        search: `?${IS_PLAID ? 'plaid_account_id' : 'asset_id'}=${account.id}`,
                    });
                }, fluid: true }, "Upload transactions via CSV")),
            account.status !== 'deleting' &&
                IS_PLAID &&
                account.status == 'active' && (React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", color: "orange", className: "mb-05rem", onClick: () => {
                    utils.relinkAccount(account.id);
                    setVisibility(false);
                } }, "Add/remove accounts from this bank")),
            account.status !== 'deleting' && IS_PLAID && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", className: "mb-1rem", color: "red", onClick: () => {
                        switchView('LINKED_ACCOUNT_INACTIVE');
                    } },
                    "Troubleshoot or Remove account",
                    ' ',
                    React.createElement(semantic_ui_react_1.Icon, { name: "arrow alternate circle right" })), size: "small", position: 'top center' }, "Need to remove this account? Click here and we'll walk you through it.")),
            IS_ZABO && (React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", className: "mb-1rem", color: "red", onClick: () => {
                    switchView('CRYPTO_DELETE');
                } },
                "Remove this account ",
                React.createElement(semantic_ui_react_1.Icon, { name: "arrow alternate circle right" }))),
            IS_SYNCED_CRYPTO && (React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", className: "mb-1rem", color: "red", onClick: () => {
                    switchView('SYNCED_CRYPTO_DELETE');
                } },
                "Remove this account ",
                React.createElement(semantic_ui_react_1.Icon, { name: "arrow alternate circle right" }))),
            IS_MANUAL && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", className: "mb-1rem", color: "red", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        const results = yield utils._process(assets_1.getAssetStatus)(account.id);
                        if (results.hasTransaction ||
                            results.hasRecurring ||
                            results.hasBalanceHistory) {
                            switchView('ASSET_DELETE');
                        }
                        else {
                            const deleteResults = yield utils._process(assets_1.deleteAsset)(account.id, {
                                keep_items: false,
                            });
                            if (!deleteResults.error) {
                                setVisibility(false);
                                _assets.removeAsset(account.id);
                                utils._showToast({
                                    message: 'Successfully deleted account',
                                    type: 'success',
                                });
                            }
                        }
                    }) },
                    "Remove this account",
                    ' ',
                    React.createElement(semantic_ui_react_1.Icon, { name: "arrow alternate circle right" })), size: "small", position: 'top center' }, "Need to remove this account? Click here and we'll walk you through it.")),
            IS_MANUAL_CRYPTO && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", className: "mb-1rem", color: "red", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        const results = yield utils._process(crypto_1.getManualStatus)(account.id);
                        if (results.hasBalanceHistory) {
                            switchView('MANUAL_CRYPTO_DELETE');
                        }
                        else {
                            const deleteResults = yield utils._process(crypto_1.removeManual)(account.id, {
                                keep_history: false,
                            });
                            if (!deleteResults.error) {
                                setVisibility(false);
                                _assets.removeManualCrypto(account.id);
                                utils._showToast({
                                    message: 'Successfully deleted account',
                                    type: 'success',
                                });
                            }
                        }
                    }) },
                    "Remove this account",
                    ' ',
                    React.createElement(semantic_ui_react_1.Icon, { name: "arrow alternate circle right" })), mouseEnterDelay: 500, size: "small", position: 'top center' }, "Need to remove this account? Click here and we'll walk you through it.")))),
        React.createElement(semantic_ui_react_1.Modal, { open: !!showOriginalNameModal, closeOnEscape: false, closeOnDimmerClick: false, size: 'small', onClose: () => {
                setShowOriginalNameModal(null);
            } },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Retroactively apply original name setting?"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("p", null,
                    "Would you like to update ",
                    showOriginalNameModal,
                    " transactions to use the original name instead of the cleaned-up merchant name?"),
                React.createElement("p", null, "Note this would also override any changes to the payee name you may have made yourself.")),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setShowOriginalNameModal(null);
                        setVisibility(false);
                    }), negative: true, content: `No` }),
                React.createElement(semantic_ui_react_1.Button, { color: 'yellow', onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield (0, plaid_1.overrideWithOriginalName)(account.id, {
                            skip_rules: true,
                        });
                        utils._showToast({
                            message: `Successfully updated ${showOriginalNameModal} transactions`,
                            type: 'success',
                        });
                        setShowOriginalNameModal(null);
                        setVisibility(false);
                    }), content: `Yes, update ${showOriginalNameModal} transactions` })))));
};
exports.default = EditModal;
