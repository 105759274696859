"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const SelectLineItem = ({ keyValue, selectedValue, label, disabled = false, displayOnSelect = React.createElement(React.Fragment, null), onClick = value => { }, }) => (React.createElement("div", { key: keyValue, onClick: () => {
        if (disabled)
            return;
        onClick(keyValue);
    }, className: `link-recurring-row ${selectedValue == keyValue && 'selected'} ${disabled ? 'disabled' : ''}` },
    React.createElement("div", null,
        React.createElement("span", null, label),
        selectedValue == keyValue && displayOnSelect),
    selectedValue == keyValue ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))));
exports.default = SelectLineItem;
