"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const format_1 = require("@helpers/format");
const UserProvider_1 = require("@providers/UserProvider");
const semantic_ui_react_1 = require("semantic-ui-react");
const DisplayOptions = ({ hiddenCols = [], setHiddenCols, tagView, setTagView, setShowDedupe, }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [_hiddenCols, _setHiddenCols] = (0, react_1.useState)(hiddenCols);
    const TABLE_COLUMNS = ['notes', 'accounts', 'tags'];
    const removeLargestWidth = () => {
        // Column widths
        const columns = [
            'date',
            'category',
            'payee',
            'amount',
            'notes',
            'accounts',
            'tags',
        ];
        let maxCol;
        let maxWidth = 0;
        columns.forEach(col => {
            const width = parseInt(localStorage.getItem(`transactions-${col}`) || '0');
            if (width > maxWidth) {
                maxCol = col;
                maxWidth = width;
            }
        });
        localStorage.removeItem(`transactions-${maxCol}`);
        // Figure out which one is the largest
    };
    return (React.createElement(semantic_ui_react_1.Popup, { position: 'bottom right', on: "hover", hoverable: true, wide: true, trigger: React.createElement(semantic_ui_react_1.Button, { basic: true, color: "orange", icon: true },
            React.createElement(semantic_ui_react_1.Icon, { name: "cogs" })), content: React.createElement(React.Fragment, null,
            React.createElement("div", { className: "mt-05rem" },
                "With multiple tags,",
                ' ',
                React.createElement(semantic_ui_react_1.Dropdown, { className: "inline-breakdown-dropdown", inline: true, value: tagView, options: [
                        {
                            key: 'Show all',
                            value: 'Show all',
                            text: 'show them all',
                            onClick: () => {
                                localStorage.setItem('_lm_transactions_tag_view', 'Show all');
                                setTagView('Show all');
                            },
                        },
                        {
                            key: 'Show one',
                            value: 'Show one',
                            text: 'show only one',
                            onClick: () => {
                                localStorage.setItem('_lm_transactions_tag_view', 'Show one');
                                setTagView('Show one');
                            },
                        },
                    ] })),
            TABLE_COLUMNS.map(col => {
                return (React.createElement("div", { className: "mt-05rem", key: `col-${col}` },
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, label: `Show ${(0, format_1.capitalize)(col)} column`, checked: (_hiddenCols === null || _hiddenCols === void 0 ? void 0 : _hiddenCols.indexOf(col)) == -1, onChange: (e, { checked }) => {
                            if (checked) {
                                // Prevent table from getting too big
                                removeLargestWidth();
                                // Remove from _hiddenCols
                                const set = new Set(_hiddenCols);
                                set.delete(col);
                                _setHiddenCols([...set]);
                                setHiddenCols([...set]);
                                _user.updateSetting('transaction_hidden_cols', [...set]);
                            }
                            else {
                                // Remove from _hiddenCols
                                const set = new Set(_hiddenCols);
                                set.add(col);
                                _setHiddenCols([...set]);
                                setHiddenCols([...set]);
                                // Remove set width
                                localStorage.removeItem(`transactions-${col}`);
                                _user.updateSetting('transaction_hidden_cols', [...set]);
                            }
                        } })));
            }),
            React.createElement(semantic_ui_react_1.Button, { size: "small", className: "mt-05rem", fluid: true, onClick: () => {
                    setShowDedupe(true);
                } }, "Deduplicate transactions")) }));
};
exports.default = DisplayOptions;
