"use strict";
/**
 *  Transactions.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const qs = require("query-string");
const _ = require("lodash");
const react_csv_1 = require("react-csv");
const react_router_dom_1 = require("react-router-dom");
const SelectLineItem_1 = require("../global/SelectLineItem");
const Moment = require("moment");
// Semantic UI
const semantic_ui_react_1 = require("semantic-ui-react");
const dates_1 = require("@helpers/dates");
const transactions_1 = require("@helpers/transactions");
const Loader_1 = require("@components/global/Loader");
const Sticky_1 = require("@components/elements/Sticky");
const EmptyState_1 = require("@components/Transactions/EmptyState");
const DisplayOptions_1 = require("@components/Transactions/DisplayOptions");
const Summary_1 = require("@components/Transactions/Summary");
const TransactionTableHeader_1 = require("@components/Transactions/TransactionTableHeader");
const UserProvider_1 = require("@providers/UserProvider");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const ModalProvider_1 = require("@providers/ModalProvider");
const TagsProvider_1 = require("@providers/TagsProvider");
const TransactionsProvider_1 = require("@providers/TransactionsProvider");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
// Actions
const transactions_2 = require("@actions/transactions");
const recurring_expenses_1 = require("@actions/recurring_expenses");
const rules_1 = require("@actions/rules");
const ClearFilterRow_1 = require("@components/Transactions/ClearFilterRow");
const Search_1 = require("@components/Transactions/Search");
const plaid_1 = require("@actions/plaid");
const recurring_expenses_2 = require("@actions/recurring_expenses");
// Helpers
const format_1 = require("@helpers/format");
// Components
const NewModal_1 = require("@components/global/NewModal");
const Filter_1 = require("@components/Transactions/Filter");
const NewTransactionRow_1 = require("@components/Transactions/NewTransactionRow");
const TransactionRow_1 = require("@components/Transactions/TransactionRow");
const DryRunRules_1 = require("@components/Rules/DryRunRules");
const Transactions_1 = require("@components/TaskCard/Transactions");
const BalanceUpdater_1 = require("@components/elements/BalanceUpdater");
const ConfirmBulkDelete_1 = require("@components/Transactions/ConfirmBulkDelete");
const RecurringProvider_1 = require("@/providers/RecurringProvider");
const TransactionsContainer = ({ 
// Options
isEmbedded, displaySidebar, allowBulkSelect, allowNewTransaction, watchQueryChanges, showHeader, showSearchAndFilter, showPlaidRefresh, preventModal, allowApplyRules = true, showYearOverride = false, showTotalFooter = false, 
// Transactions
transactionQuery = null, 
// Callbacks
updateCallback = null, 
// Utilities
_process, _removeToast, _showToast, }) => {
    var _a, _b, _c, _d;
    const _transactions = (0, react_1.useContext)(TransactionsProvider_1.TransactionsContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const _recurring = (0, react_1.useContext)(RecurringProvider_1.RecurringContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _tags = (0, react_1.useContext)(TagsProvider_1.TagsContext);
    const _modal = (0, react_1.useContext)(ModalProvider_1.ModalContext);
    const history = (0, react_router_dom_1.useHistory)();
    const { year, month } = (0, react_router_dom_1.useParams)();
    let location = (0, react_router_dom_1.useLocation)();
    const [queryTime, setQueryTime] = (0, react_1.useState)(null);
    const [showReapply, setShowReapply] = (0, react_1.useState)(false);
    const [preventUpdate, setPreventUpdate] = (0, react_1.useState)(false);
    const [currentPeriod, setCurrentPeriod] = (0, react_1.useState)(null);
    const [monthDifference, setMonthDifference] = (0, react_1.useState)(0);
    // Loaders
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    const [isFetching, setIsFetching] = (0, react_1.useState)(false);
    const [triggerRefresh, setTriggerRefresh] = (0, react_1.useState)(1);
    // Transactions list
    const allTransactions = (0, react_1.useRef)([]);
    const pendingTransactions = (0, react_1.useRef)([]);
    const [showPending, setShowPending] = (0, react_1.useState)(localStorage.getItem('_lm_transactions_show_pending') == 'true');
    // Filtered transactions can include grouped, parents, etc.
    const [filteredTransactions, setFilteredTransactions] = (0, react_1.useState)(null);
    // Exportable transactions is what's visible to the user
    const visibleTransactions = (0, react_1.useRef)([]);
    const visibleTransactionsUnique = (0, react_1.useRef)([]);
    // List of TransactionRows
    const [transactionsUI, setTransactionsUI] = (0, react_1.useState)(null);
    const [pendingUI, setPendingUI] = (0, react_1.useState)(null);
    const [showDryRunRules, setShowDryRunRules] = (0, react_1.useState)(null);
    // Transactions
    const [showNewTransactionRow, setShowNewTransactionRow] = (0, react_1.useState)(false);
    const [transactionMonths, setTransactionMonths] = (0, react_1.useState)([]);
    // Limits and skips
    const [limit, setLimit] = (0, react_1.useState)(localStorage.getItem('_lm_transactions_limit')
        ? Math.min(parseInt(localStorage.getItem('_lm_transactions_limit').toString()), 250)
        : 50);
    const [skip, setSkip] = (0, react_1.useState)(0);
    const LIMIT_OPTIONS = [25, 50, 100, 250];
    const LIMIT = 20;
    // Bulk
    const [numBulkSelected, setNumBulkSelected] = (0, react_1.useState)(0);
    const [syncSelect, setSyncSelect] = (0, react_1.useState)(null);
    // Modal
    const [showModal, setShowModal] = (0, react_1.useState)(false);
    const [modalView, setModalView] = (0, react_1.useState)(null);
    // Dedupe
    const [showDedupe, setShowDedupe] = (0, react_1.useState)(false);
    const [dedupeKeys, setDedupeKeys] = (0, react_1.useState)({
        amount: true,
        account: true,
        date: true,
        payee: true,
    });
    const [dedupeSelection, setDedupeSelection] = (0, react_1.useState)('first');
    // Display options
    const [hiddenCols, setHiddenCols] = (0, react_1.useState)(_user.settings['transaction_hidden_cols']);
    const [colSpan, setColSpan] = (0, react_1.useState)(9);
    const [tagView, setTagView] = (0, react_1.useState)(localStorage.getItem('_lm_transactions_tag_view') || 'Show all');
    const [lastImport, setLastImport] = (0, react_1.useState)(null);
    const [unclearedCount, setUnclearedCount] = (0, react_1.useState)(null);
    const [balances, setBalances] = (0, react_1.useState)(null);
    const [showConfirmBulkDelete, setShowConfirmBulkDelete] = (0, react_1.useState)(false);
    // On mobile
    const _showSidebar = localStorage.getItem('_lm_transactions_show_sidebar');
    const smallWidth = document.body.offsetWidth < 620;
    let startingValue = true;
    if (typeof _showSidebar !== 'undefined') {
        if (_showSidebar == 'true') {
            startingValue = true;
        }
        else if (_showSidebar == 'false') {
            startingValue = false;
        }
    }
    if (smallWidth) {
        // Override if width is small enough
        startingValue = false;
    }
    const [showSidebar, setShowSidebar] = (0, react_1.useState)(startingValue);
    //
    const [hasFilters, setHasFilters] = (0, react_1.useState)(false);
    const [hasSearch, setHasSearch] = (0, react_1.useState)(false);
    //
    const [unreviewedIds, setUnreviewedIds] = (0, react_1.useState)([]);
    const [confirmLoading, setConfirmLoading] = (0, react_1.useState)(false);
    const [confirmReviewAll, setConfirmReviewAll] = (0, react_1.useState)(null);
    const cancelAutofetch = (0, react_1.useRef)(false);
    const shiftClicked = (0, react_1.useRef)(false);
    const lastSelectedIndex = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        setIsLoading(true);
        // Page title
        if (!isEmbedded) {
            document.title = 'Transactions - Lunch Money';
        }
        if (allowBulkSelect || allowNewTransaction) {
            document.addEventListener('keydown', handleKeyDown, false);
            document.addEventListener('keyup', handleKeyUp, false);
            return () => {
                document.removeEventListener('keydown', handleKeyDown, false);
                document.removeEventListener('keyup', handleKeyUp, false);
            };
        }
    }, []);
    const handleKeyDown = event => {
        if (event.keyCode == 16) {
            shiftClicked.current = true;
        }
        else if (event.keyCode === 27) {
            // ESC exits new transactions
            setShowNewTransactionRow(false);
        }
    };
    const handleKeyUp = event => {
        if (event.keyCode == 16) {
            shiftClicked.current = false;
        }
    };
    /**
     * Watch if the current period changes
     */
    (0, react_1.useEffect)(() => {
        if (!watchQueryChanges)
            return;
        const query = qs.parse(location.search);
        setQueryTime(query === null || query === void 0 ? void 0 : query.time);
        if (typeof month === 'undefined' &&
            typeof year === 'undefined' &&
            !isEmbedded) {
            const _currentPeriod = localStorage.getItem('_lm_transactions_current_period');
            const currentPeriod = _currentPeriod !== 'null' && !!_currentPeriod
                ? _currentPeriod
                : (0, dates_1.getFormattedMonth)();
            history.replace(`/transactions/${Moment(currentPeriod).format('YYYY/MM')}${window.location.search}`);
        }
        else {
            const newCurrentPeriod = year && month
                ? (0, dates_1.getFormattedMonth)(year, month)
                : localStorage.getItem('_lm_transactions_current_period') ||
                    (0, dates_1.getFormattedMonth)();
            if (newCurrentPeriod !== currentPeriod) {
                localStorage.setItem('_lm_transactions_current_period', newCurrentPeriod);
                setIsLoading(true);
                setCurrentPeriod(newCurrentPeriod);
                //
                setShowNewTransactionRow(false);
                setMonthDifference(Moment(newCurrentPeriod, 'YYYY-MM-DD').diff(Moment().startOf('month'), 'months'));
                cancelAutofetch.current = true;
                setSkip(0);
                setTriggerRefresh(new Date().getTime());
                allTransactions.current = [];
                pendingTransactions.current = [];
                constructTransactions(
                // If it has time set, then don't forceFetch
                // search.time == 'custom' || search.time == 'all' ? false : true,
                // !qs.parse(location.search)?.hasOwnProperty('time'),
                newCurrentPeriod); // forceFetch
                //
                if (_user.settings['link_months_between_views']) {
                    localStorage.setItem('_lm_recurring_current_period', newCurrentPeriod);
                    localStorage.setItem('_lm_budget_current_period', newCurrentPeriod);
                    localStorage.setItem('_lm_calendar_current_period', newCurrentPeriod);
                }
            }
            else {
                // Query changed
                constructTransactions();
            }
        }
    }, [location.search, month, year]);
    (0, react_1.useEffect)(() => {
        _modal.setModalData({
            onCreate,
            onSave,
            amendTransactions,
            setShowDryRunRules,
            setTriggerRefresh,
            allTransactions: allTransactions.current,
            currentPeriod: currentPeriod,
            hasAssets: false,
            clearRecurring: clearRecurringId,
            dismissRecurring: dismissRecurringId,
            showConfirmBulkDelete: () => {
                setShowConfirmBulkDelete(true);
            },
        });
        _modal.setModalUtils({
            _process: _process,
            _showToast: _showToast,
        });
    }, [currentPeriod]);
    (0, react_1.useEffect)(() => {
        const fetchTransactionsPage = () => __awaiter(void 0, void 0, void 0, function* () {
            setIsLoading(true);
            if (!isEmbedded) {
                const snapshot = yield (0, transactions_2.getTransactionsPage)();
                setLastImport(snapshot.lastImport);
                setTransactionMonths(snapshot.transactionMonths);
                setTriggerRefresh(new Date().getTime());
            }
            setIsFetching(false);
        });
        fetchTransactionsPage();
    }, []);
    /**
     * Modifiers
     */
    const setVisibleTransactionsUnique = () => {
        visibleTransactionsUnique.current = visibleTransactions.current.filter(o => {
            if (o.group_id) {
                // Is the group within visibleTransactions?
                const exists = visibleTransactions.current.find(cur => {
                    return cur.id == o.group_id;
                });
                if (exists) {
                    return false;
                }
            }
            return true;
        }, 0);
    };
    const onCreate = (transaction) => __awaiter(void 0, void 0, void 0, function* () {
        var _e, _f, _g, _h, _j, _k, _l;
        if (!allowNewTransaction)
            return;
        // Create transaction
        const results = yield _process(transactions_2.createTransaction)(Object.assign(Object.assign({}, transaction), { status: _user.settings['auto_review_transaction_on_creation']
                ? 'cleared'
                : 'uncleared' }), {
            should_convert: true,
        });
        if (results.error) {
            // Highlight the new transaction in the table
            return false;
        }
        else {
            const newTransactions = results.data.transactions.map(o => {
                return Object.assign(Object.assign({}, o), { _highlight: true });
            });
            // Insert new transaction into current transaction list and trigger update
            allTransactions.current = newTransactions.concat(allTransactions.current);
            // This below triggers a rerender
            setFilteredTransactions(newTransactions.concat(filteredTransactions));
            visibleTransactions.current = newTransactions.concat(visibleTransactions.current);
            setVisibleTransactionsUnique();
            setShowNewTransactionRow(false);
            setTriggerRefresh(new Date().getTime());
            // Set last used currency in localStorage
            localStorage.setItem('_lm_last_currency_used', transaction.currency);
            if (((_e = results.data.asset_update) === null || _e === void 0 ? void 0 : _e.length) > 0) {
                setBalances(results.data.asset_update);
            }
            else {
                const content = ((_g = (_f = results.data.transactions[0]) === null || _f === void 0 ? void 0 : _f.logs) === null || _g === void 0 ? void 0 : _g.length) > 0 ? (React.createElement("p", null,
                    React.createElement(semantic_ui_react_1.Icon, { name: "magic" }),
                    " ", (_j = (_h = results.data.transactions[0]) === null || _h === void 0 ? void 0 : _h.logs) === null || _j === void 0 ? void 0 :
                    _j.length,
                    ' ',
                    "rule",
                    ' ',
                    ((_l = (_k = results.data.transactions[0]) === null || _k === void 0 ? void 0 : _k.logs) === null || _l === void 0 ? void 0 : _l.length) > 1
                        ? 's were '
                        : 'was ',
                    ' ',
                    "applied to this transaction.",
                    ' ',
                    React.createElement("b", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            var _m;
                            _transactions.modalData.current = Object.assign(Object.assign({}, transaction), (_m = results.data) === null || _m === void 0 ? void 0 : _m.transactions[0]);
                            setShowModal(true);
                            setModalView('VIEW_TRANSACTION_LOG');
                        }), className: "dark-green-hover font--bold clickable color--green" }, "View change history."))) : null;
                // Show success message
                _showToast({
                    message: 'Successfully created transaction',
                    autoDismissTimeout: 3000,
                    content,
                    type: 'success',
                });
            }
            return true;
        }
    });
    // update is a singular update
    // remove is an array of ids
    // add is an array of hydrated transaction objects
    // Updates all the transaction arrays in place
    const amendTransactions = (opts) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        let shouldTriggerUpdate = false;
        opts.remove = (_a = opts.remove) === null || _a === void 0 ? void 0 : _a.map(id => {
            return parseInt(id);
        });
        // Transform tags, will come in as ids
        if (opts.bulkUpdate && opts.bulkUpdate.length > 0) {
            for (let i = 0; i < opts.bulkUpdate.length; i++) {
                opts.bulkUpdate[i].ids = (((_b = opts.bulkUpdate[i]) === null || _b === void 0 ? void 0 : _b.ids) || []).map(o => parseInt(o));
                if (((_c = opts.bulkUpdate[i].update) === null || _c === void 0 ? void 0 : _c.hasOwnProperty('tags')) &&
                    !!opts.bulkUpdate[i].update['tags'] &&
                    opts.bulkUpdate[i].update['tags'] !== 'remove') {
                    opts.bulkUpdate[i].update['tags'] = opts.bulkUpdate[i].update['tags'].map(tag => {
                        if (tag.id && tag.name) {
                            // Comes here from Apply Rules
                            return tag;
                        }
                        else {
                            // Comes here from bulk-select
                            return {
                                id: tag,
                                name: _tags.getTagName(tag),
                            };
                        }
                    });
                }
            }
        }
        if (((_d = opts.update) === null || _d === void 0 ? void 0 : _d.hasOwnProperty('tags')) &&
            !!opts.update['tags'] &&
            opts.update['tags'] !== 'remove') {
            opts.update['tags'] = opts.update['tags'].map(tag => {
                if (tag.id && tag.name) {
                    return tag;
                }
                else {
                    return {
                        id: tag,
                        name: _tags.getTagName(tag),
                    };
                }
            });
        }
        opts.remove = opts.remove || [];
        if (currentPeriod) {
            opts.add =
                ((_e = opts.add) === null || _e === void 0 ? void 0 : _e.filter(o => {
                    if (o.date &&
                        Moment(o.date).format('MMM') !=
                            Moment(currentPeriod).format('MMM')) {
                        return false;
                    }
                    return true;
                }).map(o => {
                    return Object.assign({ _highlight: true }, o);
                })) || [];
        }
        // Update transaction with results.data
        mapTransactions(tx => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            const override = {};
            if (opts.removeGroup == tx.group_id) {
                override['group_id'] = null;
            }
            if (!!((_a = opts.updateMultiple) === null || _a === void 0 ? void 0 : _a.find(o => o.id == tx.id))) {
                const updateObj = opts.updateMultiple.find(o => o.id == tx.id);
                return Object.assign(Object.assign(Object.assign(Object.assign({ _highlight: true }, tx), override), updateObj), ((updateObj === null || updateObj === void 0 ? void 0 : updateObj.hasOwnProperty('tag_type')) ||
                    (updateObj === null || updateObj === void 0 ? void 0 : updateObj.hasOwnProperty('tags'))
                    ? {
                        tags: (updateObj === null || updateObj === void 0 ? void 0 : updateObj.tag_type) == 'replace' // or append
                            ? updateObj === null || updateObj === void 0 ? void 0 : updateObj.tags
                            : _.uniqBy([...((updateObj === null || updateObj === void 0 ? void 0 : updateObj.tags) || []), ...(tx.tags || [])], 'id'),
                    }
                    : {}));
            }
            if (tx.id === ((_b = opts.update) === null || _b === void 0 ? void 0 : _b.id)) {
                return Object.assign(Object.assign(Object.assign(Object.assign({ _highlight: true }, tx), override), opts.update), (((_c = opts.update) === null || _c === void 0 ? void 0 : _c.hasOwnProperty('tag_type')) ||
                    ((_d = opts.update) === null || _d === void 0 ? void 0 : _d.hasOwnProperty('tags'))
                    ? {
                        tags: ((_e = opts.update) === null || _e === void 0 ? void 0 : _e.tag_type) == 'replace' // or append
                            ? (_f = opts.update) === null || _f === void 0 ? void 0 : _f.tags
                            : _.uniqBy([...(((_g = opts.update) === null || _g === void 0 ? void 0 : _g.tags) || []), ...(tx.tags || [])], 'id'),
                    }
                    : {}));
            }
            else if (opts.remove.indexOf(tx.id) > -1) {
                return null;
            }
            if (opts.bulkUpdate && opts.bulkUpdate.length > 0) {
                for (let i = 0; i < opts.bulkUpdate.length; i++) {
                    //  || opts.bulkUpdate[i].where is like { recurring_id: X }
                    let shouldUpdate = false;
                    if (opts.bulkUpdate[i].ids.length > -1 &&
                        opts.bulkUpdate[i].ids.indexOf(tx.id) > -1) {
                        shouldUpdate = true;
                    }
                    else if (opts.bulkUpdate[i].hasOwnProperty('where')) {
                        // Go through each property in the where object
                        shouldUpdate = Object.keys(opts.bulkUpdate[i].where).every(prop => tx[prop] === opts.bulkUpdate[i].where[prop]);
                    }
                    if (shouldUpdate) {
                        if (((_h = opts.bulkUpdate[i].update) === null || _h === void 0 ? void 0 : _h.hasOwnProperty('date')) ||
                            ((_j = opts.bulkUpdate[i].update) === null || _j === void 0 ? void 0 : _j.hasOwnProperty('category_id')) ||
                            ((_k = opts.bulkUpdate[i].update) === null || _k === void 0 ? void 0 : _k.hasOwnProperty('asset_id')) ||
                            ((_l = opts.bulkUpdate[i].update) === null || _l === void 0 ? void 0 : _l.hasOwnProperty('tags')) ||
                            opts.bulkUpdate[i].hasOwnProperty('subtract')) {
                            shouldTriggerUpdate = true;
                        }
                        return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ _highlight: true }, tx), override), opts.bulkUpdate[i].update), { 
                            // amount must be in primary currency for grouped tx!
                            amount: tx.amount - (opts.bulkUpdate[i].subtract || 0), to_base: tx.to_base - (opts.bulkUpdate[i].subtract || 0) }), (opts.bulkUpdate[i].update.hasOwnProperty('tag_type') ||
                            opts.bulkUpdate[i].update.hasOwnProperty('tags')
                            ? {
                                tags: opts.bulkUpdate[i].update['tag_type'] == 'replace' // or append
                                    ? opts.bulkUpdate[i].update['tags']
                                    : _.uniqBy([
                                        ...(opts.bulkUpdate[i].update['tags'] || []),
                                        ...(tx.tags || []),
                                    ], 'id'),
                            }
                            : {}));
                    }
                }
            }
            return tx;
        }, opts.add);
        if (((_f = opts.update) === null || _f === void 0 ? void 0 : _f.hasOwnProperty('date')) ||
            ((_g = opts.update) === null || _g === void 0 ? void 0 : _g.hasOwnProperty('category_id')) ||
            ((_h = opts.update) === null || _h === void 0 ? void 0 : _h.hasOwnProperty('asset_id')) ||
            ((_j = opts.update) === null || _j === void 0 ? void 0 : _j.hasOwnProperty('tags')) ||
            ((_k = opts.update) === null || _k === void 0 ? void 0 : _k.hasOwnProperty('amount')) ||
            ((_l = opts.remove) === null || _l === void 0 ? void 0 : _l.length) > 0 ||
            ((_m = opts.add) === null || _m === void 0 ? void 0 : _m.length) > 0 ||
            shouldTriggerUpdate) {
            setTriggerRefresh(new Date().getTime());
        }
        resetBulkSelect();
    };
    const mapTransactions = (fn, add = []) => {
        allTransactions.current = [
            ...(allTransactions.current || []).map(fn).filter(o => !!o),
            ...add,
        ];
        setFilteredTransactions(prev => {
            return [...prev.map(fn).filter(o => !!o), ...add];
        });
        visibleTransactions.current = [
            ...(visibleTransactions.current || []).map(fn).filter(o => !!o),
            ...add,
        ];
        setVisibleTransactionsUnique();
        pendingTransactions.current = [
            ...(pendingTransactions.current || []).map(fn).filter(o => !!o),
        ];
    };
    (0, react_1.useEffect)(() => {
        if (!!filteredTransactions) {
            setUnreviewedIds(filteredTransactions === null || filteredTransactions === void 0 ? void 0 : filteredTransactions.filter(o => {
                try {
                    return (o.status == 'uncleared' &&
                        o.group_id == null &&
                        o.is_pending != true);
                }
                catch (e) {
                    console.log({ e, o });
                }
            }).map(o => o.id));
        }
    }, [filteredTransactions]);
    const onSave = (id, item) => __awaiter(void 0, void 0, void 0, function* () {
        var _o, _p, _q, _r, _s, _t, _u, _v, _w;
        const query = qs.parse(location.search);
        if (hasFilters || hasSearch) {
            setPreventUpdate(true);
        }
        else {
            setPreventUpdate(false);
        }
        const transaction = filteredTransactions
            .concat(pendingTransactions.current)
            .find(tx => {
            return tx.id === id;
        });
        let updateRecurring = null;
        // If this was a rcurring item's category, first, update recurring item
        if (item.hasOwnProperty('category_id') &&
            transaction.recurring_id &&
            transaction.recurring_type == 'cleared') {
            updateRecurring = transaction.recurring_id;
            yield (0, recurring_expenses_1.updateRecurringExpense)(transaction.recurring_id, {
                category_id: item['category_id'],
            });
            yield _recurring.fetchItems();
        }
        const override = {};
        // If the transction is uncleared, clear it
        // but only if the category changed OR a category exists
        if (_user.settings['auto_review_transaction_on_update'] &&
            transaction &&
            // If the transaction is currently uncleared
            transaction.status === 'uncleared' &&
            (transaction.category_id || item.hasOwnProperty('category_id')) &&
            // Don't clear if in unreviewed
            !((query === null || query === void 0 ? void 0 : query.unreviewed) === 'true') &&
            transaction.recurring_id == null) {
            override['status'] = 'cleared';
        }
        // Update transaction
        const results = yield _process(transactions_2.updateTransaction)(id, Object.assign(Object.assign({}, item), override));
        if (!results.error) {
            // Update transaction with results.data
            mapTransactions(tx => {
                var _a;
                if (tx.id === id ||
                    (updateRecurring !== null && tx.recurring_id == updateRecurring)) {
                    return Object.assign(Object.assign({}, tx), (_a = results.data) === null || _a === void 0 ? void 0 : _a.updated_fields);
                }
                else {
                    return tx;
                }
            }, ((_p = (_o = results.data) === null || _o === void 0 ? void 0 : _o.updated_fields) === null || _p === void 0 ? void 0 : _p.split) || []);
            // Callback, if any
            updateCallback && updateCallback();
            // Is there a filter and will it be outdated with this update?
            // Possibility 1: category filter
            if ((query === null || query === void 0 ? void 0 : query.unreviewed) &&
                (item.hasOwnProperty('status') || override.hasOwnProperty('status'))) {
                setShowReapply(true);
            }
            else if (((query === null || query === void 0 ? void 0 : query.category) || (query === null || query === void 0 ? void 0 : query.category_exclude) || (query === null || query === void 0 ? void 0 : query.uncategorized)) &&
                item.hasOwnProperty('category_id')) {
                setShowReapply(true);
            }
            // Should we be updating the summaries?
            // Don't update if clearing/unclearing, updating notes or payee
            if (!item.hasOwnProperty('notes') &&
                !item.hasOwnProperty('payee') &&
                !item.hasOwnProperty('status')) {
                // Trigger summary refresh
                setTriggerRefresh(new Date().getTime());
            }
            // See about the rules
            let toastId = null;
            let content = null;
            // Has similar transactions
            if (allowApplyRules &&
                results.data.criteriaId &&
                results.data.transactionRuleCount > 0) {
                content = (React.createElement("p", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        if (results.data.isSuggestedRule) {
                            yield _process(rules_1.approveRule)(results.data.criteriaId);
                        }
                        _removeToast(toastId);
                        setShowDryRunRules(results.data.criteriaId);
                    }), className: "dark-green-hover mt-05rem mb-0 font--bold clickable color--green" },
                    React.createElement(semantic_ui_react_1.Icon, { name: "tasks" }),
                    " Apply to",
                    ' ',
                    React.createElement("b", null, results.data.transactionRuleCount),
                    " similar transactions"));
            }
            else if (((_q = results.data) === null || _q === void 0 ? void 0 : _q.num_applied_rules) > 0) {
                content = (React.createElement("p", null,
                    React.createElement(semantic_ui_react_1.Icon, { name: "magic" }),
                    " ", (_r = results.data) === null || _r === void 0 ? void 0 :
                    _r.num_applied_rules,
                    " rule",
                    ((_s = results.data) === null || _s === void 0 ? void 0 : _s.num_applied_rules) > 1 ? 's were ' : 'was ',
                    " applied to this updated transaction.",
                    ' ',
                    React.createElement("b", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            var _x;
                            _transactions.modalData.current = Object.assign(Object.assign({}, transaction), (_x = results.data) === null || _x === void 0 ? void 0 : _x.updated_fields);
                            setShowModal(true);
                            setModalView('VIEW_TRANSACTION_LOG');
                        }), className: "dark-green-hover font--bold clickable color--green" }, "View change history.")));
            }
            if (((_t = results.data.asset_update) === null || _t === void 0 ? void 0 : _t.length) &&
                ((_u = results.data.asset_update[0]) === null || _u === void 0 ? void 0 : _u.hasOwnProperty('balance')) &&
                ((_v = results.data.asset_update[0]) === null || _v === void 0 ? void 0 : _v.hasOwnProperty('currency')) &&
                ((_w = results.data.asset_update[0]) === null || _w === void 0 ? void 0 : _w.hasOwnProperty('name'))) {
                setBalances(results.data.asset_update);
            }
            else if (!!content) {
                _showToast({
                    message: 'Successfully updated transaction',
                    autoDismissTimeout: 3000,
                    content,
                    type: 'success',
                    callback: id => {
                        toastId = id;
                    },
                });
            }
            else {
                _showToast({
                    message: 'Successfully updated transaction',
                    type: 'success',
                });
            }
        }
        return true;
    });
    /**
     * Transaction UI building
     */
    /**
     * Trigger construct transaction if the url changes due to Filters
     * Doesn't account for if the period has changed?
     */
    const updateHasFilters = () => {
        const query = qs.parse(location.search);
        const results = (query.match && query.time) ||
            (query.time && query.time != 'month') ||
            query.category ||
            query.category_exclude ||
            query.uncategorized ||
            query.is_income ||
            query.account ||
            query.account_exclude ||
            query.asset ||
            query.asset_exclude ||
            query.tag ||
            query.tag_exclude ||
            query.status ||
            query.status_exclude ||
            query.type ||
            query.type_exclude ||
            query.untagged ||
            query.recurring ||
            query.recurring_exclude ||
            query.payee_contain ||
            query.payee_start_with ||
            query.payee_exactly ||
            query.notes_contain ||
            query.notes_start_with ||
            query.notes_exactly ||
            query.expense_less_than ||
            query.expense_less_equal ||
            query.expense_greater_than ||
            query.expense_greater_equal ||
            query.expense_exactly ||
            query.expense_between ||
            query.income_less_than ||
            query.income_less_equal ||
            query.income_greater_than ||
            query.income_greater_equal ||
            query.income_exactly ||
            query.income_between;
        setHasFilters(!!results);
        setHasSearch(!!query.search);
    };
    /**
     * Approves a recurring expense via transaction row
     */
    const clearRecurringId = (recurring_id) => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield _process(recurring_expenses_2.clearByRecurringId)(recurring_id);
        if (!results.error) {
            mapTransactions(tx => {
                if (tx.recurring_id === recurring_id) {
                    return Object.assign(Object.assign({}, tx), { status: 'cleared' });
                }
                else {
                    return tx;
                }
            });
            // Show success message
            _showToast({
                message: 'Successfully updated transaction and approved recurring item',
                type: 'success',
            });
        }
    });
    /**
     * Dismisses a recurring expense via transaction row
     */
    const dismissRecurringId = (recurring_id_1, ...args_1) => __awaiter(void 0, [recurring_id_1, ...args_1], void 0, function* (recurring_id, showToast = true) {
        const results = yield _process(recurring_expenses_2.dismissByRecurringId)(recurring_id);
        if (!results.error) {
            mapTransactions(tx => {
                if (tx.recurring_id == recurring_id) {
                    return Object.assign(Object.assign({}, tx), { recurring_id: null, status: 'uncleared' });
                }
                else {
                    return tx;
                }
            });
            if (showToast) {
                // Show success message
                _showToast({
                    message: 'Successfully updated transaction and dismissed recurring item',
                    type: 'success',
                });
            }
        }
    });
    const addNewTransaction = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!allowNewTransaction)
            return;
        setShowNewTransactionRow(true);
    });
    /**
     * Rendering transaction rows
     */
    (0, react_1.useEffect)(() => {
        setColSpan(18 -
            2 * (hiddenCols || []).length -
            (allowBulkSelect ? 0 : 2) -
            (preventModal ? 1 : 0));
    }, [hiddenCols, allowBulkSelect, preventModal]);
    (0, react_1.useEffect)(() => {
        if (filteredTransactions == null) {
            return;
        }
        const transactions = handleSort(filteredTransactions);
        try {
            const query = qs.parse(history.location.search);
            const rows = [];
            const pending = [];
            const pendingTx = [];
            const visible = [];
            const _allTransactions = hasSearch || hasFilters || !!transactions.find(o => o.is_pending)
                ? transactions
                : transactions.concat(pendingTransactions.current);
            _allTransactions.forEach((tx, index) => {
                const row = (React.createElement(TransactionRow_1.default, { key: `transaction-${tx.id}`, onSave: onSave, showYear: showYearOverride || (queryTime && queryTime != 'month'), onOpenModal: (view, data) => {
                        _transactions.modalData.current = tx.parent ? tx.parent : data;
                        _modal.setShowModalView(view);
                        //   setShowModal(true)
                        //   setModalView(view)
                    }, hiddenCols: hiddenCols, readOnly: false, preventModal: preventModal, dismissRecurring: dismissRecurringId, transaction: tx, syncSelect: syncSelect, showBulkSelect: allowBulkSelect, onBulkSelect: () => {
                        var _a, _b, _c;
                        let shouldSelectAll = false;
                        if ((shiftClicked === null || shiftClicked === void 0 ? void 0 : shiftClicked.current) && lastSelectedIndex.current !== null) {
                            // Is there a chance this is a deselect?
                            // Only if the selected index is now unselected
                            const txsInRange = transactions
                                .concat(pendingTransactions.current)
                                .slice(Math.min(index, lastSelectedIndex.current), Math.max(index + 1, lastSelectedIndex.current + 1));
                            if (!_transactions.bulkSelected.current[tx.id]) {
                                let modifiedIndex;
                                if (index > lastSelectedIndex.current) {
                                    modifiedIndex = index - 1;
                                }
                                else {
                                    modifiedIndex = index + 1;
                                }
                                // If every single one is selected, then deselect
                                const txsToTest = transactions
                                    .concat(pendingTransactions.current)
                                    .slice(Math.min(modifiedIndex, lastSelectedIndex.current), Math.max(modifiedIndex, lastSelectedIndex.current));
                                txsToTest.forEach(o => {
                                    // If there is a single unselected, then we need to select all
                                    if (!_transactions.bulkSelected.current[o.id]) {
                                        shouldSelectAll = true;
                                    }
                                });
                            }
                            else {
                                shouldSelectAll = true;
                            }
                            txsInRange.forEach(tx => {
                                if (shouldSelectAll) {
                                    _transactions.bulkSelected.current[tx.id] = tx;
                                }
                                else {
                                    delete _transactions.bulkSelected.current[tx.id];
                                }
                            });
                            setSyncSelect(new Date().getTime());
                        }
                        lastSelectedIndex.current = index;
                        const num = ((_c = (_b = Object.values((_a = _transactions.bulkSelected) === null || _a === void 0 ? void 0 : _a.current)) === null || _b === void 0 ? void 0 : _b.filter(o => !!o)) === null || _c === void 0 ? void 0 : _c.length) || 0;
                        setNumBulkSelected(num);
                    }, tagView: tagView, utils: {
                        _process,
                        _showToast,
                        amendTransactions,
                    }, handleTagUpdate: updatedTx => {
                        mapTransactions(tx => {
                            if (tx.id === updatedTx.id) {
                                return updatedTx;
                            }
                            else {
                                return tx;
                            }
                        });
                        const query = qs.parse(history.location.search);
                        if (query === null || query === void 0 ? void 0 : query.untagged) {
                            setShowReapply(true);
                        }
                    } }));
                if (tx.is_pending || tx.status === 'pending') {
                    // Do nothing
                    pending.push(row);
                    pendingTx.push(tx);
                }
                else if (!tx.has_children && !tx.group_id) {
                    // Normal transaction
                    visible.push(tx);
                    rows.push(row);
                }
                else if (tx.group_id &&
                    ((query === null || query === void 0 ? void 0 : query.account) || (query === null || query === void 0 ? void 0 : query.asset) || (query === null || query === void 0 ? void 0 : query.search) || (query === null || query === void 0 ? void 0 : query.type))) {
                    visible.push(tx);
                    rows.push(row);
                }
            });
            // Recalculate uncleared
            setUnclearedCount(filteredTransactions === null || filteredTransactions === void 0 ? void 0 : filteredTransactions.filter(o => !o.group_id && !o.has_children && o.status === 'uncleared').length);
            setTransactionsUI([...rows]);
            setPendingUI(pending);
            updateHasFilters();
            if (!preventUpdate) {
                setShowReapply(false);
            }
            setIsFetching(false);
            setIsLoading(false);
            visibleTransactions.current = _user.settings['include_pending_in_totals']
                ? pendingTx.concat(visible)
                : visible;
            setVisibleTransactionsUnique();
            if (visible.length > limit * skip) {
                // Reset skip
                setSkip(0);
            }
        }
        catch (e) {
            console.log('Error constructing transactions', e);
        }
    }, [
        filteredTransactions,
        tagView,
        syncSelect,
        preventUpdate,
        hiddenCols,
        hasSearch,
        hasFilters,
    ]);
    const bulkReview = (ids) => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield _process(transactions_2.bulkUpdateTransactions)({
            transactionIds: ids,
            updateObj: { status: 'cleared' },
        });
        if (!results.error) {
            amendTransactions({
                bulkUpdate: [
                    {
                        ids: ids,
                        update: { status: 'cleared' },
                    },
                ],
            });
            _showToast({
                message: `Successfully marked ${ids === null || ids === void 0 ? void 0 : ids.length} transaction
          ${(ids === null || ids === void 0 ? void 0 : ids.length) > 1 ? 's' : ''} as reviewed`,
                type: 'success',
            });
        }
    });
    // Tries to get latest from Plaid
    const triggerFetch = () => __awaiter(void 0, void 0, void 0, function* () {
        if (_assets.plaidAccounts.length === 0) {
            return;
        }
        setIsFetching(true);
        try {
            cancelAutofetch.current = false;
            const results = yield (0, plaid_1.fetchTransactions)({
                start_date: currentPeriod,
                end_date: (0, dates_1.getThisMonthEnd)(currentPeriod),
            });
            if (results.error) {
                setIsFetching(false);
                // Show error message
                _showToast({
                    message: results.error && results.error.hasOwnProperty('message')
                        ? results.error.message
                        : 'Some data could not load; please try again later or check your connected Accounts.',
                    type: 'error',
                });
            }
            else {
                _showToast({
                    message: 'Fetching transactions... page will auto-refresh once this goes away! More transactions may still come in over the next few minutes.',
                    type: 'info',
                    autoDismissTimeout: 7000,
                });
                setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
                    if (cancelAutofetch.current) {
                        cancelAutofetch.current = false;
                    }
                    else {
                        // This will trigger a refresh for Plaid accounts
                        yield _assets.fetchAssets(new Date().getTime());
                        setSkip(0);
                        setTriggerRefresh(new Date().getTime());
                        constructTransactions();
                    }
                }), 6500);
            }
        }
        catch (e) {
            setIsFetching(false);
            // Show error message
            _showToast({
                message: 'Some data could not load; please try again later or check your connected Accounts.',
                type: 'error',
            });
        }
    });
    const dedupe = () => __awaiter(void 0, void 0, void 0, function* () {
        const getKey = o => {
            const key = [];
            if (dedupeKeys['date']) {
                key.push(o.formatted_date);
            }
            if (dedupeKeys['amount']) {
                key.push(o.to_base);
                key.push(o.currency);
            }
            if (dedupeKeys['payee']) {
                key.push(o.payee);
            }
            if (dedupeKeys['account']) {
                key.push(o.plaid_account_id);
                key.push(o.asset_id);
            }
            return key.join('-');
        };
        // exclude split and group
        const transactions = visibleTransactions.current.filter(o => !o.in_group && !o.is_group && !o.has_children && !o.has_parent);
        const group = _.groupBy(transactions, o => getKey(o));
        Object.values(group).forEach((txs, index) => {
            if (txs.length > 1) {
                if (dedupeSelection == 'recent') {
                    // Keep the most recent
                    const mostRecent = txs.reduce((prev, current) => {
                        return Moment(prev.created_at).isAfter(Moment(current.created_at))
                            ? prev
                            : current;
                    });
                    txs.forEach(tx => {
                        if (tx.id != mostRecent.id) {
                            _transactions.bulkSelected.current[tx.id] = tx;
                        }
                    });
                }
                else if (dedupeSelection == 'first') {
                    // Keep the least recent
                    const leastRecent = txs.reduce((prev, current) => {
                        return Moment(prev.created_at).isBefore(Moment(current.created_at))
                            ? prev
                            : current;
                    });
                    txs.forEach(tx => {
                        if (tx.id != leastRecent.id) {
                            _transactions.bulkSelected.current[tx.id] = tx;
                        }
                    });
                }
            }
        });
        const numSelected = Object.keys(_transactions.bulkSelected.current).length;
        setNumBulkSelected(numSelected);
        if (numSelected == 0) {
            _showToast({
                message: 'No duplicate transactions were detected.',
                type: 'info',
            });
        }
        setSyncSelect(new Date().getTime());
        setShowDedupe(false);
    });
    const constructTransactions = (...args_2) => __awaiter(void 0, [...args_2], void 0, function* (currentPeriodOverride = null) {
        var _y;
        const isSpecialTime = ['year', 'last-year', 'last-month', 'all', 'custom'].indexOf((_y = qs.parse(location.search)) === null || _y === void 0 ? void 0 : _y.time) > -1;
        // TODO: Why?
        const query = Object.assign(Object.assign(Object.assign({}, (currentPeriodOverride
            ? { currentPeriod: currentPeriodOverride }
            : {})), (isSpecialTime ? qs.parse(location.search) : {})), (transactionQuery || {}));
        let _finalTransactions;
        if (isSpecialTime ||
            allTransactions.current.length == 0 ||
            (query.start_date &&
                query.end_date &&
                allTransactions.current.filter(tx => {
                    // It's on this month, but make sure we have transactions from there
                    // This can happen if we land on a certain month with a filter for dates
                    // outside of that range
                    return Moment(tx.formatted_date).isBetween(query.start_date, query.end_date, undefined, '[]');
                }).length == 0)) {
            // We need to fetch from the server
            if (isEmbedded) {
                setIsFetching(true);
            }
            else {
                setIsLoading(true);
            }
            const transactionResults = yield (0, transactions_2.getTransactions)(Object.assign({}, (0, transactions_1.buildQuery)(query, localStorage.getItem('_lm_transactions_current_period'))));
            allTransactions.current = transactionResults.filter(o => !o.is_pending);
            pendingTransactions.current = transactionResults.filter(o => o.is_pending);
            _finalTransactions = transactionResults;
        }
        if (isSpecialTime ||
            !query.hasOwnProperty('time') ||
            (query === null || query === void 0 ? void 0 : query.time) == 'month') {
            // Build off of existing transactions
            const filterFns = (0, transactions_1.buildFilters)(Object.assign(Object.assign({}, query), qs.parse(location.search)), {
                currency: _user.primaryCurrency,
                allow_recurring_categories: _user.settings['allow_recurring_categories'],
                include_pending_in_totals: _user.settings['include_pending_in_totals'],
            });
            const _newTx = filterFns.length > 0
                ? [...pendingTransactions.current, ...allTransactions.current].filter(tx => {
                    var _a;
                    /* Pending? */
                    if (!_user.settings['include_pending_in_totals'] &&
                        tx.is_pending) {
                        return false;
                    }
                    const results = filterFns.map(fn => {
                        // Result can be null, undefined, empty string,..
                        // Normalize to return true or false
                        return fn(tx) == true;
                    });
                    if (((_a = qs.parse(location.search)) === null || _a === void 0 ? void 0 : _a.match) == 'all') {
                        // Must match all, aka have no falses
                        return _.indexOf(results, false) == -1;
                    }
                    else {
                        // Match any, aka have at least one true
                        // Comes here if none is set
                        return _.indexOf(results, true) > -1;
                    }
                })
                : [...pendingTransactions.current, ...allTransactions.current]; // No filters, use just allTransactions
            _finalTransactions = [..._newTx];
        }
        if (typeof _finalTransactions !== 'undefined') {
            setFilteredTransactions(_finalTransactions);
        }
        else {
            setFilteredTransactions([...filteredTransactions]);
        }
    });
    const resetBulkSelect = () => {
        _transactions.bulkSelected.current = {};
        setNumBulkSelected(0);
        setSyncSelect(new Date().getTime());
    };
    const hasOnlyOneNonPrimaryCurrency = () => {
        const currencies = [];
        Object.values(_transactions.bulkSelected.current).forEach(tx => {
            if (tx['currency'] == _user.primaryCurrency) {
                // If even one of the tx is in primary currency, it's false condition
                currencies.push(tx['currency']);
                return false;
            }
            if (!!tx['currency'] && currencies.indexOf(tx['currency']) == -1) {
                // We only want to track currencies that are not the primary currency
                // and appear at least once
                currencies.push(tx['currency']);
            }
        });
        return currencies.length == 1;
    };
    const calculateTotal = (amountVar, currencyVar = null) => {
        const selected = Object.values(_transactions.bulkSelected.current);
        return (0, format_1.toPrice)(_user.settings['show_debits_as_negative']
            ? -1 *
                selected
                    .map(o => (o ? Number(o[amountVar]) : 0))
                    .reduce((acc, cur) => {
                    return cur + acc;
                }, 0)
            : selected
                .map(o => (o ? Number(o[amountVar]) : 0))
                .reduce((acc, cur) => {
                return cur + acc;
            }, 0), currencyVar ? selected[0]['currency'] : _user.primaryCurrency);
    };
    const handleSort = tx => {
        const query = qs.parse(location.search);
        let sortKey = (query === null || query === void 0 ? void 0 : query.sort) || 'date';
        if (sortKey === 'amount') {
            sortKey = 'to_base';
        }
        else if (sortKey === 'payee') {
            sortKey = 'display_name';
        }
        else if (sortKey === 'notes') {
            sortKey = 'display_notes';
        }
        else if (sortKey === 'category') {
            sortKey = 'category_name';
        }
        const sorted = sortKey === 'to_base'
            ? _.sortBy(tx, ['to_base'])
            : tx.sort(function (a, b) {
                var _a, _b;
                if (sortKey == 'account') {
                    const compareA = ((_a = [
                        a.institution_name,
                        a.asset_institution_name,
                        a.asset_name,
                        a.plaid_account_name,
                    ]
                        .join(' ')) === null || _a === void 0 ? void 0 : _a.trim()) || '';
                    const compareB = ((_b = [
                        b.institution_name,
                        b.asset_institution_name,
                        b.asset_name,
                        b.plaid_account_name,
                    ]
                        .join(' ')) === null || _b === void 0 ? void 0 : _b.trim()) || '';
                    if (compareA.length == 0) {
                        return 1;
                    }
                    if (compareB.length == 0) {
                        return -1;
                    }
                    return compareA
                        .toLowerCase()
                        .localeCompare(compareB.toLowerCase());
                }
                if (!a[sortKey])
                    return 1;
                if (!b[sortKey])
                    return -1;
                if (a[sortKey] === b[sortKey]) {
                    return a.id - b.id;
                }
                return a[sortKey]
                    .trim()
                    .toLowerCase()
                    .localeCompare(b[sortKey].toLowerCase().trim());
            });
        return (query === null || query === void 0 ? void 0 : query.direction) == 'ascending' ? sorted : sorted.reverse();
    };
    return (React.createElement("div", { className: "g-transactions" },
        showHeader &&
            (queryTime && queryTime !== 'month' ? (React.createElement("div", { className: "toggle-header-container" },
                React.createElement(semantic_ui_react_1.Header, { as: "h1", id: "search-results-header" },
                    "Search Results",
                    ' ',
                    !isLoading && React.createElement(React.Fragment, null,
                        "(",
                        visibleTransactions.current.length,
                        ")")))) : (React.createElement(ContainerHeader_1.default, { backLink: {
                    pathname: `/transactions/${(0, dates_1.getPreviousMonth)(currentPeriod)
                        .replace('-', '/')
                        .substring(0, 7)}`,
                    search: history.location.search,
                }, forwardLink: {
                    pathname: `/transactions/${(0, dates_1.getNextMonth)(currentPeriod)
                        .replace('-', '/')
                        .substring(0, 7)}`,
                    search: history.location.search,
                }, title: currentPeriod
                    ? `${Moment(currentPeriod, 'YYYY-MM-DD').format('MMMM YYYY')}`
                    : '', dropdownOnChange: (e, { value }) => {
                    history.push({
                        pathname: `/transactions/${value}`,
                        search: location.search,
                    });
                }, dropdownOptions: transactionMonths, showBackToCurrentMonthButton: monthDifference !== 0, jumpBackToCurrentMonth: () => {
                    localStorage.setItem('_lm_transactions_current_period', `${Moment().format('YYYY-MM')}-01`);
                    if (_user.settings['link_months_between_views']) {
                        localStorage.setItem('_lm_recurring_current_period', `${Moment().format('YYYY-MM')}-01`);
                        localStorage.setItem('_lm_budget_current_period', `${Moment().format('YYYY-MM')}-01`);
                        localStorage.setItem('_lm_calendar_current_period', `${Moment().format('YYYY-MM')}-01`);
                    }
                    history.push({
                        pathname: `/transactions/${Moment().format('YYYY/MM')}`,
                        search: history.location.search,
                    });
                } }))),
        React.createElement("div", { className: "header-buttons" },
            React.createElement("div", { className: "width-100 flex--space-between-flex-end transaction-toolbar" },
                allowNewTransaction && (React.createElement("div", { className: "flex--space-between-flex-end" },
                    React.createElement("div", { className: "display--flex" },
                        React.createElement(semantic_ui_react_1.Button.Group, { className: "mr-05rem" },
                            React.createElement(semantic_ui_react_1.Button, { className: "hide-on-mobile", onClick: addNewTransaction },
                                "Add to",
                                ' ',
                                !isNaN(parseInt((_a = qs.parse(history.location.search)) === null || _a === void 0 ? void 0 : _a.asset))
                                    ? _assets.getAssetName(parseInt((_b = qs.parse(history.location.search)) === null || _b === void 0 ? void 0 : _b.asset))
                                    : _user.settings['default_asset_id']
                                        ? _.truncate(_assets.getAssetName(_user.settings['default_asset_id']), { length: 18 })
                                        : 'cash'),
                            React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                                    setShowModal(true);
                                    setModalView('ADD_TRANSACTION');
                                }, className: "display-on-mobile" }, "Add transaction"),
                            React.createElement(semantic_ui_react_1.Dropdown, { className: "button icon dropdown-menu add-to-cash-menu", trigger: React.createElement(React.Fragment, null) },
                                React.createElement(semantic_ui_react_1.Dropdown.Menu, null,
                                    _assets.getAssignableAccounts().map(o => {
                                        return (React.createElement(semantic_ui_react_1.Dropdown.Item, { key: `dropdown-asset-${o.id}`, content: `Add to ${o.display_name}`, className: "hide-on-mobile", value: o.id, onClick: () => {
                                                history.push({
                                                    search: `?${o.source == 'manual' ? 'asset' : 'account'}=${o.id}`,
                                                });
                                                addNewTransaction();
                                            } }));
                                    }),
                                    React.createElement(semantic_ui_react_1.Dropdown.Item, { key: `dropdown-asset-cash`, content: 'Add to Cash', className: "hide-on-mobile", value: 'cash', onClick: () => {
                                            history.push({
                                                search: `?asset=cash`,
                                            });
                                            addNewTransaction();
                                        } }),
                                    _assets.assets.length > 0 && (React.createElement(semantic_ui_react_1.Dropdown.Item, { key: `dropdown-asset-transfer`, content: 'Create transfer', icon: 'exchange', value: 'create_transfer', onClick: () => {
                                            // Open edit modal
                                            setShowModal(true);
                                            setModalView('CREATE_TRANSFER');
                                        } })),
                                    React.createElement(semantic_ui_react_1.Dropdown.Item, { key: `dropdown-asset-recurring`, content: 'Create from recurring', icon: 'repeat', value: 'create_recurring', onClick: () => {
                                            // Open edit modal
                                            setShowModal(true);
                                            setModalView('CREATE_RECURRING_TRANSACTION');
                                        } })))),
                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", trigger: React.createElement(semantic_ui_react_1.Button, { icon: true, labelPosition: "left", onClick: () => {
                                    history.push('/transactions/import');
                                } },
                                React.createElement(semantic_ui_react_1.Icon, { name: "upload" }),
                                "Import") }, "Upload transactions via CSV")))),
                React.createElement("div", { className: `transaction-toolbar-functions shown` },
                    showSearchAndFilter &&
                        (!hasFilters || queryTime == 'month') &&
                        !isLoading && (React.createElement(Search_1.default, { disabled: isLoading || isFetching || hasFilters, currentPeriod: currentPeriod })),
                    showSearchAndFilter && (React.createElement(Filter_1.default, { currentPeriod: currentPeriod, disabled: isLoading || isFetching, hasFiltersApplied: hasFilters })),
                    React.createElement("div", { className: "display--flex transaction-toolbar-buttons" },
                        showHeader && (React.createElement(React.Fragment, null,
                            React.createElement(semantic_ui_react_1.Modal, { open: showDedupe, onClose: () => setShowDedupe(false), size: "tiny" },
                                React.createElement(semantic_ui_react_1.Modal.Content, null,
                                    React.createElement(semantic_ui_react_1.Message, { info: true }, "Use this tool to automatically select duplicate transactions based on the following criteria. Always double-check before deleting as you may have legitimate multiple of the same transaction!"),
                                    React.createElement(semantic_ui_react_1.Form, null,
                                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                                React.createElement("label", null, "Deduplicate by:"),
                                                ['date', 'account', 'amount', 'payee'].map(key => (React.createElement(SelectLineItem_1.default, { keyValue: key, selectedValue: dedupeKeys[key] ? key : null, label: `${(0, format_1.capitalize)(key)} ${key == 'date' ||
                                                        key == 'account' ||
                                                        key == 'amount'
                                                        ? '(recommended)'
                                                        : ''}`, onClick: value => {
                                                        setDedupeKeys(Object.assign(Object.assign({}, dedupeKeys), { [key]: !dedupeKeys[key] }));
                                                    } })))))),
                                    Object.values(dedupeKeys).filter(o => o).length < 3 && (React.createElement(semantic_ui_react_1.Message, { warning: true, content: "Deduplication may not be effective if less than 3 values are selected. We highly recommend selecting at least 3 values. You must select at least 2 values." })),
                                    React.createElement(semantic_ui_react_1.Form, { className: "mb-0" },
                                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-0" },
                                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                                React.createElement("label", null, "Which transaction should be kept?"),
                                                React.createElement(SelectLineItem_1.default, { keyValue: 'recent', selectedValue: dedupeSelection, label: 'Keep the most recently added transaction', onClick: value => {
                                                        setDedupeSelection(value);
                                                    } }),
                                                React.createElement(SelectLineItem_1.default, { keyValue: 'first', selectedValue: dedupeSelection, label: 'Keep the earliest added transaction', onClick: value => {
                                                        setDedupeSelection(value);
                                                    } }))))),
                                React.createElement(semantic_ui_react_1.Modal.Actions, null,
                                    React.createElement(semantic_ui_react_1.Button, { disabled: Object.values(dedupeKeys).filter(o => o).length < 2, onClick: () => {
                                            dedupe();
                                        } }, "Select transactions to delete"))),
                            React.createElement(DisplayOptions_1.default, { hiddenCols: hiddenCols, setHiddenCols: setHiddenCols, tagView: tagView, setTagView: setTagView, setShowDedupe: () => {
                                    resetBulkSelect();
                                    setShowDedupe(true);
                                } }),
                            React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", position: "top right", trigger: React.createElement(react_csv_1.CSVLink, { uFEFF: false, filename: `lunchmoney-${Moment().format('YYYYMMDDHHmmss')}.csv`, data: visibleTransactions.current.map(tx => {
                                        return {
                                            date: tx.formatted_date,
                                            category: tx.recurring_id &&
                                                !_user.settings['allow_recurring_categories']
                                                ? '[recurring]'
                                                : (0, format_1.escapeCSV)(tx.category_name),
                                            payee: (0, format_1.escapeCSV)(tx.display_name) || (0, format_1.escapeCSV)(tx.payee),
                                            amount: _user.settings['show_debits_as_negative']
                                                ? -1 * tx.amount
                                                : tx.amount,
                                            currency: tx.currency,
                                            notes: (0, format_1.escapeCSV)(tx.display_notes) ||
                                                (0, format_1.escapeCSV)(tx.notes),
                                            tags: tx.tags
                                                ? (0, format_1.escapeCSV)(tx.tags.map(o => o.name).join(','))
                                                : '',
                                            account_name: (0, format_1.escapeCSV)(tx.plaid_account_name) ||
                                                (0, format_1.escapeCSV)(tx.asset_name) ||
                                                '',
                                            original_name: (0, format_1.escapeCSV)(tx.original_name) || '',
                                            transaction_id: tx.id,
                                            type: tx.parent_id
                                                ? 'split'
                                                : tx.is_group
                                                    ? 'group'
                                                    : tx.recurring_id &&
                                                        tx.recurring_type == 'cleared'
                                                        ? 'recurring'
                                                        : tx.group_id
                                                            ? 'part of group'
                                                            : '',
                                            parent_id: tx.parent_id || '',
                                            recurring_id: tx.recurring_id || '',
                                        };
                                    }) },
                                    React.createElement(semantic_ui_react_1.Button, { basic: true, color: "orange", style: { height: '100%' }, icon: true, className: "no-pointer-events" },
                                        React.createElement(semantic_ui_react_1.Icon, { name: "download" }))) }, "Export to CSV"))),
                        showPlaidRefresh &&
                            _assets.plaidAccounts.length > 0 &&
                            monthDifference <= 0 && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", position: "top right", trigger: React.createElement(semantic_ui_react_1.Button, { icon: true, basic: true, loading: isFetching, color: "orange", onClick: triggerFetch, className: "display--flex mr-0" },
                                React.createElement(semantic_ui_react_1.Icon, { name: "refresh" })) },
                            !isFetching &&
                                monthDifference == 0 &&
                                lastImport &&
                                `Updated ${Moment().to(Moment(lastImport.date))}`,
                            !isFetching &&
                                monthDifference == 0 &&
                                !lastImport &&
                                `Import the latest transactions for ${Moment(currentPeriod, 'YYYY-MM-DD').format('MMMM')}`,
                            !isFetching &&
                                monthDifference < 0 &&
                                `Update ${Moment(currentPeriod, 'YYYY-MM-DD').format('MMMM')} transactions`,
                            isFetching && `Updating...`)),
                        displaySidebar && (React.createElement(semantic_ui_react_1.Button, { className: "ml-05rem display-on-mobile", basic: !showSidebar, color: "orange", icon: true, onClick: () => {
                                localStorage.setItem('_lm_transactions_show_sidebar', showSidebar ? 'false' : 'true');
                                setShowSidebar(!showSidebar);
                            } },
                            React.createElement(semantic_ui_react_1.Icon, { name: showSidebar ? 'angle double right' : 'angle double left' }))))))),
        React.createElement("div", { className: `p-content-container ${isEmbedded ? 'mt-0 lower-min-height' : ''}` },
            React.createElement("div", { className: `left ${isEmbedded ? 'lower-min-height' : ''}`, id: "sticky-counterpart" },
                React.createElement("div", { className: `table-container ${isEmbedded ? 'lower-min-height' : ''}` },
                    React.createElement(semantic_ui_react_1.Table, { fixed: true, selectable: true, sortable: true, celled: true, className: `p-transactions-table ${isEmbedded ? 'no-border-radius no-border-top' : ''} ${isEmbedded && visibleTransactions.current.length <= LIMIT
                            ? 'no-border-bottom'
                            : ''}` },
                        React.createElement(TransactionTableHeader_1.default, { hiddenCols: hiddenCols, preventModal: preventModal, sortColumn: (_c = qs.parse(location.search)) === null || _c === void 0 ? void 0 : _c.sort, sortDirection: (_d = qs.parse(location.search)) === null || _d === void 0 ? void 0 : _d.direction, hasUnreviewed: unreviewedIds.length > 0, showBulkSelect: allowBulkSelect, numBulkSelected: numBulkSelected, checkReviewAll: () => {
                                setConfirmReviewAll(true);
                            }, onDeselectAll: () => {
                                resetBulkSelect();
                            }, onSelectAll: () => {
                                var _a, _b, _c;
                                visibleTransactions.current.forEach(tx => {
                                    _transactions.bulkSelected.current[tx.id] = tx;
                                });
                                setSyncSelect(new Date().getTime());
                                setNumBulkSelected(((_c = (_b = Object.values((_a = _transactions.bulkSelected) === null || _a === void 0 ? void 0 : _a.current)) === null || _b === void 0 ? void 0 : _b.filter(o => !!o)) === null || _c === void 0 ? void 0 : _c.length) || 0);
                            } }),
                        React.createElement(semantic_ui_react_1.Table.Body, null,
                            !isLoading && (hasFilters || hasSearch) && (React.createElement(ClearFilterRow_1.default, { cols: colSpan, showReapply: showReapply, onReapply: () => {
                                    setPreventUpdate(false);
                                    constructTransactions();
                                }, resetAllTransactions: () => {
                                    allTransactions.current = [];
                                } })),
                            showNewTransactionRow && (React.createElement(NewTransactionRow_1.default, { hiddenCols: hiddenCols, onSave: onCreate, currentPeriod: currentPeriod })),
                            isLoading && React.createElement(Loader_1.default, { colSpan: colSpan }),
                            isFetching && !isLoading && (React.createElement(Loader_1.default, { colSpan: colSpan, singleRow: true, text: "Fetching transactions.." })),
                            !isLoading && showPending && pendingUI,
                            !isLoading && pendingUI.length > 0 && (React.createElement(semantic_ui_react_1.Table.Row, { className: "no-hover-tr" },
                                React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: colSpan, className: "row-padding center-align clickable color--grey", onClick: () => {
                                        localStorage.setItem('_lm_transactions_show_pending', !showPending ? 'true' : 'false');
                                        setShowPending(!showPending);
                                    } }, showPending ? (React.createElement("span", null,
                                    React.createElement(semantic_ui_react_1.Icon, { name: "caret up" }),
                                    " Hide pending transactions")) : (React.createElement("span", null,
                                    React.createElement(semantic_ui_react_1.Icon, { name: "caret right" }),
                                    " Show pending transactions (",
                                    pendingUI.length,
                                    ")"))))),
                            !isLoading && transactionsUI.slice(skip, skip + limit),
                            !isLoading &&
                                pendingUI.length == 0 &&
                                transactionsUI.length == 0 && (React.createElement(EmptyState_1.default, { colSpan: colSpan, hasFilters: isEmbedded || hasFilters || hasSearch, addNewTransaction: addNewTransaction }))),
                        showTotalFooter && (React.createElement(semantic_ui_react_1.Table.Footer, null,
                            React.createElement(semantic_ui_react_1.Table.Row, { className: "transaction-row" },
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { colSpan: "4" }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "right-align", colSpan: "2" },
                                    "Total (",
                                    visibleTransactionsUnique.current.length,
                                    ")"),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "right-align color--dark-grey" }, (0, format_1.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) *
                                    visibleTransactionsUnique.current.reduce((acc, cur) => {
                                        return cur.to_base + acc;
                                    }, 0), _user.primaryCurrency)),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { colSpan: colSpan - 7 })))))),
                !isLoading && visibleTransactions.current.length > LIMIT && (React.createElement("div", { className: "pagination-container flex--space-between" },
                    React.createElement("div", { className: "pagination-amount" }, LIMIT_OPTIONS.map(option => {
                        return (React.createElement("a", { key: `limit-option-${option}`, onClick: () => {
                                setLimit(option);
                                localStorage.setItem('_lm_transactions_limit', option.toString());
                                setSkip(0);
                            }, className: `choice ${limit == option ? 'active' : ''}` }, option));
                    })),
                    Math.ceil(visibleTransactions.current.length / limit) > 1 && (React.createElement(semantic_ui_react_1.Pagination, { activePage: Math.floor(skip / limit) + 1, onPageChange: (e, { activePage }) => {
                            setSkip((activePage - 1) * limit);
                        }, totalPages: Math.ceil(visibleTransactions.current.length / limit) }))))),
            displaySidebar && (React.createElement("div", { className: `right ${showSidebar ? '' : 'hide-on-mobile'}` },
                React.createElement(Sticky_1.default, { forcePosition: numBulkSelected ? 'top' : null, reset: showSidebar || !isLoading || numBulkSelected || hasFilters
                        ? new Date().getTime()
                        : null, content: React.createElement(React.Fragment, null,
                        numBulkSelected > 0 && (React.createElement(semantic_ui_react_1.Card, null,
                            React.createElement(semantic_ui_react_1.Card.Content, null,
                                React.createElement(semantic_ui_react_1.Card.Header, null, "Selected Transactions")),
                            React.createElement(semantic_ui_react_1.Card.Content, null,
                                React.createElement("div", { className: "card-content-wrapper footer" },
                                    React.createElement("div", { className: "card-content mb-05rem" },
                                        React.createElement("span", { className: `card-text` },
                                            React.createElement("b", null, "Average Amount")),
                                        React.createElement("span", { className: "card-number no-wrap" }, (0, format_1.toPrice)(_user.settings['show_debits_as_negative']
                                            ? (-1 *
                                                Object.values(_transactions.bulkSelected.current)
                                                    .map(o => (o ? o['to_base'] : 0))
                                                    .reduce((acc, cur) => {
                                                    return cur + acc;
                                                }, 0)) /
                                                Object.values(_transactions.bulkSelected.current).length
                                            : Object.values(_transactions.bulkSelected.current)
                                                .map(o => (o ? o['to_base'] : 0))
                                                .reduce((acc, cur) => {
                                                return cur + acc;
                                            }, 0) /
                                                Object.values(_transactions.bulkSelected.current).length, _user.primaryCurrency))),
                                    React.createElement("div", { className: "card-content" },
                                        React.createElement("span", { className: `card-text` },
                                            React.createElement("b", null, "Selected Total")),
                                        React.createElement("span", { className: "card-number no-wrap" }, calculateTotal('to_base'))),
                                    hasOnlyOneNonPrimaryCurrency() ? (React.createElement("div", { className: "card-content mt-05rem" },
                                        React.createElement("span", { className: `card-text` },
                                            React.createElement("b", null, "Selected Total")),
                                        React.createElement("span", { className: "card-number no-wrap" }, calculateTotal('amount', 'currency')))) : (React.createElement(React.Fragment, null))),
                                React.createElement(semantic_ui_react_1.Button, { fluid: true, className: "mt-05rem adjust", size: "small", icon: true, labelPosition: "left", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        yield bulkReview(Object.keys(_transactions.bulkSelected.current));
                                        resetBulkSelect();
                                    }) },
                                    React.createElement(semantic_ui_react_1.Icon, { name: "check circle" }),
                                    " Mark Reviewed (",
                                    numBulkSelected,
                                    ")"),
                                React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", icon: true, labelPosition: "left", className: "mt-05rem adjust", onClick: () => {
                                        if (numBulkSelected == 1 &&
                                            Object.keys(_transactions.bulkSelected.current)
                                                .length == 1) {
                                            // Open on individual screen
                                            const selected = Object.values(_transactions.bulkSelected.current)[0];
                                            _transactions.modalData.current = selected;
                                            setShowModal(true);
                                            setModalView('DETAILS_VIEW');
                                        }
                                        else {
                                            // Reset
                                            _transactions.modalData.current = {};
                                            // // Open edit modal
                                            setShowModal(true);
                                            setModalView('BULK_EDIT');
                                        }
                                    } },
                                    React.createElement(semantic_ui_react_1.Icon, { name: "pencil" }),
                                    " Bulk-Edit (",
                                    numBulkSelected,
                                    ")"),
                                React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", disabled: (numBulkSelected == 1 &&
                                        Object.keys(_transactions.bulkSelected.current)
                                            .length == 1) ||
                                        !!Object.values(_transactions.bulkSelected.current).find(o => o['is_group'] ||
                                            o['group_id'] ||
                                            (o['recurring_id'] &&
                                                o['recurring_type'] == 'cleared')), icon: true, labelPosition: "left", className: "mt-05rem adjust", onClick: () => {
                                        // Reset
                                        _transactions.modalData.current = {};
                                        // // Open edit modal
                                        setShowModal(true);
                                        setModalView('GROUP_VIEW');
                                    } },
                                    React.createElement(semantic_ui_react_1.Icon, { name: "object group" }),
                                    " Group (",
                                    numBulkSelected,
                                    ")"),
                                React.createElement(semantic_ui_react_1.Button, { fluid: true, color: "red", className: "mt-05rem adjust", size: "small", icon: true, labelPosition: "left", onClick: () => {
                                        // Reset
                                        setShowConfirmBulkDelete(true);
                                    } },
                                    React.createElement(semantic_ui_react_1.Icon, { name: "trash" }),
                                    " Delete (",
                                    numBulkSelected,
                                    ")"),
                                React.createElement(semantic_ui_react_1.Button, { fluid: true, basic: true, color: "orange", className: "mt-05rem adjust", size: "small", icon: true, labelPosition: "left", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        resetBulkSelect();
                                    }) },
                                    React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }),
                                    " Deselect All")))),
                        ((!!queryTime && queryTime !== 'month') ||
                            hasFilters ||
                            hasSearch) && (React.createElement(semantic_ui_react_1.Card, null,
                            React.createElement(semantic_ui_react_1.Card.Content, null,
                                React.createElement(semantic_ui_react_1.Card.Header, null, "Search Results")),
                            React.createElement(semantic_ui_react_1.Card.Content, null, isLoading ? (React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "tiny" })) : (React.createElement("div", { className: "card-content-wrapper footer" },
                                React.createElement("div", { className: "card-content mb-05rem" },
                                    React.createElement("span", { className: `card-text` },
                                        React.createElement("b", null, "Total transactions"),
                                        ' ',
                                        visibleTransactionsUnique.current.length !=
                                            visibleTransactions.current.length && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", position: "top center", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "There are grouped transactions present in these search results among their transaction group. To prevent double-counting, we've separated these out."))),
                                    React.createElement("span", { className: "card-number no-wrap" }, visibleTransactionsUnique.current.length !=
                                        visibleTransactions.current.length ? (React.createElement(React.Fragment, null,
                                        visibleTransactionsUnique.current.length,
                                        " (",
                                        visibleTransactions.current.length,
                                        ")")) : (visibleTransactions.current.length))),
                                React.createElement("div", { className: "card-content mb-05rem" },
                                    React.createElement("span", { className: `card-text` },
                                        React.createElement("b", null, "Average Amount"),
                                        ' ',
                                        visibleTransactionsUnique.current.length !=
                                            visibleTransactions.current.length && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", position: "top center", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "There are grouped transactions in these results that are not counted towards this calculation because the transaction group is also present in the results."))),
                                    React.createElement("span", { className: "card-number no-wrap" }, (0, format_1.toPrice)(((_user.settings['show_debits_as_negative']
                                        ? -1
                                        : 1) *
                                        visibleTransactionsUnique.current.reduce((acc, cur) => {
                                            return cur.to_base + acc;
                                        }, 0)) /
                                        visibleTransactionsUnique.current.length, _user.primaryCurrency))),
                                React.createElement("div", { className: "card-content" },
                                    React.createElement("span", { className: `card-text` },
                                        React.createElement("b", null, "Filtered Total"),
                                        ' ',
                                        visibleTransactionsUnique.current.length !=
                                            visibleTransactions.current.length && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", position: "top center", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "There are grouped transactions in these results that are not counted towards this calculation because the transaction group is also present in the results."))),
                                    React.createElement("span", { className: "card-number no-wrap" }, (0, format_1.toPrice)((_user.settings['show_debits_as_negative']
                                        ? -1
                                        : 1) *
                                        visibleTransactionsUnique.current.reduce((acc, cur) => {
                                            return cur.to_base + acc;
                                        }, 0), _user.primaryCurrency)))))))),
                        (!queryTime || queryTime == 'month') && (React.createElement(React.Fragment, null,
                            React.createElement(Transactions_1.default, { unclearedCount: unclearedCount }),
                            currentPeriod && (React.createElement(Summary_1.default, { currentPeriod: currentPeriod, triggerRefresh: triggerRefresh }))))) })))),
        showModal && (React.createElement(NewModal_1.default, { show: showModal, setShow: (show) => {
                setShowModal(show);
            }, data: {
                onCreate,
                onSave,
                amendTransactions,
                setShowDryRunRules,
                setTriggerRefresh,
                currentPeriod: currentPeriod,
                hasAssets: false,
                clearRecurring: clearRecurringId,
                dismissRecurring: dismissRecurringId,
                showConfirmBulkDelete: () => {
                    setShowConfirmBulkDelete(true);
                },
                resetAllTransactions: () => {
                    allTransactions.current = [];
                },
            }, utils: {
                _process: _process,
                _showToast: _showToast,
            }, view: modalView, closeCallback: () => __awaiter(void 0, void 0, void 0, function* () {
                resetBulkSelect();
            }) })),
        React.createElement(DryRunRules_1.default, { show: !!showDryRunRules, closeModal: () => {
                setShowDryRunRules(null);
            }, criteriaIds: [showDryRunRules], amendTransactions: amendTransactions, _showToast: _showToast, _process: _process, deleteRules: (ids) => __awaiter(void 0, void 0, void 0, function* () {
                yield _process(rules_1.deleteRule)(ids[0]);
            }) }),
        React.createElement(BalanceUpdater_1.default, { _showToast: _showToast, balances: balances }),
        React.createElement(ConfirmBulkDelete_1.default, { setBalances: setBalances, amendTransactions: amendTransactions, _process: _process, close: () => {
                setShowConfirmBulkDelete(false);
                setShowModal(false);
            }, show: showConfirmBulkDelete }),
        React.createElement(semantic_ui_react_1.Modal, { open: !!confirmReviewAll, size: 'tiny' },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Confirm bulk review"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("p", null,
                    "Are you sure you want to mark ", unreviewedIds === null || unreviewedIds === void 0 ? void 0 :
                    unreviewedIds.length,
                    " transaction",
                    (unreviewedIds === null || unreviewedIds === void 0 ? void 0 : unreviewedIds.length) > 1 ? 's' : '',
                    " as reviewed?")),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { basic: true, color: "orange", loading: confirmLoading, onClick: () => {
                        setConfirmReviewAll(null);
                    } }, "No, Cancel"),
                React.createElement(semantic_ui_react_1.Button, { loading: confirmLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setConfirmLoading(true);
                        yield bulkReview(unreviewedIds);
                        setConfirmLoading(false);
                        setConfirmReviewAll(null);
                    }) }, "Yes, Confirm")))));
};
exports.default = TransactionsContainer;
