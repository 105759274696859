"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagsProvider = exports.TagsContext = void 0;
const React = require("react");
const react_1 = require("react");
const tags_1 = require("@actions/tags");
const UserProvider_1 = require("@/providers/UserProvider");
const TagsContext = (0, react_1.createContext)({
    tags: [],
    colors: [],
    setTags: null,
    getTagName: tag_id => {
        return null;
    },
    insertTag: (obj, create = false) => { },
    removeTag: id => { },
    updateTagDetails: (id, obj) => { },
    updateTagColor: (id, bg, text) => { },
    fetchTags: () => { },
    isReady: false,
});
exports.TagsContext = TagsContext;
const TagsProvider = props => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    // Used for EditableCategory
    const [colors, setColors] = (0, react_1.useState)([]);
    // Used for Tags page (these include all categories w/ nested children)
    const [tags, setTags] = (0, react_1.useState)([]);
    const [isReady, setIsReady] = (0, react_1.useState)(false);
    const hasFetchedTags = React.useRef(false);
    /** GENERAL */
    (0, react_1.useEffect)(() => {
        console.log('[Tags Provider] Loaded.');
    }, []);
    (0, react_1.useEffect)(() => {
        if (_user.hasFetchedUser &&
            _user.hasFetchedSettings &&
            _user.hasFetchedAccountSettings &&
            !isReady &&
            !hasFetchedTags.current) {
            fetchTags();
        }
    }, [_user]);
    const fetchTags = () => __awaiter(void 0, void 0, void 0, function* () {
        hasFetchedTags.current = true;
        const tags = yield (0, tags_1.getTags)();
        const colors = yield (0, tags_1.getTagColors)();
        if (!tags.error && !colors.error) {
            // Alphabetical order please
            const sorted = (tags === null || tags === void 0 ? void 0 : tags.sort((a, b) => {
                return a.name
                    .trim()
                    .toLowerCase()
                    .localeCompare(b.name.toLowerCase().trim());
            })) || [];
            setTags(sorted);
            setColors(colors);
            setIsReady(true);
            console.log('[Tags Provider] Done fetching tags.');
        }
    });
    const getTagName = tag_id => {
        const found = tags.find(o => {
            return o.id == tag_id;
        });
        if (found) {
            return found.name;
        }
        else {
            return '';
        }
    };
    const insertTag = (obj_1, ...args_1) => __awaiter(void 0, [obj_1, ...args_1], void 0, function* (obj, skipCreate = false) {
        let result = obj;
        if (!skipCreate) {
            result = yield (0, tags_1.createTag)(Object.assign(Object.assign({}, obj), { background_color: '#eee', text_color: '#333' }));
            result = result.data;
        }
        setTags([...tags, result]);
        setColors(Object.assign(Object.assign({}, colors), { [result.id]: result }));
        return result.id;
    });
    const updateTagDetails = (id, obj) => __awaiter(void 0, void 0, void 0, function* () {
        // obj can have name or description
        yield (0, tags_1.updateTag)(id, obj);
        // Update tag
        setTags(tags.map(tag => {
            if (tag.id == id) {
                return Object.assign(Object.assign({}, tag), obj);
            }
            else {
                return tag;
            }
        }));
    });
    const updateTagColor = (id, background_color, text_color) => __awaiter(void 0, void 0, void 0, function* () {
        // Update on the server
        yield (0, tags_1.setTagColors)(id, {
            background: background_color,
            text: text_color,
        });
        // Update on the state
        const newColors = Object.assign({}, colors);
        if (newColors.hasOwnProperty(id)) {
            newColors[id].background_color = background_color;
            newColors[id].text_color = text_color;
        }
        else {
            // Defaults
            newColors[id] = {
                background_color: background_color,
                text_color: text_color,
            };
        }
        setColors(newColors);
    });
    const removeTag = (id) => __awaiter(void 0, void 0, void 0, function* () {
        setTags(tags.filter(tag => {
            return tag.id !== id;
        }));
        setColors(Object.assign(Object.assign({}, colors), { [id]: null }));
    });
    return (React.createElement(TagsContext.Provider, { value: {
            tags,
            colors,
            setTags,
            getTagName,
            insertTag,
            removeTag,
            updateTagDetails,
            updateTagColor,
            fetchTags,
            isReady,
        } }, props.children));
};
exports.TagsProvider = TagsProvider;
