"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateAccessToken = exports.revokeAccessToken = exports.getAccessTokens = exports.requestAccessToken = void 0;
const common_1 = require("./common");
const requestAccessToken = body => {
    return (0, common_1.post)(`api_tokens`, body);
};
exports.requestAccessToken = requestAccessToken;
const getAccessTokens = () => {
    return (0, common_1.get)(`api_tokens`);
};
exports.getAccessTokens = getAccessTokens;
const revokeAccessToken = id => {
    return (0, common_1.del)(`api_tokens/${id}`);
};
exports.revokeAccessToken = revokeAccessToken;
const updateAccessToken = (id, label) => {
    return (0, common_1.put)(`api_tokens/${id}`, { label });
};
exports.updateAccessToken = updateAccessToken;
