"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const NewModal_1 = require("@components/global/NewModal");
const budget_1 = require("@helpers/budget");
const dates_1 = require("@helpers/dates");
const budgets_1 = require("@actions/budgets");
const Loader_1 = require("@components/global/Loader");
const BudgetRow_1 = require("@components/Budget/BudgetRow");
const UserProvider_1 = require("@providers/UserProvider");
const Round_1 = require("@components/Budget/Round");
const PopupTransactions_1 = require("../Transactions/PopupTransactions");
const Compact = ({ _showToast, _removeToast, _process, currentPeriod, updateBudgetTotal, updateTaskCard, budgetHistory, fetchLatest, }) => {
    const history = (0, react_router_dom_1.useHistory)();
    const [openTransactionsTable, setOpenTransactionsTable] = (0, react_1.useState)(false);
    // Modal
    const [showModal, setShowModal] = (0, react_1.useState)(false);
    const [budgets, setBudgets] = (0, react_1.useState)([]);
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    const [round, setRound] = (0, react_1.useState)(parseFloat(localStorage.getItem('_lm_budget_round') || '1'));
    const [keepGroups, setKeepGroups] = (0, react_1.useState)(true);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [sortColumn, setSortColumn] = (0, react_1.useState)(null);
    const [sortDirection, setSortDirection] = (0, react_1.useState)('descending');
    const [selected, setSelected] = (0, react_1.useState)(null);
    // Settings popup
    const [openPopup, setOpenPopup] = (0, react_1.useState)(false);
    const [buttonIsLoading, setButtonIsLoading] = (0, react_1.useState)(false);
    const shouldRefreshBudget = (0, react_1.useRef)(false);
    const handleSort = column => {
        // Start as descending
        setKeepGroups(false);
        if (column == sortColumn) {
            setSortDirection(sortDirection == 'ascending' ? 'descending' : 'ascending');
            setBudgets(budgets.reverse());
        }
        else {
            // Keep direction, re-sort by new column
            const sorted = budgets.sort((a, b) => {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                if (column == 'category_name') {
                    return a[column]
                        .trim()
                        .toLowerCase()
                        .localeCompare(b[column].toLowerCase().trim());
                }
                else if (column == 'budget') {
                    if (!((_a = a.data) === null || _a === void 0 ? void 0 : _a.hasOwnProperty(currentPeriod)) ||
                        a.data[currentPeriod].budget_to_base == null) {
                        return 1;
                    }
                    else if (!((_b = b.data) === null || _b === void 0 ? void 0 : _b.hasOwnProperty(currentPeriod)) ||
                        b.data[currentPeriod].budget_to_base == null) {
                        return -1;
                    }
                    else {
                        return (b.data[currentPeriod].budget_to_base -
                            a.data[currentPeriod].budget_to_base);
                    }
                }
                else if (column == 'spending') {
                    const period = Moment(currentPeriod, 'YYYY-MM-DD').format('YYYY-MM-DD');
                    if (!((_c = a.data) === null || _c === void 0 ? void 0 : _c.hasOwnProperty(period)) ||
                        (!a.data[period].spending_to_base &&
                            !a.data[period].num_transactions)) {
                        return 1;
                    }
                    else if (!((_d = b.data) === null || _d === void 0 ? void 0 : _d.hasOwnProperty(period)) ||
                        (!b.data[period].spending_to_base &&
                            !b.data[period].num_transactions)) {
                        return -1;
                    }
                    else {
                        return (b.data[period].spending_to_base - a.data[period].spending_to_base);
                    }
                }
                else if (column == 'last_budget') {
                    const period = Moment(currentPeriod, 'YYYY-MM-DD')
                        .subtract(1, 'month')
                        .format('YYYY-MM-DD');
                    if (!((_e = a.data) === null || _e === void 0 ? void 0 : _e.hasOwnProperty(period)) ||
                        a.data[period].budget_to_base == null) {
                        return 1;
                    }
                    else if (!((_f = b.data) === null || _f === void 0 ? void 0 : _f.hasOwnProperty(period)) ||
                        b.data[period].budget_to_base == null) {
                        return -1;
                    }
                    else {
                        return b.data[period].budget_to_base - a.data[period].budget_to_base;
                    }
                }
                else if (column == 'last_spending') {
                    const period = Moment(currentPeriod, 'YYYY-MM-DD')
                        .subtract(1, 'month')
                        .format('YYYY-MM-DD');
                    if (!((_g = a.data) === null || _g === void 0 ? void 0 : _g.hasOwnProperty(period)) ||
                        (!a.data[period].spending_to_base &&
                            !a.data[period].num_transactions)) {
                        return 1;
                    }
                    else if (!((_h = b.data) === null || _h === void 0 ? void 0 : _h.hasOwnProperty(period)) ||
                        (!b.data[period].spending_to_base &&
                            !b.data[period].num_transactions)) {
                        return -1;
                    }
                    else {
                        return (b.data[period].spending_to_base - a.data[period].spending_to_base);
                    }
                }
                else if (column == 'last_difference') {
                    const period = Moment(currentPeriod, 'YYYY-MM-DD')
                        .subtract(1, 'month')
                        .format('YYYY-MM-DD');
                    const differenceA = (0, budget_1.calculateDifference)(a.data[period], 1, a.is_income);
                    const differenceB = (0, budget_1.calculateDifference)(b.data[period], 1, b.is_income);
                    if (differenceA == null) {
                        return 1;
                    }
                    if (differenceB == null) {
                        return -1;
                    }
                    return differenceB - differenceA;
                }
                else if (column == 'difference') {
                    const period = Moment(currentPeriod, 'YYYY-MM-DD').format('YYYY-MM-DD');
                    const differenceA = (0, budget_1.calculateDifference)(a.data[period], 1, a.is_income);
                    const differenceB = (0, budget_1.calculateDifference)(b.data[period], 1, b.is_income);
                    if (differenceA == null) {
                        return 1;
                    }
                    if (differenceB == null) {
                        return -1;
                    }
                    return differenceB - differenceA;
                }
            });
            setSortColumn(column);
            setBudgets(sorted);
        }
    };
    const deleteBudget = (_budget) => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield _process(budgets_1.removeBudget)({
            category_id: _budget.category_id,
            start_date: currentPeriod,
        });
        if (!results.error) {
            if (results.hasOwnProperty('data')) {
                // Update budget data in-line
                setBudgets(prevValue => prevValue.map(budget => {
                    var _a, _b, _c, _d, _e;
                    let budgetUpdates = {};
                    if (budget.category_id === _budget.category_id) {
                        if (budget.data.hasOwnProperty(currentPeriod)) {
                            const prevKey = ((_a = budget.data[currentPeriod]) === null || _a === void 0 ? void 0 : _a.prev_key) || null;
                            if (((_b = budget.config) === null || _b === void 0 ? void 0 : _b.auto_suggest) == 'budgeted' && prevKey) {
                                budgetUpdates = {
                                    is_automated: true,
                                    budget_to_base: budget.data[prevKey].budget_to_base,
                                    budget_amount: budget.data[prevKey].budget_amount,
                                    budget_currency: budget.data[prevKey].budget_currency,
                                };
                            }
                            else if (((_c = budget.config) === null || _c === void 0 ? void 0 : _c.auto_suggest) == 'fixed-rollover' &&
                                prevKey) {
                                budgetUpdates = {
                                    is_automated: true,
                                    budget_to_base: budget.config.to_base - budget.data[prevKey].difference,
                                    budget_amount: budget.config.to_base - budget.data[prevKey].difference,
                                    budget_currency: _user.primaryCurrency,
                                };
                            }
                            else if (((_d = budget.config) === null || _d === void 0 ? void 0 : _d.auto_suggest) == 'spent' && prevKey) {
                                budgetUpdates = {
                                    is_automated: true,
                                    budget_to_base: budget.data[prevKey].spending_to_base,
                                    budget_amount: budget.data[prevKey].spending_to_base,
                                    budget_currency: _user.primaryCurrency,
                                };
                            }
                            else if (((_e = budget.config) === null || _e === void 0 ? void 0 : _e.auto_suggest) == 'fixed') {
                                budgetUpdates = {
                                    is_automated: true,
                                    budget_to_base: budget.config.to_base,
                                    budget_amount: budget.config.amount,
                                    budget_currency: budget.config.currency,
                                };
                            }
                            else {
                                budgetUpdates = {
                                    is_automated: false,
                                    budget_to_base: null,
                                    budget_amount: null,
                                    budget_currency: null,
                                };
                            }
                        }
                    }
                    return Object.assign(Object.assign({}, budget), { data: Object.assign(Object.assign({}, budget.data), { [currentPeriod]: Object.assign(Object.assign({}, budget.data[currentPeriod]), budgetUpdates) }) });
                }));
                // Show success message
                _showToast({
                    message: 'Successfully deleted budget',
                    type: 'success',
                });
                updateBudgetTotal(results.data);
            }
            else {
                _showToast({
                    message: 'Something went wrong while deleting this budget. Please try again.',
                    type: 'error',
                });
            }
        }
    });
    const resetBudget = ({ updates, newBudgetTotal = null }) => {
        // Update budget data in-line
        setBudgets(prevValue => prevValue.map(budget => {
            let budgetUpdates = {};
            if (updates.hasOwnProperty(budget.category_id)) {
                budgetUpdates = {
                    budget_amount: updates[budget.category_id].amount,
                    budget_currency: updates[budget.category_id].currency,
                    budget_to_base: updates[budget.category_id].to_base,
                    is_automated: false,
                };
            }
            return Object.assign(Object.assign({}, budget), { data: Object.assign(Object.assign({}, budget.data), { [currentPeriod]: Object.assign(Object.assign({}, (budget.data[currentPeriod] || {})), budgetUpdates) }) });
        }));
        if (newBudgetTotal) {
            updateBudgetTotal(newBudgetTotal);
        }
    };
    const updateBudget = (category_id, update) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f;
        const results = yield _process(budgets_1.createBudget)(Object.assign({ category_id: category_id, start_date: currentPeriod }, update));
        if (!results.error) {
            if (((_a = results.data) === null || _a === void 0 ? void 0 : _a.hasOwnProperty('updatedToBase')) &&
                ((_b = results.data) === null || _b === void 0 ? void 0 : _b.hasOwnProperty('newBudgetTotal'))) {
                const updates = {};
                // Show success message
                updates[category_id] = Object.assign(Object.assign({}, update), { to_base: results.data.updatedToBase });
                if ((_d = (_c = results.data) === null || _c === void 0 ? void 0 : _c.parentUpdated) === null || _d === void 0 ? void 0 : _d.hasOwnProperty('category_id')) {
                    // Show success message
                    updates[results.data.parentUpdated.category_id] = {
                        amount: results.data.parentUpdated.amount,
                        currency: results.data.parentUpdated.currency,
                        to_base: results.data.parentUpdated.to_base,
                    };
                }
                resetBudget({
                    updates,
                    newBudgetTotal: results.data.newBudgetTotal,
                });
                _showToast({
                    message: `Successfully saved budget. ${((_f = (_e = results.data) === null || _e === void 0 ? void 0 : _e.parentUpdated) === null || _f === void 0 ? void 0 : _f.amount)
                        ? 'The budget for the category group was also updated.'
                        : ''}`,
                    type: 'success',
                });
                return true;
            }
            else {
                _showToast({
                    message: 'Something went wrong while updating this budget. Please try again.',
                    type: 'error',
                });
                return false;
            }
        }
        else {
            return false;
        }
    });
    const fetchData = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (shouldSetLoading = true) {
        if (shouldSetLoading) {
            setIsLoading(true);
        }
        yield fetchLatest({
            start_date: (0, dates_1.getPreviousMonth)(currentPeriod),
            end_date: (0, dates_1.getThisMonthEnd)(currentPeriod),
        });
        setIsLoading(false);
    });
    (0, react_1.useEffect)(() => {
        setIsLoading(true);
    }, [currentPeriod]);
    (0, react_1.useEffect)(() => {
        setBudgets(budgetHistory);
        setIsLoading(false);
    }, [budgetHistory]);
    (0, react_1.useEffect)(() => {
        if (budgets &&
            budgets.length &&
            currentPeriod ===
                Moment()
                    .startOf('month')
                    .format('YYYY-MM-DD')) {
            const _budgets = [...budgets.filter(o => !!o.category_id)];
            let count = _budgets.length;
            const parentIds = [];
            _budgets
                .sort((a, b) => {
                if (a.is_group && !b.is_group) {
                    return -1;
                }
                else {
                    return 1;
                }
            })
                .forEach(o => {
                if (parentIds.indexOf(o.group_id) > -1) {
                    // Parent already had budget
                    count--;
                }
                else if (o.data.hasOwnProperty(currentPeriod) &&
                    o.data[currentPeriod].hasOwnProperty('budget_amount') &&
                    o.data[currentPeriod].budget_amount !== null) {
                    count--;
                    if (o.is_group) {
                        parentIds.push(o.category_id);
                    }
                }
            });
            updateTaskCard(count);
        }
    }, [budgets]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex--end" },
            !keepGroups && (React.createElement(semantic_ui_react_1.Button, { size: "small", style: { maxHeight: '31px' }, basic: true, color: "orange", onClick: () => {
                    // Reset budget order
                    setBudgets(budgets.sort((a, b) => {
                        return a.order - b.order;
                    }));
                    setKeepGroups(true);
                    // Reset sorts
                    setSortColumn(null);
                    setSortDirection('descending');
                } }, "Reset view")),
            React.createElement(Round_1.default, { round: round, setRound: setRound }),
            React.createElement(semantic_ui_react_1.Popup, { position: 'bottom right', on: "hover", open: openPopup, onOpen: () => {
                    setOpenPopup(true);
                }, onClose: () => {
                    setOpenPopup(false);
                }, hoverable: true, trigger: React.createElement(semantic_ui_react_1.Button, { text: "Settings", icon: "ellipsis horizontal", size: "small", basic: true, color: "orange" }), content: React.createElement("div", null,
                    React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", position: "top center", trigger: React.createElement(semantic_ui_react_1.Button, { fluid: true, basic: true, color: "orange", loading: buttonIsLoading, size: "mini", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                var _g;
                                setButtonIsLoading(true);
                                const results = yield _process(budgets_1.copyBudget)(currentPeriod);
                                if (!results.error) {
                                    yield fetchData(false);
                                    (0, budget_1.handleBudgetResponse)(_showToast, results);
                                    if ((_g = results.data) === null || _g === void 0 ? void 0 : _g.budget_total) {
                                        updateBudgetTotal(results.data.budget_total);
                                    }
                                    setOpenPopup(false);
                                }
                                setButtonIsLoading(false);
                            }) },
                            "Copy last month's budget ",
                            React.createElement(semantic_ui_react_1.Icon, { name: "question circle" })) }, "Copy over last month's entire budget. This will override existing and rollover budgets."),
                    React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", position: "top center", trigger: React.createElement(semantic_ui_react_1.Button, { fluid: true, basic: true, color: "orange", loading: buttonIsLoading, className: "mt-05rem", size: "mini", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                setButtonIsLoading(true);
                                const results = yield _process(budgets_1.unsetMonthBudget)(currentPeriod);
                                if (!results.error) {
                                    updateBudgetTotal(0);
                                    yield fetchData(false);
                                    _showToast({
                                        message: "Successfully unset this month's budget.",
                                        type: 'success',
                                    });
                                    setOpenPopup(false);
                                }
                                setButtonIsLoading(false);
                            }) },
                            "Clear budget ",
                            React.createElement(semantic_ui_react_1.Icon, { name: "question circle" })) }, "Unset all of this month's budget")) })),
        React.createElement("div", { className: "table-container mt-1rem" },
            React.createElement(semantic_ui_react_1.Table, { unstackable: true, fixed: true, selectable: true, sortable: true, celled: true, className: "p-budget-table" },
                React.createElement(semantic_ui_react_1.Table.Header, { className: "sticky" },
                    React.createElement(semantic_ui_react_1.Table.Row, null,
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { sorted: sortColumn === 'category_name' ? sortDirection : null, onClick: () => {
                                handleSort('category_name');
                            }, className: "table-cell-category" }, "Category"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-delete" }),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { sorted: sortColumn === 'budget' ? sortDirection : null, onClick: () => {
                                handleSort('budget');
                            }, className: "right-align table-cell-input" },
                            React.createElement("span", { className: "right-align" }, "This Period's Budget"),
                            '  ',
                            React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "This is how much you expect to earn/spend in the category this period.")),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { sorted: sortColumn === 'spending' ? sortDirection : null, onClick: () => {
                                handleSort('spending');
                            }, className: "right-align table-cell-amounts" },
                            React.createElement("span", { className: "right-align" }, "This Period's Total"),
                            '  ',
                            React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "This is how much you earned/spent in the category this period.")),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { sorted: sortColumn === 'difference' ? sortDirection : null, onClick: () => {
                                handleSort('difference');
                            }, className: "right-align table-cell-amounts" },
                            "Difference",
                            ' ',
                            React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "This is the difference between how much you budgeted and your actual total. Depending on the category (treated as income or not), this will show as a positive or negative number.")),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { sorted: sortColumn === 'last_budget' ? sortDirection : null, onClick: () => {
                                handleSort('last_budget');
                            }, className: "right-align table-cell-amounts" },
                            "Last Period's Budget",
                            '  ',
                            React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "For comparison, this is how much you budgeted to earn/spend last period in this category.")),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { sorted: sortColumn === 'last_spending' ? sortDirection : null, onClick: () => {
                                handleSort('last_spending');
                            }, className: "right-align table-cell-amounts" },
                            "Last Period's Total",
                            '  ',
                            React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "For comparison, this is how much you actually earned/spent last period in this category.")),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { sorted: sortColumn === 'last_difference' ? sortDirection : null, onClick: () => {
                                handleSort('last_difference');
                            }, className: "right-align table-cell-amounts" },
                            "Difference",
                            ' ',
                            React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "This is the difference between how much you budgeted and your actual total. Depending on the category (treated as income or not), this will show as a positive or negative number.")),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "right-align table-cell-settings no-hover-td" }))),
                React.createElement(semantic_ui_react_1.Table.Body, { className: !isLoading && (budgets === null || budgets === void 0 ? void 0 : budgets.length) === 0 ? 'g-empty-body' : '' },
                    !isLoading && (budgets === null || budgets === void 0 ? void 0 : budgets.filter(o => o.category_id).length) === 0 && (React.createElement(semantic_ui_react_1.Table.Row, { className: "g-empty-row no-hover-tr" },
                        React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: "16", className: "center-align" },
                            React.createElement("div", { className: "empty-mascot" }),
                            React.createElement("p", null, "You have no budgets yet!"),
                            React.createElement("p", null,
                                "Get started by",
                                ' ',
                                React.createElement(react_router_dom_1.Link, { to: { pathname: '/categories' } }, "adding categories"),
                                ' ',
                                "and",
                                ' ',
                                React.createElement(react_router_dom_1.Link, { to: { pathname: '/transactions' } }, "creating transactions"),
                                ".")))),
                    !isLoading &&
                        (budgets === null || budgets === void 0 ? void 0 : budgets.map((budget, index) => {
                            var _a, _b;
                            if (budget.category_id) {
                                return (React.createElement(BudgetRow_1.default, { key: `budgets-${budget.category_id}-${((_a = budget.data[currentPeriod]) === null || _a === void 0 ? void 0 : _a.budget_amount) || 'none'}-${((_b = budget.data[currentPeriod]) === null || _b === void 0 ? void 0 : _b.budget_currency) || 'none'}`, currentPeriod: currentPeriod, onSave: updateBudget, onDelete: deleteBudget, round: round, keepGroups: keepGroups, budget: budget, config: budget.config, thisMonthData: budget.data[currentPeriod], openTransactions: (_c) => __awaiter(void 0, [_c], void 0, function* ({ start_date, end_date, total, }) {
                                        setSelected({
                                            category_id: budget.category_id,
                                            category_name: budget.category_name,
                                            start_date,
                                            end_date,
                                            total,
                                        });
                                        setOpenTransactionsTable(true);
                                    }), openModal: () => {
                                        setShowModal(true);
                                    } }));
                            }
                            else
                                return null;
                        })),
                    isLoading && React.createElement(Loader_1.default, { colSpan: "16" })))),
        showModal && (React.createElement(NewModal_1.default, { show: showModal, setShow: (show) => {
                setShowModal(show);
            }, data: {
                displayCurrency: _user.primaryCurrency,
                updateBudget,
                resetBudget,
                currentPeriod,
                updateBudgetHistoryConfig: (category_id, update) => {
                    setBudgets([
                        ...budgets.map(budget => {
                            var _a, _b, _c, _d, _e;
                            let budgetUpdates = {};
                            if (budget.category_id === category_id) {
                                if (update == null) {
                                    delete budget.config;
                                }
                                else {
                                    budget.config = Object.assign(Object.assign({}, budget.config), update);
                                }
                                const prevKey = ((_a = budget.data[currentPeriod]) === null || _a === void 0 ? void 0 : _a.prev_key) || null;
                                if (((_b = budget.config) === null || _b === void 0 ? void 0 : _b.auto_suggest) == 'budgeted' && prevKey) {
                                    budgetUpdates = {
                                        is_automated: true,
                                        budget_to_base: budget.data[prevKey].budget_to_base,
                                        budget_amount: budget.data[prevKey].budget_amount,
                                        budget_currency: budget.data[prevKey].budget_currency,
                                    };
                                }
                                else if (((_c = budget.config) === null || _c === void 0 ? void 0 : _c.auto_suggest) == 'fixed-rollover' &&
                                    prevKey) {
                                    budgetUpdates = {
                                        is_automated: true,
                                        budget_to_base: budget.config.to_base -
                                            budget.data[prevKey].difference,
                                        budget_amount: budget.config.to_base -
                                            budget.data[prevKey].difference,
                                        budget_currency: _user.primaryCurrency,
                                    };
                                }
                                else if (((_d = budget.config) === null || _d === void 0 ? void 0 : _d.auto_suggest) == 'spent' &&
                                    prevKey) {
                                    budgetUpdates = {
                                        is_automated: true,
                                        budget_to_base: budget.data[prevKey].spending_to_base,
                                        budget_amount: budget.data[prevKey].spending_to_base,
                                        budget_currency: _user.primaryCurrency,
                                    };
                                }
                                else if (((_e = budget.config) === null || _e === void 0 ? void 0 : _e.auto_suggest) == 'fixed') {
                                    budgetUpdates = {
                                        is_automated: true,
                                        budget_to_base: budget.config.to_base,
                                        budget_amount: budget.config.amount,
                                        budget_currency: budget.config.currency,
                                    };
                                }
                                else {
                                    budgetUpdates = {
                                        is_automated: false,
                                        budget_to_base: null,
                                        budget_amount: null,
                                        budget_currency: null,
                                    };
                                }
                                budget.data[currentPeriod] = Object.assign(Object.assign({}, budget.data[currentPeriod]), budgetUpdates);
                            }
                            return budget;
                        }),
                    ]);
                },
            }, utils: {
                _process,
                _showToast,
            }, view: 'SET_BUDGET_VIEW', closeCallback: () => { } })),
        React.createElement(PopupTransactions_1.default, { openTransactionsTable: openTransactionsTable, onClose: () => __awaiter(void 0, void 0, void 0, function* () {
                setOpenTransactionsTable(false);
                history.replace({
                    search: null,
                });
                if (shouldRefreshBudget.current) {
                    yield fetchData();
                    shouldRefreshBudget.current = false;
                }
            }), headerText: `${Moment(selected === null || selected === void 0 ? void 0 : selected.start_date).format('MMMM YYYY')} ${selected === null || selected === void 0 ? void 0 : selected.category_name}: ${selected === null || selected === void 0 ? void 0 : selected.total}`, query: Object.assign(Object.assign({ start_date: selected === null || selected === void 0 ? void 0 : selected.start_date, end_date: selected === null || selected === void 0 ? void 0 : selected.end_date, time: 'custom', match: 'all' }, (_user.settings['allow_recurring_categories']
                ? {}
                : { recurring_exclude: 'any' })), { 
                // exclude_pending: !_user.settings['include_pending_in_totals'],
                category: selected === null || selected === void 0 ? void 0 : selected.category_id }), _process: _process, _showToast: _showToast, _removeToast: _removeToast })));
};
exports.default = Compact;
