"use strict";
/**
 *  MergeRecurring.tsx
 *  Recurring expenses merge modal
 *  This is only accessed by suggested recurring items right now
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_1 = require("@helpers/format");
const react_1 = require("react");
const recurring_expenses_1 = require("@actions/recurring_expenses");
const ModalProvider_1 = require("@providers/ModalProvider");
const TransactionsProvider_1 = require("@providers/TransactionsProvider");
const UserProvider_1 = require("@/providers/UserProvider");
const RecurringProvider_1 = require("@/providers/RecurringProvider");
const MergeRecurring = ({ 
/** Recurring expense to be edited */
data, useModal, utils, setVisibility, switchView, }) => {
    var _a, _b, _c;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _modal = (0, react_1.useContext)(ModalProvider_1.ModalContext);
    const _recurring = (0, react_1.useContext)(RecurringProvider_1.RecurringContext);
    const _transactions = (0, react_1.useContext)(TransactionsProvider_1.TransactionsContext);
    const [expense, setExpense] = (0, react_1.useState)(((_b = (_a = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.recurring) || data.recurring);
    const [recurring, setRecurring] = (0, react_1.useState)(null);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        var _a, _b, _c, _d;
        useModal({
            header: (React.createElement("div", null,
                ((_b = (_a = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.previous) && (React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        var _a, _b;
                        switchView((_b = (_a = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.previous);
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " ", (_d = (_c = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 :
                    _d.previous_text)),
                React.createElement("span", { className: "header-text-large" }, "Merge recurring items"))),
            middleButton: React.createElement(React.Fragment, null),
            rightButton: (React.createElement(semantic_ui_react_1.Button, { loading: isLoading, disabled: isLoading, color: 'orange', onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    var _e, _f;
                    setIsLoading(true);
                    const results = yield (0, recurring_expenses_1.mergeSuggestedRecurring)({
                        source: expense,
                        dest_id: recurring,
                    });
                    if (!results.error) {
                        if (data.amendTransactions && ((_e = expense.transactions) === null || _e === void 0 ? void 0 : _e.length) > 0) {
                            data.amendTransactions({
                                bulkUpdate: [
                                    {
                                        ids: (_f = expense.transactions) === null || _f === void 0 ? void 0 : _f.map(o => o.id),
                                        update: {
                                            display_name: results.data.payee,
                                            display_notes: results.data.description,
                                            category_id: results.data.category_id,
                                            status: 'cleared',
                                            recurring_id: recurring,
                                            recurring_type: 'cleared',
                                        },
                                    },
                                ],
                            });
                        }
                        yield _recurring.fetchItems(); // Get new list of recurring
                        if (data.submitCallback) {
                            data.submitCallback();
                        }
                        if (data.removeSuggested) {
                            // Coming from Suggested Modal
                            data.removeSuggested(_modal.data.current.recurring.id);
                        }
                        utils._showToast({
                            message: 'Successfully merged recurring items',
                            type: 'success',
                        });
                        setIsLoading(false);
                        setVisibility(false);
                    }
                    else {
                        utils._showToast({
                            message: 'Something went wrong merging recurring items. Please try again.',
                            type: 'success',
                        });
                        setIsLoading(false);
                    }
                }) }, "Save Changes")),
            leftButton: (React.createElement(semantic_ui_react_1.Button, { loading: isLoading, disabled: isLoading, className: "no-border", color: 'orange', basic: true, onClick: () => {
                    setVisibility(false, true);
                } }, 'Close')),
        });
        return () => {
            useModal({
                header: React.createElement(React.Fragment, null),
                leftButton: React.createElement(React.Fragment, null),
                middleButton: React.createElement(React.Fragment, null),
                rightButton: React.createElement(React.Fragment, null),
            });
        };
    }, [isLoading, expense, recurring]);
    return (React.createElement("div", { id: "recurring-modal-detail-view", className: "g-recurring-modal" },
        React.createElement(semantic_ui_react_1.Message, { info: true },
            React.createElement("p", null, "Which recurring item should these transactions be linked to?"),
            React.createElement("p", null, "A new rule will be created for the merged recurring item to match future similar transactions.")),
        React.createElement("div", { className: "transaction-details" },
            React.createElement("h3", null, "Current recurring item"),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Transaction Original Name"),
                React.createElement("span", null, expense.payee)),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Originating Account"),
                React.createElement("span", null,
                    expense.institution_name || expense.asset_institution_name,
                    ' ',
                    expense.plaid_account_name ||
                        expense.asset_name ||
                        'Cash (none assigned)')),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Transaction Amount"),
                React.createElement("span", null,
                    React.createElement("p", null, (0, format_1.toPrice)(expense.flipSign ? -1 * expense.amount : expense.amount, expense.currency)))),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Paid on"),
                React.createElement("p", { className: "mb-1rem" }, expense.transactions ? ((_c = expense.transactions) === null || _c === void 0 ? void 0 : _c.map((transaction, index) => {
                    return (React.createElement("span", { className: "display--block", key: `transaction-date-${index}` },
                        React.createElement(semantic_ui_react_1.Icon, { name: "check circle", color: "green" }),
                        Moment(transaction.formatted_date || transaction.date).format(_user.getMonthDayYearFormat())));
                })) : (React.createElement("p", null, "Never.")))),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Merge with"),
                React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal mb-1rem", fluid: true, selection: true, value: recurring, placeholder: `Select recurring item`, options: _recurring.dropdownOptions.filter(o => o.value !== expense.id), onChange: (e, o) => {
                            setRecurring(o.value);
                        } }))))));
};
exports.default = MergeRecurring;
