"use strict";
/**
 *  EditModal.tsx
 *  Recurring expenses edit modal
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const React = require("react");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const transactions_1 = require("@actions/transactions");
const format_1 = require("@helpers/format");
const transactions_2 = require("@helpers/transactions");
const react_1 = require("react");
const ModalProvider_1 = require("@providers/ModalProvider");
const TransactionsProvider_1 = require("@providers/TransactionsProvider");
const UserProvider_1 = require("@providers/UserProvider");
const GroupSelectView = ({ transactions, setTransactions, parent, starterTransactions, switchView, _process, showChanges, }) => {
    var _a, _b, _c, _d;
    const _modal = (0, react_1.useContext)(ModalProvider_1.ModalContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _transactions = (0, react_1.useContext)(TransactionsProvider_1.TransactionsContext);
    const [openTransactionDropdown, setOpenTransactionDropdown] = (0, react_1.useState)(false);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [searchResults, setSearchResults] = (0, react_1.useState)([]);
    const [hasReturnedAll, setHasReturnedAll] = (0, react_1.useState)(false);
    const [dropdownOptions, setDropdownOptions] = (0, react_1.useState)([]);
    const [runningTotal, setRunningTotal] = (0, react_1.useState)(null);
    const [runningTotalCurrency, setRunningTotalCurrency] = (0, react_1.useState)(_user.primaryCurrency);
    const [nextPeriod, setNextPeriod] = (0, react_1.useState)(Moment(parent.date)
        .add(1, 'month')
        .format('YYYY-MM-DD'));
    const [prevPeriod, setPrevPeriod] = (0, react_1.useState)(Moment(parent.date)
        .subtract(1, 'month')
        .format('YYYY-MM-DD'));
    const [_searchQuery, setSearchQuery] = (0, react_1.useState)('');
    let searchQuery = (0, react_1.useRef)(null);
    let timeout = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        if (_modal.data.current.transactions) {
            // There were previous changes to transactions that we need to enforce from before going to edit transaction
            setTransactions(_modal.data.current.transactions);
            showChanges();
            _modal.data.current.transactions = null;
        }
        // Set event listeners for search
        const input = document.getElementById('search-dropdown').firstElementChild;
        input.addEventListener('keydown', handler, false);
        return () => {
            input.removeEventListener('keydown', handler);
        };
    }, [transactions, starterTransactions]);
    const handler = e => {
        if (e.keyCode == '37' ||
            e.keyCode == '38' ||
            e.keyCode == '39' ||
            e.keyCode == '40' ||
            e.keyCode == '13') {
            // arrows and enter
            return;
        }
        const input = document.getElementById('search-dropdown').firstElementChild;
        clearTimeout(timeout.current);
        // Make a new timeout set to go off in 1000ms (1 second)
        const timeoutId = setTimeout(function () {
            const search = input.value;
            performSearch(search);
        }, 750);
        timeout.current = timeoutId;
    };
    const resetPeriods = () => {
        setNextPeriod(Moment(parent.date)
            .add(1, 'month')
            .format('YYYY-MM-DD'));
        setPrevPeriod(Moment(parent.date)
            .subtract(1, 'month')
            .format('YYYY-MM-DD'));
    };
    const performSearch = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (term = null, time = 'month') {
        if ((term === null || term === void 0 ? void 0 : term.length) == 0) {
            // Reset everything
            searchQuery.current = null;
            setHasReturnedAll(false);
            resetPeriods();
            setSearchResults(starterTransactions === null || starterTransactions === void 0 ? void 0 : starterTransactions.filter(tx => {
                if (transactions[tx.id]) {
                    // Don't show already selected transactions
                    return false;
                }
                return true;
            }));
            setSearchQuery(null);
        }
        else if (term == searchQuery.current) {
            // Do nothing
            // Another keystroke was pressed but term is the same
        }
        else {
            if (term) {
                // Term could be null
                // This is a new term, so just search within starterTransactions
                searchQuery.current = term;
            }
            // Expanded transactions view has no starter transactions
            if (term && starterTransactions) {
                resetPeriods();
                const search = term.toLowerCase();
                setSearchResults(starterTransactions === null || starterTransactions === void 0 ? void 0 : starterTransactions.filter(tx => {
                    if (transactions[tx.id]) {
                        return false;
                    }
                    return ((tx.display_name &&
                        tx.display_name.toLowerCase().indexOf(search) >= 0) ||
                        (tx.display_notes &&
                            tx.display_notes.toLowerCase().indexOf(search) >= 0) ||
                        (tx.amount &&
                            (0, format_1.toPrice)(tx.amount, tx.currency).indexOf(search) >= 0) ||
                        (tx.amount && tx.amount.toString().indexOf(search) >= 0) ||
                        (tx.currency && tx.currency.toString().indexOf(search) >= 0));
                }));
            }
            else {
                setIsLoading(true);
                let _currentPeriod = null;
                if (time == 'all') {
                    setHasReturnedAll(true);
                }
                else if (time == 'prev') {
                    _currentPeriod = prevPeriod;
                    setPrevPeriod(Moment(prevPeriod)
                        .subtract(1, 'months')
                        .format('YYYY-MM-DD'));
                }
                else if (time == 'next') {
                    _currentPeriod = nextPeriod;
                    setNextPeriod(Moment(nextPeriod)
                        .add(1, 'months')
                        .format('YYYY-MM-DD'));
                }
                else {
                    // Current Period
                    _currentPeriod = Moment(parent.date).format('YYYY-MM-DD');
                }
                const results = yield _process(transactions_1.searchByString)({
                    term: searchQuery.current,
                    time: time == 'all' ? 'all' : 'month',
                    currentPeriod: _currentPeriod,
                });
                const filtered = results.data.filter(tx => {
                    if (transactions[tx.id]) {
                        // Don't show already selected transactions
                        return false;
                    }
                    if (tx.has_children) {
                        // Don't show parent transactions
                        return false;
                    }
                    if (tx.recurring_id) {
                        // Don't show recurring transactions
                        return false;
                    }
                    if (tx.is_group || tx.group_id) {
                        // Don't show other grouped tx or tx groups
                        return false;
                    }
                    if (tx.is_pending || tx.status.indexOf('delete') > -1) {
                        return false;
                    }
                    return true;
                });
                setIsLoading(false);
                setSearchResults([
                    ...(time == 'next' ? filtered : []),
                    // If it's an "all-time" search or a new term, then replace all search results
                    ...(time == 'all' || term ? [] : searchResults),
                    ...(time == 'prev' || time == 'all' || time == 'month'
                        ? filtered
                        : []),
                ]);
            }
        }
    });
    (0, react_1.useEffect)(() => {
        // Should we display running total in primary currency or transaction currency?
        // If all the currencies are the same, then use that
        // Otherwise, use primary
        const currencies = _.groupBy(Object.values(transactions), 'currency');
        let useOriginalCurrency = false;
        if (Object.keys(currencies).length == 1) {
            useOriginalCurrency = true;
        }
        setRunningTotal(Object.values(transactions).reduce((acc, cur) => {
            return (acc + (useOriginalCurrency ? Number(cur['amount']) : cur['to_base']));
        }, 0));
        setRunningTotalCurrency(useOriginalCurrency
            ? Object.values(transactions)[0]['currency']
            : _user.primaryCurrency);
    }, [transactions]);
    (0, react_1.useEffect)(() => {
        if (searchResults) {
            setDropdownOptions([
                ...getActions('prev'),
                ...searchResults.map((tx, index) => {
                    return toDropdownItem(tx);
                }),
                ...getActions('next'),
            ]);
        }
    }, [searchResults, isLoading, transactions, parent]);
    const toDropdownItem = tx => {
        return {
            key: `tx-item-${tx.id}`,
            value: tx.id,
            children: (React.createElement("div", { key: `tx-${tx.id}`, className: "grouped-transaction-details option" },
                React.createElement("div", null,
                    React.createElement("p", { className: "date-payee" },
                        Moment(tx.formatted_date).format(_user.getMonthDayFormat()),
                        " -",
                        ' ',
                        tx.payee),
                    tx.notes && (React.createElement("p", { className: "notes" },
                        React.createElement(semantic_ui_react_1.Icon, { name: "sticky note outline" }),
                        " ",
                        tx.notes)),
                    tx.plaid_account_name && (React.createElement("p", { className: "notes" },
                        React.createElement(semantic_ui_react_1.Icon, { name: "university" }),
                        ' ',
                        [tx.institution_name, tx.plaid_account_name].join(' '))),
                    tx.asset_name && (React.createElement("p", { className: "notes" },
                        React.createElement(semantic_ui_react_1.Icon, { name: "university" }),
                        ' ',
                        [tx.asset_institution_name, tx.asset_name].join(' ')))),
                React.createElement("span", { className: "no-wrap" }, (0, format_1.toPrice)(_user.settings['show_debits_as_negative']
                    ? -1 * tx.amount
                    : tx.amount, tx.currency)))),
            onClick: (e, { value }) => {
                if (value) {
                    addTransaction(tx);
                }
            },
        };
    };
    const addTransaction = tx => {
        // Add to transactions
        setTransactions(Object.assign(Object.assign({}, transactions), { [tx.id]: (0, transactions_2.toGroupFormat)(tx) }));
        // Remove from search results
        setSearchResults(searchResults.filter(o => o.id !== tx.id));
        // Set hasChanges for parent
        showChanges();
    };
    const getActions = position => {
        if ((hasReturnedAll && !isLoading) || searchQuery.current == null) {
            return [];
        }
        else if (isLoading) {
            return [
                {
                    key: `loading-${new Date().getTime() *
                        Math.floor(Math.random() * 11)}`,
                    children: (React.createElement("div", null,
                        React.createElement(semantic_ui_react_1.Loader, { active: true, inline: true, size: "mini" }),
                        " Loading transactions, please wait...")),
                    value: 'loading',
                    onClick: () => { },
                },
            ];
        }
        else {
            if (position == 'prev') {
                return [
                    {
                        key: 'search_next',
                        children: (React.createElement("div", null,
                            "... include",
                            ' ',
                            Moment(nextPeriod, 'YYYY-MM-DD').format(_user.getMonthYearFormat()),
                            ' ',
                            "transactions")),
                        value: 'search_next',
                        onClick: () => {
                            performSearch(undefined, 'next');
                        },
                    },
                ];
            }
            else {
                return [
                    {
                        key: 'search_previous',
                        children: (React.createElement("div", null,
                            "... include",
                            ' ',
                            Moment(prevPeriod, 'YYYY-MM-DD').format(_user.getMonthYearFormat()),
                            ' ',
                            "transactions")),
                        value: 'search_previous',
                        onClick: () => {
                            performSearch(undefined, 'prev');
                        },
                    },
                    {
                        key: 'search_all',
                        children: React.createElement("div", null, "... include transactions from all time"),
                        value: 'search_all',
                        onClick: () => {
                            performSearch(undefined, 'all');
                        },
                    },
                ];
            }
        }
    };
    return (React.createElement(React.Fragment, null,
        (!parent.is_group ||
            parent.id !== ((_b = (_a = _transactions === null || _transactions === void 0 ? void 0 : _transactions.modalData) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.id)) && (React.createElement("h2", { className: "center-align" }, "Transactions in Group")),
        React.createElement("div", { className: "grouped-tx-row mb-0" },
            React.createElement("div", { className: "width-100" },
                React.createElement("div", { className: "flex--space-between-flex-start" },
                    React.createElement("span", { className: "label-style" }, "Number of transactions"),
                    React.createElement("span", { className: "display--block monospace" }, (_c = Object.values(transactions)) === null || _c === void 0 ? void 0 : _c.length)),
                React.createElement("div", { className: "flex--space-between-flex-start" },
                    React.createElement("span", { className: "label-style" }, "Total"),
                    React.createElement("span", { className: "display--block monospace right-align" }, (0, format_1.toPrice)(_user.settings['show_debits_as_negative']
                        ? runningTotal * -1
                        : runningTotal, runningTotalCurrency))))),
        React.createElement("p", { className: "divider" }),
        React.createElement(semantic_ui_react_1.Dropdown, { loading: isLoading, id: "search-dropdown", noResultsMessage: "Start typing to search.", className: "mb-1rem category-dropdown p-category-modal", placeholder: "Search by payee name, amount or notes", fluid: true, minCharacters: 0, search: (e, searchTerm) => {
                return dropdownOptions;
            }, selection: true, onFocus: () => {
                setOpenTransactionDropdown(true);
            }, clearable: true, onBlur: () => {
                setOpenTransactionDropdown(false);
            }, selectOnNavigation: false, 
            // Only for visual purposes
            searchQuery: _searchQuery || undefined, onSearchChange: (e, data) => {
                setSearchQuery(data.searchQuery);
            }, onChange: (e_1, _e) => __awaiter(void 0, [e_1, _e], void 0, function* (e, { value }) {
                if (e.key == 'Enter') {
                    if (value) {
                        addTransaction(searchResults.find(tx => tx.id == parseInt(value)));
                    }
                }
            }), options: dropdownOptions, open: openTransactionDropdown }),
        transactions && Object.values(transactions).length > 0 ? ((_d = Object.values(transactions)) === null || _d === void 0 ? void 0 : _d.sort((a, b) => {
            return Moment(a['date']).isBefore(Moment(b['date'])) ? 1 : -1;
        }).map((tx, index) => {
            return (React.createElement("div", { key: `tx-${index}`, className: "grouped-tx-row" },
                React.createElement("div", { className: "grouped-tx-content" },
                    React.createElement("span", null,
                        React.createElement("b", null, Moment(tx.date).format(_user.getMonthDayFormat())),
                        React.createElement("span", null,
                            " ",
                            tx.payee)),
                    !!tx.notes && (React.createElement("div", { className: "notes" },
                        React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "sticky note outline" }),
                        " ",
                        tx.notes)),
                    !!(tx.asset || tx.plaid_account) ? (React.createElement("div", { className: "account-name" },
                        React.createElement(semantic_ui_react_1.Icon, { name: "university" }),
                        React.createElement("span", { className: "account" }, tx.asset || tx.plaid_account))) : ('')),
                React.createElement("div", { className: "grouped-tx-side" },
                    React.createElement("span", { className: "no-wrap monospace" }, (0, format_1.toPrice)(_user.settings['show_debits_as_negative']
                        ? -1 * tx.amount
                        : tx.amount, tx.currency)),
                    React.createElement("div", { className: "show-on-hover" },
                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "edit", color: "grey", className: "clickable mr-05rem", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    // Snapshot
                                    _modal.data.current.transactions = transactions;
                                    _modal.data.current.transaction = tx;
                                    switchView('GROUP_EDIT_TRANSACTION');
                                }) }), inverted: true, position: "top right", mouseEnterDelay: 500, size: "tiny" }, "Edit transaction"),
                        ' ',
                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "close", color: "grey", className: "clickable", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    const selected = Object.assign({}, transactions);
                                    delete selected[tx.id];
                                    setTransactions(selected);
                                    // Set hasChanges for parent
                                    showChanges();
                                }) }), position: "top right", inverted: true, mouseEnterDelay: 500, size: "tiny" }, "Remove transaction from group")))));
        })) : (React.createElement(semantic_ui_react_1.Loader, { active: true, inline: "centered", size: "tiny" }))));
};
exports.default = GroupSelectView;
