"use strict";
/**
 *  Transactions.tsx
 *  Container for the Transactions page
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const user_1 = require("@actions/user");
const semantic_ui_react_1 = require("semantic-ui-react");
const CannyRedirect = ({}) => {
    (0, react_1.useEffect)(() => {
        document.title = `Redirecting you to Canny - Lunch Money`;
        getToken();
    }, []);
    const getToken = () => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield (0, user_1.getCannyToken)();
        if (results) {
            window.location.href = `https://feedback.lunchmoney.app?ssoToken=${results}`;
        }
    });
    return (React.createElement("div", { className: "g-logged-out" },
        React.createElement("div", { className: "g-logged-out-container" },
            React.createElement("div", { className: "logged-out-content" },
                React.createElement(semantic_ui_react_1.Container, null,
                    React.createElement("div", { id: "logo-container" },
                        React.createElement("div", { id: "logo" }),
                        React.createElement("div", { id: "shadow" })),
                    React.createElement(semantic_ui_react_1.Header, { as: "h2", className: "center-align" }, "Please wait, redirecting you to Canny.."))))));
};
exports.default = CannyRedirect;
