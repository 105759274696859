"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.amendBalanceHistory = exports.removeFromBalanceHistory = void 0;
const ASSET_TYPES = [
    'employee compensation',
    'vehicle',
    'real estate',
    'cryptocurrency',
    'brokerage', //same1
    'investment', //same1
    'depository', //same2
    'cash', //same2
];
const removeFromBalanceHistory = (balanceHistory, whereArray) => {
    whereArray.forEach((obj) => {
        const { asset_id, plaid_account_id, zabo_account_id, zabo_currency } = obj;
        try {
            // Given some account, remove all traces from balanceHistory
            let is_asset;
            let type;
            // By accounts
            const byAccountMatch = balanceHistory.by_accounts.find(account => {
                return !!obj._id
                    ? account._id == obj._id
                    : account.asset_id == asset_id &&
                        account.plaid_account_id == plaid_account_id &&
                        account.zabo_account_id == zabo_account_id &&
                        account.zabo_currency == zabo_currency;
            });
            if (!!byAccountMatch) {
                type = byAccountMatch.type;
                is_asset = ASSET_TYPES.indexOf(byAccountMatch.type) > -1;
                byAccountMatch.data.forEach((o, dateIndex) => {
                    if (o !== null) {
                        // Deduct from net worth
                        if (is_asset) {
                            balanceHistory.net_worth[dateIndex] -= o.to_base;
                        }
                        else {
                            balanceHistory.net_worth[dateIndex] += o.to_base;
                        }
                    }
                });
                balanceHistory.by_accounts = balanceHistory.by_accounts.filter(account => {
                    return !(byAccountMatch._id == account._id);
                });
            }
            // By date
            const _id = obj._id ||
                [
                    '',
                    '',
                    asset_id || '',
                    plaid_account_id || '',
                    zabo_account_id || '',
                    zabo_currency || '',
                ].join('-');
            balanceHistory.by_date.forEach(dateData => {
                const accountObj = is_asset ? dateData.assets : dateData.liabilities;
                const matchInList = accountObj.by_account[_id];
                if (matchInList) {
                    // Subtract from total
                    accountObj.total -= matchInList.to_base;
                    // Subtract also net from networth
                    if (is_asset) {
                        dateData.net_worth -= matchInList.to_base;
                    }
                    else {
                        dateData.net_worth += matchInList.to_base;
                    }
                    // Remove from accountObj.list
                    delete accountObj.by_account[_id];
                    // Remove from by_type
                    const matchInListByType = accountObj.by_type[type].list.find(account => {
                        return (account._id == _id &&
                            account.asset_id == asset_id &&
                            account.plaid_account_id == plaid_account_id &&
                            account.zabo_account_id == zabo_account_id &&
                            account.zabo_currency == zabo_currency);
                    });
                    // Subtract from total
                    accountObj.by_type[type].total -= matchInListByType.to_base;
                    // Remove from accountObj.list
                    accountObj.by_type[type].list = accountObj.by_type[type].list.filter(account => {
                        return !(account._id == _id &&
                            account.asset_id == asset_id &&
                            account.plaid_account_id == plaid_account_id &&
                            account.zabo_account_id == zabo_account_id &&
                            account.zabo_currency == zabo_currency);
                    });
                }
            });
        }
        catch (e) {
            console.log({
                asset_id,
                plaid_account_id,
                zabo_account_id,
                zabo_currency,
                e,
            });
        }
    });
    return balanceHistory;
};
exports.removeFromBalanceHistory = removeFromBalanceHistory;
const amendBalanceHistory = (balanceHistory, { date, balance, to_base, currency, _id, asset_id, plaid_account_id, zabo_account_id, crypto_manual_id, zabo_balance, zabo_currency, account_name, account_institution_name, account_type, }) => {
    const ASSET_TYPES = [
        'employee compensation',
        'cash',
        'vehicle',
        'cryptocurrency',
        'investment',
        'real estate',
        'brokerage',
        'depository',
    ];
    const is_asset = ASSET_TYPES.indexOf(account_type) > -1;
    // Get index from timeArray
    const timeIndex = balanceHistory.time_array.indexOf(date);
    // Update by_date
    const dateMatch = balanceHistory.by_date[timeIndex];
    const dateByAccountMatch = (is_asset
        ? dateMatch.assets
        : dateMatch.liabilities).by_account[_id];
    let oldBalance = 0;
    // Update individual balance
    if (dateByAccountMatch) {
        oldBalance = dateByAccountMatch.to_base;
        dateByAccountMatch.balance = balance;
        dateByAccountMatch.currency = currency;
        dateByAccountMatch.to_base = to_base;
        dateByAccountMatch.zabo_balance = zabo_balance;
        dateByAccountMatch.zabo_currency = zabo_currency;
    }
    else {
        // Didn't previously exist.
        ;
        (is_asset ? dateMatch.assets : dateMatch.liabilities).by_account[_id] = {
            balance: balance,
            currency: currency,
            to_base: to_base,
            zabo_balance: null,
        };
    }
    // Update total
    ;
    (is_asset ? dateMatch.assets : dateMatch.liabilities).total =
        (is_asset ? dateMatch.assets : dateMatch.liabilities).total -
            oldBalance +
            to_base;
    // Update net_worth
    if (is_asset) {
        dateMatch.net_worth = +(dateMatch.net_worth -
            oldBalance +
            (to_base || 0)).toFixed(2);
    }
    else {
        dateMatch.net_worth = +(dateMatch.net_worth +
            oldBalance -
            (to_base || 0)).toFixed(2);
    }
    // Update by type
    let byTypeObj = (is_asset ? dateMatch.assets : dateMatch.liabilities).by_type[account_type];
    if (byTypeObj) {
        // There exists some other accounts of the same type
        const dateTypeAccountMatch = byTypeObj === null || byTypeObj === void 0 ? void 0 : byTypeObj.list.find(account => {
            return account._id == _id;
        });
        if (dateTypeAccountMatch) {
            // There was a previous balance here
            oldBalance = dateTypeAccountMatch.to_base;
            dateTypeAccountMatch.balance = balance;
            dateTypeAccountMatch.currency = currency;
            dateTypeAccountMatch.to_base = to_base;
            dateByAccountMatch.zabo_balance = zabo_balance;
            dateByAccountMatch.zabo_currency = zabo_currency;
        }
        else {
            // There was no previous balance so we have to add a new one
            byTypeObj === null || byTypeObj === void 0 ? void 0 : byTypeObj.list.push({
                balance: balance,
                currency: currency,
                to_base: to_base,
                zabo_balance: zabo_balance,
                zabo_currency: zabo_currency,
                asset_id: asset_id,
                history_id: new Date().getTime(),
                institution_name: account_institution_name,
                name: account_name,
                plaid_account_id: plaid_account_id,
                type: account_type,
                zabo_account_id: zabo_account_id,
                crypto_manual_id: crypto_manual_id,
            });
        }
        // Update by_type total
        byTypeObj.total = (byTypeObj.total || 0) - oldBalance + to_base;
    }
    else {
        // This type didn't exist before for this month!
        ;
        (is_asset ? dateMatch.assets : dateMatch.liabilities).by_type[account_type] = {
            list: [
                {
                    balance: balance,
                    currency: currency,
                    to_base: to_base,
                    zabo_balance: zabo_balance,
                    zabo_currency: zabo_currency,
                    asset_id: asset_id,
                    history_id: new Date().getTime(),
                    institution_name: account_institution_name,
                    name: account_name,
                    plaid_account_id: plaid_account_id,
                    type: account_type,
                    zabo_account_id: zabo_account_id,
                },
            ],
            total: to_base,
            type: account_type,
        };
        byTypeObj = (is_asset ? dateMatch.assets : dateMatch.liabilities).by_type[account_type];
    }
    // Update by_accounts
    const accountMatch = balanceHistory.by_accounts.find(account => {
        return account._id == _id;
    });
    // Update individual numbers
    if (accountMatch) {
        if (!!accountMatch.data[timeIndex]) {
            accountMatch.data[timeIndex].history_id = new Date().getTime();
            accountMatch.data[timeIndex].balance = balance;
            accountMatch.data[timeIndex].currency = currency;
            accountMatch.data[timeIndex].to_base = to_base;
            accountMatch.data[timeIndex].zabo_balance = zabo_balance;
            accountMatch.data[timeIndex].zabo_currency = zabo_currency;
        }
        else {
            accountMatch.data[timeIndex] = {
                balance,
                currency,
                to_base,
                zabo_balance,
                zabo_currency,
                history_id: new Date().getTime(),
            };
        }
    }
    // Update net worth
    if (is_asset) {
        balanceHistory.net_worth[timeIndex] = +(balanceHistory.net_worth[timeIndex] -
            oldBalance +
            (to_base || 0)).toFixed(2);
    }
    else {
        balanceHistory.net_worth[timeIndex] = +(balanceHistory.net_worth[timeIndex] +
            oldBalance -
            (to_base || 0)).toFixed(2);
    }
    return balanceHistory;
};
exports.amendBalanceHistory = amendBalanceHistory;
