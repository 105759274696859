"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const react_1 = require("react");
const format_1 = require("@helpers/format");
const semantic_ui_react_1 = require("semantic-ui-react");
const UserProvider_1 = require("@providers/UserProvider");
const format_2 = require("@helpers/format");
const TransactionTag_1 = require("../elements/TransactionTag");
const Report = ({ transformedData, datasetIndex, hiddenKeys, groupBy, timeGranularity, timeArray, numberType, filterDateStart, filterDateEnd, baseUrl, toQuery, expandCategories, averageRowCalculation, averageColCalculation, exportable, hasSecondaryBreakdown, openTable, }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [categoryCol, setCategoryCol] = (0, react_1.useState)([]);
    const [timeRow, setTimeRow] = (0, react_1.useState)([]);
    const [dataRows, setDataRows] = (0, react_1.useState)([]);
    const [totalsCol, setTotalsCol] = (0, react_1.useState)([]);
    const [totalsRow, setTotalsRow] = (0, react_1.useState)([]);
    const [averageRow, setAverageRow] = (0, react_1.useState)([]);
    const [expanded, setExpanded] = (0, react_1.useState)({});
    const [sortKey, setSortKey] = (0, react_1.useState)(null);
    const [sortDirection, setSortDirection] = (0, react_1.useState)('ascending');
    const toExportablePrice = price => {
        if (!price)
            return 0;
        let text = price;
        if (_user.settings['show_debits_as_negative']) {
            text = price * -1;
        }
        return text.toFixed(2);
    };
    const toDisplayPrice = (price, obj = {}, showPreview = true, postText = '') => {
        const query = Object.assign(Object.assign({}, baseUrl), obj);
        let text = '';
        let className = '';
        if (_user.settings['show_debits_as_negative']) {
            if (price < 0) {
                // This is income and should be positive and green
                className = 'color--green';
                text = (0, format_1.toPrice)(Math.abs(price), _user.primaryCurrency);
            }
            else {
                // This is expense and should be displayed with negative
                text = (0, format_1.toPrice)(price * -1, _user.primaryCurrency);
            }
        }
        else if (!_user.settings['show_debits_as_negative']) {
            if (price < 0) {
                // This is income and should be negative and green
                className = 'color--green';
                text = (0, format_1.toPrice)(price, _user.primaryCurrency);
            }
            else {
                // This is expense and should be displayed with positive
                text = (0, format_1.toPrice)(price, _user.primaryCurrency);
            }
        }
        return (React.createElement("div", { className: `position--relative hover-toggle ${showPreview ? 'clickable' : ''}`, onClick: () => {
                if (showPreview) {
                    openTable(query);
                }
            } },
            showPreview && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "search plus", fitted: true, style: { position: 'absolute', left: '3px' }, className: "show-on-hover color--dark-grey dark-grey-on-hover clickable" }), position: "top center", size: "mini", hoverable: true, inverted: true }, "View transactions (pop-up)")),
            React.createElement("span", { className: className },
                text,
                postText)));
    };
    const buildRow = (datum, grandTotal, grandCount, _categoryCol, _dataRows, _totalsCols, totalByTime, countByTime, numActive) => {
        const datum_id = datum.id || datum.parent_id;
        const datum_group_id = datum.group_id || datum.parent_group_id;
        const datumStatus = getDatumStatus(datum);
        if (!datumStatus.IS_HIDDEN) {
            if (datumStatus.IS_INDEPENDENT_TX ||
                datumStatus.IS_PARENT ||
                datumStatus.IS_EXPANDED ||
                datumStatus.HAS_HIDDEN_PARENT) {
                const classNames = `${datum.is_secondary && datum.name == 'Untagged' ? ' border-bottom' : ''}`;
                const exportRow = [];
                // Header name
                _categoryCol.push(React.createElement("div", { key: `column-name-${datum.id}-${datum.parent_id}`, className: `row ${datum.has_children && sortKey == null ? 'clickable' : ''} ${hasSecondaryBreakdown
                        ? datum.is_secondary
                            ? 'secondary'
                            : 'primary'
                        : ''}`, onClick: () => {
                        if (datum.has_children && sortKey == null) {
                            if (!expanded.hasOwnProperty(datum.id) && expandCategories) {
                                // By default it's open so set to closed
                                setExpanded(Object.assign(Object.assign({}, expanded), { [datum.id]: false }));
                            }
                            else {
                                // Already has setting, so just do the opposite
                                setExpanded(Object.assign(Object.assign({}, expanded), { [datum.id]: !expanded[datum.id] }));
                            }
                        }
                    } },
                    React.createElement("div", { className: `cell ${classNames} ${expanded[datum.id] ||
                            (datum.has_children &&
                                !expanded.hasOwnProperty(datum.id) &&
                                expandCategories)
                            ? 'font--bold'
                            : ''}` },
                        datum.has_children && sortKey == null && (React.createElement(semantic_ui_react_1.Icon, { fitted: true, className: "line-height--normal", name: expanded[datum.id] ||
                                (datum.has_children &&
                                    !expanded.hasOwnProperty(datum.id) &&
                                    expandCategories)
                                ? 'caret down'
                                : 'caret right' })),
                        datum_group_id &&
                            !sortKey &&
                            !datum.is_secondary &&
                            !datumStatus.HAS_HIDDEN_PARENT && (React.createElement("span", { className: "hierarchy-line-icon-a" })),
                        (!!sortKey || datumStatus.HAS_HIDDEN_PARENT) &&
                            datum.group_name ? (React.createElement(React.Fragment, null,
                            datum.group_name,
                            ":")) : null,
                        ' ',
                        datum.is_tag ? (React.createElement(TransactionTag_1.default, { circleOnly: true, readOnly: true, tag: datum.id, displayOverride: datum.name })) : (datum.name),
                        !!sortKey && datum.has_children && React.createElement("span", null, " (Group)"))));
                try {
                    exportRow.push((0, format_2.escapeCSV)(datum.group_name
                        ? `${datum.group_name}: ${datum.name}`
                        : datum.name));
                }
                catch (e) {
                    console.log(e, datum);
                }
                // All data
                const row = [];
                timeArray.forEach(date => {
                    if (datum.counts[date] > 0) {
                        const display = numberType == 'percentage'
                            ? (0, format_1.toPercentage)(datum.data[date] / totalByTime[date])
                            : toDisplayPrice(datum.data[date], toQuery(datum, date), true, groupBy == 'by_recurring' ? ` (${datum.counts[date]})` : '');
                        row.push(React.createElement("div", { key: `cell-${datum.id}-${date}`, className: `cell ${classNames} ${expanded[datum.id] ? 'font--bold' : ''}` }, display));
                        exportRow.push(numberType == 'percentage'
                            ? display
                            : toExportablePrice(datum.data[date]));
                    }
                    else {
                        row.push(React.createElement("div", { key: `cell-${datum.id}-${date}`, className: `cell ${classNames} color--grey` }, "-"));
                        exportRow.push(0);
                    }
                });
                _dataRows.push(React.createElement("div", { key: `cell-${datum.id}-${datum.parent_id}-row`, className: `row ${classNames} ${datum.has_children ? 'parent-row' : ''} ${hasSecondaryBreakdown
                        ? datum.is_secondary
                            ? 'secondary'
                            : 'primary'
                        : ''}` }, row));
                // Total columns
                const total = datum.count > 0
                    ? toDisplayPrice(datum.total, toQuery(datum, 'all'))
                    : '-';
                const average = !!(averageRowCalculation == 'all'
                    ? timeArray.length
                    : numActive[datum.id])
                    ? toDisplayPrice(datum.total /
                        (averageRowCalculation == 'all'
                            ? timeArray.length
                            : numActive[datum.id]), toQuery(datum, 'all'), false)
                    : '-';
                const count = datum.count;
                _totalsCols.push(React.createElement("div", { key: `row-${datum.id}-${datum.parent_id}-total`, className: `row ${hasSecondaryBreakdown
                        ? datum.is_secondary
                            ? 'secondary'
                            : 'primary'
                        : ''} ${datum.has_children ? 'parent-row' : ''}` },
                    React.createElement("div", { className: `cell${classNames}` }, total),
                    React.createElement("div", { className: `cell${classNames}` }, average),
                    React.createElement("div", { className: `cell${classNames}` }, count)));
                // Only count for grand total if this isn't in a category group or
                // the category group is hidden
                if ((!hasSecondaryBreakdown || !datum.is_secondary) &&
                    (!datum.group_id || datumStatus.HAS_HIDDEN_PARENT)) {
                    grandTotal[0] += datum.total;
                    grandCount[0] += count;
                }
                exportRow.push(total == '-' ? 0 : toExportablePrice(datum.total));
                exportRow.push(average == '-' ? 0 : toExportablePrice(datum.total / datum.count));
                exportRow.push(count);
                exportable.current.push(exportRow);
                // Subdata
                if (datum.subdata && Object.keys(datum === null || datum === void 0 ? void 0 : datum.subdata).length > 0) {
                    Object.values(datum.subdata).forEach(subdata => {
                        buildRow(Object.assign(Object.assign({}, subdata), { parent_group_id: datum.group_id, parent_id: datum.id }), grandTotal, grandCount, _categoryCol, _dataRows, _totalsCols, totalByTime, countByTime, numActive);
                    });
                }
            }
        }
    };
    const getDatumStatus = datum => {
        const datum_id = datum.id || datum.parent_id;
        const datum_group_id = datum.group_id || datum.parent_group_id;
        const HAS_HIDDEN_PARENT = !!datum_group_id &&
            (hiddenKeys.indexOf(datum_group_id === null || datum_group_id === void 0 ? void 0 : datum_group_id.toString()) > -1 ||
                hiddenKeys.indexOf(`${datasetIndex}/${datum_group_id === null || datum_group_id === void 0 ? void 0 : datum_group_id.toString()}`) >
                    -1);
        const IS_HIDDEN = hiddenKeys &&
            (hiddenKeys.indexOf(datum_id === null || datum_id === void 0 ? void 0 : datum_id.toString()) > -1 ||
                hiddenKeys.indexOf(`${datasetIndex}/${datum_id === null || datum_id === void 0 ? void 0 : datum_id.toString()}`) > -1);
        const IS_PARENT = datum.has_children;
        const IS_INDEPENDENT_TX = !datum.has_children && !datum_group_id;
        const IS_EXPANDED = (datum_group_id && expanded[datum_group_id]) ||
            (datum_group_id &&
                !expanded.hasOwnProperty(datum_group_id) &&
                expandCategories);
        return {
            IS_HIDDEN,
            HAS_HIDDEN_PARENT,
            IS_PARENT,
            IS_INDEPENDENT_TX,
            IS_EXPANDED,
        };
    };
    (0, react_1.useEffect)(() => {
        if (!timeArray)
            return;
        if (!transformedData)
            return;
        const totalByTime = {};
        const countByTime = {};
        exportable.current = [];
        const firstRow = [(0, format_1.capitalize)(groupBy.substring(3))];
        // First row
        setTimeRow([
            React.createElement("div", { key: 'time-row', className: "row header" }, timeArray.map(date => {
                totalByTime[date] = 0;
                countByTime[date] = 0;
                const displayDate = Moment(date).format(timeGranularity == 'month'
                    ? _user.getMonthYearFormat()
                    : timeGranularity == 'year'
                        ? 'YYYY'
                        : _user.getMonthDayFormat());
                firstRow.push(displayDate);
                return (React.createElement("div", { key: `time-row-${date}`, className: `cell ${date == sortKey ? 'active-sort' : ''}`, onClick: () => {
                        if (date == sortKey) {
                            setSortDirection(sortDirection == 'ascending' ? 'descending' : 'ascending');
                        }
                        else {
                            setSortKey(date);
                        }
                    } },
                    date == sortKey && (React.createElement(semantic_ui_react_1.Icon, { name: `caret ${sortDirection == 'ascending' ? 'up' : 'down'}` })),
                    displayDate));
            })),
        ]);
        firstRow.push('Total');
        firstRow.push('Average');
        firstRow.push('Count');
        exportable.current.push(firstRow);
        // Build other rows
        const _categoryCol = [];
        const _dataRows = [];
        const _totalsCols = [];
        const grandTotal = [0];
        const grandCount = [0];
        const numActive = {};
        const categoryList = [];
        // Calculate total rows first in case %
        transformedData.data.forEach((datum, index) => {
            const datumStatus = getDatumStatus(datum);
            const datum_id = datum.id || datum.parent_id;
            const datum_group_id = datum.group_id || datum.parent_group_id;
            if (!datumStatus.IS_HIDDEN) {
                timeArray.forEach(date => {
                    if (datum.data[date]) {
                        // For calculating average column
                        if (numActive.hasOwnProperty(datum_id)) {
                            numActive[datum_id]++;
                        }
                        else {
                            numActive[datum_id] = 1;
                        }
                        if (
                        // has no parents
                        datumStatus.IS_INDEPENDENT_TX ||
                            // is a parent (and is not hidden, as prev stated)
                            datumStatus.IS_PARENT ||
                            // has a hidden parent but it itself is not hidden
                            (datumStatus.HAS_HIDDEN_PARENT && !datumStatus.IS_HIDDEN)) {
                            // For total & average rows
                            totalByTime[date] += datum.data[date];
                            countByTime[date]++;
                        }
                    }
                });
            }
        });
        transformedData.data
            .sort((a, b) => {
            let sortResult = 0;
            if (sortKey == 'total') {
                sortResult = (a.total || 0) - (b.total || 0);
            }
            else if (sortKey == 'count') {
                if (!a.count) {
                    sortResult = 1;
                }
                else if (!b.count) {
                    sortResult = -1;
                }
                else {
                    sortResult = a.count - b.count;
                }
            }
            else if (sortKey == 'average') {
                const denominatorA = averageRowCalculation == 'all' ? timeArray.length : numActive[a.id];
                const denominatorB = averageRowCalculation == 'all' ? timeArray.length : numActive[b.id];
                if (!denominatorA) {
                    sortResult = -1;
                }
                else if (!denominatorB) {
                    sortResult = 1;
                }
                else {
                    sortResult = a.total / denominatorA - b.total / denominatorB;
                }
            }
            else if (!!sortKey) {
                // sorted by date
                if (!a.data[sortKey]) {
                    sortResult = -1;
                }
                else if (!b.data[sortKey]) {
                    sortResult = 1;
                }
                else {
                    sortResult = a.data[sortKey] - b.data[sortKey];
                }
            }
            else if (groupBy == 'by_category') {
                sortResult = a.index - b.index;
            }
            else {
                // sort is null
                sortResult = a.name
                    .trim()
                    .toLowerCase()
                    .localeCompare(b.name.trim().toLowerCase());
            }
            return (sortDirection == 'ascending' ? 1 : -1) * sortResult;
        })
            .forEach(datum => {
            categoryList.push(datum.id);
            buildRow(datum, grandTotal, grandCount, _categoryCol, _dataRows, _totalsCols, totalByTime, countByTime, numActive);
        });
        setCategoryCol(_categoryCol);
        setDataRows(_dataRows);
        // Add grand total/counts
        _totalsCols.push(React.createElement("div", { key: `row-grand-total`, className: `row footer` },
            React.createElement("div", { className: "cell" }, toDisplayPrice(grandTotal[0], {
                start_date: filterDateStart,
                end_date: filterDateEnd,
            })),
            React.createElement("div", { className: "cell" }, timeArray.length > 0
                ? toDisplayPrice(grandTotal[0] /
                    (averageRowCalculation == 'all'
                        ? timeArray.length
                        : Object.values(totalByTime).filter(o => !!o).length), {
                    start_date: filterDateStart,
                    end_date: filterDateEnd,
                }, false)
                : '-'),
            React.createElement("div", { className: "cell" }, grandCount[0])));
        setTotalsCol(_totalsCols);
        const exportRow = [['Total'], ['Average']];
        setTotalsRow(timeArray.map(date => {
            exportRow[0].push(toExportablePrice(totalByTime[date]));
            return (React.createElement("div", { className: "cell", key: `totals-${date}` }, totalByTime[date]
                ? toDisplayPrice(totalByTime[date], toQuery(null, date, true, categoryList))
                : '-'));
        }));
        setAverageRow(timeArray.map(date => {
            let denominator = countByTime[date];
            if (averageColCalculation == 'time') {
                if (timeGranularity == 'year') {
                    denominator = 12;
                }
                else if (timeGranularity == 'month') {
                    if ([0, 2, 4, 6, 7, 9, 11].indexOf(Moment(date).month()) > -1) {
                        denominator = 31;
                    }
                    else if ([3, 5, 8, 10].indexOf(Moment(date).month()) > -1) {
                        denominator = 30;
                    }
                    else if (Moment(date).isLeapYear()) {
                        // Leap February
                        denominator = 29;
                    }
                    else {
                        // February
                        denominator = 28;
                    }
                }
                else if (timeGranularity == 'week') {
                    denominator = 7;
                }
                else {
                    denominator = 1; // day
                }
            }
            exportRow[1].push(toExportablePrice(totalByTime[date] / denominator));
            return (React.createElement("div", { className: "cell", key: `average-${date}` }, denominator
                ? toDisplayPrice(totalByTime[date] / denominator, toQuery(null, date), false)
                : '-'));
        }));
        exportable.current.push(exportRow[0]);
        exportable.current.push(exportRow[1]);
    }, [
        expanded,
        groupBy,
        sortDirection,
        sortKey,
        timeGranularity,
        timeArray,
        transformedData,
        hiddenKeys,
        filterDateEnd,
        filterDateStart,
        baseUrl,
        expandCategories,
        averageRowCalculation,
        averageColCalculation,
    ]);
    return (React.createElement("div", { className: "tabular-report" },
        React.createElement("div", { className: "report" },
            React.createElement("div", { className: "left" },
                React.createElement("div", { className: "row header" },
                    React.createElement("div", { className: `cell ${sortKey == null ? 'active-sort' : ''}`, onClick: () => {
                            if (sortKey == null) {
                                setSortDirection(sortDirection == 'ascending' ? 'descending' : 'ascending');
                            }
                        } },
                        groupBy == 'by_category' && 'Category',
                        groupBy == 'by_recurring' && 'Recurring Item',
                        groupBy == 'by_account' && 'Account',
                        groupBy == 'by_tag' && 'Tag',
                        groupBy == 'by_payee' && 'Payee',
                        sortKey == null && (React.createElement(semantic_ui_react_1.Icon, { name: `caret ${sortDirection == 'ascending' ? 'up' : 'down'}` })))),
                categoryCol,
                React.createElement("div", { className: "footer row" },
                    React.createElement("div", { className: " cell" },
                        React.createElement("span", null, "Total"))),
                React.createElement("div", { className: "footer row" },
                    React.createElement("div", { className: " cell" },
                        React.createElement("span", null, "Average")))),
            React.createElement("div", { className: "center" },
                React.createElement("div", { className: "center-table" },
                    timeRow,
                    dataRows,
                    React.createElement("div", { className: "footer row" }, totalsRow),
                    React.createElement("div", { className: "footer row" }, averageRow))),
            React.createElement("div", { className: "right" },
                React.createElement("div", { className: "row header" },
                    React.createElement("div", { className: `cell ${sortKey == 'total' ? 'active-sort' : ''}`, onClick: () => {
                            if (sortKey == 'total') {
                                setSortDirection(sortDirection == 'ascending' ? 'descending' : 'ascending');
                            }
                            else {
                                setSortKey('total');
                            }
                        } },
                        sortKey == 'total' && (React.createElement(semantic_ui_react_1.Icon, { name: `caret ${sortDirection == 'ascending' ? 'up' : 'down'}` })),
                        "Total"),
                    React.createElement("div", { className: `cell ${sortKey == 'average' ? 'active-sort' : ''}`, onClick: () => {
                            if (sortKey == 'average') {
                                setSortDirection(sortDirection == 'ascending' ? 'descending' : 'ascending');
                            }
                            else {
                                setSortKey('average');
                            }
                        } },
                        sortKey == 'average' && (React.createElement(semantic_ui_react_1.Icon, { name: `caret ${sortDirection == 'ascending' ? 'up' : 'down'}` })),
                        "Average"),
                    React.createElement("div", { className: `cell ${sortKey == 'count' ? 'active-sort' : ''}`, onClick: () => {
                            if (sortKey == 'count') {
                                setSortDirection(sortDirection == 'ascending' ? 'descending' : 'ascending');
                            }
                            else {
                                setSortKey('count');
                            }
                        } },
                        sortKey == 'count' && (React.createElement(semantic_ui_react_1.Icon, { name: `caret ${sortDirection == 'ascending' ? 'up' : 'down'}` })),
                        "Count")),
                totalsCol))));
};
exports.default = Report;
