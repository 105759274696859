"use strict";
/**
 *  Transactions.tsx
 *  Container for the Transactions page
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const billing_1 = require("@actions/billing");
const Moment = require("moment");
// Semantic UI
const semantic_ui_react_1 = require("semantic-ui-react");
class ExtendTrial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toTransactions: false,
            text: 'Extending your trial..',
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            // Page title
            document.title = `Extending your trial - Lunch Money`;
            const response = yield (0, billing_1.extendTrial)(this.props.match.params.extension_id);
            if (response.error) {
                this.setState({
                    text: 'This free trial extension code is invalid.',
                });
            }
            else {
                this.setState({
                    text: `Your free trial has been extended to ${Moment(response.data.trial_end, 'X')
                        .subtract(1, 'day')
                        .format('MMM D, YYYY')}!`,
                });
            }
        });
    }
    render() {
        return (React.createElement("div", { className: "g-logged-out" },
            React.createElement("div", { className: "g-logged-out-container" },
                React.createElement("div", { className: "logged-out-content" },
                    React.createElement(semantic_ui_react_1.Container, { className: "center-align" },
                        React.createElement("div", { id: "logo-container" },
                            React.createElement("div", { id: "logo" }),
                            React.createElement("div", { id: "shadow" })),
                        React.createElement(semantic_ui_react_1.Header, { as: "h2" }, this.state.text),
                        React.createElement("p", null,
                            "If you need any assistance,",
                            ' ',
                            React.createElement("a", { href: "mailto:support@lunchmoney.app" }, "email us anytime"),
                            "."),
                        React.createElement(semantic_ui_react_1.Button, { fluid: true, onClick: () => {
                                window.location.href = '/login';
                            } }, "Log in"),
                        React.createElement(semantic_ui_react_1.Button, { fluid: true, basic: true, color: "orange", className: "mt-05rem", onClick: () => {
                                window.location.href = 'https://lunchmoney.app';
                            } }, "Visit lunchmoney.app"))))));
    }
}
exports.default = ExtendTrial;
