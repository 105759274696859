"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
const qs = require("query-string");
const account_1 = require("@actions/account");
const UserProvider_1 = require("@providers/UserProvider");
const EditableString_1 = require("../elements/EditableString");
const StartFresh = ({}) => {
    const history = (0, react_router_dom_1.useHistory)();
    const [confirmText, setConfirmText] = (0, react_1.useState)(null);
    const [showDelete, setShowDelete] = (0, react_1.useState)(false);
    const [showConfirmation, setShowConfirmation] = (0, react_1.useState)(false);
    const [include, setInclude] = (0, react_1.useState)({
        Categories: true,
        Tags: true,
        'Manually-managed assets (non-crypto)': true,
        'Synced accounts (via Plaid, non-crypto)': true,
        'Manually-managed crypto': true,
        'Synced crypto accounts': true,
    });
    const [processStarted, setProcessStarted] = (0, react_1.useState)(false);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const elements = [
        'Categories',
        'Tags',
        'Manually-managed assets (non-crypto)',
        'Synced accounts (via Plaid, non-crypto)',
        'Manually-managed crypto',
        'Synced crypto accounts',
    ];
    (0, react_1.useEffect)(() => {
        const opts = qs.parse(history.location.search);
        if (opts.start_fresh) {
            setShowConfirmation(true);
        }
    }, [history.location.search]);
    return (React.createElement(React.Fragment, null,
        React.createElement(semantic_ui_react_1.Card, { fluid: true },
            React.createElement(semantic_ui_react_1.Card.Content, { header: "Reset" }),
            React.createElement(semantic_ui_react_1.Card.Content, null,
                React.createElement(semantic_ui_react_1.Message, { info: true }, "Want to start fresh new budget and wipe everything clean?"),
                React.createElement(semantic_ui_react_1.Popup, { position: 'bottom right', on: "hover", disabled: _user.isAdmin, hoverable: true, trigger: React.createElement(semantic_ui_react_1.Button, { disabled: !_user.isAdmin, fluid: true, color: "orange", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setShowConfirmation(true);
                        }) },
                        "I want to start fresh ",
                        React.createElement(semantic_ui_react_1.Icon, { name: "arrow circle right" })) }, "You may only start fresh if you are the admin of the account."),
                React.createElement(semantic_ui_react_1.Message, { info: true },
                    "Want to ",
                    _user.isAdmin ? 'delete ' : 'leave ',
                    "this entire budget? Note: You can also create new budgets at any time."),
                React.createElement(semantic_ui_react_1.Button, { className: "mt-05rem", fluid: true, negative: true, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setShowDelete(true);
                    }) },
                    _user.isAdmin ? 'Delete this budget' : 'Leave this budget',
                    ' ',
                    React.createElement(semantic_ui_react_1.Icon, { name: "arrow circle right" })))),
        React.createElement(semantic_ui_react_1.Modal, { open: showDelete, onClose: () => {
                // do something after this budget is dead
            }, size: "tiny", dimmer: "inverted" },
            React.createElement(semantic_ui_react_1.Modal.Header, null,
                "Are you sure you want to ",
                _user.isAdmin ? 'delete' : 'leave',
                " this budget?"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("p", null,
                    _user.isAdmin
                        ? 'Deleting this budget and its data is irreversible. '
                        : 'You will lose access to this budget and all its data, but you can be re-invited in the future. ',
                    "Your Lunch Money account will not be affected and you may create new budgets at any time."),
                React.createElement("p", null,
                    "To proceed, type the name of the budget (",
                    React.createElement("b", null, _user.displayName),
                    ") below that you'd like to ",
                    _user.isAdmin ? 'delete' : 'leave',
                    "."),
                React.createElement(EditableString_1.default, { identifier: `confirm-delete`, location: 'inline', firstValue: confirmText, state: 'Editing', className: ' font-size--larger ', autoFocus: true, shouldSaveOnBlur: false, shouldSaveOnEnter: false, shouldSaveOnChange: true, shouldSelectOnFocus: false, onSave: (name) => __awaiter(void 0, void 0, void 0, function* () {
                        setConfirmText(name);
                    }) })),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { negative: true, disabled: confirmText !== _user.displayName, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield (0, account_1.deleteBudget)(_user.accountId);
                        setConfirmText(null);
                        setShowDelete(false);
                        // When we come back, let's trigger a refresh
                        localStorage.setItem('_lm_account_switched', new Date().toTimeString());
                        window.location.reload();
                    }) }, _user.isAdmin
                    ? 'Delete This Budget Permanently'
                    : 'Leave this budget'),
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        setShowDelete(false);
                        setConfirmText(null);
                    } }, "Cancel"))),
        React.createElement(semantic_ui_react_1.Modal, { open: showConfirmation, closeOnEscape: true, closeOnDimmerClick: true, size: 'tiny', onClose: () => {
                setShowConfirmation(false);
            } },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Start Fresh"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement(semantic_ui_react_1.Message, { info: true }, "Starting Fresh is temporarily disabled while we work on improving site performance. In the meantime, we encourage you to create a new budget instead! Click on the circle to the left of the 'Logout' button at the top right corner to create a new budget.")),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { color: "orange", onClick: () => {
                        setShowConfirmation(false);
                    } }, "Okay")))));
};
exports.default = StartFresh;
