"use strict";
/**
 *  Transactions.tsx
 *  Container for the Transactions page
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_router_dom_1 = require("react-router-dom");
const Moment = require("moment");
const auth_1 = require("@actions/auth");
const account_1 = require("@actions/account");
const global_1 = require("@actions/global");
const gift_cards_1 = require("@actions/gift_cards");
const referral_1 = require("@actions/referral");
const AccountSetupOptions_1 = require("@components/global/AccountSetupOptions");
const inEU = require("@segment/in-eu");
const qs = require("query-string");
const StaticProvider_1 = require("@providers/StaticProvider");
const AuthProvider_1 = require("@providers/AuthProvider");
const semantic_ui_react_2 = require("semantic-ui-react");
const react_google_recaptcha_1 = require("react-google-recaptcha");
const plausible_1 = require("@/helpers/plausible");
const Register = ({}) => {
    var _a;
    let history = (0, react_router_dom_1.useHistory)();
    const _auth = (0, react_1.useContext)(AuthProvider_1.AuthContext);
    const _static = (0, react_1.useContext)(StaticProvider_1.StaticContext);
    const [name, setName] = (0, react_1.useState)('');
    const [email, setEmail] = (0, react_1.useState)('');
    const [password, setPassword] = (0, react_1.useState)('');
    const [primaryCurrency, setPrimaryCurrency] = (0, react_1.useState)(null);
    const [plan, setPlan] = (0, react_1.useState)(null);
    const [coupon, setCoupon] = (0, react_1.useState)(null);
    const [giftCard, setGiftCard] = (0, react_1.useState)(null);
    const [referral, setReferral] = (0, react_1.useState)(null);
    const [referralMethod, setReferralMethod] = (0, react_1.useState)(null);
    const [referralOther, setReferralOther] = (0, react_1.useState)(null);
    const [isInvalidGiftCard, setIsInvalidGiftCard] = (0, react_1.useState)(null);
    const [isInvalidReferral, setIsInvalidReferral] = (0, react_1.useState)(null);
    const [inviter, setInviter] = (0, react_1.useState)(null);
    const [error, setError] = (0, react_1.useState)(null);
    const [buttonLoading, setButtonLoading] = (0, react_1.useState)(null);
    const [buttonResent, setButtonResent] = (0, react_1.useState)(null);
    const [subscribeToNewsletter, setSubscribeToNewsletter] = (0, react_1.useState)(inEU() ? false : true);
    const [showOptions, setShowOptions] = (0, react_1.useState)(false);
    const [startWithDemoAccount, setStartWithDemoAccount] = (0, react_1.useState)(false);
    const captchaRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        var _a, _b, _c;
        if (_auth.isLoggedIn) {
            history.push('/overview');
        }
        // Check cookie for referral
        const cookie = (_c = (_b = (_a = document.cookie) === null || _a === void 0 ? void 0 : _a.split('; ')) === null || _b === void 0 ? void 0 : _b.find(row => row.startsWith('_lm_referral_token'))) === null || _c === void 0 ? void 0 : _c.split('=')[1];
        const query = qs.parse(history.location.search);
        setPlan(['usd', 'cad', 'eur'].indexOf(query.plan) > -1
            ? query.plan
            : 'usd');
        setPrimaryCurrency(query.plan || 'usd');
        setCoupon(query.coupon);
        setEmail(query.email);
        setReferral(cookie || null);
        (0, global_1.preregistration)(query.email);
        if (query.code) {
            getGiftCardInfo(query.code);
        }
        document.title = `Register - Lunch Money`;
    }, []);
    (0, react_1.useEffect)(() => {
        (0, plausible_1.trackEvent)('View register page', null, 'lunchmoney.app');
    }, []);
    const getGiftCardInfo = (code) => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield (0, gift_cards_1.getGiftCard)(code);
        if (results) {
            setIsInvalidGiftCard(false);
            setGiftCard(results);
        }
        else {
            setIsInvalidGiftCard(true);
        }
    });
    (0, react_1.useEffect)(() => {
        const getReferrerInfo = () => __awaiter(void 0, void 0, void 0, function* () {
            const results = yield (0, referral_1.getReferrer)(referral);
            if (results === null || results === void 0 ? void 0 : results.hasOwnProperty('id')) {
                setIsInvalidReferral(false);
            }
            else {
                setIsInvalidReferral(true);
                setReferral(null);
                // Reset cookie
                document.cookie =
                    '_lm_referral_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
            }
        });
        if (referral) {
            getReferrerInfo();
        }
    }, [referral]);
    const submit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c;
        setButtonLoading(true);
        const results = yield (0, auth_1.register)(Object.assign({ captcha: captchaRef.current.getValue(), name: name, email: email, password: password, primary_currency: primaryCurrency, subscribe_to_newsletter: subscribeToNewsletter, plan: plan, coupon: coupon, referral: referral, gift_card: giftCard === null || giftCard === void 0 ? void 0 : giftCard.code, referral_method: referralMethod, referral_other: referralOther }, (((_b = qs.parse(history.location.search)) === null || _b === void 0 ? void 0 : _b.fp_ref)
            ? { fp_ref: (_c = qs.parse(history.location.search)) === null || _c === void 0 ? void 0 : _c.fp_ref }
            : {})));
        if (results.error) {
            setButtonLoading(false);
            (0, plausible_1.trackEvent)('Encountered error on register page', { error: results.error }, 'lunchmoney.app');
            if (results.error.data && results.error.data.inviter) {
                // This user was invited by someone to join.
                setInviter(results.error.data.inviter);
                setError(null);
            }
            else {
                setError(results.error.message);
            }
        }
        else {
            (0, plausible_1.trackEvent)('Registered successfully', { stripe_currency_plan: plan, primary_currency: primaryCurrency }, 'lunchmoney.app');
            setShowOptions(true);
        }
        captchaRef.current.reset();
    });
    const resend = () => __awaiter(void 0, void 0, void 0, function* () {
        setButtonLoading(true);
        const response = yield (0, account_1.resendInvitation)({
            email: email,
        });
        if (response.error) {
            setButtonLoading(false);
            setError(response.error);
        }
        else {
            setError(null);
            setButtonLoading(false);
            setButtonResent(true);
        }
    });
    return (React.createElement("div", { className: "g-logged-out", id: "g-register" },
        React.createElement("div", { className: "g-logged-out-container" },
            inviter && (React.createElement("div", { className: "register-container flex--column" },
                React.createElement(semantic_ui_react_2.Header, { as: "h2", className: "center-align" },
                    inviter.name,
                    " sent you an invitation to collaborate",
                    ' ',
                    Moment(inviter.invited_on).fromNow(),
                    "."),
                React.createElement(semantic_ui_react_2.Header, { as: "h3", className: "center-align" }, "Please complete registration using the link in your email."),
                React.createElement("p", { className: "center-align" },
                    "You may either accept or reject ",
                    inviter.name,
                    "'s invitation via the link."),
                React.createElement(semantic_ui_react_2.Button, { type: "submit", onClick: resend, className: "center-block", loading: buttonLoading, disabled: buttonResent }, buttonResent ? 'Email resent' : 'Resend invitation'),
                React.createElement("p", { className: "mt-1rem register" },
                    "Back to",
                    ' ',
                    React.createElement("a", { href: "https://lunchmoney.app", className: "link clickable" }, "lunchmoney.app")),
                error && React.createElement(semantic_ui_react_1.Message, { negative: true }, error))),
            !inviter && (React.createElement("div", { className: `register-container` },
                React.createElement("div", { className: "flex--column flex--space-between" },
                    React.createElement("div", null),
                    React.createElement("div", null,
                        React.createElement("div", { id: "logo-container" },
                            React.createElement("div", { id: "logo" }),
                            React.createElement("div", { id: "shadow" })),
                        React.createElement(semantic_ui_react_2.Header, { as: "h2", className: "center-align" }, "Welcome to the first day of your new financial journey!"),
                        (isInvalidGiftCard || !giftCard) && (React.createElement(semantic_ui_react_1.Message, { className: "mt-05rem mb-0" },
                            React.createElement("div", { className: "center-align" },
                                React.createElement("p", null,
                                    "You are starting off with a",
                                    ' ',
                                    !isInvalidGiftCard && (giftCard === null || giftCard === void 0 ? void 0 : giftCard.type)
                                        ? `${giftCard === null || giftCard === void 0 ? void 0 : giftCard.type} gifted period`
                                        : referral &&
                                            referral !== 't8xsfk46' &&
                                            referral !== '4y6pt7la' &&
                                            !isInvalidReferral
                                            ? '45 day free trial'
                                            : '30 day free trial',
                                    "!")))),
                        !giftCard &&
                            referral !== 't8xsfk46' &&
                            referral !== '4y6pt7la' &&
                            referral !== 'zhq63wmg' &&
                            referral !== 'newsweek' &&
                            (isInvalidReferral !== null ||
                                ((_a = qs.parse(history.location.search)) === null || _a === void 0 ? void 0 : _a.fp_ref)) &&
                            (isInvalidReferral ? (React.createElement(semantic_ui_react_1.Message, { warning: true, className: "mt-05rem" }, "Note: The referral link you used is invalid.")) : (React.createElement(semantic_ui_react_1.Message, { info: true, className: "mt-05rem" },
                                React.createElement("p", null,
                                    "\uD83C\uDF81 ",
                                    React.createElement("b", null, "Referral bonus!"),
                                    " You'll receive one free month on top of your 14-day trial!")))),
                        isInvalidGiftCard !== null &&
                            (isInvalidGiftCard || !giftCard ? (React.createElement(semantic_ui_react_1.Message, { warning: true, className: "mt-05rem" }, "Note: The gift card you are trying to redeem is invalid.")) : (React.createElement(semantic_ui_react_1.Message, { info: true, className: "mt-05rem" },
                                React.createElement("p", null,
                                    "\uD83C\uDF81 ",
                                    React.createElement("b", null, "You got gifted!"),
                                    " Thanks to ",
                                    giftCard.name,
                                    ", you've got a free ",
                                    giftCard.type,
                                    " subscription!"))))),
                    React.createElement("div", null,
                        React.createElement("p", { className: "mt-1rem register" },
                            "Already signed up?",
                            ' ',
                            React.createElement(react_router_dom_1.Link, { to: "/login" }, "Login to your account.")))),
                React.createElement("div", null,
                    React.createElement(semantic_ui_react_2.Form, { className: "logged-out-form" },
                        React.createElement(semantic_ui_react_2.Form.Field, { className: "mb-05rem" },
                            React.createElement(semantic_ui_react_2.Form.Input, { autoFocus: true, value: name, type: "text", onChange: e => {
                                    setName(e.target.value);
                                }, fluid: true, label: "Name" })),
                        React.createElement(semantic_ui_react_2.Form.Field, { className: "mb-05rem" },
                            React.createElement(semantic_ui_react_2.Form.Input, { value: email || '', type: "email", onChange: e => {
                                    setEmail(e.target.value);
                                }, fluid: true, label: "Email Address" })),
                        React.createElement(semantic_ui_react_2.Form.Field, { className: "mb-05rem" },
                            React.createElement(semantic_ui_react_2.Form.Input, { value: password, onChange: e => {
                                    setPassword(e.target.value);
                                }, fluid: true, type: "password", label: "Password" })),
                        React.createElement(semantic_ui_react_2.Form.Field, null,
                            React.createElement("label", null, "Primary currency"),
                            React.createElement(semantic_ui_react_2.Dropdown, { label: "Primary currency", id: "register-dropdown", className: "category-dropdown p-category-modal", search: true, required: true, selection: true, fluid: true, value: primaryCurrency, onChange: (e, { value }) => {
                                    setPrimaryCurrency(value.toString());
                                }, options: _static.allCurrencies })),
                        React.createElement(semantic_ui_react_2.Form.Field, null,
                            React.createElement("label", null,
                                "Preferred billing currency",
                                React.createElement(semantic_ui_react_2.Popup, { inverted: true, size: "small", hoverable: true, trigger: React.createElement(semantic_ui_react_2.Icon, { className: "ml-05rem", name: "question circle", color: "grey" }) },
                                    React.createElement("p", null, "You can choose to pay your subscription dues in USD, CAD or EUR. If you need to change this later, please email us at support@lunchmoney.app."))),
                            React.createElement(semantic_ui_react_2.Dropdown, { label: "Preferred billing currency", id: "referrer-dropdown", className: "category-dropdown p-category-modal", selection: true, fluid: true, value: plan, onChange: (e, { value }) => {
                                    setPlan(value);
                                }, options: [
                                    { key: 'usd', value: 'usd', text: 'USD' },
                                    { key: 'cad', value: 'cad', text: 'CAD' },
                                    { key: 'eur', value: 'eur', text: 'EUR' },
                                ] })),
                        React.createElement(semantic_ui_react_2.Form.Field, null,
                            React.createElement("label", null, "How did you hear about us? (optional)"),
                            React.createElement(semantic_ui_react_2.Dropdown, { label: "How did you hear about us? (optional)", id: "referrer-dropdown", className: "category-dropdown p-category-modal", selection: true, fluid: true, value: referralMethod, onChange: (e, { value }) => {
                                    setReferralMethod(value);
                                }, options: [
                                    'Search Engine',
                                    'Twitter',
                                    'Reddit',
                                    'Hacker News',
                                    'Youtube',
                                    'LinkedIn',
                                    'Facebook',
                                    'Instagram',
                                    'TikTok',
                                    'Other Social Media',
                                    'Email Newsletter',
                                    'Podcast Episode',
                                    'Blog',
                                    'Word of Mouth',
                                    'Other',
                                ].map(o => {
                                    return { key: o, value: o, text: o };
                                }) })),
                        [
                            'Other Social Media',
                            'Email Newsletter',
                            'Podcast Episode',
                            'Blog',
                            'Other',
                            'TikTok',
                            'Instagram',
                            'Youtube',
                        ].indexOf(referralMethod) > -1 && (React.createElement(semantic_ui_react_2.Form.Field, null,
                            React.createElement(semantic_ui_react_2.Form.Input, { value: referralOther || '', type: "text", onChange: e => {
                                    setReferralOther(e.target.value);
                                }, fluid: true, placeholder: "Care to let us a little bit more?" }))),
                        inEU() && (React.createElement(semantic_ui_react_2.Form.Field, { className: "mb-05rem" },
                            React.createElement(semantic_ui_react_2.Checkbox, { toggle: true, checked: subscribeToNewsletter, onChange: (e, { checked }) => {
                                    setSubscribeToNewsletter(checked);
                                }, label: React.createElement(React.Fragment, null,
                                    React.createElement("label", null, "Stay up-to-date via newsletter")) }),
                            React.createElement(semantic_ui_react_2.Popup, { inverted: true, size: "small", hoverable: true, trigger: React.createElement(semantic_ui_react_2.Icon, { className: "ml-05rem", name: "question circle", color: "grey" }) },
                                React.createElement("p", null, "We send a newsletter twice a month to update our users on new features and latest changes.")))),
                        React.createElement(react_google_recaptcha_1.default, { type: 'v3', sitekey: '6LcDhNInAAAAAATjfUHXMHOHwfX63FUV9tuxLPZr', ref: captchaRef }),
                        error && (React.createElement(semantic_ui_react_1.Message, { negative: true, className: "mt-05rem" }, error)),
                        React.createElement(semantic_ui_react_2.Button, { type: "submit", onClick: submit, loading: buttonLoading, disabled: !(name && email && password && primaryCurrency) ||
                                buttonLoading }, "Get Started"),
                        React.createElement("p", { className: "small mb-0" }, "No credit card required. Cancel anytime.")))))),
        React.createElement(AccountSetupOptions_1.default, { open: showOptions })));
};
exports.default = Register;
