"use strict";
/**
 *  AmortizeView.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const EditableDate_1 = require("@components/elements/EditableDate");
const react_1 = require("react");
// Components
const format_1 = require("@helpers/format");
const transactions_1 = require("@actions/transactions");
const EditableCategory_1 = require("@components/elements/EditableCategory");
const EditableTags_1 = require("@components/elements/EditableTags");
const EditableString_1 = require("@components/elements/EditableString");
const TransactionsProvider_1 = require("@providers/TransactionsProvider");
const UserProvider_1 = require("@/providers/UserProvider");
const AmortizeView = ({ data, useModal, utils, setVisibility, switchView, setDisableOnClickOutside, }) => {
    var _a;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const _transactions = (0, react_1.useContext)(TransactionsProvider_1.TransactionsContext);
    const transaction = _transactions.modalData.current;
    const [finalSplits, setFinalSplits] = (0, react_1.useState)([]);
    const [startDate, setStartDate] = (0, react_1.useState)(transaction.formatted_date);
    const [endDate, setEndDate] = (0, react_1.useState)(transaction.formatted_date);
    const [cadence, setCadence] = (0, react_1.useState)('months');
    const [split, setSplit] = (0, react_1.useState)({
        payee: transaction.payee,
        category_id: transaction.category_id,
        notes: transaction.notes,
        tags: ((_a = transaction.tags) === null || _a === void 0 ? void 0 : _a.map(o => o.id)) || [],
    });
    // Setup
    (0, react_1.useEffect)(() => {
        setDisableOnClickOutside(true);
        useModal({
            header: (React.createElement("div", null,
                React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        switchView('SPLIT_VIEW');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " Back to Split"),
                React.createElement("span", { className: "header-text-large" }, "Split transaction over time"))),
            middleButton: React.createElement(React.Fragment, null),
        });
        return () => {
            useModal({
                middleButton: React.createElement(React.Fragment, null),
                header: React.createElement(React.Fragment, null),
            });
        };
    }, [data]);
    // Update save function
    (0, react_1.useEffect)(() => {
        useModal({
            leftButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "no-border", basic: true, onClick: () => {
                    setVisibility(false, true);
                } }, 'Cancel')),
            rightButton: (React.createElement(semantic_ui_react_1.Button, { loading: isLoading, disabled: isLoading ||
                    finalSplits.length < 2 ||
                    Moment(startDate).isAfter(Moment(endDate)), color: "orange", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setIsLoading(true);
                    // Figure out if the sign should be flipped.
                    const shouldFlip = transaction.amount < 0;
                    const results = yield utils._process(transactions_1.splitTransaction)(transaction.id, {
                        split: finalSplits.map(_split => {
                            return {
                                date: _split.date,
                                amount: shouldFlip ? -1 * _split.amount : _split.amount,
                                payee: split.payee,
                                category_id: split.category_id,
                                notes: split.notes,
                                tags: split.tags,
                            };
                        }),
                    });
                    if (!results.error) {
                        // Show success message
                        utils._showToast({
                            message: 'Successfully updated split transaction',
                            type: 'success',
                        });
                        if (data.amendTransactions) {
                            data.amendTransactions({
                                update: { id: transaction.id, has_children: true }, // parent tx
                                add: results.data, // splits
                            });
                            setIsLoading(false);
                            setVisibility(false, true);
                        }
                        else {
                            setIsLoading(false);
                            setVisibility(false);
                        }
                    }
                    else {
                        setIsLoading(false);
                    }
                }) }, "Save changes")),
        });
        return () => {
            useModal({
                rightButton: React.createElement(React.Fragment, null),
                leftButton: React.createElement(React.Fragment, null),
            });
        };
    }, [isLoading, split, finalSplits]);
    (0, react_1.useEffect)(() => {
        if (Moment(startDate).isAfter(Moment(endDate))) {
            setFinalSplits([]);
            return;
        }
        // Calculate number of payments
        const splits = [];
        const pointer = Moment(startDate);
        while (Moment(pointer).isSameOrBefore(Moment(endDate))) {
            splits.push({
                date: pointer.format('YYYY-MM-DD'),
            });
            pointer.add(1, cadence);
        }
        let runningTotal = 0;
        const amount = parseFloat((Math.abs(transaction.amount) / splits.length).toFixed(2));
        setFinalSplits(splits.map((split, index) => {
            if (index !== splits.length - 1) {
                runningTotal += amount;
                return Object.assign(Object.assign({}, split), { amount: amount });
            }
            else {
                return Object.assign(Object.assign({}, split), { amount: parseFloat((Math.abs(transaction.amount) - runningTotal).toFixed(2)) });
            }
        }));
    }, [startDate, endDate, cadence]);
    return (React.createElement("div", { id: "split-view", className: "detail-buttons" },
        React.createElement("div", { className: "transaction-details" },
            React.createElement("h3", { className: "flex--space-between" },
                "Amortization Details",
                ' ',
                React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "Decide how many payment periods to split this transaction into, and how often payments are made")),
            React.createElement(semantic_ui_react_1.Form, null,
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null, "Start Date"),
                        React.createElement(EditableDate_1.default, { identifier: "split-end-date", firstValue: startDate, state: 'Editing', location: 'modal', showError: Moment(startDate).isAfter(Moment(endDate)), format: 'month_day_year', onSave: start_date => {
                                setStartDate(start_date);
                                if (Moment(start_date).isAfter(Moment(endDate))) {
                                    utils._showToast({
                                        message: 'Start date must be before end date!',
                                        type: 'error',
                                    });
                                }
                            } })),
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null, "End Date"),
                        React.createElement(EditableDate_1.default, { identifier: "split-end-date", firstValue: endDate, state: 'Editing', location: 'modal', showError: Moment(startDate).isAfter(Moment(endDate)), format: 'month_day_year', onSave: end_date => {
                                setEndDate(end_date);
                                if (Moment(end_date).isBefore(Moment(startDate))) {
                                    utils._showToast({
                                        message: 'End date must be after start date!',
                                        type: 'error',
                                    });
                                }
                            } }))),
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    React.createElement("label", null, "Paid every"),
                    React.createElement(semantic_ui_react_1.Dropdown, { fluid: true, selection: true, defaultValue: cadence, className: "mb-1rem category-dropdown p-category-modal", onChange: (e, { value }) => {
                            setCadence(value);
                        }, options: [
                            {
                                key: 'month',
                                value: 'months',
                                text: 'month',
                            },
                            {
                                key: 'week',
                                value: 'weeks',
                                text: 'week',
                            },
                            {
                                key: 'day',
                                value: 'days',
                                text: 'day',
                            },
                        ] })),
                finalSplits.length ? (React.createElement(semantic_ui_react_1.Message, { info: true, className: "mb-1rem" },
                    React.createElement(semantic_ui_react_1.Message.Header, null,
                        finalSplits.length,
                        " transaction",
                        finalSplits.length > 1 ? 's' : '',
                        " will be created totalling",
                        ' ',
                        (0, format_1.toPrice)(finalSplits.reduce((acc, curr) => {
                            return acc + curr.amount;
                        }, 0), transaction.currency)),
                    React.createElement(semantic_ui_react_1.Message.List, { className: "scrollable-list" }, finalSplits.map((split, index) => {
                        return (React.createElement(semantic_ui_react_1.Message.Item, { key: `split-${index}` },
                            Moment(split.date).format(_user.getMonthDayYearFormat()),
                            ": ",
                            (0, format_1.toPrice)(split.amount, transaction.currency)));
                    })))) : (React.createElement(React.Fragment, null)))),
        React.createElement("div", { className: "transaction-details" },
            React.createElement("h3", { className: "flex--space-between" },
                "Split Details",
                ' ',
                React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "Each split transaction created will have the following properties.")),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Payee"),
                React.createElement(EditableString_1.default, { identifier: `split-payee`, placeholder: "Leave blank to inherit from parent", firstValue: split.payee, location: 'modal', state: 'Editing', shouldSaveOnChange: true, onSave: (payee) => __awaiter(void 0, void 0, void 0, function* () {
                        setSplit(Object.assign(Object.assign({}, split), { payee }));
                    }) })),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Category"),
                React.createElement(EditableCategory_1.default, { identifier: `split-category`, firstValue: split.category_id, state: 'Editing', location: 'modal', onSave: category_id => {
                        setSplit(Object.assign(Object.assign({}, split), { category_id }));
                    } })),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Notes"),
                React.createElement(EditableString_1.default, { identifier: `split-notes`, placeholder: "Notes", firstValue: split.notes, location: 'modal', state: 'Editing', shouldSaveOnChange: true, onSave: (notes) => __awaiter(void 0, void 0, void 0, function* () {
                        setSplit(Object.assign(Object.assign({}, split), { notes }));
                    }) })),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Tags"),
                React.createElement(EditableTags_1.default, { allowAdd: false, showUntagged: false, placeholder: "Search tags", initialValue: split.tags || [], onChange: tags => {
                        setSplit(Object.assign(Object.assign({}, split), { tags }));
                    } })))));
};
exports.default = AmortizeView;
