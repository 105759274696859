"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const qs = require("query-string");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const TaskCard_1 = require("@components/TaskCard/TaskCard");
const TransactionsTaskCard = ({ unclearedCount, hideQuestionMark = false, onHover = () => { }, onBlur = () => { }, onClick = () => { }, isLoading = false, startDate = null, endDate = null, }) => {
    const history = (0, react_router_dom_1.useHistory)();
    const [title, setTitle] = (0, react_1.useState)(null);
    const [subtitle, setSubtitle] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        if (unclearedCount > 0) {
            setTitle(`Review ${parseInt(unclearedCount) || ''} transactions`);
            setSubtitle(React.createElement("span", null, "Ensure your transactions are categorized properly."));
        }
        else {
            setTitle(`Review transactions`);
            setSubtitle('All cleared!');
        }
    }, [unclearedCount]);
    return (React.createElement(TaskCard_1.default, { isLoading: isLoading, type: "transactions", className: parseInt(unclearedCount) === 0 ? 'task-done' : '', onClick: () => {
            if (history.location.pathname.indexOf('transactions') > -1) {
                history.push({
                    search: `?status=unreviewed`,
                });
            }
            else {
                let search = `?status=unreviewed`;
                if (startDate && endDate) {
                    search = qs.stringify({
                        start_date: startDate,
                        end_date: endDate,
                        time: 'custom',
                        match: 'all',
                        status: 'unreviewed',
                    });
                }
                history.push({
                    pathname: `/transactions/${Moment().format('YYYY/MM')}`,
                    search: parseInt(unclearedCount) !== 0 ? search : null,
                });
            }
        }, title: title, subtitle: subtitle, onHover: onHover, onBlur: onBlur, hideQuestionMark: hideQuestionMark }));
};
exports.default = TransactionsTaskCard;
