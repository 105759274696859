"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOCALES = exports.MONTH_DAY_FORMATS = exports.MONTH_DAY_YEAR_FORMATS = exports.MONTH_YEAR_FORMATS = exports.SHORT_DATE = void 0;
const _ = require("lodash");
exports.SHORT_DATE = [''];
exports.MONTH_YEAR_FORMATS = _.flatten([
    ['MMMM YYYY'],
    ['MMM YYYY'],
    ['YYYY MMM'],
    ...[
        ['MM', 'YYYY'],
        ['M', 'YYYY'],
        ['YYYY', 'M'],
        ['YYYY', 'MM'],
    ].map(segments => {
        return [
            segments.join(' '),
            segments.join('-'),
            segments.join('.'),
            segments.join('/'),
        ];
    }),
]);
exports.MONTH_DAY_YEAR_FORMATS = _.flatten([
    ['MMM D, YYYY'],
    ['D MMM YYYY'],
    ...[
        ['YYYY', 'MM', 'DD'],
        ['YYYY', 'M', 'DD'],
        ['YYYY', 'MM', 'D'],
        ['YYYY', 'M', 'D'],
        ['DD', 'MM', 'YYYY'],
        ['DD', 'M', 'YYYY'],
        ['D', 'MM', 'YYYY'],
        ['D', 'M', 'YYYY'],
        ['M', 'D', 'YYYY'],
        ['MM', 'D', 'YYYY'],
        ['MM', 'DD', 'YYYY'],
        ['M', 'DD', 'YYYY'],
    ].map(segments => {
        return [
            segments.join(' '),
            segments.join('-'),
            segments.join('.'),
            segments.join('/'),
        ];
    }),
]);
exports.MONTH_DAY_FORMATS = _.flatten([
    ['MMM D'],
    ['MMM DD'],
    ['D MMM'],
    ['DD MMM'],
    ...[
        ['MM', 'D'],
        ['MM', 'DD'],
        ['M', 'D'],
        ['M', 'DD'],
        ['D', 'MM'],
        ['DD', 'MM'],
        ['D', 'M'],
        ['DD', 'M'],
    ].map(segments => {
        return [
            segments.join(' '),
            segments.join('-'),
            segments.join('.'),
            segments.join('/'),
        ];
    }),
]);
exports.LOCALES = [
    ['Albanian', 'Albania', 'sq-AL'],
    ['Belarusian', 'Belarus', 'be-BY'],
    ['Bulgarian', 'Bulgaria', 'bg-BG'],
    ['Catalan', 'Spain', 'ca-ES'],
    ['Chinese (Simplified)', 'China', 'zh-CN'],
    ['Chinese (Traditional)', 'Hong Kong', 'zh-HK'],
    ['Chinese (Traditional)', 'Taiwan', 'zh-TW'],
    ['Croatian', 'Croatia', 'hr-HR'],
    ['Czech', 'Czech Republic', 'cs-CZ'],
    ['Danish', 'Denmark', 'da-DK'],
    ['Dutch', 'Belgium', 'nl-BE'],
    ['Dutch', 'Netherlands', 'nl-NL'],
    ['English', 'Australia', 'en-AU'],
    ['English', 'Canada', 'en-CA'],
    ['English', 'India', 'en-IN'],
    ['English', 'Ireland', 'en-IE'],
    ['English', 'Malta', 'en-MT'],
    ['English', 'New Zealand', 'en-NZ'],
    ['English', 'Philippines', 'en-PH'],
    ['English', 'Singapore', 'en-SG'],
    ['English', 'South Africa', 'en-ZA'],
    ['English', 'United Kingdom', 'en-GB'],
    ['English', 'United States', 'en-US'],
    ['Estonian', 'Estonia', 'et-EE'],
    ['Finnish', 'Finland', 'fi-FI'],
    ['French', 'Belgium', 'fr-BE'],
    ['French', 'Canada', 'fr-CA'],
    ['French', 'France', 'fr-FR'],
    ['French', 'Luxembourg', 'fr-LU'],
    ['French', 'Switzerland', 'fr-CH'],
    ['German', 'Austria', 'de-AT'],
    ['German', 'Germany', 'de-DE'],
    ['German', 'Luxembourg', 'de-LU'],
    ['German', 'Switzerland', 'de-CH'],
    ['Greek', 'Cyprus', 'el-CY'],
    ['Greek', 'Greece', 'el-GR'],
    ['Hebrew', 'Israel', 'iw-IL'],
    ['Hindi', 'India', 'hi-IN'],
    ['Hungarian', 'Hungary', 'hu-HU'],
    ['Icelandic', 'Iceland', 'is-IS'],
    ['Indonesian', 'Indonesia', 'in-ID'],
    ['Irish', 'Ireland', 'ga-IE'],
    ['Italian', 'Italy', 'it-IT'],
    ['Italian', 'Switzerland', 'it-CH'],
    ['Japanese', 'Japan', 'ja-JP'],
    ['Korean', 'South Korea', 'ko-KR'],
    ['Latvian', 'Latvia', 'lv-LV'],
    ['Lithuanian', 'Lithuania', 'lt-LT'],
    ['Macedonian', 'Macedonia', 'mk-MK'],
    ['Malay', 'Malaysia', 'ms-MY'],
    ['Maltese', 'Malta', 'mt-MT'],
    ['Norwegian', 'Norway', 'no-NO'],
    ['Polish', 'Poland', 'pl-PL'],
    ['Portuguese', 'Brazil', 'pt-BR'],
    ['Portuguese', 'Portugal', 'pt-PT'],
    ['Romanian', 'Romania', 'ro-RO'],
    ['Russian', 'Russia', 'ru-RU'],
    ['Slovak', 'Slovakia', 'sk-SK'],
    ['Slovenian', 'Slovenia', 'sl-SI'],
    ['Spanish', 'Argentina', 'es-AR'],
    ['Spanish', 'Bolivia', 'es-BO'],
    ['Spanish', 'Chile', 'es-CL'],
    ['Spanish', 'Colombia', 'es-CO'],
    ['Spanish', 'Costa Rica', 'es-CR'],
    ['Spanish', 'Dominican Republic', 'es-DO'],
    ['Spanish', 'Ecuador', 'es-EC'],
    ['Spanish', 'El Salvador', 'es-SV'],
    ['Spanish', 'Guatemala', 'es-GT'],
    ['Spanish', 'Honduras', 'es-HN'],
    ['Spanish', 'Mexico', 'es-MX'],
    ['Spanish', 'Nicaragua', 'es-NI'],
    ['Spanish', 'Panama', 'es-PA'],
    ['Spanish', 'Paraguay', 'es-PY'],
    ['Spanish', 'Peru', 'es-PE'],
    ['Spanish', 'Puerto Rico', 'es-PR'],
    ['Spanish', 'Spain', 'es-ES'],
    ['Spanish', 'United States', 'es-US'],
    ['Spanish', 'Uruguay', 'es-UY'],
    ['Spanish', 'Venezuela', 'es-VE'],
    ['Swedish', 'Sweden', 'sv-SE'],
    ['Thai', 'Thailand', 'th-TH'],
    ['Turkish', 'Turkey', 'tr-TR'],
    ['Ukrainian', 'Ukraine', 'uk-UA'],
    ['Vietnamese', 'Vietnam', 'vi-VN'],
];
