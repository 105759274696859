"use strict";
/**
 *  Loader.tsx
 *  Loading segment in tables
 */
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const TableRowLoader = ({ colSpan, text = null, singleRow = false }) => {
    return singleRow ? (React.createElement(semantic_ui_react_1.Table.Row, null,
        React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: colSpan, className: "loader-row" },
            React.createElement(semantic_ui_react_1.Loader, { active: true, inline: true, size: "mini" }),
            React.createElement("span", null, text || 'Fetching & processing data..')))) : (React.createElement(semantic_ui_react_1.Table.Row, { className: 'g-loader-row no-hover-tr' },
        React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: colSpan },
            React.createElement("div", { className: "loader-cell" },
                React.createElement(semantic_ui_react_1.Loader, { active: true, inline: "centered", size: "small" }, text || 'Fetching & processing data..')))));
};
exports.default = TableRowLoader;
