"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_1 = require("react");
const crypto_1 = require("@actions/crypto");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const DeleteSyncedCryptoModal = ({ data, useModal, utils, setVisibility, switchView, }) => {
    const [step, setStep] = (0, react_1.useState)(1);
    const [removeOne, setRemoveOne] = (0, react_1.useState)(null);
    const [keepHistory, setKeepHistory] = (0, react_1.useState)(false);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [siblings, setSiblings] = (0, react_1.useState)([]);
    const [view, setView] = (0, react_1.useState)(null);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    (0, react_1.useEffect)(() => {
        const _siblings = _assets.getSyncedCryptoSiblings(data.account.id);
        setSiblings(_siblings);
        if (_siblings.length == 1) {
            setRemoveOne(false);
            setStep(2);
        }
        else {
            setStep(1);
        }
    }, []);
    (0, react_1.useEffect)(() => {
        useModal({
            header: (React.createElement("div", null,
                React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        switchView('LINKED_ACCOUNT_VIEW');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " Account Details"),
                React.createElement("span", { className: "header-text-large" }, "Remove Crypto Account"))),
        });
        return () => {
            useModal({
                header: null,
            });
        };
    }, []);
    (0, react_1.useEffect)(() => {
        useModal({
            middleButton: (step > 1 && siblings.length > 1) ||
                (step > 2 && siblings.length == 1) ? (React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "no-border", basic: true, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setStep(step - 1);
                }) }, "Back")) : (React.createElement(React.Fragment, null)),
            leftButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "no-border", basic: true, onClick: () => {
                    setVisibility(false, true);
                } }, 'Cancel')),
            rightButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", disabled: (removeOne === null && step == 1) ||
                    (keepHistory == null && step == 2), loading: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    if (step < 3) {
                        setStep(step + 1);
                    }
                    else {
                        setIsLoading(true);
                        let results = null;
                        if (removeOne) {
                            // Remove only one
                            results = yield utils._process(crypto_1.updateSynced)(data.account.id, {
                                excluded_currencies: [data.account.currency],
                                keep_history: keepHistory,
                            });
                            if (!results.error) {
                                if (!keepHistory && utils.remove) {
                                    // utils.remove([
                                    //   {
                                    //     zabo_account_id: data.account.id,
                                    //     zabo_currency: data.account.currency,
                                    //   },
                                    // ])
                                }
                                _assets.removeSyncedCrypto(data.account.id, data.account.currency);
                            }
                        }
                        else {
                            // Remove entire account
                            results = yield utils._process(crypto_1.removeSynced)(data.account.id, {
                                keep_history: keepHistory,
                            });
                            if (!results.error) {
                                if (!keepHistory && utils.remove) {
                                    // If removing from balance history
                                    utils
                                        .remove();
                                }
                                _assets.removeSyncedCrypto(data.account.id, null);
                            }
                        }
                        if (!results.error) {
                            setIsLoading(false);
                            setVisibility(false);
                            utils._showToast({
                                message: 'Successfully removed cryptocurrency account!',
                                type: 'success',
                            });
                        }
                        else {
                            setIsLoading(false);
                        }
                    }
                }) }, step == 3 ? 'Save changes' : 'Next')),
        });
        return () => {
            useModal({
                rightButton: null,
                leftButton: null,
                middleButton: null,
            });
        };
    }, [step, data, siblings, removeOne, keepHistory, isLoading]);
    (0, react_1.useEffect)(() => {
        if (step == 1) {
            setView(React.createElement(React.Fragment, null,
                React.createElement("p", null,
                    "You are syncing ",
                    siblings.length,
                    " cryptocurrencies (",
                    siblings.map(o => o.currency.toUpperCase()).join(', '),
                    ") with",
                    ' ',
                    data.account.institution_name,
                    "."),
                React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setRemoveOne(true);
                    }), className: `link-recurring-row ${removeOne == true ? 'selected' : ''}` },
                    React.createElement("div", null,
                        React.createElement("span", null,
                            "I want to stop syncing ",
                            data.account.currency.toUpperCase(),
                            ' ',
                            "balance only")),
                    removeOne ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))),
                React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setRemoveOne(false);
                    }), className: `link-recurring-row ${removeOne == false ? 'selected' : ''}` },
                    React.createElement("div", null,
                        React.createElement("span", null,
                            "I want to remove all accounts associated with",
                            ' ',
                            data.account.institution_name,
                            ", which includes:"),
                        siblings.map((account, index) => {
                            return (React.createElement("li", { key: `siblings-${index}` },
                                React.createElement("b", null, account.name)));
                        })),
                    removeOne == false ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" })))));
        }
        else if (step == 2) {
            setView(React.createElement(React.Fragment, null,
                React.createElement("p", null,
                    "There may be balance history associated with",
                    ' ',
                    removeOne
                        ? `${[
                            data.account.institution_name,
                            data.account.currency.toUpperCase(),
                        ]
                            .join(' ')
                            .trim()}`
                        : data.account.institution_name,
                    ' ',
                    "for calculating your net worth over time."),
                React.createElement("p", null, "Would you like to keep the balance history on this account, or remove it completely?"),
                React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        // setKeepHistory(true)
                    }), className: `link-recurring-row disabled ${keepHistory == true ? 'selected' : ''}` },
                    React.createElement("div", null,
                        React.createElement("span", null,
                            "I want to keep",
                            ' ',
                            removeOne
                                ? data.account.currency.toUpperCase()
                                : data.account.institution_name,
                            "'s balance history. (Not currently supported)")),
                    keepHistory ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))),
                React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setKeepHistory(false);
                    }), className: `link-recurring-row ${keepHistory == false ? 'selected' : ''}` },
                    React.createElement("div", null,
                        React.createElement("span", null,
                            "I want to remove",
                            ' ',
                            removeOne
                                ? data.account.currency.toUpperCase()
                                : data.account.institution_name,
                            "'s balance history.")),
                    keepHistory == false ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" })))));
        }
        else if (step == 3) {
            setView(React.createElement(React.Fragment, null,
                React.createElement("p", null, "Please confirm the following operations:"),
                React.createElement("div", { className: `not-clickable link-recurring-row selected` },
                    React.createElement("div", null, siblings.length > 1 && !removeOne ? (React.createElement("span", null,
                        ' ',
                        "Remove all account(s) under ",
                        data.account.institution_name,
                        ", including:",
                        siblings.map((account, index) => {
                            return (React.createElement("li", { key: `siblings-${index}` },
                                React.createElement("b", null, account.name)));
                        }))) : (React.createElement("span", null,
                        "Remove ",
                        React.createElement("b", null, data.account.currency.toUpperCase()),
                        " from my account"))),
                    React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })),
                React.createElement("div", { className: `not-clickable link-recurring-row selected` },
                    React.createElement("span", null, keepHistory
                        ? 'Keep account balance history'
                        : 'Remove account balance history'),
                    React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })),
                React.createElement("p", { className: "mt-1rem" }, "Note: This operation may take a few minutes depending on how much data we have imported for your accounts.")));
        }
    }, [data, step, siblings, keepHistory, removeOne]);
    return view;
};
exports.default = DeleteSyncedCryptoModal;
