"use strict";
/**
 *  Transactions.tsx
 *  Container for the Transactions page
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const user_1 = require("@/actions/user");
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const Maintenance = ({}) => {
    const errorInterval = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        const intervalId = setInterval(() => __awaiter(void 0, void 0, void 0, function* () {
            const results = yield (0, user_1.getUser)();
            if (!results.error) {
                window.location.href = '/overview';
            }
        }), 60 * 1000); // Try every minute
        errorInterval.current = intervalId;
        return () => clearInterval(errorInterval.current);
    }, []);
    return (React.createElement("div", { className: "g-logged-out" },
        React.createElement("div", { className: "g-logged-out-container" },
            React.createElement("div", { className: "logged-out-content" },
                React.createElement(semantic_ui_react_1.Container, { className: "center-align" },
                    React.createElement("div", { id: "logo-container" },
                        React.createElement("div", { id: "logo", className: "empty-logo" }),
                        React.createElement("div", { id: "shadow" })),
                    React.createElement(semantic_ui_react_1.Header, { as: "h2" }, "Undergoing Maintenance"),
                    React.createElement("p", null, "We'll be back shortly. Thank you for your patience while we continuously improve the site!"),
                    React.createElement("p", null, "This page will refresh automatically when we're out of maintenance mode."))))));
};
exports.default = Maintenance;
