"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const Moment = require("moment");
const TaskCard_1 = require("@components/TaskCard/TaskCard");
const RecurringExpensesTaskCard = ({ suggestedCount, missingCount = '0', onHover = () => { }, onBlur = () => { }, hideQuestionMark = false, isLoading = false, }) => {
    const history = (0, react_router_dom_1.useHistory)();
    const [title, setTitle] = (0, react_1.useState)(null);
    const [subtitle, setSubtitle] = (0, react_1.useState)(null);
    const [className, setClassName] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        if (parseInt(suggestedCount) > 0) {
            setTitle(`Review ${suggestedCount} recurring item${parseInt(suggestedCount) > 1 ? 's' : ''}`);
            setSubtitle(`We found ${parseInt(suggestedCount) > 1 ? 'some transactions' : 'a transaction'} we think might be recurring`);
            setClassName(``);
        }
        else if (parseInt(missingCount) > 0) {
            setTitle(`Review ${missingCount} recurring item${parseInt(missingCount) > 1 ? 's' : ''}`);
            setSubtitle(`${parseInt(missingCount) > 1
                ? 'Some recurring transactions have'
                : 'A recurring transaction has'} not occurred yet`);
            setClassName(``);
        }
        else {
            setTitle(`Review recurring items`);
            setSubtitle(``);
            setClassName(`task-done`);
        }
    }, [suggestedCount, missingCount]);
    return (React.createElement(TaskCard_1.default, { isLoading: typeof missingCount === 'undefined' || isLoading, type: "recurring", className: className, onClick: () => {
            if (parseInt(suggestedCount) > 0) {
                history.push(`/recurring/suggested`);
            }
            else {
                history.push(`/recurring/${Moment().format('YYYY/MM')}`);
            }
        }, title: title, subtitle: subtitle, onHover: onHover, onBlur: onBlur, hideQuestionMark: hideQuestionMark }));
};
exports.default = RecurringExpensesTaskCard;
