"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalConsumer = exports.GlobalContext = void 0;
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_toast_notifications_1 = require("react-toast-notifications");
/**
 * Global context
 */
exports.GlobalContext = React.createContext({
    toast: null,
    /** Actions */
    showToast: (_a = (this || { setToastMessage: null })) === null || _a === void 0 ? void 0 : _a.setToastMessage,
    removeToast: (_b = (this || { removeToast: null })) === null || _b === void 0 ? void 0 : _b.removeToast,
    [Symbol.iterator]: function* () {
        let properties = Object.keys(this);
        for (let i of properties) {
            yield [i, this[i]];
        }
    },
});
exports.GlobalConsumer = exports.GlobalContext.Consumer;
class GlobalProvider extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            toast: null,
            showToast: opts => this.setToastMessage(opts),
            removeToast: id => this.removeToast(id),
            [Symbol.iterator]: function* () {
                let properties = Object.keys(this);
                for (let i of properties) {
                    yield [i, this[i]];
                }
            },
        };
        this.TYPE_ICON = {
            success: 'checkmark',
            warning: 'exclamation',
            error: 'x',
            info: 'info',
        };
        this.TYPE_TIMER = {
            success: 2500,
            warning: 3500,
            error: 3500,
            info: 3500,
        };
        this.setToastMessage = (opts) => {
            this.props.toastManager.add(React.createElement("div", null,
                opts.message !== null && (React.createElement("p", null,
                    React.createElement(semantic_ui_react_1.Icon, { name: this.TYPE_ICON[opts.type] }),
                    " ",
                    React.createElement("b", null, opts.message))),
                opts.content), {
                appearance: opts.type,
                autoDismissTimeout: opts.autoDismissTimeout || this.TYPE_TIMER[opts.type],
            }, opts.callback || (id => { }));
        };
        this.removeToast = (id) => {
            this.props.toastManager.remove(id);
        };
    }
    render() {
        return (React.createElement(exports.GlobalContext.Provider, { value: this.state }, this.props.children));
    }
}
exports.default = (0, react_toast_notifications_1.withToastManager)(GlobalProvider);
