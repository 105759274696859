"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const _ = require("lodash");
const Moment = require("moment");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const transactions_1 = require("@helpers/transactions");
const dates_1 = require("@helpers/dates");
const report_1 = require("@helpers/report");
const semantic_ui_react_1 = require("semantic-ui-react");
const CategoriesProvider_1 = require("@providers/CategoriesProvider");
const TagsProvider_1 = require("@providers/TagsProvider");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const UserProvider_1 = require("@providers/UserProvider");
const Report_1 = require("@components/QueryTool/Report");
const Chart_1 = require("@components/QueryTool/Chart");
const colors_1 = require("@helpers/colors");
const react_csv_1 = require("react-csv");
const qs = require("query-string");
const QueryLoader_1 = require("@components/global/QueryLoader");
const PeriodPicker_1 = require("@components/QueryTool/PeriodPicker");
const Container = ({ view, transactionsGroup, triggerProperties, dataSetNames, filterDateStart, filterDateEnd, utils, isLoading, openTable, }) => {
    const BREAKDOWNS = [
        'tag',
        'tag/category',
        'category',
        'category/tag',
        'account',
        'account/category',
        'account/tag',
        'payee',
        'recurring',
        'totals',
    ];
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const _tags = (0, react_1.useContext)(TagsProvider_1.TagsContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const history = (0, react_router_dom_1.useHistory)();
    const [currentView, setCurrentView] = (0, react_1.useState)(view);
    const [chartBreakdown, setChartBreakdown] = (0, react_1.useState)(null);
    const [secondaryBreakdown, setSecondaryBreakdown] = (0, react_1.useState)(null);
    const [timeGranularity, setTimeGranularity] = (0, react_1.useState)(localStorage.getItem('_lm_query_tool_chart_granularity') ||
        'month');
    const [tooltipType, setTooltipType] = (0, react_1.useState)('nearest');
    const [numberType, setNumberType] = (0, react_1.useState)(localStorage.getItem('_lm_query_tool_number_type') ||
        'number');
    const [datasetIndex, setDatasetIndex] = (0, react_1.useState)(0);
    const [baseUrl, setBaseUrl] = (0, react_1.useState)(null);
    const [datasetSelector, setDatasetSelector] = (0, react_1.useState)([]);
    const [hiddenKeys, setHiddenKeys] = (0, react_1.useState)({});
    const [timeArray, setTimeArray] = (0, react_1.useState)(null);
    const [transformedData, setTransformedData] = (0, react_1.useState)([]);
    const [showExclude, setShowExclude] = (0, react_1.useState)(localStorage.getItem('_lm_query_tool_show_exclude') == 'true');
    const [showUngroup, setShowUngroup] = (0, react_1.useState)(localStorage.getItem('_lm_query_tool_show_ungroup') == 'true');
    const [expandCategories, setExpandCategories] = (0, react_1.useState)(localStorage.getItem('_lm_query_tool_expand_categories') == 'true');
    const [averageRowCalculation, setAverageRowCalculation] = (0, react_1.useState)(localStorage.getItem('_lm_query_tool_average_calculation') || 'non-zero');
    const [averageColCalculation, setAverageColCalculation] = (0, react_1.useState)(localStorage.getItem('_lm_query_tool_average_calculation_column') ||
        'categories');
    const [showPieGroups, setShowPieGroups] = (0, react_1.useState)(Boolean(localStorage.getItem('_lm_query_tool_show_pie_groups') == 'true' || true));
    // Settings
    const [normalizeIncome, setNormalizeIncome] = (0, react_1.useState)(localStorage.getItem('_lm_query_tool_normalize_income') == 'true');
    const exportable = (0, react_1.useRef)([]);
    const [initExport, setInitExport] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        // Converts properties within URL to state properties
        if (triggerProperties == null)
            return;
        const opts = qs.parse(history.location.search);
        const datasets = opts['opts'].split(',');
        const filteredDatasets = datasets.map((data, index) => {
            const parsed = qs.parse(data);
            // get settings from url
            if (parsed['time_granularity']) {
                localStorage.setItem('_lm_query_tool_chart_granularity', parsed['time_granularity']);
                setTimeGranularity(parsed['time_granularity']);
                delete parsed['time_granularity'];
            }
            if (parsed['show_exclude']) {
                localStorage.setItem('_lm_query_tool_show_exclude', parsed['show_exclude']);
                setShowExclude(parsed['show_exclude'] == 'true');
                delete parsed['show_exclude'];
            }
            if (parsed['show_ungroup']) {
                localStorage.setItem('_lm_query_tool_show_ungroup', parsed['show_ungroup']);
                setShowUngroup(parsed['show_ungroup'] == 'true');
                delete parsed['show_ungroup'];
            }
            if (parsed['number_type']) {
                localStorage.setItem('_lm_query_tool_number_type', parsed['number_type']);
                setNumberType(parsed['number_type']);
                delete parsed['number_type'];
            }
            if (parsed['chart_breakdown']) {
                localStorage.setItem('_lm_query_tool_chart_view', parsed['chart_breakdown']);
                setChartBreakdown(parsed['chart_breakdown']);
                delete parsed['chart_breakdown'];
            }
            if (parsed['current_view']) {
                delete parsed['current_view'];
            }
            return qs.stringify(parsed);
        });
        history.replace({
            to: '/analyze',
            search: qs.stringify({
                opts: filteredDatasets,
            }, { arrayFormat: 'comma' }),
        }, { refresh: false });
    }, [triggerProperties]);
    (0, react_1.useEffect)(() => {
        if ((transactionsGroup === null || transactionsGroup === void 0 ? void 0 : transactionsGroup.length) > 0) {
            // On new transactions load, set hidden keys to whatever is in the URL
            const opts = qs.parse(history.location.search);
            const datasets = opts['opts'].split(',');
            const _hiddenKeys = {};
            datasets.forEach((data, index) => {
                var _a;
                const set = qs.parse(data);
                // If there is only one thing in parsed.hidden, then it presents itself as string
                // Make it an array
                if ((set === null || set === void 0 ? void 0 : set.hidden) && typeof set.hidden == 'string') {
                    set['hidden'] = [set['hidden']];
                }
                if (((_a = set['hidden']) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    ;
                    set['hidden'].forEach(key => {
                        _hiddenKeys[key] = true;
                    });
                }
            });
            setHiddenKeys(_hiddenKeys);
        }
    }, [transactionsGroup]);
    const updateHiddenKeysQuery = keys => {
        var _a;
        // Every time we update hidden keys, we need to make sure the URL also
        // updates without affecting anything else
        const hidden = { [0]: [] };
        const opts = qs.parse(history.location.search);
        const datasets = opts['opts'].split(',');
        if (!!keys && ((_a = Object.keys(keys)) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            Object.keys(keys).forEach(key => {
                if (keys[key]) {
                    const split = key.split('/');
                    if (split.length == 1) {
                        // Only one dataset
                        hidden[0].push(key);
                    }
                    else {
                        // More than one dataset
                        if (hidden.hasOwnProperty(parseInt(split[0]))) {
                            hidden[parseInt(split[0])].push(key);
                        }
                        else {
                            hidden[parseInt(split[0])] = [key];
                        }
                    }
                }
            });
            const finalDatasets = datasets.map((data, index) => {
                const set = qs.parse(data);
                return qs.stringify(Object.assign(Object.assign({}, set), { hidden: hidden[index] }));
            });
            history.replace({
                to: '/analyze',
                search: qs.stringify({ opts: finalDatasets }, { arrayFormat: 'comma' }),
            }, { refresh: false });
        }
        else {
            const finalDatasets = datasets.map((data, index) => {
                const set = qs.parse(data);
                delete set.hidden;
                return qs.stringify(set);
            });
            history.replace({
                to: '/analyze',
                search: qs.stringify({ opts: finalDatasets }, { arrayFormat: 'comma' }),
            }, { refresh: false });
        }
    };
    (0, react_1.useEffect)(() => {
        const isMultiSet = transactionsGroup.length > 1;
        let groupBy = localStorage.getItem('_lm_query_tool_chart_view') || 'by_category';
        // Make sure Breakdown settings available for view
        if (groupBy == 'total') {
            localStorage.setItem('_lm_query_tool_chart_view', 'by_totals');
            groupBy = 'by_totals'; // Backward compatible
        }
        if (view == 'line' && isMultiSet) {
            groupBy = 'by_totals';
        }
        else if (view == 'bar' && isMultiSet) {
            groupBy = 'by_totals';
        }
        else if ((view == 'report' || view == 'stacked') &&
            groupBy == 'by_totals') {
            groupBy = 'by_category';
        }
        else if (view == 'pie' && !isMultiSet && groupBy == 'by_totals') {
            groupBy = 'by_category';
        }
        setChartBreakdown(groupBy);
        setSecondaryBreakdown(localStorage.getItem('_lm_query_tool_chart_view_secondary') || null);
        setCurrentView(view);
    }, [view, transactionsGroup]);
    (0, react_1.useEffect)(() => {
        if (!timeArray)
            return;
        if (!chartBreakdown)
            return;
        let groupBy = chartBreakdown;
        const allData = [];
        transactionsGroup.map((txGroup, index) => {
            const transform = new report_1.ReportHelper().transformData({
                transactions: txGroup,
                timeArray: timeArray,
                timeGranularity,
                nestedCategories: _categories.nested,
                groupBy: groupBy,
                showExclude: showExclude,
                ungroup: showUngroup,
                allowRecurringCategories: _user.settings['allow_recurring_categories'],
                secondaryBreakdown,
            }, {
                getCategoryName: _categories.getName,
                getTagName: _tags.getTagName,
                getAssetName: _assets.getAssetName,
                getPlaidAccountName: _assets.getPlaidAccountName,
            });
            allData.push(transform);
        });
        setTransformedData(allData);
        try {
            if (allData.length == 1) {
                setDatasetSelector(allData[0].data
                    .map((datum, index) => {
                    return {
                        id: datum.id,
                        has_children: datum.has_children,
                        parent_id: datum.group_id,
                        is_child: !!datum.group_name,
                        order: `${datum.group_name ? datum.group_name + '/' : ''}${datum.name}${datum.has_children ? ' (group)' : ''}`,
                        label: datum.name,
                        color: colors_1.CHART_COLORS[index % colors_1.CHART_COLORS.length],
                    };
                })
                    .sort((a, b) => {
                    return a.order
                        .trim()
                        .toLowerCase()
                        .localeCompare(b.order.trim().toLowerCase());
                }));
            }
            else {
                const selectors = [];
                const colors = [];
                let colorIndex = 0;
                allData.forEach((dataset, _datasetIndex) => {
                    if (currentView !== 'report' || datasetIndex == _datasetIndex) {
                        const set = dataset.data
                            .map((datum, index) => {
                            let color;
                            if (colors.hasOwnProperty(datum.id)) {
                                color = colors[datum.id];
                            }
                            else {
                                color = colors_1.CHART_COLORS[colorIndex++ % colors_1.CHART_COLORS.length];
                                colors[datum.id] = color;
                            }
                            return {
                                id: `${_datasetIndex}/${datum.id}`,
                                has_children: datum.has_children,
                                parent_id: `${_datasetIndex}/${datum.group_id}`,
                                is_child: !!datum.group_name,
                                label: datum.name,
                                order: `${datum.group_name ? datum.group_name + '/' : ''}${datum.name}${datum.has_children ? ' (group)' : ''}`,
                                color: color,
                            };
                        })
                            .filter(o => !!o)
                            .sort((a, b) => {
                            return a.order
                                .trim()
                                .toLowerCase()
                                .localeCompare(b.order.trim().toLowerCase());
                        });
                        selectors.push({
                            id: `dataset-${_datasetIndex}`,
                            label: dataSetNames[_datasetIndex],
                            color: colors_1.CHART_COLORS[_datasetIndex % colors_1.CHART_COLORS.length],
                        });
                        if (['pie', 'report', 'stacked'].indexOf(currentView) > -1) {
                            selectors.push(...set);
                        }
                    }
                });
                setDatasetSelector(selectors);
            }
        }
        catch (e) {
            console.log('Error in setting dataset selector', e);
        }
    }, [
        timeArray,
        transactionsGroup,
        timeGranularity,
        currentView,
        datasetIndex,
        chartBreakdown,
        showExclude,
        showUngroup,
        secondaryBreakdown,
    ]);
    (0, react_1.useEffect)(() => {
        const time = (0, dates_1.getTimeArray)(filterDateStart ||
            Moment()
                .subtract(5, 'months')
                .startOf('month')
                .format('YYYY-MM-DD'), filterDateEnd || Moment().format('YYYY-MM-DD'), timeGranularity).map(arr => {
            return Moment(arr.key, 'YYYYMMDD').format('YYYY-MM-DD');
        });
        setTimeArray(time);
    }, [filterDateEnd, filterDateStart, timeGranularity]);
    (0, react_1.useEffect)(() => {
        try {
            const opts = utils._getMultiOpts()[datasetIndex];
            if (!showExclude) {
                // Gather all the excluded categories
                const excluded = _categories.assignable
                    .filter(cat => {
                    return cat.exclude_from_totals;
                })
                    .map(o => o.id);
                opts.category_exclude = excluded;
            }
            if (!showUngroup) {
                opts.type_exclude = ['part_of_group'];
            }
            // Transfer into transactions filter url_date
            setBaseUrl((0, transactions_1.buildUrl)(opts));
        }
        catch (e) {
            console.log('Error getting opts', e);
        }
    }, [datasetIndex, showExclude, showUngroup]);
    const toQuery = (datum, date, includeCats = false, categoryList = []) => {
        try {
            const toQueryObj = (breakdown, value) => {
                const obj = {};
                if (breakdown.indexOf('category') > -1) {
                    if (value == '-1' || value == 'uncategorized') {
                        obj['uncategorized'] = true;
                    }
                    else if (value == '-2') {
                        obj['recurring'] = 'any';
                    }
                    else {
                        obj['category'] = [value];
                    }
                }
                else if (breakdown.indexOf('recurring') > -1) {
                    if (value !== 'Not recurring') {
                        // If value is null, then don't match recurring
                        obj['recurring'] = value;
                    }
                    else {
                        obj['recurring_exclude'] = 'any';
                    }
                }
                else if (breakdown.indexOf('tag') > -1) {
                    if (value == '-1' || value == 'untagged') {
                        obj['untagged'] = true;
                    }
                    else {
                        obj['tag'] = [value];
                    }
                }
                else if (breakdown.indexOf('account') > -1) {
                    if (value == 'cash') {
                        obj['asset'] = 'cash';
                    }
                    else if (value.toString().indexOf('plaid_') == 0) {
                        obj['account'] = value.substring(6);
                    }
                    else if (value.toString().indexOf('asset_') == 0) {
                        obj['asset'] = value.substring(6);
                    }
                }
                else if (breakdown.indexOf('payee') > -1) {
                    obj['payee_exact'] = value;
                }
                else if (breakdown.indexOf('totals') > -1) {
                }
                return obj;
            };
            let obj = {};
            if (datum !== null) {
                obj = Object.assign(Object.assign({}, toQueryObj(chartBreakdown, datum.is_secondary ? datum.primaryId : datum.id)), (secondaryBreakdown
                    ? toQueryObj(secondaryBreakdown, datum.is_secondary ? datum.id : datum.primaryId)
                    : {}));
            }
            if (date == 'all') {
                obj['start_date'] =
                    filterDateStart ||
                        Moment()
                            .subtract(5, 'months')
                            .startOf('month')
                            .format('YYYY-MM-DD');
                obj['end_date'] =
                    filterDateEnd ||
                        Moment()
                            .endOf('month')
                            .format('YYYY-MM-DD');
            }
            else {
                if (Moment(date).isBefore(Moment(filterDateStart, 'YYYY-MM-DD'))) {
                    date = filterDateStart;
                }
                obj['start_date'] = date;
                obj['end_date'] = Moment(date, 'YYYY-MM-DD')
                    .endOf(timeGranularity)
                    .format('YYYY-MM-DD');
                if (Moment(obj['end_date']).isAfter(Moment(filterDateEnd, 'YYYY-MM-DD'))) {
                    obj['end_date'] = filterDateEnd;
                }
            }
            if (includeCats) {
                // Any exclusions?
                const _opts = qs.parse(history.location.search);
                const opts = qs.parse(_opts['opts']);
                // hidden is what's actively being hidden
                let hidden = ((opts === null || opts === void 0 ? void 0 : opts.hidden) || []);
                // category_ids is the set of categories being shown. if empty, then it's all
                const category_ids = opts === null || opts === void 0 ? void 0 : opts.category_ids;
                // if there are only hidden
                if ((hidden === null || hidden === void 0 ? void 0 : hidden.length) > 0 &&
                    (!category_ids || (category_ids === null || category_ids === void 0 ? void 0 : category_ids.length) == 0)) {
                    const exclude_from_show = new Set();
                    // Take categoryList and remove hidden from it
                    // categoryList is the masterList
                    // We need to remove hidden from it
                    hidden === null || hidden === void 0 ? void 0 : hidden.forEach(category_id => {
                        const category = _categories.get(category_id);
                        if (category) {
                            // If a category group is hidden but a child is not, then we need to
                            // unhide the category group
                            exclude_from_show.add(parseInt(category.id));
                            if (
                            // if it's in a group and it's hidden, then the cat group should never be shown as a whole
                            !category.is_group &&
                                !!category.group_id) {
                                exclude_from_show.add(parseInt(category.group_id));
                            }
                        }
                    });
                    obj['category'] = _.difference(categoryList, Array.from(exclude_from_show));
                    obj['match'] = obj['category'].length == 1 ? 'all' : 'any';
                }
                else if ((category_ids === null || category_ids === void 0 ? void 0 : category_ids.length) > 0 && (hidden === null || hidden === void 0 ? void 0 : hidden.length) == 0) {
                    obj['category'] = category_ids;
                    obj['match'] = 'any';
                }
                else {
                    const exclude_from_hidden = new Set();
                    // Take categoryList and remove hidden from it
                    // categoryList is the masterList
                    // We need to remove hidden from it
                    hidden === null || hidden === void 0 ? void 0 : hidden.forEach(category_id => {
                        const category = _categories.get(category_id);
                        if (category) {
                            // If a category group is hidden but a child is not, then we need to
                            // unhide the category group
                            exclude_from_hidden.add(parseInt(category.id));
                            if (!category.is_group &&
                                !!category.group_id &&
                                hidden.indexOf(category.group_id.toString()) > -1) {
                                exclude_from_hidden.add(parseInt(category.group_id));
                            }
                        }
                    });
                    obj['category'] = _.difference(categoryList, Array.from(exclude_from_hidden));
                    obj['match'] = 'any';
                }
            }
            return obj;
        }
        catch (e) {
            console.log(e);
        }
    };
    const getVisibleBreakdown = () => {
        let visibleBreakdown = chartBreakdown;
        if (currentView == 'line' && transformedData.length == 0) {
            visibleBreakdown = 'by_totals';
        }
        else if (currentView == 'bar' && transformedData.length == 0) {
            visibleBreakdown = 'by_totals';
        }
        else if ((currentView == 'report' || currentView == 'stacked') &&
            visibleBreakdown == 'by_totals') {
            visibleBreakdown = 'by_category';
        }
        else if (currentView == 'pie' &&
            transformedData.length == 0 &&
            chartBreakdown == 'by_totals') {
            visibleBreakdown = 'by_category';
        }
        return visibleBreakdown;
    };
    return (React.createElement("div", { className: "query-tool-container" },
        React.createElement("div", { className: "header-controls" },
            React.createElement("div", { className: "display--flex" },
                React.createElement(PeriodPicker_1.default, { className: "on-bg", disabled: isLoading, useSelectedDate: true }),
                !isLoading && currentView == 'report' && dataSetNames.length > 1 && (React.createElement(semantic_ui_react_1.Dropdown, { className: "query-dropdown mr-05rem", placeholder: "View", selection: true, value: datasetIndex, onChange: (e, { value }) => {
                        setDatasetIndex(value);
                    }, options: dataSetNames.map((name, index) => {
                        return {
                            key: name,
                            value: index,
                            text: `View: ${name}`,
                        };
                    }) })),
                !isLoading && currentView == 'report' && (React.createElement(React.Fragment, null,
                    React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", trigger: React.createElement("div", { className: "mr-05rem icon-container", onClick: () => {
                                setInitExport(true);
                                setTimeout(() => {
                                    document.getElementById('export-csv').click();
                                    setInitExport(false);
                                }, 500);
                            } },
                            React.createElement(semantic_ui_react_1.Icon, { name: "download", color: "black", className: "clickable" })) }, "Export to CSV"),
                    initExport && (React.createElement(react_csv_1.CSVLink, { uFEFF: false, id: "export-csv", filename: `lunchmoney-${Moment().format('YYYYMMDDHHmmss')}.csv`, data: exportable.current }))))),
            !isLoading && (React.createElement("div", { className: "display--flex" },
                currentView == 'report' && (React.createElement(semantic_ui_react_1.Dropdown, { className: "query-dropdown min-width-48", value: numberType, placeholder: "Number", selection: true, onChange: (e, { value }) => {
                        localStorage.setItem('_lm_query_tool_number_type', value);
                        setNumberType(value);
                    }, options: [
                        {
                            key: 'percentage',
                            value: 'percentage',
                            text: '%',
                        },
                        {
                            key: 'number',
                            value: 'number',
                            text: '#',
                        },
                    ] })),
                currentView !== 'pie' && (React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-05rem query-dropdown min-width-95", selection: true, value: timeGranularity, placeholder: "Chart Granularity", onChange: (e, { value }) => {
                        localStorage.setItem('_lm_query_tool_chart_granularity', value);
                        setTimeGranularity(value);
                    }, options: [
                        {
                            key: 'day',
                            value: 'day',
                            text: 'Day',
                        },
                        {
                            key: 'week',
                            value: 'week',
                            text: 'Week',
                        },
                        {
                            key: 'month',
                            value: 'month',
                            text: 'Month',
                        },
                        {
                            key: 'year',
                            value: 'year',
                            text: 'Year',
                        },
                    ] })),
                ((transformedData.length > 1 &&
                    ['line', 'bar'].indexOf(currentView) == -1) ||
                    transformedData.length == 1) && (React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-05rem query-dropdown", selection: true, value: `${getVisibleBreakdown()}${secondaryBreakdown && getVisibleBreakdown() !== 'by_recurring'
                        ? `/${secondaryBreakdown}`
                        : ''}`, options: BREAKDOWNS.filter(o => {
                        if ((currentView == 'report' ||
                            currentView == 'stacked' ||
                            (currentView == 'pie' && transformedData.length <= 1)) &&
                            o == 'totals') {
                            return false;
                        }
                        return true;
                    }).map(breakdown => {
                        const split = breakdown.split('/');
                        const primary = split[0];
                        const secondary = split[1] || null;
                        return {
                            key: `dropdown-${breakdown}`,
                            value: `by_${breakdown}`,
                            text: primary == 'payee' ? 'Payee (top 100)' : `${breakdown}`,
                            selected: `${getVisibleBreakdown()}${secondaryBreakdown &&
                                getVisibleBreakdown() !== 'by_recurring'
                                ? `/${secondaryBreakdown}`
                                : ''}` == `by_${breakdown}`,
                            onClick: () => {
                                setHiddenKeys({});
                                updateHiddenKeysQuery(null);
                                localStorage.setItem('_lm_query_tool_chart_view', `by_${primary}`);
                                if (secondary) {
                                    localStorage.setItem('_lm_query_tool_chart_view_secondary', secondary);
                                }
                                else {
                                    localStorage.removeItem('_lm_query_tool_chart_view_secondary');
                                }
                                setChartBreakdown(`by_${primary}`);
                                setSecondaryBreakdown(secondary);
                            },
                        };
                    }) })),
                React.createElement(semantic_ui_react_1.Popup, { wide: true, position: "bottom right", trigger: React.createElement("div", { className: "ml-05rem icon-container" },
                        React.createElement(semantic_ui_react_1.Icon, { name: "filter", className: "clickable" })), content: React.createElement("div", { className: "dataset-picker" },
                        React.createElement("div", { className: "mb-05rem right-align" },
                            React.createElement("span", { onClick: () => {
                                    setHiddenKeys({});
                                    updateHiddenKeysQuery(null);
                                }, className: "link clickable" }, "Select all"),
                            ' ',
                            "|",
                            ' ',
                            React.createElement("span", { onClick: () => {
                                    const keys = {};
                                    if (transformedData.length == 1) {
                                        transformedData[0].data.forEach(o => {
                                            keys[o.id] = true;
                                        });
                                        setHiddenKeys(keys);
                                        updateHiddenKeysQuery(keys);
                                    }
                                }, className: "link clickable" }, "Deselect all")),
                        currentView == 'pie' && (React.createElement(semantic_ui_react_1.Message, { info: true },
                            React.createElement("p", null, "In a pie chart, choose to show category groups or ungrouped categories."),
                            React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: !showPieGroups, onChange: (e, { checked }) => {
                                    setShowPieGroups(!checked);
                                }, label: 'Show ungrouped categories' }))),
                        datasetSelector.map((dataset, index) => {
                            const isDataset = dataset.id.toString().indexOf('dataset') > -1;
                            const isBar = currentView == 'bar';
                            const isLine = currentView == 'line';
                            const isStacked = currentView == 'stacked';
                            const isPie = currentView == 'pie';
                            const isReport = currentView == 'report';
                            const isMultiset = transformedData.length > 1;
                            const isChild = dataset.is_child;
                            return (React.createElement(React.Fragment, { key: `dataset-selector-${index}` },
                                index > 0 && isDataset && !isBar && !isLine && (React.createElement("p", { className: "divider" })),
                                isReport &&
                                    isMultiset &&
                                    dataset.id.toString().indexOf('dataset') > -1 ? (React.createElement("p", { className: "label-style mb-05rem" }, dataset.label)) : (React.createElement("div", { className: `dataset` },
                                    ((isMultiset && !isDataset && !isChild) ||
                                        (!isMultiset && isChild)) && (React.createElement("span", { className: "hierarchy-line-icon mr-05rem" })),
                                    isMultiset && isChild && (React.createElement("span", { className: "hierarchy-line-icon mr-05rem ml-2rem" })),
                                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: !hiddenKeys[dataset.id], onChange: (e, { checked }) => {
                                            var _a;
                                            const override = {};
                                            if (dataset.has_children) {
                                                // Same setting for children
                                                (_a = datasetSelector
                                                    .filter(o => o.parent_id == dataset.id)) === null || _a === void 0 ? void 0 : _a.forEach(o => {
                                                    override[o.id] = !checked;
                                                });
                                            }
                                            if (!checked) {
                                                // Also uncheck the parent, if any
                                                if (dataset.is_child) {
                                                    override[dataset.parent_id] = !checked;
                                                }
                                            }
                                            setHiddenKeys(Object.assign(Object.assign(Object.assign({}, hiddenKeys), override), { [dataset.id]: !checked }));
                                            updateHiddenKeysQuery(Object.assign(Object.assign(Object.assign({}, hiddenKeys), override), { [dataset.id]: !checked }));
                                        }, label: dataset.label, 
                                        // Don't show special colors if this is the main
                                        // dataset and we're on stacked & pie with multiple
                                        // datasets
                                        className: (!isStacked && !isPie) || !isDataset
                                            ? `bg-${dataset.color.substring(1)}`
                                            : '' })))));
                        })), on: "click" }),
                React.createElement(semantic_ui_react_1.Popup, { wide: true, position: "bottom right", trigger: React.createElement("div", { className: "ml-05rem icon-container" },
                        React.createElement(semantic_ui_react_1.Icon, { name: "cog", className: "clickable" })), on: "click", content: React.createElement(React.Fragment, null,
                        React.createElement("div", null,
                            React.createElement(semantic_ui_react_1.Dropdown, { className: "mr-05rem inline-breakdown-dropdown no-wrap", inline: true, value: showExclude ? 'show' : 'hide', onChange: (e, { value }) => {
                                    localStorage.setItem('_lm_query_tool_show_exclude', value == 'show' ? 'true' : 'false');
                                    setShowExclude(value == 'show');
                                }, options: [
                                    {
                                        key: 'show',
                                        value: 'show',
                                        text: 'Include',
                                    },
                                    {
                                        key: 'hide',
                                        value: 'hide',
                                        text: 'Exclude',
                                    },
                                ] }),
                            React.createElement("span", null,
                                "'Exclude from Totals' transactions",
                                ' ',
                                React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "help circle" }), inverted: true, size: "tiny" }, "Certain categories can be marked 'exclude from totals'. By default, transactions categorized as such will be hidden from any totals calculations."))),
                        React.createElement("div", { className: "mt-05rem" },
                            React.createElement("span", null, "Count grouped transactions"),
                            React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-05rem mr-05rem inline-breakdown-dropdown no-wrap", inline: true, value: showUngroup ? 'include' : 'exclude', onChange: (e, { value }) => {
                                    localStorage.setItem('_lm_query_tool_show_ungroup', value == 'include' ? 'true' : 'false');
                                    setShowUngroup(value == 'include');
                                }, options: [
                                    {
                                        key: 'include',
                                        value: 'include',
                                        text: 'individually',
                                    },
                                    {
                                        key: 'exclude',
                                        value: 'exclude',
                                        text: 'in group',
                                    },
                                ] }),
                            React.createElement("span", null,
                                React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "help circle" }), inverted: true, size: "tiny" }, "Transactions in groups can maintain their own properties. Choose to count these transactions individually or as part of the group."))),
                        React.createElement("div", { className: "mt-05rem" },
                            React.createElement(semantic_ui_react_1.Dropdown, { className: "mr-05rem inline-breakdown-dropdown no-wrap", inline: true, value: expandCategories ? 'expand' : 'collapse', onChange: (e, { value }) => {
                                    localStorage.setItem('_lm_query_tool_expand_categories', value == 'expand' ? 'true' : 'false');
                                    setExpandCategories(value == 'expand');
                                }, options: [
                                    {
                                        key: 'expand',
                                        value: 'expand',
                                        text: 'Expand',
                                    },
                                    {
                                        key: 'collapse',
                                        value: 'collapse',
                                        text: 'Collapse',
                                    },
                                ] }),
                            React.createElement("span", null, "category groups in Report"),
                            ' ',
                            React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "help circle" }), inverted: true, size: "tiny" }, "In the Reports view grouped by category, choose whether to show category groups as expanded or collapsed by default.")),
                        React.createElement("div", { className: "mt-05rem" },
                            React.createElement("span", null, "Show average (row) over"),
                            ' ',
                            React.createElement(semantic_ui_react_1.Dropdown, { className: "mr-05rem inline-breakdown-dropdown no-wrap", inline: true, value: averageRowCalculation, onChange: (e, { value }) => {
                                    localStorage.setItem('_lm_query_tool_average_calculation', value);
                                    setAverageRowCalculation(value);
                                }, options: [
                                    {
                                        key: 'all',
                                        value: 'all',
                                        text: 'all time periods',
                                    },
                                    {
                                        key: 'non-zero',
                                        value: 'non-zero',
                                        text: 'active periods only',
                                    },
                                ] }),
                            React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "help circle" }), inverted: true, size: "tiny" }, "In the Reports view, choose whether the average calculation on the right-hand side should be calculated over all time periods, or only over time periods with transactions.")),
                        React.createElement("div", { className: "mt-05rem" },
                            React.createElement("span", null, "Show average (column) over"),
                            ' ',
                            React.createElement(semantic_ui_react_1.Dropdown, { className: "mr-05rem inline-breakdown-dropdown no-wrap", inline: true, value: averageColCalculation, onChange: (e, { value }) => {
                                    localStorage.setItem('_lm_query_tool_average_calculation_column', value);
                                    setAverageColCalculation(value);
                                }, options: [
                                    {
                                        key: 'time',
                                        value: 'time',
                                        text: 'time periods (days, weeks, months)',
                                    },
                                    {
                                        key: 'categories',
                                        value: 'categories',
                                        text: 'active categories only',
                                    },
                                ] }),
                            React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "help circle" }), inverted: true, size: "tiny" }, "In the Reports view, choose whether the average calculation on the bottom should be calculated over time periods (e.g. divide by 12 for yearly analysis, divide by number of days for monthly analysis), or only over the number of categories, tags, accounts, or payee with transactions.")),
                        chartBreakdown == 'by_category' &&
                            ['line', 'stacked', 'bar'].indexOf(currentView) > -1 && (React.createElement("div", { className: "mt-05rem" },
                            React.createElement(semantic_ui_react_1.Dropdown, { className: "mr-05rem inline-breakdown-dropdown no-wrap", inline: true, value: normalizeIncome ? 'normalize' : 'x-normalize', onChange: (e, { value }) => {
                                    setNormalizeIncome(value == 'normalize');
                                    localStorage.setItem('_lm_query_tool_normalize_income', value == 'normalize' ? 'true' : 'false');
                                }, options: [
                                    {
                                        key: 'normalize',
                                        value: 'normalize',
                                        text: 'Normalize',
                                    },
                                    {
                                        key: 'x-normalize',
                                        value: 'x-normalize',
                                        text: "Don't normalize",
                                    },
                                ] }),
                            React.createElement("span", null, "income amount"),
                            ' ',
                            React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "help circle" }) }, "Flip the sign for Income categories to easily compare them to expenses.")))) })))),
        isLoading ? (React.createElement(QueryLoader_1.default, null)) : (React.createElement(React.Fragment, null,
            currentView == 'report' && (React.createElement(Report_1.default, { hiddenKeys: Object.keys(hiddenKeys)
                    .map(key => {
                    if (hiddenKeys[key]) {
                        return key;
                    }
                    else {
                        return null;
                    }
                })
                    .filter(o => !!o), expandCategories: expandCategories, averageRowCalculation: averageRowCalculation, averageColCalculation: averageColCalculation, transformedData: transformedData[datasetIndex], datasetIndex: datasetIndex, timeGranularity: timeGranularity, timeArray: timeArray, numberType: numberType, groupBy: chartBreakdown, filterDateStart: filterDateStart, filterDateEnd: filterDateEnd, baseUrl: baseUrl, toQuery: toQuery, exportable: exportable, hasSecondaryBreakdown: !!secondaryBreakdown, openTable: openTable })),
            currentView !== 'report' && (React.createElement(Chart_1.default, { groupBy: chartBreakdown, hiddenKeys: Object.keys(hiddenKeys)
                    .map(key => {
                    if (hiddenKeys[key]) {
                        return key;
                    }
                    else {
                        return null;
                    }
                })
                    .filter(o => !!o), dataSetNames: dataSetNames, transformedData: transformedData, timeGranularity: timeGranularity, timeArray: timeArray, tooltipType: tooltipType, currentView: currentView, filterDateStart: filterDateStart, filterDateEnd: filterDateEnd, normalizeIncome: normalizeIncome, showPieGroups: showPieGroups, baseUrl: baseUrl, toQuery: toQuery }))))));
};
exports.default = Container;
