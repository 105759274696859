"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryToolProvider = exports.QueryToolContext = void 0;
const React = require("react");
const react_1 = require("react");
const QueryToolContext = (0, react_1.createContext)(undefined);
exports.QueryToolContext = QueryToolContext;
const QueryToolProvider = props => {
    const allDataSets = (0, react_1.useRef)({});
    return (React.createElement(QueryToolContext.Provider, { value: [
            {
                allDataSets,
            },
        ] }, props.children));
};
exports.QueryToolProvider = QueryToolProvider;
