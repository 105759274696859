"use strict";
/**
 *  Transactions.tsx
 *  Container for the Transactions page
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const Moment = require("moment");
const date_fns_1 = require("date-fns");
const semantic_ui_react_1 = require("semantic-ui-react");
const EditableDate_1 = require("@components/elements/EditableDate");
const EditableString_1 = require("@components/elements/EditableString");
const UserProvider_1 = require("@/providers/UserProvider");
const AssetsProvider_1 = require("@/providers/AssetsProvider");
const PlaidConfirmPopup = ({ showPlaidLinkConfirmation, plaidMetadata, plaidPublicToken, init, onClose, }) => {
    var _a, _b, _c, _d, _e, _f;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [index, setIndex] = (0, react_1.useState)(0);
    const [plaidAccounts, setPlaidAccounts] = (0, react_1.useState)(plaidMetadata === null || plaidMetadata === void 0 ? void 0 : plaidMetadata.newAccountsDetected);
    const [mergingAccountsSetting, setMergingAccountsSetting] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        // Initialize plaidAccounts with plaidMetadata
        setPlaidAccounts(plaidMetadata === null || plaidMetadata === void 0 ? void 0 : plaidMetadata.newAccountsDetected.map(o => {
            var _a;
            // check plaidMetadata.prevMissingAccounts to see if there is a match
            const prevMissingAccount = (_a = plaidMetadata === null || plaidMetadata === void 0 ? void 0 : plaidMetadata.prevMissingAccounts) === null || _a === void 0 ? void 0 : _a.find(prevMissingAccount => {
                return (
                // prevMissingAccount.name == o.name &&
                prevMissingAccount.mask == o.mask &&
                    prevMissingAccount.type == o.type &&
                    prevMissingAccount.subtype == o.subtype);
            });
            return Object.assign(Object.assign({}, o), { create_notification_rule: true, display_name: null, merge_with: (prevMissingAccount === null || prevMissingAccount === void 0 ? void 0 : prevMissingAccount.id)
                    ? `plaid_${prevMissingAccount === null || prevMissingAccount === void 0 ? void 0 : prevMissingAccount.id}`
                    : null, original_merge_with: (prevMissingAccount === null || prevMissingAccount === void 0 ? void 0 : prevMissingAccount.id) || null });
        }));
    }, [plaidMetadata]);
    const updateSettingsAndIndexChange = newIndex => {
        const plaidAccount = plaidAccounts[newIndex];
        if ((plaidAccount === null || plaidAccount === void 0 ? void 0 : plaidAccount.merge_with) != null) {
            setMergingAccountsSetting(true);
        }
        else if ((plaidAccount === null || plaidAccount === void 0 ? void 0 : plaidAccount.import_start_date) != null) {
            setMergingAccountsSetting(false);
        }
        else {
            setMergingAccountsSetting(null);
        }
        setIndex(newIndex);
    };
    const updatePlaidAccount = (id, updateObj) => {
        setPlaidAccounts(plaidAccounts.map(o => {
            // change to ID later
            if (o.id == id) {
                return Object.assign(Object.assign({}, o), updateObj);
            }
            return o;
        }));
    };
    return (React.createElement(semantic_ui_react_1.Modal, { open: !!showPlaidLinkConfirmation &&
            !!((_a = plaidMetadata === null || plaidMetadata === void 0 ? void 0 : plaidMetadata.newAccountsDetected) === null || _a === void 0 ? void 0 : _a.length) &&
            !!plaidPublicToken, size: "small" },
        React.createElement(semantic_ui_react_1.Modal.Header, null,
            "Setup Account ",
            index + 1,
            "/", plaidAccounts === null || plaidAccounts === void 0 ? void 0 :
            plaidAccounts.length,
            ":",
            ' ', (_b = plaidAccounts[index]) === null || _b === void 0 ? void 0 :
            _b.name,
            " (", (_c = plaidAccounts[index]) === null || _c === void 0 ? void 0 :
            _c.mask,
            ") -",
            ' ', (_d = plaidAccounts[index]) === null || _d === void 0 ? void 0 :
            _d.type,
            "/", (_e = plaidAccounts[index]) === null || _e === void 0 ? void 0 :
            _e.subtype),
        React.createElement(semantic_ui_react_1.Modal.Content, null,
            React.createElement("div", { className: "content" }, (_f = [plaidAccounts[index]]) === null || _f === void 0 ? void 0 : _f.map(account => {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                if (!account)
                    return;
                const disabled = plaidAccounts.map(o => o.merge_with);
                return (React.createElement("div", { 
                    // className="segment-container"
                    key: `plaid-account-${account === null || account === void 0 ? void 0 : account.id}` },
                    account.type == 'loan' && (React.createElement(semantic_ui_react_1.Message, { info: true }, "Transactions will not be imported for loan-type accounts (including mortgages).")),
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement("label", null,
                                    React.createElement("span", null,
                                        "Account Display Name",
                                        ' ',
                                        React.createElement(semantic_ui_react_1.Popup, { size: "tiny", inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", fitted: true, name: "question circle" }) }, "This account will be referenced by this display name throughout Lunch Money"))),
                                React.createElement(EditableString_1.default, { state: 'Editing', placeholder: `${(_a = plaidMetadata === null || plaidMetadata === void 0 ? void 0 : plaidMetadata.institution) === null || _a === void 0 ? void 0 : _a.name} ${account.name}`, location: 'modal', identifier: 'account-display-name', firstValue: account.display_name ||
                                        `${(_b = plaidMetadata === null || plaidMetadata === void 0 ? void 0 : plaidMetadata.institution) === null || _b === void 0 ? void 0 : _b.name} ${account.name}`, shouldSaveOnChange: true, onSave: name => {
                                        updatePlaidAccount(account.id, {
                                            display_name: name,
                                        });
                                    } })))),
                    ((account.type !== 'loan' &&
                        window.location.href.indexOf('beta.lunchmoney') >= 0) ||
                        (window.location.href.indexOf('beta.lunchmoney') == -1 &&
                            account.type !== 'depository' &&
                            account.type !== 'credit')) && (React.createElement("div", null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: account.create_notification_rule, onChange: (e, { checked }) => {
                                updatePlaidAccount(account.id, Object.assign({ create_notification_rule: checked }, (checked ? { skip_transactions: false } : {})));
                            }, label: 'Send me email notifications when new transactions are imported' }),
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: account.skip_transactions, onChange: (e, { checked }) => {
                                updatePlaidAccount(account.id, Object.assign({ skip_transactions: checked }, (checked
                                    ? { create_notification_rule: false }
                                    : {})));
                            }, label: 'Do not import transactions' }))),
                    React.createElement("h3", null, "Are you merging this synced account with an existing account?"),
                    React.createElement("div", null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: mergingAccountsSetting || !!(account === null || account === void 0 ? void 0 : account.merge_with), onChange: (e, { checked }) => {
                                setMergingAccountsSetting(checked ? true : null);
                                updatePlaidAccount(account.id, {
                                    import_start_date: null,
                                });
                            }, label: "Yes, I am merging this with an existing account" })),
                    (mergingAccountsSetting || !!account.merge_with) && (React.createElement("div", { className: "segment-container", key: `plaid-account-${account.id}` },
                        React.createElement(semantic_ui_react_1.Message, { success: true },
                            React.createElement("p", null, "The selected account will have all their transactions, balance history and other properties merged into this newly synced account before being removed."),
                            React.createElement("p", null,
                                "The newly synced account will have",
                                ' ',
                                React.createElement("b", null, "Import Start Date"),
                                " automatically set based on the merged account's last imported transactions.")),
                        React.createElement("div", null,
                            React.createElement(semantic_ui_react_1.Dropdown, { className: "selection category-dropdown p-category-modal", placeholder: `Select account to merge with`, fluid: true, text: _assets.getAccountName(account.merge_with), value: account.merge_with },
                                React.createElement(semantic_ui_react_1.Dropdown.Menu, null,
                                    ((_c = plaidMetadata === null || plaidMetadata === void 0 ? void 0 : plaidMetadata.prevMissingAccounts) === null || _c === void 0 ? void 0 : _c.length) > 0 && (React.createElement(semantic_ui_react_1.Dropdown.Header, { content: "Suggestions" })), (_d = plaidMetadata === null || plaidMetadata === void 0 ? void 0 : plaidMetadata.prevMissingAccounts) === null || _d === void 0 ? void 0 :
                                    _d.map(o => {
                                        return (React.createElement(semantic_ui_react_1.Dropdown.Item, { active: account.merge_with == `plaid_${o.id}`, key: `prev-${o.id}`, disabled: disabled.includes(`plaid_${o.id}`), value: `plaid_${o.id}`, onClick: () => {
                                                updatePlaidAccount(account.id, {
                                                    merge_with: `plaid_${o.id}`,
                                                });
                                            } },
                                            account.display_name ||
                                                `${account.institution_name || ''} ${account.name}`,
                                            ' ',
                                            "(",
                                            account.mask,
                                            ")"));
                                    }),
                                    ((_e = _assets.plaidAccounts) === null || _e === void 0 ? void 0 : _e.length) > 0 && (React.createElement(semantic_ui_react_1.Dropdown.Header, { content: "Synced accounts" })), (_f = _assets.plaidAccounts) === null || _f === void 0 ? void 0 :
                                    _f.sort((a, b) => {
                                        return a.institution_id - b.institution_id;
                                    }).map(o => {
                                        return (React.createElement(semantic_ui_react_1.Dropdown.Item, { active: account.merge_with == `plaid_${o.id}`, key: `plaid_${o.id}`, disabled: disabled.includes(`plaid_${o.id}`), value: `plaid_${o.id}`, onClick: () => {
                                                updatePlaidAccount(account.id, {
                                                    merge_with: `plaid_${o.id}`,
                                                });
                                            } },
                                            o.display_name ||
                                                `${o.institution_name || ''} ${o.name}`,
                                            o.mask ? ` (${o.mask})` : '',
                                            " - ",
                                            o.status));
                                    }),
                                    ((_g = _assets.assets) === null || _g === void 0 ? void 0 : _g.filter(o => !!o.id).length) > 0 && (React.createElement(semantic_ui_react_1.Dropdown.Header, { content: "Manually-managed accounts" })), (_h = _assets.assets) === null || _h === void 0 ? void 0 :
                                    _h.filter(o => !!o.id).map(o => {
                                        return (React.createElement(semantic_ui_react_1.Dropdown.Item, { active: account.merge_with == `manual_${o.id}`, key: o.id, disabled: disabled.includes(`manual_${o.id}`), value: `manual_${o.id}`, onClick: () => {
                                                updatePlaidAccount(account.id, {
                                                    merge_with: `manual_${o.id}`,
                                                });
                                            } },
                                            o.display_name ||
                                                `${o.institution_name || ''} ${o.name}`,
                                            o.mask ? ` (${o.mask})` : '',
                                            " - ",
                                            o.status));
                                    })))))),
                    React.createElement("div", null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: mergingAccountsSetting == false, onChange: (e, { checked }) => {
                                setMergingAccountsSetting(checked ? false : null);
                                updatePlaidAccount(account.id, {
                                    merge_with: null,
                                    import_start_date: null,
                                });
                            }, label: "No, this is a new account or I will merge it later" })),
                    !account.skip_transactions &&
                        mergingAccountsSetting == false &&
                        (account.type == 'depository' ||
                            account.type == 'credit' ||
                            account.type == 'investment') && (React.createElement("div", { className: "segment-container", key: `plaid-account-${account.id}` },
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, disabled: account.status == 'deleted', checked: account.status !== 'deleted' &&
                                !account.import_start_date, onChange: (e, { checked }) => {
                                if (checked) {
                                    updatePlaidAccount(account.id, {
                                        import_start_date: null,
                                    });
                                }
                                else {
                                    updatePlaidAccount(account.id, {
                                        import_start_date: Moment()
                                            .subtract(90, 'days')
                                            .format('YYYY-MM-DD'),
                                    });
                                }
                            }, label: "Import all available historical transactions (max 2 years)" }),
                        account.import_start_date !== null && (React.createElement(semantic_ui_react_1.Form, { className: "mt-05rem" },
                            React.createElement("label", null, "Import transactions starting from the following date:"),
                            React.createElement(EditableDate_1.default, { identifier: 'linked-account', format: 'month_day_year', placeholder: Moment()
                                    .subtract(90, 'days')
                                    .format(_user.getMonthDayYearFormat()), maxDate: (0, date_fns_1.parseISO)(Moment().format('YYYY-MM-DD')), firstValue: account.import_start_date, location: 'modal', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () {
                                    updatePlaidAccount(account.id, {
                                        import_start_date: date,
                                    });
                                }) })))))));
            }))),
        React.createElement(semantic_ui_react_1.Modal.Actions, null,
            index > 0 && (React.createElement(semantic_ui_react_1.Button, { disabled: isLoading, loading: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    updateSettingsAndIndexChange(index - 1);
                }), color: "orange", icon: true, labelPosition: "left" },
                React.createElement(semantic_ui_react_1.Icon, { name: "arrow left" }),
                "Previous")),
            index < (plaidAccounts === null || plaidAccounts === void 0 ? void 0 : plaidAccounts.length) - 1 ? (React.createElement(semantic_ui_react_1.Button, { disabled: isLoading ||
                    mergingAccountsSetting == null ||
                    (mergingAccountsSetting && !plaidAccounts[index].merge_with), loading: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    updateSettingsAndIndexChange(index + 1);
                }), color: "orange", icon: true, labelPosition: "right" },
                "Next",
                React.createElement(semantic_ui_react_1.Icon, { name: "arrow right" }))) : (React.createElement(semantic_ui_react_1.Button, { disabled: 
                // Same as Next
                isLoading ||
                    (mergingAccountsSetting == null &&
                        !plaidAccounts[index].merge_with) ||
                    (mergingAccountsSetting && !plaidAccounts[index].merge_with), loading: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setIsLoading(true);
                    yield init(plaidPublicToken, Object.assign(Object.assign({}, plaidMetadata), { newAccountsDetected: plaidAccounts }));
                    yield onClose(plaidMetadata);
                    setIsLoading(false);
                }), color: "green", icon: true, labelPosition: "left" },
                React.createElement(semantic_ui_react_1.Icon, { name: "check" }),
                "Start Importing ",
                mergingAccountsSetting)))));
};
exports.default = PlaidConfirmPopup;
