"use strict";
/**
 * StaticProvider
 * providers static data which may be used for logged out:
 * - list of all supported currencies
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StaticProvider = exports.StaticContext = void 0;
const React = require("react");
const react_1 = require("react");
const currencies_1 = require("@actions/currencies");
const currencies_2 = require("@actions/currencies");
const StaticContext = (0, react_1.createContext)({
    allCurrencies: [],
    allCrypto: [],
    coinGeckoMap: {},
    isReady: false,
});
exports.StaticContext = StaticContext;
const StaticProvider = props => {
    // Used for EditableCategory
    const coinGeckoMap = (0, react_1.useRef)({});
    const [allCurrencies, setAllCurrencies] = (0, react_1.useState)(null);
    const [allCrypto, setAllCrypto] = (0, react_1.useState)(null);
    // When normal flow is ready
    const [isReady, setIsReady] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        console.log('[Static Provider] Loaded.');
        fetchCurrencies();
        fetchCrypto();
        console.log('[Static Provider] Done fetching data.');
        setIsReady(true);
    }, []);
    const fetchCrypto = () => __awaiter(void 0, void 0, void 0, function* () {
        const currencies = yield (0, currencies_2.getCrypto)();
        console.log('[Static Provider] Done fetching cryptocurrencies.');
        setAllCrypto(currencies === null || currencies === void 0 ? void 0 : currencies.map(currency => {
            coinGeckoMap.current[currency.coingecko_id] = currency.currency;
            return {
                key: currency.coingecko_id,
                value: currency.coingecko_id,
                text: `${currency.currency} (${currency.full_name})`,
                full_name: currency.full_name,
            };
        }));
    });
    /** GENERAL */
    const fetchCurrencies = () => __awaiter(void 0, void 0, void 0, function* () {
        const currencies = yield (0, currencies_1.getCurrencies)();
        console.log('[Static Provider] Done fetching currencies.');
        setAllCurrencies(currencies === null || currencies === void 0 ? void 0 : currencies.sort((a, b) => {
            return a['short_name']
                .toLowerCase()
                .localeCompare(b['short_name'].toLowerCase());
        }).map(currency => {
            return {
                key: currency.short_name,
                value: currency.short_name,
                text: `${currency.full_name} (${currency.short_name.toUpperCase()})`,
            };
        }));
    });
    return (React.createElement(StaticContext.Provider, { value: {
            allCurrencies,
            allCrypto,
            coinGeckoMap: coinGeckoMap.current,
            isReady,
        } }, props.children));
};
exports.StaticProvider = StaticProvider;
