"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const react_1 = require("react");
const transactions_1 = require("@actions/transactions");
const semantic_ui_react_1 = require("semantic-ui-react");
const TransactionRow_1 = require("@components/Transactions/TransactionRow");
const Loader_1 = require("@components/global/Loader");
const ColumnResizer_1 = require("@components/elements/ColumnResizer");
const TagsProvider_1 = require("@providers/TagsProvider");
const assets_1 = require("@/actions/assets");
const categories_1 = require("@/actions/categories");
const _ = require("lodash");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const AssetsProvider_1 = require("@/providers/AssetsProvider");
const Review = ({ data, currentConfig, goBack, goNext, _showToast }) => {
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const _tags = (0, react_1.useContext)(TagsProvider_1.TagsContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const [transactions, setTransactions] = (0, react_1.useState)(null);
    const [excludedIds, setExcludedIds] = (0, react_1.useState)({});
    const [duplicates, setDuplicates] = (0, react_1.useState)(null);
    const [showDuplicates, setShowDuplicates] = (0, react_1.useState)(false);
    const [skip, setSkip] = (0, react_1.useState)(0);
    const [limit, setLimit] = (0, react_1.useState)(25);
    const [buttonIsLoading, setButtonIsLoading] = (0, react_1.useState)(false);
    const newAssets = (0, react_1.useRef)([]);
    const newCategories = (0, react_1.useRef)([]);
    const _createCategories = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const toCreate = _.pickBy(currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._categories, o => !o || o == 'new');
        if (((_a = Object.keys(toCreate)) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            const _newCategories = yield (0, categories_1.createCategories)({
                categories: Object.keys(toCreate),
            });
            _categories.fetchCategories();
            newCategories.current = _newCategories.data.results;
        }
    });
    const _createAssets = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        const toCreate = _.pickBy(currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._accounts, o => !!o && !o.id);
        if (((_b = Object.values(toCreate)) === null || _b === void 0 ? void 0 : _b.length) > 0) {
            const _newAssets = yield (0, assets_1.createAssets)({
                assets: Object.values(toCreate),
            });
            _assets.fetchAssets();
            newAssets.current = _newAssets.data;
        }
    });
    const _removeCategories = () => __awaiter(void 0, void 0, void 0, function* () {
        if (newCategories.current.length > 0) {
            yield (0, categories_1.deleteCategories)({
                categories: newCategories.current.map(o => o.id),
            });
        }
    });
    const _removeAssets = () => __awaiter(void 0, void 0, void 0, function* () {
        if (newAssets.current.length > 0) {
            yield (0, assets_1.bulkDeleteAssets)({ asset_ids: newAssets.current.map(o => o.id) });
        }
    });
    (0, react_1.useEffect)(() => {
        try {
            // When 'data' changes externally, run it through the checker
            // Send to check
            const check = () => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b, _c;
                yield _createCategories();
                // await _createTags()
                yield _createAssets();
                // Assign categories and assets
                const hydratedData = data.map(tx => {
                    var _a, _b;
                    tx.date = ((currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._year_override)
                        ? Moment(tx.original_date, currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._date_format).year(currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._year_override)
                        : Moment(tx.original_date, currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._date_format)).format('YYYY-MM-DD');
                    const configCat = currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._categories[tx.category_original];
                    if (configCat == -1) {
                        tx.category_id = null;
                    }
                    else if (configCat != null && configCat != 'new') {
                        tx.category_id = configCat;
                    }
                    else {
                        tx.category_id =
                            ((_a = newCategories.current.find(o => o.name == tx.category_original)) === null || _a === void 0 ? void 0 : _a.id) || null;
                    }
                    if (tx.tags_original) {
                        tx.tags = [];
                        const tags = tx.tags_original.split(',');
                        tags.forEach(tagName => {
                            if (!(currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._tags.hasOwnProperty(tagName)) ||
                                (currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._tags[tagName]) != -1) {
                                tx.tags.push((currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._tags[tagName]) || tagName); // Assign or create
                            }
                        });
                    }
                    if (tx.account_original) {
                        if (currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.id) {
                            // This is a saved config, don't do anything?
                        }
                        else {
                            // It could be an existing. Check config for account matches
                            const config = currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._accounts[tx.account_original];
                            if (config) {
                                if (config.id) {
                                    tx[config.source == 'plaid' ? 'plaid_account_id' : 'asset_id'] = config.id;
                                }
                                else {
                                    tx.asset_id =
                                        ((_b = newAssets.current.find(o => o.name == config.name &&
                                            o.institution_name == config.institution_name &&
                                            o.type_name == config.type_name &&
                                            o.subtype_name == config.subtype_name)) === null || _b === void 0 ? void 0 : _b.id) || null;
                                }
                            }
                        }
                    }
                    if (currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._amount_flip) {
                        tx.amount = tx.amount * -1;
                    }
                    if (tx.asset_id == -1) {
                        tx.asset_id = null;
                    }
                    return tx;
                });
                const allPromises = [];
                for (let i = 0; i < hydratedData.length; i += 1000) {
                    const batch = hydratedData.slice(i, i + 1000);
                    allPromises.push((0, transactions_1.checkBulkInsertTransactions)({
                        transactions: batch,
                        apply_rules: currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._apply_category_rules,
                        skip_duplicates: currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._skip_duplicates,
                        create_new_categories: true,
                    }));
                }
                const promiseResults = yield Promise.allSettled(allPromises);
                const results = promiseResults.reduce((acc, cur) => {
                    var _a, _b;
                    if (cur.status == 'fulfilled') {
                        const latest = {};
                        latest['errors'] = cur.value.error
                            ? acc.errors.concat(cur.value.error)
                            : acc.errors;
                        latest['transactions'] = acc.transactions.concat((_a = cur.value.data) === null || _a === void 0 ? void 0 : _a.transactions);
                        latest['duplicates'] = acc.duplicates.concat((_b = cur.value.data) === null || _b === void 0 ? void 0 : _b.duplicates);
                        return latest;
                    }
                    else {
                        _showToast({
                            type: 'error',
                            message: 'We ran into an issue checking some of your transactions. To try again, hit Back and then Next.',
                        });
                        return acc;
                    }
                }, { errors: [], transactions: [], duplicates: [] });
                // const results = await checkBulkInsertTransactions({
                //   transactions: hydratedData,
                //   apply_rules: currentConfig?._apply_category_rules,
                //   skip_duplicates: currentConfig?._skip_duplicates,
                //   create_new_categories: true,
                // })
                yield _tags.fetchTags();
                if (((_a = results.errors) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    _showToast({
                        type: 'error',
                        message: 'We ran into errors parsing some of your transactions. Check the console for more details and contact support@lunchmoney.app for further assistance.',
                    });
                    console.log(results.errors);
                }
                else {
                    setTransactions((_b = results.transactions) === null || _b === void 0 ? void 0 : _b.map((o, index) => {
                        return Object.assign(Object.assign({}, o), { id: index + 1, to_import: true });
                    }));
                    setDuplicates((_c = results.duplicates) === null || _c === void 0 ? void 0 : _c.map((o, index) => {
                        return Object.assign(Object.assign({}, o), { id: index + 1, to_import: false });
                    }));
                }
            });
            check();
        }
        catch (e) {
            console.log('Error checking transactions', e);
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(semantic_ui_react_1.Message, { info: true },
            React.createElement(semantic_ui_react_1.Message.Header, null, "Step 4: Review Transactions"),
            React.createElement("p", null, "These transactions are exactly how they will be inserted into Lunch Money. If applicable, they have already been deduped and rules have been applied."),
            ' ',
            React.createElement("p", null, "To prevent some transactions from being imported, de-select them.")),
        React.createElement(semantic_ui_react_1.Table, { fixed: true, unstackable: true, selectable: true, celled: true, className: "p-transactions-table" },
            React.createElement(semantic_ui_react_1.Table.Header, { className: "sticky" },
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-toggle" },
                        React.createElement("div", null, "Import")),
                    React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'importing', prevCol: 'import', nextCol: 'date' }),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-date" },
                        React.createElement("div", null, "Date")),
                    React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'importing', prevCol: 'date', nextCol: 'category' }),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-category" },
                        React.createElement("div", null, "Category")),
                    React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'importing', prevCol: 'category', nextCol: 'payee' }),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-payee" },
                        React.createElement("div", null, "Payee")),
                    React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'importing', prevCol: 'payee', nextCol: 'amount' }),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-amount number" },
                        React.createElement("div", null, "Amount")),
                    React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'importing', prevCol: 'amount', nextCol: 'notes' }),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-notes" },
                        React.createElement("div", null, "Notes")),
                    React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'importing', prevCol: 'import', nextCol: 'date' }),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Accounts"),
                    React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'importing', prevCol: 'import', nextCol: 'date' }),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-tags" },
                        React.createElement("div", null, "Tags")))),
            React.createElement(semantic_ui_react_1.Table.Body, null, (transactions === null || transactions === void 0 ? void 0 : transactions.length) > 0 ? (transactions === null || transactions === void 0 ? void 0 : transactions.slice(skip, skip + limit).map((tx, index) => {
                if (tx.split && tx.split.length > 0) {
                    const splits = [];
                    tx.split.forEach((split, splitIndex) => {
                        splits.push(React.createElement(TransactionRow_1.default, { key: `transaction-${index}-split-${splitIndex}}`, 
                            // saving={false}
                            persistentHighlight: false, onSave: (id, update) => {
                                // Find all the ids associated with the parent id of this id
                                const parent_id = id.split('_')[0];
                                if (update.to_import) {
                                    const ids = Object.assign({}, excludedIds);
                                    delete ids[parent_id];
                                    // Set to_import to true
                                    setTransactions(transactions.map(tx => {
                                        if (tx.id === parseInt(parent_id)) {
                                            return Object.assign(Object.assign({}, tx), { split: tx.split.map(split => {
                                                    return Object.assign(Object.assign({}, split), { to_import: true });
                                                }) });
                                        }
                                        else {
                                            return tx;
                                        }
                                    }));
                                    setExcludedIds(ids);
                                }
                                else {
                                    // Set to_import to false
                                    setTransactions(transactions.map(tx => {
                                        if (tx.id === parseInt(parent_id)) {
                                            return Object.assign(Object.assign({}, tx), { split: tx.split.map(split => {
                                                    return Object.assign(Object.assign({}, split), { to_import: false });
                                                }) });
                                        }
                                        else {
                                            return tx;
                                        }
                                    }));
                                    setExcludedIds(Object.assign(Object.assign({}, excludedIds), { [parent_id]: true }));
                                }
                            }, onOpenModal: () => { }, dismissRecurring: () => { }, transaction: Object.assign(Object.assign({ to_import: true }, split), { id: `${tx.id}_${splitIndex}`, parent_id: tx.id }), preventModal: true, showReviewed: false, showCheckbox: true, showBulkSelect: false, readOnly: true, showYear: true, hideCategory: false, hideTags: false }));
                    });
                    return splits;
                }
                else {
                    return (React.createElement(TransactionRow_1.default, { key: `transaction-${index}}`, 
                        // saving={false}
                        persistentHighlight: false, onSave: (id, update) => {
                            if (update.to_import) {
                                const ids = Object.assign({}, excludedIds);
                                delete ids[id];
                                setExcludedIds(ids);
                            }
                            else {
                                setExcludedIds(Object.assign(Object.assign({}, excludedIds), { [id]: true }));
                            }
                        }, onOpenModal: () => { }, dismissRecurring: () => { }, transaction: Object.assign(Object.assign({}, tx), { to_import: !excludedIds[tx.id] }), preventModal: true, showReviewed: false, showCheckbox: true, showBulkSelect: false, readOnly: true, showYear: true, hideCategory: false, hideTags: false }));
                }
            })) : (transactions === null || transactions === void 0 ? void 0 : transactions.length) == 0 ? (React.createElement(semantic_ui_react_1.Table.Row, { className: "padded-row" },
                React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: 15, className: "center-align" }, "No data to insert"))) : (React.createElement(Loader_1.default, { text: 'Parsing transactions.. (this could take more than a few seconds if you have a lot!)', colSpan: "15" })))),
        (transactions === null || transactions === void 0 ? void 0 : transactions.length) > limit && (React.createElement("div", { className: "flex--end" },
            React.createElement(semantic_ui_react_1.Pagination, { activePage: Math.floor(skip / limit) + 1, onPageChange: (e, { activePage }) => {
                    setSkip((activePage - 1) * limit);
                }, totalPages: Math.ceil(transactions.length / limit) }))),
        (duplicates === null || duplicates === void 0 ? void 0 : duplicates.length) > 0 && (React.createElement(semantic_ui_react_1.Message, { warning: true, className: showDuplicates ? '' : 'clickable' },
            React.createElement(semantic_ui_react_1.Message.Header, null,
                "We found ",
                duplicates.length,
                " duplicate transactions in your CSV.",
                ' ',
                (currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._skip_duplicates)
                    ? "Your current configuration is set to skip duplicates. To include duplicates, go Back and deselect 'Skip Duplicates'"
                    : ''),
            React.createElement(semantic_ui_react_1.Message.Content, null,
                React.createElement(semantic_ui_react_1.Button, { className: "mt-1rem", onClick: () => {
                        setShowDuplicates(!showDuplicates);
                    }, size: "mini" },
                    showDuplicates ? 'Hide' : 'View',
                    " duplicates")))),
        (duplicates === null || duplicates === void 0 ? void 0 : duplicates.length) > 0 && showDuplicates && (React.createElement(semantic_ui_react_1.Table, { fixed: true, unstackable: true, selectable: true, celled: true, className: "p-transactions-table" },
            React.createElement(semantic_ui_react_1.Table.Header, null,
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-date" },
                        React.createElement("div", null, "Date")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-category" },
                        React.createElement("div", null, "Category")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-payee" },
                        React.createElement("div", null, "Payee")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-amount number" },
                        React.createElement("div", null, "Amount")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-notes" },
                        React.createElement("div", null, "Notes")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Accounts"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-tags" },
                        React.createElement("div", null, "Tags")))),
            React.createElement(semantic_ui_react_1.Table.Body, null, duplicates === null || duplicates === void 0 ? void 0 : duplicates.map((tx, index) => {
                return (React.createElement(TransactionRow_1.default, { key: `transaction-duplicate-${index}}`, persistentHighlight: false, onOpenModal: () => { }, dismissRecurring: () => { }, transaction: tx, preventModal: true, showReviewed: false, showBulkSelect: false, readOnly: true, hideCategory: false, hideTags: false, hiddenCols: ['account'] }));
            })))),
        React.createElement("div", { className: "flex--space-between mt-1rem" },
            React.createElement(semantic_ui_react_1.Button, { loading: buttonIsLoading, content: "Back", icon: "left arrow", labelPosition: "left", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setButtonIsLoading(true);
                    yield _removeCategories();
                    yield _removeAssets();
                    goBack();
                }) }),
            (transactions === null || transactions === void 0 ? void 0 : transactions.length) > 0 && (React.createElement(semantic_ui_react_1.Button, { loading: buttonIsLoading, disabled: buttonIsLoading &&
                    transactions.length - Object.keys(excludedIds).length == 0, content: `Insert Transactions (${(transactions === null || transactions === void 0 ? void 0 : transactions.reduce((acc, cur) => {
                    var _a;
                    return acc + (((_a = cur === null || cur === void 0 ? void 0 : cur.split) === null || _a === void 0 ? void 0 : _a.length) || 1);
                }, 0)) - Object.keys(excludedIds).length})`, icon: "right arrow", labelPosition: "right", onClick: () => {
                    setButtonIsLoading(true);
                    goNext([
                        ...transactions.filter(o => {
                            return !excludedIds[o.id];
                        }),
                    ]);
                } })))));
};
exports.default = Review;
