"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertRecurringStatuses = exports.migrateSuggestedCategories = exports.populatePlaidCategories = exports.postLogs = exports.logPlaidErrors = void 0;
const common_1 = require("./common");
/**
 * User
 */
const logPlaidErrors = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`utils/log/plaid`, body);
});
exports.logPlaidErrors = logPlaidErrors;
const postLogs = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`utils/log`, body);
});
exports.postLogs = postLogs;
const populatePlaidCategories = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`utils/populate_plaid_category`, {});
});
exports.populatePlaidCategories = populatePlaidCategories;
const migrateSuggestedCategories = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`utils/migrate_suggested_categories`, {});
});
exports.migrateSuggestedCategories = migrateSuggestedCategories;
const convertRecurringStatuses = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`utils/convert_recurring_statuses`, {});
});
exports.convertRecurringStatuses = convertRecurringStatuses;
