"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_beautiful_dnd_1 = require("react-beautiful-dnd");
const semantic_ui_react_1 = require("semantic-ui-react");
const EditableString_1 = require("@components/elements/EditableString");
const CategoryRow = ({ category, update, draggableIndex, dragDescriptor, 
// showArchived,
onOpenModal = o => { }, setCollapsed = o => { }, isCollapsed = false, numChildren = 0, openTable, isSaving = false, }) => {
    return (React.createElement(react_beautiful_dnd_1.Draggable, { key: `row-${category.id}`, draggableId: `draggable-row-${category.id}`, index: draggableIndex }, (provided, snapshot) => (React.createElement(semantic_ui_react_1.Ref, { innerRef: provided.innerRef },
        React.createElement(semantic_ui_react_1.Table.Row, Object.assign({ className: `clickable ${snapshot.isDragging ? 'dragged-row padded-row' : ''}` }, provided.draggableProps), snapshot.isDragging ? (React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: 10 },
            React.createElement("span", { style: { position: 'relative', top: '-2px' } },
                React.createElement("b", null,
                    category.is_group ? React.createElement(semantic_ui_react_1.Icon, { name: "table" }) : React.createElement(React.Fragment, null),
                    category.name,
                    category.group_id
                        ? ` (${category.group_category_name})`
                        : ''),
                dragDescriptor ? `: ${dragDescriptor}` : ''))) : (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Table.Cell, { className: `table-cell-name editable` },
                React.createElement("div", { className: category.is_group || category.group_id || isSaving
                        ? `category-row-has-icon`
                        : '' },
                    isSaving && (React.createElement(semantic_ui_react_1.Icon, { name: "spinner", loading: true, fitted: true, style: { position: 'relative', top: '1px' } })),
                    !isSaving && category.group_id && (React.createElement("span", { className: "hierarchy-line-icon" })),
                    !isSaving && category.is_group && (React.createElement(semantic_ui_react_1.Popup, { mouseEnterDelay: 750, inverted: true, size: "mini", trigger: React.createElement(semantic_ui_react_1.Icon, { style: { margin: '0' }, name: isCollapsed ? 'caret right' : 'table', onClick: () => {
                                setCollapsed(category.id);
                            } }) }, "Click to collapse this category group.")),
                    !isSaving && isCollapsed && !!numChildren && (React.createElement("span", { className: "pr-05rem" },
                        "(",
                        numChildren,
                        ")")),
                    React.createElement(EditableString_1.default, { identifier: `category-${category.id}`, location: 'inline', firstValue: category.name, autoFocus: true, shouldSaveOnBlur: true, shouldSaveOnEnter: true, shouldSelectOnFocus: true, emptyText: 'Add a name', onSave: name => {
                            if (name !== category.name) {
                                update(category.id, { name });
                            }
                        } }))),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: `table-cell-description editable hover-toggle` },
                React.createElement(EditableString_1.default, { identifier: `category-description-${category.id}`, firstValue: category.description, autoFocus: true, blankIfNull: true, location: 'inline', emptyText: 'Add a description', shouldSaveOnBlur: true, shouldSaveOnEnter: true, shouldSelectOnFocus: true, onSave: description => {
                        if (description !== category.description) {
                            update(category.id, { description });
                        }
                    } })),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            category.group_id ? (React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: 5, onClick: () => {
                    onOpenModal(category);
                } },
                React.createElement("span", { className: "inherited" }, "Inherited from group"))) : (React.createElement(React.Fragment, null,
                React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align table-cell-matches", onClick: () => {
                        onOpenModal(category);
                    } }, category.is_income && React.createElement(semantic_ui_react_1.Icon, { name: "checkmark" })),
                React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
                React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align table-cell-matches", onClick: () => {
                        onOpenModal(category);
                    } }, category.exclude_from_budget && (React.createElement(semantic_ui_react_1.Icon, { name: "checkmark" }))),
                React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
                React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align table-cell-matches", onClick: () => {
                        onOpenModal(category);
                    } }, category.exclude_from_totals && (React.createElement(semantic_ui_react_1.Icon, { name: "checkmark" }))))),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align clickable", onClick: () => {
                    openTable(category);
                } },
                React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "search plus", className: "color--grey clickable dark-grey-on-hover" }), size: "tiny", content: "View transactions (pop-up)", inverted: true })),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, Object.assign({}, provided.dragHandleProps, { className: "table-cell-settings center-align" }), !category.archived && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement("span", { className: "reorder-icon" }), inverted: true, size: "mini", content: "Reorder Category" }))),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align", onClick: () => {
                    onOpenModal(category);
                } },
                React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "angle right", className: `clickable green-on-hover color--grey`, onClick: () => {
                        onOpenModal(category);
                    } })))))))));
};
exports.default = CategoryRow;
