"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const EditableTags_1 = require("@components/elements/EditableTags");
const EditableString_1 = require("@components/elements/EditableString");
const EditableCategories_1 = require("@components/elements/EditableCategories");
const EditableAsset_1 = require("@components/elements/EditableAsset");
const options_1 = require("@helpers/options");
const QueryToolProvider_1 = require("@providers/QueryToolProvider");
const DataSetForm = ({ hideDelete = false, index, removeDataSet }) => {
    const [showName, setShowName] = (0, react_1.useState)(false);
    const [showCategoryId, setShowCategoryId] = (0, react_1.useState)(false);
    const [showNote, setShowNote] = (0, react_1.useState)(false);
    const [showAsset, setShowAsset] = (0, react_1.useState)(false);
    const [showTags, setShowTags] = (0, react_1.useState)(false);
    const [showExcludeTags, setShowExcludeTags] = (0, react_1.useState)(false);
    // Filters
    const [datasetName, setDatasetName] = (0, react_1.useState)(`Dataset ${index + 1}`);
    const [isManualDatasetName, setIsManualDatasetName] = (0, react_1.useState)(false);
    const [filterName, setFilterName] = (0, react_1.useState)(null);
    const [filterNameMatchType, setFilterNameMatchType] = (0, react_1.useState)(null);
    const [filterNotes, setFilterNotes] = (0, react_1.useState)(null);
    const [filterNotesMatchType, setFilterNotesMatchType] = (0, react_1.useState)(null);
    const [filterTags, setFilterTags] = (0, react_1.useState)(null);
    const [excludeTags, setExcludeTags] = (0, react_1.useState)(null);
    const [filterCategoryIds, setFilterCategoryIds] = (0, react_1.useState)(null);
    const [filterAccount, setFilterAccount] = (0, react_1.useState)(null);
    const [filterAsset, setFilterAsset] = (0, react_1.useState)(null);
    const [excludeUnreviewed, setExcludeUnreviewed] = (0, react_1.useState)(false);
    const [excludeIncome, setExcludeIncome] = (0, react_1.useState)(false);
    const [_queryTool] = (0, react_1.useContext)(QueryToolProvider_1.QueryToolContext);
    // Should have a remove?
    const updateDataSet = obj => {
        _queryTool.allDataSets.current = Object.assign(Object.assign({}, _queryTool.allDataSets.current), { [index]: Object.assign(Object.assign({}, _queryTool.allDataSets.current[index]), obj) });
    };
    const removeDataKeys = keys => {
        keys.forEach(key => {
            delete _queryTool.allDataSets.current[index][key];
        });
        _queryTool.allDataSets.current = Object.assign(Object.assign({}, _queryTool.allDataSets.current), { [index]: _queryTool.allDataSets.current[index] });
    };
    (0, react_1.useEffect)(() => {
        if (_queryTool.allDataSets.current.hasOwnProperty(index)) {
            // First undo everything
            setShowName(false);
            setShowCategoryId(false);
            setShowNote(false);
            setShowAsset(false);
            setShowTags(false);
            setShowExcludeTags(false);
            setExcludeUnreviewed(false);
            setExcludeIncome(false);
            setFilterName(null);
            setFilterNameMatchType(null);
            setFilterNotes(null);
            setFilterNotesMatchType(null);
            setFilterAsset(null);
            setFilterAccount(null);
            setFilterTags(null);
            setExcludeTags(null);
            setFilterCategoryIds(null);
            const config = _queryTool.allDataSets.current[index];
            let overrideDatasetName = true;
            if (config.dataset_name) {
                setDatasetName(config.dataset_name);
                overrideDatasetName = false;
            }
            if (config.show_name == 'true') {
                setShowName(true);
            }
            if (config.payee_match_type) {
                setFilterNameMatchType(config.payee_match_type);
            }
            if (config.payee) {
                setFilterName(config.payee);
                if (overrideDatasetName) {
                    setDatasetName(config.payee);
                }
            }
            if (config.show_category_id == 'true') {
                setShowCategoryId(true);
            }
            if (config.category_ids) {
                setFilterCategoryIds(config.category_ids);
            }
            if (config.show_notes == 'true') {
                setShowNote(true);
            }
            if (config.notes_match_type) {
                setFilterNotesMatchType(config.notes_match_type);
            }
            if (config.notes) {
                setFilterNotes(config.notes);
            }
            if (config.show_assets == 'true') {
                setShowAsset(true);
            }
            if (config.asset_id) {
                setFilterAsset(parseInt(config.asset_id));
            }
            if (config.plaid_account_id) {
                setFilterAccount(parseInt(config.plaid_account_id));
            }
            if (config.show_tags == 'true') {
                setShowTags(true);
            }
            if (config.tags) {
                setFilterTags(config.tags);
            }
            if (config.show_exclude_tags == 'true') {
                setShowExcludeTags(true);
            }
            if (config.tags_exclude) {
                setExcludeTags(config.tags_exclude);
            }
            if (config.exclude_unreviewed == 'true') {
                setExcludeUnreviewed(true);
            }
            if (config.exclude_income == 'true') {
                setExcludeIncome(true);
            }
            setIsManualDatasetName(!overrideDatasetName);
        }
    }, []);
    return (React.createElement(semantic_ui_react_1.Card, { fluid: true },
        React.createElement(semantic_ui_react_1.Card.Content, null,
            React.createElement(semantic_ui_react_1.Form, { className: `mb-0` },
                !hideDelete && (React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-1rem" },
                    React.createElement("span", { className: "line-color" }),
                    React.createElement(EditableString_1.default, { className: "ml-05rem", identifier: `filter-name`, placeholder: "Name this dataset", firstValue: datasetName, location: 'modal', state: 'Editing', shouldSaveOnChange: true, onSave: (name) => __awaiter(void 0, void 0, void 0, function* () {
                            setDatasetName(name);
                            setIsManualDatasetName(true);
                            updateDataSet({ dataset_name: name });
                        }) }),
                    React.createElement(semantic_ui_react_1.Button, { icon: true, basic: true, type: "button", color: "red", onClick: () => {
                            removeDataSet();
                            delete _queryTool.allDataSets.current[index];
                        }, className: "no-border" },
                        React.createElement(semantic_ui_react_1.Icon, { name: 'trash alternate outline' })))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-0" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: showName, onChange: (e, { checked }) => {
                                setShowName(checked);
                                if (!checked) {
                                    removeDataKeys(['show_name', 'payee_match_type', 'payee']);
                                }
                                else {
                                    updateDataSet({ show_name: true });
                                }
                            }, label: "Filter by payee" }),
                        showName && (React.createElement(semantic_ui_react_1.Form.Group, { className: "mt-05rem" },
                            React.createElement(semantic_ui_react_1.Form.Field, { className: "width-100" },
                                React.createElement(semantic_ui_react_1.Dropdown, { className: "p-match-inline", inline: true, options: options_1.STRING_MATCH_TYPES, defaultValue: filterNameMatchType || 'contain', onChange: (e, { value }) => {
                                        setFilterNameMatchType(value);
                                        updateDataSet({ payee_match_type: value });
                                    } }),
                                React.createElement(EditableString_1.default, { identifier: `filter-name`, placeholder: "Starbucks", firstValue: filterName, location: 'modal', state: 'Editing', autosuggest: true, shouldSaveOnChange: true, onSave: (name) => __awaiter(void 0, void 0, void 0, function* () {
                                        setFilterName(name);
                                        if (!isManualDatasetName) {
                                            // If user hasn't manually overridden the data set
                                            // name, make it match the name of payee field
                                            setDatasetName(name);
                                            updateDataSet({ dataset_name: name });
                                        }
                                        if (filterNameMatchType == null) {
                                            // Set default as 'contain'
                                            updateDataSet({
                                                payee_match_type: 'contain',
                                                payee: name,
                                            });
                                        }
                                        else {
                                            updateDataSet({ payee: name });
                                        }
                                    }) })))))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-0" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: showCategoryId, onChange: (e, { checked }) => {
                                setShowCategoryId(checked);
                                if (!checked) {
                                    removeDataKeys(['show_category_id', 'category_ids']);
                                }
                                else {
                                    updateDataSet({ show_category_id: true });
                                }
                            }, label: "Filter by categories" }),
                        showCategoryId && (React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mt-05rem" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(EditableCategories_1.default, { initialValue: filterCategoryIds, showUncategorized: true, placeholder: "Select categories", onChange: value => {
                                        setFilterCategoryIds(value);
                                        updateDataSet({ category_ids: value });
                                    } })))))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-0" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: showNote, onChange: (e, { checked }) => {
                                setShowNote(checked);
                                if (!checked) {
                                    removeDataKeys(['show_notes', 'notes_match_type', 'notes']);
                                }
                                else {
                                    updateDataSet({ show_notes: true });
                                }
                            }, label: "Filter by notes" }),
                        showNote && (React.createElement(semantic_ui_react_1.Form.Group, { className: "mt-05rem" },
                            React.createElement(semantic_ui_react_1.Form.Field, { className: "width-100" },
                                React.createElement(semantic_ui_react_1.Dropdown, { className: "p-match-inline", inline: true, options: options_1.STRING_MATCH_TYPES, defaultValue: filterNotesMatchType || 'contain', onChange: (e, { value }) => {
                                        setFilterNotesMatchType(value);
                                        updateDataSet({ notes_match_type: value });
                                    } }),
                                React.createElement(EditableString_1.default, { identifier: `filter-notes`, placeholder: '', firstValue: filterNotes, location: 'modal', state: 'Editing', shouldSaveOnChange: true, onSave: (notes) => __awaiter(void 0, void 0, void 0, function* () {
                                        setFilterNotes(notes);
                                        if (filterNotesMatchType == null) {
                                            // Set default as 'contain'
                                            updateDataSet({
                                                notes_match_type: 'contain',
                                                notes,
                                            });
                                        }
                                        else {
                                            updateDataSet({ notes });
                                        }
                                    }) })))))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-0" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: showAsset, onChange: (e, { checked }) => {
                                setShowAsset(checked);
                                if (!checked) {
                                    removeDataKeys([
                                        'show_assets',
                                        'asset_id',
                                        'plaid_account_id',
                                    ]);
                                }
                                else {
                                    updateDataSet({ show_assets: true });
                                }
                            }, label: "Filter by account" }),
                        showAsset && (React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mt-05rem mb-0" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(EditableAsset_1.default, { assignableOnly: false, className: "mb-1rem", defaultSelection: Object.assign(Object.assign({}, (filterAsset
                                        ? filterAsset > 0
                                            ? { source: 'manual', id: filterAsset }
                                            : { source: null, id: -1 }
                                        : {})), (filterAccount
                                        ? { source: 'plaid', id: filterAccount }
                                        : {})), onSave: value => {
                                        if (value.id === -1) {
                                            setFilterAsset(-1);
                                            setFilterAccount(null);
                                            removeDataKeys(['plaid_account_id']);
                                            updateDataSet({
                                                show_assets: true,
                                                asset_id: -1,
                                            });
                                        }
                                        else if (value.source == 'plaid') {
                                            setFilterAccount(value.id);
                                            setFilterAsset(null);
                                            removeDataKeys(['asset_id']);
                                            updateDataSet({
                                                show_assets: true,
                                                plaid_account_id: value.id,
                                            });
                                        }
                                        else {
                                            setFilterAccount(null);
                                            setFilterAsset(value.id);
                                            removeDataKeys(['plaid_account_id']);
                                            updateDataSet({
                                                show_assets: true,
                                                asset_id: value.id,
                                            });
                                        }
                                    } })))))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-0" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: showTags, onChange: (e, { checked }) => {
                                setShowTags(checked);
                                if (!checked) {
                                    removeDataKeys(['show_tags', 'tags']);
                                }
                                else {
                                    updateDataSet({ show_tags: true });
                                }
                            }, label: "Filter by tags" }),
                        showTags && (React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mt-05rem mb-0" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(EditableTags_1.default, { allowAdd: false, className: "mb-1rem", showUntagged: true, placeholder: "Select tags", initialValue: filterTags || [], onChange: value => {
                                        setFilterTags(value);
                                        updateDataSet({ tags: value });
                                    }, hideArchived: false })))))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-0" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: showExcludeTags, onChange: (e, { checked }) => {
                                setShowExcludeTags(checked);
                                if (!checked) {
                                    removeDataKeys(['show_exclude_tags', 'tags']);
                                }
                                else {
                                    updateDataSet({ show_exclude_tags: true });
                                }
                            }, label: "Exclude tags" }),
                        showExcludeTags && (React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mt-05rem mb-0" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(EditableTags_1.default, { allowAdd: false, className: "mb-1rem", showUntagged: true, placeholder: "Select tags", initialValue: excludeTags || [], onChange: value => {
                                        setExcludeTags(value);
                                        updateDataSet({ tags_exclude: value });
                                    }, hideArchived: false })))))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-0" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: excludeUnreviewed, onChange: (e, { checked }) => {
                                setExcludeUnreviewed(checked);
                                updateDataSet({ exclude_unreviewed: checked });
                            }, label: "Exclude unreviewed" }))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-0" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: excludeIncome, onChange: (e, { checked }) => {
                                setExcludeIncome(checked);
                                updateDataSet({ exclude_income: checked });
                            }, label: "Exclude income" })))))));
};
exports.default = DataSetForm;
