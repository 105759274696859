"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const transactions_1 = require("@actions/transactions");
const Moment = require("moment");
const format_1 = require("@helpers/format");
const EditableDate_1 = require("@components/elements/EditableDate");
const EditableString_1 = require("@components/elements/EditableString");
const EditableCategory_1 = require("@components/elements/EditableCategory");
const EditablePrice_1 = require("@components/elements/EditablePrice");
const EditableTags_1 = require("@components/elements/EditableTags");
const EditableAsset_1 = require("@components/elements/EditableAsset");
const UserProvider_1 = require("@providers/UserProvider");
const QuickAdd = ({ _process, _showToast }) => {
    var _a;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [hidden, setHidden] = (0, react_1.useState)(((_a = _user.settings['quick_add_hidden_cols']) === null || _a === void 0 ? void 0 : _a.split(',')) || []);
    const [showCustomize, setShowCustomize] = (0, react_1.useState)(false);
    const [transaction, setTransaction] = (0, react_1.useState)({
        date: localStorage.getItem('_lm_quick_add_last_date') ||
            Moment().format('YYYY-MM-DD'),
        category_id: null,
        payee: '',
        amount: null,
        currency: localStorage.getItem('_lm_quick_add_last_currency') ||
            _user.primaryCurrency,
        notes: '',
        tags: null,
        asset_id: localStorage.getItem('_lm_quick_add_last_asset_id') ||
            _user.settings['default_asset_id'] ||
            null,
        plaid_account_id: localStorage.getItem('_lm_quick_add_last_plaid_account_id') || null,
    });
    const OPTIONAL = ['category', 'notes', 'tags', 'paid from'];
    const submit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        const results = yield _process(transactions_1.createTransaction)(transaction, {
            should_convert: false,
        });
        if (results.error) {
        }
        else {
            localStorage.setItem('_lm_quick_add_last_date', transaction.date);
            localStorage.setItem('_lm_quick_add_last_currency', transaction.currency);
            if (transaction.asset_id) {
                localStorage.setItem('_lm_quick_add_last_asset_id', transaction.asset_id);
                localStorage.removeItem('_lm_quick_add_last_plaid_account_id');
            }
            if (transaction.plaid_account_id) {
                localStorage.setItem('_lm_quick_add_last_plaid_account_id', transaction.plaid_account_id);
                localStorage.removeItem('_lm_quick_add_last_asset_id');
            }
            if (!transaction.asset_id && !transaction.plaid_account_id) {
                localStorage.removeItem('_lm_quick_add_last_asset_id');
                localStorage.removeItem('_lm_quick_add_last_plaid_account_id');
            }
            _showToast({
                message: 'Successfully created transaction',
                type: 'success',
            });
            setTransaction({
                date: localStorage.getItem('_lm_quick_add_last_date') ||
                    Moment().format('YYYY-MM-DD'),
                category_id: null,
                payee: '',
                amount: null,
                currency: localStorage.getItem('_lm_quick_add_last_currency') ||
                    _user.primaryCurrency,
                notes: '',
                tags: null,
                asset_id: localStorage.getItem('_lm_quick_add_last_asset_id') ||
                    _user.settings['default_asset_id'] ||
                    null,
                plaid_account_id: localStorage.getItem('_lm_quick_add_last_plaid_account_id') || null,
            });
            (_b = document.getElementById('transaction-payee')) === null || _b === void 0 ? void 0 : _b.focus();
        }
    });
    return (React.createElement(semantic_ui_react_1.Container, null,
        React.createElement("div", { id: "quick-add-container" },
            React.createElement("div", { className: "transaction-details" },
                React.createElement("h3", { className: "flex--space-between" }, "Quick Add Transaction"),
                React.createElement("div", { className: "transaction-detail mb-1rem" },
                    React.createElement("label", { className: "form-label" },
                        "Date ",
                        React.createElement("span", { className: "color--red" }, "*")),
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(EditableDate_1.default, { identifier: `transaction-date`, firstValue: transaction.date, location: 'modal', state: 'Editing', format: 'month_day_year', onSave: date => {
                                setTransaction(Object.assign(Object.assign({}, transaction), { date: date }));
                            } }))),
                (!hidden || (hidden === null || hidden === void 0 ? void 0 : hidden.indexOf('category')) == -1) && (React.createElement("div", { className: "transaction-detail mb-1rem" },
                    React.createElement("label", { className: "form-label" }, "Category"),
                    React.createElement(semantic_ui_react_1.Form, { widths: "equal" },
                        React.createElement(EditableCategory_1.default, { identifier: `transaction-category`, firstValue: transaction.category_id, location: 'modal', state: 'Editing', onSave: category_id => {
                                setTransaction(Object.assign(Object.assign({}, transaction), { category_id: category_id }));
                            } })))),
                React.createElement("div", { className: "transaction-detail mb-1rem" },
                    React.createElement("label", { className: "form-label" },
                        "Payee Name ",
                        React.createElement("span", { className: "color--red" }, "*")),
                    React.createElement(semantic_ui_react_1.Form, { widths: "equal" },
                        React.createElement(EditableString_1.default, { identifier: `transaction-payee`, firstValue: transaction.payee, location: 'modal', autoFocus: true, autosuggest: true, state: 'Editing', shouldSaveOnChange: true, onSave: payee => {
                                setTransaction(Object.assign(Object.assign({}, transaction), { payee: payee }));
                            } }))),
                React.createElement("div", { className: "transaction-detail mb-1rem" },
                    React.createElement("label", { className: "form-label" },
                        "Amount ",
                        React.createElement("span", { className: "color--red" }, "*")),
                    React.createElement(semantic_ui_react_1.Form, { widths: "equal" },
                        React.createElement(EditablePrice_1.default, { identifier: `transaction-amount`, amount: transaction.amount, showSignLabel: true, currency: transaction.currency, location: 'modal', state: 'Editing', saveOnChange: true, onSave: (amount, currency) => {
                                setTransaction(Object.assign(Object.assign({}, transaction), { amount,
                                    currency }));
                            } }))),
                (!hidden || (hidden === null || hidden === void 0 ? void 0 : hidden.indexOf('notes')) == -1) && (React.createElement("div", { className: "transaction-detail mb-1rem" },
                    React.createElement("label", { className: "form-label" }, "Notes"),
                    React.createElement(semantic_ui_react_1.Form, { widths: "equal" },
                        React.createElement(EditableString_1.default, { identifier: `transaction-notes`, firstValue: transaction.notes, location: 'modal', state: 'Editing', shouldSaveOnChange: true, onSave: value => {
                                setTransaction(Object.assign(Object.assign({}, transaction), { notes: value }));
                            } })))),
                (!hidden || (hidden === null || hidden === void 0 ? void 0 : hidden.indexOf('tags')) == -1) && (React.createElement("div", { className: "transaction-detail mb-1rem" },
                    React.createElement("label", { className: "form-label" }, "Transaction tag(s)"),
                    React.createElement(semantic_ui_react_1.Form, { widths: "equal" },
                        React.createElement(EditableTags_1.default, { onChange: value => {
                                setTransaction(Object.assign(Object.assign({}, transaction), { tags: value }));
                            }, initialValue: [] })))),
                (!hidden || (hidden === null || hidden === void 0 ? void 0 : hidden.indexOf('paid from')) == -1) && (React.createElement("div", { className: "transaction-detail mb-1rem" },
                    React.createElement("label", { className: "form-label" }, "Paid from"),
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(EditableAsset_1.default, { defaultSelection: {
                                source: transaction.plaid_account_id
                                    ? 'plaid'
                                    : transaction.asset_id
                                        ? 'manual'
                                        : null,
                                id: transaction.plaid_account_id ||
                                    transaction.asset_id ||
                                    -1,
                            }, onSave: obj => {
                                setTransaction(Object.assign(Object.assign({}, transaction), { [obj.source == 'plaid' ? 'plaid_account_id' : 'asset_id']: obj.id > -1 ? obj.id : null, [obj.source == 'plaid'
                                        ? 'asset_id'
                                        : 'plaid_account_id']: null }));
                            } }))))),
            React.createElement(semantic_ui_react_1.Button, { disabled: !transaction.payee || !transaction.amount || !transaction.date, className: "mt-1rem", fluid: true, onClick: submit }, "Add transaction"),
            React.createElement("div", { onClick: () => {
                    setShowCustomize(true);
                }, className: "clickable width-100 mt-1rem form-label flex--center center-align" },
                React.createElement(semantic_ui_react_1.Icon, { name: "cog", className: "mr-05rem" }),
                React.createElement("span", null, "Customize"))),
        React.createElement(semantic_ui_react_1.Modal, { size: "tiny", open: showCustomize, dimmer: 'inverted' },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Customize Quick Add"),
            React.createElement(semantic_ui_react_1.Modal.Content, null, OPTIONAL.map(field => {
                return (React.createElement("div", { key: `field-${field}` },
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: (hidden === null || hidden === void 0 ? void 0 : hidden.indexOf(field)) == -1, onChange: (e, { checked }) => {
                            if (checked) {
                                // Remove from _hiddenCols
                                const set = new Set(hidden);
                                set.delete(field);
                                setHidden([...set]);
                                _user.updateSetting('quick_add_hidden_cols', [...set].join(','));
                            }
                            else {
                                // Add to _hiddenCols
                                const set = new Set(hidden);
                                set.add(field);
                                setHidden([...set]);
                                _user.updateSetting('quick_add_hidden_cols', [...set].join(','));
                            }
                        }, label: `Show ${(0, format_1.capitalize)(field)}` })));
            })),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        setShowCustomize(false);
                    } }, "Close")))));
};
exports.default = QuickAdd;
