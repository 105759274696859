"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const tooltips2_1 = require("@helpers/tooltips2");
const _ = require("lodash");
const Moment = require("moment");
const react_1 = require("react");
const format_1 = require("@helpers/format");
const react_chartjs_2_1 = require("react-chartjs-2");
const UserProvider_1 = require("@providers/UserProvider");
const QueryLoader_1 = require("@components/global/QueryLoader");
const charts_1 = require("@helpers/charts");
const chart_js_1 = require("chart.js");
const NetWorthChart = ({ hiddenAccounts, hiddenTypes, balanceHistory, listOfAccounts, percentView, hideAccountBreakdown, chartBreakdown, }) => {
    const [timeArray, setTimeArray] = (0, react_1.useState)([]);
    const [chartRef, setChartRef] = (0, react_1.useState)(null);
    const [data, setData] = (0, react_1.useState)(null);
    const [datasets, setDatasets] = (0, react_1.useState)([]);
    const [assetTypes, setAssetTypes] = (0, react_1.useState)([]);
    const [liabilityTypes, setLiabilityTypes] = (0, react_1.useState)([]);
    const [hasHiddenTypes, setHasHiddenTypes] = (0, react_1.useState)(false);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    chart_js_1.Chart.register(...chart_js_1.registerables);
    const ASSET_COLORS = [
        '#6BC1EF',
        '#1172A6',
        '#B6E0F7',
        '#0E98BE',
        '#11B5E4',
        '#0A3F5C',
    ];
    const LIABILITY_COLORS = [
        '#FB9DA4',
        '#E8081B',
        '#FEE3E5',
        '#F95664',
        '#FDC4C9',
        '#D30819',
    ];
    (0, react_1.useEffect)(() => {
        var _a;
        const hideTooltip = e => {
            document.getElementById('net-worth-tool-tip').style.display = 'none';
        };
        (_a = document
            .getElementById('chart-container')) === null || _a === void 0 ? void 0 : _a.addEventListener('mouseleave', hideTooltip);
        return () => {
            var _a;
            (_a = document
                .getElementById('chart-container')) === null || _a === void 0 ? void 0 : _a.removeEventListener('mouseleave', hideTooltip);
        };
    }, []);
    (0, react_1.useEffect)(() => {
        if (balanceHistory) {
            setData(balanceHistory);
            setTimeArray(balanceHistory.map(o => o.label));
        }
    }, [balanceHistory]);
    (0, react_1.useEffect)(() => {
        if (data) {
            const _datasets = [];
            const netWorthLine = {
                type: 'line',
                label: 'Net Worth',
                borderColor: '#A9DD2F', //E05951
                lineTension: 0,
                pointHoverBorderWidth: 2,
                pointHoverBackgroundColor: '#A9DD2F',
                pointHoverRadius: 5,
                pointHoverBorderColor: '#fff',
                pointRadius: 0,
                fill: false,
                data: data === null || data === void 0 ? void 0 : data.map(o => o.net_worth),
            };
            if (chartBreakdown == 'type') {
                // in By Type, Net Worth Line should be on top of bars
                if (hiddenAccounts['net-worth'] !== true) {
                    _datasets.push(netWorthLine);
                }
                if (hideAccountBreakdown) {
                    _datasets.push({
                        type: 'bar',
                        label: 'Assets',
                        maxBarThickness: 20,
                        backgroundColor: '#0E98BE',
                        hoverBackgroundColor: '#1172A6',
                        stack: 'assets',
                        data: data.map(o => o.assets.total),
                    });
                    _datasets.push({
                        type: 'bar',
                        label: 'Liabilities',
                        maxBarThickness: 20,
                        backgroundColor: '#ff737e',
                        hoverBackgroundColor: '#f95d6a',
                        stack: 'liabilities',
                        data: data.map(o => o.liabilities.total),
                    });
                }
                else {
                    let _hasHiddenTypes = false;
                    // Collect all asset types
                    const _assetTypes = [];
                    data.forEach(datum => {
                        const types = Object.keys(datum.assets.by_type);
                        types.forEach(type => {
                            if (hiddenTypes[type] !== true) {
                                _assetTypes.push(type);
                            }
                            else {
                                _hasHiddenTypes = true;
                            }
                        });
                    });
                    _.uniq(_assetTypes).forEach((type, index) => {
                        _datasets.push({
                            type: 'bar',
                            label: (0, format_1.capitalize)(type),
                            maxBarThickness: 20,
                            // borderColor: '#0A3F5C',
                            // borderWidth: 1,
                            backgroundColor: ASSET_COLORS[index % ASSET_COLORS.length], //E05951
                            hoverBackgroundColor: ASSET_COLORS[index % ASSET_COLORS.length],
                            stack: 'asset',
                            data: data.map(o => o.assets.by_type.hasOwnProperty(type)
                                ? o.assets.by_type[type].total
                                : 0),
                        });
                    });
                    setAssetTypes(_.uniq(_assetTypes)); // To preserve order in tooltips
                    // Collect all liability types
                    const _liabilityTypes = [];
                    data.forEach(datum => {
                        const types = Object.keys(datum.liabilities.by_type);
                        types.forEach(type => {
                            if (hiddenTypes[type] !== true) {
                                _liabilityTypes.push(type);
                            }
                            else {
                                _hasHiddenTypes = true;
                            }
                        });
                    });
                    _.uniq(_liabilityTypes).forEach((type, index) => {
                        _datasets.push({
                            type: 'bar',
                            label: (0, format_1.capitalize)(type),
                            maxBarThickness: 20,
                            // borderColor: '#D30819',
                            // borderWidth: 1,
                            backgroundColor: LIABILITY_COLORS[index % LIABILITY_COLORS.length], //E05951
                            hoverBackgroundColor: LIABILITY_COLORS[index % LIABILITY_COLORS.length],
                            stack: 'liability',
                            data: data.map(o => o.liabilities.by_type.hasOwnProperty(type)
                                ? o.liabilities.by_type[type].total
                                : 0),
                        });
                    });
                    setLiabilityTypes(_.uniq(_liabilityTypes)); // To preserve order in tooltips
                    setHasHiddenTypes(_hasHiddenTypes);
                }
            }
            else {
                _.flatten(Object.values(listOfAccounts === null || listOfAccounts === void 0 ? void 0 : listOfAccounts.assets).concat(Object.values(listOfAccounts === null || listOfAccounts === void 0 ? void 0 : listOfAccounts.liabilities)))
                    .filter(o => {
                    return hiddenAccounts[o._id] !== true;
                })
                    .forEach((account, index) => {
                    const colors = account.is_asset ? ASSET_COLORS : LIABILITY_COLORS;
                    _datasets.push({
                        type: 'line',
                        label: account.name,
                        borderColor: colors[index % colors.length],
                        lineTension: 0,
                        pointHoverBorderWidth: 2,
                        pointHoverBackgroundColor: colors[index % colors.length],
                        pointHoverRadius: 5,
                        pointHoverBorderColor: '#fff',
                        pointRadius: 0,
                        fill: false,
                        data: data === null || data === void 0 ? void 0 : data.map(o => {
                            if (o[account.is_asset ? 'assets' : 'liabilities'].by_account.hasOwnProperty(account._id)) {
                                return o[account.is_asset ? 'assets' : 'liabilities']
                                    .by_account[account._id].to_base;
                            }
                            else {
                                return null;
                            }
                        }),
                    });
                });
                // In By Account, net worth line shoold be at the end to not fuck with indices
                if (hiddenAccounts['net-worth'] !== true) {
                    _datasets.push(netWorthLine);
                }
            }
            setDatasets(_datasets);
        }
    }, [data, hiddenAccounts, hiddenTypes, hideAccountBreakdown, chartBreakdown]);
    return (React.createElement("div", { id: "net-worth-chart" },
        React.createElement("div", { id: "chart-container" },
            data ? (React.createElement(react_chartjs_2_1.Bar, { key: `chart-${chartBreakdown}`, ref: reference => {
                    setChartRef(reference);
                }, data: {
                    labels: data === null || data === void 0 ? void 0 : data.map(o => o.label),
                    datasets: datasets.map(d => {
                        // TODO: use this instead of filtering
                        return Object.assign(Object.assign({}, d), { hidden: false });
                    }),
                }, options: {
                    plugins: {
                        title: {
                            display: false,
                        },
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            mode: chartBreakdown == 'account' ? 'nearest' : 'index',
                            intersect: false,
                            enabled: false,
                            external: function (context) {
                                const tooltip = context.tooltip;
                                return (0, tooltips2_1.getNetWorthTooltip)(context.chart, chartRef, tooltip, tooltip.title &&
                                    data.find(o => o.label == tooltip.title[0]), tooltip.title &&
                                    data.find(o => o.label ==
                                        Moment(tooltip.title[0], 'MMM YYYY')
                                            .subtract(1, 'month')
                                            .format('MMM YYYY')), _user.primaryCurrency, [
                                    {
                                        header: 'assets',
                                        subtypes: assetTypes,
                                        colors: [
                                            '#6BC1EF',
                                            '#1172A6',
                                            '#B6E0F7',
                                            '#0E98BE',
                                            '#11B5E4',
                                            '#0A3F5C',
                                        ],
                                    },
                                    {
                                        header: 'liabilities',
                                        subtypes: liabilityTypes,
                                        colors: [
                                            '#FB9DA4',
                                            '#E8081B',
                                            '#FEE3E5',
                                            '#F95664',
                                            '#FDC4C9',
                                            '#D30819',
                                        ],
                                        shouldReverseColors: true,
                                    },
                                ], _.flatten(Object.values(listOfAccounts === null || listOfAccounts === void 0 ? void 0 : listOfAccounts.assets).concat(Object.values(listOfAccounts === null || listOfAccounts === void 0 ? void 0 : listOfAccounts.liabilities))).filter(o => {
                                    return hiddenAccounts[o._id] !== true;
                                }), {
                                    hasHiddenTypes,
                                    chartBreakdown,
                                    percentView,
                                    hideAccountBreakdown,
                                }, _user.getMonthYearFormat());
                            },
                        },
                    },
                    hover: {
                        mode: chartBreakdown == 'account' ? 'nearest' : 'index',
                        intersect: false,
                    },
                    scales: {
                        x: {
                            grid: (0, charts_1.getGridLineColors)(),
                            ticks: Object.assign(Object.assign({}, (0, charts_1.getTickColors)()), { callback: function (value, index, values) {
                                    return Moment(timeArray[value], 'MMM YYYY').format(_user.getMonthYearFormat());
                                } }),
                        },
                        y: {
                            beginAtZero: true,
                            grid: (0, charts_1.getGridLineColors)(),
                            ticks: Object.assign(Object.assign({}, (0, charts_1.getTickColors)()), { 
                                // Include a dollar sign in the ticks
                                callback: function (value, index, values) {
                                    return (0, format_1.toPrice)(value, _user.primaryCurrency);
                                } }),
                        },
                    },
                } })) : (React.createElement(QueryLoader_1.default, { noBorder: true })),
            React.createElement("div", { id: "net-worth-tool-tip" }))));
};
exports.default = NetWorthChart;
