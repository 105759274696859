"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const Moment = require("moment");
const TaskCard_1 = require("@components/TaskCard/TaskCard");
const BudgetTaskCard = ({ budgetStatus, hideQuestionMark = false, onHover = () => { }, onBlur = () => { }, }) => {
    const history = (0, react_router_dom_1.useHistory)();
    const [taskDone, setTaskDone] = (0, react_1.useState)(false);
    const [title, setTitle] = (0, react_1.useState)(null);
    const [subtitle, setSubtitle] = (0, react_1.useState)(null);
    const [thisMonth, setThisMonth] = (0, react_1.useState)(true);
    (0, react_1.useEffect)(() => {
        if (!!budgetStatus) {
            const numberOfDaysLeft = Moment()
                .add(1, 'months')
                .date(0)
                .diff(Moment(), 'days');
            if (Object.keys(budgetStatus).length === 0 ||
                (budgetStatus === null || budgetStatus === void 0 ? void 0 : budgetStatus.this_month) > 0) {
                setTaskDone(false);
                setTitle(`Set ${Moment().format('MMMM')} budget`);
                setSubtitle(`There is no budget set for ${budgetStatus.this_month || 'all'} ${parseInt(budgetStatus.this_month) === 1 ? 'category' : 'categories'}`);
                setThisMonth(true);
            }
            else if (numberOfDaysLeft > 10) {
                // Focus and prompt this month's budget
                setTaskDone(true);
                setTitle(`Set ${Moment().format('MMMM')} budget`);
                setSubtitle(`You're set for this month– nice one!`);
                setThisMonth(true);
            }
            else if (numberOfDaysLeft <= 10 && numberOfDaysLeft > 2) {
                // Focus/prompt on next month's budget
                setTaskDone(true);
                setTitle(`Set ${Moment().format('MMMM')} budget`);
                setSubtitle(`Start thinking about ${Moment()
                    .add(1, 'month')
                    .format('MMMM')} budget ${Moment().to(Moment()
                    .add(1, 'months')
                    .date(0)
                    .subtract(2, 'days'))}.`);
                setThisMonth(false);
            }
            else {
                // Focus/prompt on next month's budget
                setTaskDone((budgetStatus === null || budgetStatus === void 0 ? void 0 : budgetStatus.next_month) === 0);
                setTitle(`Set ${Moment()
                    .add(1, 'month')
                    .format('MMMM')} budget now`);
                setSubtitle((budgetStatus === null || budgetStatus === void 0 ? void 0 : budgetStatus.next_month) === 0 ? `You're all set!` : `Don't delay!`);
                setThisMonth(false);
            }
        }
    }, [budgetStatus]);
    return (React.createElement(TaskCard_1.default, { isLoading: !budgetStatus || typeof budgetStatus === 'undefined', type: "budget", className: taskDone ? 'task-done' : '', onClick: () => {
            if (thisMonth) {
                history.push(`/budget/${Moment().format('YYYY/MM')}`);
            }
            else {
                history.push(`/budget/${Moment()
                    .add(1, 'month')
                    .format('YYYY/MM')}`);
            }
        }, title: title || `Set ${Moment().format('MMMM')} budget`, subtitle: subtitle, onHover: onHover, onBlur: onBlur, hideQuestionMark: true }));
};
exports.default = BudgetTaskCard;
