"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const UserProvider_1 = require("@providers/UserProvider");
const semantic_ui_react_1 = require("semantic-ui-react");
const locales_1 = require("@helpers/locales");
const Moment = require("moment");
const format_1 = require("@/helpers/format");
const UserPreferences = ({ _showToast }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [showDateFormat, setShowDateFormat] = (0, react_1.useState)(false);
    const update = (setting, value) => __awaiter(void 0, void 0, void 0, function* () {
        _user.updateSetting(setting, value);
        _showToast({ message: 'Successfully saved setting', type: 'success' });
    });
    return (React.createElement(semantic_ui_react_1.Form, null,
        React.createElement(semantic_ui_react_1.Card, null,
            React.createElement(semantic_ui_react_1.Card.Content, { header: "User Preferences" }),
            React.createElement(semantic_ui_react_1.Card.Content, null,
                React.createElement(semantic_ui_react_1.Message, { info: true }, "These preferences are per-user and will persist across all budgets."),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                                setShowDateFormat(true);
                            }, icon: true, labelPosition: "left" },
                            React.createElement(semantic_ui_react_1.Icon, { name: "calendar alternate outline" }),
                            "Set Date Format Preferences"))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null, "General Preferences"),
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: _user.settings['auto_suggest_payee'], label: "Show auto-suggest for payee", onChange: (e_1, _a) => __awaiter(void 0, [e_1, _a], void 0, function* (e, { checked }) {
                                yield update('auto_suggest_payee', checked);
                            }) }),
                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }) }, "Check this if you would like to see a list of suggestions when filling out the payee name. Suggestions are populated from a list of existing transaction payee names."))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: _user.settings['allow_recurring_categories'], label: "Allow categories for recurring items", onChange: (e_2, _b) => __awaiter(void 0, [e_2, _b], void 0, function* (e, { checked }) {
                                yield update('allow_recurring_categories', checked);
                            }) }),
                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }) }, "Check this if you would like to be able to set categories for recurring transactions. This will then make recurring transactions part of your budget."))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null, "View Preferences"),
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: _user.settings['show_debits_as_negative'], label: "Show debits as negative, credits as positive", onChange: (e_3, _c) => __awaiter(void 0, [e_3, _c], void 0, function* (e, { checked }) {
                                yield update('show_debits_as_negative', checked);
                            }) }),
                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }) }, "Check this if you would like credits (i.e. income, refunds) to show up as positive and debits (i.e. purchases) to show up as negative."))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: _user.settings['link_months_between_views'], label: "Align months between views", onChange: (e_4, _d) => __awaiter(void 0, [e_4, _d], void 0, function* (e, { checked }) {
                                yield update('link_months_between_views', checked);
                            }) }),
                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }) }, "Check this if you want to synchronize the months between Recurring, Budget, and Transaction view"))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: _user.settings['align_months_expanded'], label: "Align transaction month with budget month", onChange: (e_5, _e) => __awaiter(void 0, [e_5, _e], void 0, function* (e, { checked }) {
                                yield update('align_months_expanded', checked);
                            }) }),
                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }) }, "In the Expanded budget view, check this if you want the transactions to default to the Budget view's month instead of last month."))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null, "Rule Preferences"),
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: _user.settings['auto_create_category_rules'], label: "Auto-create rule when updating category", onChange: (e_6, _f) => __awaiter(void 0, [e_6, _f], void 0, function* (e, { checked }) {
                                yield update('auto_create_category_rules', checked);
                            }) }),
                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }) }, "Check this if you would like to have a rule created automatically when you update a transaction's category. Every time that a transaction with the exact same name comes in, it will be auto-categorized. You can always edit these in the Rules page."))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: _user.settings['auto_create_suggested_transaction_rules'], label: "Auto-create suggested rule when updating payee", onChange: (e_7, _g) => __awaiter(void 0, [e_7, _g], void 0, function* (e, { checked }) {
                                yield update('auto_create_suggested_transaction_rules', checked);
                            }) }),
                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }) }, "Check this if you would like to have a suggested rule created automatically when you update a transaction's payee. You can review these by clicking the \"View Suggested Rules\" button (if you have any) in the Rules page."))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null, "Transaction Preferences"),
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: _user.settings['auto_review_transaction_on_update'], label: "Auto-review transactions on update", onChange: (e_8, _h) => __awaiter(void 0, [e_8, _h], void 0, function* (e, { checked }) {
                                yield update('auto_review_transaction_on_update', checked);
                            }) }),
                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }) }, "Check this if you would like to auto-review transactions on date, category, payee, amount, account or notes update. Disable this to manually review transactions."))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: _user.settings['auto_review_transaction_on_creation'], label: "Auto-review transactions on manual creation", onChange: (e_9, _j) => __awaiter(void 0, [e_9, _j], void 0, function* (e, { checked }) {
                                yield update('auto_review_transaction_on_creation', checked);
                            }) }),
                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }) }, "Check this if you would like new manual transactions to start in a reviewed state. Disable this to start them in an unreviewed state."))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: _user.settings['include_pending_in_totals'], label: "Include pending transactions in totals", onChange: (e_10, _k) => __awaiter(void 0, [e_10, _k], void 0, function* (e, { checked }) {
                                yield update('include_pending_in_totals', checked);
                            }) }),
                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }) }, "Check this if you would like pending transactions to be included in calculated totals, budgets, reports and recurring items, as if they were posted."))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null, "Dark mode preference"),
                        React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal settings-dropdown", placeholder: "Select view", selection: true, fluid: true, value: _user.settings['dark_mode_setting'] || 'browser', onChange: (e_11, _l) => __awaiter(void 0, [e_11, _l], void 0, function* (e, { value }) {
                                if (value == 'always') {
                                    document.body.classList.remove('no-dark');
                                    document.body.classList.add('dark');
                                    document.body.classList.add('dark-mode');
                                    localStorage.setItem('_lm_dark_mode_setting', 'dark');
                                }
                                else if (value == 'never') {
                                    document.body.classList.remove('dark');
                                    document.body.classList.remove('dark-mode');
                                    document.body.classList.add('no-dark');
                                    localStorage.setItem('_lm_dark_mode_setting', 'never');
                                }
                                else if (value == 'browser') {
                                    document.body.classList.remove('no-dark');
                                    localStorage.removeItem('_lm_dark_mode_setting');
                                    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                                        document.body.classList.add('dark');
                                        document.body.classList.add('dark-mode');
                                    }
                                    else {
                                        document.body.classList.remove('dark');
                                        document.body.classList.remove('dark-mode');
                                    }
                                }
                                yield update('dark_mode_setting', value);
                            }), options: [
                                {
                                    text: 'According to system settings',
                                    key: 'browser',
                                    value: 'browser',
                                },
                                {
                                    text: 'Light mode all the time',
                                    key: 'never',
                                    value: 'never',
                                },
                                {
                                    text: 'Dark mode all the time',
                                    key: 'always',
                                    value: 'always',
                                },
                            ] }))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null, "Finances Default View"),
                        React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal settings-dropdown", placeholder: "Select view", selection: true, fluid: true, value: _user.settings['finances_default_view'] || 'budget', onChange: (e_12, _m) => __awaiter(void 0, [e_12, _m], void 0, function* (e, { value }) {
                                yield update('finances_default_view', value);
                            }), options: [
                                {
                                    text: 'Budget',
                                    key: 'Budget',
                                    value: 'budget',
                                },
                                {
                                    text: 'Transactions',
                                    key: 'Transactions',
                                    value: 'transactions',
                                },
                                {
                                    text: 'Recurring',
                                    key: 'Recurring',
                                    value: 'recurring',
                                },
                                {
                                    text: 'Analyze',
                                    key: 'Analyze',
                                    value: 'analyze',
                                },
                                // {
                                //   text: 'Calendar',
                                //   key: 'Calendar',
                                //   value: 'calendar',
                                // },
                            ] })),
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null, "Setup Default View"),
                        React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal settings-dropdown", placeholder: "Select view", selection: true, fluid: true, value: _user.settings['setup_default_view'] || 'accounts', onChange: (e_13, _o) => __awaiter(void 0, [e_13, _o], void 0, function* (e, { value }) {
                                yield update('setup_default_view', value);
                            }), options: [
                                {
                                    text: 'Accounts',
                                    key: 'Accounts',
                                    value: 'accounts',
                                },
                                {
                                    text: 'Categories',
                                    key: 'Categories',
                                    value: 'categories',
                                },
                                {
                                    text: 'Tags',
                                    key: 'Tags',
                                    value: 'tags',
                                },
                                {
                                    text: 'Rules',
                                    key: 'Rules',
                                    value: 'rules',
                                },
                            ] }))))),
        React.createElement(semantic_ui_react_1.Modal, { closeOnDimmerClick: true, closeOnEscape: true, open: showDateFormat, dimmer: 'inverted', size: 'tiny' },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Set date format preferences"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", null, "Calendar week starts on"),
                            React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal settings-dropdown", placeholder: "", selection: true, fluid: true, value: _user.settings['week_starts_on'], onChange: (e, { value }) => {
                                    update('week_starts_on', value);
                                }, options: ['sunday', 'monday'].map(day => {
                                    return {
                                        key: day,
                                        value: day,
                                        text: (0, format_1.capitalize)(day),
                                    };
                                }) }))),
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", null, "Time format"),
                            React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal settings-dropdown", placeholder: "", selection: true, fluid: true, search: true, value: _user.settings['show_am_pm'] ? 'AM/PM' : '24-hour', onChange: (e, { value }) => {
                                    update('show_am_pm', value == 'AM/PM');
                                }, options: ['24-hour', 'AM/PM'].map(pref => {
                                    return {
                                        key: pref,
                                        value: pref,
                                        text: pref,
                                    };
                                }) }))),
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", { className: "flex--start" },
                                "Month and Year",
                                ' ',
                                React.createElement(semantic_ui_react_1.Popup, { size: "small", inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }) },
                                    React.createElement("p", null, "Typically displayed in charts and reports."))),
                            React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal settings-dropdown", placeholder: "Select display format", selection: true, fluid: true, search: true, value: _user.settings['month_year_format'], onChange: (e, { value }) => {
                                    update('month_year_format', value);
                                }, options: locales_1.MONTH_YEAR_FORMATS.map(format => {
                                    return {
                                        key: format,
                                        value: format,
                                        text: `${format} (ex: ${Moment('2022-01-31').format(format)} and ${Moment('2022-12-01').format(format)})`,
                                    };
                                }) }))),
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", { className: "flex--start" }, "Month, Day and Year"),
                            React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal settings-dropdown", placeholder: "Select display format", selection: true, fluid: true, search: true, value: _user.settings['month_day_year_format'], onChange: (e, { value }) => {
                                    update('month_day_year_format', value);
                                }, options: locales_1.MONTH_DAY_YEAR_FORMATS.map(format => {
                                    return {
                                        key: format,
                                        value: format,
                                        text: `${format} (ex: ${Moment('2022-01-31').format(format)} and ${Moment('2022-12-01').format(format)})`,
                                    };
                                }) }))),
                    _user.settings['always_display_year'] ? (React.createElement(React.Fragment, null)) : (React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", { className: "flex--start" },
                                "Month and Day",
                                React.createElement(semantic_ui_react_1.Popup, { size: "small", inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }) },
                                    React.createElement("p", null, "Typically displayed where the year is obvious. Opt to either always display the year or display the weekday below."))),
                            React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal settings-dropdown", placeholder: "Select display format", selection: true, fluid: true, search: true, value: _user.settings['month_day_format'], onChange: (e, { value }) => {
                                    update('month_day_format', value);
                                }, options: locales_1.MONTH_DAY_FORMATS.map(format => {
                                    return {
                                        key: format,
                                        value: format,
                                        text: `${format} (ex: ${Moment('2022-01-31').format(format)} and ${Moment('2022-12-01').format(format)})`,
                                    };
                                }) })))),
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: _user.settings['always_display_year'], label: "Always display the year", onChange: (e_14, _p) => __awaiter(void 0, [e_14, _p], void 0, function* (e, { checked }) {
                                    if (checked) {
                                        _user.updateSetting('always_display_weekday', false);
                                    }
                                    yield update('always_display_year', checked);
                                }) }),
                            React.createElement(semantic_ui_react_1.Popup, { size: "mini", inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }) },
                                React.createElement("p", null, "Always display the year in all date formats. By default, the monthly transactions pages only show the month and day.")))),
                    _user.settings['always_display_year'] ? (React.createElement(React.Fragment, null)) : (React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: _user.settings['always_display_weekday'], label: "Show weekday where useful", onChange: (e_15, _q) => __awaiter(void 0, [e_15, _q], void 0, function* (e, { checked }) {
                                    yield update('always_display_weekday', checked);
                                }) }),
                            React.createElement(semantic_ui_react_1.Popup, { size: "mini", inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem", color: "grey" }) },
                                React.createElement("p", null, "Choose to also display the day of the week in instances where the year is obvious and we display only the month and day."),
                                React.createElement("p", null, "Note: if you choose to always display the year, we will never show the weekday."))))))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement("div", { className: "flex--space-between" },
                    React.createElement("p", { className: "mb-0 monospace" }, "Changes are saved automatically."),
                    React.createElement(semantic_ui_react_1.Button, { color: "yellow", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setShowDateFormat(false);
                        }) }, "Close"))))));
};
exports.default = UserPreferences;
