"use strict";
/**
 *  Transactions.tsx
 *  Container for the Transactions page
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const react_1 = require("react");
const qs = require("query-string");
const AuthProvider_1 = require("@providers/AuthProvider");
// Semantic UI
const semantic_ui_react_1 = require("semantic-ui-react");
const Login = ({}) => {
    const _auth = (0, react_1.useContext)(AuthProvider_1.AuthContext);
    const location = (0, react_router_dom_1.useLocation)();
    const history = (0, react_router_dom_1.useHistory)();
    const query = qs.parse(location.search);
    const [email, setEmail] = (0, react_1.useState)(query.email || '');
    const [password, setPassword] = (0, react_1.useState)('');
    const [error, setError] = (0, react_1.useState)(null);
    const [notice, setNotice] = (0, react_1.useState)(null);
    const [loginLoading, setLoginLoading] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        // Redirect if user is already logged in
        if (_auth.isLoggedIn) {
            if (query['redirect'] && query['redirect'].indexOf('https') == -1) {
                history.push(decodeURIComponent(query['redirect']));
            }
            else {
                history.push('/overview');
            }
        }
        // Page title
        document.title = `Login - Lunch Money`;
    }, []);
    const prepareLogin = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoginLoading(true);
        const query = qs.parse(location.search);
        try {
            const results = yield _auth.userLogin(email, password);
            if (results.request_2fa) {
                // Need 2FA
                setError(null);
                setNotice('2-factor authentication enabled. Redirecting you now, please wait..');
                setTimeout(() => {
                    if (query['redirect']) {
                        return (window.location.href = `/auth/2fa?method=${results.method}&redirect=${query['redirect']}`);
                    }
                    else {
                        return (window.location.href = `/auth/2fa?method=${results.method}`);
                    }
                }, 1000);
            }
            else if (results.id && results.email) {
                if (query['redirect'] && query['redirect'].indexOf('https') == -1) {
                    history.push(decodeURIComponent(query['redirect']));
                }
                else {
                    history.push('/overview');
                }
            }
            else {
                // Failure!
                setNotice(null);
                setError('Log in failed. Please try again!');
                setLoginLoading(false);
            }
        }
        catch (e) {
            setNotice(null);
            setError('Log in failed. Please try again!');
            setLoginLoading(false);
        }
    });
    return (React.createElement("div", { className: "g-logged-out" },
        React.createElement("div", { className: "g-logged-out-container" },
            React.createElement("div", { className: `logged-out-content` },
                React.createElement(semantic_ui_react_1.Container, null,
                    React.createElement("div", { id: "logo-container" },
                        React.createElement("div", { id: "logo" }),
                        React.createElement("div", { id: "shadow" })),
                    React.createElement(semantic_ui_react_1.Header, { as: "h1", className: "center-align" }, "Welcome back!"),
                    error && React.createElement(semantic_ui_react_1.Message, { negative: true }, error),
                    notice && React.createElement(semantic_ui_react_1.Message, { info: true }, notice),
                    React.createElement(semantic_ui_react_1.Form, { className: "logged-out-form" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(semantic_ui_react_1.Form.Input, { autoFocus: true, value: email, onChange: (e, { value }) => {
                                    setEmail(value);
                                }, fluid: true, type: "email", label: "Email Address" })),
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(semantic_ui_react_1.Form.Input, { value: password, onChange: (e, { value }) => {
                                    setPassword(value);
                                }, fluid: true, type: "password", label: "Password" })),
                        React.createElement("div", { className: "forgot" },
                            React.createElement("a", { href: "#", onClick: () => {
                                    history.push('/reset');
                                } }, "Forgot password?")),
                        React.createElement(semantic_ui_react_1.Button, { type: "submit", onClick: prepareLogin, loading: loginLoading, disabled: !(email && password) }, "Login")),
                    React.createElement("p", { className: "register" },
                        "Not registered yet? ",
                        React.createElement(react_router_dom_1.Link, { to: "/register" }, "Create an account.")))))));
};
exports.default = Login;
