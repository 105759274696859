"use strict";
/**
 *  Card.tsx
 *  Container for the Cards page
 */
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
// Semantic UI
const semantic_ui_react_1 = require("semantic-ui-react");
class CardElement extends React.Component {
    render() {
        const rows = this.props.rows &&
            this.props.rows
                .map((row, index) => {
                if (!row)
                    return null;
                row.display = row.hasOwnProperty('display') ? row.display : true;
                return (React.createElement("div", { className: `card-content-wrapper ${row.display ? '' : 'display--none'}`, key: `card-${index}` },
                    React.createElement("div", { className: "card-content no-wrap" },
                        React.createElement("span", { className: "card-text ellipsis" },
                            row.title,
                            row.secondary && (React.createElement("span", { className: "secondary" }, row.secondary))),
                        React.createElement("span", { className: "card-number" }, row.number))));
            })
                .filter(x => x);
        const footerRows = this.props.footer &&
            this.props.footer
                .map((footer, index) => {
                if (!footer)
                    return null;
                return (React.createElement("div", { className: "card-content-wrapper footer", key: `footer-${index}` },
                    React.createElement("div", { className: "card-content no-wrap" },
                        React.createElement("span", { className: "card-text ellipsis" }, footer.title),
                        React.createElement("span", { className: "card-number" }, footer.number))));
            })
                .filter(x => x);
        return (React.createElement(semantic_ui_react_1.Card, { className: this.props.className || '' },
            this.props.header && (React.createElement(semantic_ui_react_1.Card.Content, null,
                React.createElement(semantic_ui_react_1.Card.Header, null,
                    this.props.header,
                    ' ',
                    this.props.headerTooltip && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }), position: "right center" }, this.props.headerTooltip))))),
            this.props.isLoading && (React.createElement(semantic_ui_react_1.Card.Content, null,
                React.createElement(semantic_ui_react_1.Loader, { active: true, size: "tiny", inline: "centered" }))),
            !this.props.isLoading && this.props.content && (React.createElement(semantic_ui_react_1.Card.Content, null, this.props.content)),
            !this.props.isLoading && rows && rows.length > 0 && (React.createElement(semantic_ui_react_1.Card.Content, null, rows)),
            !this.props.isLoading && rows && rows.length == 0 && (React.createElement(semantic_ui_react_1.Card.Content, null, this.props.emptyMessage)),
            !this.props.isLoading &&
                this.props.footer &&
                footerRows &&
                footerRows.length > 0 && (React.createElement(semantic_ui_react_1.Card.Content, { footer: "Footer" }, footerRows)),
            this.props.extra));
    }
}
exports.default = CardElement;
