"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const Moment = require("moment");
const auth_1 = require("@actions/auth");
const semantic_ui_react_1 = require("semantic-ui-react");
const UserProvider_1 = require("@/providers/UserProvider");
const BackupCodes = ({ process }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [codes, setCodes] = (0, react_1.useState)(null);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [timestamp, setTimestamp] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        const getTimestamp = () => __awaiter(void 0, void 0, void 0, function* () {
            const results = yield (0, auth_1.getLastGeneratedTime)();
            if (results) {
                setTimestamp(`${Moment(results).fromNow()} on ${Moment(results).format(_user.getFullTimeFormat())}`);
            }
        });
        getTimestamp();
    }, []);
    const generate = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const results = yield process(auth_1.generateBackupCodes)();
        if (!results.error) {
            setCodes(results.data);
        }
        setIsLoading(false);
    });
    return (React.createElement(semantic_ui_react_1.Card, { fluid: true },
        React.createElement(semantic_ui_react_1.Card.Content, { header: "Backup codes" }),
        !codes && (React.createElement(semantic_ui_react_1.Card.Content, null,
            timestamp ? (React.createElement(semantic_ui_react_1.Message, { info: true },
                React.createElement("p", null,
                    React.createElement(semantic_ui_react_1.Icon, { name: "exclamation triangle" }),
                    " If you generate new codes, the previous set of backup codes will be invalid."),
                React.createElement("p", null,
                    "Last generated ",
                    timestamp))) : (React.createElement(semantic_ui_react_1.Message, { error: true },
                React.createElement("p", null,
                    React.createElement("b", null, "You have not generated any backup codes yet.")),
                React.createElement("p", null, "We highly recommend you save backup codes in case you lose access to your 2FA device."))),
            React.createElement(semantic_ui_react_1.Button, { loading: isLoading, onClick: generate }, "Generate new backup codes"))),
        codes && (React.createElement(semantic_ui_react_1.Card.Content, null,
            React.createElement("b", null, "Codes generated!"),
            " Save these in a safe place. We will not show them to you again!",
            React.createElement(semantic_ui_react_1.Message, { info: true }, codes.map(code => {
                return (React.createElement(React.Fragment, { key: code },
                    React.createElement("span", { className: "monospace" }, code),
                    React.createElement("br", null)));
            })),
            React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                    setCodes(null);
                    setTimestamp(`${Moment().fromNow()} on ${Moment().format(_user.getFullTimeFormat())}`);
                } }, "Done")))));
};
exports.default = BackupCodes;
