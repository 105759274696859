"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const qs = require("query-string");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const Search = ({ disabled, currentPeriod }) => {
    var _a, _b;
    const history = (0, react_router_dom_1.useHistory)();
    const [search, setSearch] = (0, react_1.useState)(null);
    const [time, setTime] = (0, react_1.useState)(((_a = qs.parse(history.location.search)) === null || _a === void 0 ? void 0 : _a.time) || 'month');
    const location = (0, react_router_dom_1.useLocation)();
    const timeRef = (0, react_1.useRef)(((_b = qs.parse(history.location.search)) === null || _b === void 0 ? void 0 : _b.time) || 'month');
    let timeout = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        var _a;
        // On load
        const query = qs.parse(location.search);
        if (query.search !== search) {
            setTime(((_a = qs.parse(history.location.search)) === null || _a === void 0 ? void 0 : _a.time) || 'month');
            setSearch(query.search || '');
        }
        const input = document.getElementById('search-transactions-input');
        input.addEventListener('keydown', handler, false);
        return () => {
            input.removeEventListener('keydown', handler);
        };
    }, []);
    (0, react_1.useEffect)(() => {
        // We only care if this was cleared
        const query = qs.parse(location.search);
        if (!query.search && search !== null) {
            setSearch(null);
        }
        if (!query.time && time !== null) {
            setTime('month');
        }
    }, [location]);
    const handler = () => {
        const input = document.getElementById('search-transactions-input');
        clearTimeout(timeout.current);
        // Make a new timeout set to go off in 1000ms (1 second)
        const timeoutId = setTimeout(function () {
            const query = qs.parse(history.location.search);
            const search = input.value;
            if (search.length == 0) {
                delete query.search;
                delete query.time;
                history.replace({
                    search: `?${qs.stringify(query)}`,
                });
            }
            else {
                history.replace({
                    search: `?${qs.stringify({
                        sort: query.sort,
                        direction: query.direction,
                        search: input.value,
                        time: query.time || timeRef.current || 'month',
                    })}`,
                });
            }
        }, 750);
        timeout.current = timeoutId;
    };
    return (React.createElement(semantic_ui_react_1.Input, { id: "search-transactions-input", 
        // disabled={disabled}
        size: "large", value: search || '', className: `search-transactions mr-05rem`, placeholder: `Quick search`, onChange: (e, { value }) => {
            setSearch(value);
        }, action: React.createElement(semantic_ui_react_1.Select, { options: [
                {
                    key: 'month',
                    text: Moment(currentPeriod).format('MMMM YYYY'),
                    value: 'month',
                },
                {
                    value: 'last-month',
                    key: 'last-month',
                    text: `${Moment(currentPeriod)
                        .subtract(1, 'month')
                        .format('MMMM YYYY')}`,
                },
                {
                    key: 'year',
                    text: Moment().format('YYYY'),
                    value: 'year',
                },
                {
                    value: 'last-year',
                    key: 'last-year',
                    text: `${Moment()
                        .subtract(1, 'year')
                        .format('YYYY')}`,
                },
                { key: 'all', text: 'All time', value: 'all' },
            ], value: time, onChange: (e, { value }) => {
                setTime(value);
                timeRef.current = value;
                const query = qs.parse(history.location.search);
                history.replace({
                    search: `?${qs.stringify(Object.assign(Object.assign({}, query), { time: value }))}`,
                });
            } }), icon: "search", iconPosition: "left" }));
};
exports.default = Search;
