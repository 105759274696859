"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHART_COLORS = void 0;
exports.CHART_COLORS = [
    '#40d6bb',
    '#c73a5b',
    '#0089BA',
    '#ffe11f',
    '#2f4b7c',
    '#B0A8B9',
    '#00E8EF',
    '#ff7c43',
    '#845EC2',
    '#FF9671',
    '#B1B37B',
    '#d45087',
    '#00aeff',
    // '#D65DB1',
    '#2C73D2',
    '#a05195',
    '#FF8592',
    '#046e5e',
    '#A9D965',
    '#00AFB7',
    '#f95d6a',
    '#ffa600',
    '#BEF197',
    '#665191',
    '#F6906C',
    '#97B1AB',
    '#4B4453',
    '#C34A36',
];
