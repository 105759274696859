"use strict";
/**
 *  EmailFeedback.tsx
 *  Container for the EmailFeedback page
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
// Semantic UI
const semantic_ui_react_1 = require("semantic-ui-react");
// Context
const react_1 = require("react");
const support_1 = require("@actions/support");
const react_router_dom_1 = require("react-router-dom");
const feedback_1 = require("@actions/feedback");
const EmailFeedback = ({}) => {
    const { feedback_token, feedback } = (0, react_router_dom_1.useParams)();
    const [text, setText] = (0, react_1.useState)('');
    const [done, setDone] = (0, react_1.useState)(false);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        document.title = `Submit feedback - Lunch Money`;
        sendInitialFeedback();
    }, []);
    const sendInitialFeedback = () => __awaiter(void 0, void 0, void 0, function* () {
        yield (0, feedback_1.createFeedback)({
            token: feedback_token,
            feedback: feedback,
        });
    });
    return (React.createElement("div", { className: "g-logged-out" },
        React.createElement("div", { className: "g-logged-out-container" },
            React.createElement("div", { className: "logged-out-content" },
                React.createElement(semantic_ui_react_1.Container, { className: "center-align" },
                    React.createElement("div", { id: "logo-container" },
                        React.createElement("div", { id: "logo" }),
                        React.createElement("div", { id: "shadow" })),
                    React.createElement(semantic_ui_react_1.Header, { as: "h2" }, "Feedback received!"),
                    done ? (React.createElement("p", null, "Thanks for sharing! Your feedback helps make Lunch Money better!")) : (React.createElement(React.Fragment, null,
                        React.createElement(semantic_ui_react_1.Form, null,
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                                React.createElement(semantic_ui_react_1.Form.Field, { style: { paddingLeft: 0, paddingRight: 0 } },
                                    React.createElement("p", null,
                                        "We'd love to hear more about why you ",
                                        feedback,
                                        "d this email:"),
                                    React.createElement(semantic_ui_react_1.TextArea, { rows: 3, value: text, onChange: (e, { value }) => {
                                            setText(value.toString());
                                        }, placeholder: "(optional)" })))),
                        React.createElement(semantic_ui_react_1.Button, { fluid: true, color: "orange", loading: isLoading, className: "mt-1rem", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                setIsLoading(true);
                                yield (0, support_1.receiveFeedback)({
                                    type: `Email Feedback (${feedback})`,
                                    feedback: text,
                                    priority: 'normal',
                                    url: 'https://my.lunchmoney.app/feedback/..',
                                });
                                setIsLoading(false);
                                setDone(true);
                            }) }, "Submit feedback"))))))));
};
exports.default = EmailFeedback;
