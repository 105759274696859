"use strict";
/**
 *  Transactions.tsx
 *  Container for the Transactions page
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const auth_1 = require("@actions/auth");
// Semantic UI
const semantic_ui_react_1 = require("semantic-ui-react");
class Reset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasToken: props.match.params.hasOwnProperty('reset_token'),
            email: '',
            password: '',
            message: '',
            error: '',
            buttonLoading: false,
        };
        this.reset = this.reset.bind(this);
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            // Page title
            document.title = `Reset password - Lunch Money`;
        });
    }
    reset() {
        return __awaiter(this, void 0, void 0, function* () {
            this.setState({
                buttonLoading: true,
            });
            if (this.state.hasToken) {
                const response = yield (0, auth_1.resetPassword)({
                    reset_token: this.props.match.params.reset_token,
                    password: this.state.password,
                });
                if (response.error) {
                    this.setState({
                        error: response.error.message,
                        message: '',
                        buttonLoading: false,
                    });
                }
                else {
                    this.setState({ error: '', message: response.data });
                }
            }
            else {
                const response = yield (0, auth_1.initResetPassword)(this.state.email);
                if (response.error) {
                    this.setState({
                        message: '',
                        error: response.error.message,
                        buttonLoading: false,
                    });
                }
                else {
                    this.setState({
                        error: '',
                        message: 'Check your email for the link to reset your password.',
                    });
                }
            }
        });
    }
    render() {
        return (React.createElement("div", { className: "g-logged-out" },
            React.createElement("div", { className: "g-logged-out-container" },
                React.createElement("div", { className: "logged-out-content" },
                    React.createElement(semantic_ui_react_1.Container, null,
                        React.createElement("div", { id: "logo-container" },
                            React.createElement("div", { id: "logo" }),
                            React.createElement("div", { id: "shadow" })),
                        React.createElement(semantic_ui_react_1.Header, { as: "h1", className: "center-align" }, this.state.hasToken
                            ? 'Set your new password'
                            : 'Forgot your password?'),
                        React.createElement(semantic_ui_react_1.Form, { className: "logged-out-form" },
                            this.state.message ? (React.createElement(React.Fragment, null)) : this.state.hasToken ? (React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(semantic_ui_react_1.Form.Input, { value: this.state.password, onChange: e => {
                                        this.setState({ password: e.target.value });
                                    }, fluid: true, type: "password", label: "Password" }))) : (React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(semantic_ui_react_1.Form.Input, { value: this.state.email, onChange: e => {
                                        this.setState({ email: e.target.value });
                                    }, fluid: true, label: "E-mail Address" }))),
                            this.state.error && (React.createElement("p", { className: `error ${this.state.isLogin ? 'login-error' : ''}` }, this.state.error)),
                            this.state.message && (React.createElement("p", { className: "message" }, this.state.message)),
                            !this.state.message && (React.createElement(semantic_ui_react_1.Button, { type: "submit", onClick: this.reset, loading: this.state.buttonLoading }, "Reset password"))),
                        React.createElement("p", { className: "register" },
                            React.createElement("a", { href: "#", onClick: () => {
                                    this.props.history.push('/login');
                                } }, "Back to login")))))));
    }
}
exports.default = Reset;
