"use strict";
/**
 *  Transactions.tsx
 *  Container for the Transactions page
 */
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const AuthProvider_1 = require("@providers/AuthProvider");
const semantic_ui_react_1 = require("semantic-ui-react");
const NotFound = ({}) => {
    const _auth = (0, react_1.useContext)(AuthProvider_1.AuthContext);
    return (React.createElement("div", { className: "g-logged-out" },
        React.createElement("div", { className: "g-logged-out-container" },
            React.createElement("div", { className: "logged-out-content" },
                React.createElement(semantic_ui_react_1.Container, { className: "center-align" },
                    React.createElement("div", { id: "logo-container" },
                        React.createElement("div", { id: "logo", className: "empty-logo" }),
                        React.createElement("div", { id: "shadow" })),
                    React.createElement(semantic_ui_react_1.Header, { as: "h2" }, "404 - Page Not Found"),
                    React.createElement("p", null,
                        "If you need assistance,",
                        ' ',
                        React.createElement("a", { href: "mailto:support@lunchmoney.app" }, "email us anytime"),
                        "."),
                    _auth.isLoggedIn ? (React.createElement(semantic_ui_react_1.Button, { fluid: true, onClick: () => {
                            window.location.href = '/overview';
                        } }, "Back to dashboard")) : (React.createElement(semantic_ui_react_1.Button, { fluid: true, onClick: () => {
                            window.location.href = '/login';
                        } }, "Log in")),
                    React.createElement(semantic_ui_react_1.Button, { fluid: true, basic: true, color: "orange", className: "mt-05rem", onClick: () => {
                            window.location.href = 'https://lunchmoney.app';
                        } }, "Back to lunchmoney.app"))))));
};
exports.default = NotFound;
