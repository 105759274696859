"use strict";
/**
 *  EditModal.tsx
 *  Recurring expenses edit modal
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const transactions_1 = require("@actions/transactions");
const react_1 = require("react");
const EditableAsset_1 = require("@components/elements/EditableAsset");
const EditablePrice_1 = require("@components/elements/EditablePrice");
const EditableString_1 = require("@components/elements/EditableString");
const EditableDate_1 = require("@components/elements/EditableDate");
const ModalProvider_1 = require("@providers/ModalProvider");
const AssetsProvider_1 = require("@/providers/AssetsProvider");
const GroupEditTransaction = ({ data, useModal, utils, setVisibility, switchView, setDisableOnClickOutside, }) => {
    const _modal = (0, react_1.useContext)(ModalProvider_1.ModalContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [hasChanges, setHasChanges] = (0, react_1.useState)(false);
    const [transaction, setTransaction] = (0, react_1.useState)(_modal.data.current.transaction);
    /**
     * Setup: Static Details
     */
    (0, react_1.useEffect)(() => {
        useModal({
            header: (React.createElement("div", null,
                React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        switchView('GROUP_VIEW');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " Back to transaction group"),
                React.createElement("span", { className: "header-text-large" }, "Edit transaction"))),
            middleButton: React.createElement(React.Fragment, null),
            leftButton: hasChanges ? (React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "no-border", basic: true, onClick: () => {
                    switchView('GROUP_VIEW');
                } }, 'Cancel')) : (React.createElement(React.Fragment, null)),
            rightButton: hasChanges ? (React.createElement(semantic_ui_react_1.Button, { loading: isLoading, disabled: isLoading, color: "orange", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setIsLoading(true);
                    const results = yield utils._process(transactions_1.updateTransaction)(transaction.id, {
                        payee: transaction.payee,
                        amount: transaction.amount,
                        currency: transaction.currency,
                        asset_id: transaction.asset_id,
                        plaid_account_id: transaction.plaid_account_id,
                        notes: transaction.notes,
                        date: transaction.date,
                    });
                    if (_modal.data.current.transactions) {
                        _modal.data.current.transactions = Object.assign(Object.assign({}, _modal.data.current.transactions), { [transaction.id]: Object.assign(Object.assign({}, _modal.data.current.transactions[transaction.id]), { id: transaction.id, payee: transaction.payee, amount: transaction.amount, currency: transaction.currency, asset_id: transaction.asset_id, plaid_account_id: transaction.plaid_account_id, notes: transaction.notes, date: transaction.date }) });
                    }
                    if (data.amendTransactions) {
                        data.amendTransactions({
                            update: {
                                id: transaction.id,
                                payee: transaction.payee,
                                amount: transaction.amount,
                                currency: transaction.currency,
                                asset_id: transaction.asset_id,
                                plaid_account_id: transaction.plaid_account_id,
                                notes: transaction.notes,
                                date: transaction.date,
                            },
                        });
                    }
                    if (results.data) {
                        setIsLoading(false);
                        utils._showToast({
                            message: `Successfully updated transaction`,
                            type: 'success',
                        });
                        switchView('GROUP_VIEW');
                    }
                }) }, 'Save Changes')) : (React.createElement(semantic_ui_react_1.Button, { color: "orange", onClick: () => {
                    switchView('GROUP_VIEW');
                } }, 'Back')),
        });
        return () => {
            useModal({
                header: null,
                leftButton: null,
                middleButton: null,
                rightButton: null,
            });
        };
    }, [transaction, isLoading, hasChanges]);
    return (transaction && (React.createElement("div", { id: "grouped-modal-detail-view" },
        transaction.plaid_account_id &&
            !_assets.getPlaidAccount(transaction.plaid_account_id)['treat_as_manual'] && (React.createElement(semantic_ui_react_1.Message, { info: true }, "This transaction was automatically imported from your bank and has certain properties (account, amount) that cannot be changed.")),
        React.createElement("div", { className: "transaction-details" },
            React.createElement(semantic_ui_react_1.Form, null,
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null, "Date"),
                        React.createElement(EditableDate_1.default, { identifier: "group-date", format: 'month_day_year', firstValue: transaction.date, location: 'modal', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () {
                                setHasChanges(true);
                                setTransaction(Object.assign(Object.assign({}, transaction), { date: date }));
                            }) }))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null, "Payee Name"),
                        React.createElement(EditableString_1.default, { identifier: `group-payee`, firstValue: transaction.payee, location: 'modal', state: 'Editing', shouldSaveOnChange: true, onSave: (payee) => __awaiter(void 0, void 0, void 0, function* () {
                                setHasChanges(true);
                                setTransaction(Object.assign(Object.assign({}, transaction), { payee: payee }));
                            }) }))),
                (!transaction.plaid_account_id ||
                    _assets.getPlaidAccount(transaction.plaid_account_id)['treat_as_manual']) && (React.createElement(React.Fragment, null,
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", null, "Amount"),
                            React.createElement(EditablePrice_1.default, { identifier: `group-amount`, amount: transaction.amount, currency: transaction.currency, state: 'Editing', location: 'modal', saveOnChange: true, onSave: (amount, currency) => __awaiter(void 0, void 0, void 0, function* () {
                                    setHasChanges(true);
                                    setTransaction(Object.assign(Object.assign({}, transaction), { amount,
                                        currency }));
                                }) }))),
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", null, "Paid from"),
                            React.createElement(EditableAsset_1.default, { defaultSelection: {
                                    source: transaction.plaid_account_id
                                        ? 'plaid'
                                        : transaction.asset_id
                                            ? 'manual'
                                            : null,
                                    id: transaction.plaid_account_id ||
                                        transaction.asset_id ||
                                        -1,
                                }, onSave: obj => {
                                    setHasChanges(true);
                                    setTransaction(Object.assign(Object.assign({}, transaction), { [obj.source == 'plaid'
                                            ? 'plaid_account_id'
                                            : 'asset_id']: obj.id > -1 ? obj.id : null, [obj.source == 'plaid'
                                            ? 'asset_id'
                                            : 'plaid_account_id']: null }));
                                } }))))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null, "Notes"),
                        React.createElement(EditableString_1.default, { identifier: `group-notes`, placeholder: "Notes (optional)", firstValue: transaction.notes, location: 'modal', state: 'Editing', shouldSaveOnChange: true, onSave: (notes) => __awaiter(void 0, void 0, void 0, function* () {
                                setHasChanges(true);
                                setTransaction(Object.assign(Object.assign({}, transaction), { notes: notes }));
                            }) }))))))));
};
exports.default = GroupEditTransaction;
