"use strict";
/**
 *  EditableCrypto.tsx
 */
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const StaticProvider_1 = require("@providers/StaticProvider");
const EditableCrypto = ({ className = '', onSave }) => {
    const _static = (0, react_1.useContext)(StaticProvider_1.StaticContext);
    // const [availableCrypto, setAvailableCrypto] = useState(null)
    const [selectedCrypto, setSelectedCrypto] = (0, react_1.useState)(null);
    return (React.createElement(semantic_ui_react_1.Dropdown, { fluid: true, selection: true, loading: !_static.allCrypto, search: true, placeholder: 'Select cryptocurrency', selectOnNavigation: false, options: _static.allCrypto, className: `${className} category-dropdown p-category-modal no-hover`, value: selectedCrypto, onChange: (e, { value, options }) => {
            setSelectedCrypto(value);
            // Get full name
            const match = options.find(o => o.value == value);
            onSave(value, match.full_name);
        } }));
};
exports.default = EditableCrypto;
