"use strict";
/**
 *  SplitElement.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
// Components
const format_1 = require("@helpers/format");
const RuleConditionOptions = ({ conditions, setConditions, setDisableOnClickOutside, }) => {
    const filters = [
        !conditions.hasOwnProperty('payee')
            ? { value: 'matches payee name', key: 'payee', disabled: false }
            : null,
        !conditions.hasOwnProperty('category_id')
            ? { value: 'matches category', key: 'category_id', disabled: false }
            : null,
        !conditions.hasOwnProperty('notes')
            ? { value: 'matches notes', key: 'notes', disabled: false }
            : null,
        !conditions.hasOwnProperty('amount')
            ? { value: 'matches amount', key: 'amount', disabled: false }
            : null,
        !conditions.hasOwnProperty('day')
            ? { value: 'matches day', key: 'day', disabled: false }
            : null,
        !conditions.hasOwnProperty('account')
            ? { value: 'in account', key: 'account', disabled: false }
            : null,
    ].filter(o => !!o);
    return (React.createElement(semantic_ui_react_1.Dropdown, { className: `category-dropdown p-category-modal modal-dropdown`, style: { borderColor: '#94ABD0' }, placeholder: 'Add condition', fluid: true, selection: true, 
        // search
        selectOnNavigation: false, selectOnBlur: false, closeOnEscape: true, closeOnChange: true, value: null, options: (filters === null || filters === void 0 ? void 0 : filters.length) > 0
            ? filters.map(filter => {
                return {
                    disabled: filter.disabled || conditions.hasOwnProperty(filter.key),
                    key: filter.value,
                    value: filter.value,
                    text: (0, format_1.capitalize)(filter.value),
                };
            })
            : [
                {
                    disabled: true,
                    key: 'none',
                    value: 'No options left to select.',
                    text: 'No options left to select.',
                },
            ], onChange: (e_1, _a) => __awaiter(void 0, [e_1, _a], void 0, function* (e, { value }) {
            setDisableOnClickOutside(true);
            if (value == 'matches payee name') {
                setConditions(Object.assign(Object.assign({}, conditions), { payee: {
                        name: null,
                        match: 'contain',
                    } }));
            }
            else if (value == 'matches category') {
                setConditions(Object.assign(Object.assign({}, conditions), { category_id: null }));
            }
            else if (value == 'matches notes') {
                setConditions(Object.assign(Object.assign({}, conditions), { notes: {
                        name: null,
                        match: 'contain',
                    } }));
            }
            else if (value == 'matches amount') {
                setConditions(Object.assign(Object.assign({}, conditions), { amount: {
                        match: 'exactly',
                        type: 'expenses',
                    } }));
            }
            else if (value == 'matches day') {
                setConditions(Object.assign(Object.assign({}, conditions), { day: {
                        match: 'before',
                    } }));
            }
            else if (value == 'in account') {
                setConditions(Object.assign(Object.assign({}, conditions), { account: {} }));
            }
        }) }));
};
exports.default = RuleConditionOptions;
