"use strict";
/**
 *  EditableCurrency.tsx
 */
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
// Components
const semantic_ui_react_1 = require("semantic-ui-react");
const StaticProvider_1 = require("@providers/StaticProvider");
const UserProvider_1 = require("@providers/UserProvider");
const EditableCurrency = ({ onSave, firstValue = null, useSupported = false, }) => {
    const _static = (0, react_1.useContext)(StaticProvider_1.StaticContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [selectedCurrency, setSelectedCurrency] = (0, react_1.useState)(firstValue);
    (0, react_1.useEffect)(() => {
        setSelectedCurrency(firstValue);
    }, [firstValue]);
    return (React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal", placeholder: "Select currency", fluid: true, selection: true, value: selectedCurrency, options: useSupported
            ? _user.supportedCurrencies.map(currency => {
                return {
                    key: currency,
                    value: currency,
                    text: `${currency.toUpperCase()}`,
                    onClick: (e, { value }) => {
                        if (value) {
                            setSelectedCurrency(value);
                            onSave(value);
                        }
                    },
                };
            })
            : _static.allCurrencies.map(currency => {
                return Object.assign(Object.assign({}, currency), { onClick: (e, { value }) => {
                        if (value) {
                            setSelectedCurrency(value);
                            onSave(value);
                        }
                    } });
            }) }));
};
exports.default = EditableCurrency;
