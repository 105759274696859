"use strict";
/**
 *  MergeAccounts.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_1 = require("react");
const assets_1 = require("@actions/assets");
const AssetSelection_1 = require("@/components/elements/AssetSelection");
const MergeAccounts = ({ data, useModal, switchView, utils, setVisibility, }) => {
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [selectedAccount, setSelectedAccount] = (0, react_1.useState)(null);
    const [overrideBalance, setOverrideBalance] = (0, react_1.useState)('use_destination');
    // Setup
    (0, react_1.useEffect)(() => {
        useModal({
            header: (React.createElement("div", null,
                React.createElement("span", { className: "header-text-large" }, "Merge Accounts"))),
            leftButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: true, className: "no-border", onClick: () => {
                    switchView('LINKED_ACCOUNT_VIEW');
                } }, "Back")),
            middleButton: React.createElement(React.Fragment, null),
        });
    }, []);
    (0, react_1.useEffect)(() => {
        useModal({
            rightButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", loading: isLoading, disabled: !selectedAccount || isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setIsLoading(true);
                    const results = yield utils._process(assets_1.mergeAccounts)({
                        src: {
                            type: data.account.source,
                            id: data.account.id,
                        },
                        dest: {
                            type: selectedAccount.split('_')[0],
                            id: Number(selectedAccount.split('_')[1]),
                        },
                        opts: {
                            override_balance_history: overrideBalance == 'use_source',
                        },
                    });
                    if (!results.error) {
                        utils._showToast({
                            type: 'success',
                            message: 'Accounts are being merged! This may take a few moments.',
                        });
                        setIsLoading(false);
                        setVisibility(false);
                    }
                    else {
                        setIsLoading(false);
                    }
                }) }, "Save Changes")),
        });
    }, [isLoading, data, overrideBalance, selectedAccount]);
    return (React.createElement("div", { className: "g-networth-modal", id: "recurring-modal-detail-view" },
        React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Message, { info: true },
                data.account.source !== 'zabo' ? (React.createElement(React.Fragment, null,
                    React.createElement("p", null,
                        data.account.institution_name,
                        " ",
                        data.account.name,
                        "'s items (",
                        React.createElement("b", null, "transactions, recurring expenses, rules"),
                        ") will be moved over to the account of your choice."),
                    React.createElement("p", null,
                        data.account.institution_name,
                        " ",
                        data.account.name,
                        "'s",
                        ' ',
                        React.createElement("b", null, "import configurations"),
                        " will be removed."),
                    React.createElement("p", null,
                        data.account.institution_name,
                        " ",
                        data.account.name,
                        "'s",
                        ' ',
                        React.createElement("b", null, "balance history"),
                        " will be merged with the account of your choice."),
                    React.createElement("p", null,
                        "Finally, ",
                        data.account.institution_name,
                        " ",
                        data.account.name,
                        ' ',
                        "will be removed."))) : (React.createElement(React.Fragment, null,
                    React.createElement("p", null,
                        "All of ",
                        data.account.institution_name,
                        "'s ",
                        React.createElement("b", null, "balance history"),
                        ' ',
                        "by currency will be merged with the balance history of the same currency in the account of your choice."))),
                React.createElement("p", null,
                    React.createElement("b", null, "Note: this is an irreversible operation!")),
                React.createElement("p", null,
                    React.createElement(semantic_ui_react_1.Icon, { name: "external alternate" }),
                    ' ',
                    React.createElement("b", null,
                        React.createElement("a", { href: "https://support.lunchmoney.app/setup/linked-accounts#how-do-i-merge-a-manually-managed-account-with-a-synced-account", target: "_blank" }, "When should I use the \"Merge Accounts\" feature?")))),
            React.createElement("div", { id: "transactions-modal-detail-view", className: "mb-0" },
                React.createElement("div", { className: "transaction-details" },
                    React.createElement("div", { className: "transaction-detail" },
                        React.createElement("label", null, "Which account would you like to merge this account into?"),
                        React.createElement(AssetSelection_1.default, { value: selectedAccount, onSelect: value => {
                                setSelectedAccount(value);
                            } })),
                    React.createElement("div", { className: "transaction-detail" },
                        React.createElement(semantic_ui_react_1.Form, null,
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement("label", null, "When merging balance histories and there is a conflict, which account's balance should we choose?"),
                                    React.createElement(semantic_ui_react_1.Dropdown, { fluid: true, value: overrideBalance, selection: true, className: "p-category-modal category-dropdown", options: [
                                            {
                                                key: 'use_source',
                                                value: 'use_source',
                                                text: `Use source balance`,
                                            },
                                            {
                                                key: 'use_destination',
                                                value: 'use_destination',
                                                text: `Use destination balance`,
                                            },
                                        ], onChange: (e, { value }) => {
                                            setOverrideBalance(value);
                                        } }))))))))));
};
exports.default = MergeAccounts;
