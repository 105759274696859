"use strict";
/**
 *  Transactions.tsx
 *  Container for the Transactions page
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const inEU = require("@segment/in-eu");
const account_1 = require("@actions/account");
const auth_1 = require("@actions/auth");
// Semantic UI
const semantic_ui_react_1 = require("semantic-ui-react");
class Accept extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasToken: props.match.params.hasOwnProperty('invite_token'),
            email: '',
            name: '',
            password: '',
            message: '',
            error: '',
            buttonLoading: false,
            inviter: null,
            redirectToRegister: false,
            subscribeToNewsletter: inEU() ? false : true,
        };
        this.create = this.create.bind(this);
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            // Page title
            document.title = `Accept Invitation - Lunch Money`;
            const invitedUser = yield (0, account_1.getCollaboratorInvite)(this.props.match.params.invite_token);
            if (invitedUser) {
                this.setState({
                    email: invitedUser.email,
                    inviter: invitedUser.inviter_name,
                });
            }
        });
    }
    create() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.state.name) {
                this.setState({
                    error: 'Missing first name. You can make one up if you prefer!',
                });
                return;
            }
            else if (!this.state.password) {
                this.setState({ error: 'Missing password. May we suggest 1Password?' });
                return;
            }
            else if (this.state.password.trim().length < 8) {
                this.setState({
                    error: 'You can come up with a better password than that, right? Minimum 8 characters, please!',
                });
                return;
            }
            this.setState({
                buttonLoading: true,
            });
            const response = yield (0, account_1.acceptInvitation)({
                invite_token: this.props.match.params.invite_token,
                name: this.state.name,
                password: this.state.password,
                subscribe_to_newsletter: this.state.subscribeToNewsletter,
            });
            if (response.error) {
                this.setState({ error: response.error.message, buttonLoading: false });
            }
            else {
                // Login
                const results = yield (0, auth_1.login)(this.state.email, this.state.password);
                if (!!results) {
                    window.location.href = '/';
                }
                else {
                    this.setState({ error: 'Log in failed. Please try again!' });
                }
            }
        });
    }
    render() {
        if (this.state.redirectToRegister === true) {
            return (React.createElement(react_router_dom_1.Redirect, { to: {
                    pathname: '/register',
                    state: {
                        name: this.state.name,
                        email: this.state.email,
                        password: this.state.password,
                    },
                } }));
        }
        return (React.createElement("div", { className: "g-logged-out" },
            React.createElement("div", { className: "g-logged-out-container" },
                React.createElement("div", { className: "logged-out-content" },
                    React.createElement("div", { id: "logo-container" },
                        React.createElement("div", { id: "logo" }),
                        React.createElement("div", { id: "shadow" })),
                    React.createElement(semantic_ui_react_1.Header, { as: "h2", className: "center-align" }, this.state.email
                        ? `Accept ${this.state.inviter}'s invitation to collaborate on a budget`
                        : 'This invite link is invalid.'),
                    this.state.email && (React.createElement(semantic_ui_react_1.Form, { className: "logged-out-form" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", null, "Email Address"),
                            React.createElement(semantic_ui_react_1.Form.Input, { value: this.state.email, onChange: e => {
                                    this.setState({ email: e.target.value });
                                }, fluid: true, disabled: true })),
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(semantic_ui_react_1.Form.Input, { value: this.state.name, onChange: e => {
                                    this.setState({ name: e.target.value });
                                }, fluid: true, label: "Name" })),
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(semantic_ui_react_1.Form.Input, { value: this.state.password, onChange: e => {
                                    this.setState({ password: e.target.value });
                                }, fluid: true, type: "password", label: "Password" })),
                        inEU() && (React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: this.state.subscribeToNewsletter, onChange: (e, { checked }) => {
                                    this.setState({
                                        subscribeToNewsletter: checked,
                                    });
                                }, label: React.createElement(React.Fragment, null,
                                    React.createElement("label", null, "Subscribe to newsletter")) }),
                            React.createElement(semantic_ui_react_1.Popup, { hoverable: true, trigger: React.createElement("a", { href: "https://lunchmoney.app/sample_newsletter", target: "_blank" },
                                    React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", name: "eye", color: "black" })) },
                                React.createElement("p", null, "See a sample of our newsletter!")),
                            React.createElement(semantic_ui_react_1.Popup, { hoverable: true, trigger: React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", name: "question circle", color: "grey" }) },
                                React.createElement("p", null, "We send a newsletter twice a month to update our users on new features and latest changes.")))),
                        this.state.error && (React.createElement("p", { className: `error ${this.state.isLogin ? 'login-error' : ''}` }, this.state.error)),
                        this.state.message && (React.createElement("p", { className: "message" }, this.state.message)),
                        !this.state.message && (React.createElement(semantic_ui_react_1.Button, { type: "submit", onClick: this.create, loading: this.state.buttonLoading, disabled: this.state.name.length === 0 ||
                                this.state.password.length < 8 }, "Accept & create account"))))))));
    }
}
exports.default = Accept;
