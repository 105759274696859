"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const _ = require("lodash");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_1 = require("@/helpers/format");
const UserProvider_1 = require("@/providers/UserProvider");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const react_1 = require("react");
const LineItem = ({ keyValue, propertyValue, lineItemClass = '', keyClass = '', propertyClass = '', isNested = false, inPopup = false, hideUnderline = false, onClick = () => { }, }) => (React.createElement("div", { className: `line-item ${lineItemClass}`, onClick: onClick },
    React.createElement("div", { className: `key ${keyClass}` },
        isNested && React.createElement("span", { className: "hierarchy-line-icon-5" }),
        keyValue),
    !hideUnderline && (React.createElement("div", { className: `underline ${inPopup ? 'in-popup' : ''}` })),
    React.createElement("div", { className: `property ${propertyClass}` }, propertyValue)));
const Day = ({ day, month, year, data, isToday, shouldTruncate, transactionsDisplay, categoryGroupDisplay, recurringDisplay, includeExcludeFromTotals, includePendingTransactions, includeUnmatchedRecurringInTotals, showTransactions, addTransaction, }) => {
    const _category = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [content, setContent] = (0, react_1.useState)([]);
    const [grandTotal, setGrandTotal] = (0, react_1.useState)(null);
    const [actualTotal, setActualTotal] = (0, react_1.useState)(null);
    const [unmatchedRecurringTotal, setUnmatchedRecurringTotal] = (0, react_1.useState)(null);
    const [seeMore, setSeeMore] = (0, react_1.useState)(false);
    const constructConsolidatedRecurringPopup = ({ items, total, noun, isMatched, }) => {
        if (items.length == 0) {
            return null;
        }
        const currentDay = Moment()
            .month(month - 1)
            .year(year)
            .date(day);
        return (React.createElement(semantic_ui_react_1.Popup, { key: `recurring-popup-${day}-${month}-${year}-${noun}-${isMatched ? 'matched' : 'unmatched'}`, position: 'right center', trigger: React.createElement("div", null,
                React.createElement(LineItem, { lineItemClass: isMatched ? 'clickable matched' : '', keyClass: `cadence-monthly ${noun == 'income' ? 'color-green' : ''}`, keyValue: `${items.length} ${isMatched ? 'Recurring' : 'Expected'} ${(0, format_1.capitalize)(noun)}${items.length > 1 ? 's' : ''}`, propertyClass: "no-truncate" // color-green if income
                    , propertyValue: (0, format_1.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) * total, _user.primaryCurrency), onClick: () => {
                        if (isMatched) {
                            showTransactions({
                                title: `Recurring ${(0, format_1.capitalize)(noun)}s`,
                                query: {
                                    match: 'all',
                                    time: 'custom',
                                    recurring: 'any',
                                    is_income: noun == 'income',
                                    start_date: Moment()
                                        .year(year)
                                        .month(month - 1)
                                        .date(day)
                                        .format('YYYY-MM-DD'),
                                    end_date: Moment()
                                        .year(year)
                                        .month(month - 1)
                                        .date(day)
                                        .format('YYYY-MM-DD'),
                                },
                                total: (0, format_1.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) *
                                    total, _user.primaryCurrency),
                            });
                        }
                    } })), hoverable: true, size: "small", content: React.createElement(React.Fragment, null,
                React.createElement("h3", { className: "label-style mb-0" },
                    isMatched ? 'Matched' : 'Expected',
                    " Recurring ",
                    (0, format_1.capitalize)(noun),
                    "s"),
                !isMatched &&
                    (currentDay.isAfter(Moment()) ? (React.createElement(semantic_ui_react_1.Message, { info: true }, "We are anticipating the following recurring item(s) to happen soon!")) : (React.createElement(semantic_ui_react_1.Message, { warning: true }, "We couldn't find this period's payment for the following recurring item(s). Tip: you can manually link a transaction to a recurring item to match it or set up a rule to automatically link transactions."))), items === null || items === void 0 ? void 0 :
                items.map(tx => {
                    return (React.createElement(LineItem, { inPopup: true, key: `line-up-tx-${tx.id}`, lineItemClass: `calendar-item ${isMatched ? 'matched' : ''}`, keyClass: `cadence-${tx.cadence.replaceAll(' ', '-')}`, keyValue: tx.payee, propertyClass: `no-truncate ${tx.is_income ||
                            (isMatched ? tx.transaction.to_base : tx.to_base) < 0
                            ? 'color--green'
                            : ''}`, propertyValue: (0, format_1.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) *
                            (isMatched ? tx.transaction.amount : tx.amount), isMatched ? tx.transaction.currency : tx.currency) }));
                }),
                isMatched && (React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "mini", onClick: () => {
                        showTransactions({
                            title: 'Recurring Expenses',
                            query: {
                                match: 'all',
                                time: 'custom',
                                recurring: 'any',
                                is_income: noun == 'income',
                                start_date: Moment()
                                    .year(year)
                                    .month(month - 1)
                                    .date(day)
                                    .format('YYYY-MM-DD'),
                                end_date: Moment()
                                    .year(year)
                                    .month(month - 1)
                                    .date(day)
                                    .format('YYYY-MM-DD'),
                            },
                            total: (0, format_1.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) *
                                total, _user.primaryCurrency),
                        });
                    } },
                    "View",
                    ' ',
                    Moment()
                        .year(year)
                        .month(month - 1)
                        .date(day)
                        .format(_user.getFormat('month_day_year')),
                    ' ',
                    "recurring ",
                    noun,
                    "s"))) }));
    };
    const constructIndividualRecurringPopup = item => {
        var _a, _b;
        return (React.createElement("div", { key: `recurring-items-a-${item.id}` },
            React.createElement(LineItem, { inPopup: true, keyClass: "no-label", keyValue: "Repeats", propertyValue: item.cadence == 'custom'
                    ? `Every ${item.quantity} ${item.granularity}${item.quantity > 1 ? 's' : ''}`
                    : (0, format_1.capitalize)(item.cadence) }),
            React.createElement(LineItem, { inPopup: true, keyClass: "no-label", keyValue: "Merchant Name", propertyValue: item.payee }),
            React.createElement(LineItem, { inPopup: true, keyClass: "no-label", keyValue: "Amount Expected", propertyValue: (0, format_1.toPrice)(_user.settings['show_debits_as_negative']
                    ? item.amount * -1
                    : item.amount, item.currency) }),
            !!item.description && (React.createElement(LineItem, { inPopup: true, keyClass: "no-label", keyValue: "Description", propertyValue: item.description })),
            !!(item.plaid_account_name || item.asset_name) && (React.createElement(LineItem, { inPopup: true, keyClass: "no-label", keyValue: "Paid From", propertyValue: item.plaid_account_display_name ||
                    item.asset_display_name ||
                    item.plaid_account_name ||
                    item.asset_name })),
            !!item.notes && (React.createElement(LineItem, { inPopup: true, keyClass: "no-label", keyValue: "Notes", propertyValue: item.notes })),
            React.createElement(LineItem, { inPopup: true, keyClass: "no-label", keyValue: "Recurring Status", propertyValue: React.createElement(semantic_ui_react_1.Popup, { trigger: ((_a = item.transaction) === null || _a === void 0 ? void 0 : _a.id) ? (React.createElement("span", null,
                        React.createElement(semantic_ui_react_1.Icon, { name: "check circle", color: "green" }),
                        " Matched")) : Moment().isAfter(Moment(item.billing_date).add(1, 'day')) ? (React.createElement("span", null,
                        React.createElement(semantic_ui_react_1.Icon, { name: "warning circle", color: item.type == 'suggested' ? 'grey' : 'yellow' }),
                        "Missing")) : (React.createElement("span", null,
                        React.createElement(semantic_ui_react_1.Icon, { name: "wait", color: 'grey' }),
                        "Awaiting")), inverted: true, size: "tiny" }, ((_b = item.transaction) === null || _b === void 0 ? void 0 : _b.id)
                    ? 'Transaction matched for this period'
                    : Moment().isAfter(Moment(item.billing_date).add(1, 'day'))
                        ? "We couldn't find this period's payment for this recurring item. Tip: you can manually link a transaction to a recurring item."
                        : 'This recurring item is scheduled to be paid in the future.') }),
            React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "mini", onClick: () => {
                    showTransactions({
                        title: 'Past Recurring Items',
                        query: {
                            match: 'all',
                            time: 'all',
                            recurring: item.id,
                        },
                        total: null,
                    });
                } }, "View past recurring transactions")));
    };
    (0, react_1.useEffect)(() => {
        var _a, _b, _c, _d, _e;
        const _content = [];
        const _MATCHED_EXPENSES = recurringDisplay == 'hide'
            ? { items: [] }
            : Object.assign({ items: [] }, data === null || data === void 0 ? void 0 : data.matched_expense);
        const _UNMATCHED_EXPENSES = recurringDisplay == 'hide'
            ? { items: [] }
            : Object.assign({ items: [] }, data === null || data === void 0 ? void 0 : data.unmatched_expense);
        const _MATCHED_INCOME = recurringDisplay == 'hide'
            ? { items: [] }
            : Object.assign({ items: [] }, data === null || data === void 0 ? void 0 : data.matched_income);
        const _UNMATCHED_INCOME = recurringDisplay == 'hide'
            ? { items: [] }
            : Object.assign({ items: [] }, data === null || data === void 0 ? void 0 : data.unmatched_income);
        const _TX_EXPENSES = transactionsDisplay == 'hide'
            ? { items: [] }
            : Object.assign({ items: [] }, data === null || data === void 0 ? void 0 : data.tx_expense);
        const _TX_INCOME = transactionsDisplay == 'hide'
            ? { items: [] }
            : Object.assign({ items: [] }, data === null || data === void 0 ? void 0 : data.tx_income);
        if (includeExcludeFromTotals) {
            // default
        }
        else {
            // remove excluded transactions
            _TX_EXPENSES.items = _TX_EXPENSES.items.filter(o => !o.exclude_from_totals);
            _TX_INCOME.items = _TX_INCOME.items.filter(o => !o.exclude_from_totals);
        }
        if (includePendingTransactions) {
            // default
        }
        else {
            // remove excluded transactions
            _TX_EXPENSES.items = _TX_EXPENSES.items.filter(o => !o.is_pending);
            _TX_INCOME.items = _TX_INCOME.items.filter(o => !o.is_pending);
        }
        // Get new total
        _TX_EXPENSES.total = _TX_EXPENSES.items.reduce((acc, cur) => {
            return acc + cur.to_base;
        }, 0);
        _TX_INCOME.total = _TX_INCOME.items.reduce((acc, cur) => {
            return acc + cur.to_base;
        }, 0);
        const _ALL_RECURRING = [
            ..._MATCHED_EXPENSES === null || _MATCHED_EXPENSES === void 0 ? void 0 : _MATCHED_EXPENSES.items,
            ..._MATCHED_INCOME === null || _MATCHED_INCOME === void 0 ? void 0 : _MATCHED_INCOME.items,
            ...(includeUnmatchedRecurringInTotals
                ? [..._UNMATCHED_EXPENSES === null || _UNMATCHED_EXPENSES === void 0 ? void 0 : _UNMATCHED_EXPENSES.items, ..._UNMATCHED_INCOME === null || _UNMATCHED_INCOME === void 0 ? void 0 : _UNMATCHED_INCOME.items]
                : []),
        ];
        const _HAS_RECURRING = _ALL_RECURRING.length > 0;
        const _HAS_TRANSACTIONS = [..._TX_EXPENSES === null || _TX_EXPENSES === void 0 ? void 0 : _TX_EXPENSES.items, ..._TX_INCOME === null || _TX_INCOME === void 0 ? void 0 : _TX_INCOME.items].length > 0;
        // First include recurring
        if (_HAS_RECURRING) {
            if (recurringDisplay == 'consolidate') {
                _content.push(constructConsolidatedRecurringPopup({
                    items: _MATCHED_EXPENSES.items,
                    total: _MATCHED_EXPENSES.total,
                    noun: 'expense',
                    isMatched: true,
                }));
                _content.push(constructConsolidatedRecurringPopup({
                    items: _MATCHED_INCOME.items,
                    total: _MATCHED_INCOME.total,
                    noun: 'income',
                    isMatched: true,
                }));
                if (includeUnmatchedRecurringInTotals) {
                    _content.push(constructConsolidatedRecurringPopup({
                        items: _UNMATCHED_EXPENSES.items,
                        total: _UNMATCHED_EXPENSES.total,
                        noun: 'expense',
                        isMatched: false,
                    }));
                    _content.push(constructConsolidatedRecurringPopup({
                        items: _UNMATCHED_INCOME.items,
                        total: _UNMATCHED_INCOME.total,
                        noun: 'income',
                        isMatched: false,
                    }));
                }
            }
            else if (recurringDisplay == 'show') {
                _ALL_RECURRING.forEach(o => {
                    var _a, _b, _c, _d;
                    _content.push(React.createElement(semantic_ui_react_1.Popup, { position: 'right center', key: `pop-up-${o.id}`, trigger: React.createElement("div", null,
                            React.createElement(LineItem, { lineItemClass: `calendar-item ${((_a = o.transaction) === null || _a === void 0 ? void 0 : _a.id) ? 'matched' : ''}`, keyClass: `cadence-${o.cadence.replaceAll(' ', '-')}`, propertyClass: `no-truncate ${o.is_income ||
                                    (((_b = o.transaction) === null || _b === void 0 ? void 0 : _b.id) ? o.transaction.to_base : o.to_base) <
                                        0
                                    ? 'color--green'
                                    : ''}`, keyValue: o.payee, propertyValue: (0, format_1.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) *
                                    (((_c = o.transaction) === null || _c === void 0 ? void 0 : _c.id) ? o.transaction.amount : o.amount), ((_d = o.transaction) === null || _d === void 0 ? void 0 : _d.id) ? o.transaction.currency : o.currency) })), hoverable: true, size: "small", content: constructIndividualRecurringPopup(o) }));
                });
            }
        }
        // Next, include transactions
        if (_HAS_TRANSACTIONS) {
            if (transactionsDisplay == 'categorize') {
                const _allTx = [..._TX_EXPENSES.items, ..._TX_INCOME.items];
                let sortedCategories = [];
                if (categoryGroupDisplay == 'show') {
                    // First, group by category group
                    const groupedByCategoryGroup = _.groupBy(_allTx, 'category_group_id');
                    // Sanitize the category group data
                    const categoryGroups = (_a = Object.keys(groupedByCategoryGroup)) === null || _a === void 0 ? void 0 : _a.map(category_group_id => {
                        return {
                            total: groupedByCategoryGroup[category_group_id].reduce((acc, cur) => {
                                return acc + cur.to_base;
                            }, 0),
                            category_name: category_group_id == 'null'
                                ? null
                                : _category.getName(category_group_id),
                            category_id: category_group_id == 'null' ? null : category_group_id,
                            data: groupedByCategoryGroup[category_group_id],
                        };
                    }).sort((a, b) => b.total - a.total);
                    // Within each category group, we need to organize further!
                    categoryGroups.forEach(categoryGroup => {
                        var _a;
                        if (categoryGroup.category_id) {
                            // Skip where categories are not in groups
                            sortedCategories.push({
                                category_name: categoryGroup.category_name,
                                category_id: categoryGroup.category_id,
                                label: `${categoryGroup.category_name} (${categoryGroup.data.length})`,
                                value: categoryGroup.total,
                                data: categoryGroup.data,
                            });
                        }
                        // Go through the children categories and group them by category_id
                        const groupedByCategory = _.groupBy(categoryGroup.data, 'category_id');
                        const categories = (_a = Object.keys(groupedByCategory)) === null || _a === void 0 ? void 0 : _a.map(category_id => {
                            return {
                                category_name: _category.getName(category_id),
                                category_id,
                                total: groupedByCategory[category_id].reduce((acc, cur) => {
                                    return acc + cur.to_base;
                                }, 0),
                                data: groupedByCategory[category_id],
                            };
                        }).sort((a, b) => b.total - a.total);
                        // And then add it to sortedCategories
                        Object.keys(categories).forEach(category => {
                            sortedCategories.push({
                                is_nested: !!categoryGroup.category_id, // nest it only if it has a category group
                                category_name: categories[category].category_name,
                                category_id: categories[category].category_id,
                                label: `${categories[category].category_name} (${categories[category].data.length})`,
                                value: categories[category].total,
                                data: categories[category].data,
                            });
                        });
                    });
                }
                else {
                    const groupedByCategoryId = _.groupBy(_allTx, 'category_id');
                    // Sanitize the category group data
                    (_b = Object.keys(groupedByCategoryId)) === null || _b === void 0 ? void 0 : _b.map(category_id => {
                        return {
                            total: groupedByCategoryId[category_id].reduce((acc, cur) => {
                                return acc + cur.to_base;
                            }, 0),
                            category_name: category_id == 'null' ? null : _category.getName(category_id),
                            category_id: category_id == 'null' ? null : category_id,
                            category_group_name: groupedByCategoryId[category_id][0].category_group_name,
                            data: groupedByCategoryId[category_id],
                        };
                    }).sort((a, b) => b.total - a.total).forEach(category => {
                        sortedCategories.push({
                            category_name: category.category_name,
                            category_id: category.category_id,
                            label: `${category.category_name} (${category.data.length})`,
                            value: category.total,
                            data: category.data,
                        });
                    });
                }
                sortedCategories.forEach(row => {
                    var _a;
                    _content.push(React.createElement(semantic_ui_react_1.Popup, { key: `category-${day}-${row.category_id}`, position: 'right center', trigger: React.createElement("div", null,
                            React.createElement(LineItem, { lineItemClass: "clickable", keyClass: "no-label", keyValue: row.label, propertyClass: "no-truncate", propertyValue: (0, format_1.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) *
                                    row.value, _user.primaryCurrency), isNested: row.is_nested, onClick: () => {
                                    showTransactions({
                                        title: row.category_name,
                                        query: Object.assign(Object.assign({ match: 'all', time: 'custom' }, (_category.getName(row.category_id) !==
                                            'Uncategorized'
                                            ? { category: row.category_id }
                                            : { uncategorized: true })), { recurring_exclude: 'any', start_date: Moment()
                                                .year(year)
                                                .month(month - 1)
                                                .date(day)
                                                .format('YYYY-MM-DD'), end_date: Moment()
                                                .year(year)
                                                .month(month - 1)
                                                .date(day)
                                                .format('YYYY-MM-DD') }),
                                        total: (0, format_1.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) *
                                            row.value, _user.primaryCurrency),
                                    });
                                } })), hoverable: true, size: "small", content: React.createElement("div", { style: { minWidth: '220px' } }, (_a = row.data) === null || _a === void 0 ? void 0 :
                            _a.map(tx => {
                                return (React.createElement(LineItem, { inPopup: true, key: `line-up-tx-${tx.id}`, keyClass: "no-label", keyValue: tx.payee, propertyClass: "no-truncate", propertyValue: (0, format_1.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) *
                                        tx.amount, tx.currency) }));
                            }),
                            React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "mini", onClick: () => {
                                    showTransactions({
                                        title: row.category_name,
                                        query: Object.assign(Object.assign({ match: 'all', time: 'custom' }, (_category.getName(row.category_id) !==
                                            'Uncategorized'
                                            ? { category: row.category_id }
                                            : { uncategorized: true })), { start_date: Moment()
                                                .year(year)
                                                .month(month - 1)
                                                .date(day)
                                                .format('YYYY-MM-DD'), end_date: Moment()
                                                .year(year)
                                                .month(month - 1)
                                                .date(day)
                                                .format('YYYY-MM-DD') }),
                                        total: (0, format_1.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) *
                                            row.value, _user.primaryCurrency),
                                    });
                                } },
                                "View",
                                ' ',
                                Moment()
                                    .year(year)
                                    .month(month - 1)
                                    .date(day)
                                    .format(_user.getFormat('month_day')),
                                ' ',
                                row.category_name)) }));
                });
            }
            else if (transactionsDisplay == 'consolidate') {
                if (_TX_EXPENSES.items.length > 0) {
                    _content.push(React.createElement(semantic_ui_react_1.Popup, { key: "expense-transactions-popup", position: 'right center', trigger: React.createElement("div", null,
                            React.createElement(LineItem, { lineItemClass: "clickable", keyClass: "no-label", keyValue: `${_TX_EXPENSES.items.length} Expense${_TX_EXPENSES.items.length > 1 ? 's' : ''}`, propertyClass: "no-truncate", propertyValue: (0, format_1.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) *
                                    _TX_EXPENSES.total, _user.primaryCurrency), onClick: () => {
                                    showTransactions({
                                        title: 'Expenses',
                                        query: Object.assign(Object.assign({ match: 'all', time: 'custom', recurring_exclude: 'any', is_income: false }, (includeExcludeFromTotals
                                            ? {}
                                            : { exclude_from_totals: 'false' })), { start_date: Moment()
                                                .year(year)
                                                .month(month - 1)
                                                .date(day)
                                                .format('YYYY-MM-DD'), end_date: Moment()
                                                .year(year)
                                                .month(month - 1)
                                                .date(day)
                                                .format('YYYY-MM-DD') }),
                                        total: (0, format_1.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) *
                                            _TX_EXPENSES.total, _user.primaryCurrency),
                                    });
                                } })), hoverable: true, size: "small", content: React.createElement("div", { style: { minWidth: '230px' } }, (_c = _TX_EXPENSES === null || _TX_EXPENSES === void 0 ? void 0 : _TX_EXPENSES.items) === null || _c === void 0 ? void 0 :
                            _c.map(tx => {
                                return (React.createElement(LineItem, { inPopup: true, key: `line-up-tx-${tx.id}`, keyClass: "no-label", keyValue: tx.payee, propertyClass: "no-truncate", propertyValue: (0, format_1.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) *
                                        tx.amount, tx.currency) }));
                            }),
                            React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "mini", onClick: () => {
                                    showTransactions({
                                        title: 'Expenses',
                                        query: Object.assign(Object.assign({ match: 'all', time: 'custom', recurring_exclude: 'any', is_income: false }, (includeExcludeFromTotals
                                            ? {}
                                            : { exclude_from_totals: 'false' })), { start_date: Moment()
                                                .year(year)
                                                .month(month - 1)
                                                .date(day)
                                                .format('YYYY-MM-DD'), end_date: Moment()
                                                .year(year)
                                                .month(month - 1)
                                                .date(day)
                                                .format('YYYY-MM-DD') }),
                                        total: (0, format_1.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) *
                                            _TX_EXPENSES.total, _user.primaryCurrency),
                                    });
                                } },
                                "View",
                                ' ',
                                Moment()
                                    .year(year)
                                    .month(month - 1)
                                    .date(day)
                                    .format(_user.getFormat('month_day')),
                                ' ',
                                "expenses")) }));
                }
                if (_TX_INCOME.items.length > 0) {
                    _content.push(React.createElement(semantic_ui_react_1.Popup, { key: "income-transactions-popup", position: 'right center', trigger: React.createElement("div", null,
                            React.createElement(LineItem, { keyClass: "no-label color--green", keyValue: `${_TX_INCOME.items.length} Income${_TX_INCOME.items.length > 1 ? 's' : ''}`, propertyClass: "no-truncate color--green", propertyValue: (0, format_1.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) *
                                    _TX_INCOME.total, _user.primaryCurrency) })), hoverable: true, size: "small", content: React.createElement(React.Fragment, null, (_d = _TX_INCOME === null || _TX_INCOME === void 0 ? void 0 : _TX_INCOME.items) === null || _d === void 0 ? void 0 :
                            _d.map(tx => {
                                return (React.createElement(LineItem, { inPopup: true, key: `line-up-tx-${tx.id}`, keyClass: "no-label", keyValue: tx.payee, propertyClass: "no-truncate", propertyValue: (0, format_1.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) *
                                        tx.amount, tx.currency) }));
                            }),
                            React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "mini", onClick: () => {
                                    showTransactions({
                                        title: 'Income',
                                        query: Object.assign(Object.assign({ match: 'all', time: 'custom', recurring_exclude: 'any', is_income: true }, (includeExcludeFromTotals
                                            ? {}
                                            : { exclude_from_totals: 'false' })), { start_date: Moment()
                                                .year(year)
                                                .month(month - 1)
                                                .date(day)
                                                .format('YYYY-MM-DD'), end_date: Moment()
                                                .year(year)
                                                .month(month - 1)
                                                .date(day)
                                                .format('YYYY-MM-DD') }),
                                        total: (0, format_1.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) *
                                            _TX_INCOME.total, _user.primaryCurrency),
                                    });
                                } },
                                "View",
                                ' ',
                                Moment()
                                    .year(year)
                                    .month(month - 1)
                                    .date(day)
                                    .format(_user.getFormat('month_day')),
                                ' ',
                                "income")) }));
                }
            }
            else if (transactionsDisplay == 'show') {
                ;
                (_e = [..._TX_INCOME.items, ..._TX_EXPENSES.items]) === null || _e === void 0 ? void 0 : _e.map(o => {
                    _content.push(React.createElement(LineItem, { key: `line-tx-${o.id}`, lineItemClass: "calendar-item", keyClass: `no-label ${o.is_income || o.to_base < 0 ? 'color--green' : ''}`, keyValue: o.payee, propertyClass: `no-truncate ${o.is_income || o.to_base < 0 ? 'color--green' : ''}`, propertyValue: (0, format_1.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) * o.amount, o.currency) }));
                });
            }
        }
        const _actualTotal = ((_MATCHED_INCOME === null || _MATCHED_INCOME === void 0 ? void 0 : _MATCHED_INCOME.total) || 0) +
            ((_TX_INCOME === null || _TX_INCOME === void 0 ? void 0 : _TX_INCOME.total) || 0) +
            ((_MATCHED_EXPENSES === null || _MATCHED_EXPENSES === void 0 ? void 0 : _MATCHED_EXPENSES.total) || 0) +
            ((_TX_EXPENSES === null || _TX_EXPENSES === void 0 ? void 0 : _TX_EXPENSES.total) || 0);
        const _grandTotal = _actualTotal +
            ((_UNMATCHED_EXPENSES === null || _UNMATCHED_EXPENSES === void 0 ? void 0 : _UNMATCHED_EXPENSES.total) || 0) +
            ((_UNMATCHED_INCOME === null || _UNMATCHED_INCOME === void 0 ? void 0 : _UNMATCHED_INCOME.total) || 0);
        setGrandTotal((0, format_1.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) * _grandTotal, _user.primaryCurrency));
        setUnmatchedRecurringTotal((0, format_1.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) *
            (_grandTotal - _actualTotal), _user.primaryCurrency));
        setActualTotal((0, format_1.toPrice)((_user.settings['show_debits_as_negative'] ? -1 : 1) * _actualTotal, _user.primaryCurrency));
        setContent(_content.filter(o => !!o));
    }, [
        includeExcludeFromTotals,
        includePendingTransactions,
        includeUnmatchedRecurringInTotals,
        transactionsDisplay,
        categoryGroupDisplay,
        recurringDisplay,
        day,
    ]);
    return (React.createElement(semantic_ui_react_1.Table.Cell, { className: `hover-toggle cell-day ${!day ? 'null-day' : ''} ${isToday ? 'is-today' : ''}` },
        React.createElement("div", { className: "cell-day-container" }, !!day && (React.createElement("div", { className: "cell-day-content" },
            React.createElement("div", { className: "flex--space-between width-100" },
                React.createElement("div", { className: `display-day` }, day),
                React.createElement("div", { className: "display--flex", style: { marginBottom: '-4px' } },
                    React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "mini", trigger: React.createElement("div", { className: "show-on-hover clickable mr-05rem", style: {
                                position: 'relative',
                                top: '-4px',
                            }, onClick: () => {
                                addTransaction(Moment()
                                    .year(year)
                                    .month(month - 1)
                                    .date(day)
                                    .format('YYYY-MM-DD'));
                            } },
                            React.createElement(semantic_ui_react_1.Icon, { name: "add circle", fitted: true })) },
                        "Add new transaction for",
                        ' ',
                        Moment()
                            .year(year)
                            .month(month - 1)
                            .date(day)
                            .format(_user.getFormat('month_day_year'))),
                    React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "mini", trigger: React.createElement("div", { className: "show-on-hover clickable", style: {
                                position: 'relative',
                                top: '-4px',
                            }, onClick: () => {
                                showTransactions({
                                    title: 'Daily Transactions',
                                    query: Object.assign(Object.assign({ match: 'all', time: 'custom' }, (includeExcludeFromTotals
                                        ? {}
                                        : { exclude_from_totals: 'false' })), { start_date: Moment()
                                            .year(year)
                                            .month(month - 1)
                                            .date(day)
                                            .format('YYYY-MM-DD'), end_date: Moment()
                                            .year(year)
                                            .month(month - 1)
                                            .date(day)
                                            .format('YYYY-MM-DD') }),
                                    total: actualTotal,
                                });
                            } },
                            React.createElement(semantic_ui_react_1.Icon, { name: "search plus", fitted: true })) },
                        "View all transactions from",
                        ' ',
                        Moment()
                            .year(year)
                            .month(month - 1)
                            .date(day)
                            .format(_user.getFormat('month_day'))))),
            shouldTruncate ? content.slice(0, 5) : content,
            shouldTruncate &&
                content.length > 5 &&
                seeMore &&
                content.slice(5),
            shouldTruncate && content.length > 5 && (React.createElement("p", { className: "center-align link clickable", onClick: () => {
                    setSeeMore(!seeMore);
                } }, seeMore ? 'Show less' : `See more (${content.length - 5})`))))),
        content.length > 1 && (React.createElement(semantic_ui_react_1.Popup, { disabled: actualTotal == grandTotal, hoverable: true, size: "small", position: "right center", trigger: React.createElement("div", { className: "cell-day-total" },
                React.createElement(LineItem, { lineItemClass: "total", keyClass: "no-label", keyValue: 'Total:', propertyClass: "no-truncate", propertyValue: grandTotal, hideUnderline: true })) },
            React.createElement(LineItem, { inPopup: true, lineItemClass: "total", keyClass: "no-label", keyValue: 'Actual Total:', propertyClass: "no-truncate", propertyValue: actualTotal }),
            React.createElement(LineItem, { inPopup: true, lineItemClass: "total", keyClass: "no-label", keyValue: 'Unmatched Recurring:', propertyClass: "no-truncate", propertyValue: unmatchedRecurringTotal }),
            React.createElement(LineItem, { inPopup: true, lineItemClass: "total", keyClass: "no-label", keyValue: 'Expected Total:', propertyClass: "no-truncate", propertyValue: grandTotal })))));
};
exports.default = Day;
