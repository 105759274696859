"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 *  EditModal.tsx
 *  Recurring expenses edit modal
 */
const React = require("react");
const Moment = require("moment");
const react_router_dom_1 = require("react-router-dom");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const transactions_1 = require("@actions/transactions");
// Types
const format_1 = require("@helpers/format");
const UserProvider_1 = require("@providers/UserProvider");
const BalanceUpdater_1 = require("@components/elements/BalanceUpdater");
const RecurringProvider_1 = require("@/providers/RecurringProvider");
const CreateRecurringTransaction = ({ data, useModal, utils, setVisibility, switchView, }) => {
    const history = (0, react_router_dom_1.useHistory)();
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _recurring = (0, react_1.useContext)(RecurringProvider_1.RecurringContext);
    const [selectedRecurring, setSelectedRecurring] = (0, react_1.useState)({});
    const [balances, setBalances] = (0, react_1.useState)(null);
    const [sortType, setSortType] = (0, react_1.useState)('billing_date');
    const [unmatchedRecurring, setUnmatchedRecurring] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        const fetchUnmatched = () => __awaiter(void 0, void 0, void 0, function* () {
            const results = yield _recurring.getUnmatched(Moment(data.currentPeriod)
                .startOf('month')
                .format('YYYY-MM-DD'), Moment(data.currentPeriod)
                .endOf('month')
                .format('YYYY-MM-DD'));
            setUnmatchedRecurring(results || []);
        });
        fetchUnmatched();
    }, [data]);
    // Setup
    (0, react_1.useEffect)(() => {
        useModal({
            header: (React.createElement("div", null,
                React.createElement("span", { className: "header-text-large" }, "New transaction from recurring item"))),
            middleButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: true, className: "no-border", onClick: () => {
                    setVisibility(false, true);
                } }, "Cancel")),
        });
    }, []);
    (0, react_1.useEffect)(() => {
        if (selectedRecurring && Object.keys(selectedRecurring).length > 0) {
            useModal({
                rightButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        var _a;
                        const recurringIds = [];
                        Object.keys(selectedRecurring).forEach(id => {
                            if (selectedRecurring[id]) {
                                recurringIds.push(id);
                            }
                        });
                        const results = yield utils._process(transactions_1.createFromRecurring)({
                            recurring_ids: recurringIds,
                            date: data.currentPeriod,
                        });
                        if (!results.error) {
                            if (((_a = results.data.asset_update) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                                setBalances(results.data.asset_update);
                            }
                            else {
                                utils._showToast({
                                    message: 'Successfully created transaction',
                                    type: 'success',
                                });
                            }
                            data.amendTransactions({
                                add: results.data.transactions,
                            });
                            setVisibility(false, true);
                        }
                    }) }, "Create")),
            });
        }
    }, [data, selectedRecurring]);
    return unmatchedRecurring == null ? (React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "small" }, "Loading recurring expenses...")) : (React.createElement(React.Fragment, null,
        React.createElement("p", null, "Choose 1 or more recurring items to create a linked transaction. Linked transactions will match the recurring item's details such as amount and payee and will denote that payment was made this month for the recurring item."),
        React.createElement("p", null,
            "Note: suggested recurring items will not be listed here. Go to the",
            ' ',
            React.createElement(react_router_dom_1.Link, { className: "link", to: { pathname: '/recurring' } }, "Recurring Items"),
            ' ',
            "page and click the green 'Add' button to see it here."),
        unmatchedRecurring.length > 0 ? (React.createElement(semantic_ui_react_1.Button, { size: "mini", className: "mb-1rem", onClick: () => {
                setSortType(sortType == 'billing_date' ? 'name' : 'billing_date');
            } },
            "Sort by",
            ' ',
            sortType == 'billing_date' ? 'merchant name' : 'billing date')) : (React.createElement(React.Fragment, null,
            React.createElement("b", null, "No recurring expenses found."),
            React.createElement(semantic_ui_react_1.Button, { size: "mini", className: "mt-1rem", onClick: () => {
                    history.push('/recurring');
                } }, "Go to recurring expenses page"))), unmatchedRecurring === null || unmatchedRecurring === void 0 ? void 0 :
        unmatchedRecurring.sort((a, b) => {
            if (sortType == 'name') {
                return a.payee
                    .trim()
                    .toLowerCase()
                    .localeCompare(b.payee.toLowerCase().trim());
            }
            else {
                return Moment(a.billing_date).date() < Moment(b.billing_date).date()
                    ? -1
                    : 1;
            }
        }).map((rec, index) => {
            return (React.createElement("div", { key: `link-recurring-${index}`, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setSelectedRecurring(Object.assign(Object.assign({}, selectedRecurring), { [rec.id]: !selectedRecurring[rec.id] }));
                }), className: `link-recurring-row ${selectedRecurring[rec.id] ? 'selected' : ''}` },
                React.createElement("span", null,
                    rec.payee,
                    ":",
                    ' ',
                    (0, format_1.toPrice)(_user.settings['show_debits_as_negative']
                        ? rec.amount * -1
                        : rec.amount, rec.currency),
                    ' ',
                    "(",
                    rec.cadence == 'custom'
                        ? `Every ${rec.quantity} ${rec.granularity}${rec.quantity > 1 ? 's' : ''}`
                        : rec.cadence,
                    ")",
                    React.createElement("br", null),
                    "Expected on",
                    ' ',
                    Moment(rec.billing_date)
                        .month(Moment(data.currentPeriod).month())
                        .format(_user.getMonthDayFormat())),
                selectedRecurring[rec.id] ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))));
        }),
        React.createElement(BalanceUpdater_1.default, { _showToast: utils._showToast, balances: balances })));
};
exports.default = CreateRecurringTransaction;
