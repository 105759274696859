"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const EditablePrice_1 = require("@components/elements/EditablePrice");
const EditableString_1 = require("@components/elements/EditableString");
const react_1 = require("react");
const options_1 = require("@helpers/options");
const UserProvider_1 = require("@providers/UserProvider");
const CategoriesProvider_1 = require("@providers/CategoriesProvider");
const RuleDetails = ({ expense, setExpense }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const [isFluctuating, setIsFluctuating] = (0, react_1.useState)(false);
    const [showAdvanced, setShowAdvanced] = (0, react_1.useState)(false);
    const [category, setCategory] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        const override = {};
        if (expense.criteria_name == null) {
            override['criteria_name'] = expense.original_name || expense.payee;
        }
        if (expense.category_id) {
            const _category = _categories.get(expense.category_id);
            setCategory(_category);
            override['amount_type'] = _category.is_income ? 'credits' : 'expenses';
        }
        override['amount_match'] = 'exactly';
        setExpense(Object.assign(Object.assign({}, expense), override));
    }, []);
    return (React.createElement(React.Fragment, null,
        category && _user.settings['allow_recurring_categories'] && (React.createElement(semantic_ui_react_1.Message, { info: true },
            "According to the category, ",
            React.createElement("b", null, category.name),
            ", this is a recurring",
            ' ',
            category.is_income ? 'income' : 'expense',
            ".")),
        !category && !_user.settings['allow_recurring_categories'] && (React.createElement(React.Fragment, null,
            React.createElement("h3", null, "Is this a recurring debit or recurring credit?"),
            React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setExpense(Object.assign(Object.assign({}, expense), { amount_type: 'expenses' }));
                }), className: `link-recurring-row ${expense.amount_type == 'expenses' ? 'selected' : ''}` },
                React.createElement("div", { className: "width-100" },
                    React.createElement("div", { className: "flex--space-between-flex-start" },
                        React.createElement("span", null, "This is a recurring debit/expense"),
                        expense.amount_type == 'expenses' ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))))),
            React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setExpense(Object.assign(Object.assign({}, expense), { amount_type: 'credits' }));
                }), className: `link-recurring-row ${expense.amount_type == 'credits' ? 'selected' : ''}` },
                React.createElement("div", { className: "width-100" },
                    React.createElement("div", { className: "flex--space-between-flex-start" },
                        React.createElement("span", null, "This is a recurring credit/income"),
                        expense.amount_type == 'credits' ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))))))),
        expense.amount_type && (React.createElement(React.Fragment, null,
            React.createElement("h3", null,
                "Is the amount of this recurring",
                ' ',
                expense.amount_type == 'expenses' ? 'expense' : 'income',
                ' ',
                "fluctuating (i.e. usage-based utility bill) or fixed (i.e. same monthly price)?"),
            React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setIsFluctuating(true);
                    setExpense(Object.assign(Object.assign({}, expense), { amount_match: 'between' }));
                }), className: `link-recurring-row ${isFluctuating ? 'selected' : ''}` },
                React.createElement("div", { className: "width-100" },
                    React.createElement("div", { className: "flex--space-between-flex-start" },
                        React.createElement("span", null,
                            "How much I",
                            ' ',
                            expense.amount_type == 'expenses' ? 'pay' : 'receive',
                            ' ',
                            "fluctuates ",
                            isFluctuating ? 'between' : ''),
                        isFluctuating ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))),
                    isFluctuating && (React.createElement("div", { className: "flex--align-center mt-05rem" },
                        React.createElement(EditablePrice_1.default, { identifier: "criteria-amount", amount: expense.amount || undefined, currency: expense.currency, location: 'modal', autoFocus: true, state: 'Editing', saveOnChange: true, onSave: (amount, currency) => __awaiter(void 0, void 0, void 0, function* () {
                                setExpense(Object.assign(Object.assign({}, expense), { amount, currency }));
                            }) }),
                        React.createElement("span", { className: "mr-05rem ml-05rem" }, "&"),
                        React.createElement(EditablePrice_1.default, { identifier: `criteria-amount-2`, amount: expense.amount_2 || undefined, currency: expense.currency, location: 'modal', state: 'Editing', saveOnChange: true, onSave: (amount_2, currency) => __awaiter(void 0, void 0, void 0, function* () {
                                setExpense(Object.assign(Object.assign({}, expense), { amount_2, currency }));
                            }) }))))),
            React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setIsFluctuating(false);
                    setExpense(Object.assign(Object.assign({}, expense), { amount_match: 'exactly' }));
                }), className: `link-recurring-row ${isFluctuating == false ? 'selected' : ''}` },
                React.createElement("div", { className: "width-100" },
                    React.createElement("div", { className: "flex--space-between-flex-start" },
                        React.createElement("span", null,
                            "How much I",
                            ' ',
                            expense.amount_type == 'expenses' ? 'pay' : 'receive',
                            " is fixed ",
                            isFluctuating == false ? 'at' : ''),
                        isFluctuating == false ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))),
                    isFluctuating == false && (React.createElement("div", { className: "mt-05rem" },
                        React.createElement(EditablePrice_1.default, { identifier: "criteria-amount", amount: expense.amount || undefined, currency: expense.currency, location: 'modal', autoFocus: true, state: 'Editing', saveOnChange: true, onSave: (amount, currency) => __awaiter(void 0, void 0, void 0, function* () {
                                setExpense(Object.assign(Object.assign({}, expense), { amount, currency }));
                            }) }))))),
            React.createElement("div", { className: "mt-2rem" },
                React.createElement("p", { className: "link clickable", onClick: () => {
                        setShowAdvanced(!showAdvanced);
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: showAdvanced ? 'caret down' : 'caret right' }),
                    " View advanced settings")),
            showAdvanced && (React.createElement(React.Fragment, null,
                !_user.settings['viewed_recurring_rule_details'] && (React.createElement(semantic_ui_react_1.Message, { info: true },
                    React.createElement("p", null,
                        "When you use automatic bank syncing or CSV importing, Lunch Money will attempt to automatically match transactions to this recurring",
                        ' ',
                        expense.amount_type == 'expenses' ? 'expense' : 'income',
                        "."),
                    React.createElement("p", null,
                        "Here, we are setting up the ",
                        React.createElement("b", null, "rule"),
                        " that will help match the right transactions."),
                    React.createElement("p", null,
                        React.createElement("a", { href: "https://support.lunchmoney.app/finances/recurring-expenses#integration-with-rules", target: "_blank", className: "link clickable" },
                            React.createElement(semantic_ui_react_1.Icon, { name: "external" }),
                            "Learn more about recurring items and rules"),
                        React.createElement("br", null),
                        React.createElement("a", { href: "https://support.lunchmoney.app/setup/rules", target: "_blank", className: "link clickable" },
                            React.createElement(semantic_ui_react_1.Icon, { name: "external" }),
                            "Learn more about rules")))),
                React.createElement("div", { className: "transaction-details" },
                    React.createElement("div", { className: "transaction-detail" },
                        React.createElement("label", null,
                            "Link to recurring",
                            ' ',
                            expense.amount_type == 'expenses' ? 'expense' : 'income',
                            ' ',
                            "where payee name",
                            ' ',
                            React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "On import, how will this transaction show up as the payee name? You can use different matching options to capture if the payee name changes slightly every time.")),
                        React.createElement(semantic_ui_react_1.Form.Group, null,
                            React.createElement(semantic_ui_react_1.Form.Field, { className: "width-100" },
                                React.createElement(semantic_ui_react_1.Dropdown, { className: "p-match-inline", inline: true, options: options_1.STRING_MATCH_TYPES, defaultValue: expense.criteria_name_match || 'contain', onChange: (e, { value }) => {
                                        setExpense(Object.assign(Object.assign({}, expense), { criteria_name_match: value }));
                                    } }),
                                React.createElement(EditableString_1.default, { identifier: `filter-name`, placeholder: "Starbucks", firstValue: expense.criteria_name, location: 'modal', state: 'Editing', shouldSaveOnChange: true, onSave: (name) => __awaiter(void 0, void 0, void 0, function* () {
                                        setExpense(Object.assign(Object.assign({}, expense), { criteria_name: name }));
                                    }) })))))))))));
};
exports.default = RuleDetails;
