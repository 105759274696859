"use strict";
/**
 *  Footer.tsx
 *  Global footer
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const inEU = require("@segment/in-eu");
const gdpr_1 = require("@actions/gdpr");
const Auth = require("@actions/auth");
const ConsentBanner = ({ show, setParentShow }) => {
    const [settings, setSettings] = (0, react_1.useState)({
        'lunch-money': true,
        stripe: true,
        mixpanel: true,
    });
    const [affected, setAffected] = (0, react_1.useState)(3);
    const [showModal, setShowModal] = (0, react_1.useState)(false);
    const [showBanner, setShowBanner] = (0, react_1.useState)(false);
    const [hasFetched, setHasFetched] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        if (!show)
            return;
        const fetchSettings = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const results = yield (0, gdpr_1.getGDPRSettings)();
                const currentSettings = {
                    'lunch-money': true,
                    stripe: true,
                    mixpanel: true,
                };
                results.forEach(cookie => {
                    currentSettings[cookie] = true;
                });
                setHasFetched(true);
                setSettings(currentSettings);
                const deactivated = [];
                Object.keys(currentSettings).forEach(cookie => {
                    if (currentSettings[cookie] == false) {
                        deactivated.push(cookie);
                    }
                });
                localStorage.setItem('_lm_consent_disallow', deactivated.join(','));
            }
            catch (e) { }
        });
        if (!hasFetched) {
            fetchSettings();
        }
        setShowBanner(show);
    }, [show]);
    (0, react_1.useEffect)(() => {
        const fetchSettings = () => __awaiter(void 0, void 0, void 0, function* () {
            const results = yield (0, gdpr_1.getGDPRSettings)();
            if (results.length === 0) {
                if (inEU()) {
                    // Show banner
                    setShowBanner(true);
                }
            }
            else {
                const currentSettings = {
                    'lunch-money': true,
                    stripe: true,
                    mixpanel: false,
                };
                setShowBanner(false);
                setParentShow(false);
                results === null || results === void 0 ? void 0 : results.forEach(cookie => {
                    currentSettings[cookie] = true;
                });
                setHasFetched(true);
                setSettings(currentSettings);
                const deactivated = [];
                Object.keys(currentSettings).forEach(cookie => {
                    if (currentSettings[cookie] == false) {
                        deactivated.push(cookie);
                    }
                });
                localStorage.setItem('_lm_consent_disallow', deactivated.join(','));
            }
        });
        // Only fetch if logged in and on authed page
        if (!hasFetched && Auth.isAuthenticatedPage()) {
            fetchSettings();
        }
    }, []);
    (0, react_1.useEffect)(() => {
        let count = 0;
        Object.values(settings).forEach(setting => {
            if (setting)
                count++;
        });
        setAffected(count);
    }, [settings]);
    const accept = (all) => __awaiter(void 0, void 0, void 0, function* () {
        const acceptedCookies = [];
        const deactivated = [];
        Object.keys(settings).forEach(setting => {
            if (settings[setting] || all) {
                acceptedCookies.push(setting);
            }
            else {
                deactivated.push(setting);
            }
        });
        yield (0, gdpr_1.setGDPRSettings)(acceptedCookies);
        setShowModal(false);
        setShowBanner(false);
        setParentShow(false);
        localStorage.setItem('_lm_consent_disallow', deactivated.join(','));
        // Refresh window
        document.location.reload();
    });
    return showBanner ? (React.createElement("div", { id: "consent-banner", className: showBanner ? 'show' : '' },
        React.createElement(semantic_ui_react_1.Container, null,
            "Hello \uD83D\uDC4B Lunch Money uses cookies to improve your experience on our site.",
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Modal, { trigger: React.createElement(semantic_ui_react_1.Button, { size: "mini", basic: true, color: "orange", onClick: () => {
                            setShowModal(true);
                        } }, "Tell me more"), open: showModal },
                    React.createElement(semantic_ui_react_1.Modal.Header, null, "Data Collection Settings"),
                    React.createElement(semantic_ui_react_1.Modal.Content, null,
                        React.createElement(semantic_ui_react_1.Modal.Description, null,
                            React.createElement("p", null,
                                "Lunch Money uses data collected by cookies and JavaScript libraries to improve your browsing experience and analyze site traffic. By using Lunch Money, you\u2019re agreeing to our",
                                ' ',
                                React.createElement("a", { href: "https://lunchmoney.app/privacy" }, "Privacy Policy"),
                                "."),
                            React.createElement(semantic_ui_react_1.Table, { id: "consent-table", unstackable: true },
                                React.createElement(semantic_ui_react_1.Table.Header, null,
                                    React.createElement(semantic_ui_react_1.Table.Row, null,
                                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "cell-type" }, "Type"),
                                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "cell-cookie" }, "Provider"),
                                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "cell-description" }, "Description"),
                                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "cell-setting" }, "Setting"))),
                                React.createElement(semantic_ui_react_1.Table.Body, null,
                                    React.createElement(semantic_ui_react_1.Table.Row, { verticalAlign: "top" },
                                        React.createElement(semantic_ui_react_1.Table.Cell, null, "Strictly Necessary"),
                                        React.createElement(semantic_ui_react_1.Table.Cell, null, "Lunch Money"),
                                        React.createElement(semantic_ui_react_1.Table.Cell, null, "We use cookies to save your login session and your 'Remember my device' setting if two-factor authentication is enabled."),
                                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                                            React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: settings['lunch-money'], disabled: true }))),
                                    React.createElement(semantic_ui_react_1.Table.Row, { verticalAlign: "top" },
                                        React.createElement(semantic_ui_react_1.Table.Cell, null, "Strictly Necessary"),
                                        React.createElement(semantic_ui_react_1.Table.Cell, null, "Stripe"),
                                        React.createElement(semantic_ui_react_1.Table.Cell, null, "Stripe is a billing service trusted by many web and mobile applications. We use Stripe to process billing subscriptions."),
                                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                                            React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: settings['stripe'], disabled: true }))),
                                    React.createElement(semantic_ui_react_1.Table.Row, { verticalAlign: "top" },
                                        React.createElement(semantic_ui_react_1.Table.Cell, null, "Analytics"),
                                        React.createElement(semantic_ui_react_1.Table.Cell, null, "Mixpanel"),
                                        React.createElement(semantic_ui_react_1.Table.Cell, null, "We use Mixpanel to track anonymized and aggregated user behavior on our site."),
                                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                                            React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: settings['mixpanel'], onChange: (e, { checked }) => {
                                                    setSettings(Object.assign(Object.assign({}, settings), { mixpanel: checked }));
                                                } }))))))),
                    React.createElement(semantic_ui_react_1.Modal.Actions, null,
                        affected < 3 && (React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                                accept(false);
                            } },
                            "Allow ",
                            affected,
                            " out of 3")),
                        React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                                accept(true);
                            }, primary: true }, "Allow All"))),
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        accept(true);
                    }, className: "ml-05rem", size: "mini" }, "Accept"))))) : (React.createElement(React.Fragment, null));
};
exports.default = ConsentBanner;
