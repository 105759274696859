"use strict";
/**
 *  EditableCategory.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
// Components
const semantic_ui_react_1 = require("semantic-ui-react");
// Helpers
const categories_1 = require("@actions/categories");
const checks_1 = require("@helpers/checks");
const GlobalProvider_1 = require("@providers/GlobalProvider");
var State;
(function (State) {
    /** Default display */
    State["Default"] = "Default";
    /** Is currently being edited */
    State["Editing"] = "Editing";
    /** Is read only; not editable */
    State["ReadOnly"] = "ReadOnly";
})(State || (State = {}));
const EditableCategory = ({ identifier, firstValue, state, location, className = '', containerClassName = '', disabled = false, onSave, placeholder = null, autoFocus = false, showError = false, allowAdditions = true, allowSelectingGroups = false, showUncategorized = false, closeOnEscape = true, extraValues = [], hideArchived = true, onShowConfirmation = () => { }, }) => {
    var _a;
    const _global = (0, react_1.useContext)(GlobalProvider_1.GlobalContext);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const [currentState, setCurrentState] = (0, react_1.useState)(!!state ? State[state] : State.Default);
    const [currentValue, setCurrentValue] = (0, react_1.useState)(firstValue);
    const [currentName, setCurrentName] = (0, react_1.useState)(undefined);
    const [hasNoValue, setHasNoValue] = (0, react_1.useState)((0, checks_1.isNull)(firstValue) || (0, checks_1.isEmptyString)(firstValue));
    const [dropdownOpen, setDropdownOpen] = (0, react_1.useState)(autoFocus);
    const [inputLocation, setInputLocation] = (0, react_1.useState)(location);
    const [inputId, setInputId] = (0, react_1.useState)(identifier);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [searchQuery, setSearchQuery] = (0, react_1.useState)('');
    const [showConfirmation, setShowConfirmation] = (0, react_1.useState)(false);
    const [newCategoryName, setNewCategoryName] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        if (inputId !== identifier) {
            // When identifier changes, update state
            setInputLocation(location);
            setInputId(identifier);
            // setCategories(options);
            setCurrentState(!!state ? State[state] : State.Default);
            setCurrentValue(firstValue || null);
            setHasNoValue((0, checks_1.isNull)(firstValue) || (0, checks_1.isEmptyString)(firstValue));
        }
    }, [identifier]);
    (0, react_1.useEffect)(() => {
        setCurrentState(state);
    }, [state]);
    // Something externally can update the value
    (0, react_1.useEffect)(() => {
        setCurrentValue(firstValue || null);
        if (firstValue && !isNaN(firstValue)) {
            setCurrentName(firstValue ? _categories.getName(firstValue) : undefined);
        }
        setHasNoValue((0, checks_1.isNull)(firstValue) || (0, checks_1.isEmptyString)(firstValue));
    }, [firstValue]);
    (0, react_1.useEffect)(() => {
        // Autofocus hack, only on first try
        if (autoFocus &&
            inputId &&
            currentState == State.Editing &&
            // If it's a new transaction row, only autofocus if its current value is
            // null. Other it screws up when tabbing
            (inputLocation !== 'inline-new' || currentValue == null)) {
            setDropdownOpen(true);
            setTimeout(() => {
                if (document.getElementById(inputId) &&
                    document.getElementById(inputId).getElementsByTagName('input')) {
                    document
                        .getElementById(inputId)
                        .getElementsByTagName('input')[0]
                        .focus();
                    document
                        .getElementById(inputId)
                        .getElementsByTagName('input')[0].spellcheck = false;
                }
            }, 100);
        }
        else if (currentState == State.Editing) {
            setTimeout(() => {
                if (document.getElementById(inputId) &&
                    document.getElementById(inputId).getElementsByTagName('input')) {
                    document
                        .getElementById(inputId)
                        .getElementsByTagName('input')[0].spellcheck = false;
                }
            }, 100);
        }
    }, [autoFocus, inputId, currentState]);
    const createNewCategory = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const value = newCategoryName;
        const results = yield (0, categories_1.createCategory)({
            name: value,
            is_income: false,
            exclude_from_budget: false,
            exclude_from_totals: false,
        });
        if (!results.error) {
            // Add to context
            _categories.add(results.data);
            // Set to category
            setSearchQuery('');
            setNewCategoryName(null);
            setDropdownOpen(false);
            setCurrentValue(Number(results.data.id));
            setCurrentName(results.data.name);
            setCurrentState(inputLocation.indexOf('modal') > -1 || inputLocation == 'inline-new'
                ? State.Editing
                : State.Default);
            // Save value
            yield onSave(Number(results.data.id));
            // Go to next
        }
        else {
            _global.showToast({
                message: results.error.message,
                type: 'error',
            });
        }
        setIsLoading(false);
    });
    const handleChange = (category_id, category_name) => __awaiter(void 0, void 0, void 0, function* () {
        // Save value first
        // if (results !== false) {
        setCurrentValue(Number(category_id) || category_id);
        setCurrentName(category_name);
        // }
        // setDropdownOpen(false)
        setCurrentState(inputLocation.indexOf('modal') > -1 ||
            inputLocation == 'inline-new' ||
            inputLocation == 'filter-row'
            ? State.Editing
            : State.Default);
        onSave(Number(category_id) || category_id);
        setSearchQuery('');
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `${inputLocation == 'filter-row' ? '' : 'g-editable-text'} ${currentState === State.Editing && inputLocation.indexOf('inline') > -1
                ? 'selected'
                : ''} ${containerClassName}` },
            currentState == State.ReadOnly && (React.createElement("div", { className: `readonly ${location === 'inline' ? '' : 'no-padding'}` }, currentName || 'Uncategorized')),
            currentState === State.Default && (React.createElement("div", { className: `default-state ${!currentValue &&
                    'empty-state color--red'}`, onMouseLeave: () => {
                    setCurrentState(State.Default);
                }, onClick: () => {
                    setCurrentState(State.Editing);
                } }, currentValue ? (currentName) : (React.createElement(React.Fragment, null,
                React.createElement(semantic_ui_react_1.Icon, { name: "exclamation triangle", className: "color--red", style: { position: 'relative', top: '-2px' } }),
                ' ',
                "Uncategorized")))),
            currentState === State.Editing && (React.createElement(semantic_ui_react_1.Dropdown, { value: currentValue, autoComplete: 'false', loading: isLoading, id: inputId, fluid: inputLocation == 'filter-row', className: `category-dropdown p-category-${inputLocation} ${className}`, placeholder: placeholder || 'Search or add new', text: currentName, search: (options, query) => {
                    const results = [];
                    options.forEach(option => {
                        var _a, _b;
                        const category = _categories.get(option.value);
                        if (category) {
                            if ((allowSelectingGroups || !category.is_group) &&
                                (((_a = category.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().indexOf(query.toLowerCase())) >
                                    -1 ||
                                    ((_b = category.group_category_name) === null || _b === void 0 ? void 0 : _b.toLowerCase().indexOf(query.toLowerCase())) > -1)) {
                                option.text = category.name;
                                if (category.group_id) {
                                    option.content = (React.createElement("span", { className: "text" },
                                        category.name,
                                        " (",
                                        category.group_category_name,
                                        ")"));
                                }
                                results.push(option);
                            }
                        }
                    });
                    return results.sort((a, b) => {
                        return a.text.localeCompare(b.text);
                    });
                }, disabled: disabled, allowAdditions: allowAdditions, additionPosition: 'bottom', onAddItem: (e_1, _b) => __awaiter(void 0, [e_1, _b], void 0, function* (e, { value }) {
                    e.preventDefault();
                    e.stopPropagation();
                    setNewCategoryName(value);
                    onShowConfirmation();
                    setShowConfirmation(true);
                }), selection: inputLocation === 'modal' || inputLocation === 'filter-row', searchQuery: searchQuery, onSearchChange: (e, { searchQuery }) => {
                    setSearchQuery(searchQuery);
                }, options: (showUncategorized
                    ? [
                        {
                            key: 'category-uncategorized',
                            text: 'Uncategorized',
                            value: null,
                            active: currentValue == null,
                            content: (React.createElement("div", { className: "display--flex" },
                                React.createElement("span", { className: "text" }, "Uncategorized"))),
                            onClick: (e_2, _c) => __awaiter(void 0, [e_2, _c], void 0, function* (e, { value, text }) {
                                // Save value first
                                const results = yield onSave(null);
                                if (results !== false) {
                                    setCurrentValue(null);
                                    setCurrentName('Uncategorized');
                                }
                                setDropdownOpen(false);
                                setCurrentState(inputLocation.indexOf('modal') > -1 ||
                                    inputLocation == 'inline-new' ||
                                    inputLocation == 'filter-row'
                                    ? State.Editing
                                    : State.Default);
                                setSearchQuery('');
                            }),
                        },
                    ]
                    : [])
                    .concat(extraValues.map(extraValue => {
                    return Object.assign(Object.assign({}, extraValue), { onClick: (e_3, _a) => __awaiter(void 0, [e_3, _a], void 0, function* (e, { value, text }) {
                            setCurrentValue(Number(value) || value);
                            // Temp because somehow text doesn't return the new text..
                            setCurrentName(text);
                            setCurrentState(inputLocation.indexOf('modal') > -1 ||
                                inputLocation === 'inline-new' ||
                                inputLocation === 'filter-row'
                                ? State.Editing
                                : State.Default);
                            if (value == 'new' ||
                                !isNaN(Number(value)) ||
                                !allowAdditions) {
                                // Don't save if this was "Entered" from onAddItem
                                onSave(Number(value) || value);
                                setSearchQuery('');
                            }
                        }) });
                }))
                    .concat((_a = _categories.flattened) === null || _a === void 0 ? void 0 : _a.filter(o => {
                    var _a;
                    return !(hideArchived && o.archived) &&
                        (!o.is_group ||
                            ((_a = o.children) === null || _a === void 0 ? void 0 : _a.filter(o => !(hideArchived && o.archived)).length) > 0);
                }).map(category => {
                    return {
                        key: `category-${category.id}`,
                        value: category.id,
                        active: category.id == currentValue,
                        disabled: allowSelectingGroups
                            ? false
                            : category.is_group,
                        content: category.is_group ? (React.createElement("div", { className: "display--flex" },
                            React.createElement(semantic_ui_react_1.Icon, { name: "table", fitted: true }),
                            React.createElement("span", { className: "ml-05rem text" },
                                category.name,
                                category.archived ? ' (archived)' : ''))) : category.group_id ? (React.createElement("div", { className: "display--flex" },
                            React.createElement("span", { className: `${inputLocation === 'modal' ? 'in-modal' : ''} hierarchy-line-icon-1` }),
                            React.createElement("span", { className: "text" },
                                category.name,
                                category.archived ? ' (archived)' : ''))) : (React.createElement("span", { className: "text" },
                            category.name,
                            category.archived ? ' (archived)' : '')),
                        className: category.is_group
                            ? 'dropdown-category-group'
                            : '',
                        onClick: (e_3, _a) => __awaiter(void 0, [e_3, _a], void 0, function* (e, { value, text }) {
                            if (allowSelectingGroups || !category.is_group) {
                                yield handleChange(value, text);
                                // // Save value first
                                // const results = await onSave(Number(value) || value)
                                // if (results !== false) {
                                //   setCurrentValue(Number(value) || value)
                                //   setCurrentName(text)
                                // }
                                // setDropdownOpen(false)
                                // setCurrentState(
                                //   inputLocation.indexOf('modal') > -1 ||
                                //     inputLocation == 'inline-new' ||
                                //     inputLocation == 'filter-row'
                                //     ? State.Editing
                                //     : State.Default,
                                // )
                                // setSearchQuery('')
                            }
                        }),
                        text: `${category.name}${category.archived ? ' (archived)' : ''}`,
                    };
                })), error: showError, closeOnChange: true, closeOnEscape: closeOnEscape, open: dropdownOpen, onFocus: () => {
                    setDropdownOpen(true);
                }, selectOnNavigation: false, onClick: (e, b) => {
                    setDropdownOpen(true);
                }, onBlur: () => {
                    setDropdownOpen(false);
                    setCurrentState(inputLocation.indexOf('modal') > -1 ||
                        inputLocation === 'inline-new' ||
                        inputLocation === 'filter-row'
                        ? State.Editing
                        : State.Default);
                }, onChange: (e, obj) => __awaiter(void 0, void 0, void 0, function* () {
                    // Click type is handled by "onclick"
                    if (e.type == 'keydown') {
                        setCurrentValue(Number(obj.value) || obj.value);
                        // Temp because somehow obj.text doesn't return the new text..
                        const match = obj.options.find(o => {
                            return o.value == Number(obj.value);
                        }) || extraValues.find(o => o.value == obj.value);
                        setCurrentName(match ? match.text : obj.text);
                        setCurrentState(inputLocation.indexOf('modal') > -1 ||
                            inputLocation === 'inline-new' ||
                            inputLocation === 'filter-row'
                            ? State.Editing
                            : State.Default);
                        if (!isNaN(Number(obj.value)) || !allowAdditions) {
                            // Don't save if this was "Entered" from onAddItem
                            onSave(Number(obj.value) || obj.value);
                            setSearchQuery('');
                        }
                    }
                }), onClose: () => {
                    setDropdownOpen(false);
                    setCurrentState(inputLocation.indexOf('modal') > -1 ||
                        inputLocation === 'inline-new' ||
                        inputLocation === 'filter-row'
                        ? State.Editing
                        : State.Default);
                } }))),
        React.createElement(semantic_ui_react_1.Modal, { open: showConfirmation, size: 'tiny' },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Confirm new category"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                "Are you sure you want to create the new category: ",
                newCategoryName),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { disabled: isLoading, loading: isLoading, onClick: () => {
                        setSearchQuery('');
                        setNewCategoryName(null);
                        setShowConfirmation(false);
                    } }, "No, cancel"),
                React.createElement(semantic_ui_react_1.Button, { disabled: isLoading, loading: isLoading, color: "green", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield createNewCategory();
                        setShowConfirmation(false);
                    }) }, "Yes, create")))));
};
exports.default = EditableCategory;
