"use strict";
/**
 *  EditableCategory.tsx
 */
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const react_1 = require("react");
// Components
const semantic_ui_react_1 = require("semantic-ui-react");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const UserProvider_1 = require("@/providers/UserProvider");
const AssetSelection = ({ value: string, onSelect }) => {
    var _a, _b, _c, _d;
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [currentValue, setCurrentValue] = (0, react_1.useState)('');
    return (React.createElement(semantic_ui_react_1.Dropdown, { className: "selection category-dropdown p-category-modal", placeholder: `Select account to merge with`, fluid: true, text: _assets.getAccountName(currentValue), value: currentValue },
        React.createElement(semantic_ui_react_1.Dropdown.Menu, null,
            ((_a = _assets.plaidAccounts) === null || _a === void 0 ? void 0 : _a.length) > 0 && (React.createElement(semantic_ui_react_1.Dropdown.Header, { content: "Synced accounts" })), (_b = _assets.plaidAccounts
            .filter(o => o.status !== 'deleted')) === null || _b === void 0 ? void 0 :
            _b.sort((a, b) => {
                return a.institution_id - b.institution_id;
            }).map(o => {
                return (React.createElement(semantic_ui_react_1.Dropdown.Item, { active: currentValue == `plaid_${o.id}`, key: `plaid_${o.id}`, value: `${o.source}_${o.id}`, onClick: () => {
                        setCurrentValue(`plaid_${o.id}`);
                        onSelect(`plaid_${o.id}`);
                    } },
                    React.createElement("p", { className: "mb-0" },
                        o.display_name || `${o.institution_name} ${o.name}`,
                        ' ',
                        o.mask && React.createElement(React.Fragment, null,
                            "(",
                            o.mask,
                            ")"),
                        " - ",
                        o.status),
                    React.createElement("p", { className: "font-size--smaller" },
                        "Linked by ",
                        o.linked_by_name,
                        " on",
                        ' ',
                        Moment(o.date).format(_user.getMonthDayYearFormat()))));
            }),
            ((_c = _assets.assets) === null || _c === void 0 ? void 0 : _c.filter(o => !!o.id).length) > 0 && (React.createElement(semantic_ui_react_1.Dropdown.Header, { content: "Manually-managed accounts" })), (_d = _assets.assets) === null || _d === void 0 ? void 0 :
            _d.filter(o => !!o.id).map(o => {
                return (React.createElement(semantic_ui_react_1.Dropdown.Item, { active: currentValue == `manual_${o.id}`, key: o.id, value: `${o.source}_${o.id}`, onClick: () => {
                        setCurrentValue(`manual_${o.id}`);
                        onSelect(`manual_${o.id}`);
                    } },
                    React.createElement("p", { className: "mb-0" },
                        o.display_name || `${o.institution_name} ${o.name}`,
                        ' ',
                        o.mask && React.createElement(React.Fragment, null,
                            "(",
                            o.mask,
                            ")"),
                        " - ",
                        o.status),
                    React.createElement("p", { className: "font-size--smaller" },
                        "Created by ",
                        o.linked_by_name,
                        " on",
                        ' ',
                        Moment(o.date).format(_user.getMonthDayYearFormat()))));
            }))));
};
exports.default = AssetSelection;
