"use strict";
/**
 *  Transactions.tsx
 *  Container for the Transactions page
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
// Semantic UI
const semantic_ui_react_1 = require("semantic-ui-react");
const user_1 = require("@actions/user");
class Unsubscribe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonLoading: false,
            doneUnsubscribe: false,
            errorMessage: '',
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            // Page title
            document.title = `Unsubscribe - Lunch Money`;
        });
    }
    unsubscribe() {
        return __awaiter(this, void 0, void 0, function* () {
            const results = yield (0, user_1.updateNotificationSettings)({
                token: this.props.match.params.unsubscribe_token,
                email: false,
            });
            if (results.error) {
                this.setState({
                    buttonLoading: false,
                    doneUnsubscribe: false,
                    errorMessage: results.error.message,
                });
            }
            else {
                this.setState({ buttonLoading: false, doneUnsubscribe: true });
            }
        });
    }
    resubscribe() {
        return __awaiter(this, void 0, void 0, function* () {
            yield (0, user_1.updateNotificationSettings)({
                token: this.props.match.params.unsubscribe_token,
                email: true,
            });
            this.setState({ buttonLoading: false, doneUnsubscribe: false });
        });
    }
    render() {
        return (React.createElement("div", { className: "g-logged-out" },
            React.createElement("div", { className: "g-logged-out-container" },
                React.createElement("div", { className: "logged-out-content" },
                    React.createElement(semantic_ui_react_1.Container, null,
                        React.createElement("div", { id: "logo-container" },
                            React.createElement("div", { id: "logo" }),
                            React.createElement("div", { id: "shadow" })),
                        React.createElement(semantic_ui_react_1.Header, { as: "h2", className: "center-align" }, "Are you sure you no longer want to receive this type of email?"),
                        React.createElement("div", { className: "center-align" },
                            this.state.doneUnsubscribe && !this.state.errorMessage && (React.createElement(React.Fragment, null,
                                React.createElement("p", null, "You've been unsubscribed successfully."),
                                React.createElement("p", { onClick: () => {
                                        this.resubscribe();
                                    }, className: "clickable link" }, "I want to resubscribe."))),
                            !this.state.doneUnsubscribe && !this.state.errorMessage && (React.createElement(semantic_ui_react_1.Button, { loading: this.state.buttonLoading, onClick: () => {
                                    this.setState({ buttonLoading: true });
                                    this.unsubscribe();
                                } }, "Unsubscribe")),
                            this.state.errorMessage && (React.createElement("p", { className: "color--red" }, this.state.errorMessage))))))));
    }
}
exports.default = Unsubscribe;
