"use strict";
/**
 *  NewTransactionRow.tsx
 *  Row for adding a new transaction to the transaction table
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const qs = require("query-string");
const react_1 = require("react");
const Moment = require("moment");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
require("react-datepicker/dist/react-datepicker.css");
// Components
const EditablePrice_1 = require("@components/elements/EditablePrice");
const EditableString_1 = require("@components/elements/EditableString");
const EditableCategory_1 = require("@components/elements/EditableCategory");
const EditableDate_1 = require("@components/elements/EditableDate");
const EditableTags_1 = require("@components/elements/EditableTags");
const EditableAsset_1 = require("@components/elements/EditableAsset");
const CategoriesProvider_1 = require("@providers/CategoriesProvider");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const UserProvider_1 = require("@providers/UserProvider");
const TransactionsProvider_1 = require("@providers/TransactionsProvider");
const NewTransactionRow = ({ hiddenCols, onSave, currentPeriod }) => {
    const _transactions = (0, react_1.useContext)(TransactionsProvider_1.TransactionsContext);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const history = (0, react_router_dom_1.useHistory)();
    const isCurrentMonth = Moment(currentPeriod).format('YYYY-MM') === Moment().format('YYYY-MM');
    const [date, setDate] = (0, react_1.useState)(isCurrentMonth
        ? Moment().format('YYYY-MM-DD')
        : `${Moment(currentPeriod)
            .endOf('month')
            .format('YYYY-MM-DD')}T12:00:00Z`);
    const [payee, setPayee] = (0, react_1.useState)('');
    const [notes, setNotes] = (0, react_1.useState)('');
    const [categoryId, setCategoryId] = (0, react_1.useState)(undefined);
    const [assetId, setAssetId] = (0, react_1.useState)(_user.settings['default_asset_id'] || 'cash');
    const [plaidAccountId, setPlaidAccountId] = (0, react_1.useState)(null);
    const [amount, setAmount] = (0, react_1.useState)(undefined);
    const [currency, setCurrency] = (0, react_1.useState)(localStorage.getItem('_lm_last_currency_used') || _user.primaryCurrency);
    const [isSaving, setIsSaving] = (0, react_1.useState)(false);
    const [openTags, setOpenTags] = (0, react_1.useState)(false);
    const [tags, setTags] = (0, react_1.useState)([]);
    const [defaultNotation, setDefaultNotation] = (0, react_1.useState)(undefined);
    (0, react_1.useEffect)(() => {
        var _a, _b;
        const query = qs.parse(history.location.search);
        // Is there an asset in the query? If so, set this to be the asset_id
        if (query.asset && typeof query.asset == 'string') {
            setAssetId(query.asset == 'cash' ? 'cash' : parseInt(query.asset));
            setPlaidAccountId(null);
            // Set right currency
            if (query.asset !== 'cash') {
                setCurrency(((_a = _assets.getAsset(parseInt(query.asset))) === null || _a === void 0 ? void 0 : _a.currency) ||
                    _user.primaryCurrency);
            }
        }
        else if (query.account && typeof query.account == 'string') {
            setAssetId(null);
            setPlaidAccountId(parseInt(query.account));
            // Set right currency
            setCurrency(((_b = _assets.getPlaidAccount(parseInt(query.account))) === null || _b === void 0 ? void 0 : _b.currency) ||
                _user.primaryCurrency);
        }
    }, [history.location]);
    const createTransaction = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isSaving)
            return;
        setIsSaving(true);
        const results = yield onSave({
            date: date,
            payee: payee,
            notes: notes,
            category_id: categoryId,
            amount: amount,
            currency: currency,
            tags: tags,
            asset_id: assetId == 'cash' ? null : assetId,
            plaid_account_id: plaidAccountId,
        });
        if (!!results) {
            _transactions.addToMerchantNames(payee);
            setIsSaving(false);
        }
    });
    return (React.createElement(semantic_ui_react_1.Table.Row, { className: "editing" },
        React.createElement(semantic_ui_react_1.Table.Cell, null),
        React.createElement(semantic_ui_react_1.Table.Cell, null),
        React.createElement(semantic_ui_react_1.Table.Cell, null,
            React.createElement(EditableDate_1.default, { identifier: "new-tx-date", firstValue: date, autoFocus: !isCurrentMonth, state: 'Editing', location: 'inline', format: 'month_day_year', onSave: date => {
                    setDate(date);
                    // Focus on payee next
                    if (document.getElementById('new-tx-category')) {
                        document
                            .getElementById('new-tx-category')
                            .getElementsByTagName('input')[0]
                            .focus();
                    }
                } })),
        React.createElement(semantic_ui_react_1.Table.Cell, null),
        React.createElement(semantic_ui_react_1.Table.Cell, null,
            React.createElement(EditableCategory_1.default, { identifier: "new-tx-category", autoFocus: isCurrentMonth, firstValue: categoryId, state: 'Editing', location: 'inline-new', showUncategorized: true, onSave: category_id => {
                    var _a;
                    const _categoryId = category_id == null ? null : Number(category_id);
                    setCategoryId(_categoryId);
                    if (_categoryId) {
                        setDefaultNotation(((_a = _categories.get(_categoryId)) === null || _a === void 0 ? void 0 : _a.is_income) ? 'credit' : 'debit');
                    }
                    if (document.getElementById('new-tx-payee')) {
                        setTimeout(() => {
                            document
                                .getElementById('new-tx-payee')
                                .getElementsByTagName('input')[0]
                                .focus();
                        }, 10);
                    }
                } })),
        React.createElement(semantic_ui_react_1.Table.Cell, null),
        React.createElement(semantic_ui_react_1.Table.Cell, null,
            React.createElement(EditableString_1.default, { location: 'inline', identifier: "new-tx-payee", placeholder: 'e.g. Starbucks', firstValue: payee, autosuggest: true, shouldSaveOnChange: true, state: 'Editing', onSave: payee => {
                    setPayee(payee);
                }, onEnter: () => {
                    // Focus on price next
                    if (document.getElementById('new-tx-amount')) {
                        document.getElementById('new-tx-amount').focus();
                    }
                } })),
        React.createElement(semantic_ui_react_1.Table.Cell, null),
        React.createElement(semantic_ui_react_1.Table.Cell, null,
            React.createElement(EditablePrice_1.default, { identifier: `new-tx-amount`, state: 'Editing', location: 'inline-new', amount: amount, currency: currency, onEnter: createTransaction, saveOnChange: true, showSignLabel: true, defaultNotation: defaultNotation, onSave: (amount, currency) => {
                    setAmount(amount);
                    setCurrency(currency);
                } })),
        (hiddenCols || []).indexOf('notes') == -1 && (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Table.Cell, null),
            React.createElement(semantic_ui_react_1.Table.Cell, null,
                React.createElement(EditableString_1.default, { location: 'inline', identifier: "new-tx-notes", placeholder: 'Optional notes', firstValue: notes, onEnter: createTransaction, shouldSaveOnChange: true, state: 'Editing', onSave: notes => {
                        setNotes(notes);
                    } })))),
        (hiddenCols || []).indexOf('accounts') == -1 && (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Table.Cell, null),
            React.createElement(semantic_ui_react_1.Table.Cell, null,
                React.createElement(EditableAsset_1.default, { identifier: "new-tx-category", defaultSelection: Object.assign({}, (plaidAccountId
                        ? { id: plaidAccountId, source: 'plaid' }
                        : assetId && assetId != 'cash'
                            ? { id: assetId, source: 'manual' }
                            : { id: -1, source: null })), 
                    // defaultValue={assetId}
                    state: 'Editing', location: 'inline-new', onSave: obj => {
                        if (obj.id == -1) {
                            setAssetId('cash');
                            setPlaidAccountId(null);
                        }
                        else if (obj.source == 'manual') {
                            setAssetId(obj.id);
                            setPlaidAccountId(null);
                        }
                        else if (obj.source == 'plaid') {
                            setAssetId(null);
                            setPlaidAccountId(obj.id);
                        }
                    } })))),
        (hiddenCols || []).indexOf('tags') == -1 && (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Table.Cell, null),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align" },
                React.createElement(semantic_ui_react_1.Popup, { className: "width-250", position: 'bottom right', open: openTags, trigger: React.createElement(semantic_ui_react_1.Button, { color: "yellow", size: "tiny", onClick: () => {
                            setOpenTags(!openTags);
                        } }, !!openTags
                        ? 'Done'
                        : tags.length > 0
                            ? `${tags.length} Tag${tags.length > 1 ? 's' : ''}`
                            : 'Add Tags') },
                    React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "mini", className: "mb-05rem", style: { paddingTop: '7px', paddingBottom: '5px' } }, "Done"),
                    React.createElement(EditableTags_1.default, { className: "darker-bg", initialValue: tags, onBlur: () => {
                            setOpenTags(false);
                        }, onChange: value => {
                            setTags(value);
                        }, defaultOpen: true }))))),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "right-align", colSpan: 3, style: { padding: '5px 4px' } },
            React.createElement(semantic_ui_react_1.Button, { loading: isSaving, color: "green", size: "tiny", onClick: createTransaction }, "Add"))));
};
exports.default = NewTransactionRow;
