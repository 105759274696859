"use strict";
/**
 *  Transactions.tsx
 */
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const TransactionsContainer_1 = require("@components/Transactions/TransactionsContainer");
const Transactions = ({ _process, _removeToast, _showToast }) => {
    return (React.createElement(semantic_ui_react_1.Container, null,
        React.createElement(TransactionsContainer_1.default
        // Options
        , { 
            // Options
            isEmbedded: false, displaySidebar: true, allowBulkSelect: true, allowNewTransaction: true, watchQueryChanges: true, showHeader: true, showSearchAndFilter: true, showPlaidRefresh: true, preventModal: false, 
            // Utilities
            _process: _process, _removeToast: _removeToast, _showToast: _showToast })));
};
exports.default = Transactions;
