"use strict";
/**
 *  CategoryStatus.tsx
 *  Progress bar for a category in the budget summary card
 */
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_router_dom_1 = require("react-router-dom");
const react_1 = require("react");
const format_1 = require("@helpers/format");
const qs = require("query-string");
const CategoryStatus = ({ summary, monthProgress, currency, showBar, showPercentage, link, showAsPercentage, flipSign, isExcluded = false, showWarning = false, noMarginBottom = false, budgetColors = 'multiple', }) => {
    const [displaySum, setDisplaySum] = (0, react_1.useState)(summary.sum);
    const [displayBudgeted, setDisplayBudgeted] = (0, react_1.useState)(summary.budgeted);
    const [displayPercentage, setDisplayPercentage] = (0, react_1.useState)(null);
    const history = (0, react_router_dom_1.useHistory)();
    (0, react_1.useEffect)(() => {
        if (flipSign) {
            setDisplaySum(-1 * summary.sum);
            setDisplayBudgeted(-1 * summary.budgeted);
            if (!!summary.budgeted) {
                setDisplayPercentage((0, format_1.toPercentage)((summary.sum * (summary.is_income ? -1 : 1)) / summary.budgeted));
            }
        }
        else {
            setDisplaySum(summary.sum);
            setDisplayBudgeted(summary.budgeted);
            if (!!summary.budgeted) {
                setDisplayPercentage((0, format_1.toPercentage)((summary.sum * (summary.is_income ? -1 : 1)) / summary.budgeted, 1));
            }
        }
    }, [flipSign, summary]);
    return (React.createElement("div", { className: `card-content-wrapper ${noMarginBottom ? 'footer' : ''}` },
        React.createElement("div", { className: `card-content` },
            React.createElement("span", { className: `card-text flex--align-top ellipsis` },
                summary.group_id && React.createElement("span", { className: "hierarchy-line-icon-4" }),
                summary.is_group && React.createElement(semantic_ui_react_1.Icon, { name: "caret down" }),
                isExcluded && (React.createElement(semantic_ui_react_1.Popup, { hoverable: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "dont" }) },
                    "This category is excluded from the totals calculation. You can change this setting from the",
                    ' ',
                    React.createElement(react_router_dom_1.Link, { to: "/categories", className: "link clickable" }, "Categories page."))),
                showWarning && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "exclamation triangle", color: "red" }) }, "Categorize these transactions to get an accurate view into your spending.")),
                link !== null ? (React.createElement(react_router_dom_1.Link, { to: {
                        search: `?${qs.stringify(Object.assign({}, link))}`,
                    }, className: "link clickable" }, summary.category_name)) : (React.createElement("span", { className: "font--bold" }, summary.category_name))),
            showPercentage && displayPercentage && (React.createElement("span", { className: `card-number no-wrap ${displaySum / displayBudgeted > 1 && showBar && !summary.is_income
                    ? 'critical'
                    : ''} ${displaySum / displayBudgeted > monthProgress &&
                    displaySum / displayBudgeted < 1 &&
                    showBar
                    ? 'warning'
                    : ''}` },
                React.createElement("span", { className: showAsPercentage ? 'percentage' : 'amount' }, displayPercentage),
                React.createElement("span", { className: showAsPercentage ? 'amount' : 'percentage' }, (0, format_1.toPrice)(displaySum || 0, currency)))),
            showPercentage && !displayPercentage && (React.createElement("span", { className: `card-number plain no-wrap` }, (0, format_1.toPrice)(displaySum, currency))),
            !showPercentage && (React.createElement("span", { className: `card-number ${(flipSign && displaySum > 0) || (!flipSign && displaySum < 0)
                    ? 'color--green'
                    : 'plain'} no-wrap` }, (0, format_1.toPrice)(displaySum, currency)))),
        showBar && displayPercentage !== null && summary.budgeted !== 0 && (React.createElement("div", { className: `status-bar-background ${summary.group_id ? 'shortened' : ''}` },
            React.createElement("div", { className: `status-bar ${parseFloat(displayPercentage.substring(0, displayPercentage.length - 1)) > 100 &&
                    budgetColors == 'multiple' &&
                    !summary.is_income
                    ? 'critical'
                    : ''} ${parseFloat(displayPercentage.substring(0, displayPercentage.length - 1)) <
                    monthProgress * 100 && budgetColors == 'multiple'
                    ? 'normal'
                    : ''}`, style: {
                    width: displayPercentage === '∞%'
                        ? '100%'
                        : Math.min(parseFloat(displayPercentage.substring(0, displayPercentage.length - 1)), 100) + '%',
                } }),
            React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "mini", position: "bottom center", trigger: React.createElement("div", { className: "today-line", style: { left: (0, format_1.toPercentage)(monthProgress) } }) },
                "We are currently ",
                (0, format_1.toPercentage)(monthProgress, 0),
                " of the way through the month.")))));
};
exports.default = CategoryStatus;
