"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_1 = require("react");
const crypto_1 = require("@actions/crypto");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const DeleteManualCryptoModal = ({ data, useModal, utils, setVisibility, switchView, }) => {
    const [keepHistory, setKeepHistory] = (0, react_1.useState)(null);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    (0, react_1.useEffect)(() => {
        useModal({
            header: (React.createElement("div", null,
                React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        switchView('LINKED_ACCOUNT_VIEW');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " Account Details"),
                React.createElement("span", { className: "header-text-large" }, "Remove Crypto Account"))),
            leftButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "no-border", basic: true, onClick: () => {
                    setVisibility(false, true);
                } }, 'Cancel')),
        });
        return () => {
            useModal({
                header: null,
                leftButton: null,
            });
        };
    }, []);
    (0, react_1.useEffect)(() => {
        useModal({
            middleButton: React.createElement(React.Fragment, null),
            rightButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", disabled: keepHistory == null, loading: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setIsLoading(true);
                    // Save changes!
                    let results = yield utils._process(crypto_1.removeManual)(data.account.id, {
                        keep_history: keepHistory,
                    });
                    if (!results.error) {
                        // If we're not keeping history, then reload
                        if (!keepHistory && utils.remove) {
                            utils.remove([{ asset_id: data.account.id }]);
                        }
                        setVisibility(false);
                        _assets.removeManualCrypto(data.account.id);
                        utils._showToast({
                            message: 'Successfully started deletion process for asset. It may take a minute for changes to reflect here.',
                            type: 'success',
                            autoDismissTimeout: 5000,
                        });
                        setIsLoading(false);
                    }
                }) }, "Save Changes")),
        });
        return () => {
            useModal({
                middleButton: null,
                rightButton: null,
            });
        };
    }, [data, keepHistory, isLoading]);
    return (React.createElement(React.Fragment, null,
        React.createElement("p", null,
            "There is balance history associated with ",
            data.account.name,
            " for calculating your net worth over time."),
        React.createElement("p", null, "Would you like to keep the balance history on this account, or remove it completely?"),
        React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                setKeepHistory(true);
            }), className: `link-recurring-row ${keepHistory == true ? 'selected' : ''}` },
            React.createElement("div", null,
                React.createElement("span", null,
                    "I want to keep ",
                    data.account.name,
                    "'s balance history.")),
            keepHistory ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))),
        React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                setKeepHistory(false);
            }), className: `link-recurring-row ${keepHistory == false ? 'selected' : ''}` },
            React.createElement("div", null,
                React.createElement("span", null,
                    "I want to remove ",
                    data.account.name,
                    "'s balance history.")),
            keepHistory == false ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" })))));
};
exports.default = DeleteManualCryptoModal;
