"use strict";
/**
 *  TransactionTag.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
// Components
const semantic_ui_react_1 = require("semantic-ui-react");
const TagsProvider_1 = require("@/providers/TagsProvider");
const TransactionTag = ({ readOnly = false, className = '', tag, transactionId = new Date().getTime(), // for random key gen
displayOverride = null, circleOnly = false, }) => {
    const [background, setBackground] = (0, react_1.useState)('eeeeee');
    const [text, setText] = (0, react_1.useState)('333333');
    const [popupOpen, setPopupOpen] = (0, react_1.useState)(false);
    const _tags = (0, react_1.useContext)(TagsProvider_1.TagsContext);
    const [currentTag, setCurrentTag] = (0, react_1.useState)({ id: null, name: null });
    const colorSchemes = [
        {
            color: '#333',
            backgroundColor: '#FFE7D4',
        },
        {
            color: '#333', //'#EE2424',
            backgroundColor: '#FFD9D9',
        },
        {
            color: '#333', //'#C0329A',
            backgroundColor: '#FFDAF5',
        },
        {
            color: '#333', //'#692DCB',
            backgroundColor: '#E3D7F7',
        },
        {
            color: '#333', //'#2d49cb',
            backgroundColor: '#D7DDFA',
        },
        {
            color: '#333', //'#2d8ccb',
            backgroundColor: '#D5EAF8',
        },
        {
            color: '#333',
            backgroundColor: '#CFF4F3',
        },
        {
            color: '#333',
            backgroundColor: '#D7F5CC',
        },
        {
            color: '#333',
            backgroundColor: '#FDF99D',
        },
        {
            color: '#333',
            backgroundColor: '#eee',
        },
    ];
    // For clicking outside
    const ref = (0, react_1.useRef)();
    useOnClickOutside(ref, () => {
        setPopupOpen(false);
    });
    // If the master color list changes, update
    (0, react_1.useEffect)(() => {
        var _a, _b;
        setBackground(((_a = _tags.colors[currentTag.id]) === null || _a === void 0 ? void 0 : _a.background_color) || 'eeeeee');
        setText(((_b = _tags.colors[currentTag.id]) === null || _b === void 0 ? void 0 : _b.text_color) || '333333');
    }, [currentTag]);
    (0, react_1.useEffect)(() => {
        if (typeof tag === 'object') {
            // Should be fine
            setCurrentTag(tag);
        }
        else if (!!tag) {
            const match = _tags.tags.find(o => o.id == parseInt(tag));
            setCurrentTag(Object.assign({}, match));
        }
    }, [tag]);
    return circleOnly && readOnly ? (React.createElement(React.Fragment, null,
        React.createElement("span", null, displayOverride || currentTag.name),
        React.createElement("span", { className: `ml-05rem transaction-circle-tag mr-0`, style: {
                backgroundColor: `#${background}`,
                borderColor: `#${background}`,
                color: `#${text}`,
            } }))) : circleOnly ? (React.createElement(semantic_ui_react_1.Popup, { key: `tag-popup-${transactionId}`, hoverable: true, open: popupOpen, trigger: React.createElement("span", { className: `transaction-circle-tag clickable`, style: {
                backgroundColor: `#${background}`,
                borderColor: `#${background}`,
                color: `#${text}`,
            }, onClick: () => {
                setPopupOpen(!popupOpen);
            } }) },
        React.createElement("div", { ref: ref },
            React.createElement("span", { className: "form-label display--block" }, "Choose color scheme"),
            colorSchemes.map((scheme, index) => {
                return (React.createElement("div", { key: `tag-scheme-${currentTag.id}-${transactionId}-${index}`, className: "clickable color-block", style: scheme, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setBackground(scheme.backgroundColor.substring(1));
                        setText(scheme.color.substring(1));
                        _tags.updateTagColor(currentTag.id, scheme.backgroundColor.substring(1), scheme.color.substring(1));
                    }) }, "a"));
            })))) : readOnly ? (React.createElement("span", { className: `transaction-tag no-hover ellipsis`, style: document.body.classList.contains('dark')
            ? {
                color: `#${text}`,
                borderColor: `#${background}`,
                backgroundColor: `#${background}`,
            }
            : {
                backgroundColor: `#${background}`,
                borderColor: `#${background}`,
                color: `#${text}`,
            } }, displayOverride || currentTag.name)) : (React.createElement(react_router_dom_1.Link, { tabIndex: -1, to: {
            search: `?tag=${currentTag.id}`,
        }, className: `link clickable ellipsis display--block`, style: { lineHeight: '1rem' } },
        React.createElement("span", { className: "transaction-tag ellipsis", style: document.body.classList.contains('dark')
                ? {
                    color: `#${text}`,
                    borderColor: `#${background}`,
                    backgroundColor: `#${background}`,
                }
                : {
                    color: `#${text}`,
                    borderColor: `#${background}`,
                    backgroundColor: `#${background}`,
                } }, displayOverride || currentTag.name)));
};
function useOnClickOutside(ref, handler) {
    (0, react_1.useEffect)(() => {
        const listener = event => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]); // ... passing it into this hook. // ... but to optimize you can wrap handler in useCallback before ... // ... callback/cleanup to run every render. It's not a big deal ... // ... function on every render that will cause this effect ... // It's worth noting that because passed in handler is a new ... // Add ref and handler to effect dependencies
}
exports.default = TransactionTag;
