"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPercentChange = exports.toReadableSize = exports.escapeCSV = exports.removeNullValues = exports.parseNumber = exports.reverseArray = exports.simplifyArr = exports.simplify = exports.roundTo = exports.capitalize = exports.toPercentage = exports.toPrice = exports.toCrypto = void 0;
const parseDecimalNumber = require("parse-decimal-number");
const toCrypto = (num, currency, hideSymbol = false, nullValue = '-') => {
    if (isNaN(num) ||
        num === undefined ||
        num === null ||
        num.toString().length === 0 ||
        currency === null) {
        return nullValue || '-';
    }
    const formatter = new Intl.NumberFormat(localStorage.getItem('_lm_locale') || 'en-US', {
        style: 'decimal',
        useGrouping: false,
        maximumFractionDigits: 18,
    });
    return `${formatter.format(num)}${hideSymbol ? '' : ` ${currency.toUpperCase()}`}`;
};
exports.toCrypto = toCrypto;
const toPrice = (num, currency = 'usd', round = 0.01, hideSymbol = false, nullValue = null, signDisplay = null) => {
    // Differentiate between null (no value) and $0
    if (isNaN(num) ||
        num === undefined ||
        num === null ||
        num.toString().length === 0 ||
        currency === null) {
        return nullValue || '-';
    }
    try {
        const options = Object.assign({ style: hideSymbol ? 'decimal' : 'currency', currency: currency, useGrouping: hideSymbol ? false : true }, (round == 0.0001 ? { minimumFractionDigits: 4 } : {}));
        if (signDisplay) {
            options['signDisplay'] = signDisplay;
        }
        const formatter = new Intl.NumberFormat(localStorage.getItem('_lm_locale') || 'en-US', options);
        if ((0, exports.roundTo)(num, round) == 0) {
            return formatter.format(Math.abs(0));
        }
        else {
            return formatter.format((0, exports.roundTo)(num, round));
        }
    }
    catch (e) {
        return nullValue || '-';
    }
};
exports.toPrice = toPrice;
const toPercentage = (num, points = 2) => {
    if (num == null || typeof num == 'undefined')
        return '';
    return `${(num * 100).toFixed(points)}%`;
};
exports.toPercentage = toPercentage;
const capitalize = (str) => {
    try {
        if (!str || str.length === 0)
            return str;
        // This is kinda cheating, but for certain strings, return all caps.
        if ([
            'cd',
            'ira',
            'rrsp',
            'resp',
            'hsa',
            'sep ira',
            'simple ira',
            'tfsa',
        ].indexOf(str) > -1) {
            return str.toUpperCase();
        }
        return str[0].toUpperCase() + str.toLowerCase().substring(1);
    }
    catch (e) {
        console.log('Error in capitalization', { e, str });
        return str;
    }
};
exports.capitalize = capitalize;
const roundTo = (num, round) => {
    return Math.round(num / round) * round;
};
exports.roundTo = roundTo;
const simplify = (str) => {
    if (!str)
        return str;
    return str.toLowerCase().trim();
};
exports.simplify = simplify;
const simplifyArr = (arr) => {
    if (!arr)
        return arr;
    if (arr.length > 0) {
        return arr.map(o => {
            return (0, exports.simplify)(o);
        });
    }
};
exports.simplifyArr = simplifyArr;
const reverseArray = arr => {
    var newArray = [];
    for (var i = arr.length - 1; i >= 0; i--) {
        newArray.push(arr[i]);
    }
    return newArray;
};
exports.reverseArray = reverseArray;
const parseNumber = (input) => {
    if (input !== 0 &&
        (input == null ||
            typeof input == 'undefined' ||
            input.toString().length == 0)) {
        return NaN;
    }
    try {
        // Remove any spaces/whitespace
        input = input.toString().replace(/\s/g, '');
    }
    catch (e) {
        input = input.toString().trim();
    }
    try {
        let isNegative = false;
        if (input.indexOf('-') == 0) {
            isNegative = true;
        }
        else if (input[0] == '(' && input[input.length - 1] == ')') {
            isNegative = true;
        }
        // Regex to only get digits, commas and periods
        let match = input.match(/[\d'.,]+/g);
        if (match && match.length == 1) {
            const num = match[0];
            const possibleOptions = [',.', '.,', ' .', ' ,', "'."];
            let found = false;
            let index = 0;
            let result = NaN;
            while (!found && index < possibleOptions.length) {
                result = parseDecimalNumber(num, possibleOptions[index]);
                if (isNaN(result)) {
                    index++;
                }
                else {
                    found = true;
                }
            }
            return isNegative ? -1 * result : result;
        }
        else {
            return NaN;
        }
    }
    catch (e) {
        return NaN;
    }
};
exports.parseNumber = parseNumber;
const removeNullValues = obj => {
    if (!obj || typeof obj == 'undefined')
        return obj;
    Object.keys(obj).forEach(k => !obj[k] && obj[k] !== undefined && delete obj[k]);
    return obj;
};
exports.removeNullValues = removeNullValues;
const escapeCSV = str => {
    if (!str) {
        return '';
    }
    else {
        return str.replace(/"/g, '""');
    }
};
exports.escapeCSV = escapeCSV;
const toReadableSize = kb => {
    // Convert to kb
    if (kb < 300) {
        return `${kb} KB`;
    }
    return `${(kb / 1000).toFixed(1)} MB`;
};
exports.toReadableSize = toReadableSize;
const getPercentChange = (prev, curr) => {
    if (prev == 0 && curr == 0) {
        return `+$0.00%`;
    }
    else if (prev == null || curr == null || prev == 0) {
        return null;
    }
    else if (prev * curr < 0) {
        return null; // No percentage changes between negative and positives
    }
    const flipSign = curr < 0 && prev < 0;
    let percentChangeAsset = (0, exports.toPercentage)((curr - prev) / prev);
    if (flipSign) {
        if (percentChangeAsset[0] != '-') {
            percentChangeAsset = `-${percentChangeAsset}`;
        }
        else {
            percentChangeAsset = `+${percentChangeAsset.slice(1)}`;
        }
    }
    else {
        if (percentChangeAsset[0] != '-') {
            percentChangeAsset = `+${percentChangeAsset}`;
        }
    }
    return percentChangeAsset;
};
exports.getPercentChange = getPercentChange;
