"use strict";
/**
 *  SetBudgetView.tsx
 *  Budget
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const EditablePrice_1 = require("@components/elements/EditablePrice");
const EditableCategory_1 = require("@components/elements/EditableCategory");
const react_router_dom_1 = require("react-router-dom");
const budgets_1 = require("@actions/budgets");
const react_1 = require("react");
const UserProvider_1 = require("@providers/UserProvider");
const SetBudgetView = ({ 
/** Recurring expense to be edited */
data, useModal, setVisibility, switchView, _modal, }) => {
    var _a, _b, _c, _d, _e;
    const history = (0, react_router_dom_1.useHistory)();
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _budget = _modal.data.current.budget;
    const prevData = ((_a = _budget.data[data.currentPeriod]) === null || _a === void 0 ? void 0 : _a.prev_key)
        ? _budget.data[_budget.data[data.currentPeriod].prev_key]
        : null;
    const [config, setConfig] = (0, react_1.useState)({
        id: ((_b = _budget.config) === null || _b === void 0 ? void 0 : _b.config_id) || null,
        auto_suggest: ((_c = _budget.config) === null || _c === void 0 ? void 0 : _c.auto_suggest) || 'none',
        amount: (_d = _budget.config) === null || _d === void 0 ? void 0 : _d.amount,
        currency: (_e = _budget.config) === null || _e === void 0 ? void 0 : _e.currency,
        category_id: _budget.category_id,
        cadence: 'monthly',
    });
    (0, react_1.useEffect)(() => {
        var _a, _b, _c, _d;
        useModal({
            header: (React.createElement("div", null,
                ((_b = (_a = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.previous) && (React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        var _a, _b;
                        switchView((_b = (_a = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.previous);
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " ", (_d = (_c = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 :
                    _d.previous_text)),
                React.createElement("span", { className: "header-text-large" }, "Set Budget"))),
            middleButton: React.createElement(React.Fragment, null),
            leftButton: (React.createElement(semantic_ui_react_1.Button, { className: "no-border", color: 'orange', basic: true, onClick: () => {
                    setVisibility(false, true);
                } }, 'Cancel')),
            rightButton: (React.createElement(semantic_ui_react_1.Button, { color: 'orange', disabled: false, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    var _e, _f;
                    const results = yield (0, budgets_1.updateBudgetConfig)(config.auto_suggest == 'none'
                        ? {
                            id: (_e = _budget.config) === null || _e === void 0 ? void 0 : _e.config_id,
                            cadence: null,
                        }
                        : Object.assign({ id: (_f = _budget.config) === null || _f === void 0 ? void 0 : _f.config_id, cadence: 'monthly' }, config));
                    data.updateBudgetHistoryConfig(_budget.category_id, results.data == true ? null : results.data);
                    setVisibility(false);
                }) }, "Save")),
        });
        return () => {
            useModal({
                header: React.createElement(React.Fragment, null),
                middleButton: React.createElement(React.Fragment, null),
                leftButton: React.createElement(React.Fragment, null),
                rightButton: React.createElement(React.Fragment, null),
            });
        };
    }, [config]);
    return (React.createElement("div", { id: "recurring-modal-detail-view" },
        React.createElement("div", { className: "transaction-details" },
            React.createElement("h3", null, "Budget Details"),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Category"),
                React.createElement(EditableCategory_1.default, { identifier: `bulk-edit-category`, firstValue: _budget.category_id, disabled: true, placeholder: 'Select category', state: 'Editing', location: 'modal', allowSelectingGroups: true, onSave: (value) => __awaiter(void 0, void 0, void 0, function* () { }) })),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null,
                    "Set budget suggestion",
                    React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "For periods where there isn't a budget set, we will display a suggested budget based on the previous period's activity. This budget isn't in effect until you lock it in.")),
                React.createElement(semantic_ui_react_1.Dropdown, { fluid: true, selection: true, className: "category-dropdown p-category-modal mb-1rem", value: config.auto_suggest, placeholder: "Select type", onChange: (e, { value }) => {
                        setConfig(Object.assign(Object.assign({}, config), { auto_suggest: value }));
                    }, options: [
                        {
                            key: 0,
                            value: 'none',
                            text: 'Do not suggest',
                        },
                        {
                            key: 1,
                            value: 'spent',
                            text: 'How much I spent last month',
                        },
                        {
                            key: 2,
                            value: 'budgeted',
                            text: 'How much I budgeted last month',
                        },
                        {
                            key: 3,
                            value: 'fixed',
                            text: 'A fixed amount',
                        },
                        {
                            key: 4,
                            value: 'fixed-rollover',
                            text: 'A fixed amount with rollover',
                        },
                    ] }),
                config.auto_suggest.indexOf('fixed') == 0 && (React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Fixed amount"),
                    React.createElement(EditablePrice_1.default, { identifier: "criteria-amount", amount: config.amount, currency: config.currency, location: 'modal', state: 'Editing', saveOnChange: true, onSave: (amount, currency) => __awaiter(void 0, void 0, void 0, function* () {
                            setConfig(Object.assign(Object.assign({}, config), { amount: amount, currency: currency }));
                        }) })))))));
};
exports.default = SetBudgetView;
