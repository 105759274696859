"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const _ = require("lodash");
const Moment = require("moment");
const react_router_dom_1 = require("react-router-dom");
const tags_1 = require("@actions/tags");
const budgets_1 = require("@actions/budgets");
const categories_1 = require("@actions/categories");
const demo_1 = require("@actions/demo");
const assets_1 = require("@actions/assets");
const recurring_expenses_1 = require("@actions/recurring_expenses");
const transactions_1 = require("@actions/transactions");
const balance_history_1 = require("@actions/balance_history");
const semantic_ui_react_1 = require("semantic-ui-react");
const demo_2 = require("@helpers/demo");
const UserProvider_1 = require("@providers/UserProvider");
const CategoriesProvider_1 = require("@providers/CategoriesProvider");
const TagsProvider_1 = require("@providers/TagsProvider");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const Demo = ({ _showToast }) => {
    const history = (0, react_router_dom_1.useHistory)();
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const _tags = (0, react_1.useContext)(TagsProvider_1.TagsContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const categories = (0, react_1.useRef)({});
    const assets = (0, react_1.useRef)({});
    const recurring = (0, react_1.useRef)({});
    const tags = (0, react_1.useRef)({});
    // Categories, Tags, Assets, Budgets, Recurring, Transactions
    const [percent, setPercent] = (0, react_1.useState)(10);
    const [status, setStatus] = (0, react_1.useState)('Preparing...');
    (0, react_1.useEffect)(() => {
        go();
    }, []);
    const done = () => __awaiter(void 0, void 0, void 0, function* () {
        setPercent(100);
        setStatus('Done! Redirecting you to Lunch Money..');
        yield _user.fetchUserDetails();
        history.push('/overview');
    });
    const go = () => __awaiter(void 0, void 0, void 0, function* () {
        yield (0, demo_1.setupDemo)();
        yield addCategories();
    });
    const addCategories = () => __awaiter(void 0, void 0, void 0, function* () {
        setStatus('Creating categories...');
        setPercent(20);
        const newCategories = yield (0, categories_1.createCategories)({
            categories: [
                'Alcohol, Bars',
                'Childcare',
                'Coffee Shops',
                'Ridesharing',
                'Groceries',
                'Food Delivery',
                'Home, Auto',
                'Personal Care',
                'Pets',
                'Restaurants',
                'Self-improvement',
                'Shopping',
                'Payment, Transfer',
                'Income',
            ],
        });
        categories.current = _.groupBy(newCategories.data.results, 'name');
        setStatus('Creating category group...');
        setPercent(30);
        const results = yield (0, categories_1.createCategoryGroup)({
            name: 'Food',
            description: 'Consumables',
            is_income: false,
            exclude_from_budget: false,
            exclude_from_totals: false,
            category_ids: [
                categories.current['Coffee Shops'][0].id,
                categories.current['Groceries'][0].id,
                categories.current['Food Delivery'][0].id,
                categories.current['Restaurants'][0].id,
            ],
        });
        categories.current['Food'] = [{ id: results.data.id }];
        _categories.fetchCategories();
        yield addTags();
    });
    const addTags = () => __awaiter(void 0, void 0, void 0, function* () {
        setStatus('Creating tags...');
        setPercent(40);
        const a = yield (0, tags_1.createTag)({
            name: `Penny's`,
            description: '',
            background_color: 'FFE7D4',
            text_color: '333',
        });
        const b = yield (0, tags_1.createTag)({
            name: `Road Trip 2020`,
            description: '',
            background_color: 'CFF4F3',
            text_color: '333',
        });
        const c = yield (0, tags_1.createTag)({
            name: `Date Night`,
            description: '',
            background_color: 'D7F5CC',
            text_color: '333',
        });
        tags.current['Penny'] = a.data.id;
        tags.current['Road Trip'] = b.data.id;
        tags.current['Date Night'] = c.data.id;
        _tags.fetchTags();
        yield addBudgets();
    });
    const addBudgets = () => __awaiter(void 0, void 0, void 0, function* () {
        const opts = {
            currency: _user.primaryCurrency,
            start_date: Moment()
                .startOf('month')
                .subtract(2, 'month')
                .format('YYYY-MM-DD'),
        };
        setStatus('Creating budgets (1/9)...');
        setPercent(42);
        yield (0, budgets_1.createMultipleBudgets)(Object.assign(Object.assign({}, opts), { category_id: categories.current['Alcohol, Bars'][0].id, amount: 120 }));
        setStatus('Creating budgets (2/9)...');
        setPercent(44);
        yield (0, budgets_1.createMultipleBudgets)(Object.assign(Object.assign({}, opts), { category_id: categories.current['Coffee Shops'][0].id, amount: 100 }));
        setStatus('Creating budgets (3/9)...');
        setPercent(46);
        yield (0, budgets_1.createMultipleBudgets)(Object.assign(Object.assign({}, opts), { category_id: categories.current['Ridesharing'][0].id, amount: 80 }));
        setStatus('Creating budgets (4/9)...');
        setPercent(48);
        yield (0, budgets_1.createMultipleBudgets)(Object.assign(Object.assign({}, opts), { category_id: categories.current['Groceries'][0].id, amount: 80 }));
        setStatus('Creating budgets (5/9)...');
        setPercent(50);
        yield (0, budgets_1.createMultipleBudgets)(Object.assign(Object.assign({}, opts), { category_id: categories.current['Home, Auto'][0].id, amount: 1200 }));
        setStatus('Creating budgets (6/9)...');
        setPercent(52);
        yield (0, budgets_1.createMultipleBudgets)(Object.assign(Object.assign({}, opts), { category_id: categories.current['Food Delivery'][0].id, amount: 50 }));
        setStatus('Creating budgets (7/9)...');
        setPercent(54);
        yield (0, budgets_1.createMultipleBudgets)(Object.assign(Object.assign({}, opts), { category_id: categories.current['Restaurants'][0].id, amount: 200 }));
        setStatus('Creating budgets (8/9)...');
        setPercent(56);
        yield (0, budgets_1.createMultipleBudgets)(Object.assign(Object.assign({}, opts), { category_id: categories.current['Food'][0].id, amount: 430 }));
        setStatus('Creating budgets (9/9)...');
        setPercent(58);
        yield (0, budgets_1.createMultipleBudgets)(Object.assign(Object.assign({}, opts), { category_id: categories.current['Shopping'][0].id, amount: 100 }));
        setStatus('Finalizing budgets...');
        setPercent(60);
        yield addAssets();
    });
    const addAssets = () => __awaiter(void 0, void 0, void 0, function* () {
        setStatus('Creating assets...');
        setPercent(70);
        const a = yield (0, assets_1.createAsset)({
            type_name: 'credit',
            subtype_name: 'credit card',
            name: 'Credit Card (..8310)',
            display_name: `Lenny's Amex Card`,
            balance: 0,
            currency: _user.primaryCurrency,
            institution_name: 'Chase',
        });
        assets.current['credit'] = a.data.id;
        for (let i = 1; i < 4; i++) {
            yield (0, balance_history_1.insertBalanceHistory)({
                date: Moment()
                    .startOf('month')
                    .subtract(i, 'months')
                    .format('YYYY-MM-DD'),
                balance: i == 1 ? -936.19 : i == 2 ? -989.45 : i == 3 ? -991.4 : -1021.32,
                currency: _user.primaryCurrency,
                asset_id: assets.current['credit'],
            });
        }
        const d = yield (0, assets_1.createAsset)({
            type_name: 'credit',
            subtype_name: 'credit card',
            name: 'Credit Card (..4977)',
            display_name: `Penny's Visa Card`,
            balance: 0,
            currency: _user.primaryCurrency,
            institution_name: 'Chase',
        });
        assets.current['credit-2'] = d.data.id;
        for (let i = 1; i < 4; i++) {
            yield (0, balance_history_1.insertBalanceHistory)({
                date: Moment()
                    .startOf('month')
                    .subtract(i, 'months')
                    .format('YYYY-MM-DD'),
                balance: i == 1 ? -710.45 : i == 2 ? -609.45 : i == 3 ? -771.4 : -687.32,
                currency: _user.primaryCurrency,
                asset_id: assets.current['credit-2'],
            });
        }
        const b = yield (0, assets_1.createAsset)({
            type_name: 'cash',
            subtype_name: 'checking',
            name: 'Checking (..4892)',
            display_name: `Penny's Checking`,
            balance: 21371.92,
            currency: _user.primaryCurrency,
            institution_name: 'Chase',
        });
        assets.current['cash'] = b.data.id;
        for (let i = 1; i < 4; i++) {
            yield (0, balance_history_1.insertBalanceHistory)({
                date: Moment()
                    .startOf('month')
                    .subtract(i, 'months')
                    .format('YYYY-MM-DD'),
                balance: 21371.92 * Math.pow(0.93, i),
                currency: _user.primaryCurrency,
                asset_id: assets.current['cash'],
            });
        }
        const c = yield (0, assets_1.createAsset)({
            type_name: 'investment',
            subtype_name: 'brokerage',
            name: 'Individual Brokerage',
            display_name: null,
            balance: 41211.8,
            currency: _user.primaryCurrency,
            institution_name: 'Fidelity',
        });
        assets.current['investment'] = c.data.id;
        for (let i = 1; i < 4; i++) {
            yield (0, balance_history_1.insertBalanceHistory)({
                date: Moment()
                    .startOf('month')
                    .subtract(i, 'months')
                    .format('YYYY-MM-DD'),
                balance: 41211.8 * Math.pow(0.95, i),
                currency: _user.primaryCurrency,
                asset_id: assets.current['investment'],
            });
        }
        _assets.fetchAssets();
        yield addRecurring();
    });
    const addRecurring = () => __awaiter(void 0, void 0, void 0, function* () {
        setStatus('Creating recurring items...');
        setPercent(80);
        const a = yield (0, recurring_expenses_1.createRecurringExpense)({
            cadence: 'monthly',
            payee: 'Rent',
            description: 'Monthly rent payable to Mrs Smith',
            amount: 850,
            currency: _user.primaryCurrency,
            billing_date: Moment()
                .subtract(3, 'months')
                .startOf('month')
                .format('YYYY-MM-DD'),
            category_id: categories.current['Home, Auto'][0].id,
            asset_id: assets.current['cash'],
            plaid_account_id: null,
        });
        const b = yield (0, recurring_expenses_1.createRecurringExpense)({
            cadence: 'custom',
            granularity: 'day',
            quantity: 28,
            payee: 'Geico',
            description: 'Car insurance',
            amount: 145,
            currency: _user.primaryCurrency,
            billing_date: Moment()
                .subtract(3 * 28, 'day')
                .format('YYYY-MM-DD'),
            category_id: categories.current['Home, Auto'][0].id,
            asset_id: assets.current['credit'],
            plaid_account_id: null,
        });
        const c = yield (0, recurring_expenses_1.createRecurringExpense)({
            cadence: 'monthly',
            payee: 'Direct Deposit',
            description: 'Income',
            amount: 4500,
            currency: _user.primaryCurrency,
            billing_date: Moment()
                .subtract(3, 'months')
                .startOf('month')
                .format('YYYY-MM-DD'),
            category_id: categories.current['Income'][0].id,
            asset_id: assets.current['cash'],
            plaid_account_id: null,
        });
        yield (0, recurring_expenses_1.createRecurringExpense)({
            cadence: 'monthly',
            payee: 'Google Fi',
            description: 'Cell phone plan',
            amount: 50,
            currency: _user.primaryCurrency,
            billing_date: Moment()
                .subtract(3, 'months')
                .date(25)
                .format('YYYY-MM-DD'),
            category_id: categories.current['Home, Auto'][0].id,
            asset_id: assets.current['credit'],
            plaid_account_id: null,
        });
        recurring.current['rent'] = a.data.recurring_id;
        recurring.current['insurance'] = b.data.recurring_id;
        recurring.current['income'] = c.data.recurring_id;
        yield addTransactions();
    });
    const addTransactions = () => __awaiter(void 0, void 0, void 0, function* () {
        setStatus('Creating transactions...');
        setPercent(90);
        const a = yield (0, transactions_1.bulkInsertTransactions)({
            transactions: (0, demo_2.generateTransactions)({
                categories,
                assets,
                recurring,
                tags,
                currency: _user.primaryCurrency,
            }),
        });
        // Insert Payment/Transfer transactions
        const ids = a.data.transactions.map(o => o.id);
        // Group 1
        yield (0, transactions_1.createTransactionGroup)({
            transactions: [ids[ids.length - 2], ids[ids.length - 5]],
            details: {
                date: Moment()
                    .subtract(2, 'months')
                    .date(3)
                    .format('YYYY-MM-DD'),
                payee: 'Credit Card Payment',
                category_id: categories.current['Payment, Transfer'][0].id,
            },
        });
        // Group 2
        yield (0, transactions_1.createTransactionGroup)({
            transactions: [ids[ids.length - 3], ids[ids.length - 6]],
            details: {
                date: Moment()
                    .subtract(1, 'months')
                    .date(3)
                    .format('YYYY-MM-DD'),
                payee: 'Credit Card Payment',
                category_id: categories.current['Payment, Transfer'][0].id,
            },
        });
        // Group 3
        yield (0, transactions_1.createTransactionGroup)({
            transactions: [ids[ids.length - 4], ids[ids.length - 7]],
            details: {
                date: Moment()
                    .subtract(0, 'months')
                    .date(3)
                    .format('YYYY-MM-DD'),
                payee: 'Credit Card Payment',
                category_id: categories.current['Payment, Transfer'][0].id,
            },
        });
        // Update asset balance
        yield (0, assets_1.updateAsset)(assets.current['credit'], {
            balance: -1041.39,
        });
        yield (0, assets_1.updateAsset)(assets.current['credit-2'], {
            balance: -684.63,
        });
        done();
    });
    return (React.createElement("div", { className: "g-logged-out" },
        React.createElement("div", { className: "g-logged-out-container" },
            React.createElement("div", { className: "logged-out-content" },
                React.createElement(semantic_ui_react_1.Container, null,
                    React.createElement("div", { id: "logo-container" },
                        React.createElement("div", { id: "logo" }),
                        React.createElement("div", { id: "shadow" })),
                    React.createElement(semantic_ui_react_1.Header, { as: "h2", className: "center-align" }, "Please wait while we build your demo account!"),
                    React.createElement(semantic_ui_react_1.Progress, { active: status !== 'Done! Redirecting you to Lunch Money..', percent: percent, color: "green" }, status))))));
};
exports.default = Demo;
