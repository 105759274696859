"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const react_1 = require("react");
const billing_1 = require("@actions/billing");
const semantic_ui_react_1 = require("semantic-ui-react");
const UserProvider_1 = require("@providers/UserProvider");
const react_router_dom_1 = require("react-router-dom");
const format_1 = require("@/helpers/format");
const BillingProvider_1 = require("@/providers/BillingProvider");
const Cancellation = ({}) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _billing = (0, react_1.useContext)(BillingProvider_1.BillingContext);
    const history = (0, react_router_dom_1.useHistory)();
    const [cancellationReason, setCancellationReason] = (0, react_1.useState)(null);
    const [cancellationDetails, setCancellationDetails] = (0, react_1.useState)(null);
    const [mailingList, setMailingList] = (0, react_1.useState)(true);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const _data = (0, react_1.useRef)({ credit: null, balance: null });
    const [offerPause, setOfferPause] = (0, react_1.useState)(false);
    const [display, setDisplay] = (0, react_1.useState)(null); // either pause or cancel or confirmed_cancel
    const _previewPlan = () => __awaiter(void 0, void 0, void 0, function* () {
        var _q, _r, _s;
        setIsLoading(true);
        const results = yield (0, billing_1.previewPlan)({
            plan_type: 'yearly',
            plan_currency: 'usd',
            multiplier: 0,
        });
        _data.current.credit = Object.assign(Object.assign({}, (_q = results === null || results === void 0 ? void 0 : results.data) === null || _q === void 0 ? void 0 : _q.credit), { amount: ((_s = (_r = results === null || results === void 0 ? void 0 : results.data) === null || _r === void 0 ? void 0 : _r.credit) === null || _s === void 0 ? void 0 : _s.amount) / -100 });
        _data.current.balance = results === null || results === void 0 ? void 0 : results.data.balance;
        setIsLoading(false);
        setDisplay('pause');
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(semantic_ui_react_1.Card, { fluid: true },
            React.createElement(semantic_ui_react_1.Card.Content, { header: "Cancel Subscription" }),
            React.createElement(semantic_ui_react_1.Card.Content, null,
                _user.isAdmin &&
                    ['monthly', 'yearly', 'legacy'].indexOf((_a = _billing.subscription.plan) === null || _a === void 0 ? void 0 : _a.type) > -1 &&
                    _billing.user.type !== 'early-adopter' &&
                    _billing.status == 'active' &&
                    !_billing.user.paused && (React.createElement(semantic_ui_react_1.Button, { fluid: true, loading: isLoading, disabled: isLoading, className: "mb-05rem", color: "yellow", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield _previewPlan();
                    }) },
                    "Pause my subscription",
                    React.createElement(semantic_ui_react_1.Icon, { name: "arrow alternate circle right" }))),
                React.createElement(semantic_ui_react_1.Button, { fluid: true, loading: isLoading, disabled: isLoading, basic: true, color: "red", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        if (_billing.isPaused ||
                            _billing.user.stripe_status !== 'no subscription') {
                            setDisplay('cancel');
                        }
                        else {
                            setOfferPause(true);
                            yield _previewPlan();
                        }
                    }) },
                    _user.isAdmin
                        ? 'Cancel subscription & Delete entire account'
                        : 'Delete my account',
                    React.createElement(semantic_ui_react_1.Icon, { name: "arrow alternate circle right" })))),
        React.createElement(semantic_ui_react_1.Modal, { open: !!display, closeOnEscape: false, closeOnDimmerClick: false, size: 'small', onClose: () => {
                setDisplay(null);
            } },
            React.createElement(semantic_ui_react_1.Modal.Header, null, display == 'cancel'
                ? 'Account Cancellation'
                : display == 'pause' && offerPause
                    ? 'Want to pause your subscription instead?'
                    : display == 'pause'
                        ? 'Pause my subscription'
                        : 'Account deletion confirmation'),
            display == 'pause' && (React.createElement(semantic_ui_react_1.Modal.Content, null, !!((_b = _data.current) === null || _b === void 0 ? void 0 : _b.credit) && (React.createElement("div", { className: "mb-1rem" },
                React.createElement("p", null, "Need to take a break for whatever reason, but don't want us to delete your data? You can pause your subscription indefinitely."),
                React.createElement("p", null,
                    "You will be credited",
                    ' ',
                    React.createElement("b", null, (0, format_1.toPrice)((_c = _data.current.credit) === null || _c === void 0 ? void 0 : _c.amount, (_d = _data.current.credit) === null || _d === void 0 ? void 0 : _d.currency)),
                    ' ',
                    "for unused time between today and",
                    ' ',
                    Moment((_f = (_e = _data.current.credit) === null || _e === void 0 ? void 0 : _e.period) === null || _f === void 0 ? void 0 : _f.end, 'X').format(_user.getFormat('month_day_year')),
                    ((_g = _data.current.balance) === null || _g === void 0 ? void 0 : _g.amount) ? (React.createElement("span", null,
                        ", in addition to your existing credit balance of",
                        ' ',
                        React.createElement("b", null, (0, format_1.toPrice)((_h = _data.current.balance) === null || _h === void 0 ? void 0 : _h.amount, (_j = _data.current.balance) === null || _j === void 0 ? void 0 : _j.currency)))) : (''),
                    ". You may use this towards future subscription dues whenever you're ready to come back!"))))),
            display == 'confirmed_cancel' && (React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("h3", null, "Thank you for trying out Lunch Money!"),
                React.createElement("p", null, "We have received your request to delete your account. If applicable, you will be refunded for any unused time."),
                React.createElement("p", null, "You will be automatically logged out in 5 seconds."))),
            display == 'cancel' && (React.createElement(semantic_ui_react_1.Modal.Content, null,
                !!((_k = _billing.subscription.balance) === null || _k === void 0 ? void 0 : _k.amount) && (React.createElement(semantic_ui_react_1.Message, { warning: true },
                    React.createElement(semantic_ui_react_1.Message.Header, null, "You have credit left in your account that you will miss out on if you cancel!"),
                    React.createElement(semantic_ui_react_1.Message.Content, null,
                        "You have",
                        ' ',
                        React.createElement("b", null, (0, format_1.toPrice)(Math.abs((_l = _billing.subscription.balance) === null || _l === void 0 ? void 0 : _l.amount), (_m = _billing.subscription.balance) === null || _m === void 0 ? void 0 : _m.currency)),
                        ' ',
                        "in credit towards subscription dues in your account."))),
                React.createElement("p", null,
                    React.createElement("b", null, "Please confirm that you'd like to delete your Lunch Money account and all associated data."),
                    ' ',
                    "Your data will be purged and unrecoverable within 48 hours."),
                React.createElement("p", null, "All budgets and accounts you own (listed below) will be deleted as well, including those in collaboration with others. Budgets you do not own will not be affected."),
                React.createElement("ul", { className: "mt-0" }, _user.allAccounts
                    .filter(o => o.access_level == 'admin')
                    .map(o => (React.createElement("li", { key: o.account_id }, o.account_display_name)))),
                React.createElement("p", null,
                    "A refund for unused time will only be issued if the credit is more than ",
                    (0, format_1.toPrice)(3, _billing.user.stripe_plan_currency),
                    ". It will take 5 to 7 business days for the refund to process before showing up in your account. Please note that we do not refund credit balances."),
                React.createElement("p", null, "We would appreciate it if you could spare a moment to let us know why you're canceling or what we could improve on!"),
                React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal", placeholder: "Which statement do you agree with the most?", fluid: true, selection: true, onChange: (e, { value }) => {
                                    setCancellationReason(value);
                                }, options: [
                                    {
                                        key: 'missing-features',
                                        value: 'Lunch Money is missing features that are important to me.',
                                        text: 'Lunch Money is missing features that are important to me.',
                                    },
                                    {
                                        key: 'account-syncing',
                                        value: `I couldn't get my accounts to sync.`,
                                        text: `I couldn't get my accounts to sync.`,
                                    },
                                    {
                                        key: 'difficult-to-use',
                                        value: `The product is difficult to use.`,
                                        text: `The product is difficult to use.`,
                                    },
                                    {
                                        key: 'trouble-setting-up',
                                        value: `I had trouble getting set up.`,
                                        text: `I had trouble getting set up.`,
                                    },
                                    {
                                        key: 'found-better-solution',
                                        value: `I found a better solution.`,
                                        text: `I found a better solution.`,
                                    },
                                    {
                                        key: 'not-for-me',
                                        value: `It's just not for me.`,
                                        text: `It's just not for me.`,
                                    },
                                    {
                                        key: 'too-expensive',
                                        value: `It's too expensive!`,
                                        text: `It's too expensive!`,
                                    },
                                    {
                                        key: 'none-of-the-above',
                                        value: `None of the above`,
                                        text: `None of the above`,
                                    },
                                ] }))),
                    cancellationReason ==
                        'Lunch Money is missing features that are important to me.' && (React.createElement(semantic_ui_react_1.Message, { info: true },
                        React.createElement("p", null,
                            "We have a",
                            ' ',
                            React.createElement("a", { href: "https://feedback.lunchmoney.app", className: "link clickable", target: "_blank" }, "Feature Request board"),
                            "! Lunch Money is still a work in progress and we have lots of exciting new features coming up. Check out what other users are requesting. By voting a feature request, we will automatically notify you when it's being developed."),
                        React.createElement(semantic_ui_react_1.Button, { size: "mini", onClick: () => {
                                window.open('https://feedback.lunchmoney.app');
                            } },
                            "View feature request board",
                            ' ',
                            React.createElement(semantic_ui_react_1.Icon, { className: "ml-1rem", fitted: true, name: "external alternate" })))),
                    cancellationReason == `I couldn't get my accounts to sync.` && (React.createElement(semantic_ui_react_1.Message, { info: true },
                        React.createElement("p", null, "For bank syncing, we use a third-party importer called Plaid to connect directly to your institutions. If you encounter syncing issues, go to your Accounts page, click into the account in question and submit a support ticket. We're eager to help you fix this issue and we'll typically get back to you in less than 24 hours!"),
                        React.createElement(semantic_ui_react_1.Button, { size: "mini", onClick: () => {
                                history.push('/accounts');
                            } }, "Go to my Accounts"))),
                    cancellationReason == `The product is difficult to use.` && (React.createElement(semantic_ui_react_1.Message, { info: true },
                        React.createElement("p", null, "Sorry to hear you were having trouble using Lunch Money! We'd love to see if there's any way we can do. Submit a bug or feedback via the 'Help' button at the top right of every page, and we'll be more than happy to help!"))),
                    cancellationReason == `I had trouble getting set up.` && (React.createElement(semantic_ui_react_1.Message, { info: true },
                        React.createElement("p", null, "Sorry to hear you were having trouble getting set up! We'd love to see if there's any way we can do. Submit a bug or feedback via the 'Help' button at the top right of every page, and we'll be more than happy to help!"))),
                    cancellationReason == `It's too expensive!` && (React.createElement(semantic_ui_react_1.Message, { info: true },
                        React.createElement("p", null, "We understand that our subscription costs may not be accessible to everyone. This is why we offer flexible pay-what-you-want annual plans starting at $40 per year. As much as we'd love to offer more discounts, we also need to stay sustainable as a small business!"))),
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(semantic_ui_react_1.TextArea, { rows: 2, placeholder: 'Please provide more details (optional)', onChange: (e, { value }) => {
                                    setCancellationDetails(value);
                                } }))),
                    React.createElement(semantic_ui_react_1.Form.Group, null,
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: mailingList, onChange: (e, { checked }) => {
                                    setMailingList(checked);
                                }, label: "Keep me on the newsletter for latest updates" })))))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { loading: isLoading, disabled: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setDisplay(null);
                        setOfferPause(false);
                    }), color: 'yellow', content: `Cancel` }),
                display == 'pause' && (React.createElement(semantic_ui_react_1.Button, { loading: isLoading, disabled: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setIsLoading(true);
                        yield (0, billing_1.pauseSubscription)();
                        setIsLoading(false);
                        setDisplay(null);
                        _billing.checkBilling();
                    }), color: 'green', content: `Claim ${(0, format_1.toPrice)((_o = _data.current.credit) === null || _o === void 0 ? void 0 : _o.amount, (_p = _data.current.balance) === null || _p === void 0 ? void 0 : _p.currency)} credit & Pause` })),
                display == 'pause' && offerPause && (React.createElement(semantic_ui_react_1.Button, { loading: isLoading, disabled: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setDisplay('cancel');
                    }), color: 'red', content: `No, I want to delete my account` })),
                display == 'cancel' && (React.createElement(semantic_ui_react_1.Button, { loading: isLoading, disabled: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setIsLoading(true);
                        const results = yield (0, billing_1.requestCancellation)({
                            reason: cancellationReason,
                            details: cancellationDetails,
                            newsletter: mailingList,
                        });
                        if (!results.error) {
                            setDisplay('confirmed_cancel');
                            setTimeout(() => {
                                _user.signout(true);
                            }, 5000);
                        }
                        setIsLoading(false);
                    }), negative: true, content: "Delete my account" }))))));
};
exports.default = Cancellation;
