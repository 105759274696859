"use strict";
// Original author: Nik M
// https://github.com/nik-m2/react-column-resizer
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const ColumnResizer = ({ disabled = false, //boolean
minWidth = 60, //number
className, tableId, prevCol, nextCol, }) => {
    const element = (0, react_1.useRef)(null);
    const dragging = (0, react_1.useRef)(false);
    const mouseX = (0, react_1.useRef)(0);
    const startPos = (0, react_1.useRef)(0);
    const startWidthPrev = (0, react_1.useRef)(0);
    const startWidthNext = (0, react_1.useRef)(0);
    (0, react_1.useEffect)(() => {
        addEventListenersToDocument();
        return () => {
            removeEventListenersFromDocument();
        };
    }, []);
    const startDrag = e => {
        if (disabled) {
            return;
        }
        dragging.current = true;
        startPos.current = mouseX.current;
        startWidthPrev.current = 0;
        startWidthNext.current = 0;
        if (element.current) {
            let prevSibling = element.current.previousSibling;
            let nextSibling = element.current.nextSibling;
            if (prevSibling) {
                startWidthPrev.current = prevSibling.clientWidth;
            }
            if (nextSibling) {
                startWidthNext.current = nextSibling.clientWidth;
            }
        }
    };
    const endDrag = () => {
        if (disabled) {
            return;
        }
        dragging.current = false;
    };
    const onMouseMove = e => {
        if (disabled) {
            return;
        }
        mouseX.current = e.touches ? e.touches[0].screenX : e.screenX;
        if (!dragging.current) {
            return;
        }
        const moveDiff = startPos.current - mouseX.current;
        let newPrev = startWidthPrev.current - moveDiff;
        let newNext = startWidthNext.current + moveDiff;
        if (newPrev < minWidth) {
            const offset = newPrev - minWidth;
            newPrev = minWidth;
            newNext += offset;
        }
        else if (newNext < minWidth) {
            const offset = newNext - minWidth;
            newNext = minWidth;
            newPrev += offset;
        }
        if (element.current) {
            localStorage.setItem(`${tableId}-${prevCol}`, newPrev.toString());
            localStorage.setItem(`${tableId}-${nextCol}`, newNext.toString());
            element.current.previousSibling.style.width = newPrev + 'px';
            element.current.nextSibling.style.width = newNext + 'px';
        }
    };
    const addEventListenersToDocument = () => {
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', endDrag);
        document.addEventListener('touchmove', onMouseMove);
        document.addEventListener('touchend', endDrag);
    };
    const removeEventListenersFromDocument = () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', endDrag);
        document.removeEventListener('touchmove', onMouseMove);
        document.removeEventListener('touchend', endDrag);
    };
    return (React.createElement("th", { ref: element, className: 'td-resize' },
        React.createElement("div", { className: "dragger", onMouseDown: !disabled && startDrag, onTouchStart: !disabled && startDrag })));
};
exports.default = ColumnResizer;
