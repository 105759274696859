"use strict";
/**
 *  Transactions.tsx
 *  Container for the Transactions page
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const tags_1 = require("../actions/tags");
// Semantic UI
const semantic_ui_react_1 = require("semantic-ui-react");
// Components
const TagRow_1 = require("../components/Tags/TagRow");
const NewTagRow_1 = require("../components/Tags/NewTagRow");
const ContainerHeader_1 = require("../components/elements/ContainerHeader");
const react_1 = require("react");
const TagsProvider_1 = require("@/providers/TagsProvider");
const ColumnResizer_1 = require("@components/elements/ColumnResizer");
const NewModal_1 = require("@/components/global/NewModal");
const PopupTransactions_1 = require("@/components/Transactions/PopupTransactions");
const Tags = ({ _showToast, _removeToast, _process }) => {
    const [tagRows, setTagRows] = (0, react_1.useState)([]);
    const [numAffectedRules, setNumAffectedRules] = (0, react_1.useState)(null);
    const [numAffectedTx, setNumAffectedTx] = (0, react_1.useState)(null);
    const [deleteTagId, setDeleteTagId] = (0, react_1.useState)(null);
    const [showDialog, setShowDialog] = (0, react_1.useState)(false);
    const [showNewTag, setShowNewTag] = (0, react_1.useState)(false);
    const [showModal, setShowModal] = (0, react_1.useState)(false);
    const [modalData, setModalData] = (0, react_1.useState)(null);
    const [showArchived, setShowArchived] = (0, react_1.useState)(localStorage.getItem('_lm_tags_show_archived') === 'true');
    const [openTransactionsTable, setOpenTransactionsTable] = (0, react_1.useState)(false);
    const [selectedTag, setSelectedTag] = (0, react_1.useState)(null);
    const _tags = (0, react_1.useContext)(TagsProvider_1.TagsContext);
    let nameWidth = localStorage.getItem(`tags-name`);
    let descriptionWidth = localStorage.getItem(`tags-description`);
    // Insurance against absurdly large tables on small screens
    if (nameWidth && descriptionWidth) {
        if (nameWidth > descriptionWidth) {
            nameWidth = null;
        }
        else {
            descriptionWidth = null;
        }
    }
    const insertTag = (obj) => __awaiter(void 0, void 0, void 0, function* () {
        _tags.insertTag(obj);
        setShowNewTag(false);
    });
    const removeTag = (id) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield (0, tags_1.deleteTag)(id);
        if (result.error) {
            if (result.error.data) {
                const transactions = result.error.data.transactions;
                const rules = result.error.data.rules;
                setNumAffectedTx(transactions);
                setNumAffectedRules(rules);
                setShowDialog(true);
                setDeleteTagId(id);
            }
        }
        else {
            // Remove from tags array
            _tags.removeTag(id);
            _showToast({ message: 'Successfully deleted tag', type: 'success' });
        }
    });
    const forceRemoveTag = (id) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield (0, tags_1.forceDeleteTag)(id);
        if (!result.error) {
            setShowDialog(false);
            // Remove from tags array
            _tags.removeTag(id);
            _showToast({ message: 'Successfully deleted tag', type: 'success' });
        }
    });
    const updateTag = (id, updateObj) => __awaiter(void 0, void 0, void 0, function* () {
        _tags.updateTagDetails(id, updateObj);
        _showToast({ message: 'Successfully updated tag', type: 'success' });
    });
    (0, react_1.useEffect)(() => {
        document.title = 'Tags - Lunch Money';
        document.addEventListener('keydown', handleKeyDown, false);
    }, []);
    /**
     * Key listener on body
     */
    const handleKeyDown = event => {
        if (event.keyCode === 27) {
            setShowNewTag(false);
        }
    };
    (0, react_1.useEffect)(() => {
        // Generate tag rows
        const rows = _tags.tags
            .sort(function (a, b) {
            return a['name']
                .trim()
                .toLowerCase()
                .localeCompare(b['name'].toLowerCase().trim());
        })
            .map((tag, index) => {
            return (React.createElement(TagRow_1.default, { key: `tag-row-${tag.id}`, tag: tag, showArchived: showArchived, update: (id, obj) => {
                    updateTag(id, obj);
                }, del: id => {
                    removeTag(id);
                }, onOpenModal: tag => {
                    setModalData(tag);
                    setShowModal(true);
                }, openTable: tag => {
                    setSelectedTag(tag);
                    setOpenTransactionsTable(true);
                } }));
        });
        setTagRows(rows);
    }, [_tags.tags, showArchived]);
    return (React.createElement(semantic_ui_react_1.Container, { className: "g-tags" },
        React.createElement(ContainerHeader_1.default, { title: "Tags" }),
        React.createElement("div", { className: "width-100 flex--space-between-flex-end" },
            React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                    setShowNewTag(true);
                } }, "Add A New Tag"),
            React.createElement(semantic_ui_react_1.Checkbox, { className: "mt-05rem", toggle: true, label: "Show archived tags", checked: showArchived, onChange: (e, { checked }) => {
                    localStorage.setItem('_lm_tags_show_archived', checked ? 'true' : 'false');
                    setShowArchived(checked);
                } })),
        React.createElement("div", { className: "table-container mt-1rem" },
            React.createElement(semantic_ui_react_1.Table, { fixed: true, selectable: true, celled: true, unstackable: true, className: "p-tags-table" },
                React.createElement(semantic_ui_react_1.Table.Header, { className: "sticky" },
                    React.createElement(semantic_ui_react_1.Table.Row, null,
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-name", style: nameWidth
                                ? {
                                    width: `${nameWidth}px`,
                                }
                                : {} }, "Name"),
                        React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'tags', prevCol: 'name', nextCol: 'description' }),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { style: descriptionWidth
                                ? {
                                    width: `${descriptionWidth}px`,
                                }
                                : {} }, "Description"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-color" }, "Color"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-settings" }),
                        showArchived && (React.createElement(React.Fragment, null,
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "center-align table-cell-matches" }, "Archived"))),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-settings" }))),
                React.createElement(semantic_ui_react_1.Table.Body, { className: tagRows.length === 0 ? 'g-empty-body' : '' },
                    showNewTag && React.createElement(NewTagRow_1.default, { onSave: insertTag }),
                    tagRows.length === 0 && (React.createElement(semantic_ui_react_1.Table.Row, { className: "g-empty-row no-hover-tr" },
                        React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: showArchived ? 11 : 9, className: "center-align" },
                            React.createElement("div", { className: "empty-mascot" }),
                            React.createElement("p", null, "You have no tags yet!"),
                            React.createElement("p", null, "Use tags to further organize your transactions. For instance, tag expenses related to a trip with 'Los Angeles 2020'."),
                            React.createElement("p", null,
                                "Get started by clicking",
                                ' ',
                                React.createElement(semantic_ui_react_1.Button, { size: "mini", onClick: () => {
                                        setShowNewTag(true);
                                    } }, "add a new tag"))))),
                    tagRows))),
        React.createElement(semantic_ui_react_1.Confirm, { open: !!(showDialog && (numAffectedRules || numAffectedTx)), onConfirm: () => {
                forceRemoveTag(deleteTagId);
            }, confirmButton: 'Yes, I still want to delete this tag', onCancel: () => {
                setShowDialog(false);
            }, cancelButton: 'Cancel', header: 'Are you sure you want to delete this tag?', content: React.createElement("div", { className: "content" },
                React.createElement("p", null,
                    "Deleting this tag will:",
                    !!numAffectedTx && numAffectedTx > 0 && (React.createElement("li", null,
                        "untag ",
                        numAffectedTx,
                        " transaction",
                        numAffectedTx > 1 ? 's' : '')),
                    !!numAffectedRules && numAffectedRules > 0 && (React.createElement("li", null,
                        "modify/remove ",
                        numAffectedRules,
                        " rule",
                        numAffectedRules > 1 ? 's' : '')))) }),
        showModal && (React.createElement(NewModal_1.default, { show: showModal, setShow: (show) => {
                setShowModal(show);
            }, data: {
                tag: modalData,
                del: id => {
                    removeTag(id);
                },
            }, utils: {
                _process: _process,
                _showToast: _showToast,
            }, view: 'EDIT_TAG', closeCallback: () => __awaiter(void 0, void 0, void 0, function* () {
                setModalData(null);
            }) })),
        React.createElement(PopupTransactions_1.default, { openTransactionsTable: openTransactionsTable && (selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag.id), onClose: () => __awaiter(void 0, void 0, void 0, function* () {
                setSelectedTag(null);
                setOpenTransactionsTable(false);
            }), headerText: `All transactions tagged ${selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag.name}`, query: {
                time: 'all',
                match: 'all',
                tag: selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag.id,
            }, _process: _process, _showToast: _showToast, _removeToast: _removeToast })));
};
exports.default = Tags;
